import { RANK_CONFIG } from '../../../Constants/RANK_CONFIG'
import { TypeUser } from '../types/TypeUser'

export const sumPointsRanking = (user: TypeUser) => {
	const { ranking } = user
	const indicationsOfAgents = (user.indicationsOfAgents || 0) * RANK_CONFIG.indicacao
	const academy = user.academy ? RANK_CONFIG.academy : 0
	const nPubli = (ranking.nPubli || 0) * RANK_CONFIG.originacaoEnviada
	const nAprovadas = (ranking.nAprovadas || 0) * RANK_CONFIG.originacaoAprovada
	const nContratos = (ranking.nContratos || 0) * RANK_CONFIG.contratoAssinado
	const pontuacaoManual = user.pontuacaoManual || 0
	return indicationsOfAgents + academy + nPubli + nAprovadas + nContratos + pontuacaoManual
}
