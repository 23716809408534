import { useGlobalContext } from 'App/Global'
import { ModalDs } from 'Components/ModalDS'
import { InternaLoading } from 'Components/InternalLoading'
import { UiModal } from 'ComponentsUi/UiModal'

export const GlobalLoading = () => {
	const { globalLoading } = useGlobalContext()

	return (
		<UiModal show={globalLoading} width='small' >
			<div style={{ padding: 50 }}>
				<InternaLoading />
			</div>
		</UiModal>
	)

	// return <ModalDs show={globalLoading} width={200}>
	// 	<div style={{ padding: 50 }}>
	// 		<InternaLoading />
	// 	</div>
	// </ModalDs>
}
