import { clone } from '../../../Functions/Utils/clone'
import { TypeMatch } from '../../Match/types/match'
import { TypeNotifications } from '../types/notification'

export const unReadNotificationsPerMatch = ({
	notificationsUnRead,
	match,
}: {
	notificationsUnRead: TypeNotifications;
	match: TypeMatch;
}): TypeNotifications => {

	const cloneNotificationsUnRead = clone(notificationsUnRead)
	const cloneMatch = clone(match)

	const arrayNotifications = Object.entries(cloneNotificationsUnRead).filter(
		([id, notification]) => notification.match?.id === cloneMatch._id
	)

	return Object.fromEntries(arrayNotifications)
}
