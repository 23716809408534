import { fnOrigination } from 'Functions/Origination'
import { fnTeaser } from 'Functions/Teaser'
import { db5 } from 'Services/db5'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'

/**
 * Função usada para checar se existe alguma originação com o cnpj passado
 * caso tenha, retorna  originação
 * @param cnpj string
 * @param profile TypeUser
 * @returns teaser {TypeTeaser}
 */
export const checkCNPJ = async ({ cnpj, profile }: { cnpj: string, profile: TypeUser }) => {
	const resultTeasers = await db5.teasers.getWhere({
		profile: profile,
		wheres: [['cnpj', '==', cnpj]],
	})

	if (resultTeasers && resultTeasers.docs && Object.keys(resultTeasers.docs).length) {
		//pega a originação mais recente
		const orderedTeasers: TypeTeaser[] = Object.values(resultTeasers.docs).sort((a, b) => a.dataCriacao > b.dataCriacao ? -1 : 1)
		return fnTeaser.modelTeaser(orderedTeasers[0])
	}

	const data = await fnOrigination.getCompanyDetail(cnpj)
	if (data) {
		return fnTeaser.modelTeaser({ ...data, cnpj })
	}

	return {}
}
