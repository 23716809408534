import { TYPE_OPERATION } from 'Constants/TYPE_OPERATION'
import React, { createContext, useContext, useState } from 'react'
import { fnMatch } from 'Functions/Match'

const _useContext = () => {
	// const [globalManualMatchInstitutions, setGlobalManualMatchInstitutions] = useState<TypeInstitutions>({})
	const [globalManualMatch, setGlobalManualMatch] = useState(fnMatch.modelManualMatch())
	const [globalManualMatchCredit, setGlobalManualMatchCredit] = useState(fnMatch.modelManualMatch({ typeOperation: [TYPE_OPERATION.op2] }))

	return ({
		// globalManualMatchInstitutions, setGlobalManualMatchInstitutions,
		globalManualMatch, setGlobalManualMatch,
		globalManualMatchCredit, setGlobalManualMatchCredit,
	})
}
const ContextManualMatch = createContext({} as ReturnType<typeof _useContext>)

export const useContextManualMatch = () => useContext(ContextManualMatch)
export const ContextManualMatchProvider: React.FC = ({ children }) => {
	const controller = _useContext()
	return (
		<ContextManualMatch.Provider value={controller}>
			{children}
		</ContextManualMatch.Provider>
	)
}
