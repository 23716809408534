import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const WebOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16 5.09881C9.97944 5.09881 5.09881 9.97944 5.09881 16C5.09881 22.0206 9.97944 26.9012 16 26.9012C22.0206 26.9012 26.9012 22.0206 26.9012 16C26.9012 9.97944 22.0206 5.09881 16 5.09881ZM2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M2 16.0001C2 15.1444 2.69369 14.4507 3.54941 14.4507H28.4506C29.3063 14.4507 30 15.1444 30 16.0001C30 16.8558 29.3063 17.5495 28.4506 17.5495H3.54941C2.69369 17.5495 2 16.8558 2 16.0001Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M12.5699 15.9998C12.6511 19.6271 13.8578 23.1241 16.0003 26.0166C18.1429 23.1241 19.3496 19.6271 19.4308 15.9998C19.3496 12.3724 18.1429 8.87546 16.0003 5.98298C13.8578 8.87546 12.6511 12.3724 12.5699 15.9998ZM16.0003 3.54917L14.8566 2.50391C11.4877 6.19009 9.57368 10.9752 9.47103 15.9679C9.47059 15.9891 9.47059 16.0104 9.47103 16.0316C9.57368 21.0243 11.4877 25.8094 14.8566 29.4956C15.1502 29.8168 15.5652 29.9998 16.0003 29.9998C16.4355 29.9998 16.8505 29.8168 17.1441 29.4956C20.513 25.8094 22.427 21.0243 22.5297 16.0316C22.5301 16.0104 22.5301 15.9891 22.5297 15.9679C22.427 10.9752 20.513 6.19009 17.1441 2.50391L16.0003 3.54917Z' fill='#272827' />
	</Svg>
)
