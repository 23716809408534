import * as Tokens from 'Components/CardDS/src/Tokens'

export const TextareaTheme = {
	label: {
		color: '#6F6F6F',
		font: Tokens.FONT_MAIN,
		size: Tokens.FONT_SIZE_XS,
		weight: Tokens.FONT_SEMI_BOLD
	},
	size: {
		sm: Tokens.SPACE_9,
		md: Tokens.SPACE_11,
	},
	border: {
		weight: Tokens.BORDER_WEIGHT_THIN,
		radius: Tokens.BORDER_RADIUS_SM
	},
	input: {
		color: '#6F6F6F',
		font: Tokens.FONT_MAIN,
		size: {
			sm: Tokens.FONT_SIZE_XS,
			md: Tokens.FONT_SIZE_SM,
		},
		weight: Tokens.FONT_REGULAR,
		placeholderColor: '#B2B2B2'
	},
	status: {
		['default']: {
			color: Tokens.COLOR_NEUTRAL_400
		},
		['error']: {
			color: Tokens.COLOR_ERROR_400
		},
		['success']: {
			color: Tokens.COLOR_SUCCESS_400
		},
		['focused']: {
			color: Tokens.COLOR_PRIMARY_650
		},
		['disabled']: {
			backgroundColor: Tokens.COLOR_NEUTRAL_150
		}
	}
}
