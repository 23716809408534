import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PinOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16.0719 4.80002C10.9733 4.80002 6.87425 8.79326 6.87425 13.7601C6.87425 17.19 9.16322 20.5594 11.7288 23.2128C12.9824 24.5094 14.241 25.5721 15.1876 26.3112C15.4675 26.5296 15.7191 26.719 15.934 26.877C16.1537 26.7179 16.4115 26.5265 16.6986 26.3054C17.6587 25.566 18.9349 24.5028 20.2061 23.2059C22.8091 20.5501 25.1257 17.1828 25.1257 13.7601C25.1257 8.76824 21.1449 4.80002 16.0719 4.80002ZM15.9281 28.6002C15.1272 29.7627 15.1268 29.7624 15.1265 29.7622L15.1228 29.7597L15.1145 29.7543L15.0868 29.736C15.0635 29.7204 15.0304 29.6982 14.9884 29.6696C14.9042 29.6125 14.7838 29.5296 14.6323 29.4223C14.3294 29.2078 13.9016 28.8953 13.3902 28.4962C12.3698 27.6995 11.0056 26.5485 9.63652 25.1326C6.95654 22.3609 4 18.3103 4 13.7601C4 7.24685 9.38594 2 16.0719 2C22.7832 2 28 7.27187 28 13.7601C28 18.3175 24.9992 22.3702 22.2849 25.1395C20.8975 26.5551 19.515 27.7057 18.4811 28.5019C17.963 28.9009 17.5294 29.2132 17.2227 29.4276C17.0692 29.5348 16.9472 29.6176 16.862 29.6747C16.8194 29.7033 16.786 29.7254 16.7623 29.7409L16.7344 29.7592L16.726 29.7647L16.7233 29.7664C16.7229 29.7667 16.7216 29.7675 15.9281 28.6002ZM15.9281 28.6002L16.7216 29.7675C16.2378 30.0796 15.6082 30.0772 15.1265 29.7622L15.9281 28.6002Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.0002 11.6667C14.8641 11.6667 13.9431 12.5621 13.9431 13.6667C13.9431 14.7712 14.8641 15.6667 16.0002 15.6667C17.1363 15.6667 18.0573 14.7712 18.0573 13.6667C18.0573 12.5621 17.1363 11.6667 16.0002 11.6667ZM11.2002 13.6667C11.2002 11.0893 13.3492 9 16.0002 9C18.6512 9 20.8002 11.0893 20.8002 13.6667C20.8002 16.244 18.6512 18.3333 16.0002 18.3333C13.3492 18.3333 11.2002 16.244 11.2002 13.6667Z' fill='#272827' />
	</Svg>
)
