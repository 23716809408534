import React from 'react'
import { LogoStark } from 'Components/Layout/LogoStark'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useHistory } from 'react-router-dom'
import { TMenu } from './menu'
import { UiButton } from 'ComponentsUi/UiButton'
import { generateUniqueId } from 'Functions/Utils/generateUniqueId'
import { useContextAuth } from 'App/ContextAuth'
import { encrypt } from 'Functions/Utils/encript'

export const menuOffice = (profile: TypeUser, menu: string, setMenu: React.Dispatch<React.SetStateAction<string>>): TMenu => {

	const history = useHistory()

	const { globalAuth } = useContextAuth()
	const codeProfile = encrypt(globalAuth.email)

	return ([
		{
			Component: (<>
				<div className='tw-hidden md:tw-block'>
					<LogoStark />
				</div>
				<div className='tw-block md:tw-hidden tw-h-[10px]'></div>
			</>),
			key: generateUniqueId(),
			type: 'component',
		},

		{
			type: 'component',
			Component: <div className='tw-mx-2'><UiButton className='tw-w-full' onClick={() => history.push('/teasers/newOrigination/')}>Nova Originação</UiButton></div>,
			key: generateUniqueId(),
		},

		{
			key: generateUniqueId(),
			type: 'link',
			title: 'Dash',
			icon: <i className='material-icons-outlined'> home </i>,
			iconActive: <i className='material-icons tw-text-xl'>home</i>,
			checkActive: history.location.pathname === '/painel/',
			to: '/painel/'
		},

		{
			key: generateUniqueId(),
			type: 'link',
			title: 'Originações',
			icon: <i className='material-icons-outlined'> filter_alt </i>,
			iconActive: <i className='material-icons tw-text-xl'>filter_alt</i>,
			checkActive: history.location.pathname === '/originations/',
			to: '/originations/'
		},
		{
			key: generateUniqueId(),
			type: 'link',
			title: 'Agentes',
			icon: <i className='material-icons-outlined'> person </i>,
			iconActive: <i className='material-icons tw-text-xl'> person </i>,
			checkActive: history.location.pathname === '/user-management/',
			to: '/user-management/'
		},
		{
			key: generateUniqueId(),
			type: 'link',
			title: 'Clientes',
			icon: <i className='material-icons-outlined'> business </i>,
			iconActive: <i className='material-icons tw-text-xl'>business</i>,
			checkActive: history.location.pathname === '/active-customers/',
			to: '/active-customers/'
		},
		{
			key: generateUniqueId(),
			type: 'link',
			title: 'Kit Comercial',
			icon: <i className='material-icons-outlined'> inventory </i>,
			iconActive: <i className='material-icons tw-text-xl'>inventory</i>,
			checkActive: history.location.pathname === '/kit-comercial/',
			to: '/kit-comercial/'
		},
		{
			key: generateUniqueId(),
			type: 'link',
			title: 'Teses',
			icon: <i className='material-icons-outlined'> description </i>,
			iconActive: <i className='material-icons tw-text-xl'>description</i>,
			checkActive: history.location.pathname === '/teses/',
			to: '/teses/'
		},
		{
			title: 'Darwin Light',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> android </i>,
			iconActive: <i className='material-icons tw-text-xl'>android</i>,
			checkActive: history.location.pathname.startsWith('/darwin-light/'),
			to: '/darwin-light/'
		},
		// {
		// 	key: generateUniqueId(),
		// 	type: 'link',
		// 	title: 'Darwin',
		// 	icon: <i className='material-icons-outlined'> precision_manufacturing </i>,
		// 	iconActive: <i className='material-icons tw-text-xl'>precision_manufacturing</i>,
		// 	checkActive: history.location.pathname === '/darwin/',
		// 	to: '/darwin/'
		// },
		{
			key: generateUniqueId(),
			type: 'link',
			title: 'Academy',
			icon: <i className='material-icons-outlined'> school </i>,
			iconActive: <i className='material-icons tw-text-xl'>school</i>,
			//checkActive: history.location.pathname === '/academy/',
			onClick: () => window.open(`https://stark.weduka.com.br/Authentication/Consume/?id=${codeProfile}`, '_blank')
		},
	])
}
