import { TypeUser } from '../types/TypeUser'

/**
 * Filtra um array de usuários pelo usuário relacionado/office
 * @param teasers
 * @param order
 * @returns  TypeUser[]
 */
export const filterByRelationship = ({ users, filter }: { users: TypeUser[], filter: string }): TypeUser[] =>
	users.filter(user =>
		filter === user._id || user.fromOffice === filter || user.uidBdaFather === filter || (user.fromOffice === '' && filter === '100')
	)
