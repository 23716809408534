import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PersonOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M11.5847 18.4829L11.5886 18.4829L20.4103 18.4829C21.2397 18.4829 22.0609 18.6453 22.8269 18.9607C23.593 19.2762 24.2887 19.7385 24.8743 20.3212C25.4599 20.9039 25.9238 21.5955 26.2394 22.3564C26.5548 23.1168 26.716 23.9314 26.7137 24.7536V27.241C26.7137 27.9363 26.1455 28.5 25.4446 28.5C24.7437 28.5 24.1755 27.9363 24.1755 27.241V24.7515L24.1755 24.7476C24.1771 24.2562 24.0808 23.7692 23.8922 23.3147C23.7037 22.8602 23.4266 22.4471 23.0768 22.099C22.727 21.7509 22.3114 21.4748 21.8538 21.2863C21.3963 21.0979 20.9057 21.0009 20.4103 21.0009H11.5907C10.5906 21.0044 9.63268 21.4008 8.92658 22.1035C8.22006 22.8065 7.82332 23.7587 7.82333 24.7515V27.241C7.82333 27.9363 7.25514 28.5 6.55424 28.5C5.85335 28.5 5.28516 27.9363 5.28516 27.241V24.7515C5.28515 23.0923 5.94824 21.5007 7.12908 20.3257C8.30993 19.1507 9.91221 18.488 11.5847 18.4829Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.0075 6.01798C13.928 6.01798 12.2423 7.69034 12.2423 9.7533C12.2423 11.8163 13.928 13.4886 16.0075 13.4886C18.087 13.4886 19.7728 11.8163 19.7728 9.7533C19.7728 7.69034 18.087 6.01798 16.0075 6.01798ZM9.7041 9.7533C9.7041 6.2997 12.5262 3.5 16.0075 3.5C19.4888 3.5 22.311 6.2997 22.311 9.7533C22.311 13.2069 19.4888 16.0066 16.0075 16.0066C12.5262 16.0066 9.7041 13.2069 9.7041 9.7533Z' fill='#272827' />
	</Svg>
)
