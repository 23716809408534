import styled from 'styled-components'

type Container = {
	marginBottom?: string
}

export const Container = styled.div<Container>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: ${props => props.marginBottom};
`
export const IconTitle = styled.div`
	margin-right: 5px;
	i{
	color: #a7a7a7;
	font-size: 30px;
	}
`
export const DescriptionTitle = styled.div`
	p {
		color: #676767;
		font-weight: bold;
		font-size: 15px;
		margin-bottom: 0;
	}
	> span {
		font-size: 12px;
    color: #828282;
	}
`
