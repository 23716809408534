import { mergeClassName } from '../Functions/Utils/mergeClassName'
import { UiContainerIcon } from './UiContainerIcon'

export interface UiCheckBoxProps {
	value: boolean;
	setValue: (value: UiCheckBoxProps['value']) => void
	label?: string;
	helperText?: string;
	disabled?: boolean;
	className?: string;
	checkRight?: boolean;
	iconLeft?: string;
	iconBgColor?: string;
	iconColor?: string;
}

export const UiCheckBox = (props: UiCheckBoxProps) => (
	<div
		onClick={e => !props.disabled && props.setValue(!props.value)}
		className={mergeClassName(`tw-group ${!props.disabled ? 'tw-cursor-pointer hover:tw-bg-neutral-000' : 'tw-cursor-default'} tw-relative tw-flex tw-gap-2 tw-items-start cy-${props.label?.replace(/[^A-Za-zÀ-úà-ú\s]+/g, '').replace(/\s/g, '-').replace(/[/]/g, '').replace(/[?]/g, '').replace(/[,]/g, '')}`, props.className || '')} >
		{!props.checkRight && <>
			<div className={`${!props.disabled ? 'tw-cursor-pointer hover:tw-bg-neutral-000' : 'tw-cursor-default'} tw-flex tw-items-center tw-h-5`}>
				<input
					id='comments'
					aria-describedby='comments-description'
					name='comments'
					type='checkbox'
					disabled={props?.disabled}
					className={`${!props.disabled ? 'tw-cursor-pointer hover:tw-bg-neutral-000' : 'tw-cursor-default'} tw-h-4 tw-w-4 cy-uiCheckbox ${props.disabled ? 'tw-accent-neutral-200' : 'tw-accent-blue-500 tw-cursor-pointer'} tw-border tw-border-solid tw-ring-0 tw-rounded ${props.value ? 'tw-border-blue-500' : 'tw-border-neutral-300'}`}
					checked={props.value}
					onChange={e => !props.disabled && props.setValue(e.target.checked)}
				/>
			</div>
			{props.label && <div>
				{props.label && <p className={`tw-text-sm tw-my-0 tw-font-semibold ${props.disabled ? 'tw-text-neutral-400' : 'tw-text-neutral-900'}`}>{props.label}</p>}
				{props.helperText && <p className={`tw-text-xs tw-my-0 ${props.disabled ? 'tw-text-neutral-400' : 'tw-text-neutral-500'}`}>{props.helperText}</p>}
			</div>}
		</>}
		{props.checkRight &&
			<div className='tw-flex tw-items-center tw-gap-2 tw-justify-between tw-w-full'>
				<div className='tw-flex tw-items-center tw-gap-2'>
					{props.iconLeft &&
						<UiContainerIcon className={`${`tw-bg-${props.iconBgColor}` || ''} ${`tw-text-${props.iconColor}` || ''}`} value={props.iconLeft} outlined={false} />
					}
					{props.label && <div>
						{props.label && <p className={`tw-text-sm tw-my-0 tw-font-semibold ${props.disabled ? 'tw-text-neutral-400' : 'tw-text-neutral-900'}`}>{props.label}</p>}
						{props.helperText && <p className={`tw-text-xs tw-my-0 ${props.disabled ? 'tw-text-neutral-400' : 'tw-text-neutral-500'}`}>{props.helperText}</p>}
					</div>}
				</div>

				<div className={`${!props.disabled ? 'tw-cursor-pointer hover:tw-bg-neutral-000' : 'tw-cursor-default'} tw-flex tw-items-center tw-h-5`}>
					<input
						id='comments'
						aria-describedby='comments-description'
						name='comments'
						type='checkbox'
						disabled={props?.disabled}
						className={`group-hover:tw-bg-neutral-000 tw-h-4 tw-w-4 cy-uiCheckbox ${props.disabled ? 'tw-accent-neutral-200' : 'tw-accent-blue-500 tw-cursor-pointer'} tw-border tw-border-solid tw-ring-0 tw-rounded ${props.value ? 'tw-border-blue-500' : 'tw-border-neutral-300'}`}
						checked={props.value}
						onChange={e => !props.disabled && props.setValue(e.target.checked)}
					/>
				</div>
			</div>}
	</div>
)
