import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { TypeTeaser } from '../../types/teaser'
import { clone } from '../../../Utils/clone'

export const relationSubStatusOfActiveCostumer = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return SUB_STATUS_OF_ACTIVE_COSTUMER[cloneTeaser.subStatusOfActiveCostumer]

}
