/**
 * A função `getIdOwner` pega um objeto `TypeTeaser`, clona-o e retorna
 * a propriedade `uid` do objeto clonado.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um objeto que representa um teaser. A função `getIdOwner` leva isso
 * objeto teaser, clona-o usando a função `clone` do caminho especificado e
 * então retorna a propriedade `uid` do objeto teaser clonado.
 * @returns A função `getIdOwner` está retornando a propriedade `uid` do objeto clonado
 * objeto `teaser`.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const getIdOwner = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.uid

}
