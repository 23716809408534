import { useContextAuth } from 'App/ContextAuth'
import { CardLine } from 'Components/CardLine'
import { UiModal } from 'ComponentsUi/UiModal'
import { fnUser } from 'Functions/User'
import { useState } from 'react'
import { db } from 'Services/db'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { UiButton } from '../../ComponentsUi/UiButton'
import { UiCheckBox } from '../../ComponentsUi/UiCheckBox'
import { db5 } from '../../Services/db5'
import { helpersDate } from '../../Functions/Utils/helpersDate'

type TGlobalContext = { globalAuth: { uid: string, profile: TypeUser, auth: string | undefined } }

export const ModalForContractApproval = () => {

	const [visible, setVisible] = useState(true)
	// const [acceptedTerm3, setAcceptedTerm1] = useState(false)
	// const [acceptedTerm4, setAcceptedTerm2] = useState(false)
	const [acceptedTerm3, setAcceptedTerm3] = useState(false)
	const [acceptedTerm4, setAcceptedTerm4] = useState(false)

	const global = useContextAuth() as { globalAuth: { uid: string, profile: TypeUser, auth: string | undefined } }
	const uid = global?.globalAuth.uid
	const globalAuth = global?.globalAuth
	const profile = globalAuth?.profile

	const logado = !!profile && fnUser.isAgent(profile)
	const accepted = !!profile?.acceptedTerm3 && !!profile?.acceptedTerm4
	const enabled = !!acceptedTerm3 && !!acceptedTerm4

	const handleAccept = () => {
		if (uid && enabled) {
			db5.users.update({
				id: uid,
				doc: {
					acceptedTerm3: helpersDate.newDate(),
					acceptedTerm4: helpersDate.newDate()
				}
			})
		}
	}

	const changeAcceptedTerm1 = () => {
		acceptedTerm3 ? setAcceptedTerm3(false) : setAcceptedTerm3(true)
	}

	const changeAcceptedTerm2 = () => {
		acceptedTerm4 ? setAcceptedTerm4(false) : setAcceptedTerm4(true)
	}

	return (
		<>
			{(logado && !accepted) && (
				// <Modal
				// 	dialogClassName='modal-md d-flex justify-center items-center'
				// 	visible={visible}
				// // onClickBackdrop={() => actions.setVisible(false)}
				// >
				<UiModal
					width='medium'
					show={visible}
				// onClickBackdrop={() => actions.setVisible(false)}
				>
					<div className='p-3 d-flex gap align-items-center'>
						<div className='bg-color-blue-100 border-radius-2 p-2' style={{ lineHeight: 0 }}>
							<i className='material-icons color-blue-650'>description</i>
						</div>
						<div className='font-weight-bold'>
							Novo Contrato de Agente Stark
						</div>
					</div>

					<CardLine />
					<div className='p-3'>
						<div className='tw-w-full tw-h-[400px] b-1 border-color-neutral-400 border-radius-2 p-2' style={{ overflow: 'hidden', overflowY: 'scroll' }} >

							<div className='text-center font-weight-bold'>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ORIGINAÇÃO DE NOVOS NEGÓCIOS</div>

							<br />Este CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ORIGINAÇÃO DE NOVOS NEGÓCIOS constitui um acordo entre STARK INVESTMENT BANKING S.A. (“STARK”) como proprietária e administradora do website hospedado no domínio e subdomínios de https://stark.money/ e https://app.stark.money/, (“Website” ou “Plataforma”) e os Agente de Negócios Stark (“Agentes Stark”), também denominados para fins destes termos, (“AGENTE” ou “Usuário”) do Website e/ou da Plataforma.
							<br />
							<br />A STARK pode ser identificada e localizada da seguinte forma: STARK INVESTMENT BANKING S.A., inscrita no CNPJ sob nº 21.398.549/0001-56, com sede Rua Fidêncio Ramos, 160, Cj. 706/707, Vila Olímpia, Cidade de São Paulo, Estado de São Paulo, CEP: 04551-010, representada de acordo com seu Contrato Social por seu sócio administrador JOÃO VITOR CARMINATTI OLIVEIRA, brasileiro, solteiro, economista, portador da cédula de identidade n.º 4.352.845 e CPF/MF sob o n.º 071.371.169-81, e-mail: jv.carminatti@stark.money
							<br />
							<br />Sendo STARK e AGENTE, individualmente denominados “Parte” e conjuntamente denominados “Partes”, resolvem celebrar o presente CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ORIGINAÇÃO DE NOVOS NEGÓCIOS (“Contrato de Agente Stark” ou “Contrato”), que será regido pelos seguintes termos e condições:
							<br />
							<br />

							<div className='font-weight-bold'>1. OBJETO</div>

							<br />1.1. O presente Contrato tem por objeto o estabelecimento de vínculo não exclusivo entre as Partes com a finalidade de que o AGENTE possa, por si ou através seus representantes, realizar Originações de novos negócios, mediante o fornecimento de informações de possíveis clientes via página web: https://app.stark.money/ (a seguir denominado "Plataforma" ou “Plataforma STARK”), bem como a realização de intermediação com os representantes dos Clientes Originados que tenham interesse em contratar o produto Stark Match da STARK.
							<br />
							<br />1.2. O presente Contrato se desenvolverá de forma autônoma e não exclusiva entre as Partes, não constituindo nenhuma obrigação de exclusividade ou dever de preferência do AGENTE em favor da STARK, que não é obrigado a realizar Originações de novos negócios à STARK por ser livre e autônomo na condução de sua atividade. Caso o AGENTE autonomamente decida realizar Originações de novos negócios (Clientes) à STARK, este será remunerado de acordo com os termos deste Contrato, cumpridas as condições estabelecidas.
							<br />
							<br />

							<div className='font-weight-bold'>2. ORIGINAÇÃO DE NOVOS NEGÓCIOS (CLIENTES)</div>

							<br />2.1. Para a realização das Originações de novos negócios, o AGENTE terá uma conta na Plataforma STARK. Para liberação do acesso o AGENTE deverá estar de acordo com o presente Contrato, com os Termos de Uso e Aviso de Privacidade e/ou outros termos a serem disponibilizados na página web: https://app.stark.money/. Todo o fluxo de Originação e acompanhamento acontecerá dentro da Plataforma STARK.
							<br />
							<br />Parágrafo único. Todas as Originações validamente realizadas através da conta do AGENTE na Plataforma STARK, serão tratadas como Originações realizadas exclusivamente pelo AGENTE aqui
							<br />
							<br />contratante. As Partes concordam que a STARK somente se responsabilizará pela satisfação de qualquer obrigação unicamente perante ao AGENTE, sendo assim, caso outras pessoas utilizem a conta do AGENTE para realizar uma Originação, esta será tratada como uma Originação realizada pelo AGENTE devidamente contratado.
							<br />Iniciando uma Originação
							<br />
							<br />2.2. Quando da realização de uma Originação, o AGENTE deverá preencher determinadas informações sobre o Cliente que deseja apresentar à STARK. As informações a serem preenchidas dizem respeito à identificação, características da atividade empresarial desenvolvida, aspectos financeiros, e outros, do Cliente Originado. Ao finalizar o preenchimento das informações solicitadas, o formulário de originação poderá ser publicado/enviado para a STARK.
							<br />
							<br />2.2.1. O AGENTE deverá preencher adequadamente as informações solicitadas sobre o Cliente, sendo que as informações fornecidas pelo AGENTE deverão ser verdadeiras e coletadas diretamente com os representantes do Cliente Originado com quem tenha contato, mediante sua ciência e autorização.
							<br />
							<br />2.2.2. Dentro do processo de Originação será garantido ao AGENTE um prazo de 30 (trinta) dias corridos contados do início da Originação para que este publique/envie, para a STARK, as informações solicitadas no formulário de Originação (“Prazo de Publicação”). Dentro do Prazo de Publicação de 30 (trinta) dias corridos, fica resguardado ao AGENTE os direitos sobre aquela Originação iniciada, sendo assim, quaisquer outros AGENTES ficam impossibilitados de realizar a publicação/envio durante o Prazo de Publicação. Após o transcurso do Prazo de Publicação, o Cliente cuja Originação foi iniciada, mas não publicada/enviada, passa a ser um Cliente livre, e outro AGENTE poderá iniciar o processo de Originação até sua publicação/envio.
							<br />
							<br />Comitê de Originação
							<br />
							<br />2.3. Realizada a publicação/envio da Originação pelo AGENTE, a STARK receberá as informações sobre o Cliente que será submetida ao Comitê de Originação que tem como objetivo analisar a Originação bem como conferir as informações fornecidas pelo AGENTE. Realizadas as análises do Comitê de Originação, a STARK poderá: i) Aceitar a Originação; ii) Rejeitar a Originação; ou iii) Solicitar Ajustes na Originação.
							<br />
							<br />i) Aceitar a Originação (Registro da Oportunidade): A STARK aceitará a Originação realizada pelo AGENTE, registrando esta em seu nome para todos os efeitos deste Contrato, quando conjuntamente: a) o Cliente apresentado (Originado) não esteja configurado como uma Cliente Restrito, nos termos da cláusula 2.8.1.; e b) se as informações fornecidas estiverem adequadas e sem quaisquer defeitos que prejudiquem a avaliação pelo Comitê de Originação a critério da STARK;
							<br />
							<br />ii) Rejeitar a Originação: A STARK rejeitará a Originação realizada pelo AGENTE quando verificar que o Cliente Originado é configurado como uma Cliente Restrito, nos termos da cláusula 2.8.1.;
							<br />
							<br />iii) Solicitar Ajustes na Originação: A STARK solicitará ajustes nas informações fornecidas pelo AGENTE quando estas, a critério da STARK, contiverem defeitos que prejudiquem a avaliação pelo Comitê de Originação.
							<br />
							<br />Aceitação da Originação
							<br />
							<br />2.4. Sendo a Originação aceita pela STARK, esta será registrada em nome do AGENTE, que passará a ter a exclusividade perante a STARK nos termos deste Contrato. Em função da
							<br />
							<br />
							<br />Exclusividade outorgada, o Cliente Originado e aceito, passará a ser uma Cliente Restrito para os demais AGENTES, garantindo o direito do AGENTE Originador, e impossibilitando outro AGENTE de apresentar o mesmo Cliente pelo prazo de exclusividade da Originação.
							<br />
							<br />2.5. Após a avaliação do Comitê de Originação a STARK decidirá, a seu exclusivo critério, se deseja: a) iniciar uma Fase Comercial com o Cliente Originado pelo AGENTE, considerando a execução dos atos comerciais, incluindo exemplificativamente a realização da Reunião de Validação, apresentação de proposta de prestação de serviços e realização das demais interações e atos comerciais visando à contratação de seus serviços, recebendo esta Originação o status de Fase Comercial; ou b) Não iniciar uma Fase Comercial, recebendo esta Originação o status de Stand-by.
							<br />Aceitação da Originação: Fase Comercial
							<br />
							<br />2.6. Caso a STARK aceite a Originação realizada pelo AGENTE e a seu exclusivo critério decida iniciar uma tratativa comercial com os representantes do Cliente Originado visando à prestação de seus serviços, esta Originação passará a ter o status de “Fase Comercial”, sendo garantido ao AGENTE um prazo de Exclusividade de 6 (seis) meses contados da data em que for disponibilizada a informação da decisão do Comitê de Originação que colocou o status de Fase Comercial na Originação. A informação sobre o status da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim.
							<br />
							<br />2.6.1. Igualmente, a Originação anteriormente aceita com o status de Stand-by que a STARK decidir por dar início a uma tratativa comercial, nos termos da cláusula 2.7.1., passará a ter o status de “Fase Comercial” sendo garantido ao AGENTE um prazo de Exclusividade de 6 (seis) meses contados da data em que for disponibilizada a informação da decisão da STARK que colocou o status de Fase Comercial na Originação. A informação sobre o status da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim.
							<br />
							<br />2.6.2. Dentro do prazo de Exclusividade de 6 (seis) meses da Fase Comercial, o AGENTE, atuando livremente, poderá, sem obrigatoriedade, realizar a intermediação entre STARK e os representantes do Cliente Originado, visando aproximar e facilitar o entendimento relativo aos produtos e serviços oferecidos pela STARK, para que isso favoreça a contratação destes pelos representantes do Cliente Originado.
							<br />
							<br />2.6.3. Finalizado o prazo de Exclusividade da Fase Comercial sem que tenha sido assinado um Contrato de Stark Match entre Cliente Originado e STARK, a Originação será removida da interface do AGENTE na Plataforma STARK, e o Cliente Originado passará a ser considerado um Cliente Restrito da STARK, não havendo em se falar de eventuais futuros direitos do AGENTE sobre a Originação.
							<br />
							<br />2.6.4. Caso dentro dos últimos 30 (trinta) dias do prazo de Exclusividade da Fase Comercial for verificado que existem tratativas em andamento visando à assinatura do Contrato de Stark Match, o AGENTE poderá solicitar e/ou a STARK poderá conceder, a seu critério, prazos suplementares para a conclusão das tratativas.
							<br />
							<br />Aceitação da Originação: Stand-by
							<br />
							<br />2.7. Caso a STARK aceite a Originação realizada pelo AGENTE, no entanto, a seu exclusivo critério, decida não iniciar uma Fase Comercial com os representantes do Cliente Originado naquele momento, esta Originação passará a ter o status de “Stand-by”, sendo garantido ao
							<br />
							<br />AGENTE um prazo de Exclusividade de 6 (seis) meses contados da data em que for disponibilizada a informação da decisão do Comitê de Originação que colocou o status de Stand-by na Originação. A informação sobre o status da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim.
							<br />
							<br />2.7.1. Dentro do prazo de Exclusividade de 6 (seis) meses do status Stand-by, caso a STARK resolva iniciar uma tratativa comercial com os representantes do Cliente Originado visando a prestação de seus serviços, esta comunicará o AGENTE quanto a isso, e alterará o status da Originação para Fase Comercial, dando início às tratativas com os representantes do Cliente Originado sob a intermediação, ou não, do AGENTE, de acordo com sua vontade. Caso a STARK inicie uma Fase Comercial de uma Originação, anteriormente com o status de Stand-by, será observada a disposição contida na cláusula 2.6.1.
							<br />
							<br />2.7.2. Finalizado o prazo de Exclusividade do status Stand-by sem que tenha sido iniciada uma Fase Comercial e assinado um Contrato de Stark Match, a Originação será removida da interface do AGENTE na Plataforma STARK e o Cliente Originado passará a ser um Cliente Restrito da STARK, não havendo em se falar de eventuais futuros direitos do AGENTE sobre a Originação.
							<br />Rejeição da Originação
							<br />
							<br />2.8. Como regra, toda Originação de um Cliente é aceita pela STARK, cabendo ao Comitê de Originação à avaliação sobre o início da Fase Comercial ou colocação da Originação em Stand-by. Uma Originação somente será Rejeitada pela STARK caso o Cliente apresentado seja configurado como um Cliente Restrito ou na hipótese disposta na cláusula 2.9.2.
							<br />
							<br />2.8.1. Para efeitos deste Contrato, será entendida como um Cliente Restrito: a) o Cliente que já foi, anteriormente, Originado por outro AGENTE e que esteja sob seu prazo de Exclusividade; b) o Cliente que já foi anteriormente Originado por outro AGENTE, porém que seu prazo de Exclusividade já tenha expirado; ou c) o Cliente com a qual a STARK já mantenha um relacionamento prévio, independente de Originação de qualquer AGENTE; sendo que:
							<br />
							<br />a) Caso um Cliente já houver sido validamente Originado por outro AGENTE e aceito pela STARK, o AGENTE que Originou validamente o Cliente terá sua Exclusividade, sendo assim, este Cliente será considerado um Cliente Restrito para quaisquer novas Originações pelo prazo de Exclusividade a que outro AGENTE tiver direito.
							<br />
							<br />b) Caso um Cliente já houver sido validamente Originado por outro AGENTE, aceito pela STARK, e o prazo de Exclusividade do outro AGENTE tenham expirado, o Cliente será considerada um Cliente Restrito da STARK por prazo indeterminado;
							<br />
							<br />c) Caso um Cliente, independente de Originação prévia de qualquer AGENTE, for de relacionamento prévio da STARK, o Cliente será considerado um Cliente Restrito da STARK por prazo indeterminado. O relacionamento prévio será verificado para a configuração da situação restritiva, se previamente à Originação realizada pelo AGENTE, a STARK já: i) possua ou tenha possuído qualquer acordo firmado, em especial de prestação de serviços; ou ii) tenha iniciado quaisquer atos comerciais visando alguma contratação; ou iii) tenha listado, a seu critério, o Cliente como um Cliente Restrito.
							<br />
							<br />2.8.2. A situação restritiva se estenderá a todo o negócio do Cliente Restrito, sendo assim, caso o negócio ou atividade desenvolvida pelo Cliente Restrito possua mais de um CNPJ (Cadastro Nacional de Pessoa Jurídica), sites ou até mesmo nomes empresariais diversos, todos serão considerados o mesmo negócio, e por tanto, o mesmo Cliente Restrito. Caso o Cliente Restrito não possua um CNPJ (Cadastro Nacional de Pessoa Jurídica), site ou até mesmo nomes
							<br />fantasias que caracterizem seu negócio, outras informações, como CPF (cadastro de pessoa física), RG (Registro Geral), nome pessoal dentre outras poderão ser levadas em consideração para a visualização da situação restritiva.
							<br />
							<br />2.8.3. A Informação quanto a Rejeição da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim, e poderá ocorrer de duas formas: a) antecipada, com o bloqueio da publicação/envio da Originação ao Comitê de Originação; ou b) posterior, após a análise em Comitê de Originação e verificação da situação restritiva.
							<br />
							<br />a) Em regime de melhores esforços, todo Cliente Restrito fará parte de uma lista de Clientes Restritos, onde informações aceca da identificação destes, serão elencadas. Caso qualquer AGENTE tente realizar a publicação/envio da Originação de um Cliente Restrito componente da lista de restrição, imediatamente haverá o bloqueio da publicação/envio da Originação e sua Rejeição antecipada.
							<br />
							<br />b) Caso, mesmo o Cliente sendo considerado um Cliente Restrito, porém este não conste na lista de restrição, ou, os dados que constem sejam diferentes dos dados preenchidos pelo AGENTE Originador, a STARK se resguarda o direito de analisar a situação restritiva do Cliente Restrito após a publicação/envio, e realizar a Rejeição posterior.
							<br />
							<br />2.8.4. Realizada a Rejeição antecipada com bloqueio da publicação/envio da Originação, ou a Rejeição posterior, a Originação será removida da interface do AGENTE na Plataforma STARK, não havendo em se falar de eventuais futuros direitos do AGENTE sobre a Originação Rejeitada.
							<br />
							<br />Solicitação de Ajustes no Teaser
							<br />
							<br />2.9. Dentro do processo de Originação serão solicitadas determinadas informações acerca do Cliente Originado que serão avaliadas pelo Comitê de Originação. Dentro do Comitê de Originação, caso a STARK avalie, a seu critério, que as informações preenchidas pelo AGENTE não são suficientes ou contenham algum defeito que prejudique sua análise, a STARK poderá Solicitar Ajustes ao AGENTE, que terá um prazo de 15 (quinze) dias para realizar os ajustes solicitados (Prazo de Ajustes) e novamente publicar/enviar a Originação para a STARK para nova avaliação em Comitê de Originação.
							<br />
							<br />2.9.1. Dentro do Prazo de Ajustes de 15 (quinze) dias fica resguardado ao AGENTE os direitos sobre aquela Originação iniciada, sendo assim, quaisquer outros AGENTES ficam impossibilitados de realizar a publicação/envio durante o Prazo de Ajustes. Após o transcurso do Prazo de Ajustes o Cliente passa a ser um Cliente livre e outro AGENTE pode realizar sua publicação/envio.
							<br />
							<br />2.9.2. Caso após o AGENTE realizar os ajustes solicitados, publicando/enviando novamente a Originação para a STARK; e esta, a seu critério, ainda julgar que as informações ajustadas não são suficientes ou ainda contenha algum defeito que prejudique sua análise, a seu critério, a STARK poderá: a) Solicitar Ajustes novamente, sendo aberto novo Prazo de Ajustes de 15 (quinze); ou b) Rejeitar a Originação.
							<br />
							<br />

							<div className='font-weight-bold'>3. REMUNERAÇÃO DO AGENTE</div>

							<br />Comissão pela Originação validada
							<br />
							<br />3.1. O AGENTE será remunerado no valor de R$ 250,00 (duzentos e cinquenta reais) por cada uma das Originações realizadas e efetivamente validadas pela STARK (“Comissão pela Originação”). Cumpridas as condições estabelecidas na cláusula 3.13. para o recebimento da Comissão pela Originação validada, o valor respectivo à cada uma das reuniões de validação realizadas será disponibilizado para o AGENTE que poderá, nos termos da cláusula 3.10., solicitar o recebimento mediante o envio das respectivas Notas Fiscais de Serviços.
							<br />
							<br />3.2. Para que uma Originação seja efetivamente validada pela STARK, e o AGENTE tenha direito ao recebimento da Comissão pela Originação, este, além de ter uma Originação validamente realizada e aprovada para Fase Comercial na Plataforma Stark, deverá, efetivamente, atuar para promover e fazer com que efetivamente ocorra uma Reunião de Validação entre um Representante da STARK junto à Representantes do Cliente Originado dentro de um prazo de 30 (trinta) dias contados do início da Fase Comercial.
							<br />
							<br />Parágrafo primeiro. A Reunião de Validação será entendida como efetivamente realizada caso esta ocorra de forma presencial ou mediante vídeo/teleconferência, necessariamente com a participação de um Representante da STARK e Representantes do Cliente Originado.
							<br />
							<br />Parágrafo segundo. Para efeitos da verificação da realização de uma Reunião de Validação, os Representantes do Cliente Originado que deverão participar da Reunião de Validação são aqueles diretamente vinculados ao Cliente Originado e que tenham poder de decisão/encaminhamento sobre a contratação do produto Stark Match. Caso a STARK entenda que os Representantes do Cliente Originado não possuem vinculação e/ou poder de decisão/encaminhamento sobre a contratação do produto Stark Match, a seu critério, a STARK poderá se recusar a participar da Reunião de Validação, ou, caso participe, não reconhecer a Reunião de Validação como efetivamente realizada.
							<br />
							<br />Parágrafo terceiro. Outrossim, para efeitos da verificação da realização de uma Reunião de Validação, é necessário que não haja relevante divergência sobre os dados e informações oferecidos quando da publicação/envio da Originação pelo AGENTE. Sendo assim, caso a STARK entenda, a seu critério, que, após a Reunião de Validação, os dados e informações sobre o Cliente Originado continham relevantes divergências que seriam, à época da publicação/envio da Originação, capazes de alterar o resultado do Comitê de Originações, a STARK poderá não reconhecer a Reunião de Validação como efetivamente realizada.
							<br />
							<br />Parágrafo quarto. Realizada uma Originação válida pelo AGENTE e após a STARK decidir por iniciar uma Fase Comercial, caso o AGENTE, dentro do prazo de 30 (trinta) dias corridos e por qualquer motivo, não promover a Reunião de Validação, fica resguardado à STARK o direito de acessar autonomamente os representantes do Cliente Originado com objetivo de dar andamento às tratativas comerciais, não havendo de ser falar, neste caso, em direito ao recebimento da Comissão pela Originação pelo AGENTE. Todos os demais direitos sobre a Originação serão integralmente mantidos nos termos deste Contrato.
							<br />
							<br />Parágrafo quinto. Caso, dento do prazo de 30 (trinta) dias corridos, a STARK, sem justa causa, se recusar a participar de uma Reunião de Validação efetivamente agendada pelo AGENTE com Representantes do Cliente Originado, e após isso, à revelia do AGENTE, havendo ou não Reunião de Validação, efetivamente assinar um Contrato de Stark Match com o Cliente Originado, fica obrigada a STARK a pagar a Comissão pela Originação para o AGENTE. Caso a recusa da participação da STARK seja por justa causa, sendo esta, neste caso, entendida como a verificação da incapacidade de decisão/encaminhamento sobre a contratação do Stark Match pelos Representantes do Cliente Originado, não haverá de se falar em pagamento de Comissão pela Originação caso a Reunião de Validação ou outro ato comercial sejam realizados autonomamente pela STARK após o prazo de 30 (trinta) dias conferidos ao AGENTE.
							<br />
							<br />Bônus de Assinatura de Contrato
							<br />
							<br />3.3. Dentro do prazo de 6 (seis) meses de exclusividade conferido ao AGENTE, caso Representantes da STARK e Representantes do Cliente Originado cheguem a um acordo quanto à prestação de serviços e efetivamente assinem um ou mais Contratos de Stark Match, estes serão denominados de “Clientes Ativos” ou “Contratantes”, e o AGENTE será remunerado no valor de R$ 250,00 (duzentos e cinquenta reais) por cada um dos Clientes Originados que efetivamente contratarem o produto Stark Match (“Bônus de Assinatura de Contrato”). Cumpridas as condições estabelecidas na cláusula 3.13. para o recebimento do Bônus de Assinatura de Contrato, o valor respectivo à cada um dos Contratos de Stark Match assinados será disponibilizado para o AGENTE que poderá, nos termos da cláusula 3.10., solicitar o recebimento mediante o envio das respectivas Notas Fiscais de Serviços.
							<br />
							<br />Comissão por Cliente Ativo
							<br />
							<br />3.4. Após a assinatura de um ou mais Contratos de Stark Match com um Cliente Originado, a STARK ou o respectivo Stark Office, iniciará sua prestação de serviços. Dentro de sua prestação de serviços, caso e quando seja verificado o efetivo pagamento da primeira parcela à título de Assinatura Mensal, paga pelo Cliente Ativo, a STARK iniciará o pagamento do valor total de R$ 4.000,00 (quatro mil reais) para o AGENTE em parcelas mensais, iguais e sucessivas de R$ 400,00 (quatrocentos reais) (“Comissão por Cliente Ativo”). Sendo assim, cumpridas as condições estabelecidas na cláusula 3.13. para o recebimento da Comissão por Cliente Ativo, o valor de cada uma das parcelas será disponibilizado para o AGENTE que poderá, nos termos da cláusula 3.10., solicitar o recebimento mediante o envio das respectivas Notas Fiscais de Serviços.
							<br />
							<br />Parágrafo único. Sendo o gatilho para o início dos pagamentos das parcelas à título de Comissão por Cliente Ativo é o efetivo pagamento, pelo Cliente Ativo, da primeira parcela da Assinatura Mensal em favor da STARK ou do respectivo Stark Office, fica acordado entre as Partes que, caso o Cliente Ativo, por qualquer motivo não inicie o pagamento da Assinatura Mensal, a STARK não iniciará os pagamentos da Comissão por Cliente Ativo em favor do AGENTE.
							<br />
							<br />Comissão de Sucesso (Success Fee)
							<br />
							<br />3.5. O AGENTE será remunerado via comissão de sucesso (“Success Fee AGENTE”) em percentual aplicado sobre o Valor líquido da comissão de sucesso efetivamente paga pelo Cliente Ativo em favor da STARK ou do respectivo Stark Office, em função da prestação de seus serviços (“Success Fee STARK”). O percentual de Success Fee AGENTE será de 20% (vinte por cento) sobre o Valor líquido do Success Fee STARK efetivamente pago pelo Cliente Ativo. Sendo assim, cumpridas as condições estabelecidas na cláusula 3.13. para o recebimento do Success Fee AGENTE, o valor
							<br />respectivo será disponibilizado para o AGENTE que poderá, nos termos da cláusula 3.10., solicitar o recebimento mediante o envio das respectivas Notas Fiscais de Serviços.
							<br />Parágrafo único. Sendo o gatilho para o pagamento da remuneração à título de Success Fee AGENTE é o efetivo pagamento, pelo Cliente Ativo, do Success Fee STARK em favor da STARK ou do respectivo Stark Office, fica acordado entre as Partes que, caso o Cliente Ativo, por qualquer motivo não pague o Success Fee STARK, a STARK não repesará o percentual ajustado para o pagamento da remuneração à título de Success Fee AGENTE em favor do AGENTE.
							<br />
							<br />3.6. A STARK envidará seus melhores esforços conjuntamente ao AGENTE no sentido de conseguir a melhor negociação comercial tendo em vista sua estratégia. No entanto, a depender das peculiaridades de cada projeto e de cada negociação, e tendo em vista a estratégia na condução de seus negócios, a STARK, ou o respectivo Stark Office, autonomamente, poderá alterar, majorar, minorar ou suprimir, de qualquer forma dispondo acerca da forma, prazos e valores de quaisquer de suas remunerações.
							<br />
							<br />Campanhas
							<br />
							<br />3.7. Dentro da Política de Canais vigente, a STARK poderá conceder bonificações, premiações, bem como incremento de valores e/ou percentuais adicionais às remunerações previstas neste contrato (“Campanhas”), de acordo a performance do AGENTE, características quantitativas e/ou qualitativas dos Clientes Originados ou outros critérios a serem dispostos na Política de Canais. Todo o regramento sobre as Campanhas será disposto na Política de Canais, sem prejuízo de outra forma de comunicação.
							<br />
							<br />Forma de Pagamento do AGENTE
							<br />
							<br />3.8. O AGENTE terá direito ao recebimento de cada um dos tipos de suas respectivas comissões, cumpridas as condições estabelecidas na cláusula 3.13.
							<br />
							<br />Apuração, comunicação, solicitação e pagamento dos Valores Disponíveis
							<br />
							<br />3.9. A STARK realizará mensalmente a apuração e fechamento dos valores que o AGENTE tem a receber (“Valores Disponíveis”). A apuração será realizada no primeiro dia útil de cada mês e levará em consideração todos os gatilhos de cada tipo de remuneração efetivamente verificados até o último dia do mês anterior.
							<br />
							<br />3.10. Realizada a apuração e fechamento dos Valores Disponíveis, o AGENTE será comunicado até o quinto dia útil do mês, via e-mail, quanto ao exato valor que tem direito de receber. Após o envio da informação sobre o valor que tem a receber, o AGENTE terá até o dia 09 (nove) do respectivo mês apurado para solicitar o pagamento dos valores disponíveis, necessariamente, com o envio da competente Nota Fiscal de Serviços no exato valor informado disponível para pagamento. Os pagamentos dos Valores Disponíveis solicitados, mediante o envio da Nota Fiscal de serviços até os dias 09 (nove) de cada mês, serão realizados até o dia 15 (quinze) de cada mês.
							<br />
							<br />Parágrafo primeiro. Para que a STARK efetivamente realize o pagamento dos Valores Disponíveis, o AGENTE deverá emitir a respectiva Nota Fiscal de Serviços e encaminhar para o e-mail: financeiro@stark.money até as 23:59 (vinte três horas e cinquenta e nove minutos) dos dias 09 (nove) de cada mês. Os pagamentos solicitados, mediante envio da respectiva Nota Fiscal de Serviço, serão processados e efetivamente pagos ao AGENTE até o dia 15 (quinze) de cada mês.
							<br />
							<br />Parágrafo segundo. Caso o AGENTE não envie a respectiva Nota Fiscal de Serviços até as 23:59 (vinte três horas e cinquenta e nove minutos) do dia 09 (nove) do mês apurado, o valor permanecerá disponível podendo ser solicitado para pagamento no próximo mês.
							<br />
							<br />3.11. As Notas Fiscais de Serviços para pagamento da remuneração deverão ser encaminhadas pelo AGENTE à STARK, no exato valor informado e dentro do prazo de solicitação, sendo que a STARK que deverá realizar os pagamentos até o dia 15 (quinze) de cada mês. Caso haja atraso no pagamento dos valores devidamente solicitados incidirão sobre o montante principal em atraso: (i) correção monetária segundo o IPCA/FGV; e (ii) juros moratórios de 1% (um por cento) ao mês, calculados pro rata die.
							<br />
							<br />3.12. Caso seja constatado algum erro, a Nota Fiscal será devolvida sendo informado ao AGENTE que o respectivo pagamento será sustado até sua efetiva correção, sem que isso implique em atraso por parte da STARK.
							<br />
							<br />Condições para o Recebimento de Remunerações
							<br />
							<br />3.13. De acordo com este Contrato, somente haverá de se falar em direito de recebimento das remunerações aqui ajustadas, caso forem verificadas conjuntamente, nos termos do art. 121 do Código Civil, as seguintes condições para cada tipo de remuneração:
							<br />
							<br />Condições para o recebimento da Comissão pela Originação:
							<br />
							<br />i) O AGENTE tenha realizado validamente uma Originação com o preenchimento das informações solicitadas de forma completa e adequada, e tenha realizado a publicação/envio das informações através da Plataforma STARK, nos termos da cláusula 2.2.; e
							<br />
							<br />ii) A Originação tenha sido aceita pelo Comitê de Originação da STARK nos termos da cláusula 2.4.; e
							<br />
							<br />iii) A STARK decidir, a seu critério, por iniciar uma tratativa comercial com os Representantes do Cliente Originado visando à prestação de seus serviços (Fase Comercial) nos termos da cláusula 2.6.; e
							<br />
							<br />iv) O AGENTE ter efetivamente atuado para promover a Reunião de Validação entre os Representantes da STARK junto à Representantes do Cliente Originado nos termos da cláusula 3.1. e seguintes; e
							<br />
							<br />v) Efetivamente ter ocorrido a Reunião de Validação promovida pelo AGENTE entre os Representantes da STARK e Representantes do Cliente Originado nos termos da cláusula 3.1. e seguintes;
							<br />
							<br />Condições para o recebimento do Bônus de Assinatura de Contrato:
							<br />
							<br />vi) as condições “i”, “ii” e “iii” anteriormente mencionadas;
							<br />
							<br />vii) a STARK, ou o Stark Office respectivo, tenha assinado o Contrato de Stark Match com o Cliente Originado, bem como seus respectivos representantes (“Cliente Ativo”) nos termos da cláusula 3.3.
							<br />
							<br />Condições para o recebimento da Comissão por Cliente Ativo
							<br />
							<br />viii) as condições “i”, “ii”, “iii” e “vii” anteriormente mencionadas; e
							<br />
							<br />ix) A STARK, ou o Stark Office respectivo, tenha efetivamente recebido a primeira parcela à título de Assinatura Mensal paga pelo Cliente Ativo nos termos da cláusula 3.4.
							<br />
							<br />Condições para o recebimento da Comissão no Sucesso (Success Fee):
							<br />
							<br />x) as condições “i”, “ii”, “iii” e “vii” anteriormente mencionadas; e
							<br />
							<br />xi) A STARK, ou o Stark Office respectivo, tenha efetivamente recebido do Cliente Ativo valores a título de comissão de sucesso (“Success Fee STARK”) em função da prestação de seus serviços (“Success Fee STARK”) nos termos da cláusula 3.5.
							<br />
							<br />

							<div className='font-weight-bold'>4. VALIDADE E RESCISÃO</div>

							<br />4.1. O presente Contrato de Agente Stark vigorará pelo prazo determinado de 24 (vinte e quatro) meses, sendo que, qualquer das Partes, a qualquer tempo e mediante envio prévio de comunicação, com antecedência mínima de 10 (dez) dias, poderá resilir unilateralmente o presente acordo.
							<br />
							<br />4.1.1. A comunicação acima referida operar-se-á mediante envio de e-mail, notificando a contraparte que dentro do prazo de 10 (dez) dias, o presente Contrato restará resilido.
							<br />
							<br />4.2. A infração cometida por uma das Partes contratantes a quaisquer cláusulas e condições incluídas neste Contrato de Agente Stark dará direito à outra parte de rescindi-lo imediata e unilateralmente, ficando totalmente sob a responsabilidade da parte infratora as indenizações e perdas e danos decorrentes, nos termos do art. 475 do código civil.
							<br />
							<br />4.3. Mesmo que operada a extinção do presente vínculo, seja mediante resilição ou rescisão, e tendo havido a Aceitação na Originação ou contratação por um Cliente Ativo, tanto o direito do AGENTE relativo à Exclusividade, quanto os relativos à eventual remuneração proveniente dos Clientes Ativos, permanecerão válidas pelos prazos respectivos, salvo a rescisão tenha sido motivada por conduta danosa realizada pelo AGENTE.
							<br />
							<br />4.4. As Partes poderão de comum acordo, decidir pela manutenção deste Contrato, ainda que o descumprimento contratual decorra de ato culposo ou doloso praticado por qualquer uma delas.
							<br />
							<br />4.5. Caso reste verificada conduta danosa ou desabonadora por parte do AGENTE na relação entre STARK e representantes da Cliente Originado, ou referente à STARK perante o mercado, que de qualquer forma possa prejudicar a imagem, relacionamento ou a prestação de serviços, a critério da STARK o presente Contrato poderá ser rescindido, perdendo o AGENTE quaisquer direitos que detenha.
							<br />
							<br />4.6. A rescisão não acarretará, tanto à STARK quanto ao AGENTE, a obrigação de pagamento de quaisquer indenizações e reparações em decorrência de eventuais investimentos ou despesas que as Partes tenham incorrido para a consecução do objeto deste Contrato.
							<br />
							<br />

							<div className='font-weight-bold'>5. SIGILO E CONFIDENCIALIDADE</div>

							<br />5.1. Todas as informações a que as Partes tiverem acesso em razão do desenvolvimento do objeto do preste Contrato de AGENTE são e serão consideradas como Informações Confidenciais durante todo o período de vigência deste Contrato por 2 (dois) anos contados da sua extinção.
							<br />
							<br />5.2. As Partes, por si e seus representantes, obrigam-se a não divulgar, revelar, reproduzir, utilizar ou dar conhecimento a terceiros cujo este conhecimento não seja necessário à consecução do objeto deste Contrato, mantendo sob o mais absoluto sigilo todas as Informações Confidenciais do AGENTE, da STARK e dos Clientes Originados.
							<br />
							<br />5.3. Não serão consideradas como descumprimento do Contrato as seguintes hipóteses: a) Se quaisquer das Informações Confidenciais já haviam sido divulgadas anteriormente à celebração deste Contrato; b) Houver prévia e expressa anuência da parte reveladora quanto à liberação da obrigação de sigilo e confidencialidade de quaisquer Informações Confidenciais; c) A informação tenha, comprovadamente, sido obtida por outra fonte, de forma legal e legítima, independentemente do presente Contrato; d) Se houver determinação judicial e/ou governamental, de fornecimento de quaisquer Informações Confidenciais, devendo a parte reveladora notificar a outra parte previamente à liberação das referidas Informações Confidenciais, bem como requerer segredo de justiça no seu trato judicial e/ou administrativo.
							<br />
							<br />

							<div className='font-weight-bold'>6. OBSERVÂNCIA DAS PRÁTICAS DE ANTICORRUPÇÃO</div>
							<br />6.1. O AGENTE declara por si e seus sócios, administradores, empregados, prepostos ou quaisquer pessoas agindo em seu nome ou interesse, que jamais praticou, e se obriga, durante a vigência deste Contrato, a não praticar quaisquer atos que violem as leis anticorrupção aplicáveis. Da mesma forma a STARK, declara por si e seus sócios, administradores, empregados, prepostos ou quaisquer pessoas agindo em seu nome ou interesse que jamais praticou, e se obriga, durante a vigência deste Contrato, a não praticar quaisquer atos que violem as leis anticorrupção aplicáveis.
							<br />
							<br />6.2. A obrigação e declaração aqui exposta se aplicam à conduta das Partes em suas relações com terceiros e com a administração pública, nacional ou estrangeira, seus agentes, pessoas jurídicas controladas, direta ou indiretamente pelo poder público.
							<br />
							<br />6.3. Independentemente de quaisquer investigações ou processos terem sido iniciados pelas autoridades públicas competentes, caso surjam denúncias ou indícios razoáveis de que quaisquer das Partes, por si ou seus representantes, violou qualquer lei anticorrupção ou disposição contratual anticorrupção, ou caso os tenha comprovadamente violado, terá a outra Parte direito de rescindir este Contrato por justa causa, sem prejuízo de obterem reparação integral por perdas e danos, inclusive por quaisquer multas, tributos, juros, despesas e custos incorridos em conexão com a investigação de irregularidades ou defesa, diante de quaisquer acusações ou processos relacionados à violação da legislação.
							<br />
							<br />

							<div className='font-weight-bold'>7. OBSERVÂNCIA DAS PRÁTICAS TRABALHISTAS</div>

							<br />7.1. O AGENTE desenvolverá o objeto deste Contrato por meio de seus sócios, empregados, prepostos ou consultores contratados, sendo o único e exclusivo responsável pelo pagamento de remunerações, comissões, salários, contribuições previdenciárias e fundiárias e demais benefícios e encargos trabalhistas devidos às pessoas que vier a contratar e tenha qualquer relação com o presente Contrato, descabendo, por conseguinte, a imputação de quaisquer dessas obrigações à STARK.
							<br />
							<br />7.2. O AGENTE compromete-se a fiscalizar e dirigir, diretamente, seus sócios, empregados, prepostos ou consultores contratados ou qualquer pessoa designados para a execução do presente Contrato, a fim de que todas as obrigações de sua responsabilidade sejam perfeitamente cumpridas, sempre respeitando os ditames da boa-fé e ética inerentes às atividades desenvolvidas, adotando medidas corretivas eventualmente necessárias e eficazes.
							<br />
							<br />7.3. Em caso de propositura de reclamação trabalhista por qualquer funcionário, preposto ou consultor contratado e/ou sócio do AGENTE, visando o reconhecimento de vínculo empregatício com a STARK, e/ou o pagamento de verbas trabalhistas, o AGENTE ficará obrigada reembolsar a STARK, por todo e qualquer valor por ela despendido, incluindo, mas não se limitando a, honorários advocatícios, custas processuais e valor integral de eventual condenação, mediante simples solicitação da STARK, sob pena de, além das verbas de reembolso, responder por perdas e danos a serem oportunamente apurados.
							<br />
							<br />7.4. As partes reconhecem expressamente que a relação entre elas é de fornecedor e adquirente de serviços (Originação de novos negócios) e não poderá ser considerada como empregado/empregador, outorgante/outorgado, “joint venture”, parceria ou qualquer outra forma de associação. O AGENTE não poderá, em nenhuma hipótese, celebrar, negociar modificar, aditar ou estabelecer qualquer termo contratual com qualquer pessoa com a intenção de obrigar, representar ou agir em nome da STARK, e a STARK não será responsável por qualquer contrato celebrado pelo AGENTE em violação ao presente instrumento.
							<br />
							<br />

							<div className='font-weight-bold'>8. OUTRAS DISPOSIÇÕES</div>

							<br />8.1. O presente acordo não implica a concessão, pelas Partes, de nenhuma licença ou qualquer outro direito, explícito ou implícito, em relação a qualquer direito legal, direito de patente, direito de edição, direito de cópia, ou qualquer outro direito relativo à propriedade intelectual, bem como direito à representação.
							<br />
							<br />8.2. O AGENTE permitirá a fiscalização, por parte da STARK, de ocorrências relativas aos serviços realizados para a execução deste ajuste de vontades.
							<br />
							<br />8.3. Não existe qualquer coligação, consórcio ou outro vínculo societário entre as Partes constantes neste Contrato, sendo AGENTE e STARK são sujeitos de direito distintos e independentes. Portanto, ambas responderão individual e isoladamente, sem qualquer solidariedade ou subsidiariedade, pelas obrigações que contraírem durante a validade do presente Contrato.
							<br />
							<br />8.4. Quaisquer despesas e investimentos em que eventualmente as Partes incorram, quando do desenvolvimento da presente Contrato, serão inteiramente de suportados e de responsabilidade de cada Parte isoladamente, não havendo, de Parte a Parte, em se falar em nenhum tipo de restituição, sendo assim, as despesas necessárias ao exercício das atividades do AGENTE, incluindo, mas não se limitando à locomoção, combustível, hospedagem, telegramas, telefonemas, porte de postal, contratação de empregados ou de outras pessoas a qualquer título, bem como as despesas de eventuais prepostos, serão suportadas exclusivamente pelo AGENTE.
							<br />
							<br />8.5. O presente Contrato e/ou os direitos e obrigações oriundos deste Contrato não poderão ser cedidos e/ou transferidos, parcial ou integralmente, por qualquer das Partes, sendo considerada nula qualquer cessão ou transferência sem o prévio e expresso consentimento das outra Parte.
							<br />
							<br />8.6. Qualquer tolerância, exercício parcial ou concessão entre as Partes serão sempre considerados como mera liberalidade e não renúncia ou perda de direito, nem implicarão em novação, alteração, transigência, remissão, modificação ou redução dos direitos e obrigações daqui decorrentes.
							<br />
							<br />8.7. O AGENTE autoriza a STARK a fazer referência da presente contratação, utilizando seu nome, marca e logotipo para ações de marketing em seu site, rede sociais, apresentações institucionais e outros locais, sempre respeitando a confidencialidade de informações.
							<br />
							<br />8.8. As Partes afirmam que este documento pode ser assinado por meio eletrônico, sendo consideradas válidas as referidas assinaturas mediante o aceite na Plataforma STARK.
							<br />
							<br />8.9. Toda vez que a STARK fizer alguma alteração elas serão válidas, eficazes e vinculantes após: (1) serem divulgadas na Plataforma STARK; ou (2) serem enviadas por e-mail aos Usuários; e/ou (3) serem comunicadas de qualquer outra forma AGENTES.
							<br />
							<br />8.10. Este instrumento será regido e interpretado de acordo com as Leis da República Federativa do Brasil.
							<br />
							<br />8.11. As Partes elegem o Foro da Comarca de São Paulo - SP para a solução de qualquer conflito decorrente deste Contrato.
							<br />
							<br />É com base nisso que as Partes firmam este Contrato por meio digital na forma da legislação vigente.
							<br />
							<br />vigente em 04 de outubro de 2023
							<br />1a. Versão publicada no dia 15 de outubro de 2022
							<br />
							<br />---
							<br />
							<div className='tw-text-xs'>
								<br />1 Originações: Apresentações de possíveis clientes que tenham potencial para se tornarem Clientes Ativos da STARK;
								<br />
								<br />2 Stark Match: Produto da STARK que consiste na prestação de serviços de investment banking considerando a assessoria e consultoria operações nos mercados de Equity (participação societária) e Dívida;
								<br />
								<br />3 Comitê de Originação: Reunião interna da STARK que tem por objetivo avaliar os Clientes Originados;
								<br />
								<br />4 Cliente Restrito: São as Clientes que não podem ser objeto de uma Originação nos termos da cláusula 2.8.1.;
								<br />
								<br />5 Exclusividade: Prazo de 6 (seis) meses onde o AGENTE terá direitos nos, termos deste Contrato, sobre uma Originação colocada no status Fase Comercial;
								<br />
								<br />6 Exclusividade: Prazo de 6 (seis) meses onde o AGENTE terá direitos, nos termos deste Contrato, sobre uma Originação colocada no status Stand-by;
								<br />
								<br />7 Negócio: serão consideradas as filiais, coligadas, controladas ou controladora ou empresas mesmo que sem vínculo societário ou institucional que componham a atividade econômica da Empresa Restrita;
								<br />
								<br />8 Reunião de Validação: Reunião presencial ou mediante vídeo/teleconferência a ser promovida pelo Agente, ou não, realizada entre Representantes da Stark e Representantes do Cliente Originado;
								<br />
								<br />9 Representante da Stark: Pessoa vinculada diretamente a Stark ou ao Stark Office responsável pelas tratativas comerciais com o Cliente Originado;
								<br />
								<br />10 Representantes do Cliente Originado: Pessoas diretamente vinculadas ao Cliente originado que possam agir em nome do Cliente Originado e que tenham poder de decisão/encaminhamento sobre a contratação do produto Stark Match;
								<br />
								<br />11 Cliente Ativo ou Contratantes: Clientes Originados que através dos seus representantes efetivamente assinaram um ou mais Contratos de Stark Match com a Stark ou Stark Offices;
								<br />
								<br />12 Assinatura Mensal: É o valor pago à título de honorários pelo Cliente Ativo em favor da Stark ou Stark Offices pela prestação dos serviços dentro do contrato de Stark Match. Estes valores somente são devidos pelo Cliente Ativo caso haja efetivamente uma tratativa estabelecida com um investidor exclusivo da Stark (Match) ou caso o Cliente Ativo opte pelo início da elaboração dos materiais de preparação antes da verificação de ao menos um Match.
								<br />
								<br />13 Valor líquido: Valor efetivamente recebido pela Stark, ou o Office respectivo, em função de sua prestação de serviços com os Clientes Ativos, descontado os impostos incidentes;
							</div>

							{/* <div className='text-center font-weight-bold'>CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ORIGINAÇÃO DE NOVOS NEGÓCIOS</div> */}
							{/* <br />
							Estes CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ORIGINAÇÃO DE NOVOS NEGÓCIOS constitui um acordo entre STARK CAPITAL (“STARK”) como proprietária e administradora do website hospedado no domínio e subdomínios de https://stark.money/ e https://app.stark.money/, (“Website” ou “Plataforma”) e os Agente de Negócios Stark (“Agentes Stark”), também denominados para fins destes termos, (“AGENTE” ou “Usuário”) do Website e/ou da Plataforma.
							<br />
							<br />
							A STARK pode ser identificada e localizada da seguinte forma: STARK CAPITAL LTDA, inscrita no CNPJ sob nº 21.398.549/0001-56, com sede Rua Fidêncio Ramos, 160, Cj. 706/707, Vila Olímpia, Cidade de São Paulo, Estado de São Paulo, CEP: 04551-010, representada de acordo com seu Contrato Social por seu sócio administrador JOÃO VITOR CARMINATTI OLIVEIRA, brasileiro, solteiro, economista, portador da cédula de identidade n.º 4.352.845 e CPF/MF sob o n.º 071.371.169-81, e-mail: jv.carminatti@stark.money
							<br />
							<br />
							Sendo STARK e AGENTE, individualmente denominados “Parte” e conjuntamente denominados “Partes”, resolvem celebrar o presente CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE ORIGINAÇÃO DE NOVOS NEGÓCIOS (“Contrato de Agente Stark” ou “Contrato”), que será regido pelos seguintes termos e condições:
							<br />
							<br />
							<div className='font-weight-bold'>1. OBJETO</div>
							<br />
							1.1. O presente Contrato tem por objeto o estabelecimento de vínculo não exclusivo entre as Partes com a finalidade de que o AGENTE possa, através seus representantes, realizar Originações de novos negócios, mediante o fornecimento de informações de Empresas via página web: https://app.stark-capital.com.br (a seguir denominado "Plataforma" ou “Plataforma STARK”), bem como a realização de intermediação com os representantes das Empresas Originadas que tenham interesse em contratar o produto Stark Match da STARK.
							<br />
							<br />
							1.2. O presente Contrato se desenvolverá de forma autônoma e não exclusiva entre as Partes, não constituindo nenhuma obrigação de exclusividade ou dever de preferência do AGENTE em favor da STARK, que não é obrigado a realizar Originações de novos negócios à STARK por ser livre e autônomo na condução de sua atividade. Caso o AGENTE autonomamente decida realizar Originações de novos negócios (Empresas) à STARK, este será remunerado de acordo com os termos deste Contrato, cumpridas as condições estabelecidas.
							<br />
							<br />
							<div className='font-weight-bold'>2. ORIGINAÇÃO DE NOVOS NEGÓCIOS (EMPRESAS)</div>
							<br />
							2.1. Para a realização das Originações de novos negócios, o AGENTE terá uma conta na Plataforma STARK. Para liberação do acesso o AGENTE deverá estar de acordo com o presente Contrato, com os Termos de Uso e Aviso de Privacidade e/ou outros termos a serem disponibilizados na página web: https://app.stark-capital.com.br. Todo o fluxo de Originação e acompanhamento acontecerá dentro da Plataforma STARK.
							<br />
							<br />Parágrafo único. Todas as Originações validamente realizadas através da conta do AGENTE na Plataforma STARK, serão tratadas como Originações realizadas exclusivamente pelo AGENTE aqui contratante. As Partes concordam que a STARK somente se responsabilizará pela satisfação de qualquer obrigação unicamente perante o AGENTE, sendo assim, caso outras pessoas utilizem a conta do AGENTE para realizar uma Originação, esta será tratada como uma Originação realizada pelo AGENTE devidamente contratado.
							<br />
							<br />Iniciando uma Originação
							<br />
							<br />2.2. Quando da realização de uma Originação, o AGENTE deverá preencher determinadas informações sobre a Empresa que deseja apresentar à STARK. As informações a serem preenchidas dizem respeito à identificação, características da atividade empresarial desenvolvida, aspectos financeiros, e outros, da Empresa Originada. Ao finalizar o preenchimento das informações solicitadas, automaticamente será gerado um Teaser relativo à Empresa Originada, que poderá ser publicado/enviado para a STARK.
							<br />
							<br />2.2.1. O AGENTE deverá preencher adequadamente as informações solicitadas sobre a Empresa, sendo que as informações fornecidas pelo AGENTE deverão ser verdadeiras e coletadas diretamente com os representantes da Empresa Originada com quem tenha contato, mediante sua ciência e autorização.
							<br />
							<br />Comitê de Originação
							<br />
							<br />2.3. Realizada a publicação/envio do Teaser pelo AGENTE, a STARK receberá as informações sobre a Empresa que será submetida ao Comitê de Originação que tem como objetivo analisar o Teaser bem como conferir as informações fornecidas pelo AGENTE. Realizadas as análises do Comitê de Originação, a STARK poderá: i) Aceitar a Originação; ii) Rejeitar a Originação; ou iii) Solicitar Ajustes no Teaser.
							<br />
							<br />i) Aceitar a Originação (Registro da Oportunidade): A STARK aceitará a Originação realizada pelo AGENTE, registrando esta em seu nome para todos os efeitos deste Contrato, quando conjuntamente: a) a Empresa apresentada (Originada) não esteja configurada como uma Empresa Restrita, nos termos da cláusula 2.8.1.; e b) se as informações fornecidas estiverem adequadas e sem quaisquer defeitos que prejudiquem a avaliação pelo Comitê de Originação a critério da STARK;
							<br />
							<br />ii) Rejeitar a Originação: A STARK rejeitará a Originação realizada pelo AGENTE quando verificar que a Empresa Originada é configurada como uma Empresa Restrita, nos termos da cláusula 2.8.1.;
							<br />
							<br />iii) Solicitar Ajustes no Teaser: A STARK solicitará ajustes nas informações fornecidas pelo AGENTE quando estas, a critério da STARK, contiverem defeitos que prejudiquem a avaliação pelo Comitê de Originação.
							<br />
							<br />Aceitação da Originação
							<br />
							<br />2.4. Sendo a Originação aceita pela STARK, esta será registrada em nome do AGENTE, que passará a ter a Exclusividade perante a STARK nos termos deste Contrato. Em função da Exclusividade outorgada, a Empresa Originada e aceita, passará a ser uma Empresa Restrita para os demais AGENTES, garantindo o direito do AGENTE Originador, e impossibilitando outro AGENTE de apresentar a mesma Empresa pelos prazos de Exclusividade relacionados ao status da Originação (Exclusividade Comercial ou Exclusividade Stand-by).
							<br />
							<br />2.5. Após a avaliação do Comitê de Originação a STARK decidirá, a seu exclusivo critério, se deseja: a) iniciar uma Fase Comercial com Empresa Originada pelo AGENTE, com a apresentação de proposta de prestação de serviços e realização das demais interações e atos comerciais visando à contratação de seus serviços, recebendo esta Originação o status de Fase Comercial; ou b) Não iniciar uma Fase Comercial, recebendo esta Originação o status de Stand-by.
							<br />
							<br />Aceitação da Originação: Fase Comercial
							<br />
							<br />2.6. Caso a STARK aceite a Originação realizada pelo AGENTE e a seu exclusivo critério decida iniciar uma tratativa comercial com os representantes da Empresa Originada visando à prestação de seus serviços, esta Originação passará a ter o status de “Fase Comercial”, sendo garantido ao AGENTE um prazo de Exclusividade Comercial de 6 (seis) meses contados da data em que for disponibilizada a informação da decisão do Comitê de Originação que colocou o status de Fase Comercial na Originação. A informação sobre o status da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim.
							<br />
							<br />2.6.1. Igualmente, a Originação anteriormente aceita com o status de Stand-by que a STARK decidir por dar início a uma tratativa comercial, nos termos da cláusula 2.7.1., passará a ter o status de “Fase Comercial” sendo garantido ao AGENTE um prazo de Exclusividade Comercial de 6 (seis) meses contados da data em que for disponibilizada a informação da decisão da STARK que colocou o status de Fase Comercial na Originação. A informação sobre o status da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim.
							<br />
							<br />2.6.2. Dentro do prazo de Exclusividade Comercial de 6 (seis) meses da Fase Comercial, o AGENTE, atuando livremente, poderá, sem obrigatoriedade, realizar a intermediação entre STARK e os representantes da Empresa Originada, visando aproximar e facilitar o entendimento dos serviços relativos aos produtos e serviços oferecidos pela STARK, para que isso favoreça a contratação destes pelos representantes da Empresa Originada. Caso essas tratativas resultem na assinatura de um Contrato de Stark Match, este tenha assegurado o eventual recebimento de sua remuneração, conjuntamente à STARK, caso sejam verificadas concomitantemente as condições previstas na cláusula 3.12.
							<br />
							<br />2.6.3. Finalizado o prazo de Exclusividade Comercial sem que tenha sido assinado um Contrato de Stark Match entre Empresa Originada e STARK, o Teaser da Empresa Originada será removido da interface do AGENTE na Plataforma STARK, e a Empresa Originada passará a ser uma Empresa Restrita da STARK, não havendo em se falar de eventuais futuros direitos do AGENTE sobre a Originação.
							<br />
							<br />2.6.4. Caso dentro dos últimos 30 (trinta) dias do prazo de Exclusividade Comercial for verificado que existem tratativas em andamento visando à assinatura do Contrato de Stark Match, o AGENTE poderá solicitar e/ou a STARK poderá conceder, a seu critério, prazos suplementares para a conclusão das tratativas.
							<br />
							<br />Aceitação da Originação: Stand-by
							<br />
							<br />2.7. Caso a STARK aceite a Originação realizada pelo AGENTE, no entanto, a seu exclusivo critério, decida não iniciar uma Fase Comercial com os representantes da Empresa naquele momento, esta Originação passará a ter o status de “Stand-by”, sendo garantido ao AGENTE um prazo de Exclusividade Stand-by de 12 (doze) meses contados da data em que for disponibilizada a informação da decisão do Comitê de Originação que colocou o status de Stand-by na Originação. A informação sobre o status da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim.
							<br />
							<br />2.7.1. Dentro do prazo de Exclusividade Stand-by de 12 (doze) meses, caso a STARK resolva iniciar uma tratativa comercial com os representantes da Empresa Originada visando à prestação de seus serviços, esta comunicará o AGENTE quanto a isso, e alterará o status da Originação para Fase Comercial, dando início às tratativas com os representantes da Empresa sob a intermediação, ou não, do AGENTE, de acordo com sua vontade. Caso a STARK inicie uma Fase Comercial de uma Originação anteriormente com o status de Stand-by, será observada a disposição contida na cláusula 2.6.1.
							<br />
							<br />2.7.2. Finalizado o prazo de Exclusividade Stand-by sem que tenha sido iniciada uma Fase Comercial e assinado um Contrato de Stark Match, o Teaser da Empresa Originada será removido da interface do AGENTE na Plataforma STARK e a Empresa Originada passará a ser uma Empresa Restrita da STARK, não havendo em se falar de eventuais futuros direitos do AGENTE sobre a Originação.
							<br />
							<br />Rejeição da Originação
							<br />
							<br />2.8. Como regra, toda Originação de uma nova Empresa é aceita pela STARK, cabendo ao Comitê de Originação à avaliação sobre o início da Fase Comercial ou colocação da Originação em Stand-by. Uma Originação somente será Rejeitada pela STARK caso a Empresa apresentada seja configurada como uma Empresa Restrita ou na hipótese disposta na cláusula 2.9.2.
							<br />
							<br />2.8.1. Para efeitos deste Contrato, será entendida como uma Empresa Restrita: a) a Empresa que já foi anteriormente Originada por outro AGENTE e que esteja sob seu prazo de Exclusividade (Comercial ou Stand-by); b) a Empresa que já foi anteriormente Originada por outro AGENTE, porém que seu prazo de Exclusividade (Comercial ou Stand-by) já tenha expirado; ou c) Empresa com a qual a STARK já mantenha um relacionamento prévio, independente de Originação de qualquer AGENTE; sendo que:
							<br />
							<br />a) Caso uma Empresa já houver sido validamente Originada por outro AGENTE e aceita pela STARK, o AGENTE que Originou validamente a Empresa terá sua Exclusividade (Comercial ou Stand-by), sendo assim, esta Empresa será considerada uma Empresa Restrita para quaisquer novas Originações pelos prazos de Exclusividade a que outro AGENTE tiver direito.
							<br />
							<br />b) Caso uma Empresa já houver sido validamente Originada por outro AGENTE, aceita pela STARK e os prazos de Exclusividade do outro AGENTE tenham expirado, a Empresa será considerada uma Empresa Restrita da STARK por prazo indeterminado;
							<br />
							<br />c) Caso uma Empresa, independente de Originação prévia de qualquer AGENTE, for de relacionamento prévio da STARK, a Empresa será considerada uma Empresa Restrita da STARK por prazo indeterminado. O relacionamento prévio será verificado para a configuração da situação restritiva, se previamente à Originação realizada pelo AGENTE, a STARK já: i) possua qualquer acordo firmado; ou ii) tenha iniciado quaisquer atos comerciais visando alguma contratação; ou iii) tenha listado, a seu critério, a Empresa como uma Empresa Restrita.
							<br />
							<br />2.8.2. A situação restritiva se estenderá a todo o negócio da Empresa Restrita, sendo assim, caso o negócio ou atividade desenvolvida pela Empresa Restrita possua mais de um CNPJ (Cadastro Nacional de Pessoa Jurídica), sites ou até mesmo nomes empresariais diversos, todos serão considerados o mesmo negócio, e por tanto, a mesma Empresa Restrita.
							<br />
							<br />2.8.3. A Informação quanto a Rejeição da Originação será disponibilizada na Plataforma STARK, sem prejuízo de outro meio de comunicação para este fim, e poderá ocorrer de duas formas: a) antecipada, com o bloqueio da publicação/envio da Originação à Comitê de Originação; ou b) posterior, após a análise em Comitê de Originação e verificação da situação restritiva.
							<br />
							<br />a) Em regra, toda Empresa Restrita fará parte de uma lista de Empresas Restritas, onde informações aceca da identificação destas serão elencadas. Caso qualquer AGENTE tente realizar a publicação/envio da Originação de uma Empresa Restrita componente da lista de restrição, imediatamente haverá o bloqueio da publicação/envio da Originação e sua Rejeição antecipada.
							<br />
							<br />b) Caso, mesmo a Empresa sendo considerada uma Empresa Restrita, porém esta não conste na lista de restrição, ou, os dados que constem sejam diferentes dos dados preenchidos pelo AGENTE Originador, a STARK se resguarda o direito de analisar a situação restritiva da Empresa após a publicação/envio, e realizar a Rejeição posterior.
							<br />
							<br />2.8.4. Realizada a Rejeição antecipada com bloqueio da publicação/envio da Originação, ou a Rejeição posterior, o Teaser da Empresa Originada será removido da interface do AGENTE na Plataforma STARK, não havendo em se falar de eventuais futuros direitos do AGENTE sobre a Originação Rejeitada.
							<br />
							<br />Solicitação de Ajustes no Teaser
							<br />
							<br />2.9. Dentro do processo de Originação serão solicitadas determinadas informações acerca da Empresa Originada, gerando assim um Teaser da oportunidade através da Plataforma STARK que será avaliado pelo Comitê de Originação. Dentro do Comitê de Originação, caso a STARK avalie, a seu critério, que as informações preenchidas pelo AGENTE não são suficientes ou contenham algum defeito que prejudique sua análise, a STARK poderá Solicitar Ajustes ao AGENTE, que terá um prazo de 15 (quinze) dias para realizar os ajustes solicitados (Prazo de Ajustes) e novamente publicar/enviar a Originação para a STARK para nova avaliação em Comitê de Originação.
							<br />
							<br />2.9.1. Dentro do Prazo de Ajustes de 15 (quinze) dias fica resguardado ao AGENTE os direitos sobre aquela Originação iniciada, sendo assim, quaisquer outros AGENTES ficam impossibilitados de realizar a publicação/envio durante o Prazo de Ajustes. Após o transcurso do Prazo de Ajustes a Empresa passa a ser uma Empresa livre e outro AGENTE pode realizar sua publicação/envio.
							<br />
							<br />2.9.2. Caso após o AGENTE realizar os ajustes solicitados, publicando/enviando novamente a Originação para a STARK; e esta, a seu critério, ainda julgar que as informações ajustadas não são suficientes ou ainda contenha algum defeito que prejudique sua análise, a seu critério, a STARK poderá: a) Solicitar Ajustes novamente, sendo aberto novo Prazo de Ajustes de 15 (quinze); ou b) Rejeitar a Originação.

							<br />
							<br />
							<div className='font-weight-bold'>3. CLIENTE ATIVO E REMUNERAÇÃO DO AGENTE</div>

							<br />3.1. Dentro dos respectivos prazos de Exclusividade do AGENTE (Exclusividade Comercial ou Exclusividade Stand-by), caso STARK e Empresa Originada, conjuntamente com seus respectivos representantes, cheguem a um acordo quanto à prestação de serviços e efetivamente assinem um Contrato de Stark Match, estes serão denominados de “Cliente Ativo” ou “Contratantes”.
							<br />
							<br />3.2. Toda remuneração à que o AGENTE tiver direito será proveniente do Contrato de Stark Match firmado entre STARK e Clientes Ativos. Cumpridas das condições estabelecidas na cláusula 3.12., o AGENTE terá direito a uma parte do Valor líquido das remunerações livremente ajustadas e efetivamente recebidas pela STARK em função de sua prestação de serviços em favor de cada Cliente Ativo. Em geral a remuneração a ser paga ao AGENTE poderá ser proveniente de duas formas de cobrança do Cliente Ativo: a) Honorários (“Retainer”); e b) Comissão no Sucesso (“Success Fee”); se assim for ajustado no Contrato de Stark Match, guardando-se a autonomia da vontade das partes para ajustarem de forma diversa.
							<br />
							<br />Retainer
							<br />
							<br />3.3. O AGENTE será remunerado via Retainer em montante igual ao Valor líquido da primeira parcela dos Honorários (”Retainer”) efetivamente contratados e pagos pelo Cliente Ativo em favor da STARK pela prestação de seus serviços.
							<br />
							<br />Success Fee
							<br />
							<br />3.4. O AGENTE será remunerado via comissão (“Success Fee AGENTE”) em percentual aplicado sobre o Valor líquido da Comissão no Sucesso (“Success Fee STARK”) efetivamente paga pelo Cliente Ativo em favor da STARK pela prestação de seus serviços, quando do sucesso da transação. O percentual de Success Fee AGENTE será de 20% (vinte por cento) sobre o Valor líquido Success Fee STARK efetivamente pago pelo Cliente Ativo.
							<br />
							<br />3.5. A STARK envidará seus melhores esforços conjuntamente ao AGENTE no sentido de conseguir a melhor negociação comercial tendo em vista sua estratégia. No entanto, a depender das peculiaridades de cada projeto e de cada negociação, e tendo em vista a estratégia na condução de seus negócios, a STARK, autonomamente, poderá alterar, majorar, minorar ou suprimir, de qualquer forma dispondo acerca da forma, prazos e valores de quaisquer de sua remunerações.
							<br />
							<br />Campanhas
							<br />
							<br />3.6. Dentro da Política de Canais vigente, a STARK poderá conceder bonificações, premiações, bem como incremento de valores e/ou percentuais adicionais às remunerações previstas neste contrato (“Campanhas”), de acordo a performance do AGENTE, características quantitativas e/ou qualitativas das Empresas Originadas ou outros critérios a serem dispostos na Política de Canais. Todo o regramento sobre as Campanhas será disposto na Política de Canais.
							<br />
							<br />Forma de Pagamento do AGENTE
							<br />
							<br />3.7. O AGENTE terá direito ao recebimento de sua remuneração, cumpridas as condições estabelecidas na cláusula 3.12., na medida em que o Cliente Ativo Originado efetivamente pague os respectivos valores ajustados em favor da STARK nos termos do Contrato de Stark Match firmado.
							<br />
							<br />3.8. Na data em que a STARK acusar o recebimento da primeira parcela de seus Honorários (”Retainer”) paga pelo Cliente Ativo Originado, o AGENTE será informado via Plataforma STARK, ou outro meio de comunicação, quanto ao valor que tem direito de receber. Após o envio da informação sobre o valor que tem a receber, o AGENTE deverá emitir competente nota fiscal de serviços no exato valor informado.
							<br />
							<br />3.8.1. O pagamento a título de Retainer será realizado à vista, no Valor líquido da primeira parcela dos Honorários (”Retainer”) ajustados entre Cliente Ativo e STARK, e a obrigação quitada com a compensação da competente nota fiscal.
							<br />
							<br />3.9. Na data em que a STARK acusar o recebimento, no todo ou em parte, de qualquer valor de sua remuneração a título de Comissão no Sucesso (“Success Fee STARK”), o AGENTE será informado via Plataforma STARK, ou outro meio de comunicação, quanto ao valor que tem direito de receber. Após o envio da informação sobre o valor que tem a receber, o AGENTE deverá emitir competente nota fiscal de serviços no exato valor informado.
							<br />
							<br />3.9.1. O pagamento a título de Success Fee AGENTE será realizado da mesma forma ajustada entre Cliente Ativo e STARK no Contrato de Stark Match ou outro acordo de pagamento firmado. Sendo assim, a STARK pagará o AGENTE aplicando o percentual a que este tiver direito sobre o Valor líquido de cada um dos pagamentos efetivamente recebidos a título de Comissão no Sucesso (“Success Fee STARK”) pagos pelo Cliente Ativo.
							<br />
							<br />3.10. A(s) nota(s) fiscal(is) para pagamento da remuneração, seja a título de Retainer ou Success Fee AGENTE, deverá(ão) ser encaminhada(s) pelo AGENTE à STARK, no exato valor informado, sendo que a STARK que deverá realizar o(s) pagamento(s) em até 5 (cinco) dias úteis de seu efetivo recebimento. Caso haja atraso no pagamento por parte da STARK incidirão sobre o montante principal em atraso: (i) correção monetária segundo o IPCA/FGV; e (ii) juros moratórios de 1% (um por cento) ao mês, calculados pro rata die.
							<br />
							<br />3.11. Caso seja constatado algum erro, a nota fiscal será devolvida sendo informado ao AGENTE que o respectivo pagamento será sustado até sua efetiva correção, sem que isso implique em atraso por parte da STARK.
							<br />
							<br />Condições para o Recebimento de Remunerações
							<br />
							<br />3.12. De acordo com este Contrato, somente haverá de se falar em direito de recebimento de qualquer valor pelo AGENTE, caso forem verificadas conjuntamente as seguintes condições, nos termos do art. 121 do Código Civil:
							<br />
							<br />i) O AGENTE tenha realizado validamente uma Originação de uma Empresa com o preenchimento das informações solicitadas de forma completa e adequada, e tenha realizado a publicação/envio das informações (Teaser) através da Plataforma STARK, nos termos da cláusula 2.2.;
							<br />
							<br />ii) A Originação tenha sido Aceita pelo Comitê de Originação da STARK nos termos da cláusula 2.4.;
							<br />
							<br />iii) Durante o prazo de Exclusividade (Stand-by/Comercial) outorgado ao AGENTE em função da Aceitação da Originação, a STARK tenha assinado o Contrato de Stark Match com a Empresa Originada, bem como seus respectivos representantes (“Cliente Ativo”);
							<br />
							<br />iv) A STARK tenha efetivamente iniciado e desenvolvido a prestação de seus serviços em favor do Cliente Ativo; e
							<br />
							<br />v) A STARK tenha efetivamente recebido a remuneração ajustada do Cliente Ativo, ou seja, a Empresa Originada, bem como seus respectivos representantes, como Contratantes dos serviços, tenham efetivamente pago a remuneração ajustada em favor da STARK, nos valores e formas estabelecidas nos acordos firmados;




							<br />
							<br />
							<div className='font-weight-bold'>4. RISCO EMPRESARIAL</div>

							<br />4.1. O AGENTE declara estar ciente e aceita sem ressalvas participar do risco empresarial envolvido na prestação dos serviços pela STARK, sendo que eventuais atrasos ou não recebimentos por parte da STARK darão o direito ao AGENTE de ver proporcionalmente acrescida à sua remuneração, eventuais valores provenientes de multas, juros de mora e correção monetária, em caso de eventual cobrança e após o efetivo recebimento pela STARK. Os valores acessórios somente comporão a remuneração do AGENTE caso estas penalidades: i) a exclusivo critério da prestadora de serviços, forem efetivamente cobradas no bojo dos acordos firmados; e ii) forem efetivamente pagas pelo Cliente Ativo Originado pelo AGENTE.
							<br />
							<br />4.2. Da mesma forma, e tendo em vista o risco empresarial inerente à prestação dos serviços ao qual o AGENTE livremente se associa, e levando-se em consideração que a obrigação de remunerar tanto o AGENTE quanto a STARK será sempre do Cliente Ativo, em caso de não recebimento da remuneração, o único responsável será o Cliente Ativo, salvo em caso de atraso ou descumprimento da obrigação de repasse por parte da STARK.
							<br />
							<br />4.3. Caso a qualquer momento e por qualquer motivo STARK e Cliente Ativo rescindirem ou resilirem, ou de qualquer outra forma extinguirem o vínculo entre eles havido, e tendo em vista a assunção, pelo AGENTE, do risco empresarial em voga, nada será devido ao AGENTE.
							<br />
							<br />4.4. As despesas necessárias ao exercício das atividades do AGENTE, incluindo, mas não se limitando à locomoção, combustível, hospedagem, telegramas, telefonemas, porte de postal, contratação de empregados ou de outras pessoas a qualquer título, bem como as despesas de eventuais prepostos, serão suportadas exclusivamente pelo AGENTE.

							<br />
							<br />
							<div className='font-weight-bold'>5. VALIDADE E RESCISÃO</div>

							<br />5.1. O presente Contrato de Agente Stark vigorará pelo prazo determinado de 12 (doze) meses, sendo que, qualquer das Partes, a qualquer tempo e mediante envio prévio de comunicação, com antecedência mínima de 10 (dez) dias, poderá resilir unilateralmente o presente acordo.
							<br />
							<br />5.1.1. A comunicação acima referida operar-se-á mediante envio de e-mail, notificando a contraparte que dentro do prazo de 10 (dez) dias, o presente Contrato restará resilido.
							<br />
							<br />5.2. A infração cometida por uma das Partes contratantes a quaisquer cláusulas e condições incluídas neste Contrato de Agente Stark dará direito à outra parte de rescindi-lo imediata e unilateralmente, ficando totalmente sob a responsabilidade da parte infratora as indenizações e perdas e danos decorrentes, nos termos do art. 475 do código civil.
							<br />
							<br />5.3. Mesmo que operada a extinção do presente vínculo, seja mediante resilição ou rescisão, e tendo havido a Aceitação na Originação ou contratação por um Cliente Ativo, tanto o direito do AGENTE relativo à Exclusividade (Stand-by/Comercial) quanto os relativos à eventual remuneração proveniente dos Clientes Ativos, permanecerão válidas pelos prazos respectivos, salvo a rescisão tenha sido motivada por conduta danosa realizada pelo AGENTE.
							<br />
							<br />5.4. As Partes poderão de comum acordo, decidir pela manutenção deste Contrato, ainda que o descumprimento contratual decorra de ato culposo ou doloso praticado por qualquer uma delas.
							<br />
							<br />5.5. Caso reste verificada conduta danosa ou desabonadora por parte do AGENTE na relação entre STARK e representantes da Empresa Originada, ou referente à STARK perante o mercado, que de qualquer forma possa prejudicar a imagem, relacionamento ou a prestação de serviços, a critério da STARK o presente Contrato poderá ser rescindido, perdendo o AGENTE quaisquer direitos que detenha.
							<br />
							<br />5.6. A rescisão não acarretará, tanto à STARK quanto ao AGENTE, a obrigação de pagamento de quaisquer indenizações e reparações em decorrência de eventuais investimentos ou despesas que as Partes tenham incorrido para a consecução do objeto deste Contrato.

							<br />
							<br />
							<div className='font-weight-bold'>6. SIGILO E CONFIDENCIALIDADE</div>

							<br />6.1. Todas as informações a que as Partes tiverem acesso em razão do desenvolvimento do objeto do preste Contrato de AGENTE são e serão consideradas como Informações Confidenciais durante todo o período de vigência deste Contrato por 2 (dois) anos contados da sua extinção.
							<br />
							<br />6.2. As Partes, por si e seus representantes, obrigam-se a não divulgar, revelar, reproduzir, utilizar ou dar conhecimento a terceiros cujo este conhecimento não seja necessário à consecução do objeto deste Contrato, mantendo sob o mais absoluto sigilo todas as Informações Confidenciais do AGENTE, da STARK e das Empresas Originadas.
							<br />
							<br />6.3. Não serão consideradas como descumprimento do Contrato as seguintes hipóteses: a) Se quaisquer das Informações Confidenciais já haviam sido divulgadas anteriormente à celebração deste Contrato; b) Houver prévia e expressa anuência da parte reveladora quanto à liberação da obrigação de sigilo e confidencialidade de quaisquer Informações Confidenciais; c) A informação tenha, comprovadamente, sido obtida por outra fonte, de forma legal e legítima, independentemente do presente Contrato; d) Se houver determinação judicial e/ou governamental, de fornecimento de quaisquer Informações Confidenciais, devendo a parte reveladora notificar a outra parte previamente à liberação das referidas Informações Confidenciais, bem como requerer segredo de justiça no seu trato judicial e/ou administrativo.

							<br />
							<br />
							<div className='font-weight-bold'>7. OBSERVÂNCIA DAS PRÁTICAS DE ANTICORRUPÇÃO</div>

							<br />7.1. O AGENTE declara por si e seus sócios, administradores, empregados, prepostos ou quaisquer pessoas agindo em seu nome ou interesse, que jamais praticou, e se obriga, durante a vigência deste Contrato, a não praticar quaisquer atos que violem as leis anticorrupção aplicáveis. Da mesma forma a STARK, declara por si e seus sócios, administradores, empregados, prepostos ou quaisquer pessoas agindo em seu nome ou interesse que jamais praticou, e se obriga, durante a vigência deste Contrato, a não praticar quaisquer atos que violem as leis anticorrupção aplicáveis.
							<br />
							<br />7.2. A obrigação e declaração aqui exposta se aplicam à conduta das Partes em suas relações com terceiros e com a administração pública, nacional ou estrangeira, seus agentes, pessoas jurídicas controladas, direta ou indiretamente pelo poder público.
							<br />
							<br />7.3. Independentemente de quaisquer investigações ou processos terem sido iniciados pelas autoridades públicas competentes, caso surjam denúncias ou indícios razoáveis de que quaisquer das Partes, por si ou seus representantes, violou qualquer lei anticorrupção ou disposição contratual anticorrupção, ou caso os tenha comprovadamente violado, terá a outra Parte direito de rescindir este Contrato por justa causa, sem prejuízo de obterem reparação integral por perdas e danos, inclusive por quaisquer multas, tributos, juros, despesas e custos incorridos em conexão com a investigação de irregularidades ou defesa, diante de quaisquer acusações ou processos relacionados à violação da legislação.

							<br />
							<br />
							<div className='font-weight-bold'>8. OBSERVÂNCIA DAS PRÁTICAS TRABALHISTAS</div>

							<br />8.1. O AGENTE desenvolverá o objeto deste Contrato por meio de seus sócios, empregados, prepostos ou consultores contratados, sendo o único e exclusivo responsável pelo pagamento de remunerações, comissões, salários, contribuições previdenciárias e fundiárias e demais benefícios e encargos trabalhistas devidos às pessoas que vier a contratar e tenha qualquer relação com o presente Contrato, descabendo, por conseguinte, a imputação de quaisquer dessas obrigações à STARK.
							<br />
							<br />8.2. O AGENTE compromete-se a fiscalizar e dirigir, diretamente, seus sócios, empregados, prepostos ou consultores contratados ou qualquer pessoa designados para a execução do presente Contrato, a fim de que todas as obrigações de sua responsabilidade sejam perfeitamente cumpridas, sempre respeitando os ditames da boa-fé e ética inerentes às atividades desenvolvidas, adotando medidas corretivas eventualmente necessárias e eficazes.
							<br />
							<br />8.3. Em caso de propositura de reclamação trabalhista por qualquer funcionário, preposto ou consultor contratado e/ou sócio do AGENTE, visando o reconhecimento de vínculo empregatício com a STARK, e/ou o pagamento de verbas trabalhistas, o AGENTE ficará obrigada reembolsar a STARK, por todo e qualquer valor por ela despendido, incluindo, mas não se limitando a, honorários advocatícios, custas processuais e valor integral de eventual condenação, mediante simples solicitação da STARK, sob pena de, além das verbas de reembolso, responder por perdas e danos a serem oportunamente apurados.
							<br />
							<br />8.4. As partes reconhecem expressamente que a relação entre elas é de fornecedor e adquirente de serviços (Originação de novos negócios) e não poderá ser considerada como empregado/empregador, outorgante/outorgado, “joint venture”, parceria ou qualquer outra forma de associação. O AGENTE não poderá, em nenhuma hipótese, celebrar, negociar modificar, aditar ou estabelecer qualquer termo contratual com qualquer pessoa com a intenção de obrigar, representar ou agir em nome da STARK, e a STARK não será responsável por qualquer contrato celebrado pelo AGENTE em violação ao presente instrumento.

							<br />
							<br />
							<div className='font-weight-bold'>9. OUTRAS DISPOSIÇÕES</div>

							<br />9.1. O presente acordo não implica a concessão, pelas Partes, de nenhuma licença ou qualquer outro direito, explícito ou implícito, em relação a qualquer direito legal, direito de patente, direito de edição, direito de cópia, ou qualquer outro direito relativo à propriedade intelectual, bem como direito à representação.
							<br />
							<br />9.2. O AGENTE permitirá a fiscalização, por parte da STARK, de ocorrências relativas aos serviços realizados para a execução deste ajuste de vontades.
							<br />
							<br />9.3. Não existe qualquer coligação, consórcio ou outro vínculo societário entre as Partes constantes neste Contrato, sendo AGENTE e STARK são sujeitos de direito distintos e independentes. Portanto, ambas responderão individual e isoladamente, sem qualquer solidariedade ou subsidiariedade, pelas obrigações que contraírem durante a validade do presente Contrato.
							<br />
							<br />9.4. Quaisquer despesas e investimentos em que eventualmente as Partes incorram, quando do desenvolvimento da presente Contrato, serão inteiramente de suportados e de responsabilidade de cada Parte isoladamente, não havendo, de Parte a Parte, em se falar em nenhum tipo de restituição.
							<br />
							<br />9.5. O presente Contrato e/ou os direitos e obrigações oriundos deste Contrato não poderão ser cedidos e/ou transferidos, parcial ou integralmente, por qualquer das Partes, sendo considerada nula qualquer cessão ou transferência sem o prévio e expresso consentimento das outra Parte.
							<br />
							<br />9.6. Qualquer tolerância, exercício parcial ou concessão entre as Partes serão sempre considerados como mera liberalidade e não renúncia ou perda de direito, nem implicarão em novação, alteração, transigência, remissão, modificação ou redução dos direitos e obrigações daqui decorrentes.
							<br />
							<br />9.7. O AGENTE autoriza a STARK a fazer referência da presente contratação, utilizando seu nome, marca e logotipo para ações de marketing em seu site, rede sociais, apresentações institucionais e outros locais, sempre respeitando a confidencialidade de informações.
							<br />
							<br />9.8. As Partes afirmam que este documento pode ser assinado por meio eletrônico, sendo consideradas válidas as referidas assinaturas mediante o aceite na Plataforma STARK.
							<br />
							<br />9.9. Toda vez que a STARK fizer alguma alteração elas serão válidas, eficazes e vinculantes após: (1) serem divulgadas na Plataforma STARK; (2) serem enviadas por e-mail aos Usuários; e/ou (3) serem comunicadas de qualquer outra forma AGENTES.
							<br />
							<br />9.10. Este instrumento será regido e interpretado de acordo com as Leis da República Federativa do Brasil.
							<br />
							<br />9.11. As Partes elegem o Foro da Comarca de São Paulo - SP para a solução de qualquer conflito decorrente deste Contrato.

							<br />
							<br />É com base nisso que as Partes firmam este Contrato por meio digital na forma da legislação vigente.

							<br />
							<br />vigente em 17 de outubro de 2022
							<br />1a. Versão publicada no dia 17 de outubro de 2022 */}


						</div>
					</div>

					<CardLine />
					<div className='p-3'>
						<div className='font-weight-bold'>Aceite todos os termos para continuar para a Plataforma Stark</div>
						<div className='d-flex gap mt-2 pointer'>
							<div className='mb-3'><UiCheckBox value={!!acceptedTerm3} setValue={changeAcceptedTerm1} /></div>
							<div>
								<span onClick={changeAcceptedTerm1}>Declaro que li e aceito o Contrato de Prestação de Serviços de Originação de Novos Negócios (“Contrato de Agente Stark”). Faça download do contrato </span>
								<a target='_blank' href='https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2FSTARK_x_AGENTE_-_2023.2%20(1).pdf?alt=media&token=25f3ae58-532a-43f1-9c52-ecad6dca3e38'>clicando aqui</a>
							</div>
						</div>
						<div className='d-flex gap mt-2 pointer'>
							<UiCheckBox value={!!acceptedTerm4} setValue={changeAcceptedTerm2} />
							<div>
								<span onClick={changeAcceptedTerm2}>Declaro que li e aceito os Termos de Uso da Plataforma Stark (“Termos de Uso”) e Aviso de Privacidade da Plataforma Stark  (“Aviso de Privacidade”). Faça download dos documentos </span>
								<a target='_blank' href='https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2F2023.2_-_Termos_de_Uso_-_Plataforma_-_Agentes_Stark.pdf?alt=media&token=26e41387-f9db-40c0-aa89-52ca56919779'>clicando aqui</a>
							</div>
						</div>
					</div>

					<div className='p-3'>
						<UiButton disabled={!enabled} onClick={handleAccept} className='tw-w-full'>Aceitar</UiButton>
					</div>

				</UiModal>
			)}
		</>
	)
}
