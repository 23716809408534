import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const DocumentOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path d='M27.0756 11.97C26.9435 11.84 26.9435 11.71 26.8113 11.58L18.4882 3.39C18.3561 3.26 18.2239 3.13 18.0918 3.13C17.9597 3 17.6955 3 17.5634 3H9.2402C7.2585 3 5.54102 4.69 5.54102 6.64V25.36C5.54102 27.31 7.2585 29 9.2402 29H23.5085C25.4902 29 27.2077 27.31 27.2077 25.36V12.49C27.2077 12.36 27.2077 12.1 27.0756 11.97ZM18.8845 7.42L22.7158 11.19H18.8845V7.42ZM23.5085 26.4H9.2402C8.71175 26.4 8.18329 25.88 8.18329 25.36V6.64C8.18329 6.12 8.71175 5.6 9.2402 5.6H16.2422V12.49C16.2422 13.27 16.7707 13.79 17.5634 13.79H24.5654V25.36C24.5654 25.88 24.037 26.4 23.5085 26.4Z' fill='#272827' />
	</Svg>
)
