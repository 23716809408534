import { helpersDate } from '../../Utils/helpersDate'
import { escreveData } from '../../Utils/minhaData'
import { TypeMatch } from '../types/match'
import { inDefinitiveContracts } from './inDefinitiveContracts'
import { inDefinitiveContractsDeclined } from './inDefinitiveContractsDeclined'
import { inDiligence } from './inDiligence'
import { inDiligenceDeclined } from './inDiligenceDeclined'
import { inMatchMaking } from './inMatchMaking'
import { inMatchMakingDeclined } from './inMatchMakingDeclined'
import { inOfferNegotiations } from './inOfferNegotiations'
import { inOfferNegotiationsDeclined } from './inOfferNegotiationsDeclined'
import { inPreMatch } from './inPreMatch'
import { inPreMatchDeclined } from './inPreMatchDeclined'
import { inPreparation } from './inPreparation'
import { inPreparationDeclined } from './inPreparationDeclined'
import { matchFinished } from './matchFinished'

export const showLastDate = (match: TypeMatch) => {
	if (inPreMatch(match)) return escreveData(match?.datePrematch.toString())
	else if (inPreMatchDeclined(match)) return escreveData(match?.dateReject.toString())
	else if (inMatchMaking(match)) return escreveData(match?.dateMatchmaking.toString())
	else if (inMatchMakingDeclined(match)) return escreveData(match?.dateDeclined.toString())
	else if (inPreparation(match) && match?.datePreparation !== 0) return helpersDate.convertAllToDate(match?.datePreparation).toLocaleDateString()
	else if (inPreparationDeclined(match) && match?.datePreparationDeclined !== 0) return helpersDate.convertAllToDate(match?.datePreparationDeclined).toLocaleDateString()
	else if (inOfferNegotiations(match) && match?.dateOffer !== 0) return helpersDate.convertAllToDate(match?.dateOffer).toLocaleDateString()
	else if (inOfferNegotiationsDeclined(match) && match?.dateOfferDeclined !== 0) return helpersDate.convertAllToDate(match?.dateOfferDeclined).toLocaleDateString()
	else if (inDiligence(match) && match?.dateDiligence !== 0) return helpersDate.convertAllToDate(match?.dateDiligence).toLocaleDateString()
	else if (inDiligenceDeclined(match) && match?.dateDiligenceDeclined !== 0) return helpersDate.convertAllToDate(match?.dateDiligenceDeclined).toLocaleDateString()
	else if (inDefinitiveContracts(match) && match?.dateContract !== 0) return helpersDate.convertAllToDate(match?.dateContract).toLocaleDateString()
	else if (inDefinitiveContractsDeclined(match) && match?.dateContractDeclined !== 0) return helpersDate.convertAllToDate(match?.dateContractDeclined).toLocaleDateString()
	else if (matchFinished(match) && match?.dateCompleted !== 0) return helpersDate.convertAllToDate(match?.dateCompleted).toLocaleDateString()
	else return escreveData(match?.dataCriacao.toString())
}
