import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const QuestionOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M16 30C8.3 30 2 23.7 2 16C2 8.3 8.3 2 16 2C23.7 2 30 8.3 30 16C30 23.7 23.7 30 16 30ZM16 4.8C9.84 4.8 4.8 9.84 4.8 16C4.8 22.16 9.84 27.2 16 27.2C22.16 27.2 27.2 22.16 27.2 16C27.2 9.84 22.16 4.8 16 4.8Z' fill='#272827' />
		<path d='M15.8602 18.66C15.3002 18.66 14.7402 18.24 14.6002 17.68C14.3202 16.98 14.7402 16.14 15.4402 15.86C16.4202 15.58 18.2402 14.6 18.2402 13.34C18.2402 12.36 17.5402 11.38 16.7002 11.1C16.1402 10.82 15.4402 10.96 14.8802 11.24C14.3202 11.52 13.9002 11.94 13.6202 12.64C13.3402 13.48 12.6402 13.76 11.9402 13.48C11.1002 13.34 10.8202 12.5 10.9602 11.8C11.3802 10.54 12.3602 9.41999 13.6202 8.85999C14.8802 8.29999 16.2802 8.15999 17.5402 8.57999C19.6402 9.27999 21.0402 11.24 21.0402 13.48C21.0402 16.84 16.8402 18.38 16.2802 18.52C16.1402 18.66 16.0002 18.66 15.8602 18.66Z' fill='#272827' />
		<path d='M15.9996 23.7C15.1596 23.7 14.5996 23.14 14.5996 22.3C14.5996 21.46 15.1596 20.9 15.9996 20.9C16.8396 20.9 17.3996 21.46 17.3996 22.3C17.3996 23.14 16.8396 23.7 15.9996 23.7Z' fill='#272827' />
	</Svg>
)
