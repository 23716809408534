import React, { createContext, useState, useContext, ChangeEvent, useEffect, ReactNode } from 'react'
import { TypeTeaser, ITeaserFile } from 'Functions/Teaser/types/teaser'
import { useHistory, useParams } from 'react-router-dom'
import { useTeaser } from 'Hooks/useTeaser'
import { fnUser } from 'Functions/User'
import { fnOrigination } from 'Functions/Origination'
import { GlobalMessage } from 'Components/GlobalModal/globalMessage'
import { getPercentageCompleted } from 'Functions/Origination/methods/getPercentageCompleted'
import * as S from './styles'
import { TypeError, TError } from 'Functions/Origination/methods/validateFields'
import { Overlay } from './Components/Overlay'
import { IconsDS } from 'Components/IconsDS'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useContextAuth } from 'App/ContextAuth'
import { TYPE_OPERATION } from 'Constants/TYPE_OPERATION'
import { useGlobalContext } from 'App/Global'
import { cnpjIsAvailable } from 'Services/cloudfunctions'
import { UiButton } from 'ComponentsUi/UiButton'
import { modelTeaser } from 'Functions/Teaser/model/modelTeaser'
import { helpersDate } from 'Functions/Utils/helpersDate'
import { fnDeleteOrigination } from 'Services/teaser/fnDeleteOrigination'
import { fnTeaser } from '../../Functions/Teaser'

const DEFAULT: TypeTeaser = { ...modelTeaser() }

export enum ModalTypes {
	Delete
}

type TModal = {
	title: string
	description: string
	okText: string
	closeText: string
	type: ModalTypes
	show: boolean
	loading: boolean
}

type TypeSaveHandler = {
	origination?: TypeTeaser, errorsCheck?: boolean
}

export type FormContextData = {
	teaser: TypeTeaser
	setTeaser: React.Dispatch<React.SetStateAction<TypeTeaser>>
	inputHandler: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	publishHandler: () => void
	saveHandler: (args?: TypeSaveHandler) => void
	deleteHandler: () => void
	loading: boolean
	statusModal: TModal | null
	closeModal: () => void
	okModalHandler: () => void
	cancelModalHandler: () => void
	viewHandler: () => void
	percent: number
	errors: TypeError
	setErrors: React.Dispatch<React.SetStateAction<TypeError>>
	offline: boolean | null
	showModalOffline: boolean
	okModalOfflineHandler: () => void
	setShowModalOffline: React.Dispatch<React.SetStateAction<boolean>>
	isAdm: boolean
	isOffice: boolean
	isOfficeAnalyst: boolean
	id?: string
	addFile: ({ key, file }: { key: string, file: ITeaserFile }) => void
	curatingAs?: TYPE_OPERATION
	disabled: boolean
}

type TParams = {
	id?: string
	uid?: string
	uidCo?: string
	isFromOffice?: string
}

/**
 * curatingAs é uma propriedade passada na tela de curadoria para ocultar/exibir
 * itens do formulário de acordo com o tipo de operação
 */
export type TAddOriginacao = { curatingAs?: TYPE_OPERATION }

export const FormContext = createContext<FormContextData>({} as FormContextData)

export const ContextPage: React.FC<TAddOriginacao> = props => {
	const { children, curatingAs } = props
	const [teaser, _setTeaser] = useState(DEFAULT)
	const [hasSite, setHasSite] = useState(!!DEFAULT.site)
	const setTeaser = (obj: React.SetStateAction<TypeTeaser>) => _setTeaser({ ...teaser, ...obj })
	const { id, uid, uidCo, isFromOffice } = useParams<TParams>()
	// caso não haja o id e tenha (uid, uidCo, isFromOffice ) estaremos no modo de usuário offline
	const { teaser: teaserData, loading } = id ? useTeaser(id) : { teaser: DEFAULT, loading: false }
	// const [isAdm, setIsAdm] = useState<boolean>(false)

	const { setGlobalLoading, setGlobalModal, globalLoading } = useGlobalContext()
	const { globalAuth } = useContextAuth() as { globalAuth: { uid: string, profile: TypeUser, auth: string | undefined } }
	const { profile, auth, uid: AuthUid } = globalAuth

	const [loadingSend, setLoadingSend] = useState(false)

	const isOffice = fnUser.isOffice(profile)
	const isOfficeAnalyst = fnUser.isOfficeAnalyst(profile)
	const isAdm = fnUser.isAdm(profile)

	const disabled = (isAdm || isOffice || isOfficeAnalyst) ? false : (teaser.dataPublicacao ? true : false)

	const [pageLoaded, setPageLoaded] = useState(false)
	const [statusModal, setStatusModal] = useState<TModal | null>(null)
	const history = useHistory()
	const [percent, setPercentage] = useState(0)
	const [errors, setErrors] = useState<TypeError>(fnOrigination.validateFields(teaser).errors)
	const [offline, setOffline] = useState<null | boolean>(null)
	const [showModalOffline, setShowModalOffline] = useState(false)

	useEffect(() => {
		if (auth && auth === 'offLine' && !offline) {
			setOffline(true)
			setShowModalOffline(true)
		}
	}, [auth, offline])

	useEffect(() => {
		if (teaserData && !loading && !pageLoaded) {
			setPageLoaded(true)
			_setTeaser(teaserData)
			setHasSite(teaserData.site ? true : false)
		}
	}, [teaser, loading, profile, teaserData, pageLoaded])

	useEffect(() => {
		const percent = getPercentageCompleted(teaser)
		setPercentage(percent)
		setErrors(fnOrigination.validateFields(teaser).errors)
	}, [teaser])
	getPercentageCompleted(teaser)
	function inputHandler(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		const { name, value } = e.target
		setTeaser({ ...teaser, [name]: value })
	}

	useEffect(() => {
		if (loadingSend === true) setGlobalLoading(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingSend])

	function addFile({ key, file }: { key: string, file: ITeaserFile }) {
		const files = teaser.files || {}
		files[key] = file
		setTeaser({ ...teaser, files })
	}

	function slideToError(args: { field?: string, key: string }) {
		const { field, key } = args
		const elName = field || key
		const section = document.getElementById(elName) || document.getElementsByName(elName)[0] as HTMLElement | null
		const elFocus = document.getElementById(key) || document.getElementsByName(key)[0] as HTMLElement | null
		const innerHeight = window.innerHeight
		if (section && innerHeight) {
			window.scrollTo(0, section.offsetTop - (innerHeight) / 2)
		}
		if (elFocus) {
			elFocus.focus()
		}
		setGlobalModal(previous => ({ ...previous, show: false }))

	}

	function getErrors(errors: { [key: string]: TError }) {
		const fields: ReactNode[] = []
		for (const key in errors) {
			if (Object.prototype.hasOwnProperty.call(errors, key)) {
				const element = errors[key]
				fields.push(<S.ButtonError className='pb-3 mb-3' onClick={() => slideToError({ field: element.field, key })}>
					<div className='d-flex'>
						<p className='flex-1 tw-text-sm'>{element.message}</p>
						<div className='d-flex justify-content-end  tw-w-[20%]'>
							<span style={{ textDecoration: 'underline' }} className='tw-text-blue-500'>Acessar</span>
						</div>
					</div>
				</S.ButtonError>)
			}
		}
		return fields
	}

	async function saveHandler(args?: TypeSaveHandler) {
		if (!id) return

		setGlobalLoading(true)

		const originationCopy = args?.origination ? { ...args?.origination } : { ...teaser }
		if (curatingAs) {
			originationCopy.lastUpdateCuration = helpersDate.convertAllToTimestamp(new Date())
		}

		const response = await fnOrigination.save({ id, origination: originationCopy })
		if (response.status !== 200) {
			setGlobalModal({
				show: true,
				child: <GlobalMessage
					title='Ocorreu um erro ao salvar os dados'
					description='Tente novamente mais tarde, ou entre em contato com nossa equipe.'
					okText='Ok'
					okHandler={() => {
						setGlobalModal(previous => ({ ...previous, show: false }))
					}}
					onClose={() => {
						setGlobalModal(previous => ({ ...previous, show: false }))
					}}
				/>
			}
			)
		}
		setGlobalLoading(false)

		if (args?.errorsCheck) {
			checkErrors({
				icon: <IconsDS.SaveOutlined color={'#D5D5D5'} size={45} />,
				title: 'Formulário salvo com sucesso!',
				description: <p>Alguns campos ainda estão incompletos, preencha as <br />informações abaixo para habilitar o envio.</p>
			})
		}
	}

	function closePublishModal() {
		setGlobalModal(previous => ({ ...previous, show: false }))
		setLoadingSend(false)
		setGlobalLoading(false)
	}

	function checkErrors(args: { icon: ReactNode, title: ReactNode, description: ReactNode }) {
		const { icon, title, description } = args
		const _errors: { [x: string]: TError } = fnOrigination.validateFields(teaser).errors
		if (Object.keys(_errors).length) {
			setGlobalModal({
				width: 456,
				show: true,
				child: <Overlay
					errors={getErrors(_errors)}
					icon={icon}
					title={title}
					description={description}
					okHandler={() => {
						setGlobalModal(previous => ({ ...previous, show: false }))
					}}
					onClose={() => {
						setGlobalModal(previous => ({ ...previous, show: false }))
					}}
				/>
			}
			)
			setErrors(_errors)
		}

		return !!Object.keys(_errors).length
	}

	// TODO: ISTO DEVE SER UMA FUNCITON DO TEASER
	async function checkCNPJ(cnpj: string) {
		// const _cnpjIsAvailable = await cnpjIsAvailable({ cnpj: cnpj, uid: AuthUid })
		// const _cnpjIsAvailable = await cnpjIsAvailable({ teaser: { ...teaser, cnpj: cnpj }, profile: profile, publishing: true })

		if (cnpj === '00.000.000/0000-00') {
			return true
		}

		const _cnpjIsAvailable = await cnpjIsAvailable({ teaser: { ...teaser, cnpj: cnpj }, profile: profile })
		if (_cnpjIsAvailable.data.status === 400) {
			return false
		}
		return true
	}

	const publishHandler = async () => {
		setLoadingSend(true)
		setGlobalLoading(true)
		const responseCheckCNPJ = await checkCNPJ(teaser.cnpj)

		// checkCNPJ
		if (!responseCheckCNPJ) {
			setGlobalModal({
				show: true,
				width: 300,
				child: <div className='tw-p-2'>
					<div className='tw-text-lg'>Verificação de CNPJ</div>
					<hr />
					<div className='tw-text-md'>Este CNPJ já foi originado por outro usuário.</div>
					<div className='tw-mt-3 tw-justify-end'>
						<UiButton onClick={() => setGlobalModal({ show: false, })}>Fechar</UiButton>
					</div>
				</div>
			})
			hideLoading()
			return
		}

		const _errors: { [x: string]: TError } = fnOrigination.validateFields(teaser).errors
		if (Object.keys(_errors).length) {
			checkErrors({
				icon: <IconsDS.Warning color={'#D5D5D5'} size={45} />,
				title: 'Você ainda não pode enviar o formulário pois alguns campos estão incompletos',
				description: <p>Preencha as informações abaixo para habilitar <br />o envio de sua originação, são eles:</p>
			})
			hideLoading()
			return
		}

		hideLoading()

		const msg1 = 'Uma vez publicada esta originação NÃO poderá ser alterada.'
		const title = teaser.noGuarantees ? 'Você tem certeza que deseja enviar sua originação sem garantias?' : 'Você tem certeza que deseja enviar essa originação?'
		const message = teaser.noGuarantees ? `Isso pode diminuir as chances da sua empresa ser aprovada no comitê. ${msg1}` : msg1

		setGlobalModal({
			show: true,
			child: <GlobalMessage
				title={title}
				description={message}
				okText='Ok'
				cancelText='Cancelar'
				cancelHandler={() => {
					closePublishModal()
				}}
				okHandler={async () => {
					closePublishModal()
					setLoadingSend(true)
					setGlobalLoading(true)

					if (offline) {
						await fnOrigination.createOffline({ origination: teaser, uid, uidCo, isFromOffice })
					}
					else if (id && !offline) {
						await fnOrigination.publish({
							origination: teaser,
							originationId: id,
							profile,
							uid: AuthUid
						})
					}
					hideLoading()
					history.push(`/teaser-add-obrigado/${teaser._id}`)
				}}
				onClose={() => {
					closePublishModal()
				}}
			/>
		}
		)
	}

	const hideLoading = () => {
		setLoadingSend(false)
		setGlobalLoading(false)
	}

	function deleteHandler() {
		setStatusModal({
			title: 'Você tem certeza de que deseja remover essa originação?',
			description: 'Os dados não poderão ser recuperados.',
			okText: 'Sim, excluir',
			closeText: 'Não, cancelar',
			type: ModalTypes.Delete,
			show: true,
			loading: false
		})
	}

	function closeModal() {
		if (!statusModal) return
		setStatusModal({ ...statusModal, show: false })
	}

	function okModalHandler() {
		if (!statusModal) return
		if (!id) return

		setStatusModal({ ...statusModal, loading: true })
		fnDeleteOrigination({
			id,
			cb: () => {
				history.push('/teasers')
			},
		})
	}
	function cancelModalHandler() {
		closeModal()
	}

	async function viewHandler() {
		await saveHandler()
		history.push(`/teaser/${id}`)
	}

	async function okModalOfflineHandler() {

	}

	return <FormContext.Provider value={{
		teaser,
		setTeaser,
		inputHandler,
		publishHandler,
		saveHandler,
		loading,
		deleteHandler,
		statusModal,
		closeModal,
		okModalHandler,
		cancelModalHandler,
		viewHandler,
		percent,
		errors,
		setErrors,
		offline,
		showModalOffline,
		okModalOfflineHandler,
		setShowModalOffline,
		isAdm,
		isOffice,
		isOfficeAnalyst,
		id,
		addFile,
		curatingAs,
		disabled
	}}>
		{children}
	</FormContext.Provider>
}

export function useContextPage() {
	const context = useContext(FormContext)
	return context
}
