import React, { FC, HTMLAttributes, ReactChild } from 'react'
import styled from 'styled-components'

/** @deprecated */
export interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
	/** Component children */
	children?: ReactChild | ReactChild[];
	/** Fixed header or not */
	fixed?: boolean;
	/** If the layout has a sider, the sider width */
	siderWidth?: number;
	/** If true, the header will have white bg, border bottom and shadow */
	defaultTheme?: boolean;
}

const LayoutStyled = styled.header`
    box-sizing: border-box;
    height: 76px;
    width: 100%;

    ${(props: HeaderProps) => props.fixed ? `
      position: fixed;
      top: 0;
      left: 0;
      ${props.siderWidth ? `padding-left: ${props.siderWidth}px;` : ''}
      z-index: 2;
    ` : ''}

    ${(props: HeaderProps) => {
		if (props.defaultTheme) {
			return `
          background: #FFFFFF;
          border-bottom: 1px solid #DFDFDF;
          box-sizing: border-box;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        `
		}
		return ''
	}}
`

/**
 * A custom header component
 * If extending it in styled-component, the name of the component must be named with Header, ex. HeaderBox
 */
/** @deprecated */
export const Header: FC<HeaderProps> = props => {
	const { children } = props
	return <LayoutStyled {...props}>{children}</LayoutStyled>
}

const DF: HeaderProps = {}

Header.defaultProps = DF

