import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const CrowdFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M16 16.875C17.9017 16.875 19.5817 17.33 20.9467 17.925C22.2067 18.485 23 19.745 23 21.11V23H9V21.1217C9 19.745 9.79333 18.485 11.0533 17.9367C12.4183 17.33 14.0983 16.875 16 16.875ZM6.66667 17.1667C7.95 17.1667 9 16.1167 9 14.8333C9 13.55 7.95 12.5 6.66667 12.5C5.38333 12.5 4.33333 13.55 4.33333 14.8333C4.33333 16.1167 5.38333 17.1667 6.66667 17.1667ZM7.985 18.45C7.55333 18.38 7.12167 18.3333 6.66667 18.3333C5.51167 18.3333 4.415 18.5783 3.42333 19.01C2.56 19.3833 2 20.2233 2 21.1683V23H7.25V21.1217C7.25 20.1533 7.51833 19.2433 7.985 18.45ZM25.3333 17.1667C26.6167 17.1667 27.6667 16.1167 27.6667 14.8333C27.6667 13.55 26.6167 12.5 25.3333 12.5C24.05 12.5 23 13.55 23 14.8333C23 16.1167 24.05 17.1667 25.3333 17.1667ZM30 21.1683C30 20.2233 29.44 19.3833 28.5767 19.01C27.585 18.5783 26.4883 18.3333 25.3333 18.3333C24.8783 18.3333 24.4467 18.38 24.015 18.45C24.4817 19.2433 24.75 20.1533 24.75 21.1217V23H30V21.1683ZM16 9C17.9367 9 19.5 10.5633 19.5 12.5C19.5 14.4367 17.9367 16 16 16C14.0633 16 12.5 14.4367 12.5 12.5C12.5 10.5633 14.0633 9 16 9Z' fill='#272827' />
	</Svg>
)
