import React from 'react'
import { VARIANTS_SIZES_TW } from 'Constants/VARIANTS_SIZES_TW'
import classNames from 'Functions/Utils/classNames'

import { formats, TypeUiInputFormats } from './config/formats'
import { clone } from 'Functions/Utils/clone'
import { mergeClassName } from '../../Functions/Utils/mergeClassName'

export interface IUiInput extends React.ComponentProps<'input'> {
	sizeInput?: 'small' | 'medium' | 'large'
	label?: string;
	leftIcon?: string;
	rightIcon?: string;
	helperText?: string;
	error?: boolean;
	correct?: boolean;
	/**
	 * @description Número de frações que o número deve ter
	 *
	 * @example fraction = 2 -> 200,00
	 * @example fraction = 1 -> 200,0
	 * @example fraction = 0 -> 200
	*/
	fraction?: number;
	validate?: (value: string) => boolean;
	/** Tipo de campo para formatação. Ex: cpf, cnpj, currency */
	format?: TypeUiInputFormats
	/** Sigla da moeda para formatar moedas. Ex: BRL, EUR, USD */
	currency?: string;
	/** Sigla do país para formatar celular. Exemplo: BR, US, FR */
	country?: string;
	/** Unidade de qualquer coisa que deseja formatar. Ex: 2 "anos", 3 "meses", 4 "originações",
	 * 6 "agentes"
	*/
	unit?: string; // meses, anos, dias
	/** inclui números negativos no formato "number_unit" */
	includesNegativeNumbers?: boolean;

	minDate?: Date;
	maxDate?: Date;
	helperTextColor?: string
}

export const UiInput = ({
	sizeInput = 'medium',
	validate,
	...props }: IUiInput) => {

	props = formats(props)

	const propsInput = clone(props)

	// tem que apagar variaveis booleanas nao nativas do input do HTML se nao dá erro no console
	delete propsInput.error
	delete propsInput.correct
	delete propsInput.includesNegativeNumbers

	return (
		<div
			className={
				mergeClassName(
					`tw-transition tw-duration-300 tw-w-full
					${props.error ? 'tw-text-red-500' : (props.correct ? 'tw-text-green-500' : 'tw-text-neutral-500')}
					cy-${props.label?.replace(/[^A-Za-zÀ-úà-ú\s]+/g, '').replace(/\s/g, '-').replace(/[/]/g, '').replace(/[?]/g, '').replace(/[,]/g, '')}
					`, props.className)
			}
		>
			{/* {props.label && <UiLabel label={props.label} />} */}
			{props.label && <p
				className={classNames(
					'tw-mb-1 tw-font-semibold tw-text-neutral-900 tw-text-xs tw-flex tw-justify-between tw-items-center tw-relative'
				)}
			>
				{props.label}
			</p>}

			<div className={'tw-relative tw-rounded-md'}>
				{props.leftIcon && <i className={
					`
				material-icons tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-text-base
				tw-flex tw-items-center tw-pointer-events-none`
				}>
					{props.leftIcon}
				</i>}
				<input
					{...propsInput}
					className={
						`${VARIANTS_SIZES_TW[sizeInput]}
					 disabled:tw-text-neutral-400 tw-transition-all disabled:tw-bg-neutral-100 tw-duration-300 tw-border tw-border-solid tw-outline-none tw-border-neutral-300 focus:tw-border-blue-500 tw-w-full tw-rounded tw-px-3
					${props.leftIcon ? 'tw-pl-10' : ''}
					${props.rightIcon ? 'tw-pr-10' : ''}
					${props.error ? 'tw-border-red-300 hover:color-red-000 tw-bg-red-000 focus:tw-border-red-500 tw-text-red-500' : 'tw-border-neutral-300 focus:tw-border-blue-500'}
					${props.correct ? 'tw-border-green-300 tw-bg-green-000 focus:tw-border-green-500 focus:tw-text-green-500 tw-text-green-500' : 'tw-border-neutral-300 focus:tw-border-blue-500'}
					${!props.error && !props.correct ? 'hover:tw-bg-neutral-000 focus:tw-text-neutral-900 tw-text-neutral-500' : ''}`
					}
				/>
				{
					props.rightIcon && <i className={
						`
				material-icons tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none tw-text-base
			`
					}>{props.rightIcon}</i>
				}
			</div >

			{
				props.helperText &&
				<p className={
					`
					${props.helperTextColor ? `tw-text-${props.helperTextColor}` : ''}
				tw-mt-2 tw-mb-0
			`
				}
				>{props.helperText}</p>
			}

		</div >
	)
}
