import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { FOLLOWUP_CONTACT_TYPES } from '../../../Constants/FOLLOWUP_CONTACT_TYPES'
import { TypeFollowupMessage } from '../types/TypeFollowup'

export const modelFollowupMessage = (): TypeFollowupMessage => ({
	createdAt: helpersDate.convertAllToTimestamp(new Date()),
	date: null,
	type: FOLLOWUP_CONTACT_TYPES.c100._id,
	analystsIncopy: [] as { email: string, name: string }[],
	scheduleAnalyst: {
		email: '',
		name: ''
	},
	subject: '',
	message: '',
	googleEmailId: '',
	from: {
		name: '',
		email: ''
	},
	user: {
		_id: '',
		name: '',
		email: '',
		role: ''
	},
	sent: false
})
