/**
 * A função `isMA` verifica se o tipo de operação de um determinado teaser é `op1`
 * ou `op3`.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido em algum lugar da sua base de código. Provavelmente contém
 * informações sobre um teaser, como seu tipo de funcionamento. A função `isMA`
 * clona o objeto `teaser` usando a função `clone`
 * @returns A função `isMA` está retornando um valor booleano baseado na condição
 * que a propriedade `typeOperation` do objeto `teaser` clonado é igual a
 * `TYPE_OPERATION.op1` ou `TYPE_OPERATION.op3`. O valor de retorno será `true` se
 * a condição é atendida e `false` caso contrário.
 */
//CRIADO POR IA

import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const isMA = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.typeOperation === TYPE_OPERATION.op1 || cloneTeaser.typeOperation === TYPE_OPERATION.op3

}
