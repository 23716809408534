import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

type TypeOutlierFields = 'ebitdaMultiple' | 'billingMultiple'
export type TypeOutlierResult = { isOutlier: boolean, type?: TypeOutlierFields }

const calculatesAverage = (transactions: TypeTransaction[], field: TypeOutlierFields): number => {
	let average = 0

	transactions.forEach((transaction: TypeTransaction) => {
		const _fieldValue = transaction[field]
		if (_fieldValue) average += _fieldValue
	})

	return average / transactions.length
}

const calculatesStandardDeviation = (transactions: TypeTransaction[], average: number, field: TypeOutlierFields) => {
	let variance = 0

	for (const transaction of transactions) {
		const _fieldValue = transaction[field]
		if (_fieldValue) variance += Math.pow((average - _fieldValue), 2)
	}

	variance = variance / transactions.length

	return Math.sqrt(variance)
}

export const checkOutlier = (transactions: TypeTransaction[], transaction: TypeTransaction): TypeOutlierResult => {
	if (checkOutlierByField(transactions, transaction, 'ebitdaMultiple')) return { isOutlier: true, type: 'ebitdaMultiple' }
	if (checkOutlierByField(transactions, transaction, 'billingMultiple')) return { isOutlier: true, type: 'billingMultiple' }
	return { isOutlier: false }
}

const checkOutlierByField = (transactions: TypeTransaction[], transaction: TypeTransaction, field: TypeOutlierFields): boolean => {
	const average = calculatesAverage(transactions, field)
	const standardDeviation = calculatesStandardDeviation(transactions, average, field)
	const min = average - 2 * standardDeviation
	const max = average + 2 * standardDeviation
	const fieldValue = transaction[field]
	if (fieldValue && (fieldValue < min || fieldValue > max)) return true
	return false
}
