import { helpersDate } from 'Functions/Utils/helpersDate'
import { TRANSACTION_TYPES } from 'Screens/Sinatra/Constants/TransactionTypes'
import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

export const modelTransaction = (transaction?: Partial<TypeTransaction>): TypeTransaction => ({
	_id: transaction?._id || '',
	createdAt: transaction?.createdAt || helpersDate.newDate(),
	soldCompanyName: transaction?.soldCompanyName || '',
	buyerCompanies: transaction?.buyerCompanies || [],
	dateTransaction: transaction?.dateTransaction || null,
	percentage: transaction?.percentage || null,
	value: transaction?.value || null,
	ebitda: transaction?.ebitda || null,
	ebitdaMultiple: transaction?.ebitdaMultiple || null,
	enterpriseValue: transaction?.enterpriseValue || null,
	billing: transaction?.billing || null,
	billingMultiple: transaction?.billingMultiple || null,
	source: transaction?.source || '',
	tags: transaction?.tags || [],
	createdBy: transaction?.createdBy || { uid: '', name: '' },
	fromOffice: transaction?.fromOffice || '',
	type: transaction?.type || TRANSACTION_TYPES.private._id as keyof typeof TRANSACTION_TYPES
})
