export const STATUS_CUSTOMER = {
	notPublish: { value: 'notPublish', label: 'Não publicado' },
	comite: { value: 'comite', label: 'Comitê' },
	comercial: { value: 'comercial', label: 'Comercial' },
	standby: { value: 'standby', label: 'Stand-by' },
	ativo: { value: 'ativo', label: 'Ativo' },
	inactive: { value: 'inativo', label: 'Inativo' },
} as const

export const STATUS_CUSTOMER_GET = (code: string) => {
	const _code = code as keyof typeof STATUS_CUSTOMER

	if (!STATUS_CUSTOMER[_code]) {
		return (STATUS_CUSTOMER.notPublish)
	}

	return STATUS_CUSTOMER[_code]
}
export const STATUS_CUSTOMER_VALUES = Object.values(STATUS_CUSTOMER)
export const STATUS_CUSTOMER_KEYS = Object.keys(STATUS_CUSTOMER)
