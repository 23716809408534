import { Financing } from '../../../Constants/FINANCING'
import { TEASER_FIELDS } from '../../../Constants/TEASER_FIELDS'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeError } from './validateFields'

const ZERO_VALUE = ['0.00', '0,00']
const msg1 = 'Todos os campos de um Financiamento de obras, exceto observações, são obrigatórios'
const msg2 = 'Todos os campos de um Financiamento de projetos do zero são obrigatórios'

export const validateFinancing = (origination: TypeTeaser) => {
	const { financing } = origination
	const errors: TypeError = {}

	if (financing && Object.keys(financing).length) {

		for (const key in financing) {
			if (Object.prototype.hasOwnProperty.call(financing, key)) {
				const list = financing[key as Financing]
				list?.map(i => {
					// Financiamento de obras
					if ('type' in i && !i.type)
						errors[TEASER_FIELDS.financingObras] = { message: msg1 }

					if ('sales' in i && !i.sales)
						errors[TEASER_FIELDS.financingObras] = { message: msg1 }

					if ('construction' in i && !i.construction)
						errors[TEASER_FIELDS.financingObras] = { message: msg1 }

					if ('vgv' in i && (!i.vgv || ZERO_VALUE.includes(i.vgv)))
						errors[TEASER_FIELDS.financingObras] = { message: msg1 }

					if ('saldoCarteiraRecebiveis' in i && (!i.saldoCarteiraRecebiveis || ZERO_VALUE.includes(i.saldoCarteiraRecebiveis)))
						errors[TEASER_FIELDS.financingObras] = { message: msg1 }

					if ('timeline' in i && (!i.timeline || ZERO_VALUE.includes(i.timeline)))
						errors[TEASER_FIELDS.financingObras] = { message: msg1 }

					// financiamento de projetos do zero
					if ('totalBudget' in i && (!i.totalBudget || ZERO_VALUE.includes(i.totalBudget)))
						errors[TEASER_FIELDS.financingProjetosZero] = { message: msg2 }

					if ('valueByThird' in i && (!i.valueByThird || ZERO_VALUE.includes(i.valueByThird)))
						errors[TEASER_FIELDS.financingProjetosZero] = { message: msg2 }

					if ('valueByPartnes' in i && (!i.valueByPartnes || ZERO_VALUE.includes(i.valueByPartnes)))
						errors[TEASER_FIELDS.financingProjetosZero] = { message: msg2 }

				})
			}
		}
	}

	return Object.keys(errors) ? errors : null
}
