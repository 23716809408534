/**
 * A função `getLastDate` retorna a última data relevante com base no status de
 * um objeto teaser.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um objeto que contém informações sobre um teaser. A função
 * `getLastDate` pega este objeto `teaser` como entrada e retorna a última data
 * associado ao teaser com base em seu status.
 * @returns A função `getLastDate` retorna a última data associada a um
 * determinado objeto teaser com base em seu status. A função verifica o status do
 * teaser usando funções auxiliares como `inAtivo`, `inComercial`, `inComite`,
 * `inStandby`, e `isNotPublicado`, e então retorna a data correspondente com base
 * no status. Se nenhuma das condições corresponder, ele retorna
 */
//CRIADO POR IA

import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'
import { inComercial } from './inComercial'
import { inComite } from './inComite'
import { inStandby } from './inStandby'
import { isNotPublicado } from './isNotPublicado'
import { minhaData } from '../../../Functions/Utils/minhaData'
import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { clone } from '../../../Functions/Utils/clone'


export const getLastDate = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	if (inAtivo(cloneTeaser)) return cloneTeaser.subStatusOfActiveCustomerDate ? minhaData(helpersDate.convertTimestampToDate(cloneTeaser.subStatusOfActiveCustomerDate)) : cloneTeaser.dataContrato
	if (inComercial(cloneTeaser)) return cloneTeaser.dataComercial
	if (inComite(cloneTeaser)) return cloneTeaser.dataPublicacao
	if (inStandby(cloneTeaser)) return cloneTeaser.dataStandby
	if (isNotPublicado(cloneTeaser)) return cloneTeaser.dataCriacao

	return 0
}
