/**
 * Calcula um array de números com valores elásticos, onde cada valor é calculado de forma que a soma total seja aproximadamente 95.
 * A quantidade de números no array é determinada pelo número de milissegundos fornecidos.
 *
 * @param miliSecond O número de milissegundos usado para determinar a quantidade de números no array e seus valores elásticos.
 * @returns Um array de números com valores elásticos calculados.
 *
 * @example
 * / Exemplo de uso:
 * / Suponha que queremos calcular valores elásticos com base em 5000 milissegundos:
 * const result = elasticFunction(5000);
 * / O array 'result' conterá valores elásticos calculados, onde a soma total dos valores é aproximadamente 95.
 */

export const elasticFunction = (miliSecond: number): number[] => {
	const tamanhoDoArray = miliSecond / 1000
	let result: number[] = Array(tamanhoDoArray)
	// preencher o array com 1 em todas as posições
	result.fill(1, 0, tamanhoDoArray)

	// multiplicar cada item do array por 1.5 elevado a sua posição
	result = result.map((item, index) => item * (1.5) ** index)

	result.reverse()

	const somaDeTodosOsElementos = result.reduce((acc, item) => acc + item, 0)

	// fazer a regra de 3 para que o total seja 95
	result = result.map(item => {
		const x = (95 * item) / somaDeTodosOsElementos
		return x
	})

	return result
}
