import { TEASER_FIELDS } from '../../../Constants/TEASER_FIELDS'

type Props = {
	field: string
	value: any
}

export const validateField = (props: Props) => {
	const { field, value } = props

	if (
		field === TEASER_FIELDS.governanceAuditedBalance ||
		field === TEASER_FIELDS.governanceRevenueOutside ||
		field === TEASER_FIELDS.projectBudget ||
		field === TEASER_FIELDS.desiredValue ||
		field === TEASER_FIELDS.rb2 ||
		field === TEASER_FIELDS.rb1 ||
		field === TEASER_FIELDS.eb2 ||
		field === TEASER_FIELDS.eb1) {
		return !!(value && String(value) !== '0,00' && String(value) !== '0.00')
	}

	if (field === TEASER_FIELDS.description) {
		return !!value
	}

	return false
}
