import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Write = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M3.21286 5.92882C3.94942 5.19224 4.94841 4.77844 5.99006 4.77844H14.7943C15.5731 4.77844 16.2044 5.40975 16.2044 6.18851C16.2044 6.96728 15.5731 7.59859 14.7943 7.59859H5.99006C5.69634 7.59859 5.41465 7.71527 5.20696 7.92296C4.99927 8.13066 4.88259 8.41235 4.88259 8.70607V26.3149C4.88259 26.6087 4.99927 26.8903 5.20696 27.098C5.41465 27.3057 5.69634 27.4224 5.99006 27.4224H23.5986C23.8923 27.4224 24.174 27.3057 24.3817 27.098C24.5894 26.8903 24.7061 26.6087 24.7061 26.3149V17.5808C24.7061 16.8021 25.3374 16.1708 26.1161 16.1708C26.8948 16.1708 27.5261 16.8021 27.5261 17.5808V26.3149C27.5261 27.3566 27.1124 28.3556 26.3758 29.0922C25.6392 29.8288 24.6402 30.2426 23.5986 30.2426H5.99006C4.94841 30.2426 3.94942 29.8288 3.21286 29.0922C2.47629 28.3556 2.0625 27.3566 2.0625 26.3149V8.70607C2.0625 7.6644 2.4763 6.66539 3.21286 5.92882Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M25.1871 5.34281L13.6217 16.9632L13.0293 19.3461L15.4079 18.7548L26.9599 7.12041C27.1521 6.88368 27.2524 6.58493 27.2413 6.27933C27.2298 5.96054 27.098 5.65795 26.8725 5.43239C26.6469 5.20683 26.3443 5.07504 26.0255 5.06352C25.7211 5.05252 25.4234 5.15202 25.1871 5.34281ZM23.3092 3.23746C24.0895 2.56248 25.0963 2.20797 26.1273 2.24522C27.1584 2.28247 28.137 2.70872 28.8666 3.43825C29.5961 4.16777 30.0223 5.14647 30.0596 6.1775C30.0968 7.20854 29.7423 8.21544 29.0673 8.99571C29.0462 9.02013 29.0243 9.04381 29.0015 9.06672L17.1312 21.0216C16.9495 21.2046 16.7211 21.3343 16.4708 21.3965L11.4358 22.6483C10.9558 22.7676 10.4483 22.6267 10.0986 22.2769C9.74881 21.9271 9.60789 21.4197 9.72723 20.9396L10.979 15.9045C11.0405 15.657 11.168 15.4308 11.3479 15.25L23.2323 3.30917C23.257 3.28432 23.2827 3.2604 23.3092 3.23746Z' fill='#272827' />
	</Svg>
)
