import * as Tokens from 'Components/CardDS/src/Tokens'

const { SM, MD, LG, PROGRESS_SM, PROGRESS_MD,
	PROGRESS_LG, SUCCESS, PRIMARY, DANGER,
	PROGRESS_COLOR_SUCCESS, PROGRESS_BACKGROUND_DEFAULT,
	PROGRESS_COLOR_PRIMARY, PROGRESS_COLOR_DANGER } = Tokens

export const ProgressTheme = {
	sizes: {
		[SM]: {
			size: PROGRESS_SM,
		},
		[MD]: {
			size: PROGRESS_MD,
		},
		[LG]: {
			size: PROGRESS_LG,
		}
	},
	status: {
		[PRIMARY]: {
			barColor: PROGRESS_COLOR_PRIMARY,
			bgColor: PROGRESS_BACKGROUND_DEFAULT
		},
		[SUCCESS]: {
			barColor: PROGRESS_COLOR_SUCCESS,
			bgColor: PROGRESS_BACKGROUND_DEFAULT
		},
		[DANGER]: {
			barColor: PROGRESS_COLOR_DANGER,
			bgColor: PROGRESS_BACKGROUND_DEFAULT
		}
	}
}
