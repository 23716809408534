import { clone } from '../../../Utils/clone'
import { TypeTeaser } from '../../types/teaser'

export const getFirstRb = (teaser: TypeTeaser): number => {

	const cloneTeaser = clone(teaser)

	if (cloneTeaser.rb4) return +cloneTeaser.rb4
	if (cloneTeaser.rb3) return +cloneTeaser.rb3
	if (cloneTeaser.rb2) return +cloneTeaser.rb2
	if (cloneTeaser.rb1) return +cloneTeaser.rb1

	return +cloneTeaser.rb0
}
