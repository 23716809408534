/**
 * A função setHub atualiza o campo InstitutionHub de um teaser no db5
 * banco de dados com os valores id e hubId fornecidos.
 * @param {string} id - O parâmetro `id` é uma string que representa o ID de um
 * provocação.
 * @param {string} hubId - O parâmetro `hubId` é uma string que representa o ID
 * de um hub.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'

//set
export const setHub = (id: string, hubId: string) => {

	const cloneId = clone(id)
	const cloneHubId = clone(hubId)

	db5.teasers.update({
		id: cloneId,
		doc: {
			institutionHub: cloneHubId
		}
	})
}
