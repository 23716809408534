import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const MoneyOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M18.7077 14.7273H17.2308V9.12727H21.5385C22.2769 9.12727 22.7692 8.61818 22.7692 7.85455C22.7692 7.09091 22.2769 6.58182 21.5385 6.58182H17.2308V3.27273C17.2308 2.50909 16.7385 2 16 2C15.2615 2 14.7692 2.50909 14.7692 3.27273V6.58182H13.1692C10.3385 6.58182 8 9 8 11.9273C8 14.8545 10.3385 17.2727 13.1692 17.2727H14.7692V22.8727H9.23077C8.49231 22.8727 8 23.3818 8 24.1455C8 24.9091 8.49231 25.4182 9.23077 25.4182H14.7692V28.7273C14.7692 29.4909 15.2615 30 16 30C16.7385 30 17.2308 29.4909 17.2308 28.7273V25.4182H18.8308C21.6615 25.4182 24 23 24 20.0727C24 17.1455 21.5385 14.7273 18.7077 14.7273ZM13.1692 14.7273C11.6923 14.7273 10.4615 13.4545 10.4615 11.9273C10.4615 10.4 11.6923 9.12727 13.1692 9.12727H14.7692V14.7273H13.1692ZM18.7077 22.8727H17.2308V17.2727H18.8308C20.3077 17.2727 21.5385 18.5455 21.5385 20.0727C21.5385 21.6 20.1846 22.8727 18.7077 22.8727Z' fill='#272827' />
	</Svg>
)
