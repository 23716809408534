/**
 * A função `inAtivoWitchMatchmaking` verifica se um teaser está ativo e possui um
 * substatus específico relacionado ao matchmaking.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido em algum lugar da sua base de código. Provavelmente contém
 * informações relacionadas a um teaser ou conteúdo promocional. No
 * Função `inAtivoWitchMatchmaking`, este parâmetro está sendo passado para o `clone
 * @returns A função `inAtivoWitchMatchmaking` está retornando um valor booleano. Isto
 * verifica se o objeto `teaser` passado como parâmetro atende a certas condições:
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'

export const inAtivoWitchMatchmaking = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const subStatusOfActiveCostumerInMatchmaking: (keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER)[] = [
		SUB_STATUS_OF_ACTIVE_COSTUMER.s00100.code,
	]

	return inAtivo(cloneTeaser) &&
		subStatusOfActiveCostumerInMatchmaking.includes(cloneTeaser.subStatusOfActiveCostumer)

}
