import classNames from 'Functions/Utils/classNames'
import React from 'react'
import { UiLabel } from './UiLabel'

export interface IUiTextArea extends React.ComponentProps<'textarea'> {
	sizeInput?: 'small' | 'medium' | 'large'
	label?: string;
	helperText?: string;
	error?: boolean
}

export const variantsSize = {
	'small': 'tw-text-xs',
	'medium': 'tw-text-sm',
	'large': 'tw-text-base'
}

export const UiTextArea = ({ sizeInput = 'medium', rows = 7, label, helperText, error, ...props }: IUiTextArea) =>
	<div
		className={
			classNames(
				`tw-transition tw-duration-300 tw-w-full cy-${label?.replace(/[^A-Za-zÀ-úà-ú\s]+/g, '').replace(/\s/g, '-').replace(/[/]/g, '').replace(/[?]/g, '').replace(/[,]/g, '')}`
			)
		}
	>
		{label && <UiLabel>
			{label}
		</UiLabel>}
		<textarea
			{...props}
			rows={rows}
			className={`tw-placeholder-neutral-400/75 ${variantsSize[sizeInput]} tw-flex tw-items-center tw-w-full tw-p-3 tw-rounded-md tw-gap-2
			 tw-transition tw-duration-300 tw-border tw-border-solid tw-outline-none tw-bg-white
			disabled:tw-bg-neutral-100 tw-resize-none
			${error ? 'tw-border-red-300 focus:tw-border-red-500' : 'tw-border-neutral-300 focus:tw-border-blue-500'} ${props.className || ''}`}
		/>
		{helperText && <p className={classNames('tw-mt-2', error ? 'tw-text-red-500' : 'tw-text-neutral-500')}>{helperText}</p>}

	</div >
