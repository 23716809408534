import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { STATUS_MATCH_MAKING } from '../../../Constants/STATUS_MATCH_MAKING'
import { SUB_STATUS_OF_MATCH } from '../../../Constants/SUB_STATUS_OF_MATCH'
import { fnNotification } from '../../../Functions/Notification'
import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../types/match'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeTese } from '../../Tese/types/tese'
import { TypeUser } from '../../User/types/TypeUser'
import { setMatch } from './setMatch'
import { getHighestStatusFromMatches } from '../../../Services/cloudfunctions'
import { clone } from '../../../Functions/Utils/clone'
import { helpersDate } from '../../Utils/helpersDate'
import { fnError } from '../../Errors/fnError'

// Contratos definitivos
export const toDefinitiveContracts = async ({
	matchId,
	match,
	teaser,
	teaserId,
	profile,
	institution,
	institutionId,
	uid,
	tese,
	teseId,
	inMatchNegative
}: {
	matchId: string
	match: TypeMatch
	teaser: TypeTeaser
	teaserId: string
	profile: TypeUser
	institution: TypeInstitution
	institutionId: string
	uid: string
	tese: TypeTese
	teseId: string
	inMatchNegative?: boolean
}) => {

	const cloneMatchId = clone(matchId)
	const cloneMatch = clone(match)
	const cloneTeaser = clone(teaser)
	const cloneTeaserId = clone(teaserId)
	const cloneProfile = clone(profile)
	const cloneInstitution = clone(institution)
	const cloneInstitutionId = clone(institutionId)
	const cloneUid = clone(uid)
	const cloneTese = clone(tese)
	const cloneTeseId = clone(teseId)
	const cloneInMatchNegative = clone(inMatchNegative)


	const getHighestStatus = async () => {
		await getHighestStatusFromMatches({
			'id': cloneTeaserId
		})
	}

	await setMatch({
		id: cloneMatchId,
		match: {
			...cloneMatch,
			status: STATUS_MATCH.match.value,
			matchMakingStatus: STATUS_MATCH_MAKING.match.value,
			matchStatus: SUB_STATUS_OF_MATCH[400]._id as keyof typeof SUB_STATUS_OF_MATCH,
			dateContract: helpersDate.convertAllToTimestamp(new Date()),
		}
	}).then(() => {
		getHighestStatus()
	})

	if (cloneInMatchNegative) {
		const response = await fnNotification.sendInativeToMatch({
			matchId: cloneMatchId,
			match: cloneMatch,
			profile: cloneProfile,
			uid: cloneUid,
			origination: cloneTeaser,
			originationId: cloneTeaserId,
			institution: cloneInstitution,
			institutionId: cloneInstitutionId
		})
		if (response.responseInactive.status !== 200) fnError.alert({ message: 'Ocorreu um erro, tente novamente mais tarde' })
	}
	else {
		const response = await fnNotification.customer.sendContratosDefinitivos({
			profile: cloneProfile,
			origination: cloneTeaser,
			originationId: cloneTeaserId,
			uid: cloneUid,
			institution: cloneInstitution,
			institutionId: cloneMatch?.idInstitution,
			match: cloneMatch,
			matchId: cloneMatchId
		})
		if (response.status !== 200) fnError.alert({ message: 'Ocorreu um erro, tente novamente mais tarde' })
	}

}
