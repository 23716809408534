import { TypeTeaser } from '../../Teaser/types/teaser'
import { db5 } from '../../../Services/db5'

type Props = {
	id: string
	origination: TypeTeaser
}

export const save = async (args: Props) => {
	const { id, origination } = args

	return await db5.teasers.update({ id: id, doc: origination })
}
