import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { UidsNotification } from '../../../Origination/methods/getUidsNotification'
import { fnUser } from '../../../User'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.a0006

type Props = {
	origination: TypeTeaser,
	originationId: string,
	profile?: TypeUser,
	uid: string
}

export const sendOriginationAdjust =
	async ({ uid, origination, profile, originationId }: Props) => {

		const cloneUid = clone(uid)
		const cloneOrigination = clone(origination)
		const cloneProfile = clone(profile)
		const cloneOriginationId = clone(originationId)

		return await fnNotification.send({
			uiPermissions: ITEM.permissions,
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination, type: UidsNotification.a01 }),
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile || {} as TypeUser, uid: cloneUid }),
			user: fnUser.getUser({ user: cloneProfile || {} as TypeUser }),
			message: ITEM.msg, type: ITEM._id
		} as TypeNotification)
	}


