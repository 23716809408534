import { TypeTeasers } from '../../Teaser/types/teasers'

/**
 * Comissão por Originação
 */
export const getCommissionPerOrigination = ({ teasers }: { teasers: TypeTeasers }) => {
	const teasersInComercial = Object.values(teasers).filter(teaser => !!teaser.dataComercial)
	const commission = teasersInComercial.reduce((_commission, teaser) => _commission + teaser.commissionPerOrigination, 0)

	return {
		commission
	}
}
