import { clone } from '../../Utils/clone'
import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { TypeManualMatch } from '../types/manualMatch'

export const modelManualMatch = (obj = {} as Partial<TypeManualMatch>) => {

	const cloneObj = clone(obj)

	return ({
		uidTeaser: cloneObj.uidTeaser || '',
		idTeaser: cloneObj.idTeaser || '',
		teaser: cloneObj.teaser,
		institutions: cloneObj.institutions || [],
		institutionsData: cloneObj.institutionsData || {},
		institutionsOnline: cloneObj.institutionsOnline || [],
		typeOperation: cloneObj.typeOperation || [TYPE_OPERATION.op1]
	})
}
