/**
 * A função `toggleLine` atualiza a propriedade `onLine` de um documento no
 * Serviço `db5` baseado no `id` e `value` fornecidos.
 * @param {string} id - O parâmetro `id` é uma string que representa o
 * identificador de um item ou objeto específico.
 * @param {boolean} value - O parâmetro `value` na função `toggleLine` é um
 * valor booleano que indica se uma linha deve ser ativada ou desativada.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'

export const toggleLine = (id: string, value: boolean) => {

	const cloneId = clone(id)
	const cloneValue = clone(value)

	db5.teasers.update({ id: cloneId, doc: { onLine: cloneValue } })
}
