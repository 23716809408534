const _AGENT_PROFILE = {
	ap000: { label: '', _id: 'ap000' },
	ap001: { label: 'Agente Originador', _id: 'ap001' },
	ap002: { label: 'Agente Institucional', _id: 'ap002' },
	ap003: { label: 'Confrade', _id: 'ap003' },
} as const

const VALUES = Object.values(_AGENT_PROFILE)

const KEYS = Object.keys(_AGENT_PROFILE)

const GET = (code: string) => {
	const _code = code as keyof typeof _AGENT_PROFILE

	if (!_AGENT_PROFILE[_code]) {
		return (_AGENT_PROFILE.ap000)
	}

	return _AGENT_PROFILE[_code]
}

export type AGENT_PROFILE_TYPE = keyof typeof _AGENT_PROFILE
export const AGENT_PROFILE = {
	VALUES,
	KEYS,
	GET
}

