import { fnNotification } from '../../../Functions/Notification'
import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeNotifications } from '../../Notification/types/notification'
import { TypeUser } from '../types/TypeUser'

export const unReadNotificationsGeneral = ({ profile, callback }: { profile: TypeUser, callback: (notifications: TypeNotifications) => void }) => {

	const cloneProfile = clone(profile)

	const uid = cloneProfile?._id || ''

	db5.notifications.getWhereSync({
		profile,
		wheres: [
			['uids', 'array-contains', uid],
			[`unReadBy.${uid}`, '==', true]
		],
		callBack: docs => {
			const response: TypeNotifications = Object
				.keys(docs?.docs || {})
				.reduce((prev, cur) => ({ ...prev, [cur]: fnNotification.modelNotification(docs?.docs?.[cur]) }), {})
			callback(response)
		}
	})


}
