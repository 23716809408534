import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const FootballFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M11.5917 27.6426L5.00391 21.0548C5.00391 22.387 5.0528 23.6215 5.24835 24.7459C5.43168 25.8704 5.61502 26.5426 5.87168 26.7748C6.10391 27.0315 6.76391 27.2148 7.87613 27.4104C8.98835 27.6059 10.2228 27.6426 11.5917 27.6426ZM20.2817 14.0637L13.425 20.9204L11.7261 19.2215L18.5828 12.3648L20.2817 14.0637ZM5.35835 17.9626L14.6839 27.2881C18.0817 26.677 20.7706 25.3815 22.7628 23.4015C24.7428 21.4092 26.0383 18.7204 26.6495 15.3226L17.3239 5.99702C13.9261 6.60813 11.2372 7.90369 9.24502 9.88369C7.25279 11.8637 5.96946 14.5648 5.35835 17.9626ZM20.4161 5.64258L27.0039 12.2304C27.0039 10.8981 26.955 9.66369 26.7717 8.53924C26.5761 7.4148 26.3928 6.74258 26.1361 6.51036C25.9039 6.25369 25.2439 6.07036 24.1317 5.88702C23.0195 5.70369 21.785 5.64258 20.4161 5.64258Z' fill='#272827' />
	</Svg>
)


