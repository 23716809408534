import { clone } from '../../../Functions/Utils/clone'
import { fnTeaser } from '../../../Functions/Teaser'
import { TypeTeasers } from '../../Teaser/types/teasers'
import { TypeUser } from '../types/TypeUser'

export const generateRankingAgent = (user: TypeUser, id: string, teasers: TypeTeasers) => {

	const cloneUser = clone(user)
	const cloneTeasers = clone(teasers)
	const cloneId = clone(id)

	const teasersFilters = Object.entries(cloneTeasers).filter(([idTeaser, te]: [string, any]) =>
		te?.users?.includes(cloneId)
	)

	let p0 = 0 // soma de todos os p (de 1 a 5)
	const p1 = cloneUser?.academy ? 1 : 0 // Academy finalizada
	let p2 = 0 // Originações publicadas
	let p3 = 0 // "Originações aprovada (comercial)"
	let p4 = 0 // Contratos fechados (cliente ativo)
	const p5 = cloneUser?.deals || 0 //Deals fechados

	teasersFilters.map(([idT, teaser]) => {
		if (fnTeaser.isPublicado(teaser)) p2 += 1
		if (fnTeaser.inComercial(teaser)) p3 += 1
		if (fnTeaser.inAtivo(teaser)) p4 += 1
	})

	p0 = p1 + p2 + p3 + p4 + p5

	return ({
		p0,
		p1,
		p2,
		p3,
		p4,
		p5
	})
}
