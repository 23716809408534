import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'
import { db5 } from 'Services/db5'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { modelValuation } from './model'
import { fnValuation } from '.'


/**
 * Função usada para retornar um valuation
 * @param valuation TypeValuation
 * @param profile TypeUser
 * @returns valuation {TypeValuation}
 */
export const createValuation = async ({ valuation, profile }: { valuation: Partial<TypeValuation>, profile: TypeUser }) => {
	const newValuation: TypeValuation = { ...modelValuation(valuation), createdBy: { uid: profile._id, name: `${profile.nome} ${profile.sobrenome || ''}` }, fromOffice: profile.fromOffice || '' }
	const temporaryValuation = fnValuation.getTemporaryValuation(newValuation)
	newValuation.valuationResult = {
		...newValuation.valuationResult,
		// openCapital: temporaryValuation[newValuation.valuationResult.type].open.value,
		// closedCapital: temporaryValuation[newValuation.valuationResult.type].closed.value,
		// total: temporaryValuation[newValuation.valuationResult.type].total.value,
	}
	return await db5.sinatraValuations.add({ doc: newValuation })
}


