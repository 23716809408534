import { TypeMatch } from '../types/match'
import { fnMatch } from '..'
import { clone } from '../../../Functions/Utils/clone'

export const model = (match?: TypeMatch) => {

	const cloneMatch = clone(match)

	return fnMatch.modelMatch(cloneMatch)
}
