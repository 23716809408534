export const TESE_CREDIT_INDEXERS_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_INDEXERS

	if (!TESE_CREDIT_INDEXERS[_code]) {
		return (TESE_CREDIT_INDEXERS.i000)
	}

	return TESE_CREDIT_INDEXERS[_code]
}

export const TESE_CREDIT_INDEXERS = {
	i000: { label: 'Sem indexador', _id: 'i000' },
	i001: { label: 'Prefixado', _id: 'i001' },
	i002: { label: 'CDI', _id: 'i002' },
	i003: { label: 'IPCA', _id: 'i003' },
	i004: { label: 'USD', _id: 'i004' },
	i005: { label: 'EUR', _id: 'i005' },
	i006: { label: 'RMB', _id: 'i006' },
	i007: { label: 'GBP', _id: 'i007' },
} as const

export const TESE_CREDIT_INDEXERS_VALUES = Object.values(TESE_CREDIT_INDEXERS)
export const TESE_CREDIT_INDEXERS_KEYS = Object.keys(TESE_CREDIT_INDEXERS)
