import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

/**
 * Retorna o nível de governança de um teaser com base em certos critérios.
 *
 * @param {TypeTeaser} teaser - O teaser para o qual a governança será obtida.
 * @returns {string} - A pontuação de governança ('A', 'B' ou 'C').
 *
 * @example
 *
 * / Exemplo de uso:
 * const teaser1: TypeTeaser = {
 *   governanceAuditedBalance: 'Sim',
 *   governanceRevenueOutside: 'Sim'
 * };
 *
 * const score1: string = getGovernanceScore(teaser1);
 * console.log(score1); // Saída: 'A'
 *
 * const teaser2: TypeTeaser = {
 *   governanceAuditedBalance: 'Não',
 *   governanceRevenueOutside: 'Não'
 * };
 *
 * const score2: string = getGovernanceScore(teaser2);
 * console.log(score2); // Saída: 'B'
 *
 * const teaser3: TypeTeaser = {
 *   governanceAuditedBalance: 'Não',
 *   governanceRevenueOutside: 'Sim'
 * };
 *
 * const score3: string = getGovernanceScore(teaser3);
 * console.log(score3); // Saída: 'C'
 */

export const getGovernanceScore = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	if (cloneTeaser.governanceAuditedBalance === 'Sim') return 'A'
	else if (cloneTeaser.governanceRevenueOutside === 'Não') return 'B'

	return 'C'
}
