import * as Tokens from 'Components/CardDS/src/Tokens'
import { ITypography } from '../ITypography'

export const typography: ITypography = {
	font: {
		family: Tokens.FONT_MAIN
	},
	weight: {
		['light']: Tokens.FONT_LIGHT,
		['regular']: Tokens.FONT_REGULAR,
		['medium']: Tokens.FONT_MEDIUM,
		['semiBold']: Tokens.FONT_SEMI_BOLD,
		['bold']: Tokens.FONT_BOLD,
		['extraBold']: Tokens.FONT_EXTRA_BOLD
	},
	size: {
		xxs: Tokens.FONT_SIZE_XXS,
		xs: Tokens.FONT_SIZE_XS,
		sm: Tokens.FONT_SIZE_SM,
		md: Tokens.FONT_SIZE_MD,
		lg: Tokens.FONT_SIZE_LG,
		xl: Tokens.FONT_SIZE_XL
	}
}
