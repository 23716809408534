import { clone } from './clone'

/**
 * Função para checar se uma determinada data está entre duas datas
 * @param {Date} date {Date} data a ser checada
 * @param {Date} startDate {Date} data início
 * @param {Date} endDate {Date} data fim
 * @returns {boolean} {boolean} se é verdadeiro ou falso
 * @example dateIsBetween(Thu Nov 03 2022 09:33:41 GMT-0300, Mon Jan 01 2018 00:00:00 GMT-0200, Wed Feb 01 2023 00:00:00 GMT-0300) =>
 * true
 */

export default function dateIsBetween(date: Date, startDate: Date, endDate: Date): boolean {

	const cloneDate = clone(date)
	const cloneStartDate = clone(startDate)
	const cloneEndDate = clone(endDate)

	return cloneDate >= cloneStartDate && cloneDate <= cloneEndDate
}
