import React, { createContext, useContext, useState } from 'react'
import { TypeNotifications } from 'Functions/Notification/types/notification'

const _useGlobalContext = () => {

	const [unReadNotifications, setUnReadNotifications] = useState({} as TypeNotifications)

	return ({

		unReadNotifications, setUnReadNotifications,
	})
}
const GlobalContext = createContext({} as ReturnType<typeof _useGlobalContext>)

export const useContextUnReadNotifications = () => useContext(GlobalContext)
export const ContextUnReadNotificationsProvider: React.FC = ({ children }) => {
	const controller = _useGlobalContext()
	return (
		<GlobalContext.Provider value={controller}>
			{children}
		</GlobalContext.Provider>
	)
}
