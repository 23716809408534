import React, { FC, HTMLAttributes, ReactChild } from 'react'
import styled from 'styled-components'
import { SPACES } from './settings'

/** @deprecated */
export interface ContentProps extends HTMLAttributes<HTMLDivElement> {
	/** Component children */
	children?: ReactChild | ReactChild[];
	/** If true, the content will receive a padding top from header height (74px) */
	hasFixedHeader?: boolean;

	/** Spacing */
	spacing?: 'sm' | 'md' | 'lg';
}

const LayoutStyled = styled.section`
    flex: auto;
    min-height: 0;
    ${props => props.spacing ? `margin: ${SPACES[props.spacing]}px` : ''};
    ${(props: ContentProps) => props.hasFixedHeader ? `margin-top: ${76 + (props.spacing ? SPACES[props.spacing] : SPACES.md)}px;` : ''}
`

/**
 * A custom content component
 */
/** @deprecated */
export const Content: FC<ContentProps> = props => {
	const { children } = props
	return <LayoutStyled {...props}>{children}</LayoutStyled>
}

const DF: ContentProps = {
}

Content.defaultProps = DF
