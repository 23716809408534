import * as Tokens from 'Components/CardDS/src/Tokens'
import { IBreakpoints } from '../IBreakpoints'

export const breakpoints: IBreakpoints = {
	sizes: {
		xxs: Tokens.SIZE_XXS,
		xs: Tokens.SIZE_XS,
		sm: Tokens.SIZE_SM,
		md: Tokens.SIZE_MD,
		lg: Tokens.SIZE_LG,
		xl: Tokens.SIZE_XL,
		xxl: Tokens.SIZE_XXL,
		xxxl: Tokens.SIZE_XXXL,
	},
	queries: {
		xxs: Tokens.SIZE_QUERY_XXS,
		xs: Tokens.SIZE_QUERY_XS,
		sm: Tokens.SIZE_QUERY_SM,
		md: Tokens.SIZE_QUERY_MD,
		lg: Tokens.SIZE_QUERY_LG,
		xl: Tokens.SIZE_QUERY_XL,
		xxl: Tokens.SIZE_QUERY_XXL,
		xxxl: Tokens.SIZE_QUERY_XXXL,
	}
}
