import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const TelephoneOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M9.11411 15.5911C10.2739 15.5911 11.3712 15.1195 12.1989 14.2629C12.9882 13.4447 13.4598 12.3667 13.5272 11.2117C14.6629 10.9037 15.5677 10.716 16.5109 10.716C17.4783 10.716 18.4744 10.9085 19.8508 11.2406C19.923 12.3908 20.3946 13.464 21.1839 14.2725C22.0165 15.1243 23.1089 15.5911 24.2639 15.5911C26.699 15.5911 28.6866 13.4977 28.6866 10.9182C28.6866 9.66209 28.1861 8.57928 27.1995 7.68896C26.3958 6.96227 25.4718 6.5484 24.9232 6.29815L24.9136 6.29333C24.3072 6.00458 21.0588 4.58008 16.7612 4.58008H16.6168C11.9342 4.58489 8.50773 6.27408 8.47404 6.28852C7.3431 6.80346 4.69141 8.01621 4.69141 10.923C4.69141 13.4977 6.67416 15.5911 9.11411 15.5911ZM9.48467 8.4349C9.51354 8.42046 12.5214 6.98152 16.5061 6.95746H16.8623C20.6545 6.98152 23.5805 8.29052 23.903 8.43971L23.9126 8.44453C25.6595 9.23859 26.3092 9.90753 26.3092 10.9182C26.3092 12.1838 25.39 13.2137 24.2639 13.2137C23.133 13.2137 22.2186 12.1838 22.2186 10.9182C22.2186 10.8075 22.2282 10.6727 22.2523 10.5091C22.3485 9.90753 21.9635 9.3204 21.3764 9.17121C19.2011 8.61778 17.8536 8.33865 16.5061 8.33865C15.149 8.33865 13.9122 8.62259 11.9727 9.18565C11.4 9.35409 11.0391 9.92197 11.1305 10.5091C11.1546 10.6775 11.169 10.8075 11.169 10.923C11.169 12.1887 10.2499 13.2185 9.12373 13.2185C7.99279 13.2185 7.07841 12.1887 7.07841 10.923C7.07841 9.91715 7.73291 9.2434 9.49429 8.43971L9.48467 8.4349Z' fill='#272827' />
		<path d='M23.4073 18.7819C21.3716 18.5798 20.5294 17.2275 20.4187 16.0292C20.3609 15.4132 19.8508 14.9512 19.2396 14.9512H14.1384C13.5176 14.9512 13.0122 15.418 12.9593 16.034C12.8486 17.2275 12.0112 18.5798 9.97073 18.7867C7.3046 19.0562 5.29297 21.2219 5.29297 23.8254V27.3915C5.29297 28.046 5.82716 28.5802 6.48166 28.5802H26.906C27.5605 28.5802 28.0947 28.046 28.0947 27.3915V23.8254C28.085 21.2219 26.0734 19.0514 23.4073 18.7819ZM18.2531 17.3286C18.5514 18.344 19.1434 19.2295 19.9615 19.8936C20.8278 20.5914 21.9346 21.0246 23.1618 21.1497C24.6104 21.2989 25.7076 22.4491 25.7076 23.8254V26.2028H7.66072V23.8254C7.66072 22.4443 8.75317 21.2941 10.2065 21.1497C11.4337 21.0246 12.5406 20.5914 13.4069 19.8936C14.2298 19.2343 14.8169 18.3488 15.1153 17.3286H18.2531Z' fill='#272827' />
	</Svg>
)
