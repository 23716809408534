export const STATUS_MATCH_MAKING = {
	beforeMatchMaking: {
		value: 0,
		label: 'BeforeMatchmaking'
	},
	matchMaking: {
		value: 5,
		label: 'Matchmaking'
	},
	declinado: {
		value: -5,
		label: 'Declinado'
	},
	match: {
		value: 10,
		label: 'Match'
	},
	inativo: {
		value: -15,
		label: 'Inativo'
	},
	completo: {
		value: 20,
		label: 'Completo'
	}
} as const

export const STATUS_MATCH_MAKING_GET = (code: string) => {
	const _code = code as keyof typeof STATUS_MATCH_MAKING

	if (!STATUS_MATCH_MAKING[_code]) {
		return (STATUS_MATCH_MAKING.beforeMatchMaking)
	}

	return STATUS_MATCH_MAKING[_code]
}
export const STATUS_MATCH_MAKING_VALUES = Object.values(STATUS_MATCH_MAKING)
export const STATUS_MATCH_MAKING_KEYS = Object.keys(STATUS_MATCH_MAKING)
