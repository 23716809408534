/**
 * A função `inComite` verifica se um teaser foi publicado, não relacionado a um
 * cliente, e não em situação comercial.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente é um objeto que representa algum tipo de teaser ou conteúdo de visualização.
 * A função `inComite` pega este objeto `teaser` como entrada, cria um clone do
 * usando a função `clone` e então verifica
 * @returns A função `inComite` está retornando um valor booleano. Ele verifica se o
 * Objeto `teaser` passado como argumento é publicado (`isPublicado(cloneTeaser)`),
 * não possui um conjunto de propriedades `statusCustomer` (`!cloneTeaser.statusCustomer`),
 * e não está em estado comercial (`!inComercial(cloneTeaser)`). Se todos estes
 *as condições são atendidas,
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inComercial } from './inComercial'
import { isPublicado } from './isPublicado'


export const inComite = (teaser: TypeTeaser) => {
	const cloneTeaser = clone(teaser)

	return isPublicado(cloneTeaser) && !cloneTeaser.statusCustomer && !inComercial(cloneTeaser)

}
