const _CONTRACTS = {
	none: {
		_id: 'none',
		label: '---',
		value: 0,
		color: 'black',
		svg: '#000000',
		fidelityPayment: 0,
		monthlyPayment: 0,
		successFee: 0,
		dueDateTail: 0,
		dueDateFidelity: 0,
		dueDateSignature: 0,
	},
	special: {
		_id: 'special',
		label: 'Special',
		value: 10_000,
		color: 'purple',
		svg: '#8516F9',
		fidelityPayment: 9_000,
		monthlyPayment: 9_000,
		successFee: 5,
		dueDateTail: 12, // prazo validade de tail 12 meses
		dueDateFidelity: 5, // prazo de fidelidade 5 meses
		dueDateSignature: 12, // prazo de validade do contrato: 12 meses
	},
	lite: {
		_id: 'lite',
		label: 'Lite',
		value: 5_000,
		color: 'orange',
		svg: '#DA4400',
		fidelityPayment: 0,
		monthlyPayment: 5000,
		successFee: 4,
		dueDateTail: 12,
		dueDateFidelity: 0,
		dueDateSignature: 12,
	},
	pro: {
		_id: 'pro',
		label: 'Pro',
		value: 7_000,
		color: 'blue',
		svg: '#3F69E6',
		fidelityPayment: 7_000,
		monthlyPayment: 7_000,
		successFee: 5,
		dueDateTail: 12,
		dueDateFidelity: 5,
		dueDateSignature: 12,
	},
	other: {
		_id: 'other',
		label: 'Outro',
		value: 0,
		color: 'red',
		svg: '#b91c1c',
		fidelityPayment: 0,
		monthlyPayment: 0,
		successFee: 0,
		dueDateTail: 0,
		dueDateFidelity: 0,
		dueDateSignature: 0,
	},
} as const

export type CONTRACTS_TYPE = keyof typeof _CONTRACTS
export const CONTRACTS = {
	GET: (code: string) => {
		const _code = code as keyof typeof _CONTRACTS

		if (!_CONTRACTS[_code]) {
			return (_CONTRACTS.none)
		}

		return _CONTRACTS[_code]
	},
	KEYS: Object.keys(_CONTRACTS) as Array<keyof typeof _CONTRACTS>,
	VALUES: Object.values(_CONTRACTS) as Array<{ readonly _id: keyof typeof _CONTRACTS, readonly label: string, readonly value: number, readonly color: string, readonly svg: string, readonly fidelityPayment: number, readonly monthlyPayment: number, readonly successFee: number, readonly dueDateTail: number, readonly dueDateFidelity: number, readonly dueDateSignature: number }>
}
