import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

export const removeTag =
	(
		{ tag, transaction }:
			{ tag: string, transaction: TypeTransaction }) => {
		const copyOfTransaction = { ...transaction }
		copyOfTransaction.tags = [...transaction.tags]
		copyOfTransaction.tags = copyOfTransaction.tags.filter(_tag => _tag !== tag)
		return copyOfTransaction.tags
	}
