import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BarChart = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M28.008 13.3379C29.1084 13.3379 30.0004 14.0316 30.0004 14.8873V30.4505C30.0004 31.3063 29.1084 31.9999 28.008 31.9999C26.9076 31.9999 26.0156 31.3063 26.0156 30.4505V14.8873C26.0156 14.0316 26.9076 13.3379 28.008 13.3379Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.0002 4C17.1005 4 17.9926 4.69369 17.9926 5.54941V30.4506C17.9926 31.3063 17.1005 32 16.0002 32C14.8998 32 14.0078 31.3063 14.0078 30.4506V5.54941C14.0078 4.69369 14.8998 4 16.0002 4Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M3.99238 19.563C5.09273 19.563 5.98475 20.2567 5.98475 21.1124V30.4503C5.98475 31.3061 5.09273 31.9997 3.99238 31.9997C2.89202 31.9997 2 31.3061 2 30.4503V21.1124C2 20.2567 2.89202 19.563 3.99238 19.563Z' fill='#272827' />
	</Svg>
)
