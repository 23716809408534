import { TAGS_SECTOR } from '../../../Constants/TAGS'
import { clone } from '../../../Functions/Utils/clone'
import { intersectionArray } from '../../../Functions/Utils/intersectionArray'
import { cnaesFactored } from './cnaesFactored'

/**
 * Converte os códigos CNAE fornecidos em tags.
 *
 * @param cnaes Uma lista de strings representando os códigos CNAE.
 * @returns Uma lista de strings contendo as tags de  associadas aos códigos CNAE fornecidos.
 *
 * @example
 *
 * / Lista de códigos CNAE de exemplo
 * const cnaes = ['33.20-2-00', '62.04-0-00'];
 *
 *  Obter as tags de setor associadas aos códigos CNAE
 * const tagsSetor = cnaesToTags(cnaes);
 * console.log(tagsSetor); // Saída esperada: ['t001', 't002']
 */

export const cnaesToTags = (cnaes: string[]) => {

	const cloneCnaes = clone(cnaes)

	const res: string[] = []

	const _cnaesFactored = cnaesFactored(cloneCnaes)

	Object.entries(TAGS_SECTOR)
		.filter(([tag, tagObj]) => intersectionArray(_cnaesFactored, tagObj.cnae).length)
		.forEach(([tag, tagObj]) => {
			res.push(tag)
		})
	return [...res]
}
