import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const CheckOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>

		<path d='M16.4004 2.29504C8.68045 2.29504 2.40039 8.5751 2.40039 16.295C2.40039 24.015 8.68045 30.295 16.4004 30.295C24.1203 30.295 30.4004 24.015 30.4004 16.295C30.4004 8.5751 24.1203 2.29504 16.4004 2.29504ZM16.4004 27.564C10.1879 27.564 5.13144 22.5075 5.13144 16.295C5.13144 10.0826 10.1879 5.02609 16.4004 5.02609C22.6128 5.02609 27.6693 10.0826 27.6693 16.295C27.6693 19.2965 26.4931 22.1289 24.3637 24.2583C22.2343 26.3878 19.4018 27.564 16.4004 27.564Z' fill='#272827' />
		<path d='M21.9231 11.1302C21.5581 11.1302 21.2133 11.2722 20.9564 11.5291L14.3249 18.1607L11.8439 15.6797C11.587 15.4229 11.2423 15.2809 10.8772 15.2809C10.5122 15.2809 10.1674 15.4229 9.91056 15.6797C9.65368 15.9366 9.51172 16.2814 9.51172 16.6464C9.51172 17.0115 9.65368 17.3562 9.91056 17.6131L13.3582 21.0607C13.6151 21.3176 13.9598 21.4596 14.3249 21.4596C14.6899 21.4596 15.0347 21.3176 15.2915 21.0607L15.3186 21.0337L22.8898 13.4625C23.1467 13.2056 23.2886 12.8608 23.2886 12.4958C23.2886 12.1307 23.1467 11.786 22.8898 11.5291C22.6262 11.2722 22.2882 11.1302 21.9231 11.1302Z' fill='#272827' />


	</Svg>
)
