export const VALUATION_TYPES = {
	ebitdaOpenCapital: {
		_id: 'ebitdaOpenCapital',
		label: 'Ebitda de capital aberto'
	},
	ebitdaClosedCapital: {
		_id: 'ebitdaClosedCapital',
		label: 'Ebitda de capital fechado'
	},
	ebitdaTotal: {
		_id: 'ebitdaTotal',
		label: 'Ebitda geral'
	},
	billingOpenCapital: {
		_id: 'billingOpenCapital',
		label: 'Faturamento de capital aberto'
	},
	billingClosedCapital: {
		_id: 'billingClosedCapital',
		label: 'Faturamento de capital fechado'
	},
	billingTotal: {
		_id: 'billingTotal',
		label: 'Faturamento total'
	}
}
