export const RANK_CONFIG = {
	originacaoEnviada: 5,
	originacaoAprovada: 10,
	contratoAssinado: 15,
	indicacao: 5,
	academy: 10
}

export const RANK_CONFIG_GET = (code: string) => {
	const _code = code as keyof typeof RANK_CONFIG

	if (!RANK_CONFIG[_code]) {
		return (RANK_CONFIG[_code])
	}

	return RANK_CONFIG[_code]
}
export const RANK_CONFIG_VALUES = Object.values(RANK_CONFIG)
export const RANK_CONFIG_KEYS = Object.keys(RANK_CONFIG)
