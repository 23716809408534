import { db5 } from 'Services/db5'
import { TypeUser } from 'Functions/User/types/TypeUser'

/**
 * Função usada para retornar uma transação
 * @param limit number
 * @param profile TypeUser
 * @returns TypeTransaction
 */
export const readTransaction = async ({ id, profile }: { id: string, profile: TypeUser }) =>
	await db5.sinatraTransactions.get({ id, profile })
