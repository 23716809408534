import { mergeClassName } from '../Functions/Utils/mergeClassName'

interface UiContainerIconProps {
	value: string;
	outlined: boolean;
	className?: string;
}

export const UiContainerIcon = (props: UiContainerIconProps) => (
	<div className={mergeClassName(`material-icons${props.outlined ? '-outlined' : ''} tw-text-neutral-400 tw-text-2xl tw-self-center tw-bg-neutral-100 tw-border tw-border-solid tw-border-neutral-300 tw-w-[44px] tw-h-[44px] tw-rounded-lg tw-flex tw-justify-center tw-items-center`, props.className || '')}>
		{props.value ? props.value : 'add_circle_outline'}
	</div>
)
