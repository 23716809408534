import { FC } from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const StyledLoading = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		flex-direction: column;
`
const LoadingAnimateCss = styled.div`
img{
		animation: ${rotate} 2s linear infinite;
}
`
const StyledText = styled.span`
		display: block;
		margin-top: 40px;
		font-family: 'Open Sans', sans-serif;
		font-size: 14px;
		margin-bottom: 20px;
		color: #6F6F6F;
`
const StyledLogo = styled.div`
		align-self: flex-end;
		justify-content: center;
		width: 100%;
		display: flex;
		margin-bottom: 40px;
		padding-bottom: 50px;
`
const StyledBox = styled.div`
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		flex-direction: column;
`

type TLoading = {
	text?: string
}

export const LoadingAnimate = ({ white = false, width }: { white?: boolean, width?: number }) =>
	<StyledBox>
		<LoadingAnimateCss>
			<img src='/assets/img/loading.png' alt='Loading' className={`${width ? `tw-w-[${width}px]` : ''}`} style={white ? { filter: 'brightness(10)' } : {}} />
		</LoadingAnimateCss>
	</StyledBox>

export const Loading: FC<TLoading> = props => <StyledLoading>
	<LoadingAnimate />
	<div className='tw-px-8 tw-text-center'>{props.text && <StyledText>{props.text}</StyledText>}</div>

	<StyledLogo>
		<img src='/assets/img/logo-primario-positivo.png' alt='Logo Stark' />
	</StyledLogo>
</StyledLoading>
