import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PersonCheckOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M8.58883 18.6265L8.59241 18.6265L16.7579 18.6265C17.5257 18.6265 18.2858 18.7824 18.9949 19.0852C19.7039 19.388 20.3479 19.8319 20.8899 20.3912C21.432 20.9506 21.8614 21.6146 22.1535 22.3451C22.4455 23.075 22.5947 23.857 22.5925 24.6463V27.0343C22.5925 27.7018 22.0666 28.2429 21.4178 28.2429C20.7691 28.2429 20.2432 27.7018 20.2432 27.0343V24.6443L20.2432 24.6406C20.2446 24.1688 20.1555 23.7013 19.9809 23.265C19.8064 22.8286 19.5499 22.432 19.2261 22.0979C18.9024 21.7638 18.5177 21.4986 18.0941 21.3177C17.6706 21.1368 17.2165 21.0437 16.7579 21.0437H8.59432C7.66866 21.0471 6.78197 21.4277 6.12839 22.1022C5.47442 22.7771 5.10719 23.6913 5.1072 24.6443V27.0343C5.1072 27.7018 4.58127 28.2429 3.93251 28.2429C3.28374 28.2429 2.75781 27.7018 2.75781 27.0343V24.6443C2.75781 23.0515 3.37157 21.5236 4.46459 20.3955C5.55761 19.2675 7.04071 18.6313 8.58883 18.6265Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M12.6823 6.65994C10.7574 6.65994 9.19704 8.26541 9.19704 10.2459C9.19704 12.2263 10.7574 13.8318 12.6823 13.8318C14.6071 13.8318 16.1675 12.2263 16.1675 10.2459C16.1675 8.26541 14.6071 6.65994 12.6823 6.65994ZM6.84766 10.2459C6.84766 6.93039 9.4599 4.24268 12.6823 4.24268C15.9046 4.24268 18.5169 6.93039 18.5169 10.2459C18.5169 13.5613 15.9046 16.249 12.6823 16.249C9.4599 16.249 6.84766 13.5613 6.84766 10.2459Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M30.4132 11.7799C30.8726 12.2512 30.8737 13.0165 30.4157 13.4892L25.7558 18.2984C25.5356 18.5257 25.2366 18.6535 24.9248 18.6537C24.6129 18.654 24.3138 18.5266 24.0932 18.2997L21.7562 15.8951C21.2974 15.4231 21.2974 14.6579 21.7562 14.1859C22.2149 13.7139 22.9587 13.7139 23.4174 14.1859L24.9226 15.7345L28.7519 11.7826C29.2099 11.3098 29.9537 11.3087 30.4132 11.7799Z' fill='#272827' />
	</Svg>
)
