import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import { TypeInstitution } from 'Functions/Institution/types/institution'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { TypeTese } from 'Functions/Tese/types/tese'
import { TypeDbResponse } from './db5/TypeDBResponse'
import { _deleteUser } from './cloudFunctions/deleteUserFunction'
import { _emailBemVindo } from './cloudFunctions/bemVindoFunction'
import { _sendEmailMatchmaking } from './cloudFunctions/sendEmailMatchmakingFunction'
import { _verificarEmpresa } from './cloudFunctions/verificarEmpresaFunction'
import { _getTeaser } from './cloudFunctions/getTeaserFunction'
import { _getTese } from './cloudFunctions/getTeseFunction'
import { _sendNda } from './cloudFunctions/sendNdaFunction'
import { _getProfileInstitution } from './cloudFunctions/getProfileInstitutionFuction'
import { _createBda } from './cloudFunctions/createBdaFunction'
import { _sendTeseToBdas } from './cloudFunctions/sendTesesToBdasFunction'
import { _getBlindTeses } from './cloudFunctions/getBlindTesesFunction'
import { _getBlindInstitutions } from './cloudFunctions/getBlindInstitutionsFunction'
import { _getBlindInstitution } from './cloudFunctions/getBlindInstitutionFuncion'
import { _cnpjIsAvailable } from './cloudFunctions/cnpjIsAvailableFunction'
import { _sendEmailMatch } from './cloudFunctions/sendEmailMatchFunction'
import { _groupAgentInfosForRank } from './cloudFunctions/groupAgentInfosForRankFunction'
import { _createBackupAuth } from './cloudFunctions/createBackupAuthFunction'
import { _inactivateAllMatchesFromTeaser } from './cloudFunctions/inactivateAllMatchesFromTeaserFunction'
import { _notifications } from './cloudFunctions/notificationsFunction'
import { _getProfileForPreOrigination } from './cloudFunctions/getProfileFromPreOriginationFunction'
import { _getHighestStatusFromMatches } from './cloudFunctions/getHighestStatusFromMatchesFunction'
import { _userCreate } from './cloudFunctions/userCreateFunction'
import { _dbBlindGetFunction } from './cloudFunctions/dbBlindGetFunction'
import { _cfGetBlindFunction } from './cloudFunctions/cfGetBlindFunction'
import { _getDataDashBoard } from './cloudFunctions/getDataDashBoardFunction'
import { _institutionDeclineMatch } from './cloudFunctions/declineMatchFunction'
import { _createAdm } from './cloudFunctions/createAdmFunction'
import { _assistantAnne } from './cloudFunctions/assistantAnneFunction'

export const avisarBDA = firebase.functions().httpsCallable('avisarBDA')
// export const idToZero = firebase.functions().httpsCallable('idToZero')
export const internalSinatra = firebase.functions().httpsCallable('internalSinatra') //Não tem no controller
export const sendNotification = firebase.functions().httpsCallable('sendNotification') //Não é usada

// export const deleteUser = firebase.functions().httpsCallable('deleteUser')
export const deleteUser = _deleteUser
// export const emailBemVindo = firebase.functions().httpsCallable('emailBemVindo')
export const emailBemVindo = _emailBemVindo //Não sei a response
// export const sendEmailMatchmaking = firebase.functions().httpsCallable('sendEmailMatchmaking')
export const sendEmailMatchmaking = _sendEmailMatchmaking
// export const verificarEmpresa = firebase.functions().httpsCallable('verificarEmpresa')
export const verificarEmpresa = _verificarEmpresa
// export const getTese = firebase.functions().httpsCallable('getTese')
export const getTese = _getTese
// export const getTeaser = firebase.functions().httpsCallable('getTeaser')
export const getTeaser = _getTeaser
// export const sendNda = firebase.functions().httpsCallable('sendNda')
export const sendNda = _sendNda
// export const getProfileInstitution = firebase.functions().httpsCallable('getProfileInstitution')
export const getProfileInstitution = _getProfileInstitution
// export const createBda = firebase.functions().httpsCallable('createBda')
export const createBda = _createBda
// export const userCreate = firebase.functions().httpsCallable('userCreate')
export const userCreate = _userCreate
export const createAdm = _createAdm
// export const sendTeseToBdas = firebase.functions().httpsCallable('sendTeseToBdas')
export const sendTeseToBdas = _sendTeseToBdas
// export const getBlindTeses = firebase.functions().httpsCallable('getBlindTeses')
export const getBlindTeses = _getBlindTeses
// export const cnpjIsAvailable = firebase.functions().httpsCallable('cnpjIsAvailable')
export const cnpjIsAvailable = _cnpjIsAvailable
export const sendEmailMatch = firebase.functions().httpsCallable('sendEmailMatch')
// export const sendEmailMatch = _sendEmailMatch
// export const groupAgentInfosForRank = firebase.functions().httpsCallable('groupAgentInfosForRank')
export const groupAgentInfosForRank = _groupAgentInfosForRank
// export const createBackupAuth = firebase.functions().httpsCallable('createBackupAuth')
export const createBackupAuth = _createBackupAuth
// export const inactivateAllMatchesFromTeaser = firebase.functions().httpsCallable('inactivateAllMatchesFromTeaser')
export const inactivateAllMatchesFromTeaser = _inactivateAllMatchesFromTeaser
// export const notifications = firebase.functions().httpsCallable('notifications') // envio de notificações para usuários, ex: a0001, b0002, etc
export const notifications = _notifications // Não o sei o type da response
// export const getDataDashBoard = firebase.functions().httpsCallable('getDataDashBoard')
export const getDataDashBoard = _getDataDashBoard
// export const getProfileForPreOrigination = firebase.functions().httpsCallable('getProfileForPreOrigination')
export const getProfileForPreOrigination = _getProfileForPreOrigination
// export const getHighestStatusFromMatches = firebase.functions().httpsCallable('getHighestStatusFromMatches')
export const getHighestStatusFromMatches = _getHighestStatusFromMatches
// export const getBlindInstitutions = firebase.functions().httpsCallable('getBlindInstitutions')
export const getBlindInstitutions = _getBlindInstitutions
// export const getBlindInstitution = firebase.functions().httpsCallable('getBlindInstitution')
export const getBlindInstitution = _getBlindInstitution

export const declineMatch = _institutionDeclineMatch

// const _dbBlindGet = firebase.functions().httpsCallable('dbBlindGet')
const _dbBlindGet = _dbBlindGetFunction
// const _cfGetBlind = firebase.functions().httpsCallable('cfGetBlind')
const _cfGetBlind = _cfGetBlindFunction

export const assistantAnne = _assistantAnne


export const getUserLastLogin = firebase.functions().httpsCallable('getUserLastLogin')
export const usersFirstMatch = firebase.functions().httpsCallable('usersFirstMatch')

type TypeReturnDbBlind<TDoc> = {
	status: 200,
	version?: number,
	doc?: TDoc,
	docs?: { [key: string]: TDoc }
} |
{
	status: 400,
}

export const dbCloud = {
	institutions: {
		getBlind: async (id: string): Promise<TypeReturnDbBlind<TypeInstitution>> => {
			try {
				const res = await _dbBlindGet({ id, collection: 'institutions' })
				return res.data
			}
			catch {
				// erro
			}
			return { status: 400 }
		},
	},
	teses: {
		getBlind: async (id: string): Promise<TypeReturnDbBlind<TypeTese>> => {
			try {
				const res = await _dbBlindGet({ id, collection: 'teses' })
				return res.data
			}
			catch {
				// erro
			}
			return { status: 400 }
		},
	},
	teasers: {
		getBlind: async (id: string): Promise<TypeReturnDbBlind<TypeTeaser>> => {
			try {
				const res = await _dbBlindGet({ id, collection: 'teasers' })
				return res.data
			}
			catch {
				// erro
			}
			return { status: 400 }
		}
	}
}
export const cfGetBlind = async <T>(obj: {
	collection: string;
	id?: string;
	where?: [string, firebase.firestore.WhereFilterOp, any][];
	orderBy?: [string, firebase.firestore.OrderByDirection][];
	limit?: number;
	startAfterDocId?: string;
}) => {

	const result: TypeDbResponse<T> = {
		status: 400
	}

	try {
		const res = await _cfGetBlind(obj)
		result.status = res?.data?.status
		result.doc = res?.data?.doc
		result.docs = res?.data?.docs
		result.id = res?.data?.id
	}
	catch {
		// erro
	}
	return result
}
