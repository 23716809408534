import { parse, startOfDay } from 'date-fns'
import { helpersDate } from 'Functions/Utils/helpersDate'
import { IUiInput } from '..'

export const verifyDateIsValid = (props: IUiInput): boolean => {
	const dataValida = helpersDate.dataIsValid(props.value as string)

	const dataAtual = new Date()

	const data = startOfDay(parse(props.value as string || '', 'dd/MM/yyyy', dataAtual))
	// const dataFirestamp = firebase.firestore.Timestamp.fromDate(data)
	const dataFirestamp = helpersDate.convertAllToTimestamp(data)

	let invalidInFuture = false
	let invalidInPast = false

	if (props.maxDate) {
		// const maxDateFirestamp = firebase.firestore.Timestamp.fromDate(startOfDay(props.maxDate))
		const maxDateFirestamp = helpersDate.convertAllToTimestamp(startOfDay(props.maxDate))
		invalidInFuture = dataValida && props.maxDate && helpersDate.isBiggerThan(dataFirestamp, maxDateFirestamp)
	}

	if (props.minDate) {
		// const minDateFirestamp = firebase.firestore.Timestamp.fromDate(startOfDay(props.minDate))
		const minDateFirestamp = helpersDate.convertAllToTimestamp(startOfDay(props.minDate))
		invalidInPast = dataValida && props.minDate && helpersDate.isBiggerThan(minDateFirestamp, dataFirestamp)
	}

	return (!dataValida || invalidInFuture || invalidInPast)

}
