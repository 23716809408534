import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const SearchOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M14.3846 5.65448C9.5631 5.65448 5.65448 9.5631 5.65448 14.3846C5.65448 19.2061 9.5631 23.1148 14.3846 23.1148C19.2061 23.1148 23.1148 19.2061 23.1148 14.3846C23.1148 9.5631 19.2061 5.65448 14.3846 5.65448ZM2 14.3846C2 7.54478 7.54478 2 14.3846 2C21.2245 2 26.7692 7.54478 26.7692 14.3846C26.7692 21.2245 21.2245 26.7692 14.3846 26.7692C7.54478 26.7692 2 21.2245 2 14.3846Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M20.8559 20.8559C21.5869 20.1249 22.7721 20.1249 23.5032 20.8559L29.4517 26.8044C30.1827 27.5354 30.1827 28.7206 29.4517 29.4517C28.7206 30.1827 27.5354 30.1827 26.8044 29.4517L20.8559 23.5032C20.1249 22.7721 20.1249 21.5869 20.8559 20.8559Z' fill='#272827' />
	</Svg>
)
