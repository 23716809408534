import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PercentOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M7.31998 12.64C4.37999 12.64 2 10.26 2 7.31998C2 4.37999 4.37999 2 7.31998 2C10.26 2 12.64 4.37999 12.64 7.31998C12.64 10.26 10.26 12.64 7.31998 12.64ZM7.31998 4.79999C5.91998 4.79999 4.79999 5.91998 4.79999 7.31998C4.79999 8.71997 5.91998 9.83996 7.31998 9.83996C8.71997 9.83996 9.83996 8.71997 9.83996 7.31998C9.83996 5.91998 8.71997 4.79999 7.31998 4.79999Z' fill='#272827' />
		<path d='M4.94003 28.4599C4.52003 28.4599 4.24004 28.3199 3.96004 28.0399C3.40004 27.4799 3.40004 26.6399 3.96004 26.0799L26.0799 3.96004C26.6399 3.40004 27.4799 3.40004 28.0399 3.96004C28.5999 4.52003 28.5999 5.36003 28.0399 5.92003L5.92003 28.0399C5.64003 28.3199 5.36003 28.4599 4.94003 28.4599Z' fill='#272827' />
		<path d='M24.6803 29.9998C21.7403 29.9998 19.3604 27.6198 19.3604 24.6798C19.3604 21.7399 21.7403 19.3599 24.6803 19.3599C27.6203 19.3599 30.0003 21.7399 30.0003 24.6798C30.0003 27.6198 27.6203 29.9998 24.6803 29.9998ZM24.6803 22.1599C23.2803 22.1599 22.1603 23.2798 22.1603 24.6798C22.1603 26.0798 23.2803 27.1998 24.6803 27.1998C26.0803 27.1998 27.2003 26.0798 27.2003 24.6798C27.2003 23.2798 26.0803 22.1599 24.6803 22.1599Z' fill='#272827' />
	</Svg>
)
