
import { Timestamp } from '../Time/types/Timestamp'
import { clone } from './clone'
import { helpersDate } from './helpersDate'

/**
 * Converte um timestamp do Firestore para um objeto de data JavaScript.
 * @param {Timestamp | undefined} time - O timestamp para converter em data.
 * @returns {Date} - O objeto de data convertido.
 * @exemplo
 * const timestamp = { seconds: 1616227200, nanoseconds: 0 };
 * const data = timeToDate(timestamp);
 */
export const timeToDate = (time?: Timestamp) => {
	const cloneTime = clone(time)
	return cloneTime ? new Date(cloneTime.seconds * 1000) : new Date()

}

/**
 * Converte um timestamp do Firestore para uma string de data formatada localmente.
 * @param {Timestamp} time - O timestamp para converter em string de data local.
 * @returns {string} - A string de data formatada localmente.
 * @exemplo
 * const timestamp = { seconds: 1616227200, nanoseconds: 0 };
 * const dataLocal = timeToLocaleDate(timestamp);
 */
export const timeToLocaleDate = (time: Timestamp) => {
	const cloneTime = clone(time)
	return cloneTime
		? new Intl.DateTimeFormat('pt-BR', {
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
		}).format(timeToDate(cloneTime))
		: ''

}

/**
 * Converte um timestamp do Firestore para um objeto de data JavaScript.
 * Esta função é especificamente projetada para timestamps do Firebase.
 * @param {any} time - O timestamp para converter em data.
 * @returns {Date | number} - O objeto de data convertido ou 0 se o input for falso.
 * @exemplo
 * const timestamp = { seconds: 1616227200, nanoseconds: 0 };
 * const data = fireTimeToDate(timestamp);
 */
export const fireTimeToDate = (time: any) => {
	const cloneTime = clone(time)
	return cloneTime ? new Date(cloneTime.seconds * 1000) : 0

}

/**
 * Converte um objeto de data JavaScript para um timestamp do Firestore.
 * @param {Date | undefined} date - A data para converter em timestamp.
 * @returns {Timestamp} - O objeto de timestamp convertido.
 * @exemplo
 * const agora = new Date();
 * const timestamp = dateToFireTime(agora);
 */
export const dateToFireTime = (date?: Date) => {
	const cloneDate = clone(date)
	return cloneDate
		? helpersDate.convertAllToTimestamp(cloneDate)
		: helpersDate.convertAllToTimestamp(new Date())

}
