/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export const widthsModal = {
	'small': 'tw-max-w-[352px]',
	'medium': 'tw-max-w-[536px]',
	'large': 'tw-max-w-[904px]',
}

export interface IModalProps {
	children: React.ReactNode;
	show: boolean
	onClose?: React.Dispatch<React.SetStateAction<boolean>>;
	className?: string;
	/** Desabilita a funcionalidade de o modal fechar ao clicar fora dele
	* @default false
	*/
	disableOutsideClick?: boolean;
	width?: 'small' | 'medium' | 'large'
	/**
	 * Centraliza o conteúdo do modal no centro da tela
	 */
	centerOnScreen?: boolean
	/**
	 * Altura máxima para o conteúdo do modal
	 */
	maxHeight?: number
}

// useEffect(() => {
// 	const body = document.querySelector('body .wrapper')
// 	if (show) {
// 		body?.classList.add('tw-blur-md')
// 	}
// 	else {
// 		body?.classList.remove('tw-blur-md')
// 	}
// })
export const UiModal = ({ show, onClose, children, className, disableOutsideClick, width = 'medium', centerOnScreen = false, maxHeight }: IModalProps) =>
	<Transition.Root show={show} as={Fragment}>
		<Dialog as='div' className='tw-bg-neutral-500 tw-bg-opacity-75 tw-fixed tw-z-[999] tw-overflow-auto tw-inset-0' onClose={disableOutsideClick ? () => { } : (onClose ? onClose : () => { })}>
			<div className={`tw-flex tw-min-h-screen tw-py-20 tw-px-4 tw-pb-20 tw-text-center ${!centerOnScreen ? ' sm:tw-block ' : ' tw-items-center '} sm:tw-p-0`}>
				<Transition.Child
					as={Fragment}
					enter='tw-ease-out tw-duration-300'
					enterFrom='tw-opacity-0'
					enterTo='tw-opacity-100'
					leave='tw-ease-in tw-duration-200'
					leaveFrom='tw-opacity-100'
					leaveTo='tw-opacity-0'
				>
					<Dialog.Overlay className='tw-fixed tw-inset-0' />
				</Transition.Child>
				<Transition.Child
					as={Fragment}
					enter='tw-ease-out tw-duration-300'
					enterFrom='tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95'
					enterTo='tw-opacity-100 tw-translate-y-0 sm:tw-scale-100'
					leave='tw-ease-in tw-duration-200'
					leaveFrom='tw-opacity-100 tw-translate-y-0 sm:tw-scale-100'
					leaveTo='tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95'
				>
					<div className={
						`
							tw-relative tw-inline-block tw-text-left tw-mx-auto tw-bg-white tw-rounded-lg
							tw-shadow-xl tw-transform tw-transition-all tw-my-8 tw-max-h-[${maxHeight || 850}px]
							tw-w-full tw-overflow-auto ${widthsModal[width]} ${className || ''}
						`
					}>
						{children}
					</div>
				</Transition.Child>
			</div>
		</Dialog>
	</Transition.Root>
