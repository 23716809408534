import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const IndustryFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M6.80391 23.5426V25.8426H11.4039V23.5426H6.80391ZM6.80391 18.9426V21.2426H18.3039V18.9426H6.80391ZM13.7039 23.5426V25.8426H18.3039V23.5426H13.7039ZM20.6039 18.9426V21.2426H25.2039V18.9426H20.6039ZM20.6039 23.5426V25.8426H25.2039V23.5426H20.6039ZM4.50391 28.1426V12.0426L10.2539 16.6426V12.0426L16.0039 16.6426V12.0426L21.7539 16.6426L22.9039 5.14258H26.3539L27.5039 16.6426V28.1426H4.50391Z' fill='#272827' />
	</Svg>
)


