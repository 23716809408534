/**
 * A função `createTeaser` recebe parâmetros, clona-os, adiciona um teaser a um
 * banco de dados e chama uma função de retorno de chamada com o ID do teaser.
 * @param {TypeCreateTeaser} - A função `createTeaser` leva o seguinte
 * parâmetros:
 * @returns Um objeto vazio está sendo retornado pela função `createTeaser`.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { minhaData } from '../../../Functions/Utils/minhaData'
import { db } from '../../../Services/db'
import { db5 } from '../../../Services/db5'
import { TypeTeaser } from '../types/teaser'

type TypeCreateTeaser = {
	obj: Partial<TypeTeaser>
	nameBda?: string
	typeUser: string // TODO: @romulosardinha rever isso
	uid: string
	uidCoCreator: string
	fromOffice: string
	callBack: (id: string) => void
}

export const createTeaser = ({
	obj,
	nameBda,
	typeUser,
	uid,
	uidCoCreator,
	fromOffice,
	callBack
}: TypeCreateTeaser) => {

	const cloneObj = clone(obj)
	const cloneNameBda = clone(nameBda)
	const cloneTypeUser = clone(typeUser)
	const cloneUid = clone(uid)
	const cloneUidCoCreator = clone(uidCoCreator)
	const cloneFromOffice = clone(fromOffice)

	db5.teasers.add({
		doc: {
			...cloneObj,
			ativado: true,
			uid,
			uidCoCreator,
			fromOffice: cloneFromOffice,
			origin: cloneTypeUser === 'BDA' ? 'BDA' : '',
			dataCriacao: minhaData(),
			dataModificacao: minhaData(),
		}
	})
		.then(res => {
			if (res.id) callBack(res.id)
		})
	return {}
}
