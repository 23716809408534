import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeTeaser } from '../types/teaser'
/**
 * Função responsável por anexar teasers ao um teaser no momento da curadoria
 *
 * @param item: teaser anexado do tipo TAttachedTeasers
 * @param teaser: teaser TypeTeaser
 * returns teaser
 */
export const attachTeaser = async ({ item, teaser }: { item: TypeTeaser['attachedTeasers'][0], teaser: TypeTeaser }) => {

	const cloneItem = clone(item)
	const cloneTeaser = clone(teaser)

	const _teaser: TypeTeaser = { ...cloneTeaser, attachedTeasers: [...cloneTeaser.attachedTeasers, cloneItem] }
	const { _id } = cloneTeaser
	await db5.teasers.update({ id: _id, doc: _teaser })
	return _teaser
}


