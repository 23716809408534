import { clone } from '../../../Utils/clone'
import { Financing, FinancingItemProps, FinancingItemsProps, FinancingProps } from '../../../../Constants/FINANCING'
import { GUARANTEES } from '../../../../Constants/GUARANTEES'
import { ItemGuranteeProps, TypeTeaser } from '../../types/teaser'

/**
 * Retorna todas as garantias de um teaser no formato de um array
 * @param {TypeTeaser} teaser
 * @param {GUARANTEES|Financing} filter caso queira filtrar apenas algum tipo de garantia
 * @returns []
 * @example getAllGuaranteesInArray({ teaser, filter: GUARANTEES.guarantee1 })
 */
export const getAllGuaranteesInArray = ({ teaser, filter }: { teaser: TypeTeaser, filter?: GUARANTEES | Financing }) => {

	const cloneTeaser = clone(teaser)
	const cloneFilter = clone(filter)

	const { financing, guarantees } = cloneTeaser
	let allGuarantees: (ItemGuranteeProps | FinancingItemProps)[] = []
	allGuarantees.push(...getGuarantees(guarantees))
	allGuarantees.push(...getFinancingGuarantee(financing))

	if (cloneFilter) {
		allGuarantees = allGuarantees.filter(guarantee => guarantee.mainType === cloneFilter)
	}

	return allGuarantees
}
/**
 * Retorna todos os financiamentos de obras em um array
 * @param {FinancingProps | null} financings
 * @returns FinancingItemsProps
 */
const getFinancingGuarantee = (financings: FinancingProps | null): FinancingItemsProps => {
	const allGuarantees: FinancingItemsProps = []
	if (financings && financings.fin1) {
		financings.fin1.map(financing => {
			financing.mainType = Financing.fin1
			allGuarantees.push(financing)
		})
	}
	return allGuarantees
}
/**
 * Retorna todas as garantias em um array com seu tipo principal
 * @param guarantees
 * @returns ItemGuranteeProps[]
 */
const getGuarantees = (guarantees: TypeTeaser['guarantees']): ItemGuranteeProps[] => {
	const allGuarantees: ItemGuranteeProps[] = []
	if (guarantees && Object.keys(guarantees).length) {
		Object.keys(guarantees).forEach(key => {
			const _guarantees = guarantees[key as GUARANTEES] || []
			_guarantees.map(guarantee => guarantee.mainType = key as GUARANTEES)
			allGuarantees.push(..._guarantees)
		})
	}
	return allGuarantees
}
