import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { STATUS_MATCH_MAKING } from '../../../Constants/STATUS_MATCH_MAKING'
import { fnNotification } from '../../../Functions/Notification'
import { minhaData } from '../../../Functions/Utils/minhaData'
import { getHighestStatusFromMatches } from '../../../Services/cloudfunctions'
import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../types/match'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeTese } from '../../Tese/types/tese'
import { TypeUser } from '../../User/types/TypeUser'
import { setMatch } from './setMatch'

import { clone } from '../../../Functions/Utils/clone'
import { fnError } from '../../Errors/fnError'

// Matchmaking
export const toMatchmaking = async ({
	matchId,
	match,
	teaser,
	teaserId,
	profile,
	institution,
	institutionId,
	uid,
	tese,
	teseId,
}: {
	matchId: string
	match: TypeMatch
	teaser: TypeTeaser
	teaserId: string
	profile: TypeUser
	institution: TypeInstitution
	institutionId: string
	uid: string
	tese: TypeTese
	teseId: string
}) => {

	const cloneMatchId = clone(matchId)
	const cloneMatch = clone(match)
	const cloneTeaser = clone(teaser)
	const cloneTeaserId = clone(teaserId)
	const cloneProfile = clone(profile)
	const cloneInstitution = clone(institution)
	const cloneInstitutionId = clone(institutionId)
	const cloneUid = clone(uid)
	const cloneTese = clone(tese)
	const cloneTeseId = clone(teseId)

	// await toMatchmaking({ matchId, match, teaser, institutionId, institution, profile, uid })
	const getHighestStatus = async () => {
		await getHighestStatusFromMatches({
			'id': cloneTeaserId
		})
	}

	await setMatch({
		id: cloneMatchId,
		match: {
			...cloneMatch,
			status: STATUS_MATCH.matchMaking.value,
			matchMakingStatus: STATUS_MATCH_MAKING.matchMaking.value,
			matchStatus: '',
			dateMatchmaking: minhaData()
		}
	}).then(async () => {
		getHighestStatus()
	})

	const response = await fnNotification.customer.sendEnviadoMatchmaking({
		origination: cloneTeaser,
		originationId: cloneTeaserId,
		profile: cloneProfile,
		uid: cloneUid,
		match: cloneMatch,
		matchId: cloneMatchId,
		tese: cloneTese,
		teseId: cloneTeseId,
		institution: cloneInstitution,
		institutionId: cloneInstitutionId
	})
	if (response.status !== 200) fnError.alert({ message: 'Ocorreu um erro ao enviar a notificação, tente novamente mais tarde' })
}
