import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const StoreFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M16.0039 24.1426H8.50391V19.1426H16.0039V24.1426ZM27.2539 19.1426V16.6426L26.0039 10.3926H6.00391L4.75391 16.6426V19.1426H6.00391V26.6426H18.5039V19.1426H23.5039V26.6426H26.0039V19.1426H27.2539ZM26.0039 6.64258H6.00391V9.14258H26.0039V6.64258Z' fill='#272827' />
	</Svg>
)


