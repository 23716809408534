import { useContextAuth } from 'App/ContextAuth'
import { useGlobalContext } from 'App/Global'
import { LayoutMenuButton } from 'Components/Layout/LayoutMenuButton'
import { LayoutFooter } from 'Components/Layout/LayoutFooter'
import { Stark } from 'Components/Layout/FooterMenu/stark'
import { menu } from 'Components/Layout/LayoutMenuConstants/menu'
import { fireLogout } from 'Services/firebase'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UiModal } from 'ComponentsUi/UiModal'
import { UiButton } from 'ComponentsUi/UiButton'
import { InternaLoading } from 'Components/InternalLoading'
import { useContextMenu } from 'App/ContextMenu'

const ModelSair = ({ show, setShow }: { show: boolean, setShow: (e: boolean) => void }) => {
	const history = useHistory()
	const [loadingExiting, setLoadingExiting] = useState(false)
	const eventSair = () => {
		setLoadingExiting(true)
		fireLogout()
		history.push('/')
	}
	return (<>
		<UiModal onClose={() => setShow(false)} show={show} className='tw-p-4' width='small'>
			{
				loadingExiting ? <div className='tw-relative tw-h-14'><InternaLoading /></div> :
					<>
						<div className='tw-text-xl'>Sair</div>
						<p>Você deseja sair do sistema agora ?</p>
						<div className='tw-flex tw-justify-between'>
							<UiButton variant='secondary' onClick={() => setShow(false)}>Voltar</UiButton>
							<UiButton onClick={eventSair}>Sair</UiButton>
						</div>
					</>
			}
		</UiModal></>)
}

export const LayoutMenuSide = () => {
	const { globalAuth } = useContextAuth()
	const { menuActiveValue, setMenuActiveValue } = useContextMenu()
	const { profile } = globalAuth

	const [modalSairShow, setModalSairShow] = useState(false)

	const menuArray = menu(profile, menuActiveValue, setMenuActiveValue)

	const onClickLogout = () => {
		setModalSairShow(true)
	}

	return (
		<>

			<ModelSair show={modalSairShow} setShow={setModalSairShow} />

			<div className='tw-pb-4 tw-flex tw-flex-col tw-gap-4 tw-justify-between tw-h-screen tw-w-[200px] tw-border tw-border-solid tw-border-neutral-100 tw-bg-neutral-000 tw-fixed tw-overflow-y-auto'>
				<div className='tw-flex tw-flex-col tw-gap-2'>
					{
						menuArray?.map(menuItem => {

							if (menuItem.type === 'component') return (
								<div key={menuItem.key}>{menuItem.Component}</div>
							)

							if (menuItem.type === 'link') return (
								<LayoutMenuButton onClick={menuItem.onClick} to={menuItem.to} key={menuItem.key} active={menuItem.checkActive}>
									{menuItem.checkActive ? menuItem.iconActive : menuItem.icon}
									{menuItem.title}
								</LayoutMenuButton>
							)

							if (menuItem.type === 'linkGroup') return (
								<div key={menuItem.key}>
									<LayoutMenuButton onClick={menuItem.onClick} >
										{menuItem.icon}
										{menuItem.title}
										<div className='tw-flex-1'></div>
										<i className='material-icons'> {menuActiveValue === menuItem.title ? 'expand_less' : 'expand_more'}</i>
									</LayoutMenuButton>

									<div className={`tw-flex tw-mt-2 tw-ml-8 tw-gap-2 tw-flex-col ${menuActiveValue === menuItem.title ? '' : 'tw-hidden'}`}>
										{
											menuItem?.subLinks?.map(subItem => (
												<LayoutMenuButton small onClick={subItem.onClick} to={subItem.to} key={subItem.key} active={subItem.checkActive}>
													{subItem.icon}
													{subItem.title}
												</LayoutMenuButton>
											)) || null
										}
									</div>
								</div>
							)

						})}
				</div>

				<div className='tw-flex tw-flex-col tw-gap-4'>

					<LayoutMenuButton onClick={onClickLogout}>
						<i className='material-icons'>power_settings_new</i>
						Sair
					</LayoutMenuButton>

					<div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-border-x-0 tw-border-b-0 tw-pt-4 tw-border tw-border-solid tw-border-neutral-100'>
						<Stark />
						<LayoutFooter />
					</div>

				</div>

			</div>
		</>
	)
}
