import { clone } from '../../Utils/clone'
import { TypeMatch } from '../types/match'
import { db5 } from '../../../Services/db5'

/**
 * Responsável por remover a url e o fileName de um documento no match
 * @param [TypeMatch] match
 * @param [string] documentId
 * @returns match
 */
export const removeDocumentFile = async ({ match, documentId }: { match: TypeMatch, documentId: string }) => {
	const cloneMatch = clone(match)
	const cloneDocumentId = clone(documentId)

	const { _id } = cloneMatch
	cloneMatch.documents = cloneMatch.documents.map(_document => (_document._id === cloneDocumentId) ? { ..._document, url: '', fileName: '' } : _document)
	await db5.matches.update({ id: _id, doc: { documents: cloneMatch.documents } })
	return cloneMatch
}
