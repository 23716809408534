import { ITheme } from '../index.d'
import { ui } from './ui'
import { borders } from './borders'
import { spaces } from './spaces'
import { transitions } from './transitions'
import { typography } from './typography'
import { breakpoints } from './breakpoints'

export const Default: ITheme = {
	ui,
	borders,
	spaces,
	transitions,
	typography,
	breakpoints
}
