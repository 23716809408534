/* O código TypeScript acima define uma função `getValidateDue` que calcula
e retorna informações sobre o status de validação de um objeto teaser com base em
vários critérios como períodos de exclusividade para diferentes etapas, prazos,
e dias de validação. Aqui está um resumo do que o código está fazendo: */
//CRIADO POR IA

import { add, differenceInDays, parse } from 'date-fns'
import { fnUser } from '../../../Functions/User'
import { clone } from '../../../Functions/Utils/clone'
import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { escreveData, myDateToDate } from '../../../Functions/Utils/minhaData'
import { TypeTeaser } from '../types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { inAtivo } from './inAtivo'
import { inComercial } from './inComercial'
import { inComite } from './inComite'
import { inStandby } from './inStandby'
import { isNotPublicado } from './isNotPublicado'
import { isAgent } from '../../User/methods/isAgent'

const MAX_DAYS_TO_WARNING_USER_FROM_NOT_PUBLISHED = 15
const MAX_DAYS_TO_WARNING_USER_FROM_COMERCIAL = 30
const MAX_DAYS_TO_WARNING_USER_FROM_STANDBY = 30
const MAX_DAYS_TO_WARNING_USER_FROM_COMERCIAL_VALIDATION = 10

export const getValidateDue = (teaser: TypeTeaser, authorProfile?: TypeUser) => {

	const cloneTeaser = clone(teaser)
	const cloneAuthorProfile = clone(authorProfile)

	// // const daysOfNotPublished = teaser.firstStepDaysOfExclusivity
	/** * @description Dias de exclusividade quando não publicada */
	const daysOfNotPublished = 30

	// // const daysOfStandBy = teaser.thirdStepDaysOfExclusivity
	/** * @description Dias de exclusividade em standby */
	const daysOfStandBy = (() => {
		if (cloneAuthorProfile && cloneTeaser.dataPublicacao && isAgent(cloneAuthorProfile)) {
			const dataCriacao = helpersDate.convertAllToDate(cloneTeaser.dataPublicacao)
			return dataCriacao > new Date('2023-10-04') ? 180 : 365
		}
		// Caso não seja um agente, retorna 365 dias
		return 365
	})()

	// // const daysOfComercial = teaser.secondStepDaysOfExclusivity
	/** * @description Dias de exclusividade em comercial */
	const daysOfComercial = 180

	// const daysOfActive = teaser.fourthStepDaysOfExclusivity
	/** * @description Dias de exclusividade em ativo */

	const daysOfActive = (() => {
		if (cloneTeaser?.contract?.dueContract) {
			// (data do contrato + meses de contrato) - data do contrato = restante de dias
			const dueContract = add(helpersDate.convertAllToDate(cloneTeaser.dataContrato), { months: cloneTeaser?.contract.dueContract })
			const restDays = differenceInDays(dueContract, helpersDate.convertAllToDate(cloneTeaser.dataContrato))

			return restDays
		}
		return 365
	})()

	let date = 0
	let diffDateTime = 0
	let diffDateDays = 0
	let result = 0
	let defeated = false
	let resp = ''
	let type = 'Validade' // Exclusividade / Validade / Prazo
	const dateNow = new Date().getTime()
	let deadline = false // Se o prazo está abaixo do limite

	let validationDays = 0
	let validationDaysInDue = true
	let validationDaysStringFormatted = ''
	let validationDaysString = '' // string a ser apresentada de acordo com o status Ex.: Vencida | 10 dias
	let validationDaysRemaining = 0 // dias que ainda faltam para a validade expirar
	let validationDaysDeadline = false

	if (inStandby(cloneTeaser)) {
		date = myDateToDate(cloneTeaser.dataStandby).getTime()
		diffDateTime = dateNow - date
		diffDateDays = diffDateTime / (1000 * 3600 * 24)
		result = daysOfStandBy - Math.floor(diffDateDays)
		type = 'Exclusividade'
		resp = `${result} dias`

		if (result > 0 && result <= MAX_DAYS_TO_WARNING_USER_FROM_STANDBY) deadline = true
		if (result <= 0) {
			defeated = true
			resp = 'Vencida'
		}
	}

	if (inComercial(cloneTeaser)) {
		date = myDateToDate(cloneTeaser.dataComercial).getTime()
		diffDateTime = dateNow - date
		diffDateDays = diffDateTime / (1000 * 3600 * 24)
		result = daysOfComercial - Math.floor(diffDateDays)
		type = 'Exclusividade'
		resp = `${result} dias`

		const VALIDATION_DUE = 30

		const currentDate = new Date()

		const cloneDataComercial = escreveData(cloneTeaser.dataComercial.toString())
		const parsedDate = parse(cloneDataComercial, 'dd/MM/yyyy', currentDate)

		validationDays = differenceInDays(currentDate, parsedDate)

		if (VALIDATION_DUE - validationDays <= 0) {
			validationDaysInDue = false
			validationDaysStringFormatted = 'Validade: vencida'
			validationDaysString = 'Vencida'
		}
		else {
			const remainingDays = VALIDATION_DUE - validationDays
			validationDaysStringFormatted = `Validade: ${remainingDays} dias`
			validationDaysString = `${remainingDays} ${remainingDays > 1 ? 'dias' : 'dia'}`
		}

		if (result > 0 && result <= MAX_DAYS_TO_WARNING_USER_FROM_COMERCIAL) deadline = true
		if (result <= 0) {
			defeated = true
			resp = 'Vencido'
		}

		validationDaysRemaining = VALIDATION_DUE - validationDays
		validationDaysDeadline = validationDaysRemaining <= MAX_DAYS_TO_WARNING_USER_FROM_COMERCIAL_VALIDATION
	}

	if (inComite(cloneTeaser)) {
		date = myDateToDate(cloneTeaser.dataPublicacao).getTime()
		diffDateTime = dateNow - date
		diffDateDays = diffDateTime / (1000 * 3600 * 24)
		result = Math.floor(diffDateDays)
		if (result > 7) defeated = true
		type = 'Prazo'
		resp = `${result} dias`
	}

	if (isNotPublicado(cloneTeaser)) {
		date = myDateToDate(cloneTeaser.dataCriacao).getTime()
		diffDateTime = dateNow - date
		diffDateDays = diffDateTime / (1000 * 3600 * 24)
		result = daysOfNotPublished - Math.floor(diffDateDays)
		type = 'Prazo'
		resp = `${result} dias`
		if (result > 0 && result <= MAX_DAYS_TO_WARNING_USER_FROM_NOT_PUBLISHED) deadline = true
		if (result <= 0) {
			defeated = true
			resp = 'Vencido'
		}
	}

	if (inAtivo(cloneTeaser)) {
		date = myDateToDate(cloneTeaser.dataContrato).getTime()
		diffDateTime = dateNow - date
		diffDateDays = diffDateTime / (1000 * 3600 * 24)
		result = daysOfActive - Math.floor(diffDateDays)
		type = 'Validade'
		resp = `${result} dias`
		if (result <= 0) {
			defeated = true
			resp = 'Vencido'
		}
	}

	return {
		result,
		resp,
		type,
		defeated,
		deadline,

		validationDays, //28
		validationDaysInDue, // true
		validationDaysStringFormatted,

		daysOfNotPublished,
		daysOfComercial,
		daysOfStandBy,
		daysOfActive,
		validationDaysRemaining,
		validationDaysDeadline,
		validationDaysString
	}
}
