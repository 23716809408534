export const TRANSACTION_TYPES = {
	private: {
		_id: 'private',
		label: 'Capital fechado'
	},
	public: {
		_id: 'public',
		label: 'Capital aberto'
	}
}
