import { TypeDbResponse } from '../TypeDBResponse'
import { DocumentData, WhereFilterOp, CollectionReference, Query } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { generateWheres } from '../configs/wheres'
import { permissionsGetThisCollection } from '../configs/permissionsGetThisCollection'
import { TypeCollections } from '../configs/collections'
import { cfGetBlind } from 'Services/cloudfunctions'

export const getWhere = <TDoc extends DocumentData>(collection: TypeCollections, model: (doc?: DocumentData) => TDoc,) => (
	async ({ wheres, limit, orderBy, profile, lastDocumentId }: { profile: TypeUser, wheres?: [string, WhereFilterOp, any][], limit?: number, orderBy?: [string, firebase.firestore.OrderByDirection][], lastDocumentId?: string }) => {

		const serviceResult: TypeDbResponse<TDoc> = {
			status: 400
		}

		const runServerLess = async () => {

			let ref: Query<DocumentData> = firebase.firestore().collection(collection)
			ref = generateWheres(ref, collection, profile)

			if (wheres?.length) {
				wheres.forEach(where => (ref = ref.where(...where)))
			}

			if (orderBy?.length) {
				orderBy.forEach(order => (ref = ref.orderBy(order[0], order[1])))
			}

			if (limit && limit > 0) {
				ref = ref.limit(limit)
			}

			if (lastDocumentId) {
				const lastDocument = await firebase.firestore().collection(collection).doc(lastDocumentId).get()
				ref = ref.startAfter(lastDocument)
			}
			try {
				const response = await ref.get()

				const docs: { [key: string]: TDoc } = {}
				response.forEach(doc => (docs[doc.id] = model({ ...doc.data(), _id: doc.id })))

				serviceResult.status = 200
				serviceResult.docs = docs
			}
			catch (error) {
				throw new Error(`Erro ao obter documentos :: ${error}`)
			}
		}

		const runCloud = async () => {
			const res = await cfGetBlind<TDoc>({
				collection: collection,
				where: wheres?.length ? wheres : undefined,
				orderBy: orderBy?.length ? orderBy : undefined,
				limit: limit || undefined,
				startAfterDocId: lastDocumentId || undefined
			})
			if (res.status === 200) {
				serviceResult.status = res.status
				res?.docs ? (serviceResult.docs = res.docs) : null
				res?.doc ? (serviceResult.doc = res.doc) : null
				res?.id ? (serviceResult.id = res.id) : null
			}
			else {
				throw new Error('Erro ao obter documentos')
			}
			return
		}
		// console.log(permissionsGetThisCollection(collection, profile))
		if (permissionsGetThisCollection(collection, profile)) {
			await runServerLess()
		}
		else {
			await runCloud()
		}

		return serviceResult

	}
)



