import React, { FC, HTMLAttributes, ReactNode, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ReactDOM from 'react-dom'
import * as Tokens from 'Components/CardDS/src/Tokens'

// based on https://medium.com/tinyso/how-to-create-a-modal-component-in-react-from-basic-to-advanced-a3357a2a716a

/** @deprecated */
export interface IModal extends HTMLAttributes<HTMLDivElement> {
	/** Show or hide modal */
	show?: boolean;

	/** On modal close method */
	onClose?: () => void;

	/** Modal title */
	title?: string;

	/** Root React id */
	rootId?: string;

	/** Background opacity 0 to 1 */
	bgOpacity?: number;

	width?: number;

	bgColor?: string;

	closeColor?: string;

	footer?: ReactNode;

	header?: ReactNode;

	paddingBox?: boolean
}

const StyledContent = styled.div<{ bgColor?: string, width?: number }>`
  max-width: ${props => props.width || 400}px;
  background-color: ${props => props.bgColor || '#ffffff'};
  transition: all 0.2s ease-in-out;
  transform: translateY(-200px);
  width: ${props => `${props.width}px` || '100%'}
  border-radius: 8px;
  border: 1px solid ${Tokens.COLOR_NEUTRAL_400};
`
//
const StyledModal = styled.div<{ bgOpacity?: number }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, ${props => props.bgOpacity || .5});
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: -9999;
  padding: ${Tokens.SPACE_3};
  display: flex;
  flex-wrap: wrap;

  &.show{
    opacity: 1;
    pointer-events: visible;
    z-index: 9999;
    height: auto;
    width: auto;
    overflow: auto;
  }
  &.show ${StyledContent}{
    transform: translateY(0);
  }
  /*
  &.enter-done{
    opacity: 1;
    pointer-events: visible;
  }
  &.exit{
    opacity: 0;
  }
  &.enter-done ${StyledContent}{
    transform: translateY(0);
  }
  &.exit ${StyledContent}{
    transform: translateY(-200px);
  }*/
`
const StyledHeader = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
`
const StyledTittle = styled.h4`
  margin: 0 15px 0 0;
  font-family: ${Tokens.FONT_MAIN};
  font-size: ${Tokens.FONT_MEDIUM};
  font-weight: ${Tokens.FONT_SEMI_BOLD};
`
const StyledBody = styled.div<{ hasFooter: boolean, hasHeader: boolean, paddingBox: boolean }>`
	${props => props.paddingBox ? css`padding: 12px;` : ''};
  ${props => props.hasHeader ? css`border-top: 1px solid #eee` : ''};
  ${props => props.hasFooter ? css`border-bottom: 1px solid #eee` : ''};
`
const StyledFooter = styled.div`
  padding: ${Tokens.SPACE_3};
  display: flex;
`

const StyledClose = styled.svg.attrs((props: { color?: string }) => ({
	version: '1.1',
	xmlns: 'http://www.w3.org/2000/svg',
	xmlnsXlink: 'http://www.w3.org/1999/xlink',
	color: props.color
}))`
  width: 16px;
  height: 16px;
  fill: currentColor;
  color: ${props => props.color || '#000000'};
  cursor: pointer;
  *{
    fill: ${props => props.color};
  }
`
/** @deprecated */
export const Modal: FC<IModal> = props => {
	const { show, title, rootId, bgOpacity, bgColor, width, closeColor, footer, header, paddingBox = true } = props
	const rootElement = document.getElementById(rootId || 'root')

	const closeOnEscapeKeyDown = (e: { charCode: any; keyCode: any; }) => {
		if ((e.charCode || e.keyCode) === 27 && props.onClose) {
			props.onClose()
		}
	}

	useEffect(() => {
		document.body.addEventListener('keydown', closeOnEscapeKeyDown)
		return function cleanup() {
			document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function onClose() {
		if (props.onClose) {
			props.onClose()
		}
	}

	function getHeader() {
		if (header) {
			return <StyledHeader> {header} </StyledHeader>
		}

		return title && <StyledHeader>
			<StyledTittle>{title}</StyledTittle>
			<StyledClose onClick={onClose} color={closeColor}>
				<path d='M4.26756 13C4.08696 13 3.90635 12.9395 3.72575 12.758C3.42475 12.4555 3.42475 11.9714 3.72575 11.6689L11.1906 4.22689C11.4916 3.92437 11.9732 3.92437 12.2742 4.22689C12.5752 4.52941 12.5752 5.01345 12.2742 5.31597L4.80936 12.758C4.68896 12.9395 4.50836 13 4.26756 13Z' />
				<path d='M11.6722 13C11.4916 13 11.311 12.9399 11.1304 12.7596L3.72575 5.30718C3.42475 5.00668 3.42475 4.52588 3.72575 4.22538C4.02676 3.92487 4.50836 3.92487 4.80936 4.22538L12.2742 11.6778C12.5753 11.9783 12.5753 12.4591 12.2742 12.7596C12.0936 12.9399 11.8528 13 11.6722 13Z' />
			</StyledClose>
		</StyledHeader>
	}

	return ReactDOM.createPortal(
		<StyledModal onClick={onClose} className={`${show ? ' show ' : ''}`} bgOpacity={bgOpacity}>
			<StyledContent onClick={e => e.stopPropagation()} bgColor={bgColor} width={width}>
				{getHeader()}

				<StyledBody hasFooter={!!footer} hasHeader={title || header ? true : false} paddingBox={paddingBox}>
					{props.children}
				</StyledBody>

				{footer && <StyledFooter>{footer}</StyledFooter>}
			</StyledContent>
		</StyledModal>
		, rootElement as Element)
}
