import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { TypeMatch } from '../types/match'
import { fnMatch } from '..'
import { clone } from '../../../Functions/Utils/clone'

//Excluidos
export const inExcluidos = (match: TypeMatch) => {

	const cloneMatch = clone(match)

	return fnMatch.status(cloneMatch) === STATUS_MATCH.excluido.value

}
