/**
 * Este código TypeScript define tipos e uma função para extrair detalhes do originador
 * de teasers e usuários.
 * @property {string} originatorType - A propriedade `originatorType` no
 * O objeto `TypeOriginatorDetails` representa o tipo do originador de um
 * provocação. Pode ser 'empresa' ou um tipo personalizado especificado no `tipo`
 * propriedade do objeto usuário.
 * @property {string} originatorName - A propriedade `originatorName` no
 * O tipo `TypeOriginatorDetails` representa o nome do usuário que criou o
 * provocação. É extraído do objeto `users` usando o `uid` do teaser
 * propriedade. Se o usuário com esse `uid` existir no objeto `users`
 * @property {string} teaserOffice - A propriedade `teaserOffice` no
 * O tipo `TypeOriginatorDetails` representa o escritório associado a um teaser. Isto
 * é uma string que contém o nome do escritório de origem do teaser.
 * Esta propriedade é utilizada para fornecer informações sobre o escritório relacionado a um
 * teaser específico no contexto de
 * @property {string} originatorOffice - A propriedade `originatorOffice` no
 * O tipo `TypeOriginatorDetails` representa o escritório do originador de um
 * provocação. É uma string que contém o nome do escritório onde o teaser
 * originado de.
 * @property {string} epResponsavel - A propriedade `epResponsavel` no
 * O tipo `TypeOriginatorDetails` representa o EP responsável (Enterprise Partner)
 * para um teaser específico. É preenchido com o nome do EP se o usuário
 *associado ao teaser está um MicroBda (Agente de Desenvolvimento de Micro Negócios). Se
 *o usuário é
 * @property {string} labelColor - A propriedade `labelColor` no
 * O tipo `TypeOriginatorDetails` é usado para armazenar a cor associada ao
 * originador. Esta cor pode ser usada para estilo ou representação visual
 * finalidades no aplicativo. Provavelmente está definido na constante `USERS_CONST`
 *com base no tipo de usuário
 * @property {string} logoOffice - A propriedade `logoOffice` no
 * O tipo `TypeOriginatorDetails` representa a URL da imagem/logotipo associada
 *com o escritório de origem do teaser. É um tipo de string que contém
 * o URL da imagem/logotipo para fins de representação visual ou branding.
 */
//CRIADO POR IA

import { TypeUser } from '../../User/types/TypeUser'
import { TypeTeaser } from '../types/teaser'
import { USERS_CONST } from '../../../Constants/TYPE_USER'
import { isEnterprise } from '../../../Functions/User/methods/isEnterprise'
import { isMicroBda } from '../../../Functions/User/methods/isMicroBda'
import { modelUser } from '../../User/model/modelUser'

export type TypeOriginatorDetails = {
	originatorType: string,
	originatorName: string,
	teaserOffice: string,
	originatorOffice: string,
	epResponsavel: string,
	labelColor: string,
	logoOffice: string,
}

export type TypeTeaserObject = {
	[teaserId: string]: TypeOriginatorDetails;
}
export const getTeasersOriginatorDetails = (teasers: TypeTeaser[], users: { [id: string]: TypeUser; }) => {

	let teaserObject: TypeTeaserObject = {}


	teasers.forEach((teaser: TypeTeaser) => {
		const user = modelUser(users[teaser.uid])
		const typeUser = isEnterprise(user) ? 'enterprise' : user.tipo
		teaserObject = {
			...teaserObject, [teaser._id]: {
				originatorType: USERS_CONST[typeUser].label,
				originatorName: users[teaser.uid]?.nome || '',
				teaserOffice: users[teaser.fromOffice]?.nome || '',
				originatorOffice: !isEnterprise(users[users[teaser.uid]?.fromOffice]) && users[users[teaser.uid]?.fromOffice] ? users[users[teaser.uid]?.fromOffice].nome : '',
				epResponsavel: isMicroBda(users[teaser.uid]) ? users[teaser.uid]?.nome : '',
				labelColor: USERS_CONST[typeUser].color,
				logoOffice: users[teaser.fromOffice]?.ulrimg || '',
			}
		}
	})
	return teaserObject

}
