import * as Tokens from '../../../Tokens'

export const SearchTheme = {
	size: {
		md: Tokens.SPACE_11
	},
	border: {
		weight: Tokens.BORDER_WEIGHT_THIN,
		color: Tokens.COLOR_NEUTRAL_400,
		radius: Tokens.BORDER_MD,
	},
	label: {
		color: '#6F6F6F',
		font: Tokens.FONT_MAIN,
		size: Tokens.FONT_SIZE_SM,
		weight: Tokens.FONT_SEMI_BOLD
	},
	icon: {
		color: Tokens.COLOR_WHITE,
		backgroundColor: Tokens.COLOR_PRIMARY_650
	},
	input: {
		placeholderColor: Tokens.COLOR_NEUTRAL_400,
		color: '#6F6F6F',
		font: Tokens.FONT_MAIN,
		size: Tokens.FONT_SIZE_SM
	},
	variants: {
		disabled: {

		}
	},
	actions: {
		hover: {
			borderColor: Tokens.COLOR_PRIMARY_650
		},
		focus: {
			borderColor: Tokens.COLOR_PRIMARY_650,
			shadowColor: Tokens.SHADOW_200
		}
	}
}
