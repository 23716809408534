const url = typeof window !== 'undefined' ? window.location.href : ''

export const isTesting = () => {
	if (url.match('https://stark-testing.web.app/')) return true
	return false
}

export const isDevelopment = () => {
	if ((url.match('http://localhost') || url.match('https://stark-development-fb954.web.app/')) && (process.env.REACT_APP_FB_AUTHDOMAIN)?.match('development')) return true
	return false
}

export const isProductionDev = () => {
	if (url.match('http://localhost') && !(process.env.REACT_APP_FB_AUTHDOMAIN)?.match('development')) return true
	return false
}

export const isProduction = () => {
	if (!url.match('http://localhost') && !(process.env.REACT_APP_FB_AUTHDOMAIN)?.match('development')) return true
	return false
}

export const isProductionEnv = () => {
	if (!(process.env.REACT_APP_FB_AUTHDOMAIN)?.match('development')) return true
	return false
}
