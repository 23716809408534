import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'
import { inComercial } from './inComercial'
import { inComite } from './inComite'
import { inStandby } from './inStandby'
import { isNotPublicado } from './isNotPublicado'

/**
 * Retorna o título da situação do teaser dependendo do seu status
 * @param teaser
 * @returns Exclusividade | Prazo | Validade | ''
 */
export const getValidityLabel = ({ teaser }: { teaser: TypeTeaser }) => {
	if (inComite(teaser)) return 'Prazo'
	if (inComercial(teaser) || inStandby(teaser) || isNotPublicado(teaser)) return 'Exclusividade'
	if (inAtivo(teaser)) return 'Validade'
	return ''
}
