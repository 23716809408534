import { clsx, ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const customTwMerge = extendTailwindMerge({
	prefix: 'tw-'
})

/**
 * Combina múltiplas classes CSS em uma única string de classe usando as bibliotecas clsx e tailwind-merge.
 *
 * @param {...ClassValue[]} inputs - Os valores das classes a serem combinadas. Isso pode ser uma string, um objeto ou um array de strings e/ou objetos.
 * @returns {string} - Uma string de classe combinada resultante da mesclagem dos valores fornecidos.
 *
 * @example
 * const mergedClasses = mergeClassName('text-red', 'bg-blue', { 'font-bold': true }, ['p-4', 'rounded']);
 * console.log(mergedClasses); // Retorna: 'text-red bg-blue font-bold p-4 rounded'
 */

export const mergeClassName = (...inputs: ClassValue[]) => customTwMerge(clsx(inputs))
