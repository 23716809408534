export const FOLLOWUP_CONTACT_TYPES = {
	c100: { label: 'Email', _id: 'c100' },
	c200: { label: 'Telefonema', _id: 'c200' },
	c300: { label: 'Whatsapp', _id: 'c300' },
	c400: { label: 'Linkedin', _id: 'c400' },
	c500: { label: 'Outros', _id: 'c500' }
} as const

export const FOLLOWUP_CONTACT_TYPES_GET = (code: string) => {
	const _code = code as keyof typeof FOLLOWUP_CONTACT_TYPES

	if (!FOLLOWUP_CONTACT_TYPES[_code]) {
		return (FOLLOWUP_CONTACT_TYPES.c500)
	}

	return FOLLOWUP_CONTACT_TYPES[_code]
}
export const FOLLOWUP_CONTACT_TYPES_VALUES = Object.values(FOLLOWUP_CONTACT_TYPES)
export const FOLLOWUP_CONTACT_TYPES_KEYS = Object.keys(FOLLOWUP_CONTACT_TYPES)


