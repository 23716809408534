import { UiBarProgress } from 'ComponentsUi/UiBarProgress'
import { elasticFunction } from 'Functions/Utils/elasticFunction'
import { useCallback, useEffect, useRef, useState } from 'react'

export const LoadingProgress = ({ milliseconds = 5000 }: { milliseconds?: number }) => {
	const [percent, setPercent] = useState<number>(0)
	const percents = elasticFunction(milliseconds)
	const [index, setIndex] = useState<number>(0)
	const isCancelled = useRef(false) // Usando para não dar o erro "Can't perform a React state update on an unmounted component"

	const setPercentFunction = () => {
		if (!isCancelled.current) {
			if (percent < 94) {
				if (index === 0) {
					setPercent(percent + percents[0])
				}
				else {
					setTimeout(() => {
						setPercent(percent + percents[index])
					}, 1000)
				}

				setIndex(index + 1)

			}
		}

	}

	useEffect(() => {
		setPercentFunction()
		return () => {
			isCancelled.current = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [percent])

	return (
		<div className='tw-w-64'><UiBarProgress percentValue={percent} percentTotal={100} /></div>
	)
}
