import { TypeDarwinFiles } from '../types/TypeDarwinFiles'

export const modelDarwinFiles = (obj: Partial<TypeDarwinFiles>): TypeDarwinFiles => ({
	_id: obj._id || '',
	teaserId: obj.teaserId ?? '',
	createdAt: obj.createdAt ?? 0,
	originalTrialBalances: obj.originalTrialBalances ?? [],
	companiesName: obj.companiesName ?? [],
	bucketName: obj.bucketName ?? '',
	chartOfAccountsFilePath: obj.chartOfAccountsFilePath ?? '',
	trialBalanceFilePath: obj.trialBalanceFilePath ?? '',
	fileMergedFilePath: obj.fileMergedFilePath ?? ''
})
