const _SUB_TYPE_OPERATIONS = {
	greenfield: {
		id: 'greenfield',
		label: 'Greenfield',
		scoreLabel: ''
	},
	corporateFinance: {
		id: 'corporateFinance',
		label: 'Corporate Finance',
		scoreLabel: ''
	},
	projectFinance: {
		id: 'projectFinance',
		label: 'Project Finance',
		scoreLabel: 'Término de Obra'
	},
	total: {
		id: 'total',
		label: 'Aquisição Total',
		scoreLabel: 'total'
	},
	minoritário: {
		id: 'minoritário',
		label: 'Aquisição Minoritária',
		scoreLabel: 'minoritário'
	},
	majoritário: {
		id: 'majoritário',
		label: 'Aquisição Majoritária',
		scoreLabel: 'majoritário'
	},
}

export const _SUB_TYPE_OPERATIONS_GET = (code: string) => {
	const _code = code as keyof typeof _SUB_TYPE_OPERATIONS

	if (!_SUB_TYPE_OPERATIONS[_code]) {
		return (
			{
				id: '',
				label: '',
				scoreLabel: ''
			}
		)
	}

	return _SUB_TYPE_OPERATIONS[_code]
}
export const _SUB_TYPE_OPERATIONS_VALUES = Object.values(_SUB_TYPE_OPERATIONS)
export const _SUB_TYPE_OPERATIONS_KEYS = Object.keys(_SUB_TYPE_OPERATIONS)


export const SUB_TYPE_OPERATIONS = {
	ARRAY: Object.values(_SUB_TYPE_OPERATIONS)
}

export type SUB_TYPE_OPERATIONS_KEYS = keyof typeof _SUB_TYPE_OPERATIONS
