import { SUB_TYPE_OPERATIONS_KEYS } from '../../../../../Constants/SUB_TYPE_OPERATIONS'
import { TYPE_OPERATION_CODE } from '../../../../../Constants/TYPE_OPERATION'
import { TypeTeaser } from '../../../../../Functions/Teaser/types/teaser'
import { clone } from '../../../../../Functions/Utils/clone'

/* Recebe um teaser e um id. Clona o teaser. Cria uma variável que é um objeto de operação e recebe as proprieades da operação de Credops. Retorna a nova operação */
export const translateCredOps = (teaser: TypeTeaser, id: string) => {

	const cloneTeaser = clone(teaser)

	const newOperationCredops: TypeTeaser['operations'][0] = {
		id: id,
		operationType: TYPE_OPERATION_CODE['Crédito']._id,
		operationSubType: ['corporateFinance'] as SUB_TYPE_OPERATIONS_KEYS[],
		motivoCaptacao: cloneTeaser.credopsMotivoCaptacao,
		valorDesejadoOperacao: Number(cloneTeaser.desiredValue),
		valorReperfilamento: cloneTeaser.credopsValorReperfilamento,
		reperfilamento: cloneTeaser.credopsReperfilamento
	}

	return newOperationCredops
}
