export const TESE_CREDIT_INSTITUTIONAL_INVESTOR_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_INSTITUTIONAL_INVESTOR

	if (!TESE_CREDIT_INSTITUTIONAL_INVESTOR[_code]) {
		return (TESE_CREDIT_INSTITUTIONAL_INVESTOR.ii000)
	}

	return TESE_CREDIT_INSTITUTIONAL_INVESTOR[_code]
}
export const TESE_CREDIT_INSTITUTIONAL_INVESTOR = {
	ii000: { label: 'Não é necessário', _id: 'ii000' },
	ii001: { label: 'Sim, preferencialmente', _id: 'ii001' },
	ii002: { label: 'Sim, obrigatório investidor', _id: 'ii002' },
} as const

export const TESE_CREDIT_INSTITUTIONAL_INVESTOR_VALUES = Object.values(TESE_CREDIT_INSTITUTIONAL_INVESTOR)
export const TESE_CREDIT_INSTITUTIONAL_INVESTOR_KEYS = Object.keys(TESE_CREDIT_INSTITUTIONAL_INVESTOR)
