import React, { createContext, useContext, useState } from 'react'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'

const _useContext = () => {

	const [globalCustomerTeaser, setGlobalCustomerTeaser] = useState('' as '' | TypeTeaser)
	const [globalCustomerTeaserId, setGlobalCustomerTeaserId] = useState('')

	return ({

		globalCustomerTeaser, setGlobalCustomerTeaser,
		globalCustomerTeaserId, setGlobalCustomerTeaserId,
	})
}
const ContextCustomerTeaser = createContext({} as ReturnType<typeof _useContext>)

export const useContextCustomerTeaser = () => useContext(ContextCustomerTeaser)
export const ContextCustomerTeaserProvider: React.FC = ({ children }) => {
	const controller = _useContext()
	return (
		<ContextCustomerTeaser.Provider value={controller}>
			{children}
		</ContextCustomerTeaser.Provider>
	)
}
