export const TYPE_OPERATIONS_CODE_GET = (code: keyof typeof TYPE_OPERATION_CODE | undefined | null) => {
	if (!code || !TYPE_OPERATION_CODE?.[code]) {
		return (TYPE_OPERATION_CODE['M&A'])
	}

	return TYPE_OPERATION_CODE[code]
}

/** @deprecated */
export enum TYPE_OPERATION {
	op1 = 'M&A',
	op2 = 'Crédito',
	op3 = 'M&A e Crédito'
}

export const TYPE_OPERATION_CODE = {
	'M&A': {
		_id: 'M&A',
		label: 'M&A',
		adapter: TYPE_OPERATION.op1
	},
	'Crédito': {
		_id: 'Crédito',
		label: 'Crédito',
		adapter: TYPE_OPERATION.op2
	},
	'M&A e Crédito': {
		_id: 'M&A e Crédito',
		label: 'M&A e Crédito',
		adapter: TYPE_OPERATION.op3
	}
} as const


/** @deprecated */
export const TYPE_OPERATIONS = {
	op1: {
		_id: 'op1',
		label: 'M&A',
	},
	op2: {
		_id: 'op2',
		label: 'Crédito',
	},
	op3: {
		_id: 'op3',
		label: 'M&A e Crédito'
	}
} as const

export const TYPE_OPERATION_CODE_VALUES = Object.values(TYPE_OPERATION_CODE)
export const TYPE_OPERATION_CODE_KEYS = Object.keys(TYPE_OPERATION_CODE)
