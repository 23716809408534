import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const SaveOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M6.3162 5.09881C5.99333 5.09881 5.68368 5.22707 5.45538 5.45538C5.22707 5.68368 5.09881 5.99333 5.09881 6.3162V25.6838C5.09881 26.0067 5.22707 26.3163 5.45538 26.5446C5.68368 26.7729 5.99333 26.9012 6.3162 26.9012H25.6838C26.0067 26.9012 26.3163 26.7729 26.5446 26.5446C26.7729 26.3163 26.9012 26.0067 26.9012 25.6838V11.1082L20.8918 5.09881H6.3162ZM3.26419 3.26419C4.07363 2.45474 5.17148 2 6.3162 2H21.5336C21.9445 2 22.3386 2.16324 22.6292 2.45381L29.5462 9.3708C29.8367 9.66137 30 10.0555 30 10.4664V25.6838C30 26.8285 29.5452 27.9264 28.7358 28.7358C27.9264 29.5452 26.8285 30 25.6838 30H6.3162C5.17147 30 4.07363 29.5452 3.26419 28.7358C2.45474 27.9264 2 26.8285 2 25.6838V6.3162C2 5.17148 2.45474 4.07363 3.26419 3.26419Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M7.53418 17.3834C7.53418 16.5277 8.22787 15.834 9.08359 15.834H22.9176C23.7733 15.834 24.467 16.5277 24.467 17.3834V28.4506C24.467 29.3063 23.7733 30 22.9176 30C22.0619 30 21.3682 29.3063 21.3682 28.4506V18.9328H10.633V28.4506C10.633 29.3063 9.9393 30 9.08359 30C8.22787 30 7.53418 29.3063 7.53418 28.4506V17.3834Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.08359 2C9.9393 2 10.633 2.69369 10.633 3.54941V8.91699H20.1508C21.0065 8.91699 21.7002 9.61068 21.7002 10.4664C21.7002 11.3221 21.0065 12.0158 20.1508 12.0158H9.08359C8.22787 12.0158 7.53418 11.3221 7.53418 10.4664V3.54941C7.53418 2.69369 8.22787 2 9.08359 2Z' fill='#272827' />
	</Svg>
)
