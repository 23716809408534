import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ChatOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M27.2499 3.24255H12.6977C11.0484 3.24255 9.78723 4.60076 9.78723 6.25002V9.35449H5.03349C3.38424 9.35449 2.12305 10.7127 2.12305 12.362V27.9814C2.12305 28.3694 2.22006 28.7575 2.89917 29.1455C3.0932 29.2426 3.19021 29.2426 3.38424 29.2426C3.67529 29.2426 3.86932 29.1455 4.16036 29.0485L8.42902 25.847H19.5857C21.041 25.847 22.3022 24.5858 22.3992 23.1306V19.8321H24.0484L28.026 22.9366C28.2201 23.1306 28.5111 23.2276 28.8022 23.2276C28.9962 23.2276 29.1902 23.1306 29.3842 23.0336C29.9663 22.6455 30.0633 22.2575 30.0633 21.9664V6.44405C30.0633 4.69778 28.8022 3.33957 27.2499 3.24255ZM12.3096 16.6306V6.25002C12.3096 5.95897 12.5036 5.76494 12.7947 5.76494H27.2499C27.4439 5.76494 27.735 5.95897 27.735 6.34703V19.25L25.3096 17.3097C25.1156 17.2127 24.9216 17.1157 24.6305 17.1157H12.6977C12.4066 17.1157 12.3096 16.9217 12.3096 16.6306ZM7.07081 23.4217C7.07081 23.4217 6.97379 23.5187 6.87678 23.5187L4.64543 25.362V12.2649C4.64543 11.9739 4.74245 11.7799 5.03349 11.7799H9.78723V16.6306C9.78723 18.2799 11.0484 19.6381 12.6977 19.6381H19.9738V22.7426C19.9738 23.0336 19.7798 23.2276 19.4887 23.2276H7.6529C7.36185 23.2276 7.16782 23.3246 7.07081 23.4217Z' fill='#272827' />
	</Svg>
)


