/**
 * A função `getUidAuth` retorna o UID do co-criador de um teaser, se disponível,
 * caso contrário, retorna o UID do teaser.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um objeto que contém informações sobre um teaser.
 * @returns A função `getUidAuth` está retornando o `uidCoCreator`
 * propriedade do objeto `teaser` clonado, se existir e for verdadeiro, ou o `uid`
 * propriedade do objeto `teaser` clonado se `uidCoCreator` for falso ou indefinido.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const getUidAuth = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.uidCoCreator || cloneTeaser.uid

}
