import { TypeTeaser } from '../../types/teaser'
import { Financing, FinancingItemProps } from '../../../../Constants/FINANCING'
import { clone } from '../../../Utils/clone'

export const getFinancing = ({ teaser }: { teaser: TypeTeaser }): FinancingItemProps[] => {

	// console.log('teaser.financing', teaser.financing)
	const cloneTeaser = clone(teaser)

	const financing = cloneTeaser.financing
	if (!financing) return []

	const res = Object.keys(financing).reduce((prev, cur) => {
		const item: FinancingItemProps[] = financing[cur as keyof typeof Financing] || []
		return item ? [...prev, ...item] : prev
	}, [] as FinancingItemProps[])

	return res
}
