import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { fnUser } from '../../../User'
import { TypeInstitution } from '../../../Institution/types/institution'
import { TypeMatch } from '../../../Match/types/match'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeTese } from '../../../Tese/types/tese'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.b0002

type Props = {
	origination: TypeTeaser,
	originationId: string,
	profile: TypeUser,
	uid: string,
	institution: TypeInstitution,
	institutionId: string,
	tese: TypeTese,
	teseId: string,
	matchId: string
	match: TypeMatch
}

export const sendMatchAccepted =
	async ({
		origination,
		originationId,
		profile,
		uid,
		institution,
		institutionId,
		tese,
		teseId,
		matchId,
		match,
	}: Props) => {

		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneProfile = clone(profile)
		const cloneUid = clone(uid)
		const cloneInstitution = clone(institution)
		const cloneInstitutionId = clone(institutionId)
		const cloneTese = clone(tese)
		const cloneTeseId = clone(teseId)
		const cloneMatchId = clone(matchId)
		const cloneMatch = clone(match)

		return await fnNotification.send({
			uiPermissions: ITEM.permissions,
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination }),
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile, uid: cloneUid }),
			user: fnUser.getUser({ user: cloneProfile || {} as TypeUser }),
			message: ITEM.msg,
			type: ITEM._id,
			tese: fnNotification.formatData.getTese({ tese: cloneTese || {} as TypeTese, id: cloneTeseId }),
			institution: fnNotification.formatData.getInstitution({ institution: cloneInstitution || {} as TypeInstitution, id: cloneInstitutionId }),
			showCustomer: true,
			analystEmail: fnOrigination.getAnalystEmail({ origination: cloneOrigination }),
			match: fnNotification.formatData.getMatch({ match: cloneMatch, id: cloneMatchId })
		} as TypeNotification)
	}

