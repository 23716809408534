export const DEVICE = {
	all: '@media all',
	xs: '@media (max-width: 991.98px)',
	// md: '@media (min-width: 576px) and (max-width: 991.98px)',
	kanban: '@media (min-width: 994px) and (max-width: 1200px)',
	lg: '@media (min-width: 992px)',
} as const


export const DEVICE_GET = (code: string) => {
	const _code = code as keyof typeof DEVICE

	if (!DEVICE[_code]) {
		return ({
			all: '@media all',
			xs: '@media (max-width: 991.98px)',
			kanban: '@media (min-width: 994px) and (max-width: 1200px)',
			lg: '@media (min-width: 992px)'
		})
	}
	return DEVICE[_code]
}
export const DEVICE_VALUES = Object.values(DEVICE)
export const DEVICE_KEYS = Object.keys(DEVICE)


