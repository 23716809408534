import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnMatch } from '../../../Match'
import { fnOrigination } from '../../../Origination'
import { fnUser } from '../../../User'
import { TypeInstitution } from '../../../Institution/types/institution'
import { TypeMatch } from '../../../Match/types/match'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeTese } from '../../../Tese/types/tese'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'

const ITEM = TYPE_NOTIFICATIONS.b0017

type Props = {
	origination: TypeTeaser
	originationId: string
	profile: TypeUser
	uid: string
	match?: TypeMatch
	matchId?: string
	tese?: TypeTese
	teseId?: string
	institution?: TypeInstitution
	institutionId?: string
}

export const sendServiçoRetomado =
	async ({
		origination,
		originationId,
		profile,
		uid
	}: Props) =>
		await fnNotification.send({
			uiPermissions: ITEM.permissions,
			//tese: fnNotification.formatData.getTese({ tese: tese || {} as TypeTese, id: teseId || '' }),
			uids: fnOrigination.getUidsNotification({ origination }), //cliente diff de agente
			origination: fnNotification.formatData.getOrigination({ origination, id: originationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile, uid }),
			user: fnUser.getUser({ user: profile || {} as TypeUser }), //cliente diff de agente
			message: ITEM.msg,
			type: ITEM._id,
			//match: fnNotification.formatData.getMatch({ match: match || {} as TypeMatch, id: matchId || '' }),
			//institution: fnNotification.formatData.getInstitution({ institution: institution || {} as TypeInstitution, id: institutionId || '' }),
			showCustomer: true,
			analystEmail: fnOrigination.getAnalystEmail({ origination }),
			//teses: fnNotification.formatData.getTeses({ match })
		} as TypeNotification)
