import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Computer = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M5.8917 6.57143C5.24969 6.57143 4.72924 7.06178 4.72924 7.66667V19.5714C4.72924 20.1763 5.24969 20.6667 5.8917 20.6667H26.1083C26.7503 20.6667 27.2708 20.1763 27.2708 19.5714V7.66667C27.2708 7.06178 26.7503 6.57143 26.1083 6.57143H5.8917ZM2 7.66667C2 5.64162 3.74237 4 5.8917 4H26.1083C28.2576 4 30 5.64162 30 7.66667V19.5714C30 21.5965 28.2576 23.2381 26.1083 23.2381H5.8917C3.74237 23.2381 2 21.5965 2 19.5714V7.66667Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.58105 26.7144C9.58105 26.0043 10.192 25.4287 10.9457 25.4287H21.054C21.8076 25.4287 22.4186 26.0043 22.4186 26.7144C22.4186 27.4245 21.8076 28.0001 21.054 28.0001H10.9457C10.192 28.0001 9.58105 27.4245 9.58105 26.7144Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.0004 20.6665C16.754 20.6665 17.365 21.2421 17.365 21.9522V26.7141C17.365 27.4242 16.754 27.9998 16.0004 27.9998C15.2467 27.9998 14.6357 27.4242 14.6357 26.7141V21.9522C14.6357 21.2421 15.2467 20.6665 16.0004 20.6665Z' fill='#272827' />
	</Svg>
)
