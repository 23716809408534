import { clone } from '../../Functions/Utils/clone'
import { deleteFile } from '../serviceFiles'

function getPath(path: string): string {
	let lastChar = path.substring(path.length - 1)
	lastChar = lastChar === '/' ? '' : '/'
	return `${path}${lastChar}`
}
/**
 * Remove o arquivo de um teaser anexado
 * @param path o caminho do arquivo
 * @param key a chave do arquivo a ser removido no storage
 * @returns
 */
export const deteleAttachedFileTeaser = ({ path, key }: { path: string, key: string }) => {

	const clonePath = clone(path)
	const cloneKey = clone(key)

	return deleteFile(`${getPath(clonePath)}${cloneKey}`, async () => { })

}

