import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Down = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M2 9.911C2 9.46135 2.15074 9.0117 2.60296 8.56205C3.35666 7.81264 4.56259 7.81264 5.31629 8.56205L16.0188 19.2037L26.7214 8.71194C27.4751 7.96253 28.681 7.96253 29.4347 8.71194C30.1884 9.46136 30.1884 10.6604 29.4347 11.4098L17.3755 23.4005C17.074 23.7002 16.4711 24 16.0188 24C15.5666 24 15.1144 23.8501 14.6622 23.4005L2.60296 11.4098C2.15074 10.9602 2 10.5105 2 9.911Z' fill='#272827' />
	</Svg>
)
