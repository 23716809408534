import { ITeaserFile, TypeTeaser } from '../../Teaser/types/teaser'
import { db5 } from '../../../Services/db5'

export const addFile = async (args: {
	id: string
	key: string
	file: ITeaserFile,
	teaser: TypeTeaser
}) => {
	const { id, file, key, teaser } = args
	const files = { ...teaser.files, [key]: file }
	return await db5.teasers.update({
		id: id, doc: {
			files
		}
	})
}
