import { startModalLoading } from 'Components/Modal'
import firebase from 'firebase/app'
import 'firebase/firestore'

const storageService = firebase.storage()
const storageRef = storageService.ref()

export const deleteFile = (pathFile: string, callBack: () => void) => {
	storageRef.child(pathFile).delete().then(function () {
		callBack()
	}).catch(() => {
		// Uh-oh, an error occurred!
	})
}

export const uploadFile = (path: string, file: any, success: (url: string) => void) => {
	storageRef.child(path).put(file).on('state_changed', snapshot => { },
		error => { },
		() => {
			storageService.ref(path).getDownloadURL().then(url => {
				success(url)
			})
		})
}

export const sendFile = ({ pathFile, file }: { pathFile: string, file: any }, callBack: (url: string) => void) => {
	storageRef.child(pathFile).put(file).on('state_changed', () => { },
		() => { },
		() => {
			storageService.ref(pathFile).getDownloadURL().then(url => {
				callBack(url)
			})
		}
	)
}
// TODO: DEVE SER REMOVIDO, SERVIÇO DE FILES NÃO DEVE ESCREVER NO BANCOS DE DADOS
const fStoreUpdate = (caminho: string, obj: any, call: () => void) => (
	firebase.firestore().doc(caminho).set(obj, { merge: true }).then(call)
)

export const serviceSaveFile = ({ pathFile, file, urlSaveIn, keyUrl }: { pathFile: string, file: any, urlSaveIn: string, keyUrl: string }, callBack: (url: string) => void) => {

	const result = {
		url: ''
	}

	startModalLoading((next: () => void) => {
		sendFile(
			{
				pathFile: pathFile,
				file: file
			}
			,
			url => {
				fStoreUpdate(urlSaveIn, { [keyUrl]: url }, () => {
					result.url = url
					if (callBack) {
						callBack(url)
					}
					next()
				})
			}
		)
	})
	return result
}
