import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const WorldFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M16 6C10.48 6 6 10.48 6 16C6 21.52 10.48 26 16 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 16 6ZM15 23.93C11.05 23.44 8 20.08 8 16C8 15.38 8.08 14.79 8.21 14.21L13 19V20C13 21.1 13.9 22 15 22V23.93ZM21.9 21.39C21.64 20.58 20.9 20 20 20H19V17C19 16.45 18.55 16 18 16H12V14H14C14.55 14 15 13.55 15 13V11H17C18.1 11 19 10.1 19 9V8.59C21.93 9.78 24 12.65 24 16C24 18.08 23.2 19.97 21.9 21.39Z' fill='#272827' />
	</Svg>
)
