import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BookOutlined2 = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M9.65425 22.9416C9.26832 22.9416 8.89818 23.0887 8.62528 23.3504C8.35238 23.6122 8.19907 23.9672 8.19907 24.3374C8.19907 25.1014 7.55333 25.7208 6.75676 25.7208C5.9602 25.7208 5.31445 25.1014 5.31445 24.3374C5.31445 23.2334 5.77168 22.1746 6.58555 21.394C7.39942 20.6134 8.50327 20.1748 9.65425 20.1748H25.3007C26.0973 20.1748 26.743 20.7942 26.743 21.5582C26.743 22.3222 26.0973 22.9416 25.3007 22.9416H9.65425Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.65425 6.2668C9.26831 6.2668 8.89818 6.41385 8.62528 6.6756C8.35238 6.93736 8.19907 7.29237 8.19907 7.66255V24.3375C8.19907 24.7076 8.35238 25.0626 8.62528 25.3244C8.89818 25.5861 9.26832 25.7332 9.65425 25.7332H23.8584V6.2668H9.65425ZM9.65425 3.5H25.3007C26.0973 3.5 26.743 4.11937 26.743 4.8834V27.1166C26.743 27.8806 26.0973 28.5 25.3007 28.5H9.65425C8.50327 28.5 7.39942 28.0614 6.58555 27.2808C5.77168 26.5002 5.31445 25.4414 5.31445 24.3375V7.66255C5.31445 6.55857 5.77168 5.49981 6.58555 4.71918C7.39942 3.93855 8.50327 3.5 9.65425 3.5Z' fill='#272827' />
	</Svg>
)


