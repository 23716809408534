const _VARIANT_USER = {
	none: '',
	enterprise: 'enterprise',
	operation: 'operation',
	channel: 'channel',
	ir: 'ir',
	adm: 'adm'
} as const

export const _VARIANT_USER_GET = (code: string) => {
	const _code = code as keyof typeof _VARIANT_USER

	if (!_VARIANT_USER[_code]) {
		return (
			{
				none: '',
				enterprise: 'enterprise',
				operation: 'operation',
				channel: 'channel',
				ir: 'ir',
				adm: 'adm'
			}
		)
	}

	return _VARIANT_USER[_code]
}

export type VARIANT_USER_KEYOF_TYPEOF = keyof typeof _VARIANT_USER

export const VARIANT_USER = {
	VALUES: Object.values(_VARIANT_USER),
	KEYS: Object.keys(_VARIANT_USER),
	GET: (code: string) => _VARIANT_USER[code as keyof typeof _VARIANT_USER] || _VARIANT_USER['none']
}
