import { clone } from '../../../Functions/Utils/clone'
import { TypeInstitution } from '../../Institution/types/institution'
import { TypeNotifications } from '../types/notification'

export const unReadNotificationsPerInstitution = ({
	notifications,
	institution,
}: {
	notifications: TypeNotifications;
	institution: TypeInstitution;
}): TypeNotifications => {

	const cloneNotifications = clone(notifications)
	const cloneInstitution = clone(institution)

	const arrayNotifications = Object.entries(cloneNotifications).filter(
		([id, notification]) => notification.institution?.id === cloneInstitution._id
	)

	return Object.fromEntries(arrayNotifications)
}
