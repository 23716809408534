import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const HomeOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path d='M27.7957 11.4719L17.2788 3.29213C16.7595 2.90262 16.1103 2.90262 15.7208 3.29213L5.20392 11.4719C4.81441 11.7316 4.68457 12.1211 4.68457 12.5106V25.3645C4.68457 27.3121 6.37246 29 8.32003 29H24.6796C26.6271 29 28.315 27.3121 28.315 25.3645V12.5106C28.315 12.1211 28.1852 11.7316 27.7957 11.4719ZM18.707 26.4032H14.2926V17.3146H18.707V26.4032ZM25.7183 25.3645C25.7183 25.8839 25.1989 26.4032 24.6796 26.4032H21.3038V16.0162C21.3038 15.2372 20.7844 14.7179 20.0054 14.7179H12.9942C12.2152 14.7179 11.6958 15.2372 11.6958 16.0162V26.4032H8.32003C7.80068 26.4032 7.28132 25.8839 7.28132 25.3645V13.1598L16.4998 6.01873L25.7183 13.1598V25.3645Z' fill='#272827' />
	</Svg>
)


