import { REASON_CONTRACT_DONE } from '../../../Constants/REASON_CONTRACT_DONE'
import { helpersDate } from '../../Utils/helpersDate'
import { isProductionEnv } from '../../../App/EnvFlag'
import { COMPANY_RATING } from '../../../Constants/COMPANY_RATING'
import { CONTRACTS } from '../../../Constants/CONTRACTS'
import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { minhaData } from '../../Utils/minhaDataNew'
import { TypeTeaser } from '../types/teaser'
import { clone } from '../../Utils/clone'

export const modelTeaser = (obj: any = {}): TypeTeaser => {

	const cloneObj = clone(obj)

	return ({
		_id: cloneObj._id || '',
		ativado: cloneObj.ativado || false,
		curado: cloneObj.curado || false,
		fromOffice: cloneObj.fromOffice || (isProductionEnv() ? 'ez22SO3AU9WiDqCLsLKBqHj5lu33' : 'R4jh6ghEKJawVdOWHbeOFwEvEbE2'), // atribui o teaser ao office stark, dependendo do ambiente

		/** @deprecated busque na propriedade contract */
		tipoContrato: cloneObj.tipoContrato || 'none', // !depreciado
		/** @deprecated busque na propriedade contract */
		valorContrato: cloneObj.valorContrato || 0, // !depreciado

		urlClickup: cloneObj.urlClickup || '',
		urlDrive: cloneObj.urlDrive || '',

		analystEmail: cloneObj.analystEmail || '',
		linkCall: cloneObj.linkCall || '',

		validCompany: cloneObj.validCompany || false,

		origin: cloneObj.origin || '',
		uid: cloneObj.uid || '',
		status: cloneObj.status || '', // deprecated
		subStatusOfActiveCostumer: cloneObj.subStatusOfActiveCostumer || SUB_STATUS_OF_ACTIVE_COSTUMER.s00050.code,
		subStatusOfActiveCustomerDate: cloneObj?.subStatusOfActiveCustomerDate || null,
		uidCoCreator: cloneObj.uidCoCreator || '',

		dataStandby: cloneObj.dataStandby || 0,
		dataContrato: cloneObj.dataContrato || 0,
		dateContractDone: cloneObj.dateContractDone || 0,
		dateContractEnd: cloneObj.dateContractEnd || 0,
		dataPublicacao: cloneObj.dataPublicacao || 0,
		dataCriacao: cloneObj.dataCriacao || minhaData(),
		dataModificacao: cloneObj.dataModificacao || 0,
		dataComercial: cloneObj.dataComercial || 0,
		dataCuradoria: cloneObj.dataCuradoria || 0,
		dataAjustes: cloneObj.dataAjustes || [],
		statusCustomer: cloneObj.statusCustomer || '',
		dateRequestedChanges: cloneObj.dateRequestedChanges || 0,
		customerEmail: cloneObj.customerEmail || '',

		/** @deprecated */
		institutionHub: cloneObj.institutionHub || '',

		overview: cloneObj.overview || '',

		customerName: cloneObj.customerName || '',
		nome: cloneObj.nome || '',
		site: cloneObj.site || '',
		cnpj: cloneObj.cnpj || '',
		cpf: cloneObj.cpf || '',

		codinome: cloneObj.codinome || '',
		titulo: cloneObj.titulo || '',
		grau: cloneObj.grau || '',
		relacionamento: cloneObj.relacionamento || '',

		validation: {
			dateMeet: cloneObj?.validation?.dateMeet ? helpersDate.convertAllToTimestamp(cloneObj?.validation?.dateMeet) : null,
			promotedBy: cloneObj?.validation?.promotedBy || '',
			directedBy: cloneObj?.validation?.directedBy || '',
			customerName: cloneObj?.validation?.customerName || '',
			customerEmail: cloneObj?.validation?.customerEmail || '',
			customerRole: cloneObj?.validation?.customerRole || '',
			customerPhone: cloneObj?.validation?.customerPhone || '',
			observations: cloneObj?.validation?.observations || '',
			approved: cloneObj?.validation?.approved || false,
		},

		onLine: cloneObj.onLine ?? true,

		setor: cloneObj.setor || '',
		subsetor: cloneObj.subsetor || '',
		outrosubsetor: cloneObj.outrosubsetor || '',

		sdg: cloneObj.sdg ?? '',

		files: cloneObj.files || {},
		urlLogo: cloneObj.urlLogo || '',

		regiao: cloneObj.regiao || {
			Norte: false,
			Nordeste: false,
			'Centro-Oeste': false,
			Sudeste: false,
			Sul: false,
			'Atuação Internacional': false,
		},
		sede: cloneObj.sede || '',
		paises: cloneObj.paises || '',

		dpp: cloneObj.dpp || '', //Descrição Principais Produtos
		cdr: cloneObj.cdr ?? '', //Existe um cliente que responda por mais de 30% do faturamento da empresa
		cdrlct: cloneObj.cdrlct ?? '', //Existe um cliente que responda por mais de 30% do faturamento da empresa
		prr: cloneObj.prr || '', //Possui receita recorrente

		transactionValue: cloneObj.transactionValue || 0,
		rbOutside: cloneObj.rbOutside || 0,
		valuationExpectation: cloneObj.valuationExpectation || 0,
		valuationExpectationMax: cloneObj.valuationExpectationMax || 0,
		valuationExpectationUndefined: cloneObj.valuationExpectationUndefined ?? false,

		rb0: cloneObj.rb0 || '', //receita esperado par ano atual
		rb1: cloneObj.rb1 || '', //Receita Bruta ano -1
		rb2: cloneObj.rb2 || '', //Receita Bruta ano -2
		rb3: cloneObj.rb3 || '', //Receita Bruta ano -3
		rb4: cloneObj.rb4 || '', //Receita Bruta ano -4

		rl0: cloneObj.rl0 || '', //receita esperado par ano atual
		rl1: cloneObj.rl1 || '', //Receita Líquida ano -1
		rl2: cloneObj.rl2 || '', //Receita Líquida ano -2
		rl3: cloneObj.rl3 || '', //Receita Líquida ano -3
		rl4: cloneObj.rl4 || '', //Receita Líquida ano -4

		eb0: cloneObj.eb0 || '', //ebitda esperado par ano atual
		eb1: cloneObj.eb1 || '', //EBITDA ano -1
		eb2: cloneObj.eb2 || '', //EBITDA ano -2
		eb3: cloneObj.eb3 || '', //EBITDA ano -3
		eb4: cloneObj.eb4 || '', //EBITDA ano -4

		emprestimos: cloneObj.emprestimos || '',
		tributos: cloneObj.tributos || '',
		fornecedores: cloneObj.fornecedores || '',
		outrasdividas: cloneObj.outrasdividas || '',
		origemdivida: cloneObj.origemdivida || '',

		maanterior: cloneObj.maanterior || '',
		etr: cloneObj.etr || {
			'Aquisição Total': false,
			'Aquisição Majoritária': false,
			'Aquisição Minoritária': false,
		}, //Estrutura da transação
		meaMotivoCaptacao: cloneObj.meaMotivoCaptacao || '',

		motivo: cloneObj.motivo || '',

		valor: cloneObj.valor || '', //

		destaques: cloneObj.destaques || {
			destaque1: { icone: '', texto: '' },
			destaque2: { icone: '', texto: '' },
			destaque3: { icone: '', texto: '' },
			destaque4: { icone: '', texto: '' },
		},

		preparacao: cloneObj.preparacao || {
			diagnostico: 0,
			dataroom: 0,
			dfs: 0,
			valuation: 0
		},


		contract: {
			type: cloneObj?.contract?.type || CONTRACTS.GET('none')._id, // Tipo do contrato
			fidelityPayment: cloneObj?.contract?.fidelityPayment || 0, // Valor de assinatura mensal (fidelidade)
			monthlyPayment: cloneObj?.contract?.monthlyPayment || 0, // Valor de assinatura mensal (mensalidade)
			successFee: cloneObj?.contract?.successFee || 0, // Success Fee (%)
			dueDateTail: cloneObj?.contract?.dueDateTail || 0, // Prazo de tail
			dueDateFidelity: cloneObj?.contract?.dueDateFidelity || 0, // Prazo fidelidade
			dueDateSignature: cloneObj?.contract?.dueDateSignature || 0, // Prazo de validade do contrato

			dueContract: cloneObj?.contract?.dueContract || 0,
			dueFidelity: cloneObj?.contract?.dueFidelity || 0,
			dueTail: cloneObj?.contract?.dueTail || 0,

			file: {
				name: cloneObj?.contract?.file?.name || '',
				url: cloneObj?.contract?.file?.url || '',
				key: cloneObj?.contract?.file?.key || '',
			}
		},

		snapshot: cloneObj.snapshot || [],

		linkContrato: cloneObj.linkContrato || '',
		cnae: cloneObj.cnae || [],
		cnaes: cloneObj.cnaes || [],
		cnaeNegative: cloneObj.cnaeNegative || [],
		// linkFinancialAsIs: obj.linkFinancialAsIs || '',
		// linkDf: obj.linkDf || '',
		// linkValuation: obj.linkValuation || '',

		users: cloneObj.users || [],

		deliverables: cloneObj.deliverables || [
			{ label: 'Modelo financeiro básico', value: '', description: 'Suas finanças atualizadas', color: '#295295', icon: 'attach_money', dateCreate: '', status: '' },
			{ label: 'Modelo financeiro completo', value: '', description: 'Suas demonstrações financeiras', color: '#295295', icon: 'payments', dateCreate: '', status: '' },
			{ label: 'Valuation', value: '', description: 'Saiba o valor da sua empresa', color: '#295295', icon: 'bookmarks', dateCreate: '', status: '' },
		],

		tags: cloneObj.tags || [],
		tagsNegative: cloneObj.tagsNegative || [],

		tagTechActive: cloneObj.tagTechActive || false,
		tagTech: cloneObj.tagTech || [],
		tagsTarget: cloneObj.tagsTarget || [],
		// tagsInvestmentStage: obj.tagsInvestmentStage || [],
		tagsBusinessModel: cloneObj.tagsBusinessModel || [],

		empresarioNome: cloneObj.empresarioNome || '',
		empresarioEmail: cloneObj.empresarioEmail || '',
		empresarioNascimento: cloneObj.empresarioNascimento || '',
		empresarioTelefone: cloneObj.empresarioTelefone || '',
		empresarioLinkedin: cloneObj.empresarioLinkedin || '',
		empresarioEstado: cloneObj.empresarioEstado || '',
		empresarioCidade: cloneObj.empresarioCidade || '',

		templateEmail: cloneObj.templateEmail || '',

		// Add origination
		typeOperation: cloneObj.typeOperation ?? (cloneObj.dataPublicacao ? 'M&A' : null),
		// tipo de operação, ex.: M&A, Crédito ou os dois
		/** @description valor desejado na operação;
		 *
		 * label: Valor desejado da operação
		 *
		 * #addOrigination
		*/
		desiredValue: cloneObj.desiredValue ?? '',

		//Financiamento de obras
		operations: cloneObj.operations ?? [],

		isProjectFinancing: cloneObj.isProjectFinancing ?? false,
		projectBudget: cloneObj.projectBudget ?? '',
		companyRating: cloneObj.companyRating ?? COMPANY_RATING.GET('Real')._id,
		guarantees: cloneObj.guarantees ?? null,
		// guarantees: {
		// 'Imobiliária' : ItemGuranteeProps[]
		// 'Recebíveis': ItemGuranteeProps[]
		// 'Recebíveis Imobiliários': ItemGuranteeProps[]
		// 'Fiança de Safra' :ItemGuranteeProps[]
		// 'Financiamento para término de obra' : ItemGuranteeProps[]
		// 'outros' : ItemGuranteeProps[]
		// }
		patrimonios: cloneObj.patrimonios ?? [],
		noGuarantees: cloneObj.noGuarantees ?? false,
		description: cloneObj.description ?? '',
		indebtedness: cloneObj.indebtedness ?? true,
		additionalInformation: cloneObj.additionalInformation ?? '',
		tagNotFound: cloneObj.tagNotFound ?? false,
		hasSite: cloneObj.hasSite ?? (cloneObj.site ? true : false),

		financing: cloneObj.financing ?? null,
		hasCreditoOperacoesEstruturadas: cloneObj.hasCreditoOperacoesEstruturadas ?? false,
		credopsMotivoCaptacao: cloneObj.credopsMotivoCaptacao ?? '',
		credopsReperfilamento: cloneObj.credopsReperfilamento ?? false,
		credopsValorReperfilamento: cloneObj.credopsValorReperfilamento ?? 0,

		hasFinanciamentoObras: cloneObj.hasFinanciamentoObras ?? false,
		hasFinanciamentoObrasDoZero: cloneObj.hasFinanciamentoObrasDoZero ?? false,
		reasonDeal: cloneObj.reasonDeal ?? '',
		judicialRecovery: cloneObj.judicialRecovery ?? false,
		fantasyName: cloneObj.fantasyName ?? '',

		isFollowup: cloneObj.isFollowup ?? false,
		lastUpdateFollowup: cloneObj.lastUpdateFollowup ?? '',
		numberContactsFollowup: cloneObj.numberContactsFollowup ?? 0,
		publicationHistory: cloneObj.publicationHistory ?? [],
		attachedTeasers: cloneObj.attachedTeasers ?? [],
		lastUpdateCuration: cloneObj.lastUpdateCuration || null,


		firstStepDaysOfExclusivity: cloneObj.firstStepDaysOfExclusivity ?? 30,
		secondStepDaysOfExclusivity: cloneObj.secondStepDaysOfExclusivity ?? 180,
		// Originações anteriores a data 27/07/2023 possuiam 365 dias de exclusividade
		// A partir dessa data foi alterado para 180 dias
		// thirdStepDaysOfExclusivity: Number(String(obj.dataCriacao)?.slice(0, 8)) < 20230726 ? 365 : obj.thirdStepDaysOfExclusivity || 365,
		thirdStepDaysOfExclusivity: cloneObj.thirdStepDaysOfExclusivity ?? 365,
		fourthStepDaysOfExclusivity: cloneObj.fourthStepDaysOfExclusivity ?? 365,

		// undefined string ou boolean
		governanceAuditedBalance: (typeof cloneObj.governanceAuditedBalance === 'string') ? cloneObj.governanceAuditedBalance : '',
		governanceRevenueOutside: (typeof cloneObj.governanceRevenueOutside === 'string') ? cloneObj.governanceRevenueOutside : '',

		commissionPerOrigination: cloneObj.commissionPerOrigination ?? 0,

		reasonContractDone: cloneObj.reasonContractDone || REASON_CONTRACT_DONE.i000._id,
		detailsContractDone: cloneObj.detailsContractDone || '',
		contractDoneHistory: cloneObj.contractDoneHistory || [],
		_update: cloneObj._update || new Date(),

		uf: obj.uf || '',
		responsibleAnalyst: obj.responsibleAnalyst || {},

		devolutive: {
			date: cloneObj?.devolutive?.date || 0,
			comments: cloneObj?.devolutive?.comments || '',
		},

		comercialChurn: {
			date: cloneObj?.comercialChurn?.date || 0,
			reason: cloneObj?.comercialChurn?.reason || '',
			details: cloneObj?.comercialChurn?.details || '',
		},

		desiredValueAdjusted: {
			date: cloneObj?.desiredValueAdjusted?.date || 0,
			value: cloneObj?.desiredValueAdjusted?.value || '',
		},
	})
}
