import { TypeTeaser } from '../../types/teaser'
import { fnTeaser } from '../..'
import { clone } from '../../../Utils/clone'

export const isMinoritárioTech = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	if (fnTeaser.isTech(cloneTeaser) && cloneTeaser.etr['Aquisição Minoritária']) {
		return true
	}
	return false
}
