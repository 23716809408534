import { fnUser } from 'Functions/User'
import { useEffect } from 'react'

import { useContextAuth } from 'App/ContextAuth'

const ScriptBda = () => {
	const { globalAuth } = useContextAuth()

	useEffect(() => {
		// oculta iframes indesejados que aparecem na tela pela renderização do nodejs
		function removeIframe() {
			let i, frames
			frames = document.getElementsByTagName('iframe')
			for (i = 0; i < frames.length; ++i) {
				if (!frames[i].getAttribute('src') && frames[i].style.display !== 'none') {
					frames[i].style.display = 'none'
					frames[i].style.pointerEvents = 'none'
					frames[i].style.width = '1px'
					frames[i].style.height = '1px'
				}
			}
		}
		setInterval(removeIframe, 1000)
	}, [])

	return (
		<>
			{fnUser.isBda(globalAuth.profile) ? (
				<script id='Cookiebot' src='https://consent.cookiebot.com/uc.js' data-cbid='8d665954-0ace-4181-aa72-056bd1b59275' data-blockingmode='auto' type='text/javascript'></script>
			) : (
				<> </>
			)}
		</>
	)
}

export default ScriptBda
