import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { escreveData } from '../../../Functions/Utils/minhaData'
import { TypeUser } from '../../../Functions/User/types/TypeUser'
import { firstDateInPlatform } from './firstDateInPlataform'

export const firstMatch = ({ user }: { user: TypeUser }) => {
	const firstMatch = user.dateFirstMatch || ''
	const _firstDateInPlatform = firstDateInPlatform(user)
	return ({
		date:
		{
			value: firstMatch ? helpersDate.convertAllToTimestamp(firstMatch) : null,
			formatted: firstMatch ? escreveData(String(firstMatch)) : null
		},
		days: firstMatch ? helpersDate.howManyDays(helpersDate.convertAllToTimestamp(_firstDateInPlatform), helpersDate.convertAllToTimestamp(firstMatch)) : null,
		label: ''
	})
}
