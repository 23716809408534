import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { UidsNotification } from '../../../Origination/methods/getUidsNotification'
import { fnUser } from '../../../User'
import { TypeNotification } from '../../types/notification'
import { TypeTese } from '../../../Tese/types/tese'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'
import { Props } from '../sendInativeToMatch'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.a00010

export const sendInativeToMatch =
	async ({
		origination,
		originationId,
		profile,
		uid,
		match,
		matchId,
		institution,
		institutionId
	}: Props) => {

		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneProfile = clone(profile)
		const cloneUid = clone(uid)
		const cloneMatch = clone(match)
		const cloneMatchId = clone(matchId)
		const cloneInstitution = clone(institution)
		const cloneInstitutionId = clone(institutionId)


		return await fnNotification.send({
			uiPermissions: ITEM.permissions,
			tese: fnNotification.formatData.getTese({ tese: {} as TypeTese, id: cloneMatch.idTese || '' }),
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination, type: UidsNotification.a01 }),
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile, uid: cloneUid }),
			user: fnUser.getUser({ user: cloneProfile || {} as TypeUser }),
			message: ITEM.msg,
			type: ITEM._id,
			match: fnNotification.formatData.getMatch({ match: cloneMatch, id: cloneMatchId }),
			institution: fnNotification.formatData.getInstitution({ institution: cloneInstitution, id: cloneInstitutionId }),
			analystEmail: fnOrigination.getAnalystEmail({ origination: cloneOrigination }),
			teses: fnNotification.formatData.getTeses({ match: cloneMatch })
		} as TypeNotification)

	}

