import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'

export const removeTag =
	(
		{ tag, valuation }:
			{ tag: string, valuation: TypeValuation }) => {
		const copyOfValuation = { ...valuation }
		copyOfValuation.tags = [...valuation.tags]
		copyOfValuation.tags = copyOfValuation.tags.filter(_tag => _tag !== tag)
		return copyOfValuation.tags
	}
