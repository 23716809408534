import { FOLLOWUP_STATUS } from '../../../Constants/FOLLOWUP_STATUS'
import { TypeFollowup } from '../types/TypeFollowup'
import { modelFollowupMessage } from '../methods/modelFollowupMessage'
import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { minhaData } from '../../Utils/minhaData'

export const modelFollowup = (obj = {} as Partial<TypeFollowup>): TypeFollowup => ({
	_id: obj?._id || '',
	_update: obj?._update || minhaData(),

	createdAt: obj?.createdAt || helpersDate.convertAllToTimestamp(new Date()),
	updatedAt: obj?.updatedAt || helpersDate.convertAllToTimestamp(new Date()),

	declinedAt: obj?.declinedAt || null,
	inContactAt: obj?.inContactAt || null,

	toContacts: obj?.toContacts || [],

	createdBy: obj?.createdBy || {
		_id: '',
		name: '',
		email: '',
		role: '',
	},

	// status do followup
	// inicia com o status em andamento
	status: obj?.status || FOLLOWUP_STATUS.f100._id,

	institution: obj?.institution || {
		_id: '',
		name: '',
		urlLogo: '',
		profile: ''
	},

	tesesIds: obj?.tesesIds || [],

	match: obj?.match || {
		_id: ''
	},

	teaser: obj?.teaser || {
		_id: '',
		name: '',
		urlLogo: '',
	},

	typeOperation: obj?.typeOperation || [],

	messages: obj?.messages || [
		modelFollowupMessage()
	],

	fromOffice: obj?.fromOffice || ''
})

