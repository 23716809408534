import { TypeParameterDate } from '.'
import { Timestamp, _Timestamp } from '../../Time/types/Timestamp'
import { clone } from '../clone'

export const convertAllToDate = (
	date: TypeParameterDate): Date | null => {

	if (date === 0) return null

	if (date === undefined) return null

	if (date === null) return null

	if (typeof date === 'string' && date.toString().length === 10) {
		const _date = date.replaceAll('-', '/')
		return new Date(_date)
	}

	if (date?.toString().length === 8) {
		const y = (date.toString()).substring(0, 4),
			m = Number((date.toString()).substring(4, 6)) - 1,
			d = (date.toString()).substring(6, 8)
		const D = new Date(Number(y), m, Number(d))
		return D
	}

	if (date?.toString().length === 14) {
		const y = (date.toString()).substring(0, 4)
		const m = Number((date.toString()).substring(4, 6)) - 1
		const d = (date.toString()).substring(6, 8)
		const min = (date.toString()).substring(8, 10)
		const sec = (date.toString()).substring(10, 12)
		const ms = (date.toString()).substring(12, 14)
		const D = new Date(Number(y), m, Number(d), Number(min), Number(sec), Number(ms))
		return new Date(D)
	}

	if (typeof date === 'object' && Object.prototype.toString.call(date) !== '[object Date]') {
		const _data = date as Timestamp
		const _data2 = date as any
		let dateReturn
		if (_data.seconds) {
			dateReturn = new Date(_data.seconds * 1000 + _data.nanoseconds / 1000000)
		}
		else if (_data2.seconds) {
			dateReturn = new Date(_data2.seconds * 1000 + _data2.nanoseconds / 1000000)
		}
		else if (_data2._seconds) {
			dateReturn = new Date(_data2._seconds * 1000 + _data2._nanoseconds / 1000000)
		}
		if (dateReturn) {
			return dateReturn
		}
		else {
			return null
		}
	}

	if (Object.prototype.toString.call(date) === '[object Date]') {
		const _data = date as Date
		return _data
	}

	return null
}

export const convertAllToNumber = (
	dateNumber: TypeParameterDate): number => {

	const cloneDateNumber = clone(dateNumber)

	const newDate = convertAllToDate(cloneDateNumber)

	if (newDate === null) return 0

	return dateToNumber(newDate)

}

const dateToNumber = (newDate?: Date): number => {

	const cloneNewDate = clone(newDate)

	const now = cloneNewDate || new Date()
	let minhaData

	minhaData = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`
	minhaData = Number(minhaData)
	return minhaData
}
