import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeNotification } from '../types/notification'

export const send = async (props: TypeNotification) => {

	const cloneProps = clone(props)

	return await db5.notifications.add({
		doc: {
			...cloneProps,
			unReadBy: Object.fromEntries(cloneProps.uids.map(uid => [uid, true])),
			createdAt: new Date()
		}
	})
}
