import { TypeWalletLine } from '../../../Functions/WalletLine/types/walletLine'

export const modelWalletLine = (obj: Partial<TypeWalletLine> = {}): TypeWalletLine => ({
	_id: obj._id || '',

	createdByUid: obj?.createdByUid || '',

	history: obj?.history || {},

	payDay: obj?.payDay || 0,

	motivation: obj?.motivation || '',

	customerName: obj?.customerName || '',

	customerId: obj?.customerId || '',

	customerUrlImg: obj?.customerUrlImg || '',

	uid: obj?.uid || '',

	installment: obj?.installment || 0,

	installmentsTotal: obj?.installmentsTotal || 0,

	updated_at: obj?.updated_at || 0,

	created_at: obj?.created_at || 0,

	value: obj?.value || 0

})
