import { VARIANTS_SIZES_TW } from 'Constants/VARIANTS_SIZES_TW'
import classNames from 'Functions/Utils/classNames'
import { mergeClassName } from 'Functions/Utils/mergeClassName'
import React from 'react'

export interface IButton extends React.ComponentProps<'button'> {
	variant?: 'primary' | 'secondary' | 'tertiary' | 'alternative' | 'textButton' | 'menuButton' | 'quaternary' | 'black' | 'white' | 'grey' | 'outlined';
	loading?: boolean;
	disabled?: boolean;
	size?: 'small' | 'medium' | 'large'
}

const variantsSize = {
	'small': `tw-rounded tw-px-3 ${VARIANTS_SIZES_TW.small}`,
	'medium': `tw-rounded tw-px-3.5 ${VARIANTS_SIZES_TW.medium}`,
	'large': `tw-rounded tw-px-4 ${VARIANTS_SIZES_TW.large}`
}

export const variantsButton = {
	'primary': 'tw-bg-blue-500 hover:tw-bg-blue-700 active:tw-bg-blue-400 tw-text-white tw-cursor-pointer',
	'secondary': 'tw-bg-blue-100 hover:tw-bg-blue-300 active:tw-bg-blue-300 hover:tw-text-white tw-text-blue-500 tw-cursor-pointer',
	'tertiary': 'tw-border-solid tw-border tw-border-neutral-200 tw-bg-white hover:tw-bg-blue-100 active:tw-bg-blue-100 active:tw-border-blue-200 tw-text-gray-500 tw-cursor-pointer',
	'alternative': 'tw-bg-orange-400 hover:tw-bg-orange-500 active:tw-bg-orange-400 tw-text-white tw-cursor-pointer',
	'textButton': 'tw-bg-transparent tw-text-blue-500 hover:tw-text-blue-900 active:tw-text-blue-500 tw-cursor-pointer',
	'menuButton': 'tw-bg-transparent tw-text-neutral-400 hover:tw-text-neutral-500 hover:tw-bg-blue-000 active:tw-text-blue-500 tw-cursor-pointer',
	'disabled': 'tw-border-none tw-bg-neutral-300 tw-text-neutral-100 tw-cursor-default',
	'quaternary': 'tw-bg-white/50 tw-text-white hover:tw-text-blue-900 active:tw-text-blue-500 tw-cursor-pointer',
	'black': 'tw-bg-neutral-900 hover:tw-bg-neutral-600 active:tw-bg-neutral-100 active:tw-text-neutral-900 active:tw-border-2 active:tw-border-neutral-300 tw-text-white tw-cursor-pointer',
	'white': 'tw-bg-neutral-000 tw-text-neutral-400 hover:tw-text-neutral-500 hover:tw-bg-neutral-100 active:tw-text-neutral-900 active:tw-bg-neutral-200 tw-cursor-pointer',
	'grey': 'tw-bg-neutral-200 tw-text-neutral-900 hover:tw-text-neutral-500 hover:tw-bg-neutral-100 active:tw-text-neutral-900 active:tw-bg-neutral-200 tw-cursor-pointer',
	'outlined': 'tw-bg-white tw-text-neutral-900 hover:tw-text-neutral-900 hover:tw-bg-neutral-100 active:tw-text-neutral-000 active:tw-bg-neutral-200 tw-cursor-pointer tw-border-solid tw-border-1 tw-border-neutral-200'
}

export const UiButton = ({ variant = 'primary', size = 'small', loading, disabled, ...props }: IButton) => {
	const isJustifyStart = props?.className?.includes('tw-justify-start')

	return (
		<button
			{...props}
			disabled={disabled || loading}
			className={
				mergeClassName(
					disabled ? variantsButton['disabled'] : variantsButton[variant],
					`${variantsSize[size]} tw-outline-none tw-transition tw-duration-300`,
					`${(variant !== 'outlined' && variant !== 'tertiary') ? ' tw-border-none ' : ''}`,
					'tw-rounded-2 tw-relative tw-font-semibold tw-flex tw-items-center',
					`${isJustifyStart ? 'tw-justify-start' : 'tw-justify-center'} tw-gap-2 tw-py-1 tw-w-fit tw-px-6`,
					props?.className || ''
				)
			}
		>
			{/* {variant === 'menuButton' && <div className='tw-duration-500 tw-opacity-0 group-hover:tw-opacity-100 tw-absolute tw-left-0 tw-rounded-r tw-h-full tw-w-1.5 tw-bg-neutral-400 group-hover:tw-bg-blue-500' />} */}

			<svg
				className={
					classNames(
						'tw-animate-spin -ml-1',
						size === 'small' || size === 'medium' ? 'tw-h-3 tw-w-3' : 'tw-h-4 tw-w-4',
						loading && !disabled ? 'tw-flex' : 'tw-hidden',
					)
				}
				fill='none'
				viewBox='0 0 24 24'
			>
				<circle className='tw-opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
				<path className='tw-opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
			</svg>
			{props.children}
		</button >

	)

}
