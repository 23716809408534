import { add, sub } from 'date-fns'
import { TypeParameterDate } from '.'
import { _Timestamp } from '../../Time/types/Timestamp'
import { clone } from '../clone'
import { convertAllToDate, convertAllToNumber } from './converts'

type TypeWhatsFutureDate = {
	days?: number
	months?: number
	years?: number
}

/**
 * @description Função que retorna uma data futura daqui a X dias, X meses ou
 * X anos
 *
 * @param dateStart - Data inicial
 * @param options - Ooções
 *
 * @example (20230102000000, { days: 10 }) -> new Date(2023, 1, 12, 0, 0, 0)
 * @example (new Date(2024, 5, 10, 0, 0, 0), { months: 2 }) -> new Date(2024, 7, 10, 0, 0, 0)
*/
export const whatsFutureDate = <T extends TypeParameterDate>(dateStart: T, options: TypeWhatsFutureDate): T => {

	const cloneDateStart = convertAllToDate(clone(dateStart))
	const cloneOptions = clone(options)

	if (!cloneDateStart) return cloneDateStart as T

	return typeof dateStart === 'number' ? convertAllToNumber(add(cloneDateStart, cloneOptions)) as T : add(cloneDateStart, cloneOptions) as T

}

/**
 * @description Função que retorna uma data passada daqui a X dias, X meses ou
 * X anos
 *
 * @param dateStart - Data inicial
 * @param options - Ooções
 *
 * @example (20230102000000, { days: 10 }) -> new Date(2023, 1, 12, 0, 0, 0)
 * @example (new Date(2024, 5, 10, 0, 0, 0), { months: 2 }) -> new Date(2024, 7, 10, 0, 0, 0)
*/

export const whatsPastDate = <T extends TypeParameterDate>(dateStart: T, options: TypeWhatsFutureDate): T => {

	const cloneDateStart = convertAllToDate(clone(dateStart))
	const cloneOptions = clone(options)

	if (!cloneDateStart) return cloneDateStart as T

	return typeof dateStart === 'number' ? convertAllToNumber(sub(cloneDateStart, cloneOptions)) as T : sub(cloneDateStart, cloneOptions) as T

}
