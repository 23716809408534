import { clone } from './clone'
import { helpersDate } from './helpersDate'


export const minhaData = newDate => {

	const cloneNewDate = clone(newDate)

	let now = cloneNewDate || new Date()
	let minhaData

	minhaData =
		`${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, 0)}${(now.getDate()).toString().padStart(2, 0)}${(now.getHours()).toString().padStart(2, 0)}${(now.getMinutes()).toString().padStart(2, 0)}${(now.getSeconds()).toString().padStart(2, 0)}`

	minhaData = Number(minhaData)
	return minhaData
}

export const myDateToDate = myDate => {

	let cloneMyDate = clone(myDate)

	let newDate
	cloneMyDate = cloneMyDate.toString()
	// cloneMyDate >= 12 traz os segundos na data -> (20220307105002) -> Mon Mar 07 2022 10:50:02 GMT-0300 (Brasilia Standard Time)
	newDate = cloneMyDate.length >= 12 ?
		new Date(cloneMyDate.substr(0, 4), Number(cloneMyDate.substr(4, 2)) - 1, cloneMyDate.substr(6, 2), cloneMyDate.substr(8, 2), cloneMyDate.substr(10, 2), cloneMyDate.substr(12, 2)) :
		newDate = new Date(cloneMyDate.substr(0, 4), Number(cloneMyDate.substr(4, 2)) - 1, cloneMyDate.substr(6, 2))
	return newDate
}

export const myDateToDateWithTime = myDate => {

	let cloneMyDate = clone(myDate)

	let newDate
	cloneMyDate = cloneMyDate.toString()
	newDate = new Date(cloneMyDate.substr(0, 4), Number(cloneMyDate.substr(4, 2)) - 1, cloneMyDate.substr(6, 2), cloneMyDate.substr(8, 2), cloneMyDate.substr(10, 2))
	return newDate
}

export const myDateToDateObject = myDate => {

	let cloneMyDate = clone(myDate)

	cloneMyDate = myDateToDate(cloneMyDate)
	return { day: cloneMyDate.getDate(), month: cloneMyDate.getMonth() + 1, year: cloneMyDate.getFullYear(), date: cloneMyDate }
}

export const retornaData = data => {

	const cloneData = clone(data)

	let now = cloneData.toString()

	now =
		now.substr(6, 2) +
		now.substr(4, 2) +
		now.substr(0, 4)

	return now
}
// 20200617161128
// 20200420
export const toMinhaData = data => { // 20/04/2020 => 20200420

	const cloneData = clone(data)

	let now = cloneData
	now = now.replaceAll('/', '')
	now =
		`${now.substr(4, 4) +
		now.substr(2, 2) +
		now.substr(0, 2)}000000`
	now = Number(now)
	return now
}

export const escreveData = (data = '000000') => {

	const cloneData = clone(data)

	let now = cloneData.toString()

	now =
		`${now.substr(6, 2)}/${now.substr(4, 2)}/${now.substr(0, 4)}`

	return now
}

export const escreveHora = (data = '00000000000000') => {

	const cloneData = clone(data)

	let now = cloneData.toString()

	now =
		`${now.substr(8, 2)}:${now.substr(10, 2)}:${now.substr(12, 2)}`

	return now
}
/**
 *
 * @param {*} date:Date
 * @param {*} short:boolean
 */
export const getMonthPT = (date, short = false) => {

	const cloneDate = clone(date)
	const cloneShort = clone(short)

	const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
	const month = cloneDate.getMonth() + 1
	let name = monthNames[cloneDate.getMonth()]
	name = cloneShort ? name.slice(0, 3) : name
	return { month, name }
}


export const dateToBrFormat = date => {
	const cloneDate = clone(date)
	return cloneDate ? cloneDate.toLocaleDateString('pt-BR') : ''

}
export const dateToBrTimeFormat = (date, seconds = true) => {

	const cloneDate = clone(date)
	const cloneSeconds = clone(seconds)

	const d = cloneDate ? cloneDate.toLocaleTimeString('pt-BR') : ''
	return cloneSeconds ? d : d.slice(0, -3)
}

export const dateToDbFormat = date => {
	const cloneDate = clone(date)
	return cloneDate ? cloneDate.toISOString().slice(0, 10) : ''

}


export const dateToFirebase = date => {
	const cloneDate = clone(date)
	return helpersDate.convertAllToTimestamp(cloneDate)

}

/**
 *
 * @param {*} date string 12/23/5678
 * @returns 56782312
 */
export const dateToNumber = date => {
	const cloneDate = clone(date)
	return cloneDate.split('/').reverse().join('')

}

/**
 *
 * @param {*} date string 31/07/2022
 * @returns Sun Jul 31 2022 00:00:00 GMT-0300 (Brasilia Standard Time)
 */
export const formatedDateToDate = date => {

	const cloneDate = clone(date)
	return myDateToDate(dateToNumber(cloneDate))

}

/**
 *
 * @param {*} date string 9999-99-99
 * @returns 99/99/9999
 */
export const internationalToBrDate = date => {
	const cloneDate = clone(date)
	return cloneDate.split('-').reverse().join('/')

}

/**
 * Retorna a quantidade de dias que se passaram dado uma data
 * @param {*} date
 * @returns
 */
export const dateDifferenceInDays = date => {
	const cloneDate = clone(date)
	return Math.floor((new Date().getTime() - cloneDate.getTime()) / (1000 * 3600 * 24))

}
