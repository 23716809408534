import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { fnUser } from '../../../User'
import { TypeInstitution } from '../../../Institution/types/institution'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.b0003

type Props = {
	numberOfInvestors: number
	origination: TypeTeaser
	originationId: string
	profile: TypeUser
	uid: string
	institution: TypeInstitution
	institutionId: string
}

const pad = (numberOfInvestors: number) => numberOfInvestors > 1 ? 'novos investidores' : 'novo investidor'

function changeText(numberOfInvestors: number): string {
	return ITEM.msg.replace(`{{${ITEM.replacements.numberOfInvestors}}}`, `${String(numberOfInvestors)} ${pad(numberOfInvestors)}`)
}

export const sendAccept =
	async ({ uid, origination, originationId, profile, institution, institutions, institutionId, numberOfInvestors }: TypeNotification & Props) => {

		const cloneUid = clone(uid)
		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneProfile = clone(profile)
		const cloneInstitution = clone(institution)
		const cloneInstitutions = clone(institutions)
		const cloneInstitutionId = clone(institutionId)
		const cloneNumberOfInvestors = clone(numberOfInvestors)

		return await fnNotification.send({
			uiPermissions: ITEM.permissions,
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination }),
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile, uid: cloneUid }),
			user: fnUser.getUser({ user: cloneProfile || {} as TypeUser }),
			institutions: fnNotification.formatData.getInstitutions({ institutions: cloneInstitutions }),
			message: changeText(cloneNumberOfInvestors),
			type: ITEM._id,
			institution: fnNotification.formatData.getInstitution({ institution: cloneInstitution, id: cloneInstitutionId }),
			showCustomer: true
		} as TypeNotification)
	}

