import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const GiftOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M7.63145 15.1331C8.39548 15.1338 9.01429 15.7537 9.01361 16.5177L9.00492 26.2509L24.0247 26.2643L24.0334 16.5311C24.034 15.7671 24.654 15.1483 25.418 15.1489C26.182 15.1496 26.8008 15.7695 26.8002 16.5336L26.7902 27.6502C26.7896 28.4142 26.1696 29.033 25.4056 29.0323L7.61905 29.0165C6.85502 29.0158 6.23621 28.3959 6.23689 27.6318L6.24681 16.5152C6.24749 15.7512 6.86742 15.1324 7.63145 15.1331Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M4.02806 10.9547C4.02874 10.1907 4.64867 9.57184 5.4127 9.57252L27.6459 9.59237C28.4099 9.59305 29.0287 10.213 29.028 10.977L29.0231 16.5353C29.0224 17.2993 28.4025 17.9181 27.6385 17.9175L5.40527 17.8976C4.64124 17.8969 4.02242 17.277 4.0231 16.513L4.02806 10.9547ZM6.79362 12.3406L6.79113 15.1321L26.2575 15.1494L26.26 12.3579L6.79362 12.3406Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.5289 9.58278C17.2929 9.58346 17.9118 10.2034 17.9111 10.9674L17.8962 27.6423C17.8955 28.4063 17.2756 29.0251 16.5116 29.0245C15.7475 29.0238 15.1287 28.4039 15.1294 27.6398L15.1443 10.9649C15.145 10.2009 15.7649 9.5821 16.5289 9.58278Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M11.5917 6.78663C11.4084 6.78646 11.2269 6.8224 11.0575 6.89239C10.8881 6.96239 10.7341 7.06506 10.6044 7.19455C10.4747 7.32404 10.3717 7.47782 10.3014 7.64709C10.2312 7.81637 10.1949 7.99784 10.1947 8.18113C10.1946 8.36442 10.2305 8.54595 10.3005 8.71536C10.3705 8.88476 10.4732 9.03872 10.6026 9.16844C10.7321 9.29816 10.8859 9.40111 11.0552 9.47141C11.2245 9.5417 11.4059 9.57796 11.5892 9.57813L14.6341 9.58084C14.5322 9.34109 14.4149 9.09258 14.2811 8.84628C13.6014 7.59507 12.7272 6.78764 11.5917 6.78663ZM16.5287 10.9659C17.8855 10.6958 17.8854 10.6954 17.8853 10.6949L17.8851 10.6938L17.8846 10.6913L17.8832 10.6847L17.8792 10.6654C17.876 10.65 17.8715 10.6293 17.8658 10.6037C17.8545 10.5525 17.8381 10.4816 17.8163 10.3937C17.7728 10.2184 17.7072 9.97389 17.6159 9.683C17.4349 9.10621 17.1453 8.32235 16.7123 7.52545C15.8815 5.99616 14.2878 4.02223 11.5942 4.01983C11.0476 4.01934 10.5062 4.12652 10.001 4.33526C9.49576 4.544 9.03661 4.8502 8.64973 5.23638C8.26286 5.62257 7.95584 6.08117 7.7462 6.58601C7.53656 7.09084 7.42841 7.63203 7.42793 8.17866C7.42744 8.72529 7.53462 9.26667 7.74336 9.77188C7.9521 10.2771 8.2583 10.7362 8.64448 11.1231C9.03066 11.51 9.48926 11.817 9.9941 12.0266C10.4989 12.2363 11.0401 12.3444 11.5868 12.3449L16.5275 12.3493C16.9419 12.3497 17.3347 12.1642 17.5978 11.8439C17.8608 11.5237 17.9664 11.1023 17.8855 10.6958L16.5287 10.9659Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.3607 7.51989C17.2073 5.98385 18.8216 4.02623 21.5363 4.02865C22.6402 4.02963 23.6986 4.46913 24.4785 5.25046C25.2585 6.03178 25.6961 7.09093 25.6951 8.19491C25.6941 9.29889 25.2546 10.3573 24.4733 11.1372C23.692 11.9171 22.6328 12.3547 21.5288 12.3537L16.5264 12.3493C16.1119 12.3489 15.7195 12.1627 15.457 11.842C15.1945 11.5212 15.0897 11.0997 15.1713 10.6934L16.5276 10.9659C15.1713 10.6934 15.1712 10.6939 15.1713 10.6934L15.1722 10.6888L15.1736 10.6821L15.1777 10.6627C15.181 10.6472 15.1855 10.6264 15.1912 10.6007C15.2027 10.5494 15.2193 10.4783 15.2414 10.3904C15.2855 10.2147 15.3519 9.96996 15.4444 9.67879C15.6278 9.10143 15.9214 8.31703 16.3607 7.51989ZM18.4285 9.58418C18.5311 9.34624 18.6492 9.09973 18.7838 8.85543C19.4682 7.61369 20.3577 6.7944 21.5338 6.79545C21.904 6.79578 22.2589 6.94314 22.5204 7.20513C22.7819 7.46712 22.9286 7.82227 22.9283 8.19244C22.928 8.56262 22.7806 8.9175 22.5186 9.17902C22.2566 9.44054 21.9015 9.58728 21.5313 9.58695L18.4285 9.58418Z' fill='#272827' />
	</Svg>
)


