import { notifications } from '../../../Services/cloudfunctions'
import { minhaData } from '../../Utils/minhaData'
// import { sendNotificationsPublicado } from '../../Services/sendNotifications'
import { dateToFireTime } from '../../Utils/timestamp'
import { db } from '../../../Services/db'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { db5 } from '../../../Services/db5'

type Props = {
	originationId: string;
	origination: TypeTeaser;
	profile: TypeUser;
	uid: string;
};

/**
 * publish é uma função que publica um teaser, ou seja, envia o teaser para etapa de comitê
*/

export const publish = async (props: Props) => {
	const { originationId, origination } = props

	const origination2: TypeTeaser = JSON.parse(JSON.stringify(origination))
	// console.log(origination2)

	const data: TypeTeaser = {
		...origination2,
		dataPublicacao: minhaData(),
		dataModificacao: minhaData(),
		publicationHistory: origination2.publicationHistory.length
			? [...origination2.publicationHistory, dateToFireTime(new Date())]
			: [dateToFireTime(new Date())],
		status: 'completo',
		ativado: true,
	}

	return await db5.teasers
		.update({ id: originationId, doc: data })
		.then(async () => {
			await notifications({
				targetId: originationId,
				typeOrMessage: 'a0003',
				profileUid: origination2.uid,
			})
		})
}
