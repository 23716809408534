import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

import { NOTIFICATIONS_TEMPLATE } from 'Constants/NOTIFICATIONS_TEMPLATE'

export type TypeSendNotification = {
	/** uid de quem está enviando a notificação*/
	profileUid: string;
	/** id/uid de quem vai receber a notificação. Será feita uma chamada no banco, caso o targetId seja id de um match/teaser,
	 * os usuários ligado a tal é que vão receber as notificações, caso seja uid de um usuário específico somente ele receberá
	 * a notificação
	 */
	targetId: string;
	/**
	 * @description Tipo de noticiação que o targetId vai receber (ver a constante NOTIFICATIONS_TEMPLATE)
	 *
	 * @example "a0004" => 'Originação rejeitada'
	 * @example "b0002" => 'Cliente aceitar novos investidores'
	 * @example "Mensagem personalizada" => "Mensagem personalizada"
	*/
	typeOrMessage: keyof typeof NOTIFICATIONS_TEMPLATE | string;
};


export const _notifications = (input: TypeSendNotification) => firebase.functions().httpsCallable('fnCloud')({ microservice: 'notifications', input })
