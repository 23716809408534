import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const DownBiggerOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M3.7998 16.8619L15.0998 28.1619C15.1998 28.2619 15.3998 28.3619 15.4998 28.4619C15.7998 28.5619 16.1998 28.5619 16.4998 28.4619C16.6998 28.3619 16.7998 28.2619 16.8998 28.1619L28.1998 16.8619C28.6998 16.3619 28.6998 15.5619 28.1998 15.0619C27.6998 14.5619 26.8998 14.5619 26.3998 15.0619L17.1998 24.2619V4.66191C17.1998 4.36191 17.0998 3.96191 16.7998 3.76191C16.4998 3.56191 16.2998 3.46191 15.9998 3.46191C15.2998 3.46191 14.7998 4.06191 14.7998 4.66191V24.2619L5.5998 15.0619C5.0998 14.5619 4.2998 14.5619 3.7998 15.0619C3.2998 15.6619 3.2998 16.4619 3.7998 16.8619Z' fill='#272827' />
	</Svg>
)
