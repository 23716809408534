import { add } from '../methods/add'
import { del } from '../methods/del'
import { get } from '../methods/get'
import { update } from '../methods/update'
import { DocumentData } from '@firebase/firestore-types'
import { getWhere } from '../methods/getWhere'
import { getSync } from '../methods/getSync'
import { getWhereSync } from '../methods/getWhereSync'
import { TypeCollections } from '../configs/collections'

export const generateDb5 = <TDoc extends DocumentData>(collection: TypeCollections, model: any) => ({
	add: add<TDoc>(collection, model),
	delete: del<TDoc>(collection),
	update: update<TDoc>(collection),
	get: get<TDoc>(collection, model),
	getWhere: getWhere<TDoc>(collection, model),
	getSync: getSync<TDoc>(collection, model),
	getWhereSync: getWhereSync<TDoc>(collection, model),
})
