/**
 * A função `mountCardOriginacao` pega uma lista de teasers, processa cada
 * teaser para gerar um objeto de cartão com propriedades específicas e retornar um array
 *desses objetos de cartão.
 * @param {typeListTeasers} teasers - O parâmetro `teasers` é uma matriz de teasers
 * objetos que contêm informações sobre diferentes projetos ou produtos. Cada
 * o objeto teaser normalmente inclui propriedades como nome, codinome, URL do logotipo,
 * ID, status de publicação, status, status de validação, data de alteração solicitada,
 * data de publicação e detalhes do teaser.
 * @returns Um array de objetos do tipo `TMountCardOriginacao` está sendo retornado
 * da função `mountCardOriginacao`. Cada objeto representa uma carta com
 * propriedades como `nome`, `codinome`, `urlLogo`, `id`, `isPublicado`, `status`,
 * `validation`, `dateRequestedChange`, `dataPublicacao`, `teaser` e `office`.
 */
//CRIADO POR IA

import { escreveData } from '../../../Functions/Utils/minhaData'
import { typeListTeasers } from '../types/listTeasers'
import { TypeTeaser } from '../types/teaser'
import { fnTeaser, TRequestedChangeDate, TStatusCustomer } from '../'
import { TMountCardOriginacao } from '../type/TMountCardOriginacao'
import { clone } from '../../../Functions/Utils/clone'

/**
 *
 * ===== Regras =====
 *
 * PUBLICADOS
 * Aparece o botão de teaser no rodapé do card
 * Aparece o status logo acima do botão de teaser
	 Lista de status:
		- Em comitê
		- Fase comercial
		- Cliente ativo
		- Em stand-by
 *
 * NÃO PUBLICADOS
 * Aparece o botão de editar e teaser
 * aparece a barra de progresso ou o 'ajustes solicitados'
 * Barra de progresso:
		- azul : se for minimal true
		- verde : se porcentagem for 100%
		- vermelho : minimal false
 * Ajustes solicitados:
		- Aparece apenas se existir uma data do campo dateRequestedChanges
 */

export const mountCardOriginacao = (teasers: typeListTeasers): Array<TMountCardOriginacao> => {

	const cloneTeasers = clone(teasers)

	const cards: Array<TMountCardOriginacao> = cloneTeasers.map((teaser: TypeTeaser) => {
		const card: TMountCardOriginacao = {} as TMountCardOriginacao
		const stausTeaser: TStatusCustomer = fnTeaser.getStatusCustomer(teaser)
		card.nome = teaser.nome || 'Nome do projeto'
		card.codinome = teaser.codinome || 'Codinome do projeto'
		card.urlLogo = teaser.urlLogo || '/assets/img/default-avatar.png'
		card.id = teaser._id || ''
		card.isPublicado = Boolean(fnTeaser.isPublicado(teaser))
		card.status = stausTeaser || null
		card.validation = fnTeaser.validation(teaser)
		card.dateRequestedChange = fnTeaser.getRequestedChangedDate(teaser)
		card.dataPublicacao = escreveData((teaser.dataPublicacao).toString())
		card.teaser = teaser
		card.office = null
		return card
	})
	return cards
}
