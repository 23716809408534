import { clone } from '../../../Utils/clone'
import { myDateToDate } from '../../../Utils/minhaData'
import { TypeTeaser } from '../../types/teaser'

export const getRbWithSeptemberRule = (teaser: TypeTeaser): number => {

	const cloneTeaser = clone(teaser)

	const publicationDate: Date = myDateToDate(cloneTeaser.dataPublicacao)

	return publicationDate.getMonth() >= 8 ? +cloneTeaser.rb0 : +cloneTeaser.rb1
}

export const getRb2WithSeptemberRule = (teaser: TypeTeaser): number => {

	const cloneTeaser = clone(teaser)

	const publicationDate: Date = myDateToDate(cloneTeaser.dataPublicacao)

	return publicationDate.getMonth() >= 8 ? +cloneTeaser.rb1 : +cloneTeaser.rb2
}

export const getRb3WithSeptemberRule = (teaser: TypeTeaser): number => {

	const cloneTeaser = clone(teaser)

	const publicationDate: Date = myDateToDate(cloneTeaser.dataPublicacao)

	return publicationDate.getMonth() >= 8 ? +cloneTeaser.rb2 : +cloneTeaser.rb3
}
