/**
 * Indicadores de taxas de um agente
 *
*/
export const INDICATORS = {
	'indicator001': {
		_id: 'indicator001',
		label: 'Ruim',
		bgColor: 'red-000',
		textColor: 'red-400'
	},
	'indicator002': {
		_id: 'indicator002',
		label: 'Regular',
		bgColor: 'blue-000',
		textColor: 'neutral-700'
	},
	'indicator003': {
		_id: 'indicator003',
		label: 'Bom',
		bgColor: 'green-000',
		textColor: 'green-400'
	},
	'indicator004': {
		_id: 'indicator004',
		label: 'Ótimo',
		bgColor: 'purple-000',
		textColor: 'purple-400'
	}
}

export const INDICATORS_GET = (code: string) => {
	const _code = code as keyof typeof INDICATORS

	if (!INDICATORS[_code]) {
		return ({
			'indicator001': {
				_id: 'indicator001',
				label: 'Ruim',
				bgColor: 'red-000',
				textColor: 'red-400'
			},
			'indicator002': {
				_id: 'indicator002',
				label: 'Regular',
				bgColor: 'blue-000',
				textColor: 'neutral-700'
			},
			'indicator003': {
				_id: 'indicator003',
				label: 'Bom',
				bgColor: 'green-000',
				textColor: 'green-400'
			},
			'indicator004': {
				_id: 'indicator004',
				label: 'Ótimo',
				bgColor: 'purple-000',
				textColor: 'purple-400'
			}
		})
	}
	return (INDICATORS[_code])

}

export const INDICATORS_VALUES = Object.values(INDICATORS)
export const INDICATORS_KEYS = Object.keys(INDICATORS)
