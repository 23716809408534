import { clone } from './clone'

// 234-234-234-234234
// 34.3.25-1111 : produção de algodão
/**
* Retorna um array com variações do CNAE
* @param {String} StringCnae
* @returns {Array} {Array} um array com variações do CNAE?
* @example fatorialCnaes('34.3.25-1111') => ['34.3.25-1111', '34.3-25', '34.30', '34.31', '34.32', '34.33', '34.34', '34.35', '34.36', '34.37', '34.38', '34.39', '34.3', '34']
*/



export const fatorialCnaes = (StringCnae: string) => {

	const cloneStringCnae = clone(StringCnae)

	const cnae: string = (cloneStringCnae).replace(/-/g, '.')
	const arr = cnae.split('.')

	let temp = []
	if (cloneStringCnae.length !== 4) {
		temp.push(cloneStringCnae)
	}
	arr[2] && temp.push(`${`${arr[0]}.${arr[1]}` + '-'}${arr[2]}`)

	if (arr[1]) {
		if (arr[1].length === 2) {
			temp.push(`${`${arr[0]}.${arr[1]}` + '-'}${arr[2]}`)
		}
		else {
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach(i => {
				temp.push(`${arr[0]}.${arr[1]}${i}`)
			})
			temp.push(`${arr[0]}.${arr[1]}`)
		}
	}
	if (arr[1]?.length === 2) {
		temp.push(`${arr[0]}.${arr[1]}`)
		temp.push(`${arr[0]}.${arr[1][0]}`)
	}
	temp.push(arr[0])

	temp = temp.filter(i => !!i)

	return [...new Set(temp)]
}
