import { clone } from '../../../Functions/Utils/clone'
import { escreveData } from '../../../Functions/Utils/minhaData'
import { TypeTeaser } from '../types/teaser'

/**
 * Retorna a data de criação de um teaser formatada.
 *
 * @param {TypeTeaser} teaser - O teaser para o qual a data de criação formatada será obtida.
 * @returns {string} - A data de criação formatada.
 * @example
 * / Exemplo de uso:
 * const teaser = {
 *   dataCriacao: 20230409103000,
 * };
 *
 * const dataCriacaoFormatada = getDateCreateFormatted(teaser);
 * console.log(dataCriacaoFormatada); // Saída: "09/04/2023"
 */

export const getDateCreateFormatted = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return escreveData((cloneTeaser.dataCriacao).toString())

}
