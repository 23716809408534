import { TypeUser } from 'Functions/User/types/TypeUser'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeCreateBda = {
	email: string,
	pass: string,
	profile: TypeUser
}

type CreateBdaResponse = {
	status: string
}

export const _createBda = (input: TypeCreateBda): Promise<{ data: CreateBdaResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'createBda', input })
