import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
	apiKey: process.env.REACT_APP_FB_APIKEY,
	authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
	databaseURL: process.env.REACT_APP_FB_DATABASEURL,
	projectId: process.env.REACT_APP_FB_PROJECTID,
	storageBucket: process.env.REACT_APP_FB_SOTORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
	measurementId: process.env.REACT_APP_FB_MEASUREMENTID
}

firebase.initializeApp(config)

// funções de login
const fireAuth = firebase.auth()

export const fireLogar = (email: string, pass: string, callError: () => void) => {
	fireAuth.signInWithEmailAndPassword(email.trim(), pass).catch(() => {
		callError()
	})
}

export const fireLogout = () => {
	fireAuth.signOut().then(() => { })
}

// Início do Cloud Firestore

const db = firebase.firestore()
db.settings({})

// atualiza um documento sem apagar
export const fStoreUpdate = (caminho: string, obj: any, call: () => void) => (
	db.doc(caminho).set(obj, { merge: true }).then(call)
)
// obtem dados de um documento
export const fStoreGet = (caminho: string, call: () => void, callFail = () => { }) => (
	db.doc(caminho).get().then(call).catch(callFail)
)

// sotrage firebase
export const storageService = firebase.storage()
export const storageRef = storageService.ref()
