import { clone } from '../../../Utils/clone'
import { GUARANTEES_DETAILS } from '../../../../Constants/GUARANTEES'
import { checkIfCoinIsInBrazilianFormat } from '../../../Utils/checkIfCoinIsInBrazilianFormat'
import { TypeTeaser } from '../../types/teaser'
import { getFinancing } from './getFinancing'

export const getTotalInsure = (teaser: TypeTeaser): number => {

	const cloneTeaser = clone(teaser)

	if (!cloneTeaser.guarantees) return 0

	let total = Object.entries(cloneTeaser.guarantees)
		.reduce(
			(accumulator, [key, value]) => {
				const sum = value.reduce((_accumulator, _value) => {

					if (!_value?.type || !_value?.value) return _accumulator

					const enable = _value?.enabled ?? true

					if (!enable) return _accumulator

					const value = checkIfCoinIsInBrazilianFormat(_value?.value)


					const multiple =
						GUARANTEES_DETAILS[key].subType?.[_value?.type]?.multiplicator(cloneTeaser) ?? 1

					return _accumulator + value * multiple
				}, 0)

				accumulator = accumulator + sum
				return accumulator
			}, 0)

	const pfValue = getFinancing({ teaser: cloneTeaser })
		.filter(financing => financing.key === 'fin1')
		.map(item => {
			const i = item

			const saldoCarteiraRecebiveis = +(item.saldoCarteiraRecebiveis || 0)
			const saldoCarteiraRecebiveisMultiplicator = GUARANTEES_DETAILS['Recebíveis Imobiliários'].subType?.['imoveisNaPlanta']?.multiplicator(cloneTeaser) ?? 1
			i.saldoCarteiraRecebiveis = (saldoCarteiraRecebiveis * saldoCarteiraRecebiveisMultiplicator).toString()

			const vgv = +(item.vgv || 0)
			const vgvMultiplicator = GUARANTEES_DETAILS['Recebíveis Imobiliários'].subType?.['imoveisNaPlanta']?.multiplicator(cloneTeaser) ?? 1
			i.vgv = (vgv * vgvMultiplicator).toString()

			return i
		})
		.reduce((acc, financing) => acc + +(financing.vgv || 0) + +(financing.saldoCarteiraRecebiveis || 0), 0)

	total = total + pfValue

	return +total.toFixed(2)
}
