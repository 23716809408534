import { clone } from '../../../Functions/Utils/clone'
import { TypeUser } from '../../../Functions/User/types/TypeUser'
import { TypeNotifications } from '../types/notification'
import { TypeTeaser } from '../../Teaser/types/teaser'

export const unReadNotificationsPerOrigination = ({
	notificationsUnRead,
	origination,
	profile
}: {
	notificationsUnRead: TypeNotifications;
	origination: TypeTeaser;
	profile?: TypeUser
}): TypeNotifications => {

	const cloneNotificationsUnRead = clone(notificationsUnRead)
	const cloneOrigination = clone(origination)

	const arrayNotifications = Object.entries(cloneNotificationsUnRead).filter(
		([id, notification]) => cloneOrigination?._id && cloneOrigination._id === notification.origination?.id
	)

	if (profile) return Object.fromEntries(arrayNotifications.filter(([id, notification]) => notification.unReadBy?.[profile?._id] === true))

	return Object.fromEntries(arrayNotifications)
}
