import { useState, useEffect } from 'react'
import * as S from './styles'
import { fnNotification } from 'Functions/Notification'
import { fnUser } from 'Functions/User'
import { useNotifications } from 'Hooks/useNotifications'
import { PERMISSIONS_NOTIFICATIONS } from 'Constants/PERMISSIONS_NOTIFICATIONS'
import { TypeNotification } from 'Functions/Notification/types/notification'
import { TYPE_NOTIFICATIONS } from 'Constants/TYPE_NOTIFICATIONS'
import { useHistory } from 'react-router-dom'
import { useContextAuth } from 'App/ContextAuth'
import { UiButton } from 'ComponentsUi/UiButton'

import { format } from 'date-fns'
import { fnMatch } from 'Functions/Match'
import { TypeGroupByTeaser } from 'Functions/Notification/methods/getNotificationsGroupByTeaser'
import { IMG_S } from 'Constants/IMG_S'
import { helpersDate } from 'Functions/Utils/helpersDate'
import { TypeMatch } from 'Functions/Match/types/match'
import { UiTag } from 'ComponentsUi/UiTag'
import { useContextNotificationWidget } from 'App/ContextNotificationWidget'
import { usContextNotificationsFeedCustomer } from 'App/ContextNotificationsFeedCustomer'
import { useContextUnReadNotifications } from 'App/ContextUnReadNotifications'

export const Notifications = ({ mobile }: any) => {
	const history = useHistory()

	const {
		// unReadNotifications,
		setNotificationToSeeInFeedCustomer,
	} = usContextNotificationsFeedCustomer()

	const { openNotification, setOpenNotification } =
		useContextNotificationWidget()
	const { unReadNotifications, setUnReadNotifications } =
		useContextUnReadNotifications()
	const { globalAuth } = useContextAuth()
	const profile = globalAuth.profile
	const uid = globalAuth.uid
	const { notifications: notificationsAux } = useNotifications({
		profile,
		uid,
		uiPermission: PERMISSIONS_NOTIFICATIONS.p0006
			._id as keyof typeof PERMISSIONS_NOTIFICATIONS,
	})

	const isAgentOrEnterprise = fnUser.isAgent(profile) || fnUser.isEnterprise(profile)

	const [notifications, setNotifications] = useState<
		[string, TypeNotification][]
	>([])
	const [notificationsAgent, setNotificationsAgent] =
		useState<TypeGroupByTeaser>({})

	useEffect(() => {
		const list = Object.entries(notificationsAux)
			// .filter(([id, notification]) => notification.unReadBy?.[globalAuth.uid] === true)
			.sort(([_, notification], [__, notification2]) =>
				notification.createdAt < notification2.createdAt ? 1 : -1
			)

		if (globalAuth.uid && isAgentOrEnterprise) {
			const groupedByTeaser = fnNotification.getNotificationsGroupByTeaser({
				notifications: unReadNotifications,
			})
			setNotificationsAgent(groupedByTeaser)
		}

		if (globalAuth.uid && fnUser.isCustomer(profile)) {
			setNotifications(list)
		}
	}, [globalAuth.uid, profile, notificationsAux, unReadNotifications, isAgentOrEnterprise])

	function handleClick(notification: TypeNotification) {
		if (notification.type === TYPE_NOTIFICATIONS.b0001._id) {
			setOpenNotification((e: boolean) => !e)
			setTimeout(() => history.push('/perfil'), 300)
		}
	}

	const seeNotificationsTeaser = (teaserId: string) => {
		history.push(`/teasers/${teaserId}`)
	}

	const seeInFeed = (matchId: string) => {
		const currentPath = history.location.pathname

		if (currentPath !== '/' && currentPath !== '/painel') {
			history.push('/')
		}

		setOpenNotification(false)
		setNotificationToSeeInFeedCustomer(matchId)
	}

	return (
		<S.Container mobile={mobile} active={openNotification} className='tw-shadow-xl'>
			<S.Title>
				<S.TitleText>
					Notificações
					{notifications.length > 0 && <> ({notifications.length})</>}
					{fnUser.isAgent(profile) &&
						Object.keys(unReadNotifications).length > 0 &&
						(
							<> ({Object.keys(unReadNotifications).length})</>
						)}
				</S.TitleText>
				<S.TitleIcon onClick={() => setOpenNotification((e: boolean) => !e)}>
					{/* <AiOutlineClose
						className='cy-close-notification'
						size={16}
						color='#f00'
					/> */}
					<span className='material-icons tw-text-[#f00] cy-close-notification tw-text-[19px] tw-cursor-pointer'>
						close
					</span>
				</S.TitleIcon>
			</S.Title>
			<S.NotificationContainer mobile>
				<S.NotificationBox>
					{notifications.length === 0 &&
						Object.entries(notificationsAgent).length === 0 &&
						(
							<p className='tw-text-sm tw-font-bold tw-mt-2'>
								Nenhuma nova notificação
							</p>
						)}

					{isAgentOrEnterprise &&
						Object.entries(notificationsAgent)
							.sort((notificationA, notificationB) =>
								notificationA[1].dateLastNotification <
									notificationB[1].dateLastNotification
									? 1
									: -1
							)
							.map(([id, notificationInfo]) => {
								const notificationsArray = Object.values(
									notificationInfo?.notifications || {}
								)

								if (notificationsArray.length === 0) return null

								return (
									<S.Notification
										key={id}
										mobile
										notRead
									// onClick={() => handleClick(notification)}
									>
										<div className='tw-flex tw-items-center tw-gap-3'>
											<img
												className='tw-shrink-0 tw-p-1 tw-object-cover tw-bg-white tw-h-14 tw-w-14 tw-rounded tw-border tw-border-solid tw-border-neutral-200'
												src={
													notificationsArray[0].origination?.urlLogo ||
													IMG_S.LOGO_TEASER
												}
											/>

											<h2 className='tw-text-base tw-font-bold tw-text-gray-800 tw-truncate '>
												{notificationsArray[0].origination?.fantasyName ||
													notificationsArray[0].origination?.name}
											</h2>
										</div>

										<div className='tw-flex tw-mt-3'>
											{notificationsArray.length === 1 && (
												<p className='tw-text-sm tw-font-medium tw-mb-0'>
													Você tem <b>{notificationsArray.length}</b> nova
													notificação neste teaser.
												</p>
											)}
											{notificationsArray.length > 1 && (
												<p className='tw-text-sm tw-font-medium tw-mb-0'>
													Você tem <b>{notificationsArray.length}</b> novas
													notificações neste teaser.
												</p>
											)}
										</div>

										<div className='tw-flex tw-mt-3 tw-justify-between tw-items-center'>
											<p className='tw-my-0'>
												{/* {format(
													helpersDate.convertAllToDate(helpersDate.convertAllToTimestamp(notificationInfo.dateLastNotification))
													,
													'dd/MM/yyyy HH:mm'
												)} */}

												{format(
													helpersDate.convertAllToDate(notificationInfo.dateLastNotification),
													'dd/MM/yyyy HH:mm'
												)}
											</p>
											<UiButton onClick={() => seeNotificationsTeaser(id)}>
												Ver mais
											</UiButton>
										</div>
									</S.Notification>
								)
							})}

					{fnUser.isCustomer(profile) &&
						notifications.map(
							([id, notification]: [string, TypeNotification], index) => {
								const isCredit = fnMatch.isCredit({
									typeOperation: notification.match?.typeOperation || '',
								} as TypeMatch)

								return (
									<S.Notification
										key={id}
										mobile
										notRead={fnNotification.checkUnRead({ notification, uid })}
										onClick={() => handleClick(notification)}
									>
										<div className='tw-flex tw-items-center tw-gap-3'>
											{!fnNotification.isIndependent({ notification }) && (
												<img
													className='tw-shrink-0 tw-p-1 tw-object-contain	 tw-bg-white tw-h-14 tw-w-14 tw-rounded tw-border tw-border-solid tw-border-neutral-200'
													src={
														notification.institution?.urlLogo ||
														IMG_S.INSTITUTION_LOGO
													}
												/>
											)}
											<div className='tw-flex tw-flex-col tw-truncate tw-gap-1'>
												<h2 className='tw-text-base tw-font-bold tw-text-gray-800 tw-truncate '>
													{fnNotification.isIndependent({ notification })
														? 'Stark Money'
														: notification.institution?.name}
												</h2>

												{!fnNotification.isIndependent({ notification }) &&
													notification.match?.typeOperation &&
													(
														<>
															<UiTag variant={isCredit ? 'orange' : 'blue'}>
																{' '}
																{isCredit ? 'Crédito' : 'M&A'}
															</UiTag>
														</>
													)}
											</div>
										</div>
										<p className='tw-text-sm tw-mb-0 tw-mt-3'>
											{notification.message}
										</p>
										<div className='tw-flex tw-mt-3 tw-justify-between tw-items-center'>
											<p className='tw-my-0'>
												{/* {helpersDate.convertAllToDate(helpersDate.convertAllToTimestamp(notification.createdAt)) && format(
													helpersDate.convertAllToDate(helpersDate.convertAllToTimestamp(notification.createdAt)) || 0
													,
													'dd/MM/yyyy HH:mm'
												)} */}
												{format(
													helpersDate
														.convertAllToDate(notification.createdAt)
													,
													'dd/MM/yyyy HH:mm'
												)}
											</p>
											{fnNotification.checkUnRead({ notification, uid }) && (
												<>
													{fnNotification.isIndependent({ notification }) ? (
														<UiButton
															onClick={() =>
																fnNotification.setAsRead(notification, profile)
															}
														>
															Marcar como lida
														</UiButton>
													) : (
														<UiButton
															onClick={() =>
																seeInFeed(notification.match?.id || '')
															}
														>
															Ver no feed
														</UiButton>
													)}
												</>
											)}
										</div>
									</S.Notification>
								)
							}
						)}
				</S.NotificationBox>
			</S.NotificationContainer>
		</S.Container>
	)
}
