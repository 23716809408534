import { clone } from '../../../Utils/clone'
import { myDateToDate } from '../../../Utils/minhaData'
import { TypeTeaser } from '../../types/teaser'

export const getEbWithSeptemberRule = (teaser: TypeTeaser): number => {

	const cloneTeaser = clone(teaser)

	const publicationDate: Date = myDateToDate(cloneTeaser.dataPublicacao)

	return publicationDate.getMonth() >= 8 ? +cloneTeaser.eb0 : +cloneTeaser.eb1
}
