import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeNotification } from '../types/notification'

export const update = async (props: { id: string, notification: TypeNotification }) => {

	const cloneProps = clone(props)

	const { id, notification } = cloneProps
	await db5.notifications.update({ id, doc: notification })
}
