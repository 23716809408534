import { clone } from '../../../Utils/clone'
import { GUARANTEES_DETAILS } from '../../../../Constants/GUARANTEES'
import { checkIfCoinIsInBrazilianFormat } from '../../../Utils/checkIfCoinIsInBrazilianFormat'
import { TypeTeaser } from '../../types/teaser'

export const getRealInsure = (teaser: TypeTeaser): number => {

	const cloneTeaser = clone(teaser)

	if (!cloneTeaser.guarantees) return 0

	const guarantees = cloneTeaser.guarantees['Imobiliária']
		? cloneTeaser.guarantees['Imobiliária']
		: []

	return guarantees.reduce((accumulator, guarantee) => {
		if (!guarantee?.type || !guarantee?.value) return accumulator

		const enable = guarantee?.enabled ?? true

		if (!enable) return accumulator

		const value = checkIfCoinIsInBrazilianFormat(guarantee?.value)

		const multiple =
			GUARANTEES_DETAILS['Imobiliária'].subType?.[guarantee?.type]?.multiplicator(teaser) ?? 1

		return accumulator + value * multiple
	}, 0)
}
