import { masks } from 'Functions/Utils/masks'
import React from 'react'

export const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
	const cursorPosition = event.currentTarget.selectionStart
	const backspacePressed = event.key === 'Backspace'
	const value = event.currentTarget.value

	if (backspacePressed && cursorPosition && cursorPosition < value.length) {
		const newValue = value.slice(0, cursorPosition - 1) + value.slice(cursorPosition)
		event.currentTarget.value = masks.helper.percent(newValue)
	}

	if (backspacePressed && cursorPosition === value.length) {
		const newValue = value.slice(0, cursorPosition - 3) + value.slice(cursorPosition)
		event.currentTarget.value = newValue
	}
}
