import { clone } from './clone'
// import { helpersDate } from './helpersDate'

export const minhaData = (newDate?: Date): number => {

	const cloneNewDate = clone(newDate)

	const now = cloneNewDate || new Date()
	let minhaData

	minhaData = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`
	minhaData = Number(minhaData)
	return minhaData
}

export const myDateToDate = (myDateN: number | string) => {

	const cloneMyDateN = clone(myDateN)

	let newDate
	const myDate = cloneMyDateN.toString()
	// myDate >= 12 traz os segundos na data -> (20220307105002) -> Mon Mar 07 2022 10:50:02 GMT-0300 (Brasilia Standard Time)
	newDate =
		myDate.length >= 12
			? new Date(
				Number(myDate.substr(0, 4)),
				Number(myDate.substr(4, 2)) - 1,
				Number(myDate.substr(6, 2)),
				Number(myDate.substr(8, 2)),
				Number(myDate.substr(10, 2)),
				Number(myDate.substr(12, 2))
			)
			: (newDate = new Date(
				Number(myDate.substr(0, 4)),
				Number(myDate.substr(4, 2)) - 1,
				Number(myDate.substr(6, 2))
			))
	return newDate
}

// export const myDateToDateWithTime = (myDateN: number) => {

// 	const cloneMyDateN = clone(myDateN)

// 	const myDate = cloneMyDateN.toString()
// 	const newDate = new Date(
// 		Number(myDate.substr(0, 4)),
// 		Number(myDate.substr(4, 2)) - 1,
// 		Number(myDate.substr(6, 2)),
// 		Number(myDate.substr(8, 2)),
// 		Number(myDate.substr(10, 2))
// 	)
// 	return newDate
// }

// export const myDateToDateObject = (myDateN: number) => {

// 	const cloneMyDateN = clone(myDateN)

// 	const myDate = myDateToDate(cloneMyDateN)
// 	return {
// 		day: myDate.getDate(),
// 		month: myDate.getMonth() + 1,
// 		year: myDate.getFullYear(),
// 		date: myDate,
// 	}
// }

// export const retornaData = (data: number) => {

// 	const cloneData = clone(data)

// 	let now = cloneData.toString()

// 	now = now.substr(6, 2) + now.substr(4, 2) + now.substr(0, 4)

// 	return now
// }
// // 20200617161128
// // 20200420
// export const toMinhaData = (data: string) => {

// 	let cloneData = clone(data)

// 	// 20/04/2020 => 20200420
// 	cloneData = cloneData.replaceAll('/', '')
// 	cloneData = `${cloneData.substr(4, 8) + cloneData.substr(2, 4) + cloneData.substr(0, 2)}000000`
// 	const now = Number(cloneData)
// 	return now
// }

// export const escreveData = (data = '000000') => {

// 	const cloneData = clone(data)

// 	let now = cloneData.toString()

// 	// now = `${now.substr(6, 2)}/${now.substr(4, 2)}/${now.substr(0, 4)}`
// 	now = `${now.substr(6, 8)}/${now.substr(4, 6)}/${now.substr(0, 4)}`

// 	return now
// }

// export const escreveHora = (data = '00000000000000') => {

// 	const cloneData = clone(data)

// 	let now = cloneData.toString()

// 	now = `${now.substr(8, 2)}:${now.substr(10, 2)}:${now.substr(12, 2)}`

// 	return now
// }
// /**
//  *
//  * @param {*} date:Date
//  * @param {*} short:boolean
//  */
// export const getMonthPT = (date: Date, short = false) => {

// 	const cloneDate = clone(date)
// 	const cloneShort = clone(short)

// 	const monthNames = [
// 		'Janeiro',
// 		'Fevereiro',
// 		'Março',
// 		'Abril',
// 		'Maio',
// 		'Junho',
// 		'Julho',
// 		'Agosto',
// 		'Setembro',
// 		'Outubro',
// 		'Novembro',
// 		'Dezembro',
// 	]
// 	const month = cloneDate.getMonth() + 1
// 	let name = monthNames[cloneDate.getMonth()]
// 	name = cloneShort ? name.slice(0, 3) : name
// 	return { month, name }
// }

// export const dateToBrFormat = (date: Date | 0) => {

// 	const cloneDate = clone(date)
// 	return cloneDate ? cloneDate.toLocaleDateString('pt-BR') : ''

// }


// export const dateToBrTimeFormat = (date: Date, seconds = true) => {

// 	const cloneDate = clone(date)
// 	const cloneSeconds = clone(seconds)

// 	const d = cloneDate ? cloneDate.toLocaleTimeString('pt-BR') : ''
// 	return cloneSeconds ? d : d.slice(0, -3)
// }

// export const dateToDbFormat = (date: Date) => {
// 	const cloneDate = clone(date)
// 	return cloneDate ? cloneDate.toISOString().slice(0, 10) : ''

// }

// export const dateToFirebase = (date: Date) => {
// 	const cloneDate = clone(date)
// 	return helpersDate.convertAllToTimestamp(cloneDate)

// }


// /**
//  *
//  * @param {*} date string 12/23/5678
//  * @returns 56782312
//  */
// export const dateToNumber = (date: string) => {
// 	const cloneDate = clone(date)
// 	return cloneDate.split('/').reverse().join('')
// }

// /**
//  *
//  * @param {*} date string 31/07/2022
//  * @returns Sun Jul 31 2022 00:00:00 GMT-0300 (Brasilia Standard Time)
//  */
// export const formatedDateToDate = (date: string) => {
// 	const cloneDate = clone(date)
// 	return myDateToDate(Number(dateToNumber(cloneDate)))

// }

// /**
//  *
//  * @param {*} date string 9999-99-99
//  * @returns 99/99/9999
//  */
// export const internationalToBrDate = (date: string) => {
// 	const cloneDate = clone(date)
// 	return cloneDate.split('-').reverse().join('/')
// }

// /**
//  * Retorna a quantidade de dias que se passaram dado uma data
//  * @param {*} date
//  * @returns
//  */
// export const dateDifferenceInDays = (date: Date) => {
// 	const cloneDate = clone(date)
// 	return Math.floor((new Date().getTime() - cloneDate.getTime()) / (1000 * 3600 * 24))
// }

