/**
 * A função `isOffline` verifica se um objeto teaser está offline com base em seu
 * Propriedade `onLine`.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido no módulo `teaser`. Provavelmente contém
 * informações sobre um elemento teaser, como título, imagem, descrição e
 * status on-line. A função `isOffline` leva um `te
 * @returns A função `isOffline` recebe um objeto `teaser` do tipo `TypeTeaser`,
 * clona-o usando a função `clone` e depois retorna a negação do
 * Propriedade `onLine` do objeto `teaser` clonado. Então, a função retorna `true`
 * se a propriedade `onLine` do objeto `teaser` clonado for `false`, indicando
 * que
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const isOffline = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return !cloneTeaser.onLine

}
