import { clone } from '../../Utils/clone'
import { TypeTool } from '../types/tool'

export const modelTool = (obj: Partial<TypeTool> = {}): TypeTool => {
	const cloneObj = clone(obj)
	return ({
		starkVideo: {
			idYouTube: cloneObj?.starkVideo?.idYouTube || '',
			description: cloneObj?.starkVideo?.description || '',
			title: cloneObj?.starkVideo?.title || '',
			urlActionButton: cloneObj?.starkVideo?.urlActionButton || '',
		},
		panorama: {
			title: cloneObj?.panorama?.title || '',
			description: cloneObj?.panorama?.description || '',
			updatedAt: cloneObj?.panorama?.updatedAt || 0,
		}
	})
}
