import { SubstatusOfActiveCostumerKeys } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { myDateToDate } from '../../Utils/minhaDataNew'
import { TypeNotification } from '../types/notification'
import { helpersDate } from '../../Utils/helpersDate'
import { clone } from '../../Utils/clone'
import { NOTIFICATIONS_TEMPLATE } from '../../../Constants/NOTIFICATIONS_TEMPLATE'

export const modelNotification = (obj?: Partial<TypeNotification>): TypeNotification => {

	const cloneObj = clone(obj)

	return ({
		_id: cloneObj?._id || '',
		id: cloneObj?.id || '',
		date: cloneObj?.date || '',
		message: cloneObj?.message || '',
		isRead: cloneObj?.isRead || false,

		//não mapeados
		uid: cloneObj?.uid || '',
		icon: cloneObj?.icon || '',
		user: cloneObj?.user || '',
		matchId: cloneObj?.matchId || '',
		url: cloneObj?.url || '',
		deleted: cloneObj?.deleted || false,

		// novos
		createdAt: cloneObj?.createdAt ?
			(cloneObj?.createdAt instanceof Date ? cloneObj?.createdAt : (helpersDate.convertAllToDate(cloneObj?.createdAt) || new Date())) :
			(cloneObj?.date ? myDateToDate(cloneObj?.date) : (helpersDate.convertAllToTimestamp(new Date()) || new Date())),

		updatedAt: cloneObj?.updatedAt ?
			(cloneObj?.updatedAt instanceof Date ? cloneObj?.updatedAt :
				(helpersDate.convertAllToDate(cloneObj?.updatedAt) || new Date())) : (cloneObj?.date ? myDateToDate(cloneObj?.date) : (helpersDate.convertAllToTimestamp(new Date()) || new Date())),

		institution: {
			id: cloneObj?.institution?.id || '',
			name: cloneObj?.institution?.name || '',
			urlLogo: cloneObj?.institution?.urlLogo || '',
			shortDescription: cloneObj?.institution?.shortDescription || '',
			profile: cloneObj?.institution?.profile || '',
			tags: cloneObj?.institution?.tags || []
		},

		teses: cloneObj?.teses || [],

		createdBy: {
			id: cloneObj?.createdBy?.id || '',
			name: cloneObj?.createdBy?.name || '',
			type: cloneObj?.createdBy?.type || '',
			fromOffice: cloneObj?.createdBy?.fromOffice || '',
			urlLogo: cloneObj?.createdBy?.urlLogo || ''
		},

		origination: {
			id: cloneObj?.origination?.id || '',
			name: cloneObj?.origination?.name || '',
			fantasyName: cloneObj?.origination?.fantasyName || '',
			urlLogo: cloneObj?.origination?.urlLogo || '',
			uid: cloneObj?.origination?.uid || ''
		},

		analystEmail: cloneObj?.analystEmail || '',
		stage: cloneObj?.stage || '' as SubstatusOfActiveCostumerKeys,
		showCustomer: cloneObj?.showCustomer || false,
		uids: cloneObj?.uids || [],
		unReadBy: cloneObj?.unReadBy || {},
		tese: cloneObj?.tese || {
			id: ''
		},
		match: cloneObj?.match || {
			id: '',
			typeOperation: [] as TYPE_OPERATION[]
		},
		readBy: cloneObj?.readBy || [],
		forOnlyCorporative: false,

		dateRead: cloneObj?.dateRead || '',
		customerUid: cloneObj?.customerUid || '',
		forAdm: cloneObj?.forAdm || false,
		institutionId: cloneObj?.institutionId || '',
		type: cloneObj?.type || '' as keyof typeof NOTIFICATIONS_TEMPLATE,
		institutions: cloneObj?.institutions || [],
		uiPermissions: cloneObj?.uiPermissions || []

	})

}
