import styled from 'styled-components'

export interface IProps {
	size?: number;
	color?: string;
}

/** @deprecated */
export const IconBase = styled.svg.attrs((props: IProps) => ({
	version: '1.1',
	xmlns: 'http://www.w3.org/2000/svg',
	xmlnsXlink: 'http://www.w3.org/1999/xlink',
	size: props.size,
	color: props.color
}))`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  fill: currentColor;
  color: ${props => props.color};

  *{
    fill: ${props => props.color};
  }
`

IconBase.defaultProps = {
	size: 32,
	color: '#000000'
}
