import { useContextAuth } from 'App/ContextAuth'
import { useContextNotificationWidget } from 'App/ContextNotificationWidget'
import { useContextUnReadNotifications } from 'App/ContextUnReadNotifications'
import { Stark } from 'Components/Layout/FooterMenu/stark'
import { LayoutMenuMobile } from 'Components/Layout/LayoutMenuMobile/LayoutMenuMobile'
import { NotificationBell } from 'Components/NotificationBell'
import { Segment } from 'Components/Segment'
import { fnUser } from 'Functions/User'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useTour1 } from 'Hooks/useTour1'
import React, { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { mergeClassName } from '../../../Functions/Utils/mergeClassName'
import { useContextAssistantChat } from '../../../App/ContextAssistantChat'
import { UiButton } from '../../../ComponentsUi/UiButton'

export const LayoutTopBar = (props: { path?: string[], children?: ReactNode, menu?: boolean, profile?: TypeUser, className?: string }) => {
	const { globalAuth } = useContextAuth()
	const profile = globalAuth?.profile
	const { unReadNotifications } = useContextUnReadNotifications()
	const {
		// unReadNotifications,
		setOpenNotification,
	} = useContextNotificationWidget()

	const { setOpenChat } = useContextAssistantChat()

	const { driverObj, openTour, setOpenTour } = useTour1()

	if (openTour) driverObj ? driverObj.drive() : null

	return (
		<div className={mergeClassName('tw-fixed tw-bg-white tw-z-50 tw-w-full lg:tw-w-[calc(100%-198px)]', props.className)}>
			<div className='tw-border-x-0 tw-flex tw-justify-between tw-items-center tw-border-t-0 tw-border-b tw-border-solid tw-border-neutral-100 tw-py-3 tw-px-3 md:tw-px-8 tw-h-[53px]'>
				<div className='tw-hidden md:tw-flex tw-justify-between tw-items-center tw-gap-2'>
					<i className='material-icons tw-text-sm tw-text-neutral-300'>home</i>
					{
						props?.path?.map((item, index) => (
							<React.Fragment key={item}>
								<div className='tw-text-xs tw-text-neutral-300'>/</div>
								<div className='tw-text-xs tw-text-neutral-300'>{item}</div>
							</React.Fragment>
						))
					}
				</div>

				{/* mobile */}
				{
					props?.path?.map((item, index) => (
						<div key={item} className='md:tw-hidden tw-text-base tw-font-normal	'>
							{item}
						</div>
					))
				}


				<div className='tw-flex tw-justify-end tw-gap-4 tw-items-center'>
					{props.children}

					<Link to={'/perfil'} className='tw-text-neutral-600 tw-hidden md:tw-block'>
						{profile?.ulrimg ? <img src={profile?.ulrimg} className='tw-w-8 tw-h-8 tw-rounded-full' /> : <i className='material-icons tw-text-3xl'>account_circle</i>}
					</Link>

					{(window.location.pathname === '/' || window.location.pathname === '/painel/') && fnUser.isAgent(profile) &&
						<Segment name='button-tour'>
							<i className='material-icons-outlined tw-text-neutral-500 tw-cursor-pointer cy-button-tour' onClick={() => setOpenTour(true)}>
								book
							</i>
						</Segment>
					}
					<Segment name='notification-bell' >
						<div className='tw-cursor-pointer tw-z-0' onClick={() => setOpenNotification(e => !e)}>
							<NotificationBell small notifications={Object.keys(unReadNotifications).length}>
								<i className='material-icons-outlined tw-text-3xl tw-text-neutral-500'>notifications</i>
							</NotificationBell>
						</div>
					</Segment>

					<Segment name='assistant-ai' >
						<div className='tw-cursor-pointer tw-z-0' onClick={() => setOpenChat(e => !e)}>
							<i className='material-icons-outlined  tw-text-3xl tw-text-neutral-500'>auto_awesome</i>
						</div>
					</Segment>
					<LayoutMenuMobile />

				</div>
			</div>



		</div>
	)
}
