import { getGovernanceScore } from '../../getGovernanceScore'
import { TypeScoreProps } from '../types/TypeScoreProps'

export const GEN_PERCENT_OF_COVERAGE_INSURE = (props: TypeScoreProps, intervalos: 1 | 2 | 3 | 4) => {
	if (props.operation === 'projectFinance') {
		if (intervalos === 1) return 1.3
		if (intervalos === 2) return 1.5
		if (intervalos === 3) return 1.8
		if (intervalos === 4) return 2.0
		return 1.3
	}
	if (props.operation === 'corporateFinance') {
		if (intervalos === 1) { // primeira pontuação
			if (getGovernanceScore(props.teaser) === 'A') return 9999999999 // garantia infinita --- aqui eu quero que nunca seja possível conseguir os pontos
			if (getGovernanceScore(props.teaser) === 'B') return 1.5 // 150% da garantia --- se for B, eu quero que seja 150% do valor da transação
			if (getGovernanceScore(props.teaser) === 'C') return 1.8 // 180% da garantia --- se for C, eu quero que seja 180% do valor da transação
			return 1
		}
		if (intervalos === 2) { // segunda pontuação
			if (getGovernanceScore(props.teaser) === 'A') return 1.3 // 130% da garantia
			if (getGovernanceScore(props.teaser) === 'B') return 1.6 // 160% da garantia
			if (getGovernanceScore(props.teaser) === 'C') return 2.0 // 200% da garantia
			return 1.5
		}
		if (intervalos === 3) { // terceira pontuação
			if (getGovernanceScore(props.teaser) === 'A') return 1.6 // 160% da garantia
			if (getGovernanceScore(props.teaser) === 'B') return 2 // 200% da garantia
			if (getGovernanceScore(props.teaser) === 'C') return 9999999999 // garantia infinita --- aqui eu quero que nunca seja possível conseguir os pontos
			return 2
		}
	}
	return 1
}
