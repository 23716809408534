import { TypeInstitution } from 'Functions/Institution/types/institution'
import { TypeTese } from 'Functions/Tese/types/tese'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeSendTeseToBdas = {
	tese: TypeTese,
	institution: TypeInstitution,
	image: string,
	setor: string,
	subsetor: string,

}


type SendTeseToBdasResponse = {
	status: number
}

export const _sendTeseToBdas = (input: TypeSendTeseToBdas): Promise<{ data: SendTeseToBdasResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'sendTeseToBdas', input })
