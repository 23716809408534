import classNames from 'Functions/Utils/classNames'
import React from 'react'

export interface IUiBarProgress extends React.ComponentProps<'div'> {
	size?: 'small' | 'medium' | 'large'
	percentValue?: number
	percentTotal?: number
	label?: string;
	showPercent?: boolean;
}

const variantsSize = {
	'small': 'tw-rounded-3xl tw-h-1.5',
	'medium': 'tw-rounded-3xl tw-h-2',
	'large': 'tw-rounded-3xl tw-h-3'
}

export const UiBarProgress = ({ size = 'medium', percentValue = 0, percentTotal = 100, label, showPercent, ...props }: IUiBarProgress) =>
	<div className='tw-w-full'>
		{label && <div className='tw-flex tw-justify-between'>
			<p className='tw-mb-1 tw-text-sm tw-font-semibold'>{label}</p>
			{showPercent && <p className='tw-mb-1 tw-text-sm tw-font-semibold'>{percentValue}%</p>}
		</div>}
		<div className={classNames('tw-flex tw-w-full tw-bg-neutral-200', variantsSize[size])} >
			<div style={{ width: `${(percentValue / percentTotal) * 100}%` }} className={classNames('tw-transition-all tw-duration-1000 tw-ease-linear', props.className || '', 'tw-bg-blue-500', variantsSize[size])} />
		</div>
	</div >
