import { TypeTeaser } from '../types/teaser'
import { TypeUserGenerics } from '../../User/types/userGenerics'
import { getUidAuth } from './getUidAuth'
/**
 * Ordena um array de teasers pelo nome do originador
 * @param teasers
 * @param order
 * @returns  TypeTeaser[]
 */
export const sortByOriginator = ({ teasers, order, users }: { teasers: TypeTeaser[], order: 'asc' | 'desc', users: TypeUserGenerics }): TypeTeaser[] =>
	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
		const isAsc = order === 'asc'
		const nameA = users?.[getUidAuth(teaserA)]?.nome || ''
		const nameB = users?.[getUidAuth(teaserB)]?.nome || ''

		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})
