import { getAllTeses, getInstitution, setTeseFall } from './methods/getAllTeses'
import { getIdsTeses } from './methods/getIdsTeses'
import { toggleMatchFirst } from './methods/toggleMatchFirst'
import { create } from './methods/create'
import { isCredit } from './methods/isCredit'
import { addDocument } from './methods/addDocument'
import { removeDocument } from './methods/removeDocument'
import { updateDocument } from './methods/updateDocument'
import { removeDocumentFile } from './methods/removeDocumentFile'
import { model } from './methods/model'
import { status } from './methods/status'
import { subStatus } from './methods/subStatus'
import { matchMakingStatus } from './methods/matchMakingStatus'
import { setMatch } from './methods/setMatch'
import { toDelete } from './methods/toDelete'
import { toLongList } from './methods/toLongList'
import { toPreMatch } from './methods/toPreMatch'
import { toPreMatchDecline } from './methods/toPreMatchDecline'
import { toMatchmaking } from './methods/toMatchmaking'
import { toMatchmakingDecline } from './methods/toMatchmakingDecline'
import { toPreparation } from './methods/toPreparation'
import { toPreparationDecline } from './methods/toPreparationDecline'
import { toOfferNegotiation } from './methods/toOfferNegotiation'
import { toOfferNegotiationDecline } from './methods/toOfferNegotiationDecline'
import { toDiligence } from './methods/toDiligence'
import { toDiligenceDecline } from './methods/toDiligenceDecline'
import { toDefinitiveContracts } from './methods/toDefinitiveContracts'
import { toDefinitiveContractsDecline } from './methods/toDefinitiveContractsDecline'
import { toCompletedTransaction } from './methods/toCompletedTransaction'
import { inExcluidos } from './methods/inExcluidos'
import { inMatchRejectByCustomer } from './methods/inMatchRejectByCustomer'
import { inLongList } from './methods/inLongList'
import { inPreMatch } from './methods/inPreMatch'
import { inPreMatchDeclined } from './methods/inPreMatchDeclined'
import { inMatchMaking } from './methods/inMatchMaking'
import { inMatchMakingDeclined } from './methods/inMatchMakingDeclined'
import { inMatch } from './methods/inMatch'
import { inMatchDeclined } from './methods/inMatchDeclined'
import { inPreparation } from './methods/inPreparation'
import { inPreparationDeclined } from './methods/inPreparationDeclined'
import { inOfferNegotiations } from './methods/inOfferNegotiations'
import { inOfferNegotiationsDeclined } from './methods/inOfferNegotiationsDeclined'
import { inDiligence } from './methods/inDiligence'
import { inDiligenceDeclined } from './methods/inDiligenceDeclined'
import { inDefinitiveContracts } from './methods/inDefinitiveContracts'
import { inDefinitiveContractsDeclined } from './methods/inDefinitiveContractsDeclined'
import { matchFinished } from './methods/matchFinished'
import { relationMatchMakingStatus } from './methods/relation/relationMatchMakingStatus'
import { relationStatus } from './methods/relation/relationStatus'
import { relationMatchStatus } from './methods/relation/relationMatchStatus'
import { modelMatch } from './model/modelMatch'
import { modelManualMatch } from './methods/modelManualMatch'
import { getStatusKanbanAbsolute } from './methods/getStatusKanban'
import { getStatusKanbanNegative } from './methods/getStatusKanban'
import { showLastDate } from './methods/showLastDate'

/**
 * @description
 * Planilha para entender os status do match https://docs.google.com/spreadsheets/d/1L9knEaPh0nKicKvbZ1ftUvlWbA7i3fyulo32F3jEMAQ/edit#gid=1403120019
*/

export const fnMatch = {
	modelManualMatch,
	modelMatch,
	model,
	create,
	setTeseFall,
	getAllTeses,
	getInstitution,
	toggleMatchFirst,
	getIdsTeses,

	status,
	subStatus,
	matchMakingStatus,

	// in
	inMatchRejectByCustomer,
	inExcluidos,
	inLongList,
	inPreMatch,
	inPreMatchDeclined,
	inMatchMaking,
	inMatchMakingDeclined,
	inMatch,
	inMatchDeclined,
	inPreparation,
	inPreparationDeclined,
	inOfferNegotiations,
	inOfferNegotiationsDeclined,
	inDiligence,
	inDiligenceDeclined,
	inDefinitiveContracts,
	inDefinitiveContractsDeclined,
	// inCompletedTransaction: (match: TypeMatch) => match.status === STATUS_MATCH.completed.value && match.matchMakingStatus === STATUS_MATCH_MAKING.completo.value,
	matchFinished,
	setMatch,
	// fn matches
	toDelete,
	toLongList,
	toPreMatch,
	toPreMatchDecline,
	toMatchmaking,
	toMatchmakingDecline,
	toPreparation,
	toPreparationDecline,
	toOfferNegotiation,
	toOfferNegotiationDecline,
	toDiligence,
	toDiligenceDecline,
	toDefinitiveContracts,
	toDefinitiveContractsDecline,
	toCompletedTransaction,
	//is
	isCredit,

	// documents
	addDocument,
	removeDocument,
	updateDocument,
	removeDocumentFile,

	// relations: usado para ler dados de propriedades que possuem código de relacionamento com constantes
	relationMatchMakingStatus,
	relationStatus,
	relationMatchStatus,

	getStatusKanbanAbsolute,
	getStatusKanbanNegative,

	showLastDate,
}
