import { fnUser } from 'Functions/User'
import { TypeUser } from 'Functions/User/types/TypeUser'

// QUANDO CRIAR UMA NOVA COLLECTION, ADICIONAR AQUI QUAL PERFIL DE USUÁRIO PODE ACESSAR POR SERVERLESS (TRUE) OU POR CLOUD FUNCTIONS (FALSE)
export const permissionsGetThisCollection = (collection: string, profile: TypeUser) => {
	if (collection === 'ranking') {
		return true
	}

	if (collection === 'tools') {
		return true
	}

	if (collection === 'teses') {
		return true
	}

	if (collection === 'teasers') {
		if (fnUser.isAdm(profile)) return true
		if (fnUser.isAgent(profile)) return true
		if (fnUser.isMicroBda(profile)) return true
		if (fnUser.isCustomer(profile)) return true
		if (fnUser.isEnterprise(profile)) return true
		if (fnUser.isOffice(profile)) return true
		if (fnUser.isOfficeAnalyst(profile)) return true
		else return false
	}

	if (collection === 'users') {
		if (fnUser.isAdm(profile)) return true
		// if (fnUser.isAgent(profile)) return true
		// if (fnUser.isMicroBda(profile)) return true
		if (fnUser.isCustomer(profile)) return true
		if (fnUser.isEnterprise(profile)) return true
		if (fnUser.isOffice(profile)) return true
		if (fnUser.isOfficeAnalyst(profile)) return true
		else return false
	}

	if (collection === 'institutions') {
		if (fnUser.isAdm(profile)) return true
		else return false
	}

	if (collection === 'matches') {
		if (fnUser.isAdm(profile)) return true
		if (fnUser.isAgent(profile)) return true
		if (fnUser.isMicroBda(profile)) return true
		if (fnUser.isCustomer(profile)) return true
		if (fnUser.isEnterprise(profile)) return true
		if (fnUser.isOffice(profile)) return true
		if (fnUser.isOfficeAnalyst(profile)) return true
		else return false
	}

	if (collection === 'walletLines') {
		if (fnUser.isAdm(profile)) return true
		if (fnUser.isAgent(profile)) return true
		if (fnUser.isEnterprise(profile)) return true
		return false
	}

	if (collection === 'tesesCredit') {
		return true
	}

	if (collection === 'notifications') {
		return true
	}

	if (collection === 'followup') {
		return true
	}
	if (collection === 'darwinFiles') {
		return true
	}

	if (collection === 'sinatraValuations') {
		return true
	}

	if (collection === 'sinatraTransactions') {
		return true
	}

	if (collection === '_viewTeasers001') {
		return true
	}

	if (collection === 'tour') {
		return true
	}

	if (collection === 'profilePosts') {
		return true
	}

	return true
}
