import { differenceInDays, differenceInMilliseconds, differenceInMonths, isValid, parse } from 'date-fns'
import { TypeParameterDate } from '.'
import { clone } from '../clone'
import { convertAllToDate } from './converts'

type TypeBooleanComparison = 'true' | 'false' | 'null'
type TypeNumberComparison = number | null

/**
 * @description Diferença de dias entre uma data e outra
 *
 * @param dateStart - Data inicial
 * @param dateEnd - Data final
 *
 * @example (20230102000000, 20240102000000) -> 365
 * @example (new Date(2024, 5, 10, 0, 0, 0), new Date(2024, 6, 10, 0, 0, 0)) -> 31
*/
export const howManyDays = (dateStart: TypeParameterDate, dateEnd: TypeParameterDate): TypeNumberComparison => {

	const cloneStart = convertAllToDate(clone(dateStart))
	const cloneEnd = convertAllToDate(clone(dateEnd))

	if (!cloneStart) return null
	if (!cloneEnd) return null

	return differenceInDays(cloneEnd, cloneStart)

}

/**
 * @description Diferença de meses entre uma data e outra
 *
 * @param dateStart - Data inicial
 * @param dateEnd - Data final
 *
 * @example (20230102000000, 20240102000000) -> 12
 * @example (new Date(2024, 5, 10, 0, 0, 0), new Date(2024, 6, 10, 0, 0, 0)) -> 1
*/
export const howManyMonths = <T extends TypeParameterDate>(dateStart: T, dateEnd: T): TypeNumberComparison => {

	const cloneStart = convertAllToDate(clone(dateStart))
	const cloneEnd = convertAllToDate(clone(dateEnd))

	if (!cloneStart) return null
	if (!cloneEnd) return null

	return differenceInMonths(cloneEnd, cloneStart)

}

/**
 * @description Função que retorna "true" ou "false" se uma data está entre
 * duas outras datas
 *
 * @param dateStart - Data de comparação inicial
 * @param dateBetween - Data a comparar
 * @param dateEnd - Data de comparação final
 *
 * @example (20230102000000, 20230602000000, 20240102000000) -> true
 * @example (20230102000000, 20240602000000, 20240102000000) -> false
 * @example (new Date(2024, 5, 10, 0, 0, 0), new Date(2024, 5, 12, 0, 0, 0), new Date(2024, 6, 10, 0, 0, 0)) -> true
*/
export const isBetween = <T extends TypeParameterDate>(dateStart: T, dateBetween: T, dateEnd: T): TypeBooleanComparison => {

	const cloneStart = convertAllToDate(clone(dateStart))
	const cloneEnd = convertAllToDate(clone(dateEnd))
	const cloneBetween = convertAllToDate(clone(dateBetween))

	if (!cloneStart) return 'null'
	if (!cloneEnd) return 'null'
	if (!cloneBetween) return 'null'

	const conditionBetweenEnd = differenceInMilliseconds(cloneEnd, cloneBetween)
	const conditionStartBetween = differenceInMilliseconds(cloneBetween, cloneStart)

	return conditionStartBetween > 0 && conditionBetweenEnd > 0 ? 'true' : 'false'

}

/**
 * @description Função que retorna "true" ou "false" se a data dateEnd é maior
 * que a dateStart
 *
 * @param dateStart - Data inicial
 * @param dateEnd - Data final
 *
 * @example (20230102000000, 20240102000000) -> true
 * @example (20230102000000, 20230101000000) -> false
 * @example (new Date(2024, 5, 10, 0, 0, 0), new Date(2024, 4, 12, 0, 0, 0)) -> false
*/
export const isBiggerThan = <T extends TypeParameterDate>(dateStart: T, dateEnd: T): TypeBooleanComparison => {

	const cloneStart = convertAllToDate(clone(dateStart))
	const cloneEnd = convertAllToDate(clone(dateEnd))

	if (!cloneStart) return 'null'
	if (!cloneEnd) return 'null'

	const isBigger = differenceInMilliseconds(cloneEnd, cloneStart)

	return isBigger > 0 ? 'true' : 'false'

}

/**
 * @description Função que retorna true ou false se uma data no formato "dd/MM/yyyy"
 * é válida
 *
 * @param date - Data a validar
 *
 * @example "23/10/2001" -> true
 * @example "23/13/2001" -> false
 * @example "30/02/2023" -> false
*/
export const dataStringIsValid = (date: string): TypeBooleanComparison => {

	const cloneValue = clone(date)

	const dataAtual = new Date()

	const data = parse(cloneValue, 'dd/MM/yyyy', dataAtual)

	return isValid(data) ? 'true' : 'false'
}
