/**
 * A função `inStep` determina a etapa de um teaser com base em seu status usando
 * várias funções auxiliares.
 * @param {TypeTeaser} teaser - A função `inStep` pega um objeto `teaser` de
 * digite `TypeTeaser` como parâmetro. Esta função realiza diversas verificações no
 * Objeto `teaser` usando funções diferentes do módulo `fnTeaser` e retorna
 * um valor numérico baseado nas condições atendidas pelo `
 * @returns A função `inStep` recebe um objeto `teaser` do tipo `TypeTeaser` como
 * insira e retorne um valor numérico com base em determinadas condições. O valor de retorno
 * corresponde à etapa ou estágio em que o teaser se encontra.
 * os valores numéricos retornados representam diferentes etapas ou estágios do teaser
 * processo.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { fnTeaser } from '..'

export const inStep = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	if (fnTeaser.isNotPublicado(cloneTeaser)) return 1
	if (fnTeaser.inComite(cloneTeaser)) return 2
	if (fnTeaser.inComercial(cloneTeaser)) return 3
	if (fnTeaser.inStandby(cloneTeaser)) return 3
	if (fnTeaser.inAtivoWithoutMatchmaking(cloneTeaser)) return 4
	if (fnTeaser.inAtivoWitchMatchmaking(cloneTeaser)) return 5
	if (fnTeaser.inAtivoWithPreparation(cloneTeaser)) return 6
	if (fnTeaser.inAtivoWithNegotiation(cloneTeaser)) return 7
	if (fnTeaser.inAtivoWithCompletedTransaction(cloneTeaser)) return 8
	return 0
}
