import { clone } from '../../Utils/clone'
import { deleteFile } from '../../../Services/serviceFiles'

function getPath(path: string): string {
	const pathClone = clone(path)
	let lastChar = pathClone.substring(pathClone.length - 1)
	lastChar = lastChar === '/' ? '' : '/'
	return `${pathClone}${lastChar}`
}
/**
 * Remove o documento de um match
 * @param path o caminho do arquivo
 * @param key a chave do arquivo a ser removido no storage
 * @returns
 */
export const deleteDocumentInStorage = ({ path, key }: { path: string, key: string }) => {

	const clonePath = clone(path)
	const cloneKey = clone(key)

	return deleteFile(`${getPath(clonePath)}${cloneKey}`, async () => { })
}

