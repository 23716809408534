import { TypeTeaser } from '../types/teaser'
/**
 * Ordena um array de teasers pelo nome da empresa
 * @param teasers
 * @param order
 * @returns  TypeTeaser[]
 */
export const sortByAnalyst = ({ teasers, order }: { teasers: TypeTeaser[], order: 'asc' | 'desc' }): TypeTeaser[] =>
	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
		const isAsc = order === 'asc'
		const nameA = teaserA?.analystEmail || ''
		const nameB = teaserB?.analystEmail || ''

		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})


// 		if (!teasers[keyA].analystEmail) {
// 			return 1
// 		}
// 		if (!teasers[keyB].analystEmail) {
// 			return -1
// 		}
// 		if (controller.typeOrder === 'asc') {
// 			if (teasers[keyA].analystEmail && teasers[keyB].analystEmail && teasers[keyA].analystEmail < teasers[keyB].analystEmail) return -1
// 			return 1
// 		}
// 		else {
// 			if (teasers[keyA].analystEmail && teasers[keyB].analystEmail && teasers[keyA].analystEmail > teasers[keyB].analystEmail) return -1
// 			return 1
// 		}
