import { PERMISSIONS_NOTIFICATIONS_GROUP } from './PERMISSIONS_NOTIFICATIONS'

export const TYPE_NOTIFICATIONS_GET = (code: string) => {
	const _code = code as keyof typeof TYPE_NOTIFICATIONS

	if (!TYPE_NOTIFICATIONS[_code]) {
		return (
			{
				// TODO: @Mychaelolvr CRIAR UM TIPO DE NOTIFICAÇÃO DEFAULT
				_id: '',
				label: '',
				msg: '',
				permissions: []
			}
		)
	}

	return TYPE_NOTIFICATIONS[_code]
}

export const TYPE_NOTIFICATIONS = {
	// a: agentes
	a0001: {
		_id: 'a0001',
		label: 'Nova originação adicionada',
		msg: 'Você iniciou uma Originação. Finalize o preenchimento das informações e envie sua Originação para a Stark.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0002: {
		_id: 'a0002',
		label: 'Originação aprovada pelo comitê',
		msg: 'Parabéns, sua Originação foi aprovada para a Fase Comercial. A partir desta data você tem um prazo de 180 dias de exclusividade sobre sua Originação.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0003: {
		_id: 'a0003',
		label: 'Originação publicada',
		msg: 'Sua Originação foi enviada com sucesso. Você receberá uma devolutiva em breve.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0004: {
		_id: 'a0004',
		label: 'Originação rejeitada',
		msg: 'Infelizmente sua Originação não foi aprovada e ficará em Stand-by. A partir desta data você tem um prazo de 180 dias de exclusividade sobre sua Originação.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0005: {
		_id: 'a0005',
		label: 'Contrato assinado pelo cliente',
		msg: 'Parabéns, o Cliente que você originou assinou o Contrato com a Stark.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0006: {
		_id: 'a0006',
		label: 'Solicitar ajustes',
		msg: 'Alguns ajustes foram solicitados na sua Originação. Você tem o prazo de 15 dias para realizar os ajustes, acesse seu e-mail para conferir todos os ajustes.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0007: {
		_id: 'a0007',
		label: 'Analista seleciona o Status matchmaking no projeto',
		msg: 'Parabéns. A Stark iniciou o Matchmaking dessa Originação com os investidores compatíveis',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0008: {
		_id: 'a0008',
		label: 'Analista muda o card do investidor para match',
		msg: 'O Cliente Originado obteve um Match e iniciou tratativas com um Investidor Ativo.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a0009: {
		_id: 'a0009',
		label: 'Analista muda o card do investidor para inativo',
		msg: 'Infelizmente a tratativa do  Cliente Originado com um investidor foi interrompida.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a00010: {
		_id: 'a00010',
		label: 'analista devolve card do investidor para matchmaking na coluna do match (inativo)',
		msg: 'A tratativa do  Cliente Originado com um investidor foi retomada.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a00011: {
		_id: 'a00011',
		label: 'Analista seleciona o Status Negociação de oferta no projeto',
		msg: 'O processo envolvendo esta Originação está em fase de Negociação de Oferta',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a00012: {
		_id: 'a00012',
		label: 'Analista seleciona o Status Diligencia no projeto',
		msg: 'O processo envolvendo esta Originação está em fase de Diligência',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a00013: {
		_id: 'a00013',
		label: 'Analista seleciona o Status contratos definitivos no projeto',
		msg: 'O processo envolvendo esta Originação está em fase de negociação de Contratos Definitivos',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a00014: {
		_id: 'a00014',
		label: 'Analista seleciona o Status transação concluida no projeto',
		msg: 'Parabéns, o processo envolvendo esta Originação foi concluído com sucesso com um investidor!',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0008
	},
	a00015: {
		_id: 'a00015',
		label: 'Analista cria notificação customizada na página de histórico',
		msg: '',
		permissions: []
	},
	a0016: {
		_id: 'a0016',
		label: 'Cliente churnado pela Stark - agente',
		msg: 'Infelizmente não estamos mais prestando serviços para este cliente. Caso precise de mais informações, entre em contato com nosso time de canais.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	a0017: {
		_id: 'a0017',
		label: 'Prestação de serviços retomada pela Stark - agente',
		msg: 'Retomamos a prestação de serviços para este cliente. Caso precise de mais informações, entre em contato com nosso time de canais.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	// b: customer
	b0001: {
		_id: 'b0001',
		label: 'Primeiro login do cliente',
		msg: `Estamos felizes em ter você na Plataforma Stark! Aqui você vai poder fazer a gestão e acompanhar seu projeto. Nossos especialistas estão sempre a disposição para te ajudar 😊

		Vamos começar preenchendo os dados do seu perfil de usuário. Clique aqui para acessá-lo.`,
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0001
	},
	b0002: {
		_id: 'b0002',
		label: 'Cliente aceita/match um investidor',
		msg: 'Você aceitou este investidor. Estamos preparando suas informações para entrar em contato com ele, em breve teremos novidades.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	b0003: {
		_id: 'b0003',
		label: 'Cliente aceitar novos investidores',
		msg: 'Você tem {{NUMBER_OF_INVESTORS}}, aceite para iniciar o processo de distribuição.',
		replacements: {
			numberOfInvestors: 'NUMBER_OF_INVESTORS'
		},
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0001
	},
	b0004: {
		_id: 'b0004',
		label: 'Cliente rejeita/match um investidor',
		msg: 'Você rejeitou este investidor. Clique no botão aceitar para recuperar ele.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0007
	},
	b0005: {
		_id: 'b0005',
		label: 'Analista muda o card do investidor para matchmaking',
		msg: 'Seu Teaser foi enviado para este investidor. A sua oportunidade está em análise.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0007
	},
	b0006: {
		_id: 'b0006',
		label: 'Analista muda o card do investidor para match',
		msg: 'Parabéns, temos um novo Match! 🎉  com este investidor. Agora vamos iniciar a fase de Preparação com ele.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	b0007: {
		_id: 'b0007',
		label: 'Analista muda o Status do card do investidor para declinado',
		msg: 'Este investidor analisou sua oportunidade e infelizmente declinou.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0007
	},
	b0008: {
		_id: 'b0008',
		label: 'Analista muda o Status do card do investidor para inativo',
		msg: 'Sua tratativa com este investidor foi interrompida. O prazo de exclusividade (tail) com esse investidor vai ser de 365 dias contados desta data.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	b0009: {
		_id: 'b0009',
		label: 'Analista devolve card do investidor para matchmaking na coluna do match (inativo)',
		msg: 'Parabéns, sua tratativa com este investidor foi retomada.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	b00010: {
		_id: 'b00010',
		label: 'Analista seleciona o Status Negociação de oferta no projeto',
		msg: 'Parabéns, seu processo com este investidor está em fase de Negociação de Oferta',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0007
	},
	b00011: {
		_id: 'b00011',
		label: 'Analista seleciona o Status Diligência no projeto',
		msg: 'Parabéns, seu processo com este investidor está em fase de Diligência',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0007
	},
	b00012: {
		_id: 'b00012',
		label: 'Analista seleciona o Status contratos definitivos no projeto',
		msg: 'Parabéns, seu processo com este investidor está em fase de negociação de Contratos Definitivos',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0007
	},
	b00013: {
		_id: 'b00013',
		label: 'Analista seleciona o Status transação concluida no projeto',
		msg: 'Parabéns, seu processo com este investidor foi concluído com sucesso!',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	// TODO: CÓDIGOS ERRADOS, 0009 FOI PARA 00010, DEVERIA SER 0010
	b00014: {
		_id: 'b00014',
		label: 'Analista cria notificação customizada na página de histórico',
		msg: '',
		permissions: []
	},
	b0015: {
		_id: 'b0015',
		label: 'Analista, cria um novo entregável',
		msg: 'Temos um novo material disponível para você! Acesse seu Kit Stark para visualizá-lo.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0001
	},
	b0016: {
		_id: 'b0016',
		label: 'Cliente churnado pela Stark - Customer',
		msg: 'Conforme conversado a partir desta data a prestação dos nossos serviços estão interrompidas. O contrato assinado permanecerá válido porém sem gerar quaisquer custos relativos à assinatura mensal (mensalidade), e a prestação de serviços poderá ser retomada dentro dos prazos do contrato.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
	b0017: {
		_id: 'b0017',
		label: 'Prestação de serviços retomada pela Stark - Customer',
		msg: 'Conforme conversado a partir desta data a prestação dos nossos serviços estão retomadas. O contrato assinado permanecerá válido porém sem gerar quaisquer custos relativos à assinatura mensal (mensalidade), e a prestação de serviços poderá ser retomada dentro dos prazos do contrato.',
		permissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0003
	},
} as const

export const TYPE_NOTIFICATIONS_VALUES = Object.values(TYPE_NOTIFICATIONS)
export const TYPE_NOTIFICATIONS_KEYS = Object.keys(TYPE_NOTIFICATIONS)
