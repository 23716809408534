import { isInRange } from '../../../../../Functions/Utils/isInRange'
import { genTotalBudgetFin2 } from '../genCount/genTotalBudgetFin2'
import { genTransactionValue } from '../genCount/genTransactionValue'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreFinancing = (props: TypeScoreProps) => {

	const res: TypeScoreStark['scoreItens'] = []


	const transactionValue = genTransactionValue(props)

	const financeRate = genTotalBudgetFin2(props) / transactionValue

	if (isInRange(1, '<=', financeRate))
		res.push({ // adiciona 0
			'pontosMax': 2,
			'pontos': 0,
			'pontosCor': 'green',

			'setor': 'Nota de financiamento',

			'item': 'Nota de financiamento',
			'itemDescrição': 'Taxa de finance maior ou igual do que 1',

			'metaDados': '',
			devolutiva: ''
		})

	if (isInRange(0.9, '<=', financeRate, '<', 1))
		res.push({ // adiciona 0.5
			'pontosMax': 2,
			'pontos': 0.5,
			'pontosCor': 'green',
			'setor': 'Nota de financiamento',
			'item': 'Nota de financiamento',
			'itemDescrição': 'Taxa de finance maior ou igual do que 0.9 e menor do que 1',

			'metaDados': '',
			devolutiva: ''
		})

	if (isInRange(0.8, '<=', financeRate, '<', 0.9))
		res.push({ // adiciona 1
			'pontosMax': 2,
			'pontos': 1,
			'pontosCor': 'green',
			'setor': 'Nota de financiamento',
			'item': 'Nota de financiamento',
			'itemDescrição': 'Taxa de finance maior ou igual do que 0.8 e menor do que 0.9',

			'metaDados': '',
			devolutiva: ''
		})

	if (isInRange(0.7, '<=', financeRate, '<', 0.8))
		res.push({ // adiciona 1.5
			'pontosMax': 2,
			'pontos': 1.5,
			'pontosCor': 'green',
			'setor': 'Nota de financiamento',
			'item': 'Nota de financiamento',
			'itemDescrição': 'Taxa de finance maior ou igual do que 0.7 e menor do que 0.8',

			'metaDados': '',
			devolutiva: ''
		})

	if (isInRange(0.6, '<=', financeRate, '<', 0.7))
		res.push({ // adiciona 2
			'pontosMax': 2,
			'pontos': 2,
			'pontosCor': 'green',
			'setor': 'Nota de financiamento',
			'item': 'Nota de financiamento',
			'itemDescrição': 'Taxa de finance maior ou igual do que 0.6 e menor do que 0.7',

			'metaDados': '',
			devolutiva: ''
		})

	if (isInRange(financeRate, '<', 0.6))
		res.push({ // adiciona 0
			'pontosMax': 2,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': 'Nota de financiamento',
			'item': 'Nota de financiamento',
			'itemDescrição': 'Taxa de finance menor do que 0.6',

			'metaDados': '',
			devolutiva: ''
		})

	return res
}
