/**
 * A função `getStatusCustomerLabel` determina o rótulo de status de um cliente
 *com base em certas condições em um determinado objeto teaser.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente é um objeto contendo informações sobre um teaser. Esta função
 * `getStatusCustomerLabel` pega esse objeto teaser como entrada e determina o
 * etiqueta de status do cliente com base em certas condições definidas no
 *função.
 * @returns A função `getStatusCustomerLabel` retorna o rótulo correspondente
 * ao status do cliente com base no objeto `teaser` fornecido. O
 * A função primeiro clona o objeto `teaser` usando a função `clone`. Então,
 * verifica várias condições usando funções auxiliares como `isNotPublicado`,
 * `inComite`, `inComercial`, `inStandby` e `inAtivo
 */
//CRIADO POR IA

import { STATUS_CUSTOMER } from '../../../Constants/STATUS_CUSTOMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'
import { inComercial } from './inComercial'
import { inComite } from './inComite'
import { inStandby } from './inStandby'
import { isNotPublicado } from './isNotPublicado'

export const getStatusCustomerLabel = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	if (isNotPublicado(cloneTeaser)) return STATUS_CUSTOMER.notPublish.label
	if (inComite(cloneTeaser)) return STATUS_CUSTOMER.comite.label
	if (inComercial(cloneTeaser)) return STATUS_CUSTOMER.comercial.label
	if (inStandby(cloneTeaser)) return STATUS_CUSTOMER.standby.label
	if (inAtivo(cloneTeaser)) return STATUS_CUSTOMER.ativo.label
}
