/**
 * Esta função verifica se um teaser está ativo com uma transação concluída.
 * @param {TypeTeaser} teaser – O trecho de código que você forneceu é um TypeScript
 * função que recebe um objeto `teaser` do tipo `TypeTeaser` como parâmetro. O
 * A função cria um clone do objeto `teaser` usando a função `clone` de
 * o módulo `Utils`, então verifica se o `te clonado
 * @returns A função `inAtivoWithCompletedTransaction` está retornando um valor booleano
 * valor. Verifica se o teaser passado como argumento atende às condições de
 * ser "inAtivo" e ter um substatus específico de cliente ativo com
 * uma transação concluída. A função usa a função `clone` para criar uma cópia
 * do objeto teaser e verifica se o substatus do teaser clonado corresponde
 * o
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'

export const inAtivoWithCompletedTransaction = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const list:
		(keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER)[] = [
			SUB_STATUS_OF_ACTIVE_COSTUMER.s00600.code
		]

	return inAtivo(cloneTeaser) &&
		list.includes(cloneTeaser.subStatusOfActiveCostumer)
}
