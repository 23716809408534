import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Delete = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M4 9.59711C4 8.86364 4.65263 8.26904 5.4577 8.26904H26.5423C27.3474 8.26904 28 8.86364 28 9.59711C28 10.3306 27.3474 10.9252 26.5423 10.9252H5.4577C4.65263 10.9252 4 10.3306 4 9.59711Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M13.6573 4H18.3508C19.3549 4.00504 20.3163 4.3707 21.0263 5.01757C21.7363 5.66444 22.1377 6.54034 22.1432 7.45513L22.1432 7.46245L22.1432 8.26877H24.1996C25.0047 8.26877 25.6573 8.86337 25.6573 9.59684V24.5449C25.6518 25.4597 25.2504 26.3356 24.5404 26.9824C23.8304 27.6293 22.869 27.9949 21.8649 28L21.8569 28H10.1352C9.13108 27.995 8.16968 27.6293 7.45967 26.9824C6.74966 26.3356 6.34833 25.4597 6.3428 24.5449L6.34277 24.5375V9.59684C6.34277 8.86337 6.99541 8.26877 7.80047 8.26877H9.85687V7.45513C9.86241 6.54034 10.2638 5.66444 10.9738 5.01757C11.6838 4.3707 12.6452 4.00506 13.6493 4.00002L13.6573 4ZM9.25817 10.9249V24.533C9.26027 24.7474 9.35469 24.9526 9.52117 25.1043C9.68766 25.2559 9.91284 25.342 10.1482 25.3439H21.8519C22.0872 25.342 22.3124 25.256 22.4789 25.1043C22.6454 24.9526 22.7398 24.7474 22.7419 24.533V10.9249H9.25817ZM19.2278 8.26877H12.7723V7.467C12.7744 7.25257 12.8688 7.04742 13.0353 6.89573C13.2018 6.74406 13.4269 6.65804 13.6623 6.65613H18.3378C18.5732 6.65804 18.7983 6.74406 18.9648 6.89573C19.1313 7.04739 19.2257 7.25251 19.2278 7.4669V8.26877Z' fill='#272827' />
	</Svg>
)
