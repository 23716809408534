import { GUARANTEES, GUARANTEES_DETAILS } from '../../../../Constants/GUARANTEES'
import { ItemGuranteeProps, TypeTeaser } from '../../types/teaser'
import { TypeTeseCredit } from '../../../TeseCredit/types/teseCredit'
import { coinToNumber } from '../../../Utils/moeda'
import { getAllGuaranteesInArray } from './getAllGuaranteesInArray'
import { Financing, FinancingItemProps } from '../../../../Constants/FINANCING'
import { clone } from '../../../Utils/clone'

/**
 * RULES
 * garantias marcadas na tese de crédito estão presentes no teaser?
 * das garantias desta intersecção, a soma dos valores é maior ou igual ao valor da operação?
 * pegar apenas se o investidor aceita ou não tal tipo de garantia
 * retornar um objeto com um valor booleano e um array com as garantias foram aceitas e seus valores
 * (Tese ) veiculos == outros (teaser)
 *
 * O financiamneto de obras entra como uma garantia, vindo do teaser.financing
*/

/**
 * Código para teste do método, basta adiconar ele em alguma página e checar pelo LOG1
useEffect(() => {
		const load = async () => {
			const teaserRes = await db5.teasers.get({ id: '13jnQ1TAsCogfJ81KtPI', profile })
			const teseCreditRes = await db5.tesesCredit.get({ profile, id: '0wxQ6xciaWO0wd07Bqsy' })
			const teaser = teaserRes.doc as TypeTeaser
			const teseCredit = teseCreditRes.doc as TypeTeseCredit
			const check = fnTeaser.getAllGuaranteesCover({ teaser, teseCredit })
			console.log('===========LOG1=============')
			console.log('========================')
			console.log(check)
		}
		load()
}, [])
 */

type AcceptedType = {
	guarantee: string
	total: number
}[]

const MINIMUM_VALUE_OF_A_OPERATION = 20_000_000

type Props = {
	teaser: TypeTeaser
	teseCredit: TypeTeseCredit
}

type PropsReturn = {
	guarantees: AcceptedType
	approved: boolean
	message: string
}

/**
 *
 * @param key: string
 * @param teseCredit: TypeTeseCredit
 * @returns {guarantees, approved} as PropsReturn
 */
export const getAllGuaranteesCover = ({ teaser, teseCredit }: Props): PropsReturn => {

	const cloneTeaser = clone(teaser)
	const cloneTeseCredit = clone(teseCredit)


	const check = checkNoGuarantees(cloneTeaser)
	if (check) return check

	const allGuarantees: (ItemGuranteeProps | FinancingItemProps)[] = getAllGuaranteesInArray({ teaser: cloneTeaser })
	const acceptedGuarantees: AcceptedType = []// array com as garantias não aceitas
	let amountGuarantees = 0 // soma total do valor de todas as garantias
	let hasGuarantees = false // checa se existe ao menos uma garantia para passar na validação

	amountGuarantees = allGuarantees.reduce((accumulator, guarantee: ItemGuranteeProps | FinancingItemProps) => {
		let multiplicator = 1
		const { mainType, type } = guarantee

		// retorna o multiplicador da garantia
		if (type && mainType && mainType !== Financing.fin1 && GUARANTEES_DETAILS[mainType].subType[type])
			multiplicator = GUARANTEES_DETAILS[mainType].subType[type].multiplicator(teaser)

		// a propriedade value existe apenas nas garantias, em financiamento não existe esse valor
		const value = 'value' in guarantee ?
			((Number(coinToNumber(guarantee.value || '0')) || 0)) :
			(+(Number(coinToNumber(guarantee.vgv)) || '0') + +(Number(coinToNumber(guarantee.saldoCarteiraRecebiveis)) || 0))

		if (checkHasGuarantee(String(mainType), cloneTeseCredit)) {
			const total = (value * multiplicator)

			hasGuarantees = true // indica que pelo menos uma garantia foi aceita
			amountGuarantees += total // realiza a soma do total das garantias
			const acceptedGuarantee = acceptedGuarantees.find(acceptedGuarantee => acceptedGuarantee.guarantee === String(mainType))
			if (!acceptedGuarantee) {
				acceptedGuarantees.push({
					guarantee: String(mainType),
					total
				})
			}
			else {
				acceptedGuarantee.total += total
			}
			return accumulator + total
		}
		return accumulator
	}, 0)

	const checkAmount = amountGuarantees >= MINIMUM_VALUE_OF_A_OPERATION
	const approved = hasGuarantees && checkAmount
	const message = getMessage({ hasGuarantees, checkAmount })

	const data = {
		approved,
		guarantees: acceptedGuarantees,
		message,
		amountGuarantees
	}
	return data
}

const getMessage = ({ hasGuarantees, checkAmount }: { hasGuarantees: boolean, checkAmount: boolean }) => {
	if (!hasGuarantees)
		return 'Nenhuma garantia compatível'
	if (!checkAmount)
		return 'A soma das garantias é menor que o valor mínimo de uma operação'
	return 'Transação aprovada'
}

const checkHasGuarantee = (key: string, teseCredit: TypeTeseCredit): boolean => {
	const {
		garantiaImovel,
		garantiaVeiculos,
		garantiaBoletosDuplicatasContratos,
		garantiaRecebiveisImobiliarios,
		garantiaFiancaSafra
	} = teseCredit

	return (key === GUARANTEES.guarantee1 && garantiaImovel) ||
		(key === GUARANTEES.guarantee6 && garantiaVeiculos) ||
		(key === GUARANTEES.guarantee2 && garantiaBoletosDuplicatasContratos) ||
		(key === GUARANTEES.guarantee3 && garantiaRecebiveisImobiliarios) ||
		(key === GUARANTEES.guarantee4 && garantiaFiancaSafra) ||
		key === Financing.fin1
}

/**
 *
 * @param teaser Checa se existem garatias válidas
 * @returns false (se possui garantias válidas) {} (se não existir garantias válidas)
 */
const checkNoGuarantees = (teaser: TypeTeaser) => {
	const { noGuarantees, financing } = teaser
	const message = 'A originação não possui garantias'

	// O financimento de obras entra como uma garantia, caso ele não exista, ou, noGuarantees
	// esteja marcado, consideramos que não existem garantias
	if (noGuarantees && !financing?.fin1) {
		return {
			approved: false,
			guarantees: [],
			message
		}
	}
	return false
}
