import * as Tokens from 'Components/CardDS/src/Tokens'

export const RadioTheme = {
	label: {
		font: Tokens.FONT_MAIN,
		size: Tokens.FONT_SIZE_SM,
		weight: Tokens.FONT_REGULAR,
		['default']: {
			color: Tokens.COLOR_NEUTRAL_900
		},
		['disabled']: {
			color: '#B2B2B2'
		},
	},
	border: {
		weight: Tokens.BORDER_WEIGHT_THIN,
		radius: Tokens.BORDER_RADIUS_CIRCULAR,
		['default']: {
			color: Tokens.COLOR_NEUTRAL_500
		},
		['actived']: {
			color: Tokens.COLOR_NEUTRAL_500
		},
		['disabled']: {
			color: Tokens.COLOR_NEUTRAL_500
		},
		['error']: {
			color: Tokens.COLOR_ERROR_400
		}
	},
	background: {
		['default']: {
			color: Tokens.COLOR_NEUTRAL_100
		},
		['actived']: {
			color: Tokens.COLOR_WHITE
		},
		['disabled']: {
			color: '#EEEEEE'
		}
	},
	icon: {
		['actived']: {
			color: Tokens.COLOR_PRIMARY_650
		},
		['disabled']: {
			color: '#B2B2B2'
		}
	},
	actions: {
		hover: {
			color: Tokens.COLOR_PRIMARY_650
		}
	}
}
