import { TypeTeaser } from '../../../../../Functions/Teaser/types/teaser'
import { getEbWithSeptemberRule } from '../../get/getEbWithSeptemberRule'
import { getRbWithSeptemberRule } from '../../get/getRbWithSeptemberRule'
import { isTech } from '../../isTech'
import { genRealMultiple } from './genRealMultiple'

export const genValuation = (teaser: TypeTeaser) => {
	if (isTech(teaser)) {
		return getRbWithSeptemberRule(teaser) * 2.5
	}
	return getEbWithSeptemberRule(teaser) * genRealMultiple(teaser)
}
