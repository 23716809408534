/**
 * A função `curar` atualiza o campo `dataCuradoria` de um teaser no `db5`
 * banco de dados com data e hora atuais.
 * @param {string} id - O parâmetro `id` é uma string que representa o
 * identificador de um teaser.
 * @param {TypeTeaser} [teaser] - O parâmetro `teaser` na função `curar` é
 * do tipo `TypeTeaser`. É um parâmetro opcional que pode ser passado para o
 *função.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { minhaData } from '../../../Functions/Utils/minhaData'
import { db5 } from '../../../Services/db5'
import { TypeTeaser } from '../types/teaser'

export const curar = (id: string, teaser?: TypeTeaser) => {

	const cloneId = clone(id)
	const cloneTeaser = clone(teaser)

	db5.teasers.update({ id: cloneId, doc: { dataCuradoria: minhaData() } })
}
