/**
 * As funções `isLiquid` e `isNotLiquid` verificam se existe alguma tag no array de entrada
 * corresponde a um setor líquido baseado em constantes predefinidas.
 * @param {string[]} tags - O parâmetro `tags` é um array de strings que
 * representam diferentes tags associadas a um determinado item ou entidade. O
 * As funções `isLiquid` e `isNotLiquid` são usadas para verificar se alguma das tags em
 * a matriz está associada a propriedades líquidas. O `TAGS_SECTOR_ROOT`
 * @returns A função `isLiquid` retorna `true` se alguma das tags na entrada
 * array possui propriedade `liquid` igual a 1 no objeto `TAGS_SECTOR_ROOT`,
 * caso contrário, retorna `false`.
 */
//CRIADO POR IA

import { TAGS_SECTOR_ROOT } from '../../../Constants/TAGS'
import { clone } from '../../../Functions/Utils/clone'

export const isLiquid = (tags: string[]) => {

	const cloneTags = clone(tags)
	return cloneTags.some(tag => TAGS_SECTOR_ROOT?.[tag]?.liquid === 1)

}
export const isNotLiquid = (tags: string[]) => {
	const cloneTags = clone(tags)
	return cloneTags.some(tag => TAGS_SECTOR_ROOT?.[tag]?.liquid === -1)

}
