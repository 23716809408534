import { clone } from '../../../Functions/Utils/clone'
import { fatorialCnaes } from '../../../Functions/Utils/fatorialCnaes'

/**
 * Recebe uma lista de códigos CNAE e retorna uma lista de códigos CNAE fatorados.
 *
 * @param cnaes Uma lista de strings representando os códigos CNAE.
 * @returns Uma lista de strings contendo os fatoriais dos códigos CNAE fornecidos.
 *
 * @example
 *
 * / Lista de códigos CNAE de exemplo
 * const cnaes = ['34.3.25-1111', '111-111-111-111111'];
 *
 * / Obter os fatoriais dos códigos CNAE
 * const fatoriais = cnaesFactored(cnaes);
 * console.log(fatoriais); // Saída esperada: ['34.3.25-1111', '34.3-25', '34.30', '34.31', '34.32', '34.33', '34.34', '34.35', '34.36', '34.37', '34.38', '34.39', '34.3', '34'....]
 */

export const cnaesFactored = (cnaes: string[]) => {

	const cloneCnaes = clone(cnaes)

	return cloneCnaes.reduce(
		(acc: string[], cur: string) => ([...acc, ...fatorialCnaes(cur)]), []
	)


}
