import { add, isValid, parse } from 'date-fns'
import { Timestamp, _Timestamp } from '../Time/types/Timestamp'
import { clone } from './clone'

/**
 * Formata data js para data timestamp
 * @param {Date} date {Date} Data a ser formatada
 * @returns {Number} {Number} Data em timestamp
 * @example newDate('Fri Mar 24 2023 10:06:28 GMT-0300 (Horário Padrão de Brasília)') => {_nanoseconds: 84000000,
 _seconds: 1679663188}
 */
const newDate = (date?: Date): Timestamp => {

	const cloneDate = clone(date)

	if (cloneDate) return { seconds: Math.floor(new Date(cloneDate).valueOf() / 1000), nanoseconds: 0 }
	return { seconds: Math.floor(new Date().valueOf() / 1000), nanoseconds: 0 }
}

/**
 * Formata data fisebase para string
 * @param {Timestamp} date {Timestamp} Data a ser formatada
 * @returns {String} {String} Data em string
 * @example toString(Timestamp.fromDate(new Date('2023/02/22'))) => '22/02/2023'
 */
const toString = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {
		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const year = data.getFullYear()
		const month = (data.getMonth() + 1).toString()
		const monthFormatted = (month.length === 1) ? `0${month}` : month
		const day = data.getDate().toString()
		const dayFormatted = (day.length === 1) ? `0${day}` : day
		return `${dayFormatted}/${monthFormatted}/${year}`

	}
	else {
		const data = new Date()
		const year = data.getFullYear()
		const month = (data.getMonth() + 1).toString()
		const monthFormatted = (month.length === 1) ? `0${month}` : month
		const day = data.getDate().toString()
		const dayFormatted = (day.length === 1) ? `0${day}` : day
		return `${dayFormatted}/${monthFormatted}/${year}`
	}
}

/**
 * Retorna o ano em string
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Ano da data
 * @example getYear(Timestamp.fromDate(new Date('2023/02/22'))) => '2023'
 */
const getYear = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {
		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		return data.getFullYear().toString()
	}
	else {
		const data = new Date()
		return data.getFullYear().toString()
	}
}

/**
 * Retorna o dia em string
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Dia da data
 * @example getDay(Timestamp.fromDate(new Date('2023/02/22'))) => '22'
 */
const getDay = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {
		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const day = data.getDate().toString()
		return (day.length === 1) ? `0${day}` : day
	}
	else {
		const data = new Date()
		const day = data.getDate().toString()
		return (day.length === 1) ? `0${day}` : day
	}
}

/**
 * Retorna o mês em string
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Mês da data
 * @example getMonth(Timestamp.fromDate(new Date('2023/02/22'))) => '02'
 */
const getMonth = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {

		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const month = (data.getMonth() + 1).toString()
		return (month.length === 1) ? `0${month}` : month
	}
	else {
		const data = new Date()
		const month = data.getMonth().toString()
		return (month.length === 1) ? `0${month}` : month
	}
}

/**
 * Retorna o mês por extenso
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Mês da data
 * @example getMonth(Timestamp.fromDate(new Date('2023/02/22'))) => 'Fev'
 */
const getMonthExtenso = (date?: Timestamp) => {
	const monthNamesArray = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
	const cloneDate = clone(date)

	if (cloneDate) {

		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const month = monthNamesArray[data.getMonth()]
		return month
	}
	else {
		const data = new Date()
		const month = monthNamesArray[data.getMonth()]
		return month
	}
}

/**
 * Retorna a hora em string
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Hora da data
 * @example getHour(Timestamp.fromDate(new Date())) => '12'
 */
const getHour = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {
		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const hour = data.getHours().toString()
		return (hour.length === 1) ? `0${hour}` : hour
	}
	else {
		const data = new Date()
		const hour = data.getHours().toString()
		return (hour.length === 1) ? `0${hour}` : hour
	}
}

/**
 * Retorna os minutos em string
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Minutos da data
 * @example getMinutes(Timestamp.fromDate(new Date())) => '14'
 */
const getMinutes = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {
		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const minutes = data.getMinutes().toString()
		return (minutes.length === 1) ? `0${minutes}` : minutes
	}
	else {
		const data = new Date()
		const minutes = data.getMinutes().toString()
		return (minutes.length === 1) ? `0${minutes}` : minutes
	}
}

/**
 * Retorna os segundos em string
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Segundos da data
 * @example getSeconds(Timestamp.fromDate(new Date())) => '47'
 */
const getSeconds = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {
		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const seconds = data.getSeconds().toString()
		return (seconds.length === 1) ? `0${seconds}` : seconds
	}
	else {
		const data = new Date()
		const seconds = data.getSeconds().toString()
		return (seconds.length === 1) ? `0${seconds}` : seconds
	}
}

/**
 * Retorna o dia da semana em string
 * @param {Timestamp} date {Timestamp} Data completa
 * @returns {String} {String} Dia da semana da data
 * @example getWeekDay(Timestamp.fromDate(new Date('2023/02/22'))) => 'Quarta'
 */
const getWeekDay = (date?: Timestamp) => {

	const cloneDate = clone(date)

	if (cloneDate) {
		const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
		const weekdays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
		const weekdayName = weekdays[data.getDay()]
		return weekdayName
	}
	else {
		const data = new Date()
		const weekdays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']
		const weekdayName = weekdays[data.getDay()]
		return weekdayName
	}
}

/**
 * Compara 2 datas para ver se a primeira é maior que a segunda
 * @param {Timestamp} date {Timestamp} Primeira data
 * @param {Timestamp} date2 {Timestamp} Segunda data
 * @returns {Boolean} {Boolean} True se for verdadeiro, false se for falso
 * @example isBiggerThan(Timestamp.fromDate(new Date('2023/02/22')),Timestamp.fromDate(new Date('2023/01/22'))) => true
 */
const isBiggerThan = (date: Timestamp, date2: Timestamp) => {

	const cloneDate = clone(date)
	const cloneDate2 = clone(date2)

	const data = new Date(cloneDate.seconds * 1000 + cloneDate.nanoseconds / 1000000)
	const data2 = new Date(cloneDate2.seconds * 1000 + cloneDate2.nanoseconds / 1000000)
	return data > data2
}

/**
 * Compara 3 datas para ver se a segunda está entre as outras
 * @param {Timestamp} dateStart {Timestamp} Primeira data
 * @param {Timestamp} dateBetween {Timestamp} Segunda data
 * @param {Timestamp} dateEnd {Timestamp} Terceira data
 * @returns {Boolean} {Boolean} True se for verdadeiro, false se for falso
 * @example isBetween(Timestamp.fromDate(new Date('2023/02/22')),Timestamp.fromDate(new Date('2023/03/22')),
 Timestamp.fromDate(new Date('2023/05/22'))) => true
 */
const isBetween = (dateStart: Timestamp, dateBetween: Timestamp, dateEnd: Timestamp) => {

	const cloneDateStart = clone(dateStart)
	const cloneDateBetween = clone(dateBetween)
	const cloneDateEnd = clone(dateEnd)

	const data1 = new Date(cloneDateStart.seconds * 1000 + cloneDateStart.nanoseconds / 1000000)
	const dataEntre = new Date(cloneDateBetween.seconds * 1000 + cloneDateBetween.nanoseconds / 1000000)
	const data2 = new Date(cloneDateEnd.seconds * 1000 + cloneDateEnd.nanoseconds / 1000000)

	return dataEntre >= data1 && dataEntre <= data2
}

/**
 * Retorna quantos dias existem entre 2 datas
 * @param {Timestamp} dateStart {Timestamp} Primeira data
 * @param {Timestamp} dateEnd {Timestamp} Segunda data
 * @returns {Number} {Number} Quantidade de dias
 * @example howManyDays(Timestamp.fromDate(new Date('2023/03/24')), Timestamp.fromDate(new Date('2023/02/10'))) => 42
 */
const howManyDays = (dateStart: Timestamp, dateEnd: Timestamp) => {

	const cloneDateStart = clone(dateStart)
	const cloneDateEnd = clone(dateEnd)

	const data1 = new Date(cloneDateStart.seconds * 1000 + cloneDateStart.nanoseconds / 1000000)
	const data2 = new Date(cloneDateEnd.seconds * 1000 + cloneDateEnd.nanoseconds / 1000000)
	const timeDiff = data2.getTime() - data1.getTime() > 0 ? data2.getTime() - data1.getTime() : data1.getTime() - data2.getTime()
	return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
}

/**
 * Converte qualquer formato para timestamp
 * @param { number | string | Date | Timestamp | _Timestamp}
 * @returns {Timestamp}
 * { number | string | Date | Timestamp | _Timestamp}
 * Data a ser formatada
 * @example convertAllToTimestamp(20230331121000) => 'Fri Mar 31 2023 12:10:00 GMT-0300 (Horário Padrão de Brasília)'
 */
const convertAllToTimestamp =
	(

		data:
			number |
			string |
			Date |
			Timestamp |
			_Timestamp

	): Timestamp => {

		const cloneData = clone(data)
		if (cloneData.toString().length === 8) {
			const ano = cloneData.toString().substring(0, 4)
			const mes = cloneData.toString().substring(4, 6)
			const dia = cloneData.toString().substring(6, 8)
			const dataFormata = `${ano}/${mes}/${dia}`
			const date = new Date(dataFormata)
			return newDate(date)
		}
		else if (cloneData.toString().length === 14) {
			const ano = cloneData.toString().substring(0, 4)
			const mes = cloneData.toString().substring(4, 6)
			const dia = cloneData.toString().substring(6, 8)
			const horas = cloneData.toString().substring(8, 10)
			const minutos = cloneData.toString().substring(10, 12)
			const segundos = cloneData.toString().substring(12, 14)
			const dataFormata = `${ano}/${mes}/${dia} ${horas}:${minutos}:${segundos}`
			const date = new Date(dataFormata)
			return newDate(date)
		}
		if (Object.prototype.toString.call(cloneData) === '[object Date]') {
			const _data = cloneData as Date
			return newDate(_data)
		}
		if (typeof cloneData === 'object' && Object.prototype.toString.call(cloneData) !== '[object Date]') {
			const _data = cloneData as Timestamp
			const _data2 = cloneData as any
			//TODO: ALTERADO AQUI LINHA 276, VERIFICAR SE QUEBROU EM OUTRO LUGAR
			return _data.seconds ? newDate(new Date(_data.seconds * 1000)) : _data2._seconds ? newDate(new Date(_data2._seconds * 1000)) : newDate(new Date())
		}

		return newDate(new Date)
	}

// função que recebe timestap e retorna um Date
const convertTimestampToDate = (data: any) => {

	const cloneData = clone(data)

	if (cloneData as Timestamp) {
		const _data = new Date(cloneData.seconds * 1000 + cloneData.nanoseconds / 1000000)
		return _data
	}
	return new Date('1/1/1888')
}

const addMonths = (months: number, date?: Date) => {

	const cloneMonths = clone(months)
	const cloneDate = clone(date)

	const data = cloneDate || new Date()

	const dataFutura = add(data, {
		months: cloneMonths
	})

	const timeStampFirebase = convertAllToTimestamp(dataFutura)

	return timeStampFirebase
}

const dataIsValid = (value: string) => {

	const cloneValue = clone(value)

	const dataAtual = new Date()

	const data = parse(cloneValue, 'dd/MM/yyyy', dataAtual)

	return isValid(data)
}

/**
 * Converte qualquer formato para Date
 * @param { number | string | Date | Timestamp | _Timestamp}
 * data a ser formatada
 * @returns {Date}
 * @example convertAllToDate(20230920011234) => 'Wed, 20 Sep 2023 04:12:34 GMT'
 */
export const convertAllToDate = (
	date:
		number |
		string |
		Timestamp |
		_Timestamp |
		Date

) => _convertAllToDate(clone(date))

const _convertAllToDate = (
	date:
		number |
		string |
		Timestamp |
		_Timestamp |
		Date

): Date => {

	if (typeof date === 'string' && date.toString().length === 10) {
		const _date = date.replaceAll('-', '/')
		return new Date(_date)
	}
	if (date.toString().length === 8) {
		const y = (date.toString()).substring(0, 4),
			m = Number((date.toString()).substring(4, 6)) - 1,
			d = (date.toString()).substring(6, 8)
		const D = new Date(Number(y), m, Number(d))
		return D
	}
	else if (date.toString().length === 14) {
		const y = (date.toString()).substring(0, 4)
		const m = Number((date.toString()).substring(4, 6)) - 1
		const d = (date.toString()).substring(6, 8)
		const min = (date.toString()).substring(8, 10)
		const sec = (date.toString()).substring(10, 12)
		const ms = (date.toString()).substring(12, 14)
		const D = new Date(Number(y), m, Number(d), Number(min), Number(sec), Number(ms))
		return new Date(D)
	}
	if (typeof date === 'object' && Object.prototype.toString.call(date) !== '[object Date]') {
		const _data = date as Timestamp
		const _data2 = date as any
		let dateReturn
		if (_data.seconds) {
			dateReturn = new Date(_data.seconds * 1000 + _data.nanoseconds / 1000000)
		}
		else if (_data2.seconds) {
			dateReturn = new Date(_data2.seconds * 1000 + _data2.nanoseconds / 1000000)
		}
		else if (_data2._seconds) {
			dateReturn = new Date(_data2._seconds * 1000 + _data2._nanoseconds / 1000000)
		}
		if (dateReturn) {
			return dateReturn
		}
		else {
			throw 'Erro: Formato de data inválido'
		}
	}
	if (Object.prototype.toString.call(date) === '[object Date]') {
		const _data = date as unknown as Date
		return _data
	}
	throw 'Erro: Formato de data inválido'
}

export const helpersDate = {
	newDate,
	toString,
	getYear,
	getDay,
	getMonth,
	getHour,
	getMinutes,
	getSeconds,
	getWeekDay,
	isBiggerThan,
	isBetween,
	howManyDays,
	convertAllToTimestamp,
	addMonths,
	dataIsValid,
	convertTimestampToDate,
	convertAllToDate,
	getMonthExtenso
}
