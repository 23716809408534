export const SUB_STATUS_OF_ACTIVE_COSTUMER = {
	's00000': {
		code: 's00000',
		label: 'Não encontrado',
		labelForBabyCard: 'Não encontrado',
		labelSubstatus: 'Não encontrado'
	},
	's00050': {
		code: 's00050',
		label: 'Pré Match',
		labelForBabyCard: 'Cliente Ativo',
		labelSubstatus: 'Pré Match'
	},
	's00100': {
		code: 's00100',
		label: 'Matchmaking (1/6)',
		labelForBabyCard: 'Matchmaking',
		labelSubstatus: 'Matchmaking'
	},
	's00200': {
		code: 's00200',
		label: 'Preparação (2/6)',
		labelForBabyCard: 'Preparação',
		labelSubstatus: 'Preparação'
	},
	's00300': {
		code: 's00300',
		label: 'Negociações de oferta (3/6)',
		labelForBabyCard: 'Negociações',
		labelSubstatus: 'Negociação oferta'
	},
	's00400': {
		code: 's00400',
		label: 'Diligência (4/6)',
		labelForBabyCard: 'Negociações',
		labelSubstatus: 'Diligência'
	},
	's00500': {
		code: 's00500',
		label: 'Contratos definitivos (5/6)',
		labelForBabyCard: 'Negociações',
		labelSubstatus: 'Contratos Definitivos'
	},
	's00600': {
		code: 's00600',
		label: 'Transação concluída (6/6)',
		labelForBabyCard: 'Concluído',
		labelSubstatus: 'Concluído'
	},
} as const

export const SUB_STATUS_OF_ACTIVE_COSTUMER_GET = (code: string) => {
	const _code = code as keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER

	if (!SUB_STATUS_OF_ACTIVE_COSTUMER[_code]) {
		return (SUB_STATUS_OF_ACTIVE_COSTUMER.s00050)
	}

	return SUB_STATUS_OF_ACTIVE_COSTUMER[_code]
}

export const SUB_STATUS_OF_ACTIVE_COSTUMER_VALUES = Object.values(SUB_STATUS_OF_ACTIVE_COSTUMER)
export const SUB_STATUS_OF_ACTIVE_COSTUMER_KEYS = Object.keys(SUB_STATUS_OF_ACTIVE_COSTUMER)

export type SubstatusOfActiveCostumerKeys = keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER
