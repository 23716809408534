import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BarChart2 = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 28 24'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M15.999 12.337C16.8636 12.337 17.5645 12.8821 17.5645 13.5544V25.7826C17.5645 26.455 16.8636 27 15.999 27C15.1345 27 14.4336 26.455 14.4336 25.7826V13.5544C14.4336 12.8821 15.1345 12.337 15.999 12.337Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M25.4346 5C26.2991 5 27 5.54504 27 6.21739V25.7825C27 26.4548 26.2991 26.9999 25.4346 26.9999C24.57 26.9999 23.8691 26.4548 23.8691 25.7825V6.21739C23.8691 5.54504 24.57 5 25.4346 5Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M6.56543 19.6739C7.42999 19.6739 8.13086 20.219 8.13086 20.8913V25.7826C8.13086 26.4549 7.42999 27 6.56543 27C5.70087 27 5 26.4549 5 25.7826V20.8913C5 20.219 5.70087 19.6739 6.56543 19.6739Z' fill='#272827' />

	</Svg>
)
