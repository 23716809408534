import { TypeDbResponse } from '../TypeDBResponse'
import firebase from 'firebase/app'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { DocumentData, WhereFilterOp, CollectionReference, Query } from '@firebase/firestore-types'
import { generateWheres } from '../configs/wheres'
import { TypeCollections } from '../configs/collections'
import { permissionsGetThisCollection } from '../configs/permissionsGetThisCollection'

export const getWhereSync = <TDoc extends DocumentData>(collection: TypeCollections, model: (doc?: DocumentData) => TDoc) => (
	async ({ profile, wheres, limit, orderBy, callBack }: { profile: TypeUser, wheres: [string, WhereFilterOp, any][], limit?: number, orderBy?: { field: string, type: 'asc' | 'desc' }[], callBack: (e: TypeDbResponse<TDoc>) => void }) => {

		const serverLess = async () => {

			let ref: Query<DocumentData> = firebase.firestore().collection(collection)
			ref = generateWheres(ref, collection, profile)

			if (wheres.length) {
				wheres.forEach(where => (ref = ref.where(...where)))
			}

			if (orderBy && orderBy.length) {
				orderBy.forEach(order => (ref = ref.orderBy(order.field, order.type)))
			}

			if (limit && (limit > 0)) {
				ref = ref.limit(limit)
			}

			ref
				.onSnapshot(res => {
					if (!res.empty) {
						const docs: { [key: string]: TDoc } = {}
						// res.forEach(doc => (docs[doc.id] = model({ ...doc.data(), _id: doc.id })))
						res.forEach(doc => {
							docs[doc.id] = model({ ...doc.data(), _id: doc.id })
						})
						callBack({
							status: 200,
							// response.forEach(doc => (docs[doc.id] = model({ ...doc.data(), _id: doc.id })))
							// docs: model({ _id: id, ...res.data() }),
							docs: docs
						})
					}
					else {
						callBack({
							status: 400,
						})
					}
				})
		}

		const error = async () => {
			callBack({
				status: 400,
			})
		}

		permissionsGetThisCollection(collection, profile)
			? await serverLess()
			: await error()
	}
)
