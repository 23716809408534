import { INDICATORS } from '../../../../Constants/INDICATORS'
import dateIsBetween from '../../../../Functions/Utils/dateIsBetween'
import { helpersDate } from '../../../../Functions/Utils/helpersDate'
import { myDateToDate } from '../../../../Functions/Utils/minhaData'
import { TypeViewTeasers001 } from '../../../../Functions/ViewTeaser001/types/TypeViewTeasers001'
import { TypeTeaser } from '../../../../Functions/Teaser/types/teaser'
import { isPublicado } from '../isPublicado'

/**
 * Retorna Média de originações publicadas
 * @param teasers - { [key: string]: TypeTeaser } | { [key: string]: TypeViewTeasers001 }
 * @param startDate - Usado para filtrar os teasers publicados com a data especificada.
 * @param endDate - Usado para filtrar os teasers publicados com a data especificada. Caso não seja passado, tem como data final a data do dia atual
 * @returns  TypeTeasers
 */
export const getAverageOriginationsPerPeriod = ({ teasers, startDate, endDate }: { teasers: { [key: string]: Partial<TypeTeaser> } | { [key: string]: TypeViewTeasers001 }, startDate: Date, endDate: Date }) => {
	if (startDate && !endDate) endDate = new Date()
	if (endDate > new Date()) endDate = new Date()
	const teasersPublishedInDateRange: (TypeTeaser | TypeViewTeasers001)[] = Object.values(teasers).filter(teaser =>
		(startDate && endDate) ? teaser.dataPublicacao && dateIsBetween(myDateToDate(teaser.dataPublicacao), startDate, endDate) : true
	)
	const quantityOfDays = helpersDate.howManyDays(helpersDate.convertAllToTimestamp(startDate), helpersDate.convertAllToTimestamp(endDate))
	const numberOfMonths = Math.round(quantityOfDays / 30) || 1
	const teasersTotal = Object.values(teasersPublishedInDateRange).length
	const value = parseFloat((teasersTotal / numberOfMonths).toFixed(2)) || 0
	const indicator = getIndicator(value)

	return {
		value,
		indicator
	}
}

const getIndicator = (value: number) => {
	if (value >= 4) return INDICATORS.indicator004
	if (value >= 2 && value < 4) return INDICATORS.indicator003
	if (value >= 1 && value < 2) return INDICATORS.indicator002
	return INDICATORS.indicator001
}
