

const _COMPANY_RATING_CONST = {

	'Real': {
		_id: 'Real',
		label: 'Real'
	},

	'Tech': {
		_id: 'Tech',
		label: 'Tech'
	}
} as const

export type COMPANY_RATING_TYPE = keyof typeof _COMPANY_RATING_CONST
export const COMPANY_RATING = {
	GET: (code: keyof typeof _COMPANY_RATING_CONST | null | undefined) => {

		if (!code || !_COMPANY_RATING_CONST[code]) {
			return (_COMPANY_RATING_CONST['Real'])
		}

		return _COMPANY_RATING_CONST[code]
	},
	KEYS: Object.keys(_COMPANY_RATING_CONST) as Array<keyof typeof _COMPANY_RATING_CONST>,
	VALUES: Object.values(_COMPANY_RATING_CONST) as Array<{ readonly _id: keyof typeof _COMPANY_RATING_CONST, readonly label: string }>
}
