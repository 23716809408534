export const TESE_CREDIT_OPERATION_ESG_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_OPERATION_ESG

	if (!TESE_CREDIT_OPERATION_ESG[_code]) {
		return (TESE_CREDIT_OPERATION_ESG.o000)
	}

	return TESE_CREDIT_OPERATION_ESG[_code]
}

export const TESE_CREDIT_OPERATION_ESG = {
	o000: { label: 'Indiferente', _id: 'o000' },
	o001: { label: 'Sim', _id: 'o001' },
	o002: { label: 'Não', _id: 'o002' },
} as const

export const TESE_CREDIT_OPERATION_ESG_VALUES = Object.values(TESE_CREDIT_OPERATION_ESG)
export const TESE_CREDIT_OPERATION_ESG_KEYS = Object.keys(TESE_CREDIT_OPERATION_ESG)
