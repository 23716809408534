import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { UidsNotification } from '../../../Origination/methods/getUidsNotification'
import { fnUser } from '../../../User'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'

const ITEM = TYPE_NOTIFICATIONS.a0017

export type Props = {
	profile: TypeUser,
	origination: TypeTeaser,
	originationId: string,
	uid: string
}

export const sendServiçoRetomado =
	async ({
		profile,
		origination,
		originationId,
		uid
	}: Props) =>
		await fnNotification.send({
			uiPermissions: ITEM.permissions,
			uids: fnOrigination.getUidsNotification({ origination, type: UidsNotification.a01 }),
			origination: fnNotification.formatData.getOrigination({ origination, id: originationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile, uid }),
			user: fnUser.getUser({ user: profile || {} as TypeUser }),
			message: ITEM.msg,
			type: ITEM._id,
			analystEmail: fnOrigination.getAnalystEmail({ origination }),
			//stage: SUB_STATUS_OF_ACTIVE_COSTUMER.s00100.code
		} as unknown as TypeNotification)
