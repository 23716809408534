import { getFirstRb } from '../../get/getFirstRb'
import { getRbWithSeptemberRule } from '../../get/getRbWithSeptemberRule'
import { isTech } from '../../isTech'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScorePerformance = (props: TypeScoreProps) => {
	const res: TypeScoreStark['scoreItens'] = []

	const performance = ((getRbWithSeptemberRule(props.teaser) / getFirstRb(props.teaser)) ** (1 / 2) - 1) // !todo: está errado, se eu pegar a rb mais antiga, preciso mudar o elevado, para 1/4

	const devolutiva = 'A tração de crescimento pode melhorar, monitore a evolução da Receita Bruta e EBITDA.'

	if (!isTech(props.teaser)) {
		if (performance < 0.15)
			res.push({ // adiciona 0
				'pontosMax': 2,
				'pontos': 0,
				'pontosCor': 'green',
				'setor': 'Nota de Performance',
				'item': 'Nota de Performance',
				'itemDescrição': 'Performance menor que 15%',
				'metaDados': '',
				'devolutiva': devolutiva,
			})

		if (0.15 <= performance && performance < 0.25)
			res.push({ // adiciona 1
				'pontosMax': 2,
				'pontos': 1,
				'pontosCor': 'green',
				'setor': 'Nota de Performance',
				'item': 'Nota de Performance',
				'itemDescrição': 'Performance entre 15% e 25%',
				'metaDados': '',
				'devolutiva': devolutiva,
			})

		if (0.25 <= performance)
			res.push({ // adiciona 2
				'pontosMax': 2,
				'pontos': 2,
				'pontosCor': 'green',
				'setor': 'Nota de Performance',
				'item': 'Nota de Performance',
				'itemDescrição': 'Performance superior a 25%',
				'metaDados': '',
				'devolutiva': '',
			})
	}

	if (isTech(props.teaser)) {
		if (performance < 0.15)
			res.push({ // adiciona 0
				'pontosMax': 2,
				'pontos': 0,
				'pontosCor': 'green',
				'setor': 'Nota de Performance',
				'item': 'Nota de Performance',
				'itemDescrição': 'Performance menor que 15%',
				'metaDados': '',
				'devolutiva': devolutiva,
			})

		if (0.15 <= performance && performance < 0.3)
			res.push({ // adiciona 1
				'pontosMax': 2,
				'pontos': 1,
				'pontosCor': 'green',
				'setor': 'Nota de Performance',
				'item': 'Nota de Performance',
				'itemDescrição': 'Performance entre 15% e 30%',
				'metaDados': '',
				'devolutiva': devolutiva,
			})

		if (0.3 <= performance && performance < 0.5)
			res.push({ // adiciona 1.5
				'pontosMax': 2,
				'pontos': 1.5,
				'pontosCor': 'green',
				'setor': 'Nota de Performance',
				'item': 'Nota de Performance',
				'itemDescrição': 'Performance entre 15% e 30%',
				'metaDados': '',
				'devolutiva': devolutiva,
			})

		if (0.5 <= performance)
			res.push({ // adiciona 2
				'pontosMax': 2,
				'pontos': 2,
				'pontosCor': 'green',
				'setor': 'Nota de Performance',
				'item': 'Nota de Performance',
				'itemDescrição': 'Performance superior a 25%',
				'metaDados': '',
				'devolutiva': '',
			})
	}

	return res
}
