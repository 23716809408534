import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ClipOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M19.0251 5.71692L7.14163 16.9947C5.98215 18.1232 5.33728 19.6396 5.34672 21.216C5.35618 22.7949 6.02129 24.3066 7.19774 25.4231C8.37419 26.5396 9.96708 27.1708 11.6308 27.1798C13.2919 27.1887 14.8897 26.5767 16.0789 25.4763L27.99 14.1724C28.5703 13.6217 29.511 13.6217 30.0913 14.1724C30.6715 14.723 30.6715 15.6159 30.0913 16.1665L18.1739 27.4765L18.1618 27.4877C16.4127 29.1104 14.0599 30.0131 11.6139 29.9999C9.16792 29.9867 6.82608 29.0587 5.09648 27.4172C3.36689 25.7758 2.38906 23.5533 2.37515 21.232C2.36124 18.9108 3.31238 16.6779 5.0222 15.0179L5.03408 15.0065L16.9515 3.69654C16.9685 3.6804 16.9859 3.66465 17.0038 3.64931C18.2823 2.54839 19.9594 1.95837 21.6852 2.00229C23.411 2.04621 25.0523 2.72068 26.2669 3.88505C27.4814 5.04943 28.1755 6.61382 28.2043 8.25201C28.2332 9.89019 27.5945 11.4757 26.4216 12.6779C26.4087 12.6912 26.3955 12.7042 26.382 12.717L14.4689 24.0228C14.0918 24.3861 13.6425 24.6749 13.147 24.8723C12.649 25.0708 12.1143 25.173 11.5742 25.173C11.034 25.173 10.4993 25.0708 10.0013 24.8723C9.50512 24.6746 9.05528 24.3853 8.67789 24.0213C7.91623 23.2937 7.48868 22.3097 7.48868 21.2839C7.48868 20.2564 7.91767 19.2708 8.68173 18.5428L8.68451 18.5402L19.723 8.07847C20.3037 7.52818 21.2444 7.52878 21.8243 8.07982C22.4041 8.63086 22.4035 9.52366 21.8229 10.074L10.7871 20.533C10.7867 20.5334 10.7863 20.5338 10.7859 20.5342C10.5774 20.7335 10.4603 21.003 10.4603 21.2839C10.4603 21.5653 10.5778 21.8353 10.7871 22.0348L10.7951 22.0424L10.7951 22.0424C10.8967 22.1407 11.0179 22.2188 11.1517 22.2721C11.2855 22.3254 11.4291 22.3528 11.5742 22.3528C11.7193 22.3528 11.8629 22.3254 11.9967 22.2721C12.1304 22.2188 12.2517 22.1407 12.3532 22.0424L12.3633 22.0327L12.3633 22.0328L24.2589 10.7436C24.9002 10.0769 25.249 9.20238 25.2331 8.2991C25.2171 7.38752 24.8309 6.51701 24.155 5.86909C23.4792 5.22117 22.5659 4.84586 21.6055 4.82142C20.6562 4.79726 19.7334 5.11781 19.0251 5.71692Z' fill='#272827' />
	</Svg>
)
