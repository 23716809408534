import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const SocialFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M12.7039 27.6426C12.4122 27.6426 12.1324 27.5267 11.9261 27.3204C11.7198 27.1141 11.6039 26.8343 11.6039 26.5426V23.2426H7.20391C6.62043 23.2426 6.06085 23.0108 5.64827 22.5982C5.23569 22.1856 5.00391 21.6261 5.00391 21.0426V7.84258C5.00391 6.62158 5.99391 5.64258 7.20391 5.64258H24.8039C25.3874 5.64258 25.947 5.87436 26.3595 6.28694C26.7721 6.69952 27.0039 7.2591 27.0039 7.84258V21.0426C27.0039 21.6261 26.7721 22.1856 26.3595 22.5982C25.947 23.0108 25.3874 23.2426 24.8039 23.2426H18.0939L14.0239 27.3236C13.8039 27.5326 13.5289 27.6426 13.2539 27.6426H12.7039ZM20.4039 18.8426V17.7426C20.4039 16.2796 17.4669 15.5426 16.0039 15.5426C14.5409 15.5426 11.6039 16.2796 11.6039 17.7426V18.8426H20.4039ZM16.0039 10.0426C15.4204 10.0426 14.8609 10.2744 14.4483 10.6869C14.0357 11.0995 13.8039 11.6591 13.8039 12.2426C13.8039 12.8261 14.0357 13.3856 14.4483 13.7982C14.8609 14.2108 15.4204 14.4426 16.0039 14.4426C16.5874 14.4426 17.147 14.2108 17.5595 13.7982C17.9721 13.3856 18.2039 12.8261 18.2039 12.2426C18.2039 11.6591 17.9721 11.0995 17.5595 10.6869C17.147 10.2744 16.5874 10.0426 16.0039 10.0426Z' fill='#272827' />
	</Svg>
)


