import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const HotelFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M24.4037 10.0426H14.8037V18.4426H5.20371V7.64258H2.80371V25.6426H5.20371V22.0426H26.8037V25.6426H29.2037V14.8426C29.2037 13.5695 28.698 12.3486 27.7978 11.4485C26.8976 10.5483 25.6767 10.0426 24.4037 10.0426ZM10.0037 17.2426C10.9585 17.2426 11.8742 16.8633 12.5493 16.1882C13.2244 15.513 13.6037 14.5974 13.6037 13.6426C13.6037 12.6878 13.2244 11.7721 12.5493 11.097C11.8742 10.4219 10.9585 10.0426 10.0037 10.0426C9.04893 10.0426 8.13326 10.4219 7.45813 11.097C6.783 11.7721 6.40371 12.6878 6.40371 13.6426C6.40371 14.5974 6.783 15.513 7.45813 16.1882C8.13326 16.8633 9.04893 17.2426 10.0037 17.2426Z' fill='#272827' />
	</Svg>
)


