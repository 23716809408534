import { TypeMatch } from '../types/match'
import { TYPE_OPERATIONS } from '../../../Constants/TYPE_OPERATION'
import { clone } from '../../Utils/clone'

export const isCredit = (match: TypeMatch) => {
	const cloneMatch = clone(match)
	// @ts-ignore
	const result = cloneMatch.typeOperation.includes(TYPE_OPERATIONS.op2.label) // true | false
	return result
}
