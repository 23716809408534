import { TypeUser } from '../types/TypeUser'
import { getLabelTypeUser } from './getLabelTypeUser'

/**
 * Filtra um array de usuários pelo tipo de originador
 * @param teasers
 * @param order
 * @returns  TypeUser[]
 */
export const filterByType = ({ users, filter }: { users: TypeUser[], filter: string }): TypeUser[] =>
	users.filter(user => filter === getLabelTypeUser(user))
