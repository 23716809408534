import { clone } from '../../../Utils/clone'
import { stringToCnae } from '../../../Utils/numberToCnae'

export const getCnaeFromCnpj = async (cnpj: string) => {

	const cloneCnpj = clone(cnpj)

	const numberCnpj = cloneCnpj.replace(/\D/g, '')
	let rest: any = await fetch(`https://api.cnpja.com.br/companies/${numberCnpj}`, {
		'method': 'GET',
		'headers': {
			'authorization': '690197b9-0483-4557-b140-c45fc08be9e1-b1aca2b6-8f00-4cae-997e-1794dbc64ded'
		}
	})

	rest = await rest.text()
	rest = await JSON.parse(rest)

	const cnaePrincipal = stringToCnae(rest.primary_activity.code)
	const cnaes = [cnaePrincipal]
	rest.secondary_activities.forEach((activity: any) => {
		cnaes.push(stringToCnae(activity.code))
	})

	return cnaes

}
