import { TypeTeaser } from '../../types/teaser'
import { fnTeaser } from '../..'
import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { STATUS_CUSTOMER } from '../../../../Constants/STATUS_CUSTOMER'
import { clone } from '../../../Utils/clone'

export const getLabelStatus = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return fnTeaser.isPublicado(cloneTeaser) ? getLabelPublicado(cloneTeaser) : getLabelNaoPublicado(cloneTeaser)

}

const getLabelPublicado = (teaser: TypeTeaser) => {

	const statusReturn = {
		status: '',
		substatus: ''
	}

	const statusTeaser = fnTeaser.getStatusCustomer(teaser)
	const statusLabel = statusTeaser?.label || ''
	const isNotPrematch = teaser.subStatusOfActiveCostumer !== SUB_STATUS_OF_ACTIVE_COSTUMER.s00050.code
	const isActiveTeaser = statusTeaser?.value === STATUS_CUSTOMER.ativo.value
	const subStatusOfActiveCostumer = teaser.subStatusOfActiveCostumer as keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER
	const label = SUB_STATUS_OF_ACTIVE_COSTUMER[subStatusOfActiveCostumer]?.labelForBabyCard
	const substatus = (isNotPrematch && isActiveTeaser && label) ? label : ''
	statusReturn.status = statusLabel

	if (substatus) statusReturn.substatus = substatus

	const arrayStatus = ['s00300', 's00400', 's00500']
	if (arrayStatus.indexOf(subStatusOfActiveCostumer) > -1) statusReturn.substatus = 'Negociação'

	return statusReturn
}

const getLabelNaoPublicado = (teaser: TypeTeaser) => {
	const dateRequestedChange = fnTeaser.getRequestedChangedDate(teaser)
	if (dateRequestedChange) return { status: 'Ajustes solicitados', substatus: '' }

	return {
		status: 'Prospecto',
		substatus: ''
	}
}
