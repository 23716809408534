import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const WalletFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M25.8798 23.4759V24.6148C25.8798 25.2189 25.635 25.7983 25.1992 26.2254C24.7634 26.6526 24.1723 26.8926 23.5559 26.8926H7.28873C5.99897 26.8926 4.96484 25.8676 4.96484 24.6148V8.67036C4.96484 8.06625 5.20968 7.48689 5.64549 7.05972C6.08131 6.63256 6.6724 6.39258 7.28873 6.39258H23.5559C24.1723 6.39258 24.7634 6.63256 25.1992 7.05972C25.635 7.48689 25.8798 8.06625 25.8798 8.67036V9.80924H15.4223C14.1326 9.80924 13.0984 10.8342 13.0984 12.087V21.1981C13.0984 21.8022 13.3433 22.3816 13.7791 22.8088C14.2149 23.2359 14.806 23.4759 15.4223 23.4759H25.8798ZM15.4223 21.1981H27.0418V12.087H15.4223V21.1981ZM20.0701 18.3509C19.6079 18.3509 19.1645 18.1709 18.8377 17.8506C18.5108 17.5302 18.3272 17.0957 18.3272 16.6426C18.3272 16.1895 18.5108 15.755 18.8377 15.4346C19.1645 15.1142 19.6079 14.9342 20.0701 14.9342C20.5324 14.9342 20.9757 15.1142 21.3025 15.4346C21.6294 15.755 21.813 16.1895 21.813 16.6426C21.813 17.0957 21.6294 17.5302 21.3025 17.8506C20.9757 18.1709 20.5324 18.3509 20.0701 18.3509Z' fill='#272827' />
	</Svg>
)


