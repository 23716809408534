import { IUiInput } from '..'
import { masks } from 'Functions/Utils/masks'
import React from 'react'

export const onChangeCurrency = (props: IUiInput) =>
	(event: React.ChangeEvent<HTMLInputElement>) => {
		const value = masks.currencyToNumber(event.target.value).toString()
		event.target.value = value
		props.onChange && props.onChange(event)
	}
