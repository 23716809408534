/**
 * A função `inAtivo` verifica se um teaser está ativo com base na sua publicação
 * data e status do cliente.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido no arquivo onde esta função está localizada. Isto
 *provavelmente contém propriedades relacionadas a um teaser, como `dataPublicacao`
 * (data de publicação) e `statusCustomer` (status do cliente). A função
 * @returns A função `inAtivo` recebe um objeto `teaser` do tipo `TypeTeaser` como
 * input, clona o objeto `teaser` usando a função `clone` e então verifica se
 * o objeto `teaser` clonado possui uma propriedade `dataPublicacao` e o
 * A propriedade `statusCustomer` é igual ao valor de
 * `STATUS_CUSTOMER.ativo.value`.
 */
//CRIADO POR IA

import { STATUS_CUSTOMER } from '../../../Constants/STATUS_CUSTOMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'


export const inAtivo = (teaser: TypeTeaser): boolean => {

	const cloneTeaser = clone(teaser)
	return !!(cloneTeaser.dataPublicacao && cloneTeaser.statusCustomer === STATUS_CUSTOMER.ativo.value)

}
