import { AGENTS_SOURCE } from '../../../Constants/AGENTS_SOURCE'
import { TYPE_USER } from '../../../Constants/TYPE_USER'
import { TypeUser } from '../types/TypeUser'
import { AGENT_PROFILE } from '../../../Constants/AGENT_PROFILE'
import { TYPES_AGENT } from '../../../Constants/TYPES_AGENT'

/**
* Descrição da função
* @param {String | Number} Nome_do_parâmetro {String} Explicar o que é o parâmetro
* @returns {String} {String} Explicar o que a função retorna
* @example função(parâmetro) => exemplo de retorno
*/

export const modelUser = (obj: any = {}): TypeUser => ({
	_id: obj._id || '',
	// ================================== BDA
	tipo: obj.tipo || TYPE_USER.bda,
	biography: obj?.biography || '',
	fromOffice: obj.fromOffice || '',
	typeVariant: obj.typeVariant || '',
	bdaativado: obj.bdaativado || false,
	bdaativadopedido: obj.bdaativadopedido || false,
	contratoAssinado: obj.contratoAssinado || false,
	dataCriacao: obj.dataCriacao || '',
	status: obj.status || '',
	attended: obj.attended ?? false,
	// lastLogin: obj.lastLogin || null,
	nome: obj.nome || '',
	sobrenome: obj.sobrenome || '',
	telefone: obj.telefone || '',
	profissao: obj.profissao || '',
	linkedin: obj.linkedin || '',
	birthDate: obj.birthDate || '',
	ulrimg: obj.ulrimg || '',
	cpf: obj.cpf || '',
	headerImage: obj.headerImage || '',
	pontuacaoManual: obj.pontuacaoManual ?? 0,
	ranking: {
		uid: obj?.ranking?.uid ?? (obj._id || ''),
		academy: obj?.ranking?.academy ?? false,
		indicationsOfAgents: obj?.ranking?.indicationsOfAgents ?? 0,
		nAprovadas: obj?.ranking?.nAprovadas ?? 0,
		nContratos: obj?.ranking?.nContratos ?? 0,
		nPubli: obj?.ranking?.nPubli ?? 0,
		nTeasers: obj?.ranking?.nTeasers ?? 0,
		points: obj?.ranking?.points ?? 0,
		position: obj?.ranking?.position ?? 0,
		name: obj?.ranking?.name ?? (obj.nome || ''),
		urlImg: obj?.ranking?.urlImg ?? (obj.ulrimg || ''),
		isActive: obj?.ranking?.isActive ?? false
	},

	cep: obj.cep || '',
	logradouro: obj.logradouro || '',
	numero: obj.numero || '',
	complemento: obj.complemento || '',
	bairro: obj.bairro || '',
	pais: obj.pais || '',
	estado: obj.estado || '',
	cidade: obj.cidade || '',

	email: obj.email || '',
	emailcontato: obj.emailcontato || '',

	linkContrato: obj.linkContrato || '',

	nascimento: obj.nascimento || '', // DEPRECIADO

	academyStatus: obj.academyStatus || '',
	academyStartDate: obj.academyStartDate || '',
	academyEndDate: obj.academyEndDate || '',
	academyGradeAverage: obj.academyGradeAverage || '',

	form: {
		q1: obj?.form?.q1 ?? '', // já trabalha com m&A
		q2: obj?.form?.q2 ?? '', // nível de experiência
		q3: obj?.form?.q3 ?? 0, // quantas empresas acima de 20 M
		q4: obj?.form?.q4 ?? 0, // quantas empresas acima de 10 M
		q5: obj?.form?.q5 ?? '', // frequência de relacionamento com empresário
		q6: obj?.form?.q6 ?? '', // grau de relacionamento com o empresário
		q7: obj?.form?.q7 ?? '', // conhece algum empresário que se encaixa

		q8: obj?.form?.q8 ?? '', // onde voce trabalha fisicamente
		q9: obj?.form?.q9 ?? '', // principal fonte de renda
		q10: obj?.form?.q10 ?? '', // Faz parte de algum órgão institucional, associação ou organização?
		q11: obj?.form?.q11 ?? '', // Caso a resposta da pergunta anterior seja "sim", cite a(s) entidade(s).
		q12: obj?.form?.q12 ?? '', // Em qual dos setores da economia abaixo você possui mais relacionamento?
		q13: obj?.form?.q13 ?? '', // Com qual dos perfis comerciais abaixo você mais se identifica?
		q14: obj?.form?.q14 ?? '', // Como conheceu a STARK?
		q15: obj?.form?.q15 ?? '', // Como conheceu a STARK?
	},

	academy: obj.academy || false,
	deals: obj.deals || 0,

	// vai sair
	overview: obj.overview || '',
	perfil: obj.perfil || '',
	headquarters: obj.headquarters || '',
	acceptedTerm1: obj.acceptedTerm1 || 0,
	acceptedTerm2: obj.acceptedTerm2 || 0,
	acceptedTerm3: obj.acceptedTerm3 || 0,
	acceptedTerm4: obj.acceptedTerm4 || 0,
	indicationsOfAgents: obj.indicationsOfAgents ?? 0,

	// ================================== BDA

	// ================================== Customer

	// tipo: 'Customer',
	customerAtivado: obj.customerAtivado || false,
	// status: string,

	// nome: string,
	// sobrenome: string,
	// email: string,
	// telefone: string,
	// linkedin: string,
	// ulrimg: string,
	// cpf: string,
	cargo: obj.cargo || '',
	resumo: obj.resumo || '',

	setor: obj.setor || '',
	subSetor: obj.subSetor || '',

	// cep: string,
	// logradouro: string,
	// numero: string,
	// complemento: string,
	// bairro: string,
	// pais: string,
	// estado: string,
	// cidade: string,

	dateCreate: obj.dateCreate || '', // Diferente do model Costumer


	// ================================== Customer

	// ================================== MicroBDA

	// tipo: string
	uidBdaFather: obj.uidBdaFather || '',

	// dataCriacao: string
	// email: string

	// nome: string
	// sobrenome: string
	// telefone: string
	// profissao: string
	// linkedin: string
	// ulrimg: string
	// cpf: string
	// cep: string
	// logradouro: string
	// numero: string
	// complemento: string
	// bairro: string
	// pais: string
	// estado: string
	// cidade: string
	// nascimento: string

	// ================================== MicroBDA

	// ================================== Office

	// tipo: string
	// dataCriacao: number

	// nome: string
	// sobrenome: string
	// telefone: string
	// profissao: string
	// linkedin: string
	// birthDate: string
	// cpf: string

	urlProfile: obj.urlProfile || '',
	// headerImage: string
	// ulrimg: string

	// cep: string
	// logradouro: string
	// numero: string
	// complemento: string
	// bairro: string
	// pais: string
	// estado: string
	// cidade: string

	// email: string
	// emailcontato: string

	// linkContrato: string
	// nascimento: string

	// ================================== Office
	betaUser: obj.betaUser ?? 'b000',

	//Anotacoes sobre o agente
	agentNotes: obj.agentNotes || '',
	//Perfil dos agentes
	agentsProfile: obj.agentsProfile || AGENT_PROFILE.GET('ap000')._id,
	//Origem do agente
	agentSourceName: obj.agentSourceName || '',
	agentSource: obj.agentSource || AGENTS_SOURCE.GET('as000')._id,
	//Nivel engajamento
	engagementLevel: obj.engagementLevel || 0,
	//stark no linkedin
	starkInLinkedin: obj.starkInLinkedin ?? false,
	//comunidade
	community: obj.community ?? false,
	//newsletter
	newsletter: obj.newsletter ?? false,
	/* Dados gestor */
	managerName: obj.managerName || '',
	managerSurname: obj.managerSurname || '',
	managerEmail: obj.managerEmail || '',
	managerTelephone: obj.managerTelephone || '',
	managerLinkedin: obj.managerLinkedin || '',

	site: obj.site || '',
	dateFirstMatch: obj.dateFirstMatch ?? null,
	reuniaoGestaoMensal: obj.reuniaoGestaoMensal ?? false,
	reuniaoPipeDCM: obj.reuniaoPipeDCM ?? false,
	reuniaoMEAGeral: obj.reuniaoMEAGeral ?? false,
	onboarding: obj.onboarding ?? false,
	ipp: obj.ipp ?? false,
	agentType: obj.agentType || TYPES_AGENT.GET('0001')._id,
	responsibleAnalyst: obj.responsibleAnalyst || ''
})
