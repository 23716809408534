import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BuildingFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M22.7539 19.9759H20.5039V22.1981H22.7539V19.9759ZM22.7539 15.5315H20.5039V17.7537H22.7539V15.5315ZM25.0039 24.4204H16.0039V22.1981H18.2539V19.9759H16.0039V17.7537H18.2539V15.5315H16.0039V13.3092H25.0039V24.4204ZM13.7539 11.087H11.5039V8.8648H13.7539V11.087ZM13.7539 15.5315H11.5039V13.3092H13.7539V15.5315ZM13.7539 19.9759H11.5039V17.7537H13.7539V19.9759ZM13.7539 24.4204H11.5039V22.1981H13.7539V24.4204ZM9.25391 11.087H7.00391V8.8648H9.25391V11.087ZM9.25391 15.5315H7.00391V13.3092H9.25391V15.5315ZM9.25391 19.9759H7.00391V17.7537H9.25391V19.9759ZM9.25391 24.4204H7.00391V22.1981H9.25391V24.4204ZM16.0039 11.087V6.64258H4.75391V26.6426H27.2539V11.087H16.0039Z' fill='#272827' />
	</Svg>
)


