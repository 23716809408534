import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const StreetFoodCoverFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M28.0792 19.9793H26.9452C26.2709 15.5913 22.9812 12.0463 18.7108 10.9798C18.91 10.5786 19.0275 10.1266 19.0275 9.64918C19.0275 7.98844 17.6738 6.64258 16.0086 6.64258C14.3433 6.64258 12.9896 7.98844 12.9896 9.6441C12.9896 10.1215 13.102 10.5735 13.3064 10.9747C9.03591 12.0463 5.74625 15.5862 5.07197 19.9742H3.93795C3.38116 19.9793 2.93164 20.4262 2.93164 20.9798C2.93164 21.5334 3.38116 21.9803 3.93795 21.9803H4.94426H5.00045H5.94547H26.0615H27.0065H27.0627H28.069C28.6258 21.9803 29.0753 21.5334 29.0753 20.9798C29.0855 20.4262 28.636 19.9793 28.0792 19.9793ZM21.1781 18.3897C20.9839 18.5623 20.7439 18.6436 20.5089 18.6436C20.233 18.6436 19.9572 18.5319 19.758 18.3084C18.8027 17.247 17.4389 16.6426 16.0086 16.6426C15.4518 16.6426 15.0023 16.1957 15.0023 15.6421C15.0023 15.0885 15.4518 14.6416 16.0086 14.6416C18.011 14.6416 19.9214 15.4897 21.2547 16.9727C21.6276 17.3892 21.5918 18.0189 21.1781 18.3897Z' fill='#272827' />
		<path d='M26.0667 23.9766H25.0603H6.95696H5.95065H4.94434V25.6424C4.94434 26.196 5.39386 26.6429 5.95065 26.6429H26.0667C26.6234 26.6429 27.073 26.196 27.073 25.6424V23.9766H26.0667Z' fill='#272827' />
	</Svg>
)


