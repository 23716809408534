import { TypeScoreProps } from '../../types/TypeScoreProps'
import { TypeScoreStark } from '../../types/TypeScoreStark'
import { genScoreFee } from '../../genScore/genScoreFee'
import { normalize } from '../../normalize'
import { genScoreFinancing } from '../../genScore/genScoreFinancing'
import { genScoreCapTable } from '../../genScore/genScoreCaptable'
import { genScoreGov } from '../../genScore/genScoreGov'

/** @deprecated */
export const scoreStarkCréditoGreenField = (props: TypeScoreProps) => {
	let score: TypeScoreStark = {
		approved: false,
		scoreColor: 'red',
		scoreNote: 0,
		scoreItens: [],
		metadata: {
			valuation: 0,
			transactionValue: 0,
			successFee: 0,
			valueTotalInsure: 0,
			typeOperation: 'greenfield',
			type: 'Crédito',
			reperfilamento: false,
			isAdjustableValue: false,
			numberOfInstitutions: {
				total: 0,
				specific: 0,
				setorial: 0,
				generalist: 0,
			},
			insureCoverage: 0,
			governance: 'C',
			governanceColor: 'red',
			governanceCoverage: 0,
		}
	}

	// Governança
	score.scoreItens.push(...genScoreGov(props))

	// fee acima do mínimo Stark ?
	score.scoreItens.push(...genScoreFee(props))

	// Nota de financiamento
	score.scoreItens.push(...genScoreFinancing(props))

	// nota de captable
	score.scoreItens.push(...genScoreCapTable(props))

	// normalização dos pontos
	score = normalize(score)

	return score
}
