import { TypeTeaser } from '../../types/teaser'
import { TypeTeseCredit } from '../../../TeseCredit/types/teseCredit'
import { fnTeaser } from '../..'
import { clone } from '../../../Utils/clone'

export const getTagsSectorMatch = (teaser: TypeTeaser, teseCredit: TypeTeseCredit, teseCreditId: string) => {

	const cloneTeaser = clone(teaser)
	const cloneTeseCredit = clone(teseCredit)

	const tagAgro =
		cloneTeseCredit.operacoesDividaAgro

	const tagRealState =
		cloneTeseCredit.operacoesDividaRealState

	const tagTech =
		cloneTeseCredit.operacoesDividaTecnologia ||
		cloneTeseCredit.operacoesDividaStartup

	const tagOutros =
		cloneTeseCredit.operacoesDividaGeneralista ||
		cloneTeseCredit.operacoesDividaEducacao ||
		cloneTeseCredit.operacoesDividaFinanceiro ||
		cloneTeseCredit.operacoesDividaIndustria ||
		cloneTeseCredit.operacoesDividaLogistica ||
		cloneTeseCredit.operacoesDividaSaude ||
		cloneTeseCredit.operacoesDividaVarejo

	const teaserIsAgro = cloneTeaser.tags.includes('t001')
	const teaserIsTech = fnTeaser.isTech(cloneTeaser)
	const teaserIsRealState = cloneTeaser.tags.includes('t061')

	const matches = []

	if (tagAgro && teaserIsAgro) matches.push('Agro')
	if (tagTech && teaserIsTech) matches.push('Tech')
	if (tagRealState && teaserIsRealState) matches.push('RealState')
	if (tagOutros && !teaserIsTech) matches.push('Outros')

	return matches
}
