/**
* Função para subtrair o segundo array do primeiro
* @param {[]} arr1 {Array} Primeiro array
* @param {[]} arr2 {Array} Segundo array
* @returns {[]} {Array} Valor que sobrou do primeiro array (o que não é comum entre eles)
* @example arraySubtraction([1,2,3,4,5],[1,2,3,4]) => [5]
* @example arraySubtraction([1, 2, 5], [1, 2, 5, 6]) => []
*/

export const arraySubtraction = <T>(arr1: T[], arr2: T[]) => {

	const cloneArr1: T[] = JSON.parse(JSON.stringify(arr1))
	const cloneArr2: T[] = JSON.parse(JSON.stringify(arr2))

	return cloneArr1
		.filter(itemArray1 => !cloneArr2.includes(itemArray1))
}
