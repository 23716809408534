import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const HeartBeatFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M10.3367 6.14258C8.49966 6.14258 6.73787 6.87234 5.43889 8.17131C4.13991 9.47029 3.41016 11.2321 3.41016 13.0691C3.41016 13.6988 3.5235 14.3285 3.68722 14.9582H8.82545L10.4248 10.7141C10.8027 9.7066 12.2887 9.60585 12.7673 10.7141L15.3742 17.4769L16.1172 15.6886C16.2809 15.273 16.7217 14.9582 17.2632 14.9582H28.3205C28.4842 14.3285 28.5976 13.6988 28.5976 13.0691C28.5976 11.2321 27.8678 9.47029 26.5688 8.17131C25.2699 6.87234 23.5081 6.14258 21.671 6.14258C19.3286 6.14258 17.2632 7.31379 16.0039 9.0895C14.7445 7.31379 12.6791 6.14258 10.3367 6.14258ZM4.66953 16.8472C4.33552 16.8472 4.0152 16.9799 3.77902 17.2161C3.54284 17.4523 3.41016 17.7726 3.41016 18.1066C3.41016 18.4406 3.54284 18.7609 3.77902 18.9971C4.0152 19.2333 4.33552 19.366 4.66953 19.366H7.74239L14.7445 26.2925C16.0039 27.4259 16.0039 27.4259 17.2632 26.2925L24.2653 19.366H27.3382C27.6722 19.366 27.9925 19.2333 28.2287 18.9971C28.4649 18.7609 28.5976 18.4406 28.5976 18.1066C28.5976 17.7726 28.4649 17.4523 28.2287 17.2161C27.9925 16.9799 27.6722 16.8472 27.3382 16.8472H17.767L16.5958 19.7438C16.092 21.0157 14.6437 20.8394 14.1778 19.7816L11.5961 13.0691L10.3871 16.0034C10.1982 16.482 9.76998 16.8472 9.20326 16.8472H4.66953Z' fill='#272827' />
	</Svg>
)


