import React, { createContext, useContext, useState } from 'react'
import { TypeMatches } from 'Functions/Match/types/matches'

const _useContext = () => {

	const [globalCustomerMatches, setGlobalCustomerMatches] = useState('' as '' | TypeMatches)

	return ({

		globalCustomerMatches, setGlobalCustomerMatches,
	})
}
const ContextCustomerMatches = createContext({} as ReturnType<typeof _useContext>)

export const useContextCustomerMatches = () => useContext(ContextCustomerMatches)
export const ContextCustomerMatchesProvider: React.FC = ({ children }) => {
	const controller = _useContext()
	return (
		<ContextCustomerMatches.Provider value={controller}>
			{children}
		</ContextCustomerMatches.Provider>
	)
}
