/**
 * A função `updateTeaser` atualiza um objeto teaser no banco de dados usando um
 * ID e objeto clonados, exibindo uma tela de carregamento modal durante o
 * processo.
 * @param - A função `updateTeaser` recebe um objeto como parâmetro com dois
 * propriedades:
 */
//CRIADO POR IA

import { startModalLoading } from '../../../Components/Modal'
import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeTeaser } from '../types/teaser'

export const updateTeaser = ({ id, obj }: { id: string; obj: Partial<TypeTeaser> }) => {

	const cloneId = clone(id)
	const cloneObj = clone(obj)

	startModalLoading(async (success: any) => {
		await db5.teasers.update({ id: cloneId, doc: cloneObj })
		success()
	})
}
