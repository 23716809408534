import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type GroupAgentInfosForRankResponse = {
	status: number,
	doc: any // Represents any object
}

export const _groupAgentInfosForRank = (): Promise<{ data: GroupAgentInfosForRankResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'groupAgentInfosForRank' })

