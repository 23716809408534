import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const FolderOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M5.82558 4H12.1846C12.6132 4 13.0135 4.20196 13.2513 4.5382L15.4143 7.59712H26.1815C27.1925 7.60237 28.1605 7.98337 28.8754 8.65737C29.5903 9.33137 29.9944 10.244 30 11.1972L30 11.2038L30 24.3999C29.9944 25.3531 29.5903 26.2657 28.8754 26.9398C28.1605 27.6138 27.1925 27.9947 26.1815 28L26.1744 28H5.81852C4.80751 27.9947 3.83949 27.6138 3.12459 26.9398C2.40968 26.2657 2.00559 25.3531 2.00002 24.3999L2 24.3933V7.60005C2.00557 6.64689 2.40968 5.73425 3.12459 5.06025C3.83949 4.38624 4.80751 4.00527 5.81852 4.00002L5.82558 4ZM5.82972 6.41727C5.49487 6.41973 5.17441 6.54622 4.93757 6.76951C4.70073 6.9928 4.56656 7.29492 4.56395 7.61062V24.3894C4.56656 24.7051 4.70073 25.0072 4.93757 25.2305C5.17442 25.4538 5.49489 25.5803 5.82975 25.5827H26.1702C26.5051 25.5803 26.8256 25.4538 27.0624 25.2305C27.2993 25.0072 27.4334 24.7051 27.436 24.3894V11.2078C27.4334 10.8921 27.2993 10.5899 27.0624 10.3666C26.8256 10.1434 26.5051 10.0169 26.1703 10.0144H14.7282C14.2996 10.0144 13.8993 9.81242 13.6615 9.47618L11.4985 6.41727H5.82972Z' fill='#272827' />
	</Svg>
)
