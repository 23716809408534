import { DocumentData } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import { generateUniqueId } from 'Functions/Utils/generateUniqueId'
import { TypeCollections } from '../configs/collections'
import { TypeDbResponse } from '../TypeDBResponse'

export const add = <TDoc extends DocumentData>(collection: TypeCollections, model: (doc?: DocumentData) => TDoc,) => (
	async ({ doc, _id }: { doc: Partial<TDoc>, _id?: string }): Promise<TypeDbResponse<TDoc>> => {

		const serviceResult: TypeDbResponse<TDoc> = {
			status: 400
		}

		try {

			const id = _id || generateUniqueId(collection.substring(0, 2))

			await firebase
				.firestore()
				.collection(collection)
				.doc(id)
				.set(model({ ...doc, _id: id }))

			serviceResult.status = 200
			serviceResult.id = id
		}
		catch (error) {
			// throw new Error(`Erro ao adicionar documento :: ${error}`)
		}

		return serviceResult
	}
)
