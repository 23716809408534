import React, { FC, HTMLAttributes, ReactChild } from 'react'
import styled from 'styled-components'
import { Default, ITheme } from 'Components/CardDS/src'
import { Content } from './content'
import { Header } from './header'
import { Sider } from './sider'

/** @deprecated */
export interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
	/** Component children */
	children?: ReactChild | ReactChild[];
	/** If component will fill the window height (min-height:100vh) */
	fullHeight?: boolean;
	/** If component have fixed header */
	fixedHeader?: boolean;
	/** If component have fixed sider */
	fixedSider?: boolean;
	/** Sider width */
	siderWidth?: number;
}

/** @deprecated */
export interface InternalProps extends LayoutProps {
	theme: ITheme,
	hasSider?: boolean;
	siderWidth?: number;
}

const LayoutStyled = styled.section<LayoutProps>`
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex: auto;
    position: relative;
    ${(props: InternalProps) => props.siderWidth ? `margin-left: ${props.siderWidth}px;` : ''}
    ${props => props.fullHeight ? 'min-height: 100vh;' : ''}
    ${(props: InternalProps) => props.hasSider ? 'flex-direction: row;' : ''}

    ${(props: InternalProps) => `@media(max-width: ${props.theme.breakpoints?.sizes?.md}px){
          margin-left: 0;
        }`}
`

/**
 * A custom layout component with fixed sider
 */
/** @deprecated */
export const Layout: FC<LayoutProps> = props => {
	const _props: InternalProps = { ...props } as InternalProps
	const { children } = _props
	let hasSider = false
	let siderWidth: number | undefined = 0

	const _c: any = React.Children.map(children, (child: any) => {
		if (React.isValidElement(child)) {
			const t: any = child.type
			const _newChildProps: any = {}
			const name = t.name || t.displayName
			if (name && name.toLowerCase().indexOf(Sider.name.toLowerCase()) >= 0) {
				hasSider = true
				const cp: any = child.props
				if (cp && cp.width) {
					siderWidth = cp.width || 0
				}
			}

			if (name && name.toLowerCase().indexOf(Header.name.toLowerCase()) >= 0) {
				if (props.fixedHeader) {
					_newChildProps.fixed = true
					_newChildProps.siderWidth = 220
				}
			}

			if (name && name.toLowerCase().indexOf(Content.name.toLowerCase()) >= 0) {
				if (props.fixedHeader) {
					_newChildProps.hasFixedHeader = true
				}
			}

			return React.cloneElement(child, _newChildProps)
		}
		return null
	})

	_props.hasSider = hasSider
	_props.siderWidth = siderWidth

	return <LayoutStyled {..._props}>{_c}</LayoutStyled>
}

const DP: InternalProps = {
	theme: Default,
	hasSider: false
}

Layout.defaultProps = DP
