import { clone } from './clone'


// function dateYMDMS_toDate(myDate) {
// 	let newDate
// 	myDate = myDate.toString()
// 	new Date(myDate.substr(0, 4), Number(myDate.substr(4, 2)) - 1, myDate.substr(6, 2), myDate.substr(8, 2), myDate.substr(10, 2), myDate.substr(12, 2))
// 	return newDate
// }


// function dateYMDMS(newDate = new Date()) {
// 	const monthAsString = (newDate.getMonth() + 1).toString()
// 	const dayAsString = (newDate.getDate()).toString()
// 	const hoursAsString = (newDate.getHours()).toString()
// 	const minutesAsString = (newDate.getMinutes()).toString()
// 	const secondsAsString = (newDate.getSeconds()).toString()
// 	const monthFormatted = monthAsString.padStart(2, '0')
// 	const dayFormatted = dayAsString.padStart(2, '0')
// 	const hoursFormatted = hoursAsString.padStart(2, '0')
// 	const minutesFormatted = minutesAsString.padStart(2, '0')
// 	const secondsFormatted = secondsAsString.padStart(2, '0')
// 	const year = newDate.getFullYear()
// 	const dateFormatted = `${year}${monthFormatted}${dayFormatted}${hoursFormatted}${minutesFormatted}${secondsFormatted}`
// 	// 4 + 2 + 2 + 2 + 2 + 2 = 14
// 	return dateFormatted
// }

// export const encrypt = email => {

// 	function criptografar(mensagem, chave) {
// 		let resultado = ''
// 		for (let i = 0; i < mensagem.length; i++) {
// 			let letra = mensagem.charCodeAt(i)
// 			let chaveLetra = chave.charCodeAt(i % chave.length)
// 			let letraCriptografada = String.fromCharCode(letra ^ chaveLetra)
// 			resultado += letraCriptografada
// 		}
// 		return resultado
// 	}

// 	let date = new Date()
// 	let timestamp = dateYMDMS(date) // yyyyMMddHHmmSS a string tera sempre 14 caracteres
// 	// console.log(timestamp)

// 	let hash = criptografar(email, (`budapest-${timestamp}`))

// 	let encodedString = btoa(timestamp + hash)
// 	return encodedString
// }


/**
 * Retorna uma string formatada representando a data e hora atual no formato "yyyyMMddHHmmSS".
 * Se nenhum argumento for fornecido, a data e hora atual serão usadas.
 *
 * @param newDate Opcional. Uma instância de Date representando a data e hora desejadas.
 * @returns Uma string formatada representando a data e hora no formato "yyyyMMddHHmmSS".
 *
 * @example
 * dateYMDMS(new Date('2023-03-24')) => '20230324000000'
 * / Retorna uma string representando a data '24 de março de 2023, 00:00:00'.
 */
function dateYMDMS(newDate = new Date()) {
	// Clona a data para evitar alterações indesejadas
	const cloneNewDate = clone(newDate)
	// Extrai os componentes da data para strings e formata-os adequadamente
	const monthAsString = (cloneNewDate.getMonth() + 1).toString()
	const dayAsString = cloneNewDate.getDate().toString()
	const hoursAsString = cloneNewDate.getHours().toString()
	const minutesAsString = cloneNewDate.getMinutes().toString()
	const secondsAsString = cloneNewDate.getSeconds().toString()
	const monthFormatted = monthAsString.padStart(2, '0')
	const dayFormatted = dayAsString.padStart(2, '0')
	const hoursFormatted = hoursAsString.padStart(2, '0')
	const minutesFormatted = minutesAsString.padStart(2, '0')
	const secondsFormatted = secondsAsString.padStart(2, '0')
	const year = cloneNewDate.getFullYear()
	const dateFormatted = `${year}${monthFormatted}${dayFormatted}${hoursFormatted}${minutesFormatted}${secondsFormatted}`
	// 4 + 2 + 2 + 2 + 2 + 2 = 14
	return dateFormatted
}


/**
 * Criptografa um endereço de e-mail e retorna uma string codificada contendo o timestamp e o hash do e-mail.
 *
 * @param email O endereço de e-mail a ser criptografado.
 * @returns Uma string codificada contendo o timestamp e o hash do e-mail.
 *
 * @example
 * encrypt('usuario@exemplo.com') => 'YWJjZGVmMTIzNDU2bGVlMTIzNDU2'
 * / Retorna uma string codificada que inclui o timestamp e o hash do e-mail.
 */

export const encrypt = (email: string | null) => {

	const cloneEmail = clone(email)

	function criptografar(mensagem: string, chave: string) {

		const cloneMensagem = clone(mensagem)
		const cloneChave = clone(chave)

		let resultado = ''
		for (let i = 0; i < cloneMensagem.length; i++) {
			const letra = cloneMensagem.charCodeAt(i)
			const chaveLetra = cloneChave.charCodeAt(i % cloneChave.length)
			const letraCriptografada = String.fromCharCode(letra ^ chaveLetra)
			resultado += letraCriptografada
		}
		return resultado
	}

	const date = new Date()
	const timestamp = dateYMDMS(date) // yyyyMMddHHmmSS a string tera sempre 14 caracteres
	// console.log(timestamp)

	const hash = criptografar(cloneEmail || '', `budapest-${timestamp}`)

	const encodedString = btoa(timestamp + hash)
	return encodedString
}
