import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { _addYearDate } from './_addYearDate'

/**
 * Retorna a data de término do contrato de um teaser. Se a data de término do contrato estiver definida no teaser, ela será retornada.
 * Caso contrário, a função calculará a data de término do contrato adicionando um ano à data de contrato.
 *
 * @param {TypeTeaser} teaser - O teaser para o qual a data de término do contrato será obtida.
 * @returns {Date} - A data de término do contrato.
 * @example
 * / Exemplo de uso:
 * const teaser = {
 *   dataContrato: 20230304000000
 *   dateContractEnd: 20240409000000
 * };
 *
 * const dateEnd = getDateContractEnd(teaser);
 * console.log(dateEnd); // Saída: Date object representing 20240409000000
 *
 * const teaser2 = {
 *   dataContrato: 20230304000000
 * };
 *
 * const dateEnd2 = getDateContractEnd(teaser2);
 * console.log(dateEnd2); // Saída: 20240409000000
 */
export const getDateContractEnd = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.dateContractEnd || _addYearDate(cloneTeaser.dataContrato, 1)

}
