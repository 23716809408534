import { clone } from '../../../../Functions/Utils/clone'
import { scoreStarkMin } from './scoreStarkMin'
import { TypeScoreStark } from './types/TypeScoreStark'

export const normalize = (_score: TypeScoreStark) => {
	const score = clone(_score)

	score.scoreNote = +score.scoreItens.reduce((acc, value) => acc + +(value.pontos), 0).toFixed(1)
	score.scoreNote = score.scoreNote > 10 ? 10 : score.scoreNote
	score.scoreNote = score.scoreNote < 0 ? 0 : score.scoreNote

	score.approved = score.scoreNote >= scoreStarkMin
	score.scoreColor = score.approved ? 'green' : 'red'

	return score
}
