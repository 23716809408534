const _TYPES_AGENT = {
	'0001': {
		_id: '0001' as const,
		label: ''
	},
	'0002': {
		_id: '0002' as const,
		label: 'Confrade'
	},
	'0003': {
		_id: '0003' as const,
		label: 'Agente Institucional'
	},
	'0004': {
		_id: '0004' as const,
		label: 'Agente Multiplicador'
	},
}

export type TYPES_AGENT_KEYOF_TYPEOF = keyof typeof _TYPES_AGENT

export const TYPES_AGENT = {
	VALUES: Object.values(_TYPES_AGENT),
	KEYS: Object.keys(_TYPES_AGENT),
	GET: (code: string) => _TYPES_AGENT[code as keyof typeof _TYPES_AGENT] || _TYPES_AGENT['0001']
}
