import { clone } from '../../Utils/clone'
import { TypeMatch } from '../types/match'
import { deleteDocumentInStorage } from './deleteDocumentInStorage'
import { db5 } from '../../../Services/db5'

/**
 * Responsável por remover um documento de um match
 * @param match
 * @param documentId
 * @returns match
 */
export const removeDocument = async ({ match, documentId }: { match: TypeMatch, documentId: string }) => {
	const cloneMatch = clone(match)
	const cloneDocumentId = clone(documentId)

	const { _id } = cloneMatch
	cloneMatch.documents = cloneMatch.documents.filter(document => document._id !== cloneDocumentId)
	await db5.matches.update({ id: _id, doc: { documents: cloneMatch.documents } })
	deleteDocumentInStorage({ path: `matches/${_id}/documents/`, key: cloneDocumentId })
	return cloneMatch
}
