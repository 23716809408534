import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PresentationOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M27.9167 5.16667H17.0833V4.08333C17.0833 3.43333 16.65 3 16 3C15.35 3 14.9167 3.43333 14.9167 4.08333V5.16667H4.08333C3.43333 5.16667 3 5.6 3 6.25C3 6.9 3.43333 7.33333 4.08333 7.33333H5.16667V16C5.16667 18.3833 7.11667 20.3333 9.5 20.3333H14.9167V22.0667L9.825 27.1583C9.39167 27.5917 9.39167 28.2417 9.825 28.675C10.2583 29.1083 10.9083 29.1083 11.3417 28.675L14.9167 25.1V27.9167C14.9167 28.5667 15.35 29 16 29C16.65 29 17.0833 28.5667 17.0833 27.9167V25.1L20.6583 28.675C21.0917 29.1083 21.7417 29.1083 22.175 28.675C22.6083 28.2417 22.6083 27.5917 22.175 27.1583L17.0833 22.0667V20.3333H22.5C24.8833 20.3333 26.8333 18.3833 26.8333 16V7.33333H27.9167C28.5667 7.33333 29 6.9 29 6.25C29 5.6 28.5667 5.16667 27.9167 5.16667ZM24.6667 16C24.6667 17.1917 23.6917 18.1667 22.5 18.1667H9.5C8.30833 18.1667 7.33333 17.1917 7.33333 16V7.33333H24.6667V16Z' fill='#272827' />
		<path d='M11.6663 13.8335C12.3163 13.8335 12.7497 14.2668 12.7497 14.9168C12.7497 15.4585 12.3163 16.0002 11.6663 16.0002C11.1247 16.0002 10.583 15.5668 10.583 14.9168C10.583 14.2668 11.0163 13.8335 11.6663 13.8335Z' fill='#272827' />
		<path d='M16.0003 11.6665C16.6503 11.6665 17.0837 12.0998 17.0837 12.7498V14.9165C17.0837 15.4582 16.6503 15.9998 16.0003 15.9998C15.4587 15.9998 14.917 15.5665 14.917 14.9165V12.7498C14.917 12.0998 15.3503 11.6665 16.0003 11.6665Z' fill='#272827' />
		<path d='M20.3333 9.5C20.9833 9.5 21.4167 9.93333 21.4167 10.5833V14.9167C21.4167 15.4583 20.9833 16 20.3333 16C19.7917 16 19.25 15.5667 19.25 14.9167V10.5833C19.25 9.93333 19.6833 9.5 20.3333 9.5Z' fill='#272827' />
	</Svg>
)
