import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'
import { fnValuation } from '.'
import { TRANSACTION_FIELDS } from 'Screens/Sinatra/Constants/TransactionsFields'
import { TypeTransactionProperties } from '../../Types/TypeTransactionProperties'
import { fnTransaction } from '../Transaction'
/**
 *
 */
export const getValuationMedian = ({ valuation }: { valuation: TypeValuation }) => {
	const mediansOfClosedCapital: TypeValuation['mediansOfClosedCapital'] = {} as TypeValuation['mediansOfClosedCapital']
	const mediansOfOpenCapital: TypeValuation['mediansOfOpenCapital'] = {} as TypeValuation['mediansOfOpenCapital']
	const mediansTotal: TypeValuation['mediansTotal'] = {} as TypeValuation['mediansTotal']

	TRANSACTION_FIELDS.map(property => {
		const _property = property as keyof TypeValuation['mediansOfClosedCapital']
		mediansOfClosedCapital[_property] = fnTransaction.getMedian({ valuation, property: property as TypeTransactionProperties, type: 'private' })
		mediansOfOpenCapital[_property] = fnTransaction.getMedian({ valuation, property: property as TypeTransactionProperties, type: 'public' })
		mediansTotal[_property] = fnTransaction.getMedian({ valuation, property: property as TypeTransactionProperties })
	})
	return {
		mediansOfClosedCapital,
		mediansOfOpenCapital,
		mediansTotal
	}
}

