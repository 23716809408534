import { notifications } from '../../../Services/cloudfunctions'

import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../../Match/types/match'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { fnNotification } from '..'
import { clone } from '../../../Functions/Utils/clone'
import { fnError } from '../../Errors/fnError'

export type Props = {
	matchId: string
	match: TypeMatch
	profile: TypeUser
	uid: string
	origination: TypeTeaser
	originationId: string
	institution: TypeInstitution
	institutionId: string
}

export const sendEnviadoInactive =
	async ({
		matchId,
		match,
		profile,
		uid,
		origination,
		originationId,
		institution,
		institutionId
	}: Props) => {

		const cloneMatchId = clone(matchId)
		const cloneMatch = clone(match)
		const cloneProfile = clone(profile)
		const cloneUid = clone(uid)
		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneInstitution = clone(institution)
		const cloneInstitutionId = clone(institutionId)

		const response = await fnNotification.customer.sendEnviadoInactive({
			matchId: cloneMatchId,
			match: cloneMatch,
			profile: cloneProfile,
			uid: cloneUid,
			origination: cloneOrigination,
			originationId: cloneOriginationId,
			institution: cloneInstitution,
			institutionId: cloneInstitutionId
		})
		if (response.status !== 200) fnError.alert({ message: 'Ocorreu um erro, tente novamente mais tarde' })

		await notifications({
			targetId: originationId,
			typeOrMessage: 'a0009',
			profileUid: uid,
		})
	}


