import { TypeTeaser } from '../types/teaser'
/**
 * Ordena um array de teasers pelo nome da empresa
 * @param teasers
 * @param order
 * @returns  TypeTeaser[]
 */
export const sortByName = ({ teasers, order }: { teasers: TypeTeaser[], order: 'asc' | 'desc' }): TypeTeaser[] =>
	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
		const isAsc = order === 'asc'
		const nameA = teaserA?.fantasyName || teaserA?.nome || ''
		const nameB = teaserB?.fantasyName || teaserB?.nome || ''

		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})
