export const IMG_S = {
	LOGO_TEASER: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fbuild-xl.png?alt=media&token=368b0de3-1051-45ed-9980-fc26129fb683',
	LOGO_STARK: '/assets/img/stark-header-off.png',
	// USER_PROFILE: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
	USER_PROFILE: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fperson-xl.png?alt=media&token=c4fbe3ed-43ab-418d-9e6a-a347f3eb75c6',
	USER_PROFILE_MD: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fperson-md.png?alt=media&token=2ae81451-2a94-4d2e-aec3-30b6c8986201',
	TESE: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fpaper-xl.png?alt=media&token=3709ebd7-ee58-4aea-acb5-ee580a6d35c9',
	TESE_MD: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fpaper-md.png?alt=media&token=38e782d8-1d43-4041-8232-ac872a13b19b',
	CUSTOMER: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fbuild-xl.png?alt=media&token=368b0de3-1051-45ed-9980-fc26129fb683',
	CUSTOMER_MD: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fbuild-md.png?alt=media&token=2c7f3b63-6d75-4bcf-b1ad-f24d13ba3180',
	INSTITUTION_LOGO: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fbank-xl.png?alt=media&token=85b94a4b-5bd7-49d1-a55f-b43f07db5762',
	INSTITUTION_LOGO_MD: 'https://firebasestorage.googleapis.com/v0/b/stark-plataform.appspot.com/o/assets%2Favatar%2Fbank-md.png?alt=media&token=b8171ee8-4f0c-4f14-9e21-db930a6121e7',

} as const

export const IMG_S_GET = (code: string) => {
	const _code = code as keyof typeof IMG_S

	return IMG_S[_code] || IMG_S['LOGO_STARK']
}
export const IMG_S_VALUES = Object.values(IMG_S)
export const IMG_S_KEYS = Object.keys(IMG_S)
