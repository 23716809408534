/**
 * O tipo `TypeCombinationCredit` define uma estrutura para armazenar combinações de
 * teaser, crédito, instituição e detalhes para processamento do algoritmo de crédito.
 * @propriedade {{
 * teaserId: string,
 * teaser: TypeTeaser
 * teseCredit: TypeTeseCredit
 * teseCreditId: string
 * instituição: TypeInstituição
 * instituiçãoId: string
 * //correspondência: TypeMatch
 * 		detalhes: {
 * cobrança: booleano
 * valores: booleano
 * rj: booleano
 *garantiasCover: booleano
 *garantiasCoverDetailsGarantias: qualquer[]
 * tagsSectorMatch: string[]
 * }
 * aprovado: booleano,
 * }[]} [instituiçãoId: {
 * teaserId: string,
 * teaser: TypeTeaser
 * teseCredit: TypeTeseCredit
 * teseCreditId: string
 * instituição: TypeInstituição
 * instituiçãoId: string
 * //correspondência: TypeMatch
 * 		detalhes: {
 * cobrança: booleano
 * valores: booleano
 * rj: booleano
 *garantiasCover: booleano
 *garantiasCoverDetailsGarantias: qualquer[]
 * tagsSectorMatch: string[]
 * }
 * aprovado: booleano,
 * }[]] - Este código define um tipo `TypeCombinationCredit` que representa um
 * combinação de diversas propriedades relacionadas a operações de crédito. Aqui está um
 *detalhamento do que cada imóvel representa:
 */
// CRIADO POR IA

import { TypeInstitution } from '../../Institution/types/institution'
import { TypeInstitutions } from '../../Institution/types/institutions'
import { TypeMatches } from '../../Match/types/matches'
import { TypeTeaser } from '../types/teaser'
import { TypeTeseCredit } from '../../TeseCredit/types/teseCredit'
import { TypeTesesCredit } from '../../TeseCredit/types/tesesCredit'
import { fnTeaser } from '..'
import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { clone } from '../../../Functions/Utils/clone'

export type TypeCombinationCredit = {
	[institutionId: string]: {
		teaserId: string,
		teaser: TypeTeaser
		teseCredit: TypeTeseCredit
		teseCreditId: string
		institution: TypeInstitution
		institutionId: string
		// match: TypeMatch
		details: {
			billing: boolean
			values: boolean
			rj: boolean
			guaranteesCover: boolean
			guaranteesCoverDetailsGuarantees: any[]
			tagsSectorMatch: string[]
		}
		approved: boolean,
	}[]
}

//	Filtro de risco
//	valor min / max da operação
//	faturamento mínimo (if corporate)
//	ok opera ou não opera com RJ
//	2 nível => pegar todas as garantias das categorias aceitas pelo investidor e verificar se cobre o valor pedido
//	(nao performado) tem que dar match com (cat=garantia recebiveis imobiliarios > imoveis na planta)
//	(nao performado) tem que dar match com (cat=garantia recebiveis > (tipo contratos publicos || tipos contratos privados))
//	(nao performado) tem que dar match com (cat= termino de obra > saldo da carteira de recebíveis)

// -- não entrará no algoritmo
//	se teaser tiver garantias in list de garantias de tese nas 3 camadas. se o investidor colocar cat tipo e valor, e obrigatório bater os3
//				se colocou cat e tipo, e obrigatório bater os 2
//	Conta as garantias aprovadas devem ser >= a % pedida pelo investidor
// 1 nível => pegar todas as garantias e verificar se cobre o valor pedido
// 3 nível (não colocaremos agora) => pegar todas as garantias das categorias aceitas pelo investidor e as % aceitas pelo investidor e verificar se cobre o valor pedido

export const algorithmCredit = (teaser: TypeTeaser, teses: TypeTesesCredit, institutions: TypeInstitutions, teaserId: string, matches: TypeMatches): TypeCombinationCredit => {

	const cloneTeaser = clone(teaser)
	const cloneTeses = clone(teses)
	const cloneInstitutions = clone(institutions)
	const cloneTeaserId = clone(teaserId)
	const cloneMatches = clone(matches)

	const verifyCombination = (teaser: TypeTeaser, teseCredit: TypeTeseCredit, teseCreditId: string, institution: TypeInstitution, institutionId: string, teaserId: string) => {

		const matchesForInstitutionsId: string[] = []
		Object.entries(cloneMatches)
			.filter(([matchId, match]) => match.typeOperation.includes(TYPE_OPERATION.op2) || match.typeOperation.includes(TYPE_OPERATION.op3))
			.forEach(([matchId, match]) => {
				matchesForInstitutionsId.push(match.idInstitution)
			})

		if (matchesForInstitutionsId.includes(institutionId)) return null

		// // TODO: RETIRAR CARDS DE RISCO ABAIXO
		// // TODO: ENTRAR TODAS AS COMBINAÇÕES DE DCM NO TOTAL DE INVESTIDORES
		// // TODO: MUDAR O NOME DO CARD PARA TOTAL DE INVESTIDORES
		// // TODO: SEPARAR DCM E M&A NO NUMERO DE INVESTIDORES
		// TODO: GARANTIA DEVE BUSCAR TBM O TERMINO DE OBRAS PARA FAZER A CONTA

		// TODO: ? MOSTRAR A GARANTIA MINIMA NA COMBINAÇÃO ?
		// TODO: ? QUER ENCARTEIRAR UM CLIENTE QUE FAÇA O MÍNIMO DE 20KK DE OP NECESSARIAMENTE FAZENDO MAIS DE UMA OPERAÇÃO, O TRIPLO DO TRABALHO PARA O MESMO FEE ?
		// TODO: NO ALGORITMO DE CRÉDITO, GARANTIAS DEVEM VIR DE UM MÉTODO, E NAO LER NO TEASER. (GARANTIAS VINDO DO METODO DEVEM TRAZER ITENS QUE NAO SAO GARANTIAS DIRETAS)

		const combination = {
			teaserId: teaserId,
			teaser: teaser,
			teseCredit: teseCredit,
			teseCreditId: teseCreditId,
			institution: institution,
			institutionId: institutionId,
			details: {
				values: +teaser?.desiredValue >= +teseCredit.operacoesDividaValorMinimo.value && +teaser.desiredValue <= teseCredit.operacoesDividaValorMaximo.value,
				billing: fnTeaser.getRbWithSeptemberRule(teaser) >= teseCredit.operacoesDividaFaturamentoMinimo.value,
				rj: teseCredit.operacoesDividaRecuperacaoJudicial ? (true) : (!teaser.judicialRecovery),
				guaranteesCover: fnTeaser.getAllGuaranteesCover({ teaser, teseCredit }).approved,
				guaranteesCoverDetailsGuarantees: fnTeaser.getAllGuaranteesCover({ teaser, teseCredit }).guarantees,
				tagsSectorMatch: fnTeaser.getTagsSectorMatch(teaser, teseCredit, teseCreditId),
			},
			approved: true,
		}
		combination.approved =
			combination.details.values &&
			combination.details.billing &&
			combination.details.rj &&
			combination.details.guaranteesCover &&
			// combination.details.guaranteesCoverDetailsGuarantees.length > 0
			combination.details.tagsSectorMatch.length > 0

		if (combination.approved) {
			return combination
		}
		return null
	}

	const combinations: TypeCombinationCredit = {}

	Object.entries(cloneTeses).forEach(([teseId, teseCredit]) => {
		const institution = cloneInstitutions[teseCredit?.institutionId]

		if (institution) {
			const comb = verifyCombination(cloneTeaser, teseCredit, teseId, institution, teseCredit?.institutionId, cloneTeaserId)
			if (comb) {
				if (combinations[teseCredit?.institutionId]) {
					combinations[teseCredit?.institutionId].push(comb)
				}
				else {
					combinations[teseCredit?.institutionId] = [comb]
				}
			}
		}

	})

	return combinations
}

/**
 * tese crédito operações Divida Valor Mínimo <= teaser valor desejado teaser.desiredValue <= tese crédito operações Divida Valor Máximo
 * Teaser receita bruta (regra de setembro) >= tese Credit operações Divida Faturamento Mínimo
 * tese Credit operações Divida Recuperação Judicial ? (true) : teaser judicial Recovery
 * fnTeaser.getAllGuaranteesCover({ teaser, teseCredit }).approved     => garantias combinadas é maior ou igual a 20kk // TODO: DEVE OBEDECER O VALOR DO INVESTIDOR
 * fnTeaser.getTagsSectorMatch(teaser, teseCredit, teseCreditId)
*/
