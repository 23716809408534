import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { EnvFlag } from './EnvFlagComponent'
import { GlobalProvider } from './Global'
import { Auth } from './Auth'
import ScriptBda from 'Components/ScriptBda'
import { GlobalLoading } from 'Components/GlobalLoading'
import { GlobalModal } from 'Components/GlobalModal'
import { ModalForContractApproval } from 'Components/ModalForContractApproval'
import { ContextAuthProvider } from './ContextAuth'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ContextUnReadNotificationsProvider } from './ContextUnReadNotifications'
import { ContextMenuProvider } from './ContextMenu'
import { ContextNotificationsFeedCustomerProvider } from './ContextNotificationsFeedCustomer'
import { ContextNotificationWidgetProvider } from './ContextNotificationWidget'
import { ContextCustomerTeaserProvider } from './ContextCustomerTeaser'
import { ContextManualMatchProvider } from './ContextManualMatch'
import { ContextCustomerMatchesProvider } from './ContextCustomerMatches'
import { ContextTools } from './ContextTools'
import { ContextAssistantChatProvider } from './ContextAssistantChat'

const Contexts = (props: { children: React.ReactNode }) => (<>
	<ContextAuthProvider>
		<GlobalProvider>
			<ContextNotificationWidgetProvider>
				<ContextAssistantChatProvider>
					<ContextUnReadNotificationsProvider>
						<ContextNotificationsFeedCustomerProvider>
							<ContextMenuProvider>
								<ContextCustomerTeaserProvider>
									<ContextCustomerMatchesProvider>
										<ContextManualMatchProvider>
											<ContextTools>
												<Auth>
													{props.children}
												</Auth>
											</ContextTools>
										</ContextManualMatchProvider>
									</ContextCustomerMatchesProvider>
								</ContextCustomerTeaserProvider>
							</ContextMenuProvider>
						</ContextNotificationsFeedCustomerProvider>
					</ContextUnReadNotificationsProvider>
				</ContextAssistantChatProvider>
			</ContextNotificationWidgetProvider>
		</GlobalProvider>
	</ContextAuthProvider>
</>)

export default () => (<>
	<EnvFlag />
	<Contexts>
		<>
			<BrowserRouter>
				<Routes />
			</BrowserRouter>
			<ScriptBda />
			<ModalForContractApproval />
			<GlobalModal />
			<GlobalLoading />
		</>
	</Contexts>
	<ToastContainer
		hideProgressBar
		draggable
		pauseOnHover={false}
		closeOnClick={false}
		closeButton={false}
		toastClassName='tw-p-0 tw-cursor-default tw-bg-transparent tw-border-none tw-shadow-none'
	/>
</>)
