import { fnTeaser } from 'Functions/Teaser'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { TypeTese } from 'Functions/Tese/types/tese'

export const combineFinances = (tese: TypeTese, teaser: TypeTeaser) => {
	const ebitda = fnTeaser.getEbWithSeptemberRule(teaser)

	const ebMinSR = +tese.ebmiSR
	const ebMin = +tese.ebmi - +tese.ebmi * 0.05
	const ebMaxSR = +tese.ebmaSR
	const ebMax = +tese.ebma + +tese.ebma * 0.05

	const receita = +fnTeaser.getRbWithSeptemberRule(teaser)

	const rbMinSR = +tese.rbmiSR
	const rbMin = +tese.rbmi - +tese.rbmi * 0.05
	const rbMaxSR = +tese.rbmaSR
	const rbMax = +tese.rbma + +tese.rbma * 0.05

	if (!ebMinSR && ebMin > ebitda) return false
	if (!ebMaxSR && ebMax < ebitda) return false
	if (!rbMinSR && rbMin > receita) return false
	if (!rbMaxSR && rbMax < receita) return false
	return true
}
