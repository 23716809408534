/**
 * A função isPublicado verifica se um teaser tem data de publicação.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 *que provavelmente contém informações sobre um teaser ou prévia de algum conteúdo. O
 * função `isPublicado` verifica se a propriedade `dataPublicacao` do `teaser`
 *objeto é verdadeiro, indicando que o teaser foi
 * @returns A função `isPublicado` recebe um objeto `teaser` do tipo
 * `TypeTeaser`, clona-o usando a função `clone` e depois verifica se o
 * A propriedade `dataPublicacao` do teaser clonado é verdadeira. A função retorna um
 * valor booleano que indica se a propriedade `dataPublicacao` está presente no
 * objeto teaser clonado.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

// is
export const isPublicado = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return !!cloneTeaser.dataPublicacao

}
