import { getTotalDebit } from '../../get/getTotalDebit'
import { genValuation } from '../genCount/genValuation'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreEVNegative = (props: TypeScoreProps) => {
	const res: TypeScoreStark['scoreItens'] = []

	const devolutiva = 'Construa, junto ao time da empresa, formas de reduzir o seu endividamento e/ou elevar a sua margem EBITDA.'

	if (genValuation(props.teaser) - getTotalDebit(props.teaser) >= 0) {
		res.push({ // adiciona 0
			'pontosMax': 10,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': 'EV',
			'item': 'EV positivo',
			'itemDescrição': 'Valuation maior que o débito',
			'metaDados': '',
			devolutiva: ''
		})
	}
	if (genValuation(props.teaser) - getTotalDebit(props.teaser) < 0) {
		res.push({ // adiciona -10
			'pontosMax': 10,
			'pontos': -10,
			'pontosCor': 'red',
			'setor': 'EV',
			'item': 'EV negativo',
			'itemDescrição': 'Valuation menor que o débito',
			'metaDados': '',
			'devolutiva': devolutiva
		})
	}

	return res
}
