import React from 'react'

import currencySymbolMap from 'currency-symbol-map'
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'

export type Country = {
	name: string;
	idd: string;
	acronym: string;
	flag: string;
	postalCode: string;
	currency: {
		style: string; // BRL, USD, EUR
		symbol: string; // R$, $, €
	};
};

export const ModelCountryInitial = (): Country => ({
	name: 'Brasil',
	idd: '+55',
	acronym: 'BR',
	flag: 'https://flagcdn.com/w320/br.png',
	postalCode: '#####-###',
	currency: {
		style: 'BRL',
		symbol: 'R$',
	},
})

export const getOneCountry = async (name: string) => {
	const countries = await getAllCountries()

	return (
		countries.find(c => c.name === name) || {
			name: '',
			idd: '',
			acronym: '',
			flag: '',
			postalCode: '',
			currency: {
				style: '',
				symbol: '',
			},
		}
	)
}

export const getAllCountries = async () => {
	const response: any = await fetch('https://restcountries.com/v3.1/all').then(
		res => res.json()
	)

	const countries: Country[] = response.map(
		(c: any): Country => ({
			name: c?.translations?.por?.common || '',
			acronym: c?.cca2 || '',
			postalCode: c?.postalCode?.format || '',
			flag: c?.flags?.png || '',
			currency: c?.currencies
				? {
					style: Object.keys(c?.currencies)[0] || '',
					symbol: (Object.values(c?.currencies)[0] as any).symbol || '',
				}
				: { style: '', symbol: '' },
			idd:
				(c?.idd?.root || 'Não possui') +
				(c?.idd?.suffixes && c?.idd?.suffixes.length <= 1
					? c?.idd?.suffixes.join('')
					: ''),
		})
	)

	return countries.sort((a, b) => (a.name < b.name ? -1 : 1))
}

// const [cep, setCep] = useState('34567-890')

// <input
// value={masks.cep(cep)} // 34567-890
// onChange={e => setCep(masks.cep(e.target.value))} // 34567-890
// />
// TODO: @cristianaragao Testes automatizados
const CEP = (postalCode: string, format = '#####-###') => {
	const cleanedPostalCode = postalCode.replace(/\D/g, '') // Remove caracteres indesejados

	let formattedPostalCode = ''
	let digitIndex = 0
	let i = 0

	while (i < format.length && digitIndex < cleanedPostalCode.length) {
		const formatChar = format.charAt(i)

		if (formatChar === '#') {
			formattedPostalCode += cleanedPostalCode.charAt(digitIndex)
			digitIndex++
		}
		else {
			formattedPostalCode += formatChar
		}

		i++
	}

	return {
		/**
		 * @description valor formatado
		 *
		 * @example "12345678" -> "12345-678"
		*/
		value: formattedPostalCode,

		/**
		 * @description placeholder que pode ser colocado no input quando não tiver
		 * nenhum valor preenchido
		 *
		 * @example "00000-000"
		*/
		placeholder: format.replaceAll('#', '0'),
	}
}

// TODO: @cristianaragao Testes automatizados
export const CNPJ = (cnpj: string) => {
	const cleanedCNPJ = cnpj.replace(/\D/g, '')

	let formattedCNPJ = cleanedCNPJ.substring(0, 14)

	if (formattedCNPJ.length > 2) {
		formattedCNPJ = formattedCNPJ.replace(/^(\d{2})(\d)/, '$1.$2') // Insere o primeiro ponto
	}

	if (formattedCNPJ.length > 5) {
		formattedCNPJ = formattedCNPJ.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Insere o segundo ponto
	}

	if (formattedCNPJ.length > 8) {
		formattedCNPJ = formattedCNPJ.replace(
			/^(\d{2})\.(\d{3})\.(\d{3})(\d)/,
			'$1.$2.$3/$4'
		) // Insere a barra
	}

	if (formattedCNPJ.length > 12) {
		formattedCNPJ = formattedCNPJ.replace(
			/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/,
			'$1.$2.$3/$4-$5'
		) // Insere o hífen
	}

	return formattedCNPJ
}

// TODO: @cristianaragao Testes automatizados
export const CPF = (cpf: string) => {
	const cleanedCPF = cpf.replace(/\D/g, '')

	let formattedCPF = cleanedCPF.substring(0, 11)

	if (formattedCPF.length > 3) {
		formattedCPF = formattedCPF.replace(/^(\d{3})(\d)/, '$1.$2') // Insere o primeiro ponto
	}

	if (formattedCPF.length > 6) {
		formattedCPF = formattedCPF.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3') // Insere o segundo ponto
	}

	if (formattedCPF.length > 9) {
		formattedCPF = formattedCPF.replace(
			/^(\d{3})\.(\d{3})\.(\d{3})(\d)/,
			'$1.$2.$3-$4'
		) // Insere a barra
	}

	return formattedCPF
}

// TODO: @cristianaragao Testes automatizados
const Phone = (phone: string, acronym = 'BR') => {
	const phoneNumber = parsePhoneNumber(phone, acronym as CountryCode)

	return {
		format: phoneNumber?.formatInternational() || phone,
		isValid: phoneNumber?.isValid() || false,
		isPossible: phoneNumber?.isPossible() || false,
	}
}

export const Url = (site: string) => {
	if (!site) {
		return ''
	}
	if (site.match('http://') || site.match('https://')) {
		return site
	}
	else {
		return `http://${site}`
	}
}

// const [dias, setDias] = useState(0)

// <input
// value={masks.Time(dias)} // '0 dias'
// onChange={e => setDias(masks.toNatural(e.target.value)) // 0
// } />

const Time = (time: number, format = 'unidade não definida') =>
	`${time} ${format}`

const delTime = (e: React.KeyboardEvent<HTMLInputElement>, value: number) => {
	if (e.key === 'Backspace') {
		// Código da tecla "Backspace"
		e.preventDefault()
		let isNegative = false
		if (value < 0) {
			value = Math.abs(value)
			isNegative = true
		}
		let novoNumero = Math.floor(value / 10)
		if (isNegative) novoNumero *= -1
		return novoNumero
	}
	return value
}

// igual ao Time
// quando for escrito currencyToNumber
const Currency = (value: number, countryCode = 'BRL') => {
	let currencyCode = currencySymbolMap(countryCode)

	if (!currencyCode) {
		currencyCode = ''
	}

	const numericValue = value || 0

	let formattedValue = ''
	if (numericValue < 0) {
		const absoluteValue = Math.abs(numericValue)
		formattedValue = `${currencyCode} -${absoluteValue.toLocaleString(
			undefined,
			{
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
				useGrouping: true,
			}
		)}`
	}
	else {
		formattedValue = `${currencyCode} ${numericValue.toLocaleString(undefined, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			useGrouping: true,
		})}`
	}

	return formattedValue
}

// igual ao Time
// salva como inteiro
// 50 => 50%
const Percent = (value: number, fraction = 1) => {
	let valueS = Intl.NumberFormat('default', {
		style: 'percent',
		minimumFractionDigits: fraction,
		maximumFractionDigits: fraction,
	}).format((value || 0) / 100)
	valueS = valueS.replace('%', ' %')
	return valueS
}


// 50% => 50
const toPercent = ({
	value,
	max,
	min,
	fraction = 1
}: {
	value: string;
	max?: number;
	min?: number;
	fraction?: number;
}) => {

	const isNegative = value.includes('-')

	value = value.replace(/\D/g, '')

	let valueN = parseFloat(value || '0')

	if (fraction !== 0) {
		valueN /= 10
	}

	if (isNegative && min) valueN *= -1

	if (fraction === 0) {
		if (min && valueN < min) return min
		if (max && valueN > max) return max
	}
	else {
		if (min && valueN < min * 100) return min * 100
		if (max && valueN > max * 100) return max * 100
	}

	return valueN
}

const delPercent = (value: string) => {
	const valueNumber = value.replace(/[^\d-]/g, '')
	let valueN = Number(valueNumber || '0')
	return (valueN *= 10).toString()
}

const currencyToNumber = (value = '0') => {
	let isNegative = value.includes('-')

	value = value.replace(/[^\d-]/g, '')

	if (isNegative) {
		if (value.includes('-00')) {
			isNegative = false
		}

		value = value.replaceAll('-', '')
	}

	let result = parseFloat((Number(value) / 100).toFixed(2))

	if (isNegative) {
		result *= -1
	}

	return result
}

const toInteger = (value = ''): number => {
	let valueNumber = Number(value.replace(/\D/g, '') || '0')
	const possuiTraco = value.includes('-')

	if (possuiTraco && value) valueNumber *= -1

	return valueNumber
}

const toNatural = (value = ''): number => {
	const valueNumber = Number(value.replace(/\D/g, '') || '0')
	return valueNumber
}

const toString = (value = ''): string => value.replace(/\D/g, '')

const toDate_DD_MM_YYYY = (value = ''): string => {
	value = value.replace(/\D/g, '')
	value = value.substring(0, 8)

	if (value.length > 2) {
		value = value.replace(/^(\d{2})(\d)/, '$1/$2')
	}

	if (value.length > 4) {
		value = value.replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3')
	}

	return value

}

/**
 * @description máscaras de formatação para inputs
 *
 * @example CPF - 000.000.000-00
 * @example CEP - 00000-000
*/

export const masks = {

	/**
	 * @description funções que servem para formatar o valor
	 *
	 * @example cpf -> "12345678912" -> "123.456.789-12"
	 * @example number_unit -> "12" -> "12 meses"
	*/
	formatter: {
		cpf: CPF,
		cnpj: CNPJ,
		cep: CEP,
		currency: Currency,
		url: Url,
		phone: Phone,
		number_unit: Time,
		date_DD_MM_YYYY: toDate_DD_MM_YYYY,
		percent: Percent,
	},

	/**
	 * @description funções que servem para desformatar o valor para o valor "primitivo"
	 * (number ou string). Nem todas as funções do "formatter" tem sua correspondência no
	 * "parser" pois nem tudo será necessário desformatar, por exemplo, o cpf já vem como string
	 * então logo não tem necessidade de criar uma funçaõ para retornar uma string novamente
	 *
	 * @example currency -> "R$ 50,00" -> 50
	 * @example percent -> "50%" -> "0.5"
	*/
	parser: {
		currency: currencyToNumber,
		percent: toPercent,
		phone: toString,
		number_unit: toInteger,
	},

	/**
	 * @description funções que servem para ajudar na formatação de certos valores.
	 * Por exemplo:
	 *
	 * Se num input tiver o valor "50%"", se o usuário apertar "Backspace" o novo valor será "50"
	 * mas o certo seria o novo valor ser "5%"
	*/
	helper: {
		number_unit: delTime,
		percent: delPercent,
		integer: toInteger,
		natural: toNatural
	},

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	CPF,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	CNPJ,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	CEP,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	Currency,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	Url,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	Phone,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	toString,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	Time,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	toDate_DD_MM_YYYY,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	Percent,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	toPercent,

	/**
	 * @deprecated usar de dentro das funções "formatter" e "parser"
	*/
	currencyToNumber
}
