import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { STATUS_MATCH_MAKING } from '../../../Constants/STATUS_MATCH_MAKING'
import { TypeMatch } from '../types/match'
import { fnMatch } from '..'
import { clone } from '../../../Functions/Utils/clone'


export const inMatchDeclined = (match: TypeMatch) => {

	const cloneMatch = clone(match)

	return fnMatch.status(cloneMatch) === STATUS_MATCH.match.value && fnMatch.matchMakingStatus(cloneMatch) === STATUS_MATCH_MAKING.inativo.value
}
