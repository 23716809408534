import { useCallback, useEffect, useState } from 'react'
import { TypeNotifications } from 'Functions/Notification/types/notification'
import { fnNotification } from 'Functions/Notification'
import { PERMISSIONS_NOTIFICATIONS, TypePermissionsNotifications } from 'Constants/PERMISSIONS_NOTIFICATIONS'
import firebase from 'firebase/app'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { fnUser } from 'Functions/User'
import { useContextUnReadNotifications } from 'App/ContextUnReadNotifications'
import { db5 } from '../Services/db5'
import { WhereFilterOp } from '@firebase/firestore-types'
type Props = {
	uiPermission: TypePermissionsNotifications
	matchId?: string
	teaserId?: string
	uid?: string
	profile?: TypeUser
	/**
	 * index a ser carregado no relatório semanal. 0 => semana atual, 1 => semana anterior...
	 */
	index?: number
}

function getFilter(i: number) {

	const currentDate = new Date()

	const firstDayInWeek = new Date(currentDate)

	firstDayInWeek.setDate(currentDate.getDate() - currentDate.getDay())

	const lastDayInWeek = new Date(firstDayInWeek)

	lastDayInWeek.setDate(firstDayInWeek.getDate() + 6)

	// Subtrai o número de semanas com base no índice fornecido
	firstDayInWeek.setDate(firstDayInWeek.getDate() - (i * 7))
	lastDayInWeek.setDate(lastDayInWeek.getDate() - (i * 7))

	firstDayInWeek.setHours(0, 0, 0, 0)

	lastDayInWeek.setHours(23, 59, 59, 999)

	return {
		from: firstDayInWeek,
		to: lastDayInWeek > currentDate ? currentDate : lastDayInWeek
	}
}

export const useNotifications = (props: Props) => {
	const { uiPermission, matchId, teaserId, index, profile } = props
	const [notifications, setNotifications] = useState<TypeNotifications>({})
	const [loading, setLoading] = useState(true)
	const [filter, setFilter] = useState<{ from: Date, to: Date }>(getFilter(index || 0))
	const checkUser = useCallback((wheres: ((string | boolean)[] | (string | undefined | Date)[])[]) => {
		wheres.push(['showCustomer', '==', !!(profile && fnUser.isCustomer(profile))])
		return wheres
	}, [profile])

	// const { unReadNotifications } = useGlobalContext()
	const { unReadNotifications, setUnReadNotifications } = useContextUnReadNotifications()

	useEffect(() => {
		async function loadWidget() {
			if (!profile) {
				return
			}
			// novas notificações
			const wheres: [string, WhereFilterOp, any][] = [
				// ['uids', 'array-contains', profile?._id],
			]

			checkUser(wheres)

			db5.notifications.getWhereSync({
				profile,
				wheres, callBack: docs => {
					// garante que apenas notificações de widget apareçam
					const data: TypeNotifications = Object
						.keys(docs.docs || {})
						.reduce((prev, cur) => ({ ...prev, [cur]: fnNotification.modelNotification(docs?.docs?.[cur]) }), {})
					setNotifications(n => ({ ...n, ...data }))
					setLoading(false)
				}
			})
			// notificações antigas
			db5.notifications.getWhereSync({
				profile,
				wheres: [['uid', '==', profile?._id]], callBack: docs => {
					const data: TypeNotifications = Object
						.keys(docs.docs || {})
						.reduce((prev, cur) => ({ ...prev, [cur]: fnNotification.modelNotification(docs?.docs?.[cur] || {}) }), {})
					setNotifications(n => ({ ...n, ...data }))
					setLoading(false)
				}
			})
		}
		if (profile && (fnUser.isAgent(profile) || fnUser.isCustomer(profile))) loadWidget()

	}, [profile, uiPermission, checkUser])

	useEffect(() => {
		async function loadHistory() {
			// garante que os checkpoints antigos apareçam no resultado

			firebase.database().ref(`/checkpoints/${matchId}`)
				.on('value', snapshot => {
					const data = snapshot.val()
					let result = {}
					if (data) {
						result = Object.keys(data)
							.filter(doc => profile && !fnUser.isAdm(profile) ? data[doc].hidden : true)
							.reduce((prev, cur) => {
								data[cur].date = cur
								return { ...prev, [cur]: fnNotification.modelNotification(data[cur]) }
							}, {})

					}

					const wheres: [string, WhereFilterOp, any][] = []

					if (profile && !fnUser.isAdm(profile) && !fnUser.isOffice(profile)) {
						wheres.push(['uids', 'array-contains', profile._id])
						wheres.push(['showCustomer', '==', true])
					}

					if (matchId)
						wheres.push(['match.id', '==', matchId])

					if (teaserId)
						wheres.push(['origination.id', '==', teaserId])

					if (profile) {
						db5.notifications.getWhereSync({
							profile,
							wheres, callBack: docs => {
								const data: TypeNotifications = Object
									.keys(docs.docs || {})
									.filter(key => docs?.docs?.[key]?.uiPermissions?.includes(PERMISSIONS_NOTIFICATIONS.p0004._id))
									.reduce((prev, cur) => ({ ...prev, [cur]: fnNotification.modelNotification(docs?.docs?.[cur]) }), {})

								setNotifications(n => ({ ...result, ...data }))
								setLoading(false)
							}
						})
					}

				})
		}
		if (profile && matchId && uiPermission === PERMISSIONS_NOTIFICATIONS.p0004._id) loadHistory()
	}, [index, matchId, teaserId, uiPermission, profile, checkUser])

	useEffect(() => {
		const loadWeeklyReport = async () => {
			const _filter = getFilter(index || 0)

			const wheres: [string, WhereFilterOp, any][] = [
				['createdAt', '>=', _filter.from],
				['createdAt', '<=', _filter.to],
				['origination.id', '==', teaserId],
				['showCustomer', '==', true]
			]

			if (profile && !fnUser.isAdm(profile) && !fnUser.isOffice(profile) && !fnUser.isOfficeAnalyst(profile)) {
				wheres.push(['uids', 'array-contains', profile._id])
				wheres.push(['showCustomer', '==', true])
			}

			if (profile) {
				db5.notifications.getWhereSync({
					profile,
					wheres, callBack: docs => {
						const data: TypeNotifications = Object
							.keys(docs.docs || {})
							.reduce((prev, cur) => ({ ...prev, [cur]: fnNotification.modelNotification(docs?.docs?.[cur]) }), {})
						setNotifications(data)
						setLoading(false)
					}
				})
			}

			setFilter(_filter)
		}

		if (profile && teaserId) loadWeeklyReport()
	}, [index, teaserId, profile, checkUser, unReadNotifications])

	useEffect(() => {
		async function loadBabyCard() {
			if (!profile) {
				return
			}
			// garante que as notificações antigas apareçam no resultado
			const oldNotifications = await db5.notifications.getWhere({
				profile,
				wheres: [
					['id', '==', teaserId],
					['uid', '==', profile?._id]
				]
			})

			if (oldNotifications.docs) {
				const items = Object
					.keys(oldNotifications.docs)
					// @ts-ignore
					.reduce((prev, cur) => ({ ...prev, [cur]: fnNotification.modelNotification(oldNotifications?.docs?.[cur]) }), {})
				setNotifications(n => ({ ...n, ...items }))
			}

			const wheres: [string, WhereFilterOp, any][] = [
				['uids', 'array-contains', profile?._id],
				['showCustomer', '==', false],
				['origination.id', '==', teaserId]
			]

			const response = await db5.notifications.getWhere({ profile, wheres })

			if (response.status === 200 && response?.docs) {
				// garante que apenas notificações de baby card apareçam
				const items = Object
					.keys(response?.docs)
					// @ts-ignore
					.filter(key => response?.docs[key]?.uiPermissions?.includes(PERMISSIONS_NOTIFICATIONS.p0005._id))
					// @ts-ignore
					.reduce((prev, cur) => ({ ...prev, [cur]: fnNotification.modelNotification(response?.docs?.[cur]) }), {})
				setNotifications(n => ({ ...n, ...items }))
				setLoading(false)
			}

		}
		if (profile && teaserId && uiPermission === PERMISSIONS_NOTIFICATIONS.p0005._id) loadBabyCard()
	}, [index, teaserId, uiPermission, profile])

	return { notifications, loading, filter }
}
