/**
 * A função `inAtivoWithoutMatchmaking` verifica se um teaser está ativo sem
 *casamento.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido em algum lugar da sua base de código. Provavelmente contém
 * informações relacionadas a um teaser ou conteúdo promocional. No
 * Função `inAtivoWithoutMatchmaking`, este parâmetro está sendo passado e depois
 * clonado usando
 * @returns A função `inAtivoWithoutMatchmaking` está retornando um valor booleano.
 * Verifica se o objeto `cloneTeaser` passou como argumento para a função
 * corresponde à condição de estar em estado ativo sem matchmaking. O
 * A função primeiro clona o objeto `teaser` usando a função `clone` do
 * Módulo `Utilitários`. Em seguida, ele define um array `subStatusOfActiveCost
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'


export const inAtivoWithoutMatchmaking = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const subStatusOfActiveCostumerInMatchmaking: (keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER)[] = [
		SUB_STATUS_OF_ACTIVE_COSTUMER.s00050.code
	]

	return inAtivo(cloneTeaser) && subStatusOfActiveCostumerInMatchmaking.includes(cloneTeaser.subStatusOfActiveCostumer)

}
