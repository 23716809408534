import { clone } from './clone'

const operation = (value1: number, operator: string, value2: number): boolean => {

	const cloneValue1 = clone(value1)
	const cloneValue2 = clone(value2)
	const cloneOperator = clone(operator)

	if (cloneOperator === '>') return cloneValue1 > cloneValue2
	if (cloneOperator === '<') return cloneValue1 < cloneValue2
	if (cloneOperator === '>=') return cloneValue1 >= cloneValue2
	if (cloneOperator === '<=') return cloneValue1 <= cloneValue2
	if (cloneOperator === '==') return cloneValue1 === cloneValue2
	if (cloneOperator === '===') return cloneValue1 === cloneValue2
	return false
}

/**
* Função para saber se um número está entre outros 2
* @param {Number} leftLimit {Number} Primeiro número
* @param {String} leftOperation {String} Primeiro operador
* @param {Number} value {Number} Número a ser comparado
* @param {String} rightOperation {String} Segundo operador
* @param {Number} rightLimit {Number} Segundo número
* @returns {Boolean} {Boolean} Retorna true ou false
* @example isInRange(10, '>', 5, '>', 2) => true
*/
export const isInRange = (
	leftLimit: number,
	leftOperation: string,
	value: number,
	rightOperation = '',
	rightLimit = 0
) => {

	const cloneLeftLimit = clone(leftLimit)
	const cloneLeftOperation = clone(leftOperation)
	const cloneValue = clone(value)
	const cloneRightOperation = clone(rightOperation)
	const cloneRightLimit = clone(rightLimit)

	const left = operation(cloneLeftLimit, cloneLeftOperation, cloneValue)
	if (cloneRightOperation === '') return left

	const right = operation(cloneValue, cloneRightOperation, cloneRightLimit)
	return left && right
}
