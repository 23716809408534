import { masks } from 'Functions/Utils/masks'
import React from 'react'
import { IUiInput } from '..'

export const onChangeDate = (props: IUiInput) =>
	(event: React.ChangeEvent<HTMLInputElement>) => {

		const value = masks.toDate_DD_MM_YYYY(event.target.value)

		event.target.value = value

		props.onChange && props.onChange(event)
	}
