import { genScoreEVNegative } from '../../genScore/genScoreEVNegative'
import { genScoreFee } from '../../genScore/genScoreFee'
import { genScoreFeeBigValue } from '../../genScore/genScoreFeeBigValue'
import { genScoreLeverage } from '../../genScore/genScoreLeverage'
import { genScoreMarketSize } from '../../genScore/genScoreMarketSize'
import { genScorePerformance } from '../../genScore/genScorePerformance'
import { normalize } from '../../normalize'
import { TypeScoreProps } from '../../types/TypeScoreProps'
import { TypeScoreStark } from '../../types/TypeScoreStark'

export const scoreStarkMATotal = (props: TypeScoreProps): TypeScoreStark => {
	let score: TypeScoreStark = {
		approved: false,
		scoreColor: 'red',
		scoreNote: 0,
		scoreItens: [],
		metadata: {
			valuation: 0,
			transactionValue: 0,
			successFee: 0,
			valueTotalInsure: 0,
			typeOperation: 'total',
			type: 'M&A',
			reperfilamento: false,
			isAdjustableValue: false,
			numberOfInstitutions: {
				total: 0,
				specific: 0,
				setorial: 0,
				generalist: 0,
			},
			insureCoverage: 0,
			governance: 'C',
			governanceColor: 'red',
			governanceCoverage: 0,
		}
	}

	// nota de fee
	score.scoreItens.push(...genScoreFee(props))

	// nota de alavancagem
	score.scoreItens.push(...genScoreLeverage(props))

	// nota de mercado
	score.scoreItens.push(...genScoreMarketSize(props))

	// nota de performance
	score.scoreItens.push(...genScorePerformance(props))

	// nota de EV negativo
	score.scoreItens.push(...genScoreEVNegative(props))

	// normalização dos pontos
	score = normalize(score)

	return score
}
