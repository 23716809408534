import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const UploadOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M28.4506 18.7666C27.6206 18.7666 27.0672 19.32 27.0672 20.15V25.6836C27.0672 26.5136 26.5138 27.067 25.6838 27.067H6.31621C5.48617 27.067 4.93281 26.5136 4.93281 25.6836V20.15C4.93281 19.32 4.37945 18.7666 3.54941 18.7666C2.71938 18.7666 2.16602 19.32 2.16602 20.15V25.6836C2.16602 28.0354 3.96443 29.8338 6.31621 29.8338H25.6838C28.0356 29.8338 29.834 28.0354 29.834 25.6836V20.15C29.834 19.32 29.2806 18.7666 28.4506 18.7666Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M2.42906 19.0296C2.70648 18.7522 3.09666 18.6006 3.54941 18.6006C4.00215 18.6006 4.39234 18.7522 4.66976 19.0296C4.94718 19.3071 5.09881 19.6972 5.09881 20.15V25.6836C5.09881 26.0609 5.22386 26.3624 5.43063 26.5692C5.6374 26.7759 5.93891 26.901 6.31621 26.901H25.6838C26.0611 26.901 26.3626 26.7759 26.5694 26.5692C26.7761 26.3624 26.9012 26.0609 26.9012 25.6836V20.15C26.9012 19.6972 27.0528 19.3071 27.3302 19.0296C27.6077 18.7522 27.9979 18.6006 28.4506 18.6006C28.9033 18.6006 29.2935 18.7522 29.5709 19.0296C29.8484 19.3071 30 19.6972 30 20.15V25.6836C30 28.1271 28.1273 29.9998 25.6838 29.9998H6.31621C3.87274 29.9998 2 28.1271 2 25.6836V20.15C2 19.6972 2.15164 19.3071 2.42906 19.0296ZM2.66383 19.2644C2.45706 19.4712 2.33202 19.7727 2.33202 20.15V25.6836C2.33202 27.9437 4.05611 29.6678 6.31621 29.6678H25.6838C27.9439 29.6678 29.668 27.9437 29.668 25.6836V20.15C29.668 19.7727 29.5429 19.4712 29.3362 19.2644C29.1294 19.0576 28.8279 18.9326 28.4506 18.9326C28.0733 18.9326 27.7718 19.0576 27.565 19.2644C27.3582 19.4712 27.2332 19.7727 27.2332 20.15V25.6836C27.2332 26.1363 27.0816 26.5265 26.8041 26.8039C26.5267 27.0814 26.1365 27.233 25.6838 27.233H6.31621C5.86346 27.233 5.47327 27.0814 5.19586 26.8039C4.91844 26.5265 4.7668 26.1363 4.7668 25.6836V20.15C4.7668 19.7727 4.64176 19.4712 4.43499 19.2644C4.22821 19.0576 3.9267 18.9326 3.54941 18.9326C3.17211 18.9326 2.8706 19.0576 2.66383 19.2644Z' fill='#272827' />
		<path d='M16.9688 2.58112C16.8304 2.44278 16.6921 2.30444 16.5537 2.30444C16.4154 2.1661 16.1387 2.1661 16.0004 2.1661C15.862 2.1661 15.5854 2.1661 15.447 2.30444C15.3087 2.44278 15.1703 2.44278 15.032 2.58112L8.11501 9.49812C7.56165 10.0515 7.56165 10.8815 8.11501 11.4349C8.66837 11.9882 9.49841 11.9882 10.0518 11.4349L14.617 6.86966L14.617 20.1503C14.617 20.9803 15.1703 21.5337 16.0004 21.5337C16.8304 21.5337 17.3838 20.9803 17.3838 20.1503L17.3838 6.86966L21.949 11.4349C22.5024 11.9882 23.3324 11.9882 23.8858 11.4349C24.4391 10.8815 24.4391 10.0515 23.8858 9.49812L16.9688 2.58112Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M17.1207 21.2706C16.8433 21.5481 16.4531 21.6997 16.0004 21.6997C15.5476 21.6997 15.1575 21.5481 14.88 21.2706C14.6026 20.9932 14.451 20.603 14.451 20.1503L14.451 7.27044L10.1692 11.5523C9.55097 12.1705 8.61582 12.1705 7.99763 11.5523C7.37944 10.9341 7.37944 9.99893 7.99763 9.38074L14.9146 2.46374C15.0029 2.37543 15.0924 2.33072 15.1611 2.29639L15.1653 2.29431C15.2344 2.25975 15.2814 2.23529 15.3296 2.18706C15.4356 2.08109 15.5812 2.03886 15.6964 2.01965C15.8138 2.00009 15.9288 2.0001 15.9974 2.0001L16.0034 2.0001C16.0719 2.0001 16.187 2.00009 16.3044 2.01965C16.4028 2.03606 16.5234 2.06927 16.6225 2.14467C16.7163 2.16159 16.7972 2.21037 16.8609 2.25811C16.9396 2.31712 17.0157 2.39332 17.0817 2.45933L24.0032 9.38074C24.6213 9.99893 24.6213 10.9341 24.0032 11.5523C23.385 12.1705 22.4498 12.1705 21.8316 11.5523L17.5498 7.27044L17.5498 20.1503C17.5498 20.603 17.3982 20.9932 17.1207 21.2706ZM16.886 21.0359C17.0927 20.8291 17.2178 20.5276 17.2178 20.1503L17.2178 6.46889L22.0664 11.3175C22.5549 11.806 23.2799 11.806 23.7684 11.3175C24.2569 10.829 24.2569 10.104 23.7684 9.61551L16.8514 2.69851C16.78 2.62717 16.7195 2.56713 16.6617 2.52372C16.602 2.47899 16.5688 2.47046 16.5538 2.47046L16.485 2.47046L16.4364 2.42183C16.404 2.38946 16.342 2.36252 16.2498 2.34715C16.1614 2.33241 16.0705 2.33211 16.0004 2.33211C15.9303 2.33211 15.8394 2.33241 15.751 2.34715C15.6588 2.36252 15.5968 2.38946 15.5644 2.42183C15.4761 2.51014 15.3867 2.55485 15.318 2.58918L15.3138 2.59127C15.2447 2.62582 15.1976 2.65028 15.1494 2.69851L8.2324 9.61551C7.74387 10.104 7.74387 10.829 8.2324 11.3175C8.72093 11.806 9.44586 11.806 9.93439 11.3175L14.783 6.46889L14.783 20.1503C14.783 20.5276 14.908 20.8291 15.1148 21.0359C15.3216 21.2427 15.6231 21.3677 16.0004 21.3677C16.3777 21.3677 16.6792 21.2427 16.886 21.0359Z' fill='#272827' />
	</Svg>
)


