import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BellOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M27.1393 23.3925H6.07931C5.55931 23.3925 5.03931 23.0025 4.77931 22.4825C4.64931 21.9625 4.77931 21.3125 5.29931 21.0525C5.29931 21.0525 8.2893 18.8425 8.2893 11.5625C8.2893 7.01255 12.0593 3.24255 16.6093 3.24255C21.1593 3.24255 24.9293 7.01255 24.9293 11.5625C24.9293 18.8425 27.7893 21.0525 27.9193 21.0525C28.4393 21.3125 28.5693 21.9625 28.4393 22.4825C28.1793 23.0025 27.6593 23.3925 27.1393 23.3925ZM8.8093 20.7925H24.4093C23.3693 18.9725 22.3293 16.1125 22.3293 11.5625C22.3293 8.44254 19.7293 5.84255 16.6093 5.84255C13.4893 5.84255 10.8893 8.44254 10.8893 11.5625C10.8893 16.1125 9.8493 18.9725 8.8093 20.7925Z' fill='#272827' />
		<path d='M16.6099 29.2426C15.9599 29.2426 15.3099 29.1126 14.7899 28.7226C14.2699 28.4626 13.7499 27.9426 13.4899 27.4226C13.0999 26.7726 13.3599 25.9926 14.0099 25.6026C14.6599 25.2126 15.4399 25.4726 15.8299 26.1226C15.9599 26.2526 16.0899 26.3826 16.2199 26.5126C16.7399 26.7726 17.3899 26.6426 17.6499 26.1226C18.0399 25.4726 18.8199 25.3426 19.4699 25.6026C20.1199 25.9926 20.2499 26.7726 19.9899 27.4226C19.0799 28.5926 17.9099 29.2426 16.6099 29.2426Z' fill='#272827' />
	</Svg>
)
