/**
 * A função `isOnline` pega um objeto teaser, clona-o e retorna o valor
 * da propriedade `onLine` do objeto clonado.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente é um objeto que representa um teaser ou uma prévia de algum conteúdo. O
 * a função `isOnline` pega esse objeto teaser e o clona usando o `clone`
 * função de um módulo utilitário e, em seguida, retorna o valor de `
 * @returns A função `isOnline` está retornando o valor da propriedade `onLine`
 * do objeto `teaser` clonado.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'


export const isOnline = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.onLine

}
