import { IUiInput } from '..'

import { masks_props } from './masks_props'

export type TypeUiInputFormats =
	'cpf' |
	'cnpj' |
	'cep' |
	'currency' |
	'phone' |
	'site' |
	'password' |
	/**
	 * @deprecated
	*/
	'percentage' |
	'percentage_decimal' |
	'number_unit' |
	'date_dd/MM/yyyy'

export const formats = (props: IUiInput): IUiInput => {

	if (!props.format) props.type = 'text'

	if (props.format === 'password') props.type = 'password'

	if (props.format === 'cpf') props = masks_props.cpf(props)

	if (props.format === 'cnpj') props = masks_props.cnpj(props)

	if (props.format === 'cep') props = masks_props.cep(props)

	if (props.format === 'currency') props = masks_props.currency(props)

	if (props.format === 'phone') props = masks_props.phone(props)

	if (props.format === 'percentage') props = masks_props.percentage(props)

	if (props.format === 'percentage_decimal') props = masks_props.percentage_decimal(props)

	if (props.format === 'site') props = masks_props.site(props)

	if (props.format === 'number_unit') props = masks_props.number_unit(props)

	if (props.format === 'date_dd/MM/yyyy') props = masks_props.date_ddMMyyyy(props)

	return props

}

