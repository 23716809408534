import { REASON_CONTRACT_DONE, REASON_CONTRACT_DONE_GET } from '../../../Constants/REASON_CONTRACT_DONE'
import { STATUS_CUSTOMER } from '../../../Constants/STATUS_CUSTOMER'
import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { TypeTeaser } from '../types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { getStatus } from './get/getStatus'
import { isOnline } from './isOnline'

/**
 * Retorna o título e o ícone de um teaser de acordo com seu status
 * @param param0
 * @returns { label, icon }
 */

export const getStatusLabelAndIcon = ({ teaser, profile }: { teaser: TypeTeaser, profile: TypeUser }): { label: string, icon: string | undefined } => {
	const statusCustomer = getStatus({ teaser, profile })
	const statusLabel = statusCustomer.substatus ?
		statusCustomer.substatus.label :
		statusCustomer.status.label
	const isInactive = !isOnline(teaser) && teaser.reasonContractDone !== REASON_CONTRACT_DONE.i000._id
	const label = isInactive ?
		`Finalizado - ${REASON_CONTRACT_DONE_GET(teaser.reasonContractDone).title}` :
		statusLabel
	let icon = statusCustomer.substatus ?
		getIconsActive(statusCustomer.substatus.code) :
		getIconsOrigination(statusCustomer.status.value)
	const success = teaser.reasonContractDone === REASON_CONTRACT_DONE.i003._id
	icon = isInactive ? (success ? 'emoji_events' : 'cancel') : icon

	return { label, icon }
}

const getIconsOrigination = (status: keyof typeof STATUS_CUSTOMER) => {
	if (status === STATUS_CUSTOMER.standby.value) return 'block'
	if (status === STATUS_CUSTOMER.comite.value) return 'bar_chart'
	if (status === STATUS_CUSTOMER.comercial.value) return 'work_outline'
	return 'description'
}

const getIconsActive = (substatus: keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER) => {
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00000.code) return 'block'
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00050.code) return 'fact_check'
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00100.code) return 'my_location'
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00200.code) return 'inventory'
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00300.code) return 'description'
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00400.code) return 'backup'
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00500.code) return 'task'
	if (substatus === SUB_STATUS_OF_ACTIVE_COSTUMER.s00600.code) return 'emoji_events'
}

