import { TAGS_SECTOR_ROOT } from '../../../Constants/TAGS'
import { TypeTeaser } from '../types/teaser'
/**
 * Função que cria uma lista de LABELS de tags raiz a partir de uma lista de tags de um teaser,
 * removendo as tags negativas.
 *
 * @param {Object} props - Objeto contendo o teaser parcial.
 * @param {Partial<TypeTeaser>} props.teaser - Teaser contendo as tags.
 * @returns {string[]} - Lista de tags raiz resultantes.
 * @example
 *
 * / Exemplo de uso:
 * const teaser = {
 *   tags: ['t001', 't032', 't004'],
 *   tagsNegative: ['t004']
 * };
 *
 * const tagsRoot = createTagsRoot({ teaser });
 * console.log(tagsRoot); // Saída: [ 'Agro', 'Atividades Financeiras' ]
 */

export const createTagsRoot = (props: { teaser: Partial<TypeTeaser> }) => {
	if (!props.teaser || !props.teaser.tags || !props.teaser.tagsNegative) return []
	const tags: string[] = props.teaser.tags.filter(item => !props.teaser?.tagsNegative?.includes(item))
	const tagsRoot: string[] = []

	tags.forEach(tag => {
		if (TAGS_SECTOR_ROOT[tag]) {
			tagsRoot.push(TAGS_SECTOR_ROOT[tag].label)
			return
		}

		// caso seja uma subtag da tag root vamos checar se ela existe
		Object.values(TAGS_SECTOR_ROOT).map(tagRoot => {
			if (tagRoot?.tags?.includes(tag) && tagRoot?.label) tagsRoot.push(tagRoot.label)
		})
	})

	return tagsRoot
}
