import { TypeScoreProps } from '../types/TypeScoreProps'

export const genTotalBudgetFin2 = (props: TypeScoreProps) => {
	const totalBudgetFin2 =
		props.teaser.financing?.fin2?.reduce(
			(accumulator, value) =>
				accumulator + (value.totalBudget ? +value.totalBudget : 0),
			0
		) ?? 0
	return totalBudgetFin2
}
