import ReactDOM from 'react-dom'
import App from './App/App'
import 'Styles/Themes.css'
import 'Styles/TailwindCss/styles.css'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(
	<App />
	, document.getElementById('root'))
serviceWorker.unregister()
