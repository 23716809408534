import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const MatchmakingOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<g clipPath='url(#clip0_4376_8441)'>
			<path d='M29.4304 10.961C28.1837 9.71429 26.6252 8.98701 24.859 8.98701C23.9239 8.98701 23.0928 9.19481 22.3655 9.50649C22.1577 8.15584 21.5343 6.80519 20.5993 5.87013C19.3525 4.62338 17.7941 4 16.1317 4C14.4694 4 12.9109 4.62338 11.6642 5.87013C10.5213 4.62338 8.85899 4 7.19666 4C5.53432 4 3.97588 4.62338 2.83302 5.87013C1.58627 7.01299 0.962891 8.57143 0.962891 10.2338C0.962891 11.8961 1.58627 13.4545 2.83302 14.7013L10.7291 22.5974C11.0408 22.8052 11.3525 23.013 11.6642 23.013C11.9759 23.013 12.3915 22.9091 12.5993 22.5974L13.5343 21.6623L19.4564 27.5844C19.6642 27.7922 20.0798 28 20.3915 28C20.807 28 21.1187 27.8961 21.3265 27.5844L29.2226 19.6883C30.3655 18.5455 31.0928 16.8831 31.0928 15.2208C31.0928 13.6623 30.4694 12.1039 29.4304 10.961ZM10.7291 8.67532C11.0408 8.88312 11.3525 9.09091 11.6642 9.09091C11.9759 9.09091 12.3915 8.98701 12.5993 8.67532L13.5343 7.74026C14.1577 7.11688 15.0928 6.7013 16.0278 6.7013C16.9629 6.7013 17.898 7.11688 18.5213 7.74026L18.6252 7.84416C19.2486 8.46753 19.6642 9.2987 19.6642 10.1299C18.6252 9.4026 17.2746 8.98701 16.0278 8.98701C14.3655 8.98701 12.807 9.61039 11.5603 10.8571C10.4174 12 9.69016 13.6623 9.69016 15.3247C9.69016 16.7792 10.2096 18.1299 11.0408 19.2727L4.70315 12.8312C3.3525 11.4805 3.3525 9.19481 4.70315 7.74026C5.32653 7.11688 6.26159 6.7013 7.19666 6.7013C8.13172 6.7013 9.06679 7.11688 9.69016 7.74026L10.7291 8.67532ZM13.4304 12.7273C14.0538 12.1039 14.9889 11.6883 15.9239 11.6883C16.859 11.6883 17.7941 12.1039 18.4174 12.7273L19.3525 13.6623C19.5603 13.8701 19.9759 14.0779 20.2876 14.0779C20.5993 14.0779 20.9109 13.974 21.1187 13.7662L21.2226 13.6623L22.1577 12.7273C22.885 12 23.7161 11.6883 24.6512 11.6883C25.5863 11.6883 26.5213 12.1039 27.1447 12.7273L27.2486 12.8312C27.872 13.4545 28.1837 14.3896 28.1837 15.3247C28.1837 16.2597 27.7681 17.1948 27.1447 17.8182L20.3915 24.7792L13.4304 17.8182C12.0798 16.3636 12.0798 14.0779 13.4304 12.7273Z' fill='#272827' />
		</g>
		<defs>
			<clipPath id='clip0_4376_8441'>
				<rect width='32' height='32' fill='white' transform='translate(0.0283203)' />
			</clipPath>
		</defs>
	</Svg>
)


