import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const DateOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M3.58398 10.0433C3.58398 9.30692 4.18094 8.70996 4.91732 8.70996H27.0819C27.8183 8.70996 28.4152 9.30692 28.4152 10.0433V26.6667C28.4152 27.4031 27.8183 28.0001 27.0819 28.0001H4.91732C4.18094 28.0001 3.58398 27.4031 3.58398 26.6667V10.0433ZM6.25066 11.3766V25.3334H25.7486V11.3766H6.25066Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M8.10482 4C8.8412 4 9.43816 4.59696 9.43816 5.33334V9.21214C9.43816 9.94852 8.8412 10.5455 8.10482 10.5455C7.36844 10.5455 6.77148 9.94852 6.77148 9.21214V5.33334C6.77148 4.59696 7.36844 4 8.10482 4Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M24.3119 4C25.0482 4 25.6452 4.59696 25.6452 5.33334V9.21214C25.6452 9.94852 25.0482 10.5455 24.3119 10.5455C23.5755 10.5455 22.9785 9.94852 22.9785 9.21214V5.33334C22.9785 4.59696 23.5755 4 24.3119 4Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M3.58398 15.5843C3.58398 14.8479 4.18094 14.251 4.91732 14.251H27.0819C27.8183 14.251 28.4152 14.8479 28.4152 15.5843C28.4152 16.3207 27.8183 16.9177 27.0819 16.9177H4.91732C4.18094 16.9177 3.58398 16.3207 3.58398 15.5843Z' fill='#272827' />
	</Svg>
)
