import { addBuyerCompany } from './addBuyerCompany'
import { addTag } from './addTag'
import { checkOutlier } from './checkOutlier'
import { createTransaction } from './create'
import { getBillingMultiple } from './getBillingMultiple'
import { getBuyerCompanyNames } from './getBuyerCompanyNames'
import { getEbitdaMultiple } from './getEbitdaMultiple'
import { getEnterpriseValue } from './getEnterpriseValue'
import { getMedian } from './getMedian'
import { modelTransaction } from './model'
import { readTransaction } from './read'
import { removeBuyerCompany } from './removeBuyerCompany'
import { removeTag } from './removeTag'
import { updateTransaction } from './update'
import { validateFormToCreateTransation } from './validateFormToCreateTransation'

export const fnTransaction = {
	modelTransaction,
	createTransaction,
	updateTransaction,
	readTransaction,
	addBuyerCompany,
	removeBuyerCompany,
	getEnterpriseValue,
	getBuyerCompanyNames,
	addTag,
	removeTag,
	validateFormToCreateTransation,
	getEbitdaMultiple,
	getBillingMultiple,
	checkOutlier,
	getMedian
}
