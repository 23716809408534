import { SUB_TYPE_OPERATIONS_KEYS } from '../../../../../Constants/SUB_TYPE_OPERATIONS'
import { TYPE_OPERATION_CODE } from '../../../../../Constants/TYPE_OPERATION'
import { TypeTeaser } from '../../../../../Functions/Teaser/types/teaser'
import { clone } from '../../../../../Functions/Utils/clone'

/** Recebe um teaser e um id. Clona o teaser, cria um objeto, que é uma nova operação com os dados básicos de uma operação de M&a e com seu motivoCaptação = cloneTeaser.meaMotivoCaptacao. Faz um map no cloneTeaser.etr. Se o etr estiver no feminino, muda para masculino, e remove a palavra da frente 'aquisição', fazendo push no operationSubtype. Se não, apenas remove a palavra da frente 'aquisição', fazendo push no operationSubtype. Retorna a nova operação.  */
export const translateMeA = (teaser: TypeTeaser, id: string) => {
	const cloneTeaser = clone(teaser)

	// if (cloneTeaser.typeOperation === TYPE_OPERATION_CODE['Crédito']._id) {
	// 	cloneTeaser.typeOperation = TYPE_OPERATION_CODE['M&A e Crédito'].adapter
	// }
	const newOperationMeA: TypeTeaser['operations'][0] = {
		id: id,
		operationType: TYPE_OPERATION_CODE['M&A']._id,
		operationSubType: [] as SUB_TYPE_OPERATIONS_KEYS[],
		motivoCaptacao: cloneTeaser.meaMotivoCaptacao,
		valorReperfilamento: 0
	}

	Object.entries(cloneTeaser.etr).map(([etrKey, etrValue]) => {
		if (etrValue) {
			const ultimoCaractere = etrKey[etrKey.length - 1]
			if (ultimoCaractere === 'a') { // se estiver no feminino, devo mudar para masculino, e remove a palavra da frente 'aquisição'
				return newOperationMeA.operationSubType.push(`${etrKey.slice(0, -1)}o`.toLowerCase().split(' ').pop() as SUB_TYPE_OPERATIONS_KEYS)
			}
			else { // remove a primeira palavra 'aquisição'
				return newOperationMeA.operationSubType.push(etrKey.toLowerCase().split(' ').pop() as SUB_TYPE_OPERATIONS_KEYS)
			}
		}
		return
	})

	return newOperationMeA
}
