import { Component } from 'react'
import { fireLogar } from 'Services/firebase'
import { InputText } from 'Components/Inputs'
import { LogoStarkTypographyWhite } from 'Components/LogoStarkTypographyWhite'
import { Loading, LoadingAnimate } from 'Components/Loading'
import { UiModal } from 'ComponentsUi/UiModal'
import { UiInput } from '../../ComponentsUi/UiInput'
import { UiButton } from '../../ComponentsUi/UiButton'
import { UiLabel } from '../../ComponentsUi/UiLabel'
// import ts_ from 'Translate/ts'
// ---------- início do componente ----------
const fundoDeTela = {
	background: 'linear-gradient(60deg, #0b31dd, #285194)',
	backgroundSize: 'cover',
	backgroundPosition: 'top center',
}

class Screen_Login extends Component {

	state = {
		modalErro: false,
		carregando: false,
	}

	addState = (chave, valor) => this.setState({ [chave]: valor })

	ChamarModalErro = () => this.setState({ modalErro: true })

	PedidoLogin = e => {
		e.preventDefault()
		this.setState({ carregando: true })
		fireLogar(
			e.target.inputEmail.value,
			e.target.inputPassword.value,
			() => this.setState({ modalErro: true, carregando: false })
		)
	}

	handleCreateAccount = () => {
		window.location.href = 'https://web.stark.money/who-are-you/'
	}
	handleRecover = () => {
		// fazer history push para a url recover
		this.props.history.push('/recover')
	}

	render() {

		return (
			<div className='Screen_Login'>

				<div className='wrapper wrapper-full-page'>
					<div className='page-header login-page header-filter' style={fundoDeTela}>
						<div className='container'>
							<div className='row'>
								<div className='col-12 col-lg-4 ml-auto mr-auto pt-5 mt-5'>
									<div className='text-center'><LogoStarkTypographyWhite style={{ width: 200 }} /></div>
									{/* <Card> */}
									<form className='form mb-0 mx-4 d-flex flex-column gap-1' onSubmit={this.PedidoLogin}>
										<UiLabel className='tw-text-white'>E-mail</UiLabel>
										<UiInput id='inputEmail' />

										<UiLabel className='tw-text-white'>Senha</UiLabel>
										<UiInput id='inputPassword' format='password' />

										{this.state.carregando
											?
											<div className='tw-mt-4'>
												<LoadingAnimate white />
											</div>
											:
											<>
												<UiButton size='medium' type='submit' className='id-button-login tw-w-full tw-mt-4'>Entrar</UiButton>
												<UiButton variant='textButton' size='medium' type='button' className='tw-w-full tw-mt-4 tw-text-white hover:tw-text-blue-300' onClick={this.handleRecover}>Esqueci minha senha</UiButton>
											</>
										}
									</form>
									{/* </Card> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				<UiModal show={this.state.modalErro} onClose={e => {
					e.preventDefault(); this.setState({ modalErro: false })
				}}>
					<div className='modal-header'>
						<h5 className='modal-title'>Ops!</h5>
					</div>
					<div className='modal-body'>
						<p>E-mail ou senha incorretos. Confirme se seu dados estão corretos</p>
					</div>
					<div className='modal-footer'>
						<UiButton size='small' onClick={e => {
							e.preventDefault(); this.setState({ modalErro: false })
						}}>
							OK
						</UiButton>
					</div>
				</UiModal>

			</div>
		)
	}
}
export default Screen_Login
