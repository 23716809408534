import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

export const addTag =
	(
		{ tag, transaction }:
			{ tag: TypeTransaction['tags'][0], transaction: TypeTransaction }
	) => {
		const copyOfTransaction = { ...transaction }
		copyOfTransaction.tags = [...transaction.tags]
		copyOfTransaction.tags.push(tag)
		return copyOfTransaction.tags
	}

