import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { TypeUser } from '../../../Functions/User/types/TypeUser'

/**
 * Retorna a primeira data que torna o usuário ativo na plataforma
 * @rules
		1 - tem todas as datas => pega a primeira
		2 - tem só 3 e 4 => pega a 3
		3 - nao tem nenhuma, loop de 50
		4 - se nao achar pega data de criacao
 * @param user TypeUser
 * @returns Date
 */
export const firstDateInPlatform = (user: TypeUser) =>

	user.dataCriacao ? helpersDate.convertAllToDate(user.dataCriacao) : ''
// for (let index = 1; index < 50; index++) {
// 	const acceptedTerm = `acceptedTerm${index}` as string
// 	const term = user[acceptedTerm as keyof typeof user]
// 	if (term) return helpersDate.convertAllToDate(term as Timestamp)
// }


