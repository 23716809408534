import { useContextAuth } from 'App/ContextAuth'

import { TypeCalendars } from 'Functions/Calendar/types/calendars'

import { TypeFeaturedMaterials } from 'Functions/FeaturedMaterials/types/featureMaterials'
import { TypeTeses } from 'Functions/Tese/types/teses'
import { modelTool } from 'Functions/Tools/model/modelTool'

import React, { createContext, useContext, useEffect, useState } from 'react'
import { db5 } from 'Services/db5'

export const _useController = () => {
	const { globalAuth } = useContextAuth()

	const [starkVideo, setStarkVideo] = useState(modelTool().starkVideo)

	const [tool, setTool] = useState(modelTool())

	const [tesesFeatured, setTesesFeatured] = useState<TypeTeses>({})

	const [featuresMaterials, setFeaturedMaterials] =
		useState<TypeFeaturedMaterials>({})

	const [calendars, setCalendars] = useState<TypeCalendars>({})

	useEffect(() => {
		const load = async () => {
			db5.calendars.getWhereSync({
				profile: globalAuth.profile,
				wheres: [],
				callBack: data => {
					if (data.docs) {
						setCalendars(data.docs)
					}
				},
			})
			const toolsDB = await db5.tools.get({
				id: 'tools',
				profile: globalAuth.profile,
			})
			if (toolsDB.doc) {
				setTool(toolsDB.doc)
				setStarkVideo(toolsDB.doc.starkVideo)
			}
			const featuredMaterialsAux = await db5.featuredMaterials.getWhere({
				profile: globalAuth.profile,
				wheres: [],
			})
			if (featuredMaterialsAux.docs) setFeaturedMaterials(featuredMaterialsAux.docs)
		}

		globalAuth.profile.tipo && load()
	}, [globalAuth.profile])

	useEffect(() => {

		(async () => {
			const tesesFeatured = await db5.teses.getWhere({
				profile: globalAuth.profile,
				wheres: [['toFeatureHot', '==', true]]
			})
			if (tesesFeatured.docs) setTesesFeatured(tesesFeatured.docs)
		})()

	}, [globalAuth.profile])

	return {
		calendars,
		featuresMaterials,
		starkVideo,
		tool,
		tesesFeatured
	}
}

const _Controller = createContext({} as ReturnType<typeof _useController>)

export const useTools = () => useContext(_Controller)

export const ContextTools = ({ children }: { children: React.ReactNode }) => {
	const controller = _useController()
	return (
		<_Controller.Provider value={controller}>{children}</_Controller.Provider>
	)
}
