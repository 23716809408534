import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

import { TypeTeaser } from 'Functions/Teaser/types/teaser'

type TypeInactivateAllMatchesFromTeaser = {
	teaserChurnado: TypeTeaser
}


export const _inactivateAllMatchesFromTeaser = (input: TypeInactivateAllMatchesFromTeaser): Promise<{ data: TypeTeaser }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'inactivateAllMatchesFromTeaser', input })

