import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'
import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'

export const removeTransaction =
	(
		{ transaction, valuation }:
			{ transaction: TypeTransaction, valuation: TypeValuation }
	) => {
		const copyOfValuation = { ...valuation }
		copyOfValuation.transactions = [...valuation.transactions]
		copyOfValuation.transactions = copyOfValuation.transactions.filter(_transaction => _transaction._id !== transaction._id)
		return copyOfValuation.transactions
	}

