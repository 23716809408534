import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

export const validateFormToCreateTransation = (transaction: TypeTransaction) => {
	const errors: { tags?: string, ebitda?: string, soldCompanyName?: string, buyerCompanies?: string, value?: string, dateTransaction?: string, ebitdaMultiple?: string, billing?: string, billingMultiple?: string, source?: string } = {}

	if (!transaction.soldCompanyName) {
		errors.soldCompanyName = 'Digite o nome da empresa'
	}

	if (transaction.buyerCompanies.length <= 0) {
		errors.buyerCompanies = 'Adicione ao menos uma empresa compradora'
	}

	if (!transaction.dateTransaction) {
		errors.dateTransaction = 'Digite a data de transação'
	}

	if (!transaction.value) {
		errors.value = 'Digite o valor de transação'
	}

	if (!transaction.ebitda) {
		errors.ebitda = 'Digite o Ebitda da empresa'
	}

	if (!transaction.ebitdaMultiple) {
		errors.ebitdaMultiple = 'Digite o múltiplo de Ebitda da empresa'
	}

	if (!transaction.billing) {
		errors.billing = 'Digite o faturamento da empresa'
	}

	if (!transaction.billingMultiple) {
		errors.billingMultiple = 'Digite o múltiplo de faturamento da empresa'
	}

	if (transaction.tags.length <= 0) {
		errors.tags = 'Selecione ao menos uma tag que mais se parece com a empresa'
	}

	return errors
}
