import { PERMISSIONS_NOTIFICATIONS_GROUP, TypePermissionsNotifications } from '../../../Constants/PERMISSIONS_NOTIFICATIONS'
import { TYPE_NOTIFICATIONS } from '../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../Origination'
import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../../Match/types/match'
import { TypeNotification } from '../types/notification'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { fnNotification } from '..'
import { clone } from '../../../Functions/Utils/clone'

export type Props = {
	origination: TypeTeaser
	originationId: string
	profile: TypeUser
	uid: string
	match: TypeMatch
	matchId: string
	institution: TypeInstitution
	institutionId: string
	message: string
	showCustomer: boolean
}

export const sendHistory =
	async ({
		origination,
		originationId,
		profile,
		uid,
		match,
		matchId,
		institution,
		institutionId,
		message,
		showCustomer
	}: Props) => {

		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneProfile = clone(profile)
		const cloneUid = clone(uid)
		const cloneMatch = clone(match)
		const cloneMatchId = clone(matchId)
		const cloneInstitution = clone(institution)
		const cloneInstitutionId = clone(institutionId)
		const cloneMessage = clone(message)
		const cloneShowCustomer = clone(showCustomer)

		return await fnNotification.send({
			uiPermissions: PERMISSIONS_NOTIFICATIONS_GROUP.p0006 as TypePermissionsNotifications[],
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile, uid: cloneUid }),
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination }),
			match: fnNotification.formatData.getMatch({ match: cloneMatch, id: cloneMatchId }),
			institution: fnNotification.formatData.getInstitution({ institution: cloneInstitution, id: cloneInstitutionId }),
			message: cloneMessage,
			showCustomer: cloneShowCustomer,
			type: TYPE_NOTIFICATIONS.a00015._id,
			analystEmail: cloneProfile.email,
			createdAt: new Date()
		} as TypeNotification)
	}
