import React, { createContext, useContext, useState } from 'react'

const _useContext = () => {
	const [notificationToSeeInFeedCustomer, setNotificationToSeeInFeedCustomer] = useState('')

	return ({
		notificationToSeeInFeedCustomer, setNotificationToSeeInFeedCustomer
	})
}
const ContextNotificationsFeedCustomer = createContext({} as ReturnType<typeof _useContext>)

export const usContextNotificationsFeedCustomer = () => useContext(ContextNotificationsFeedCustomer)
export const ContextNotificationsFeedCustomerProvider: React.FC = ({ children }) => {
	const controller = _useContext()
	return (
		<ContextNotificationsFeedCustomer.Provider value={controller}>
			{children}
		</ContextNotificationsFeedCustomer.Provider>
	)
}
