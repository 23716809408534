import { GEN_MARGIN_FEE } from '../genConst/GEN_MARGIN_FEE'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { genTransactionValue } from './genTransactionValue'

export const genFee = (props: TypeScoreProps) => {

	if (props.operation === 'greenfield') return genTransactionValue(props) * GEN_MARGIN_FEE(props.operation)
	if (props.operation === 'corporateFinance') return genTransactionValue(props) * GEN_MARGIN_FEE(props.operation)
	if (props.operation === 'projectFinance') return genTransactionValue(props) * GEN_MARGIN_FEE(props.operation)

	if (props.operation === 'total') return genTransactionValue(props) * GEN_MARGIN_FEE(props.operation)
	if (props.operation === 'minoritário') return genTransactionValue(props) * GEN_MARGIN_FEE(props.operation)
	if (props.operation === 'majoritário') return genTransactionValue(props) * GEN_MARGIN_FEE(props.operation)

	return 0
}
