import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeCfGetBlind = {
	collection: string;
	id?: string;
	where?: [string, any, any][];
	orderBy?: [string, any][];
	limit?: number;
	startAfterDocId?: string;
}

type CfGetBlindResponse = {
	status: 200 | 400
	doc?: any
	docs?: { [key: string]: any }
	docsArray?: any[]
	id?: string
	message: string
}

export const _cfGetBlindFunction = (input: TypeCfGetBlind): Promise<{ data: CfGetBlindResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'cfGetBlind', input })
