import { clone } from '../../Utils/clone'
import { MOODS } from '../consts/MOODS'
import { TypeProfilePost } from '../types/ProfilePost'

export const modelProfilePost = (obj: Partial<TypeProfilePost> = {} as Partial<TypeProfilePost>): TypeProfilePost => {

	const cloneObj = clone(obj)

	return {
		_id: cloneObj._id || '',
		createdAt: cloneObj.createdAt || 0,
		deletedAt: cloneObj.deletedAt || null,
		message: cloneObj.message || '',
		createdBy: {
			_uid: cloneObj.createdBy?._uid || null,
			urlImage: cloneObj.createdBy?.urlImage || null,
			name: cloneObj.createdBy?.name || null,
		},
		signedTo: {
			_uid: cloneObj.signedTo?._uid || null,
			ulrimg: cloneObj.signedTo?.ulrimg || null,
			name: cloneObj.signedTo?.name || null,
		},
		mood: cloneObj.mood || MOODS.m0006._id as keyof typeof MOODS,
		teaser: {
			_id: cloneObj.teaser?._id || null,
			urlLogo: cloneObj.teaser?.urlLogo || null,
			name: cloneObj.teaser?.name || null,
			fantasyName: cloneObj.teaser?.fantasyName || null,
		},
		userUid: cloneObj.userUid || '',
	}
}
