import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const InformationOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16.8008 4.39024C10.3889 4.39024 5.19103 9.58811 5.19103 16C5.19103 22.4119 10.3889 27.6098 16.8008 27.6098C23.2127 27.6098 28.4105 22.4119 28.4105 16C28.4105 9.58811 23.2127 4.39024 16.8008 4.39024ZM2.80078 16C2.80078 8.26801 9.06879 2 16.8008 2C24.5328 2 30.8008 8.26801 30.8008 16C30.8008 23.732 24.5328 30 16.8008 30C9.06879 30 2.80078 23.732 2.80078 16Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.8006 14.8047C17.4606 14.8047 17.9957 15.3398 17.9957 15.9998V21.1218C17.9957 21.7818 17.4606 22.3169 16.8006 22.3169C16.1405 22.3169 15.6055 21.7818 15.6055 21.1218V15.9998C15.6055 15.3398 16.1405 14.8047 16.8006 14.8047Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M15.6055 10.8782C15.6055 10.2182 16.1405 9.68311 16.8006 9.68311H16.8148C17.4749 9.68311 18.0099 10.2182 18.0099 10.8782C18.0099 11.5383 17.4749 12.0733 16.8148 12.0733H16.8006C16.1405 12.0733 15.6055 11.5383 15.6055 10.8782Z' fill='#272827' />
	</Svg>
)





