import { confirmAlert } from 'react-confirm-alert'
import Carregando from 'Components/Carregando'
import styled, { keyframes } from 'styled-components'
import { Card } from 'Components/CardStyled'
import { AiFillCloseCircle } from 'react-icons/ai'
import { UiButton } from '../../ComponentsUi/UiButton'

export const Modal = ({ title, voltar, button, color, body, onClick, onClose, width }) => (
	<div>
		<div
			onClick={onClose}
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 99,
				background: 'rgba(0, 0, 0, 0.5)',
				maxHeight: '100%',
				overflowY: 'auto',
			}}
		/>
		<CardModal width={width}>
			<h5 className='modal-title'>{title}</h5>
			{body()}
			<div className='d-flex flex-row justify-content-between'>
				{voltar && <UiButton size='small' variant='tertiary' onClick={onClose}>Voltar</UiButton>}
				{button && <UiButton size='small' variant={'primary'} onClick={onClick}>{button}</UiButton>}
			</div>
		</CardModal>
	</div>
)

const ModalDialog = ({ close, title, button, color, body, onClick, onClose, width }) => (
	<div>
		<div
			onClick={onClose}
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 99,
				background: 'rgba(0, 0, 0, 0.5)',
			}}
		/>
		<CardModal width={width}>
			<h5 className='modal-title'>{title}</h5>
			{body()}

			<div className='d-flex flex-row justify-content-between mt-3'>
				{close ? (
					<button onClick={onClose} style={{ background: 'transparent', border: 'none', position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', outline: 'none' }}>
						<AiFillCloseCircle color='#f00' />
					</button>
				) : (
					<UiButton onClick={onClose} variant='tertiary'>Voltar</UiButton>
				)}
				{button && <UiButton onClick={onClick} variant='primary'>{button}</UiButton>}

			</div>

		</CardModal>
	</div>
)

export const ModalLoading = () => (
	<div
		style={{
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 99,
			background: 'rgba(0, 0, 0, 0.5)',
		}}>
		<CardModal>
			<Carregando />
		</CardModal>
	</div>
)

const FadeInAnimation = keyframes`  
  from { top:0; opacity: 0; }
  to { top:20; opacity: 1; }
`

const CardModal = styled(Card)`
  position: fixed;
  top: 20px;
  left: 10px;
  right: 10px;
  margin: auto;
  z-index: 99;
  max-width: ${p => p.width || '500px'};
  max-height: calc(100vh - 40px);
  overflow-y: auto;
	border-radius: 8px;
  /* background-color: white;
  border-radius: 5px;
  padding: 10px; */
  /* animation: ${FadeInAnimation} 0.5s; */
`

export const startModal = ({ close, title, body, voltar, button, onClick, width }) => {
	confirmAlert({
		customUI: ({ onClose }) => (
			<Modal
				title={title}
				body={body}
				close={close}
				button={button}
				onClick={() => {
					onClick()
					onClose()
				}}
				onClose={onClose}
				width={width}
			/>
		)
	})
}

export const startModalLoading = onClick => {
	confirmAlert({
		customUI: ({ onClose }) => {
			onClick(onClose,
				() => {
					confirmAlert({
						customUI: ({ onClose }) => (
							<ModalDialog
								title={'Erro'}
								body={() => <p>Ocorreu um problema nesta atividade.</p>}
								onClose={onClose}
							/>
						)
					})
				}
			)
			return <ModalLoading />
		}
	})
}

export const startModalDialog = ({ close, title, body, color, button, onClick, width }) => {
	confirmAlert({
		customUI: ({ onClose }) => (
			<ModalDialog
				title={title}
				body={body}
				color={color}
				close={close}
				button={button}
				onClick={() => {
					onClose()
					startModalLoading(onClick)
				}}
				onClose={onClose}
				width={width}
			/>
		)
	})
}

export const openModal = ({
	title,
	body,
	onClick,
	onClickText,
	onCancel,
	onCancelText,
	width = '420px'
}) => {
	confirmAlert({
		customUI: ({ onClose }) => (
			<div>
				<div
					onClick={onClose}
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 99,
						background: 'rgba(0, 0, 0, 0.5)',
					}}
				/>
				<CardModal width={width}>
					<button onClick={onClose} style={{ background: 'transparent', border: 'none', position: 'absolute', right: '10px', top: '10px', cursor: 'pointer', outline: 'none' }}>
						<AiFillCloseCircle color='#f00' />
					</button>
					{title && <h5 className='modal-title'>{title}</h5>}
					{body()}
					<div className='d-flex flex-column flex-md-row gap tw-justify-center'>
						{onClick &&
							<UiButton onClick={() => {
								onClick(onClose)
							}}>
								{onClickText}
							</UiButton>

						}
						{onCancel &&
							<UiButton variant='tertiary' onClick={onClose}>
								{onCancelText}
							</UiButton>

						}
					</div>
				</CardModal>

			</div>
		)
	})
}
