/**
 * A função `getUidCoAuth` retorna o UID do co-criador ou do escritório de
 * um determinado objeto teaser após cloná-lo.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um objeto que contém informações sobre um teaser. A função
 * `getUidCoAuth` clona o objeto `teaser` usando a função `clone` do
 * Módulo `Utils` e então retorna o `uid` do
 * @returns A função `getUidCoAuth` retorna `cloneTeaser.uid` se
 * `cloneTeaser.uidCoCreator` é verdadeiro, `cloneTeaser.fromOffice` se
 * `cloneTeaser.fromOffice` é verdadeiro, ou `null` se nenhum dos dois
 * `cloneTeaser.uidCoCreator` nem `cloneTeaser.fromOffice` são verdadeiros.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const getUidCoAuth = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.uidCoCreator ? cloneTeaser.uid : cloneTeaser.fromOffice ? cloneTeaser.fromOffice : null

}
