// export const numberToCoin = (number) => {
//     if(number === ''){
//         return ''
//     }
//     else if(number === '-'){
//         return 'R$ -'
//     }
//     else{
//         let numSemDecimal = Number(Number(number).toFixed(0))
//         return 'R$ ' + numSemDecimal.toLocaleString('pt-br')
//     }
// }

import { clone } from './clone'

// export const coinToNumber = (coin = 0) => {
//     let novoNum = coin
//     novoNum = novoNum.replace('R$ ', '')
//     // novoNum = coin.replace(/[^\d]+/g, '')
//     novoNum = novoNum.replace(/[a-zA-Z]|[ç]|[,]|[.]+/g, '')
//     return novoNum
// }

/**
* Formata um número para reais com R$
* @param {String | Number} number {String} Número a ser formatado
* @returns {String} {String} Número formatado
* @example numberToCoin(1000) => 'R$ 1.000,00'
* @example numberToCoin('') => ''
* @example numberToCoin('-') => 'R$ -'
*/
export const numberToCoin = (number: number | string) => {

	const cloneNumber = clone(number)

	if (cloneNumber === '') {
		return ''
	}
	else if (cloneNumber === '-') {
		return 'R$ -'
	}

	let coin = cloneNumber
	coin = numberToMoney(coin)
	coin = `R$ ${coin}`
	return coin
}

/**
* Formata um número para decimal/dinheiro
* @param {String | Number} number {String} Número a ser formatado
* @returns {String} {String} Número formatado
* @example numberToCoin(1000) => '1.000,00'
*/
export const numberToMoney = (value: number | string) => {
	const cloneValue = clone(value)
	return Number(cloneValue).toLocaleString('pt-br', { minimumFractionDigits: 2 })

}

/**
* Formata um valor para string de número
* @param {String} number {String} Valor a ser formatado
* @returns {String} {String} Valor formatado
* @example coinToNumber('R$ 1.000,00') => '1000.00'
* @example coinToNumber('R$') => ''
* @example coinToNumber('-') => '-'
*/
export const coinToNumber = (coin = '0') => {

	const cloneCoin = clone(coin)

	if (
		cloneCoin === 'R$' ||
		cloneCoin === 'R$ ' ||
		cloneCoin === 'R$ 0,0' ||
		cloneCoin === 'R$ -0,0' ||
		cloneCoin === 'R$ 0,00' ||
		cloneCoin === 'R$ -0,00'
	) {
		return ''
	}
	else if (cloneCoin === '-') {
		return '-'
	}

	let num = cloneCoin
	num = num.replace('R$ ', '')
	num = num.replace(/[.]/g, '')
	num = num.replace(/[,]/g, '')
	num = num.replace(/[a-zA-Z]|[$]|[ç]+/g, '')
	if (num.match('-')) {
		num = num.replace(/[-]+/g, '')
		num = `-${num}`
	}
	num = (Number(num) / 100).toFixed(2)
	return num
}
