import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ListFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M17.2239 13.5922H23.3251V11.1517H17.2239V13.5922ZM17.2239 22.1339H23.3251V19.6934H17.2239V22.1339ZM24.5453 27.6249H7.46196C6.81471 27.6249 6.19396 27.3678 5.73628 26.9101C5.27861 26.4525 5.02148 25.8317 5.02148 25.1845V8.10112C5.02148 7.45387 5.27861 6.83312 5.73628 6.37544C6.19396 5.91777 6.81471 5.66064 7.46196 5.66064H24.5453C25.1926 5.66064 25.8133 5.91777 26.271 6.37544C26.7287 6.83312 26.9858 7.45387 26.9858 8.10112V25.1845C26.9858 25.8317 26.7287 26.4525 26.271 26.9101C25.8133 27.3678 25.1926 27.6249 24.5453 27.6249ZM8.6822 15.4226H14.7834V9.32136H8.6822V15.4226ZM9.90244 10.5416H13.5632V14.2023H9.90244V10.5416ZM8.6822 23.9642H14.7834V17.863H8.6822V23.9642ZM9.90244 19.0833H13.5632V22.744H9.90244V19.0833Z' fill='#272827' />
	</Svg>
)


