import { minhaData } from '../../Utils/minhaData'
import { fnMatch } from '..'
import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeTese } from '../../Tese/types/tese'
import { TypeMatch } from '../types/match'
import { clone } from '../../Utils/clone'
import { db5 } from '../../../Services/db5'
import { fnError } from '../../Errors/fnError'

export const buildMatch = ({
	idTese = '',
	tese = {} as TypeTese,
	idTeaser = '',
	teaser = {} as TypeTeaser,
	idInstitution = '',
	status = '' as TypeMatch['status'],
	typeOperation = [TYPE_OPERATION.op1]
}) => {

	const cloneIdTese = clone(idTese)
	const cloneTese = clone(tese)
	const cloneIdTeaser = clone(idTeaser)
	const cloneTeaser = clone(teaser)
	const cloneIdInstitution = clone(idInstitution)
	const cloneStatus = clone(status)
	const cloneTypeOperation = clone(typeOperation)

	return fnMatch.modelMatch({
		fromOffice: cloneTeaser.fromOffice,
		status: cloneStatus === 0 ? 0 : cloneStatus || ((cloneTeaser.dataContrato && cloneTeaser.dataCuradoria) ? 15 : 10),
		matchMakingStatus: 0,

		uidTese: cloneTese.uid || '',
		uidTeaser: cloneTeaser.uid,

		idTese: cloneIdTese || '',
		idTeaser: cloneIdTeaser,
		idInstitution: cloneIdInstitution || '',
		institutionHub: cloneTeaser.institutionHub || '',

		dataContrato: (cloneTeaser.dataContrato && cloneTeaser.dataCuradoria) ? cloneTeaser.dataContrato : '',
		dataCuradoria: (cloneTeaser.dataContrato && cloneTeaser.dataCuradoria) ? cloneTeaser.dataCuradoria : '',

		dataCriacao: minhaData(),

		// tese: tese || '',
		// teaser: teaser,
		codinomeTese: cloneTese.codinome || '',
		codinomeTeaser: cloneTeaser.codinome,
		etr: cloneTeaser.etr,
		setor: cloneTeaser.setor,
		typeOperation: cloneTypeOperation,
	})
}

export const create = async ({
	idTese = '',
	tese = {} as TypeTese,
	idTeaser = '',
	teaser = {} as TypeTeaser,
	idInstitution = '',
	status = '' as TypeMatch['status'],
	// institution= {},
	// profile={},
	// uid = '',
	// numberOfInvestors=1,
	typeOperation = [TYPE_OPERATION.op1]
}) => {
	const cloneIdTese = clone(idTese)
	const cloneTese = clone(tese)
	const cloneIdTeaser = clone(idTeaser)
	const cloneTeaser = clone(teaser)
	const cloneIdInstitution = clone(idInstitution)
	const cloneStatus = clone(status)
	const cloneTypeOperation = clone(typeOperation)

	const response = await db5.matches.add({
		doc: buildMatch({
			idTese: cloneIdTese,
			tese: cloneTese,
			idTeaser: cloneIdTeaser,
			teaser: cloneTeaser,
			idInstitution: cloneIdInstitution,
			status: cloneStatus || '',
			typeOperation: cloneTypeOperation
		})
	})
	if (response.status !== 200) fnError.alert({ message: 'Ocorreu um erro tente novamente mais tarde' })
}
