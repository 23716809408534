import { STATUS_MATCH_MAKING } from '../../../../Constants/STATUS_MATCH_MAKING'
import { TypeMatch } from '../../types/match'

import { clone } from '../../../Utils/clone'

export const relationMatchMakingStatus = (match: TypeMatch) => {

	const cloneMatch = clone(match)

	Object.values(STATUS_MATCH_MAKING).filter(status => {
		if (status.value === cloneMatch.status) {
			return status
		}
	})
	return STATUS_MATCH_MAKING.beforeMatchMaking
}
