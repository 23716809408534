import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BookOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M3.37891 5.8751C3.37891 5.1019 4.00571 4.4751 4.77891 4.4751H11.5289C13.0937 4.4751 14.5944 5.0967 15.7008 6.20317C16.8073 7.30963 17.4289 8.81032 17.4289 10.3751V26.1251C17.4289 26.8983 16.8021 27.5251 16.0289 27.5251C15.2557 27.5251 14.6289 26.8983 14.6289 26.1251C14.6289 25.6013 14.4208 25.0989 14.0504 24.7286C13.6801 24.3582 13.1777 24.1501 12.6539 24.1501H4.77891C4.00571 24.1501 3.37891 23.5233 3.37891 22.7501V5.8751ZM14.6289 21.7777V10.3751C14.6289 9.55293 14.3023 8.76443 13.7209 8.18307C13.1396 7.6017 12.3511 7.2751 11.5289 7.2751H6.17891V21.3501H12.6539C13.3418 21.3501 14.0144 21.4985 14.6289 21.7777Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.357 6.20317C17.4634 5.0967 18.9641 4.4751 20.5289 4.4751H27.2789C28.0521 4.4751 28.6789 5.1019 28.6789 5.8751V22.7501C28.6789 23.5233 28.0521 24.1501 27.2789 24.1501H19.4039C18.8801 24.1501 18.3778 24.3582 18.0074 24.7286C17.637 25.0989 17.4289 25.6013 17.4289 26.1251C17.4289 26.8983 16.8021 27.5251 16.0289 27.5251C15.2557 27.5251 14.6289 26.8983 14.6289 26.1251V10.3751C14.6289 8.81032 15.2505 7.30963 16.357 6.20317ZM17.4289 21.7777C18.0434 21.4985 18.716 21.3501 19.4039 21.3501H25.8789V7.2751H20.5289C19.7067 7.2751 18.9182 7.6017 18.3369 8.18307C17.7555 8.76443 17.4289 9.55293 17.4289 10.3751V21.7777Z' fill='#272827' />

	</Svg>
)


