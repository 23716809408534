import { clone } from '../../../Utils/clone'
import { TypeTeaser } from '../../types/teaser'

export const isOriginatedFromUid = (teaser: TypeTeaser, uid: string): boolean => {

	const cloneTeaser = clone(teaser)
	const cloneUid = clone(uid)

	if (cloneTeaser.uid === cloneUid) return true
	if (cloneTeaser.uidCoCreator === cloneUid) return true
	if (cloneTeaser.fromOffice === cloneUid) return true
	return false
}
