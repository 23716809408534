import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PersonFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M16.0036 5.66064C17.46 5.66064 18.8566 6.23917 19.8864 7.26894C20.9162 8.29872 21.4947 9.69539 21.4947 11.1517C21.4947 12.608 20.9162 14.0047 19.8864 15.0345C18.8566 16.0643 17.46 16.6428 16.0036 16.6428C14.5473 16.6428 13.1506 16.0643 12.1209 15.0345C11.0911 14.0047 10.5126 12.608 10.5126 11.1517C10.5126 9.69539 11.0911 8.29872 12.1209 7.26894C13.1506 6.23917 14.5473 5.66064 16.0036 5.66064ZM16.0036 19.3883C22.0713 19.3883 26.9858 21.8456 26.9858 24.8794V27.6249H5.02148V24.8794C5.02148 21.8456 9.93599 19.3883 16.0036 19.3883Z' fill='#272827' />
	</Svg>
)


