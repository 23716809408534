import * as Tokens from 'Components/CardDS/src/Tokens'

export const CheckboxGroupTheme = {
	title: {
		color: '#6F6F6F',
		font: Tokens.FONT_MAIN,
		size: Tokens.FONT_SIZE_SM,
		weight: Tokens.FONT_SEMI_BOLD
	},
	helper: {
		font: Tokens.FONT_MAIN,
		size: Tokens.FONT_SIZE_SM,
		weight: Tokens.FONT_SEMI_BOLD,
		color: {
			['default']: {
				color: '#6F6F6F'
			},
			['error']: {
				color: Tokens.COLOR_ERROR_400
			},
			['success']: {
				color: Tokens.COLOR_SUCCESS_400
			}
		}
	},
}
