import { Component } from 'react'

import firebase from 'firebase/app'
import 'firebase/auth'
import { LogoStarkTypographyWhite } from 'Components/LogoStarkTypographyWhite'
import { UiInput } from 'ComponentsUi/UiInput'
import { UiLabel } from '../../ComponentsUi/UiLabel'
import { UiButton } from '../../ComponentsUi/UiButton'

const fundoDeTela = {
	background: 'linear-gradient(60deg, #0b31dd, #285194)',
	backgroundSize: 'cover',
	backgroundPosition: 'top center',
}
class Screen_Recover extends Component {
	state = {
		email: '',
		status: 'wait'
	}

	redefinirSenhaHandler = () => {
		this.setState({ status: 'loading' })

		firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
			this.setState({ status: 'success' })
		}).catch(error => {
			this.setState({ status: 'error' })
		})

	}

	render() {
		return (
			<div className='Screen_Login'>

				<div className='wrapper wrapper-full-page'>
					<div className='page-header login-page header-filter' style={fundoDeTela}>
						<div className='container'>
							<div className='row'>
								<div className='col-12 col-lg-4 ml-auto mr-auto pt-5 mt-5'>
									<div className='text-center'><LogoStarkTypographyWhite style={{ width: 200 }} /></div>
									<div className='text-center px-3'>
										<h3>Recuperação de senha</h3>
										<p>Você receberá um link para criar<br />uma nova senha em seu e-mail</p>
									</div>

									<div className='mx-4'>
										<UiLabel className='tw-text-white'>Seu e-mail</UiLabel>
										<UiInput value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />

										{
											this.state.status === 'error' && this.state.email &&
											<div className='text-center text-danger py-2'>Erro, e-mail não encontrado.</div>
										}

										{
											this.state.status === 'success' &&
											<div className='text-center tw-text-white py-2'>Pronto. O link de recuperação foi enviado para seu e-mail</div>
										}

										<UiButton
											fullWidth
											type='button'
											variant='quaternary'
											onClick={this.redefinirSenhaHandler}
											disabled={this.state.status === 'loading' || !this.state.email}
											className='tw-mt-3 tw-w-full'
											size='medium'
										>
											{this.state.status === 'loading' ? 'Aguarde' : 'Redefinir senha'}
										</UiButton>


									</div>

									{/* </Card> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Screen_Recover
