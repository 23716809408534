import React, { SelectHTMLAttributes } from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { UiButton } from 'ComponentsUi/UiButton'

const Styles = {
	InputSelect: {
		backgroundImage: 'linear-gradient(to top, #3f51b5 2px, rgba(63,81,181,0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210,210,210,0) 1px)',
		WebkitAppearance: 'menulist',
		MozAppearance: 'menulist',
		height: 'calc(2.1375rem + 2px)',
	},
	OptionSelect: {
		fontSize: '16px',
		background: '#ffff',
		border: 'none'
	},
	Checkbox: {
		width: '20px',
		height: '20px',
		marginRight: '10px',
	},
	CheckboxLabel: {
		bottom: '5px',
		position: 'relative',
	},
	UiInput: {
		color: '#000',
		fontWeight: '300',
		fontSize: '12px'
	}
}

/** @deprecated */
export const InputText = (
	{ type = 'text', name, id, label, placeholder, mask, value, onChange, ...props }
		: { type?: string, name?: string, id?: string, label?: string, placeholder?: string, mask?: string, value?: any, onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void }
) => (
	<Input>
		{label && <label>{label}</label>}
		<InputMask
			value={value}
			name={name}
			id={id}
			className='InputMask'
			mask={mask || ''}
			type={type}
			placeholder={placeholder}
			onChange={onChange}
			{...props}
		/>
	</Input>
)

/** @deprecated */
export const InputDataList = (
	{ children, id, label, placeholder, ...props }
		: { children: React.ReactNode, id: string, label: string, placeholder: string }
) => (
	<Input>
		{label && <label>{label}</label>}

		<input className='InputMask' list={id} placeholder={placeholder} {...props} />
		<datalist id={id}>
			{children}
		</datalist>

	</Input>
)

/** @deprecated */
export const InputNumber = (
	{ label, placeholder, mask, ...props }
		: { label: string, placeholder: string, mask: string }
) => (
	<Input>
		{label && <label>{label}</label>}
		<InputMask
			className='InputMask'
			mask={mask}
			type='number'
			placeholder={placeholder}
			{...props}
		/>
	</Input>
)

/** @deprecated */
export const InputSelect = (
	{ value, onChange, children, label, primeiro, colorBg, ...props }
		: { value: any, onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void, children: React.ReactNode, label: string, primeiro?: string, colorBg?: string }
) => (
	<div style={{
		margin: '4px 0px',
		height: '43px',
		border: '1px solid #eaeaea',
		backgroundColor: colorBg || 'white',
		borderRadius: '8px',
		position: 'relative',
	}}>

		{label && <label
			style={{
				position: 'absolute',
				fontSize: '9px',
				top: 0,
				left: '10px',
				zIndex: 0,
			}}
		>{label}</label>}
		<div style={{
			position: 'absolute',
			right: 0,
			top: '14px',
			zIndex: 0,
		}}>
			<i style={{
				fontSize: '12px'
			}} className='material-icons'>keyboard_arrow_down</i>
		</div>

		<select
			onChange={onChange}
			value={value}
			style={{
				background: 'none',
				border: 'none',
				padding: '10px',
				width: '100%',
				height: '100%',
				cursor: 'pointer',
				position: 'relative',
				zIndex: 1,
			}}
			{...props}
		>
			{children}
		</select>
	</div>
)

/** @deprecated */
export const OptionSelect = ({ value, label, disabled, ...props }
	: { value: any, label?: string, disabled?: boolean }
) => (
	<option value={value} style={Styles.OptionSelect} disabled={disabled} {...props}>{label ? label : value}</option>
)

/** @deprecated */
export const Checkbox = ({ checked, value, label, onChange, name, ...props }
	: { checked?: boolean, value?: any, label: string, name?: string, onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void }
) => (
	<div className='form-check'>
		<label className='form-check-label'>
			<input className='form-check-input' type='checkbox' value={value} checked={checked} onChange={onChange} name={name} {...props} id={label} /> {label}
			<span className='form-check-sign'>
				<span className='check'></span>
			</span>
		</label>
	</div>
)

/** @deprecated */
export const MultCheckbox = ({ label, ...props }
	: { label: string }
) => (
	<div className='form-check'>
		<label className='form-check-label'>
			<input className='form-check-input' type='checkbox' {...props} /> {label}
			<span className='form-check-sign'>
				<span className='check'></span>
			</span>
		</label>
	</div>
)

/** @deprecated */
export const TextArea = ({ value, rows, label, name, placeholder, onChange, ...props }
	: { value: string, rows: number, label: string, name?: string, placeholder?: string, onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void }
) => (
	<div className={label && 'form-group bmd-form-group'}>
		{label &&
			<label
				className='bmd-label-static mb-0'
				style={{
					color: '#000',
					fontWeight: 300,
					fontSize: '12px',
					position: 'relative',
				}}
			>
				{label}
			</label>
		}
		<textarea className='form-control' name={name} value={value} placeholder={placeholder} onChange={onChange} {...props} rows={rows} style={{
			border: '1px solid #e2e8f0',
			borderRadius: '8px',
			padding: '.4rem',
		}}></textarea>
	</div>
)

/** @deprecated */
export const InputFile = ({ onChange, label, accept, multiple, ...props }
	: { onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, label: string, accept: string, multiple: boolean }
) => {
	const esteId = `id${(Math.random() * 100000).toFixed(0)}`
	return (
		<div {...props}>
			<UiButton
				onClick={() => {
					const element: HTMLElement = document?.querySelector(`#${esteId}`) as HTMLElement
					element.click()
				}}
			>{label}</UiButton>
			<div className='d-none'>
				<input multiple={multiple} type='file' id={esteId} accept={accept || 'image/*'} onChange={onChange} />
			</div>
		</div>
	)
}

const Input = styled.div`
						margin: 4px 0px;
						border: 1px solid #eaeaea;
						background-color: white;
						border-radius: 8px;
						position: relative;
						/* flex: 1; */
						width: 100%;

						&:focus-within {
							border: 1px solid #295295;
						label{
							color: #295295;
			}
		}

						label{
							position: absolute;
						font-size: 9px;
						top: 0;
						left: 10px
		}

						.InputMask{
							background: none;
						border: none;
						padding: 10px;
						width: 100%;
						height: 41px;


		}
						`
