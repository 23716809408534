export const VARIANTS_SIZES_TW = {
	'small': 'tw-text-xs tw-min-h-7 tw-h-fit', // 28px
	'medium': 'tw-text-sm tw-min-h-9 tw-h-fit', // 36px
	'large': 'tw-text-base tw-min-h-11 tw-h-fit' // 44px
} as const

export const VARIANTS_SIZES_TW_GET = (code: string) => {
	const _code = code as keyof typeof VARIANTS_SIZES_TW

	if (!VARIANTS_SIZES_TW[_code]) {
		return (
			{
				'small': '',
				'medium': '',
				'large': ''
			}
		)
	}

	return VARIANTS_SIZES_TW[_code]
}
export const VARIANTS_SIZES_TW_VALUES = Object.values(VARIANTS_SIZES_TW)
export const VARIANTS_SIZES_TW_KEYS = Object.keys(VARIANTS_SIZES_TW)
