import { TypeMatch } from '../types/match'
import { inDefinitiveContracts } from './inDefinitiveContracts'
import { inDefinitiveContractsDeclined } from './inDefinitiveContractsDeclined'
import { inDiligence } from './inDiligence'
import { inDiligenceDeclined } from './inDiligenceDeclined'
import { inLongList } from './inLongList'
import { inMatchMaking } from './inMatchMaking'
import { inMatchMakingDeclined } from './inMatchMakingDeclined'
import { inOfferNegotiations } from './inOfferNegotiations'
import { inOfferNegotiationsDeclined } from './inOfferNegotiationsDeclined'
import { inPreMatch } from './inPreMatch'
import { inPreparation } from './inPreparation'
import { inPreparationDeclined } from './inPreparationDeclined'
import { matchFinished } from './matchFinished'

export const getStatusKanbanAbsolute = (match: TypeMatch) => {
	if (inLongList(match)) return 'LongList'
	if (inPreMatch(match)) return 'PreMatch'
	if (inMatchMaking(match) || inMatchMakingDeclined(match)) return 'MatchMaking'
	if (inPreparation(match) || inPreparationDeclined(match)) return 'Preparação'
	if (inOfferNegotiations(match) || inOfferNegotiationsDeclined(match)) return 'Negociações de Oferta'
	if (inDiligence(match) || inDiligenceDeclined(match)) return 'Diligência'
	if (inDefinitiveContracts(match) || inDefinitiveContractsDeclined(match)) return 'Contratos Definitivos'
	if (matchFinished(match)) return 'Match Concluído'
	return 'Não encontrado'
}

export const getStatusKanbanNegative = (match: TypeMatch) => {
	if (match.matchMakingStatus) return 'Declinado'
	return 'Ativo'
}
