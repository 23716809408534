
export const SUB_STATUS_OF_MATCH = {
	'': { _id: '', label: 'Não Mapeado' },
	'100': { _id: '100', label: 'Preparação' },
	'200': { _id: '200', label: 'Negociações de oferta' },
	'300': { _id: '300', label: 'Diligência' },
	'400': { _id: '400', label: 'Contratos definitivos' },
} as const

export const SUB_STATUS_OF_MATCH_GET = (code: string) => {
	const _code = code as keyof typeof SUB_STATUS_OF_MATCH

	if (!SUB_STATUS_OF_MATCH[_code]) {
		return (SUB_STATUS_OF_MATCH[''])
	}

	return SUB_STATUS_OF_MATCH[_code]
}
