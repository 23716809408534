import styled, { css } from 'styled-components'

/** @deprecated */
export const Card = styled.div`${({ marked = false, bgColor }) => css`
background-color: ${bgColor || '#fff'};
margin-bottom: 7.5px;
padding: 20px;
flex: 1;
border: solid 1px #e2e8f0
border-top:${marked ? '2px solid #295295' : ''};
border-radius: 8px;
`}`
