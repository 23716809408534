import { USERS } from '../../../Constants/TYPE_USER'
import { TypeUser } from '../types/TypeUser'
import { isAdm } from './isAdm'
import { isAgent } from './isAgent'
import { isCustomer } from './isCustomer'
import { isEnterprise } from './isEnterprise'
import { isHub } from './isHub'
import { isMicroBda } from './isMicroBda'
import { isOffice } from './isOffice'
import { isOfficeAnalyst } from './isOfficeAnalyst'

export const getLabelTypeUser = (user: TypeUser) => {
	if (isAdm(user)) return USERS.adm.label
	if (isOfficeAnalyst(user)) return USERS.officeAnalyst.label
	if (isAgent(user)) return USERS.bda.label
	if (isEnterprise(user)) return USERS.enterprise.label
	if (isCustomer(user)) return USERS.customer.label
	if (isMicroBda(user)) return USERS.microBda.label
	if (isHub(user)) return USERS.hub.label
	if (isOffice(user)) return USERS.office.label
	return undefined
}

