import { clone } from '../../../Utils/clone'
import { TypeTeaser } from '../../types/teaser'

export const getTotalDebit = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const tributos = +cloneTeaser.tributos ?? 0
	const fornecedores = +cloneTeaser.fornecedores ?? 0
	const emprestimos = +cloneTeaser.emprestimos ?? 0
	const outrasdividas = +cloneTeaser.outrasdividas ?? 0

	const total = tributos + fornecedores + emprestimos + outrasdividas

	return total
}
