import { clone } from '../../../Functions/Utils/clone'
import { fnMatch } from '../../Match'
import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../../Match/types/match'
import { TypeNotification } from '../types/notification'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeTese } from '../../Tese/types/tese'
import { TypeUser } from '../../User/types/TypeUser'

// TODO: @jhonsore @cristianaragao GET MATCH QUE RECEBE MATCH MAS ESTÁ DENTRO DE UMA FUNCTION ???
const getMatch = ({ match, id }: { match: TypeMatch, id: string }) => {

	const cloneMatch = clone(match)
	const cloneId = clone(id)

	return ({
		id: cloneMatch._id || '',
		typeOperation: cloneMatch.typeOperation || ''
	})
}

// TODO: @jhonsore @cristianaragao VÁRIAS FUNÇÕES DENTRO DOS ARQUIVOS. BAGUNÇA DE GAVETAS. E TODAS DENTRO DO NOTIFICATION ??
const getTese = ({ tese, id }: { tese: TypeTese, id: string }) => {

	const cloneTese = clone(tese)
	const cloneId = clone(id)

	return ({
		id: cloneTese._id || ''
	})
}

const getOrigination = ({ origination, id }: { origination: TypeTeaser, id: string }): TypeNotification['origination'] => {

	const cloneOrigination = clone(origination)
	const cloneId = clone(id)

	return ({
		id: cloneId,
		name: cloneOrigination.nome,
		urlLogo: cloneOrigination.urlLogo,
		uid: cloneOrigination.uid,
		fantasyName: cloneOrigination.fantasyName
	})
}

const getCreatedBy = ({ profile, uid }: { profile: TypeUser, uid: string }) => {

	const cloneProfile = clone(profile)
	const cloneUid = clone(uid)

	return ({
		id: cloneUid || '',
		name: cloneProfile?.nome || '',
		type: cloneProfile?.tipo || '',
		fromOffice: cloneProfile?.fromOffice || '',
		urlLogo: cloneProfile?.ulrimg || ''
	})
}

const getInstitution = ({ institution, id }: { institution: TypeInstitution, id: string }) => {

	const cloneInstitution = clone(institution)
	const cloneId = clone(id)

	const data: TypeNotification['institution'] = {
		id: cloneId,
		name: cloneInstitution?.name || '',
		urlLogo: cloneInstitution?.urlLogo || '',
		shortDescription: cloneInstitution?.shortDescription || '',
		profile: cloneInstitution?.profile || '',
		tags: cloneInstitution?.tags || []
	}
	return data
}

const getInstitutions = ({ institutions }: { institutions: TypeNotification['institutions'] }) => {

	const cloneInstitutions = clone(institutions)

	const data: TypeNotification['institutions'] = cloneInstitutions
	return data
}

const getTeses = ({ match }: { match?: TypeMatch }) => {

	const cloneMatch = clone(match)

	const data: TypeNotification['teses'] = fnMatch.getIdsTeses({ match: cloneMatch })
	return data
}


export const formatData = {
	getOrigination,
	getCreatedBy,
	getInstitution,
	getTese,
	getMatch,
	getTeses,
	getInstitutions
}

