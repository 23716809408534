import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const MailOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M27.8 7C27.1 6.4 26.2 6 25.3 6H6.6C5.6 6 4.8 6.4 4.1 7C3.4 7.7 3 8.5 3 9.4V23C3 23.9 3.4 24.8 4.1 25.4C4.8 26 5.7 26.4 6.6 26.4H25.3C26.2 26.4 27.1 26 27.8 25.4C28.5 24.7 28.8 23.9 28.9 23V9.4C28.8 8.5 28.5 7.7 27.8 7ZM25.3 8.4C25.6 8.4 25.9 8.5 26.1 8.7C26.2 8.8 26.2 8.8 26.3 8.9L15.9 15.8L5.7 8.9C5.7 8.8 5.8 8.8 5.9 8.7C6 8.5 6.3 8.4 6.6 8.4H25.3ZM5.5 11.8L15.2 18.3C15.4 18.4 15.7 18.5 15.9 18.5C16.2 18.5 16.4 18.4 16.6 18.3L26.3 11.8V23C26.3 23.3 26.2 23.5 26 23.7C25.8 23.9 25.5 24 25.2 24H6.6C6.3 24 6 23.9 5.8 23.7C5.6 23.5 5.5 23.2 5.5 23V11.8Z' fill='#272827' />
	</Svg>
)
