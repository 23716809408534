/**
 * A função `inChurn` verifica se um objeto teaser possui um `dateContractDone`
 * propriedade e retorna um valor booleano.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente contém informações sobre um objeto teaser. A função `inChurn`
 * pega esse objeto `teaser` como entrada e cria um clone dele usando o `clone`
 * função do caminho especificado e, em seguida, verifica se
 * @returns A função `inChurn` recebe um objeto `teaser` do tipo `TypeTeaser`,
 * clona-o usando a função `clone` e depois verifica se o `dateContractDone`
 *propriedade do teaser clonado é verdadeira. A função retorna um valor booleano
 * indicando se a propriedade `dateContractDone` existe no teaser clonado
 * objeto.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'


export const inChurn = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return !!cloneTeaser.dateContractDone

}
