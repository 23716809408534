/**
 * A função isNotPublicado verifica se um objeto teaser não possui um
 *propriedade dataPublicacao.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente é um objeto contendo dados relacionados a um teaser. A função
 * `isNotPublicado` pega esse objeto `teaser` e o clona usando o `clone`
 * função do caminho especificado e, em seguida, verifica se o
 * @returns A função `isNotPublicado` está retornando um valor booleano. Ele retorna
 * `true` se a propriedade `dataPublicacao` do objeto `teaser` clonado for falsa
 * ou indefinido e `false` caso contrário.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

//is
export const isNotPublicado = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return !cloneTeaser?.dataPublicacao

}
