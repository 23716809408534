import React from 'react'
import { masks } from 'Functions/Utils/masks'
import { IUiInput } from '..'
import { handleKeyDown } from '../methods/handleKeyDown'
import { onChangeCurrency } from '../methods/onChangeCurrency'
import { onChangePercentage } from '../methods/onChangePercentage'
import { onChangePercentageDecimal } from '../methods/onChangePercentageDecimal'
import { onChangePhone } from '../methods/onChangePhone'
import { onChangeTimeUnit } from '../methods/onChangeTimeUnit'
import { verifyDateIsValid } from '../methods/verifyDateIsValid'
import { onChangeDate } from '../methods/onChangeDate'

export const masks_props = {
	cpf: (props: IUiInput) => ({
		...props,
		value: masks.CPF(props.value as string),
		placeholder: '000.000.000-00',
	}),
	cnpj: (props: IUiInput) => ({
		...props,
		value: masks.CNPJ(props.value as string),
		placeholder: '00.000.000/0000-00',
	}),
	cep: (props: IUiInput) => ({
		...props,
		value: masks.CEP(props.value as string).value,
		placeholder: masks.CEP(props.value as string).placeholder || 'Não possui',
	}),
	currency: (props: IUiInput) => ({
		...props,
		value: masks.Currency(props.value as number, props.currency),
		onChange: onChangeCurrency(props),
	}),
	phone: (props: IUiInput) => ({
		...props,
		error:
			!masks.Phone(props.value as string, props.country).isPossible &&
			!masks.Phone(props.value as string, props.country).isValid &&
			Boolean(props.value),
		helperText: props.error ? 'Número inválido' : '',
		onChange: onChangePhone(props),
	}),
	percentage: (props: IUiInput) => ({
		...props,
		value: masks.Percent(props.value as number, props.fraction),
		onChange: onChangePercentage(props),
		onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
			const cursorPosition = event.currentTarget.selectionStart
			const backspacePressed = event.key === 'Backspace'
			const value = event.currentTarget.value

			if (
				backspacePressed &&
				cursorPosition &&
				cursorPosition < value.length
			) {
				const newValue =
					value.slice(0, cursorPosition - 1) + value.slice(cursorPosition)
				event.currentTarget.value = masks.helper.percent(newValue)
			}

			if (backspacePressed && cursorPosition === value.length) {
				const newValue =
					value.slice(0, cursorPosition - (3 - (props.fraction || 1))) +
					value.slice(cursorPosition)
				event.currentTarget.value = newValue
			}
		},
	}),
	percentage_decimal: (props: IUiInput) => ({
		...props,
		value: masks.Percent((props.value as number) * 100, props.fraction),
		onChange: onChangePercentageDecimal(props),
		onKeyDown: handleKeyDown,
	}),
	site: (props: IUiInput) => ({
		...props,
		value: masks.Url(props.value as string),
		placeholder: 'https://exemplo.com',
	}),
	number_unit: (props: IUiInput) => {
		/**
		 * @description Função que serve para apagar número com o Backspace, não pode ser componentizada pois não recebe
		 * argumento além do "KeyboardEvent" (linha 64), e ela precisa das props do IUIInput (linha 76)
		 */
		const handleKeyDownUnit = (
			event: React.KeyboardEvent<HTMLInputElement>
		) => {
			const cursorPosition = event.currentTarget.selectionStart
			const backspacePressed = event.key === 'Backspace'
			const value = event.currentTarget.value

			if (
				backspacePressed &&
				cursorPosition &&
				cursorPosition < value.length
			) {
				const newValue =
					value.slice(0, cursorPosition - 1) + value.slice(cursorPosition)
				event.currentTarget.value = newValue
			}

			if (backspacePressed && cursorPosition === value.length) {
				const newValue =
					value.slice(
						0,
						cursorPosition - (props.unit ? props.unit.length + 1 : 1)
					) + value.slice(cursorPosition)
				event.currentTarget.value = newValue
			}
		}

		return {
			...props,
			value: masks.Time(props.value as number, props.unit),
			placeholder: `0 ${props.unit}`,
			onKeyDown: handleKeyDownUnit,
			onChange: onChangeTimeUnit(props),
		}
	},
	date_ddMMyyyy: (props: IUiInput) => {
		/**
		 * @description verifica se a data é valida a partir do maxDate ou minDate
		 */
		const error = Boolean(props.value) && verifyDateIsValid(props)

		return {
			...props,
			placeholder: 'DD/MM/YYYY',
			onChange: onChangeDate(props),
			error: error,
			helperText: error ? 'Data inválida' : props.helperText || '',
			leftIcon: 'calendar_today',
		}
	},
} as const
