import { fatorialStringForSearch } from '../../Utils/fatorialStringForSearch'
import { minhaData } from '../../Utils/minhaDataNew'
import { TypeInstitution } from '../types/institution'

export const modelInstitution = (obj: any = {}): TypeInstitution => ({
	_id: obj._id || '',
	...modelInstitutionBlind(obj),

	data: obj.data || minhaData(),
	dataChange: obj.data || minhaData(),

	longDescription: obj.longDescription || '',
	site: obj.site || '',
	cnpj: obj.cnpj || '',

	tel: obj.tel || '',
	email: obj.email || '',
	address: obj.address || '',

	aum: obj.aum || '',
	tags: obj.tags || [],

	companies: obj.companies || {},
	keyPeople: obj.keyPeople || {},
	teses: obj.teses || {},
	transactions: obj.transactions || {},
	deleted: obj.deletedAt || false,
	deletedAt: obj.deletedAt || null,
})

export const modelInstitutionBlind = (obj: any = {}) => ({
	urlLogo: obj.urlLogo || '',
	name: obj.name || '',
	shortDescription: obj.shortDescription || '',
	headquarters: obj.headquarters || '',
	profile: obj.profile || '',
	profileRisk: obj.profileRisk ? obj.profileRisk.map((i: any) => +i) : [],
	creditSectors: obj.creditSectors ?? [],
	products: obj.products ?? [],
	forSearch: fatorialStringForSearch(obj.name),
	forSearchTags: obj.forSearchTags || [],
})
