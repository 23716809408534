export const TESE_CREDIT_VEHICLES_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_VEHICLES

	if (!TESE_CREDIT_VEHICLES[_code]) {
		return (TESE_CREDIT_VEHICLES.v000)
	}

	return TESE_CREDIT_VEHICLES[_code]
}

export const TESE_CREDIT_VEHICLES = {
	v000: { label: 'Sem veículo', _id: 'v000', setor: [] },
	v001: { label: 'FII de CRI', _id: 'v001', setor: ['s001'] },
	v002: { label: 'Renda imobiliária', _id: 'v002', setor: ['s001'] },
	v004: { label: 'Desenvolvimento', _id: 'v004', setor: ['s001'] },
	v005: { label: 'Aquisição direta', _id: 'v005', setor: ['s001'] },
	v006: { label: 'FII Agro', _id: 'v006', setor: ['s004'] },
	v007: { label: 'FIDC', _id: 'v007', setor: ['s004'] },
	// v008: { label: 'Gestores e bancos de investimento', _id: 'v008', setor: ['s005'] },
	v009: { label: 'Bancos comerciais', _id: 'v009', setor: ['s005'] },

} as const

export const TESE_CREDIT_VEHICLES_VALUES = Object.values(TESE_CREDIT_VEHICLES)
export const TESE_CREDIT_VEHICLES_KEYS = Object.keys(TESE_CREDIT_VEHICLES)
