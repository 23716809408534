import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const StampOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16.029 5.6187C12.5603 5.6187 9.74834 8.36136 9.74834 11.7446C9.74834 15.1278 12.5603 17.8705 16.029 17.8705C19.4977 17.8705 22.3097 15.1278 22.3097 11.7446C22.3097 8.36136 19.4977 5.6187 16.029 5.6187ZM7.06348 11.7446C7.06348 6.91509 11.0775 3 16.029 3C20.9805 3 24.9945 6.91509 24.9945 11.7446C24.9945 16.5741 20.9805 20.4892 16.029 20.4892C11.0775 20.4892 7.06348 16.5741 7.06348 11.7446Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M19.9878 16.701C20.7228 16.6059 21.3976 17.1099 21.4951 17.8267L22.8136 27.5183C22.8814 28.0167 22.6506 28.5089 22.2198 28.7849C21.789 29.0609 21.2342 29.0719 20.7922 28.8133L16.03 26.0263L11.2794 28.8126C10.8376 29.0717 10.2828 29.0612 9.85173 28.7856C9.42062 28.51 9.18936 28.018 9.25663 27.5196L10.5631 17.8398C10.6599 17.1228 11.3342 16.6181 12.0693 16.7125C12.8043 16.8069 13.3218 17.4646 13.225 18.1815L12.2819 25.1689L15.3374 23.3768C15.7627 23.1274 16.2944 23.1272 16.7199 23.3762L19.7858 25.1704L18.8336 18.1712C18.7361 17.4543 19.2528 16.7961 19.9878 16.701Z' fill='#272827' />
	</Svg>
)


