import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const HeadphoneFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M15.9994 4.14258C9.70305 4.14258 4.66602 9.19521 4.66602 15.511V24.3531C4.66602 25.3581 5.06403 26.322 5.7725 27.0327C6.48097 27.7433 7.44186 28.1426 8.44379 28.1426H12.2216V18.0373H7.18453V15.511C7.18453 13.1659 8.11324 10.9169 9.76633 9.25869C11.4194 7.60047 13.6615 6.66889 15.9994 6.66889C18.3372 6.66889 20.5793 7.60047 22.2324 9.25869C23.8855 10.9169 24.8142 13.1659 24.8142 15.511V18.0373H19.7771V28.1426H23.5549C24.5568 28.1426 25.5177 27.7433 26.2262 27.0327C26.9347 26.322 27.3327 25.3581 27.3327 24.3531V15.511C27.3327 9.19521 22.2579 4.14258 15.9994 4.14258Z' fill='#272827' />
	</Svg>
)


