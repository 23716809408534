import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const LaptopFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M6.00391 9.14258H26.0039V21.6426H6.00391V9.14258ZM26.0039 24.1426C26.6669 24.1426 27.3028 23.8792 27.7717 23.4103C28.2405 22.9415 28.5039 22.3056 28.5039 21.6426V9.14258C28.5039 7.75508 27.3789 6.64258 26.0039 6.64258H6.00391C4.61641 6.64258 3.50391 7.75508 3.50391 9.14258V21.6426C3.50391 22.3056 3.7673 22.9415 4.23614 23.4103C4.70498 23.8792 5.34087 24.1426 6.00391 24.1426H1.00391V26.6426H31.0039V24.1426H26.0039Z' fill='#272827' />
	</Svg>
)


