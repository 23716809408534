import { clone } from '../../../Functions/Utils/clone'
import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { Timestamp } from '../../../Functions/Time/types/Timestamp'
import { TypeNotifications } from '../types/notification'

export type TypeGroupByTeaser = {
	[teaserId: string]: {
		dateLastNotification: Timestamp
		notifications: TypeNotifications
	}
}

export const getNotificationsGroupByTeaser = ({ notifications }: { notifications: TypeNotifications }): TypeGroupByTeaser => {

	const cloneNotifications = clone(notifications)

	const group: TypeGroupByTeaser = {}

	Object.entries(cloneNotifications).forEach(([id, notification]) => {

		if (notification.origination?.id) {

			const dateNotification = notification.createdAt instanceof Date ? helpersDate.convertAllToTimestamp(notification.createdAt) : notification.createdAt

			if (!group[notification.origination.id]) {
				group[notification.origination.id] = {
					dateLastNotification: dateNotification,
					notifications: {}
				}
			}

			const date = group[notification.origination.id]?.dateLastNotification

			group[notification.origination.id] = {
				...group[notification.origination.id],
				dateLastNotification: date ? (date.seconds < dateNotification.seconds ? dateNotification : date) : dateNotification,
				notifications: {
					...group[notification.origination.id].notifications,
					[id]: notification
				}
			}

		}

	})

	return group

}
