import { Timestamp, _Timestamp } from '../../Time/types/Timestamp'
import { dataStringIsValid, howManyDays, howManyMonths, isBetween, isBiggerThan } from './comparisons'
import { convertAllToDate, convertAllToNumber } from './converts'
import { day_name, day_name_abbreviation, day_number, DD_MM_YYYY, HH_mm, HH_mm_ss, hour, minute, month_name, month_name_abbreviation, month_number, year, year_two_digits, YYYY_MM_DD } from './formats'
import { whatsFutureDate, whatsPastDate } from './functions'

/**
 * @description Tipo de parametro que a maioria das funções da fnDate aceita
 *
 * @example
*/
export type TypeParameterDate = number | string | Timestamp | _Timestamp | Date | undefined | null

export const fnDate = {
	/**
	 * @description "new" é uma função que retorna a data atual em formato de número. Ex: 20230401101010
	*/
	new: () => convertAllToNumber(new Date()),

	/**
	 * @description "converts" são funções que recebem uma data e convertem para outro tipo
	*/
	converts: {
		convertAllToDate,
		convertAllToNumber,
	},

	/**
	 * @description "functions" são funções que recebem uma data e alteram o valor
	 * inicial retornando um novo valor
	*/
	functions: {
		whatsFutureDate,
		whatsPastDate
	},

	/**
	 * @description "comparisons" são funções de comparações que retornam true ou false
	 * e também há funções que retornam a diferença de uma data e outra
	*/
	comparisons: {
		isBiggerThan,
		isBetween,
		howManyDays,
		howManyMonths,
		dataStringIsValid
	},

	/**
	 * @description "formats" são funções apenas para mostrar uma data formatada
	*/
	formats: {
		YYYY_MM_DD,
		DD_MM_YYYY,
		HH_mm_ss,
		HH_mm,
		month_number,
		month_name_abbreviation,
		month_name,
		year,
		year_two_digits,
		hour,
		minute,
		day_name,
		day_name_abbreviation,
		day_number
	}

}
