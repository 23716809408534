import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../../Match/types/match'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { fnNotification } from '..'
import { clone } from '../../../Functions/Utils/clone'
import { notifications } from '../../../Services/cloudfunctions'
import { fnError } from '../../Errors/fnError'

export type Props = {
	origination: TypeTeaser
	originationId: string
	profile: TypeUser
	uid: string
	match: TypeMatch
	matchId: string
	institution: TypeInstitution
	institutionId: string
}

export const sendInativeToMatch =
	async ({
		origination,
		originationId,
		profile,
		uid,
		match,
		matchId,
		institution,
		institutionId
	}: Props) => {

		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneProfile = clone(profile)
		const cloneUid = clone(uid)
		const cloneMatch = clone(match)
		const cloneMatchId = clone(matchId)
		const cloneInstitution = clone(institution)
		const cloneInstitutionId = clone(institutionId)

		const responseInactive = await fnNotification.customer.sendInativeToMatch({
			origination: cloneOrigination,
			originationId: cloneOriginationId,
			profile: cloneProfile,
			uid: cloneUid,
			match: cloneMatch,
			matchId: cloneMatchId,
			institution: cloneInstitution,
			institutionId: cloneInstitutionId
		})

		const responseNotifications = await notifications({
			targetId: cloneOriginationId,
			typeOrMessage: 'a00010',
			profileUid: cloneUid,
		})

		return { responseInactive, responseNotifications }

	}
