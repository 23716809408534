import { TypeUser } from 'Functions/User/types/TypeUser'
import { TypeTransaction } from '../../Types/TypeTransaction'
import { db5 } from 'Services/db5'
import { modelTransaction } from './model'

/**
 * Função usada para criar uma transação de empresa de capital fechado
 * @param transaction TypeTransaction
 * @param profile TypeUser
 * @returns
 */
export const createTransaction =
	async ({ transaction, profile }: { transaction: Partial<TypeTransaction>, profile: TypeUser }) => await db5.sinatraTransactions.add({ doc: { ...modelTransaction(transaction), createdBy: { uid: profile._id, name: `${profile.nome} ${profile.sobrenome || ''}` }, fromOffice: profile.fromOffice || '' } })
