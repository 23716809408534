import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Check = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<g clipPath='url(#clip0_4386_434)'>
			<path d='M27.4817 7.19091C27.0467 6.75909 26.4378 6.5 25.8289 6.5C25.22 6.5 24.6112 6.75909 24.1762 7.19091L12.2593 19.8L7.82305 14.9636C7.38813 14.5318 6.77923 14.2727 6.17034 14.2727C5.56144 14.2727 4.95255 14.5318 4.51762 14.9636C3.64777 15.9136 3.64777 17.2955 4.51762 18.2455L10.6066 24.7227C11.1285 25.2409 11.6504 25.5 12.2593 25.5C12.8682 25.5 13.3901 25.2409 13.912 24.7227L27.4817 10.4727C28.2645 9.52273 28.2645 8.14091 27.4817 7.19091Z' fill='#272827' />
		</g>
		<defs>
			<clipPath id='clip0_4386_434'>
				<rect width='24.1818' height='19' fill='white' transform='translate(3.90918 6.5)' />
			</clipPath>
		</defs>
	</Svg>
)
