import { clone } from './clone'

/**
* Recebe uma string formada por números e formata para Cnae
* @param {String } n {String} String a ser formatada
* @returns {String} {String} String em formado Cnae
* @example stringToCnae('9609202') => '96.09-2-02'
*/



export const stringToCnae = (n: string): string => {

	const cloneN = clone(n)

	if (!cloneN) {
		// console.log('Cnae deve conter 7 caracteres')
		return ''
	}
	// exemplo de entrada '1412601'
	const cnae = `${cloneN[0] + cloneN[1]}.${cloneN[2]}${cloneN[3]}-${cloneN[4]}-${cloneN[5]}${cloneN[6]}`
	return cnae
}
