import React, { FC, HTMLAttributes, ReactChild } from 'react'
import styled from 'styled-components'
import { Default, ITheme } from 'Components/CardDS/src'

/** @deprecated */
export interface SiderProps extends HTMLAttributes<HTMLDivElement> {
	/** Component children */
	children?: ReactChild | ReactChild[];

	/** Sider width */
	width?: number;
}

interface InternalProps extends SiderProps {
	theme: ITheme
}

const LayoutStyled = styled.aside`
    width: ${(props: SiderProps) => props.width}px;
    box-sizing: border-box;
    overflow-y: scroll;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    ${(props: InternalProps) => `@media(max-width: ${props.theme.breakpoints?.sizes?.md}px){
        display: none;
      }`}
`

/**
 * A custom sider component
 */
/** @deprecated */
export const Sider: FC<SiderProps> = props => {
	const { children } = props
	return <LayoutStyled {...props}>{children}</LayoutStyled>
}

const DF: InternalProps = {
	theme: Default,
	width: 220
}

Sider.defaultProps = DF

