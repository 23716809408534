import React, { createContext, useContext, useState } from 'react'

const _useContext = () => {

	const [menuActiveValue, setMenuActiveValue] = useState('')
	const [subMenuActiveValue, setSubMenuActiveValue] = useState('')

	return ({
		menuActiveValue, setMenuActiveValue,
		subMenuActiveValue, setSubMenuActiveValue,
	})
}

const ContextMenu = createContext({} as ReturnType<typeof _useContext>)

export const useContextMenu = () => useContext(ContextMenu)

export const ContextMenuProvider: React.FC = ({ children }) => {
	const controller = _useContext()
	return (
		<ContextMenu.Provider value={controller}>
			{children}
		</ContextMenu.Provider>
	)
}
