import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ElectricityFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M15.4539 25.4426L20.7999 14.7396H17.1039V7.84258L11.6039 18.5456H15.4539V25.4426ZM16.0039 5.64258C19.0289 5.64258 21.6139 6.74258 23.7589 8.88758C25.9039 11.0326 27.0039 13.6176 27.0039 16.6426C27.0039 19.6676 25.9039 22.2526 23.7589 24.3976C21.6139 26.5426 19.0289 27.6426 16.0039 27.6426C12.9789 27.6426 10.3939 26.5426 8.24891 24.3976C6.10391 22.2526 5.00391 19.6676 5.00391 16.6426C5.00391 13.6176 6.10391 11.0326 8.24891 8.88758C10.3939 6.74258 12.9789 5.64258 16.0039 5.64258Z' fill='#272827' />
	</Svg>
)


