import { TypeDbResponse } from '../TypeDBResponse'
import { DocumentData } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import { TypeCollections } from '../configs/collections'

export const update = <TDoc extends DocumentData>(collection: TypeCollections) => (
	async ({ id, doc }: { id: string, doc: Partial<TDoc> }): Promise<TypeDbResponse<TDoc>> => {
		const serviceResult: TypeDbResponse<TDoc> = {
			status: 400
		}

		try {
			await firebase.firestore().collection(collection).doc(id).update({ _id: id, ...doc })
			serviceResult.status = 200
		}
		catch (error) {
			// console.error('Erro ao realizar update:', error)
			throw new Error(`Erro ao atualizar documento :: ${error}`)
		}

		return serviceResult
	}
)
