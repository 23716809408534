import { useGlobalContext } from 'App/Global'
import { IModal, ModalDs } from 'Components/ModalDS'
import { ReactNode } from 'react'

export type TGlobalModal = {
	show: boolean
	width?: number
	child?: ReactNode
} & IModal

type GCProps = {
	globalModal: TGlobalModal
}

export const GlobalModal = () => {
	const { globalModal } = useGlobalContext()
	const {
		child
	} = globalModal || {}

	return <ModalDs
		{...globalModal}>
		{child || null}
	</ModalDs>
}
