import { clone } from './clone'

/**
* Faz interseção entre 2 arrays
* @param {Array} array1 {Array} Primeiro array
* @param {Array} array2 {Array} Segundo array
* @returns {Array} {Array} A interseção entre os 2 arrays
* @example intersectionArray([1,2,3,4,5],[2,4,6,8]) => [2,4]
*/



export const intersectionArray = (array1: any[], array2: any[]) => {

	const cloneArray1 = clone(array1)
	const cloneArray2 = clone(array2)

	return cloneArray1.filter((value: any) => cloneArray2.includes(value))

}
