import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
/**
 * Hook utilizado para corrigir erro que acontecia ao navegar em diferentes páginas
 * após dar um scroll.
 * Contexto: ao navegar em uma página e rolar para baixo para ver o conteúdo, ao sair da página para
 * outra página, a página nova iniciava exatamente na posição do scroll da página anterior
 * Solução: com o hook, sempre que o react router localizar uma mudança de página,
 * vamos dar um scroll na página para o topo
 * @returns null
 */
export default function useScrollToTop() {
	const { pathname } = useLocation()

	useEffect(() => {
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'auto'
		})
	}, [pathname])

	return null
}
