import * as Charts from 'Components/ChartsDS/src/stacked-bar'
import * as Tokens from 'Components/CardDS/src/Tokens'
import { useContextPage } from 'Screens/AddOriginacao/useContext'
import { COMPANY_RATING, COMPANY_RATING_TYPE } from 'Constants/COMPANY_RATING'
import { TEASER_FIELDS } from 'Constants/TEASER_FIELDS'
import { escreveMilhoes } from 'Functions/Utils/milhoes'
import { coinToNumber } from 'Functions/Utils/moeda'
import { ChangeEvent, useEffect, useState } from 'react'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { useContextAuth } from 'App/ContextAuth'
import { fnUser } from 'Functions/User'

type TNames = {
	[name: string]: string
}

const ZERO_VALUE = '0.00'

export const rbNames: TNames[] = generateNames('rb')// Receita bruta
export const ebNames: TNames[] = generateNames('eb')// Ebitda

function generateNames(prefix: string) {
	const currentYear: number = new Date().getFullYear()
	const arrayNames: TNames[] = []
	for (let index = 0; index <= 3; index++) {
		const label: string = index === 0 ? `${currentYear} (valor esperado)` : `${currentYear - index}`
		const name = `${prefix}${index}`
		const item = { name, label }
		arrayNames.push(item)
	}
	return arrayNames
}

export const useController = () => {
	const { teaser, setTeaser, inputHandler, errors, curatingAs, disabled } = useContextPage()
	const [hintFbStatus, setHintFbStatus] = useState<boolean>(false)
	const [hintEbStatus, setHintEbStatus] = useState<boolean>(false)
	const [graphicFB, setGRaphicFB] = useState<Charts.IStackedBar | null>()
	const [graphicEB, setGRaphicEB] = useState<Charts.IStackedBar | null>()
	const { globalAuth, } = useContextAuth()

	const profile = globalAuth.profile


	useEffect(() => {
		function getFgGraphic(args: { field: string, names: TNames[], barColor: string }) {
			const { field, names, barColor } = args
			const item0 = `${field}0` as keyof TypeTeaser
			const item1 = `${field}1` as keyof TypeTeaser
			const item2 = `${field}2` as keyof TypeTeaser
			const item3 = `${field}3` as keyof TypeTeaser

			if ((!teaser[item0] || teaser[item0] === ZERO_VALUE) &&
				(!teaser[item1] || teaser[item1] === ZERO_VALUE) &&
				(!teaser[item2] || teaser[item2] === ZERO_VALUE) &&
				(!teaser[item3] || teaser[item3] === ZERO_VALUE)) {
				return null
			}

			return {
				options: {
					showValue: true,
					labelMask: (value: number) => String(`${parseFloat(value.toFixed(2))}M`),
					keys: {
						[item0]: { barColor },
						[item1]: { barColor },
						[item2]: { barColor },
						[item3]: { barColor },
					},
					barSpace: 10,
					space: 20,
					barWidth: 50,
				},
				data: [
					{
						label: names[0].label.replace('(valor esperado)', ''),
						value: escreveMilhoes(Number(teaser[item0]), 2),
						key: names[0].name
					},
					{
						label: names[1].label,
						value: escreveMilhoes(Number(teaser[item1]), 2),
						key: names[1].name
					},
					{
						label: names[2].label,
						value: escreveMilhoes(Number(teaser[item2]), 2),
						key: names[2].name
					},
					{
						label: names[3].label,
						value: escreveMilhoes(Number(teaser[item3]), 2),
						key: names[3].name
					}
				]
			}
		}
		setGRaphicFB(getFgGraphic({ field: 'rb', names: rbNames, barColor: '#0B31E0' }))
		setGRaphicEB(getFgGraphic({ field: 'eb', names: ebNames, barColor: Tokens.COLOR_DANGER_300 }))
		//------

	}, [teaser])

	function companyRatingHandler(e: ChangeEvent<HTMLInputElement>) {
		const value = e.target.value as COMPANY_RATING_TYPE
		setTeaser({ [TEASER_FIELDS.companyRating]: value } as TypeTeaser)
	}

	function knowMoreFBHandler(status: boolean) {
		setHintFbStatus(status)
	}

	function closeFBHandler() {

	}

	function knowMoreEBHandler(status: boolean) {
		setHintEbStatus(status)
	}

	function closeEBHandler() {

	}

	function indebtednessHandler(value: boolean) {
		setTeaser({ [TEASER_FIELDS.indebtedness]: value } as TypeTeaser)
	}

	function convertToMoney(e: ChangeEvent<HTMLInputElement>) {
		inputHandler(e)
	}

	return {
		state: {
			teaser,
			rbNames,
			ebNames,
			hintFbStatus,
			hintEbStatus,
			graphicFB,
			graphicEB,
			errors,
			curatingAs,
			disabled
		},
		actions: {
			inputHandler,
			companyRatingHandler,
			knowMoreFBHandler,
			closeFBHandler,
			knowMoreEBHandler,
			closeEBHandler,
			indebtednessHandler, convertToMoney
		}
	}
}
