import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const GraduationHatFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M16.004 5.64258L2.55957 12.9759L16.004 20.3092L27.004 14.3081V22.7537H29.4485V12.9759L16.004 5.64258ZM7.44846 18.0848V22.9737L16.004 27.6426L24.5596 22.9737V18.0848L16.004 22.7537L7.44846 18.0848Z' fill='#272827' />
	</Svg>
)


