import firebase from 'firebase/app'
import { fnUser } from 'Functions/User'
import { generateUniqueId } from 'Functions/Utils/generateUniqueId'
import { TypeUser } from 'Functions/User/types/TypeUser'

export type TSnap = {
	createdAt: Date
	profile: TypeUser
	snapCode: string //"s-01-003"
	userId: string
	userType: TypeUser['tipo']
	typeOperation: string[]
	nps: number | null
	melhoria: string
}

const _SNAPS_CODES = {
	// s-01 São referentes ao Agent
	's-00-000': { // ok
		_id: 's-00-000',
		userType: 'não identificado',
		description: 'Não identificado'
	},

	/** Usuário acessou o sistema */
	's-00-001': { // ok
		_id: 's-00-001',
		userType: '',
		description: 'Usuário acessou o sistema'
	},
	/** Usuário viu nps */
	's-00-002': { // ok
		_id: 's-00-002',
		userType: '',
		description: 'Usuário viu nps'
	},
	/** Usuário respondeu NPS */
	's-00-003': { // ok
		_id: 's-00-003',
		userType: '',
		description: 'Usuário respondeu NPS'
	},


	's-01-001': { // ok
		_id: 's-01-001',
		userType: 'agent',
		description: 'quando um agente visualizar notificações de uma originação'
	},
	's-01-002': { // ok
		_id: 's-01-002',
		userType: 'agent',
		description: 'quando um agente acessa o add originação'
	},
	's-01-003': { // ok
		_id: 's-01-003',
		userType: 'agent',
		description: 'quando um agente acessa uma tese'
	},
	's-01-004': { // ok
		_id: 's-01-004',
		userType: 'agent',
		description: 'quando um agente abre página de links do kit'
	},
	's-01-005': { // ok
		_id: 's-01-005',
		userType: 'agent',
		description: 'quando um agente clica em uma aba do add originação'
	},
	's-01-006': { // ok
		_id: 's-01-006',
		userType: 'agent',
		description: 'quando um agente acessa o feed de uma originação'
	},
	's-01-007': { // ok
		_id: 's-01-007',
		userType: 'agent',
		description: 'quando um agente clica em um item do kit'
	},
	's-01-008': { // ok
		_id: 's-01-008',
		userType: 'agent',
		description: 'quando um agente clica em um setor de teses'
	},
	's-01-009': { // ok
		_id: 's-01-009',
		userType: 'agent',
		description: 'quando um agente clica em copiar link de add originação'
	},

	// s-02 São referentes ao Cliente
	's-02-001': {
		_id: 's-02-001',
		userType: 'customer',
		description: 'quando um cliente visualiza o feed'
	},
	's-02-002': { // ok
		_id: 's-02-002',
		userType: 'customer',
		description: 'quando um cliente abre uma combinação'
	},
	's-02-003': { // ok
		_id: 's-02-003',
		userType: 'customer',
		description: 'quando um cliente abre página de entregáveis'
	},
	's-02-004': { // ok
		_id: 's-02-004',
		userType: 'customer',
		description: 'quando um cliente aceita um match'
	},
	's-02-005': { // ok
		_id: 's-02-005',
		userType: 'customer',
		description: 'quando um cliente rejeita um match'
	},
	's-02-006': { // ok
		_id: 's-02-006',
		userType: 'customer',
		description: 'quando um cliente abre um ver mais do long list'
	},
	's-02-007': { // ok
		_id: 's-02-007',
		userType: 'customer',
		description: 'quando um cliente aceita todos os matches'
	},
}

export type SNAPS_CODES_TYPES_KEYS = keyof typeof _SNAPS_CODES

export const SNAPS_CODES_GET = (code: string) => {
	const _code = code as SNAPS_CODES_TYPES_KEYS

	if (!_SNAPS_CODES[_code]) {
		return (_SNAPS_CODES['s-00-000'])
	}

	return _SNAPS_CODES[_code]
}
export const SNAPS_CODES_ARRAY = Object.values(_SNAPS_CODES)

const snapsSet = (props: {
	profile: TypeUser,
	snapCode: SNAPS_CODES_TYPES_KEYS,
	typeOperation?: string[],
	metaData?: string[],
	nps?: number,
	melhoria?: string
}) => {
	const snapId = generateUniqueId('snap')
	firebase.database().ref(`snaps/${snapId}`).set({
		userId: props.profile?._id || '',
		profile: props.profile || '',
		userType: props.profile?.tipo || '',
		snapCode: props.snapCode,
		typeOperation: props.typeOperation || [],
		metaData: props.metaData || [],
		createdAt: firebase.database.ServerValue.TIMESTAMP,
		nps: props?.nps || null,
		melhoria: props?.melhoria || '',
		descrição: SNAPS_CODES_GET(props.snapCode)?.description
	})
}

// export const snaps = {
// 	agent: {
// 		notification: ({ profile }: { profile: TypeUser }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-001' }),
// 		addOrigination: ({ profile }: { profile: TypeUser }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-002' }),
// 		tese: ({ profile }: { profile: TypeUser }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-003' }),
// 		kit: ({ profile }: { profile: TypeUser }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-004' }),
// 		clickTabOrigination: ({ profile }: { profile: TypeUser }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-005' }),
// 		feedOrigination: ({ profile }: { profile: TypeUser }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-006' }),
// 		feedClickItemKit: ({ profile, metaData }: { profile: TypeUser, metaData: string[] }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-007', metaData: metaData }),
// 		sectorTeses: ({ profile, metaData }: { profile: TypeUser, metaData: string[] }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-008', metaData: metaData }),
// 		copyLinkAddOrigination: ({ profile }: { profile: TypeUser }) => fnUser.isAgent(profile) && snapsSet({ profile, snapCode: 's-01-008' }),
// 	},
// 	customer: {
// 		feed: ({ profile }: { profile: TypeUser }) => fnUser.isCustomer(profile) && snapsSet({ profile, snapCode: 's-02-001' }),
// 		combination: ({ profile }: { profile: TypeUser }) => fnUser.isCustomer(profile) && snapsSet({ profile, snapCode: 's-02-002' }),
// 		deliverables: ({ profile }: { profile: TypeUser }) => fnUser.isCustomer(profile) && snapsSet({ profile, snapCode: 's-02-003' }),
// 		acceptMatch: ({ profile, typeOperation }: { profile: TypeUser, typeOperation: string[] }) => fnUser.isCustomer(profile) && snapsSet({ profile, snapCode: 's-02-004', typeOperation: typeOperation }),
// 		rejectMatch: ({ profile, typeOperation }: { profile: TypeUser, typeOperation: string[] }) => fnUser.isCustomer(profile) && snapsSet({ profile, snapCode: 's-02-005', typeOperation: typeOperation }),
// 		seeMoreLongList: ({ profile, typeOperation }: { profile: TypeUser, typeOperation: string[] }) => fnUser.isCustomer(profile) && snapsSet({ profile, snapCode: 's-02-006', typeOperation: typeOperation }),
// 		acceptAllMatch: ({ profile, typeOperation }: { profile: TypeUser, typeOperation: string[] }) => fnUser.isCustomer(profile) && snapsSet({ profile, snapCode: 's-02-007', typeOperation: typeOperation }),
// 	}
// }

export const snaps2 = {
	seeNotification: (profile: TypeUser) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-001' })
	},
	addOrigination: (profile: TypeUser) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-002' })
	},
	seeTese: (profile: TypeUser) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-003' })
	},
	openKitScreen: (profile: TypeUser) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-004' })
	},
	clickTabOrigination: (profile: TypeUser) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-005' })
	},
	seeFeedOrigination: (profile: TypeUser) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-006' })
	},
	clickItemKit: (profile: TypeUser, metaData: string[]) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-007', metaData })
	},
	clickSectorTese: (profile: TypeUser, metaData: string[]) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-008', metaData })
	},
	copyLinkAddOrigination: (profile: TypeUser) => {
		if (fnUser.isAgent(profile)) snapsSet({ profile, snapCode: 's-01-009' })
	},
	seeFeed: (profile: TypeUser) => {
		if (fnUser.isCustomer(profile)) snapsSet({ profile, snapCode: 's-02-001' })
	},
	openCombination: (profile: TypeUser) => {
		if (fnUser.isCustomer(profile)) snapsSet({ profile, snapCode: 's-02-002' })
	},
	openDeliverables: (profile: TypeUser) => {
		if (fnUser.isCustomer(profile)) snapsSet({ profile, snapCode: 's-02-003' })
	},
	acceptMatch: (profile: TypeUser, typeOperation: string[]) => {
		if (fnUser.isCustomer(profile)) snapsSet({ profile, snapCode: 's-02-004', typeOperation })
	},
	rejectMatch: (profile: TypeUser, typeOperation: string[]) => {
		if (fnUser.isCustomer(profile)) snapsSet({ profile, snapCode: 's-02-005', typeOperation })
	},
	seeMoreLongList: (profile: TypeUser, typeOperation: string[]) => {
		if (fnUser.isCustomer(profile)) snapsSet({ profile, snapCode: 's-02-006', typeOperation })
	},
	acceptAllMatch: (profile: TypeUser, typeOperation: string[]) => {
		if (fnUser.isCustomer(profile)) snapsSet({ profile, snapCode: 's-02-007', typeOperation })
	},

	access: (profile: TypeUser) => {
		snapsSet({
			profile,
			snapCode: 's-00-001',
		})
	},
	npsView: (profile: TypeUser) => {
		snapsSet({
			profile,
			snapCode: 's-00-002'
		})
	},
	npsAnswer: (profile: TypeUser, nps: number, melhoria: string) => {
		snapsSet({
			profile,
			snapCode: 's-00-003',
			nps,
			melhoria
		})
	},
}
