import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'
import { fnTransaction } from '../Transaction'

export const getEstimatedValuation = ({ valuation }: { valuation: TypeValuation }) => {
	const mediansOfClosedCapital: TypeValuation['mediansOfClosedCapital'] = {} as TypeValuation['mediansOfClosedCapital']
	const mediansOfOpenCapital: TypeValuation['mediansOfOpenCapital'] = {} as TypeValuation['mediansOfOpenCapital']
	const mediansTotal: TypeValuation['mediansTotal'] = {} as TypeValuation['mediansTotal']

	const multipleEbitdaMedianOfOpen = fnTransaction.getMedian({ valuation, property: 'ebitdaMultiple', type: 'public' })
	const multipleEbitdaMedianOfClosed = fnTransaction.getMedian({ valuation, property: 'ebitdaMultiple', type: 'private' })
	const multipleEbitdaMedianOfTotal = fnTransaction.getMedian({ valuation, property: 'ebitdaMultiple' })

	const multipleBillingMedianOfOpen = fnTransaction.getMedian({ valuation, property: 'billingMultiple', type: 'public' })
	const multipleBillingMedianOfClosed = fnTransaction.getMedian({ valuation, property: 'billingMultiple', type: 'private' })
	const multipleBillingMedianOfTotal = fnTransaction.getMedian({ valuation, property: 'billingMultiple' })

	// mediana do multiplo por ebitda de todas as empresas de capital aberto
	// multplicar o resultado por ebitda da empresa avaliada
	return {
		valuationOfEbitda: {
			open: multipleEbitdaMedianOfOpen * (valuation.ebitda || 0),
			closed: multipleEbitdaMedianOfClosed * (valuation.ebitda || 0),
			total: multipleEbitdaMedianOfTotal * (valuation.ebitda || 0),
		},

		valuationOfBilling: {
			open: multipleBillingMedianOfOpen * (valuation.billing || 0),
			closed: multipleBillingMedianOfClosed * (valuation.billing || 0),
			total: multipleBillingMedianOfTotal * (valuation.billing || 0),
		}
	}
}



