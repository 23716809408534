import React from 'react'
import { isProductionDev, isTesting } from './EnvFlag'
export const EnvFlag = () => {

	type TagProps = {
		children: React.ReactNode
		color: string
		opacity?: string
	}

	const Tag = ({ children, color, opacity }: TagProps) => <div
		style={{
			position: 'fixed',
			zIndex: 999999,
			backgroundColor: color,
			width: '300px',
			padding: 11,
			textAlign: 'center',
			color: 'white',
			fontWeight: 'bold',
			right: 0,
			// marginRight: 'calc(200px)',
			opacity: opacity || '50%'
		}}
	>
		{children}
	</div>

	return (
		<React.Fragment>
			{
				isTesting() && <Tag color='orange'>Environment Testing</Tag>
			}
			{
				// isDevelopment() && <Tag color='green'>Environment Development</Tag>
			}
			{
				isProductionDev() && <Tag color='red' opacity='1'>Environment PRODUCTION</Tag>
			}
		</React.Fragment >
	)
}
