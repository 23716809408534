import { db5 } from '../../../Services/db5'
import { serviceSaveFile } from '../../../Services/serviceFiles'
import { TypeTeaser } from '../../Teaser/types/teaser'

export const saveLogo = ({ blob, teaser, callback }: { blob: Blob, teaser: TypeTeaser, callback: (url: string) => void }) => {
	const { _id } = teaser
	serviceSaveFile({
		pathFile: `teaser/logo/${_id}/logo`,
		file: blob,
		urlSaveIn: `teasers/${_id}`,
		keyUrl: 'urlLogo'
	}, async (url: string) => {
		await db5.teasers.update({ id: _id, doc: { urlLogo: url } })
		callback(url)
		// setTeaser({ urlLogo: url } as TypeTeaser)
		// saveHandler({ origination: { ...teaser, urlLogo: url } })
	})
}
