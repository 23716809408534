import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { UidsNotification } from '../../../Origination/methods/getUidsNotification'
import { fnUser } from '../../../User'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.a00011


export type Props = {
	profile: TypeUser,
	origination: TypeTeaser,
	originationId: string,
	uid: string
}


export const sendOferta =
	async ({
		profile,
		origination,
		originationId,
		uid
	}: Props) => {

		const cloneProfile = clone(profile)
		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneUid = clone(uid)

		return await fnNotification.send({
			uiPermissions: ITEM.permissions,
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination, type: UidsNotification.a01 }),
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile, uid: cloneUid }),
			user: fnUser.getUser({ user: cloneProfile || {} as TypeUser }),
			message: ITEM.msg,
			type: ITEM._id,
			analystEmail: fnOrigination.getAnalystEmail({ origination: cloneOrigination }),
			stage: SUB_STATUS_OF_ACTIVE_COSTUMER.s00300.code
		} as TypeNotification)
	}

