import { clone } from '../../Utils/clone'
import { SUB_STATUS_OF_MATCH } from '../../../Constants/SUB_STATUS_OF_MATCH'
import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { minhaData } from '../../Utils/minhaDataNew'
import { TypeMatch } from '../types/match'

export const modelMatch = (obj: any = {}): TypeMatch => {

	const cloneObj = clone(obj)

	return ({
		_id: cloneObj._id || '',
		status: cloneObj.status === 0 ? 0 : cloneObj.status || '',
		matchMakingStatus: cloneObj.matchMakingStatus || 0,
		matchStatus: cloneObj.matchStatus || SUB_STATUS_OF_MATCH[100]._id,

		uidTese: cloneObj.uidTese || '',
		uidTeaser: cloneObj.uidTeaser || '',
		uidCoCreatorTeaser: cloneObj.uidCoCreatorTeaser || '',

		idTese: cloneObj.idTese || '',
		idTeaser: cloneObj.idTeaser || '',
		idInstitution: cloneObj.idInstitution || '',
		institutionHub: cloneObj.institutionHub || '',

		dataContrato: cloneObj.dataContrato || '',
		dataCuradoria: cloneObj.dataCuradoria || '',

		matchFirst: cloneObj.matchFirst || false,
		matchSecond: cloneObj.matchSecond || false,

		ndaLink: cloneObj.ndaLink || '',
		lastCheckPoint: cloneObj.lastCheckPoint || '',

		byCustomer: cloneObj.byCustomer ?? false,

		dataCriacao: cloneObj.dataCriacao || minhaData(),

		// ANTIGO ERA ASSIM ----- NÃO USAR
		tese: cloneObj.tese || '',
		teaser: cloneObj.teaser || '',
		codinomeTese: cloneObj.codinomeTese || '',
		codinomeTeaser: cloneObj.codinomeTeaser || '',
		etr: cloneObj.etr || '',
		setor: cloneObj.setor || '',
		// ANTIGO ERA ASSIM ---- NÃO USAR

		datePrematch: cloneObj.datePrematch || '',
		dateMatchmaking: cloneObj.dateMatchmaking || '',
		dateDeclined: cloneObj.dateDeclined || '',
		dateMatch: cloneObj.dateMatch || '',
		dateInactive: cloneObj.dateInactive || '',
		dateReject: cloneObj.dateReject || '',

		datePreparation: cloneObj.datePreparation || 0,
		dateOffer: cloneObj.dateOffer || 0,
		dateDiligence: cloneObj.dateDiligence || 0,
		dateContract: cloneObj.dateContract || 0,
		datePreparationDeclined: cloneObj.datePreparation || 0,
		dateOfferDeclined: cloneObj.dateOffer || 0,
		dateDiligenceDeclined: cloneObj.dateDiligence || 0,
		dateContractDeclined: cloneObj.dateContract || 0,
		dateCompleted: cloneObj.dateCompleted || 0,

		idsTeses: cloneObj.idsTeses || [],
		idsTesesFall: cloneObj.idsTesesFall || [],
		fromOffice: cloneObj.fromOffice || '',
		typeOperation: cloneObj.typeOperation === 'M&A' ? ['M&A'] : (cloneObj.typeOperation ?? [TYPE_OPERATION.op1]),
		documents: cloneObj.documents ?? [],
	})

}
