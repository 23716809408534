import { isLiquid, isNotLiquid } from '../../Liquid'
import { TypeScoreProps } from '../types/TypeScoreProps'

export const GEN_STARK_FEE = (props: TypeScoreProps) => {

	if (props.operation === 'greenfield') return [1_000_000, 4_000_000]
	if (props.operation === 'corporateFinance') return [800_000]
	if (props.operation === 'projectFinance') return [800_000]

	if (props.operation === 'total') return [(isLiquid(props.teaser.tags) ? 800_000 : isNotLiquid(props.teaser.tags) ? 1_200_000 : 1_000_000)]
	if (props.operation === 'minoritário') return [(isLiquid(props.teaser.tags) ? 800_000 : isNotLiquid(props.teaser.tags) ? 1_200_000 : 1_000_000)]
	if (props.operation === 'majoritário') return [(isLiquid(props.teaser.tags) ? 800_000 : isNotLiquid(props.teaser.tags) ? 1_200_000 : 1_000_000)]

	return []
}
