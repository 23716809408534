import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Close = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M7.70569 26C7.30435 26 6.90301 25.8655 6.50167 25.4622C5.83278 24.7899 5.83278 23.7143 6.50167 23.042L23.0903 6.5042C23.7592 5.83193 24.8294 5.83193 25.4983 6.5042C26.1672 7.17647 26.1672 8.2521 25.4983 8.92437L8.9097 25.4622C8.64214 25.8655 8.2408 26 7.70569 26Z' fill='#272827' />
		<path d='M24.1605 26C23.7592 26 23.3579 25.8664 22.9565 25.4658L6.50167 8.90484C5.83278 8.23706 5.83278 7.16861 6.50167 6.50083C7.17057 5.83306 8.2408 5.83306 8.9097 6.50083L25.4983 23.0618C26.1672 23.7295 26.1672 24.798 25.4983 25.4658C25.097 25.8664 24.5619 26 24.1605 26Z' fill='#272827' />
	</Svg>
)
