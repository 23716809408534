import { TypeUser } from '../types/TypeUser'
/**
 * Ordena um array de usuários pelo nome
 * @param teasers
 * @param order
 * @returns  TypeUser[]
 */
export const sortByName = ({ users, order }: { users: TypeUser[], order: 'asc' | 'desc' }): TypeUser[] =>
	users.sort((userA: TypeUser, userB: TypeUser) => {
		const isAsc = order === 'asc'
		const nameA = userA?.nome || ''
		const nameB = userB?.nome || ''
		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})
