
export const PERMISSIONS_NOTIFICATIONS = {
	/**
	 * Permissão para aparecer em todos os lugares
	 */
	p0001: {
		_id: 'p0001',
		label: 'Permissão para aparecer em todos os lugares'
	},
	/**
	 * Permissão para aparecer no card
	 */
	p0002: {
		_id: 'p0002',
		label: 'Permissão para aparecer no card do customer'
	},
	/**
	 * Permissão para aparecer no relatório
	 */
	p0003: {
		_id: 'p0003',
		label: 'Permissão para aparecer no relatório'
	},
	/**
	 * Permissão para aparecer na página de histórico
	 */
	p0004: {
		_id: 'p0004',
		label: 'Permissão para aparecer na página de histórico'
	},
	/**
	 * Permissão para aparecer no baby card
	 */
	p0005: {
		_id: 'p0005',
		label: 'Permissão para aparecer no baby card do Agente'
	},
	/**
	 * Permissão para aparecer no widget
	 */
	p0006: {
		_id: 'p0006',
		label: 'Permissão para aparecer no widget'
	}
} as const

export const PERMISSIONS_NOTIFICATIONS_GET = (code: string) => {
	const _code = code as keyof typeof PERMISSIONS_NOTIFICATIONS

	if (!PERMISSIONS_NOTIFICATIONS[_code]) {
		return (PERMISSIONS_NOTIFICATIONS.p0001)
	}

	return PERMISSIONS_NOTIFICATIONS[_code]
}
export const PERMISSIONS_NOTIFICATIONS_VALUES = Object.values(PERMISSIONS_NOTIFICATIONS)
export const PERMISSIONS_NOTIFICATIONS_KEYS = Object.keys(PERMISSIONS_NOTIFICATIONS)

export type TypePermissionsNotifications = keyof typeof PERMISSIONS_NOTIFICATIONS

export const PERMISSIONS_NOTIFICATIONS_GROUP = {
	// widget
	p0001: [PERMISSIONS_NOTIFICATIONS.p0006._id],
	//baby card
	p0002: [PERMISSIONS_NOTIFICATIONS.p0005._id],
	//histórico, card, relatório, widget
	p0003: [
		PERMISSIONS_NOTIFICATIONS.p0004._id,
		PERMISSIONS_NOTIFICATIONS.p0002._id,
		PERMISSIONS_NOTIFICATIONS.p0003._id,
		PERMISSIONS_NOTIFICATIONS.p0006._id,
	],
	//card, relatório, widget
	p0004: [
		PERMISSIONS_NOTIFICATIONS.p0002._id,
		PERMISSIONS_NOTIFICATIONS.p0003._id,
		PERMISSIONS_NOTIFICATIONS.p0006._id,
	],
	//histórico, card, relatório, baby card
	p0005: [
		PERMISSIONS_NOTIFICATIONS.p0004._id,
		PERMISSIONS_NOTIFICATIONS.p0002._id,
		PERMISSIONS_NOTIFICATIONS.p0003._id,
		PERMISSIONS_NOTIFICATIONS.p0005._id
	],
	//histórico, relatório
	p0006: [
		PERMISSIONS_NOTIFICATIONS.p0004._id,
		PERMISSIONS_NOTIFICATIONS.p0003._id
	],
	//histórico, card, relatório
	p0007: [
		PERMISSIONS_NOTIFICATIONS.p0004._id,
		PERMISSIONS_NOTIFICATIONS.p0002._id,
		PERMISSIONS_NOTIFICATIONS.p0003._id
	],
	//histórico, card, relatório, baby card e widget
	p0008: [
		PERMISSIONS_NOTIFICATIONS.p0002._id,
		PERMISSIONS_NOTIFICATIONS.p0003._id,
		PERMISSIONS_NOTIFICATIONS.p0004._id,
		PERMISSIONS_NOTIFICATIONS.p0005._id,
		PERMISSIONS_NOTIFICATIONS.p0006._id,
	]
}
