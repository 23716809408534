import { clone } from '../../../Functions/Utils/clone'
import { minhaData, myDateToDate } from '../../../Functions/Utils/minhaData'

/**
 * Adiciona um número específico de anos a uma data fornecida.
 * @param myDate O valor numérico representando a data no formato de numero.
 * @param year O número de anos a serem adicionados à data.
 * @returns Um number representando a nova data no formato de número ex: 20210601010101
 * @example
 *
 * / Adiciona 5 anos à data 20210601010101 (01/06/2021)
 * const novaData = _addYearDate(20210601010101, 5);
 * console.log(novaData); // Saída esperada: '01/06/2026'
 */

export const _addYearDate = (myDate: number, year: number) => {

	const cloneMyDate = clone(myDate) // clono a data => 20210601010101
	const cloneYear = clone(year) // clono o número de anos => 5

	const date = myDateToDate(cloneMyDate) // tenho uma data do tipo Date => 01/06/2021
	new Date(date.setFullYear(date.getFullYear() + cloneYear)) // adiciono o número de anos à data => 01/06/2026
	return minhaData(date)
}
