import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeGetHighestStatusFromMatches = {
	id: string,
}

type GetHighestStatusFromMatchesResponse = {
	status: number,
	doc: any
}

export const _getHighestStatusFromMatches = (input: TypeGetHighestStatusFromMatches): Promise<{ data: GetHighestStatusFromMatchesResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'getHighestStatusFromMatches', input })

