import { notifications } from '../../../Services/cloudfunctions'
import { fnUser } from '../../User'
import { minhaData } from '../../Utils/minhaData'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { createCodinome } from './createCodinome'
import { db5 } from '../../../Services/db5'
import { fnError } from '../../Errors/fnError'

type Props = {
	origination: TypeTeaser,
	user: TypeUser,
	uid: string,
	callBack: (id: string) => void
}

export const create = (args: Props) => {
	const { origination, user, uid, callBack: cb } = args

	origination.codinome = createCodinome(origination.nome)
	origination.uid = uid

	if (fnUser.isBda(user)) {
		origination.fromOffice = user.fromOffice
		origination.origin = 'BDA'
	}

	if (fnUser.isMicroBda(user)) {
		origination.uid = user.uidBdaFather || ''
		origination.uidCoCreator = uid
	}

	if (fnUser.isOffice(user)) {
		origination.fromOffice = uid
	}

	if (fnUser.isAdm(user)) {
		origination.uid = fnUser.getUidUserAdm()
	}

	db5.teasers.add({
		doc: {
			...origination,
			ativado: true,
			dataCriacao: minhaData(),
			dataModificacao: minhaData(),
		}
	})
		.then(async res => {
			if (res.id) {
				await notifications({
					targetId: res.id,
					typeOrMessage: 'a0001',
					profileUid: uid
				})
				cb(res.id)
				return
			}
			fnError.alert({ message: 'Ocorreu um erro tente novamente mais tarde' })
		})

}
