import { TypeScoreProps } from '../types/TypeScoreProps'
import { isInRange } from '../../../../../Functions/Utils/isInRange'
import { TypeScoreStark } from '../types/TypeScoreStark'
import { genTotalBudgetFin2 } from '../genCount/genTotalBudgetFin2'

export const genScoreCapTable = (props: TypeScoreProps) => {

	const config = {
		setor: 'Nota de captable',
		score: 2,
	} as const

	const capitalOfThePartners =
		props.teaser.financing?.fin2?.reduce(
			(accumulator, value) =>
				accumulator + (value.valueByPartnes ? +value.valueByPartnes : 0),
			0
		) ?? 0
	const thirdPartyCapital =
		props.teaser.financing?.fin2?.reduce(
			(accumulator, value) =>
				accumulator + (value.valueByThird ? +value.valueByThird : 0),
			0
		) ?? 0

	const res: TypeScoreStark['scoreItens'] = []

	const budgetRate = (capitalOfThePartners + thirdPartyCapital) / genTotalBudgetFin2(props)

	if (isInRange(0.4, '<=', budgetRate))
		res.push({ // adiciona 2
			'pontosMax': 2,
			'pontos': 2,
			'pontosCor': 'green',
			'setor': config.setor,
			'item': config.setor,
			'itemDescrição': 'Taxa de capital maior que 0.4',
			'metaDados': '',
			'devolutiva': '',
		})

	if (isInRange(0.3, '<=', budgetRate, '<', 0.4))
		res.push({ // adiciona 1.5
			'pontosMax': 2,
			'pontos': 1.5,
			'pontosCor': 'green',
			'setor': config.setor,
			'item': config.setor,
			'itemDescrição': 'Taxa de capital maior ou igual do que 0.3 e menor do que 0.4',
			'metaDados': '',
			'devolutiva': '',
		})

	if (isInRange(0.2, '<=', budgetRate, '<', 0.3))
		res.push({ // adiciona 1
			'pontosMax': 2,
			'pontos': 1,
			'pontosCor': 'green',
			'setor': config.setor,
			'item': config.setor,
			'itemDescrição': 'Taxa de capital maior ou igual do que 0.2 e menor do que 0.3',
			'metaDados': '',
			'devolutiva': '',
		})

	if (isInRange(0.1, '<=', budgetRate, '<', 0.2))
		res.push({ // adiciona 0.5
			'pontosMax': 2,
			'pontos': 0.5,
			'pontosCor': 'green',
			'setor': config.setor,
			'item': config.setor,
			'itemDescrição': 'Taxa de capital maior ou igual do que 0.1 e menor do que 0.2',
			'metaDados': '',
			'devolutiva': '',
		})

	if (isInRange(budgetRate, '<', 0.1))
		res.push({ // adiciona 0
			'pontosMax': 2,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': config.setor,
			'item': config.setor,
			'itemDescrição': 'Taxa de capital menor que 0.1',
			'metaDados': '',
			'devolutiva': '',
		})

	return res
}
