import React from 'react'

export const NotificationBell = ({ className, notifications, children, small }: { className?: string, notifications: number, children: React.ReactNode, small?: boolean }) =>
	<div className='tw-relative tw-w-fit'>
		{children}
		{notifications > 0 &&
			<div
				className={
					`
					tw-absolute 
						${small ? 'tw-text-xs tw-pb-[1px] tw-px-1' : 'tw-my-[1px] tw-px-1.5'}
			 		tw-z-10 tw-rounded-full tw-top-1 tw--translate-y-1/2 tw-left-full tw--translate-x-1/2 tw-bg-red-500 tw-text-white tw-font-bold ${className && className}
					`
				}
			>
				{notifications}
			</div>
		}
	</div>

