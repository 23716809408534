import { clone } from '../../Utils/clone'
import { TypeFeaturedMaterial } from '../types/featureMaterial'

export const modelFeaturedMaterial = (obj: Partial<TypeFeaturedMaterial> = {}): TypeFeaturedMaterial => {

	const cloneObj = clone(obj)

	return ({
		_id: cloneObj._id || '',
		name: cloneObj.name || '',
		url: cloneObj.url || '',
		createdAt: cloneObj?.createdAt || 0,
		updatedAt: cloneObj?.updatedAt || 0,
	})

}
