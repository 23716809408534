import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { fnUser } from '../../../User'
import { TypeInstitution } from '../../../Institution/types/institution'
import { TypeMatch } from '../../../Match/types/match'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeTese } from '../../../Tese/types/tese'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.b0008

type Props = {
	origination: TypeTeaser
	originationId: string
	profile: TypeUser
	match: TypeMatch
	matchId: string
	tese: TypeTese
	teseId: string
	institution: TypeInstitution
	institutionId: string
	uid: string
}

export const sendMatchEnviadoDeclined =
	async ({ origination, originationId, profile, match, matchId, tese, teseId, institution, institutionId, uid }: Props) => {

		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)
		const cloneProfile = clone(profile)
		const cloneMatch = clone(match)
		const cloneMatchId = clone(matchId)
		const cloneTese = clone(tese)
		const cloneTeseId = clone(teseId)
		const cloneInstitution = clone(institution)
		const cloneInstitutionId = clone(institutionId)
		const cloneUid = clone(uid)

		return await fnNotification.send({
			uiPermissions: ITEM.permissions,
			tese: fnNotification.formatData.getTese({ tese: cloneTese, id: cloneTeseId }),
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination }),
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile, uid: cloneUid }),
			user: fnUser.getUser({ user: cloneProfile || {} as TypeUser }),
			message: ITEM.msg,
			type: ITEM._id,
			match: fnNotification.formatData.getMatch({ match: cloneMatch, id: cloneMatchId }),
			institution: fnNotification.formatData.getInstitution({ institution: cloneInstitution, id: cloneInstitutionId }),
			showCustomer: true,
			analystEmail: fnOrigination.getAnalystEmail({ origination: cloneOrigination }),
			// stage: SUB_STATUS_OF_ACTIVE_COSTUMER.s00100.code,
			teses: fnNotification.formatData.getTeses({ match: cloneMatch })
		} as TypeNotification)
	}

