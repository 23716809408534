// import { TAGS_SECTOR_ROOT, TAGS_SUB } from '../../../Constants/TAGS'
import { TAGS_SECTOR_ROOT, TAGS_SUB } from '../../../../../Constants/TAGS'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreMarketSize = (props: TypeScoreProps) => {
	const res: TypeScoreStark['scoreItens'] = []

	const tagsCombinations = Object.values(props.combinations)
		.map(combination => Object.values(combination.combinations)
			.map(teseDetails => [...teseDetails.tagsMatch, ...teseDetails.tagsTechMatch])
		).flat()

	const tagsCombinationsTagsRoot = tagsCombinations.map(tags => tags.filter(tag => TAGS_SECTOR_ROOT[tag])).filter(tags => !!tags.length)
	const tagsCombinationsTagsSub = tagsCombinations.map(tags => tags.filter(tag => TAGS_SUB[tag])).filter(tags => !!tags.length)

	const devolutiva = 'No momento temos poucos investidores compatíveis com a empresa em nossa plataforma.'

	if (3 <= tagsCombinationsTagsSub.length)
		res.push({ // adiciona 3
			'pontosMax': 3,
			'pontos': 3,
			'pontosCor': 'green',
			'setor': 'Nota de tamanho de mercado',
			'item': 'Nota de tamanho de mercado',
			'itemDescrição': 'Possui 3 ou mais combinações de tags específicas',
			'metaDados': '',
			devolutiva: '',
		})

	else if (10 <= tagsCombinationsTagsRoot.length)
		res.push({ // adiciona 3
			'pontosMax': 3,
			'pontos': 3,
			'pontosCor': 'green',
			'setor': 'Nota de tamanho de mercado',
			'item': 'Nota de tamanho de mercado',
			'itemDescrição': 'Possui 10 ou mais combinações de tags setoriais',
			'metaDados': '',
			devolutiva: '',
		})

	else if (tagsCombinations.length > 10)
		res.push({ // adiciona 3
			'pontosMax': 3,
			'pontos': 3,
			'pontosCor': 'green',
			'setor': 'Nota de tamanho de mercado',
			'item': 'Nota de tamanho de mercado',
			'itemDescrição': 'Possui 10 ou mais combinações de qualquer tipo de tag',
			'metaDados': '',
			devolutiva: '',
		})

	else {
		res.push({ // adiciona +(tagsCombinations.length / 10 * 3).toFixed(1),
			'pontosMax': 3,
			'pontos': +(tagsCombinations.length / 10 * 3).toFixed(1),
			'pontosCor': 'green',
			'setor': 'Nota de tamanho de mercado',
			'item': 'Nota de tamanho de mercado',
			'itemDescrição': 'Possui 10 ou mais combinações de tags setoriais',
			'metaDados': '',
			devolutiva: devolutiva,
		})
	}


	return res
}



