import { TypeTeaser } from '../types/teaser'
import { getValidateDue } from './getValidateDue'
/**
 * Ordena um array de teasers pelo prazo/validade/exclusividade da empresa
 * @param teasers
 * @param order
 * @returns  TypeTeaser[]
 */
export const sortByPrazo = ({ teasers, order }: { teasers: TypeTeaser[], order: 'asc' | 'desc' }): TypeTeaser[] =>
	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
		const isAsc = order === 'asc'
		const resultA = getValidateDue(teaserA).result
		const resultB = getValidateDue(teaserB).result

		if (isAsc && (resultA < 0)) return 1
		if (isAsc && (resultB < 0)) return -1
		if (isAsc) return resultA < resultB ? -1 : 1

		if (resultA < 0) return -1
		if (resultB < 0) return 1
		return resultA > resultB ? -1 : 1
	})
