import { clone } from '../../../Functions/Utils/clone'

export const getPermission = (permission: string, permissions: string[]): boolean => {

	const clonePermission = clone(permission)
	const clonePermissions = clone(permissions)

	// garante que as notificações antigas também sejam vistas, pois elas não tinham as permissões
	if (!clonePermissions.length) return true

	return clonePermissions.includes(clonePermission)
}
