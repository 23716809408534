import { TypeTeaser } from '../../types/teaser'
import { inComite } from '../inComite'
import { inComercial } from '../inComercial'
import { inStandby } from '../inStandby'
import { isNotPublicado } from '../isNotPublicado'
import { clone } from '../../../Utils/clone'

/**
 * Retorna a data do teaser de acordo com seu status
 * @param teaser
 * @returns
 */

export const getStatusDate = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	if (inComercial(cloneTeaser)) return cloneTeaser.dataComercial
	if (inComite(cloneTeaser)) return cloneTeaser.dataPublicacao
	if (inStandby(cloneTeaser)) return cloneTeaser.dataStandby
	if (isNotPublicado(cloneTeaser)) return cloneTeaser.dataCriacao
	return cloneTeaser.subStatusOfActiveCustomerDate || cloneTeaser.dataContrato
}
