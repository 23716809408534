import { useContextAuth } from 'App/ContextAuth'
import { useContextMenu } from 'App/ContextMenu'
import { Stark } from 'Components/Layout/FooterMenu/stark'
import { menu } from 'Components/Layout/LayoutMenuConstants/menu'
import React, { useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { LayoutMenuButton } from '../LayoutMenuButton'
import { fireLogout } from 'Services/firebase'
import { UiModal } from 'ComponentsUi/UiModal'
import { InternaLoading } from 'Components/InternalLoading'
import { UiButton } from 'ComponentsUi/UiButton'

const ModelSair = ({ show, setShow }: { show: boolean, setShow: (e: boolean) => void }) => {
	const history = useHistory()
	const [loadingExiting, setLoadingExiting] = useState(false)
	const eventSair = () => {
		setLoadingExiting(true)
		fireLogout()
		history.push('/')
	}
	return (<>
		<UiModal onClose={() => setShow(false)} show={show} className='tw-p-4 tw-h-32' width='small'>
			{
				loadingExiting ? <div className='tw-relative tw-h-14'><InternaLoading /></div> :
					<>
						<div className='tw-text-xl'>Sair</div>
						<p>Você deseja sair do sistema agora ?</p>
						<div className='tw-flex tw-justify-between'>
							<UiButton variant='secondary' onClick={() => setShow(false)}>Voltar</UiButton>
							<UiButton onClick={eventSair}>Sair</UiButton>
						</div>
					</>
			}
		</UiModal></>)
}
export const LayoutMenuMobile = () => {

	const { globalAuth } = useContextAuth()
	const { menuActiveValue, setMenuActiveValue } = useContextMenu()
	const { profile } = globalAuth
	const [modalSairShow, setModalSairShow] = useState(false)

	const [showMenuMobile, setShowMenuMobile] = useState(false)

	const menuArray = menu(profile, menuActiveValue, setMenuActiveValue)

	const MenuMobileButton = (props: { className: string, to?: string, onClick?: () => void, children: React.ReactNode }) => {
		if (props.to !== undefined) return (
			<Link to={props.to}>
				<div className={props.className} >
					{props.children}
				</div>
			</Link>
		)
		return (
			<div className={props.className} onClick={props.onClick}>{props.children}</div>
		)
	}

	const onClickLogout = () => {
		setModalSairShow(true)
	}

	return (
		<>
			<ModelSair show={modalSairShow} setShow={setModalSairShow} />
			<div className=' tw-flex lg:tw-hidden tw-cursor-pointer' onClick={() => setShowMenuMobile(true)}>
				<i className='material-icons tw-text-3xl tw-text-neutral-500'>menu</i>
			</div>

			<div className={`${showMenuMobile ? 'tw-translate-x-0 ' : 'tw-translate-x-full'} tw-fixed tw-left-0 tw-top-0 tw-z-60 tw-w-full tw-bg-neutral-100 tw-h-screen tw-overflow-auto tw-pb-10 tw-transition-all tw-ease-in-out tw-delay-150 tw-duration-500`}>

				<div className='tw-h-[53px] tw-flex tw-flex-row tw-justify-between tw-items-center tw-border tw-border-t-0 tw-border-b-1 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-b-neutral-100'>
					<div className='tw-px-8 tw-py-4'>
						<Stark />
					</div>
					<div onClick={() => setShowMenuMobile(false)}>
						<i className='material-icons tw-px-7 tw-py-2 tw-text-3xl tw-cursor-pointer tw-text-neutral-500'>close</i>
					</div>
				</div >

				<div className='tw-flex tw-flex-col tw-gap-4 tw-px-6'>
					{
						menuArray?.map(menuItem => {

							if (menuItem.type === 'component') return (
								<div key={menuItem.key}>{menuItem.Component}</div>
							)

							if (menuItem.type === 'link') return (
								<MenuMobileButton className='tw-text-neutral-700 tw-text-[18px] sm:tw-text-xl tw-cursor-pointer tw-flex tw-items-center tw-gap-4' to={menuItem.to} onClick={menuItem.onClick} key={menuItem.key}>
									{menuItem.icon}
									{menuItem.title}
								</MenuMobileButton>
							)

							if (menuItem.type === 'linkGroup') return (
								<div key={menuItem.key}>
									<MenuMobileButton className='tw-text-[18px] tw-cursor-pointer tw-flex tw-items-center tw-gap-4' onClick={menuItem.onClick} >
										{menuItem.icon}
										{menuItem.title}
										<div className='tw-flex-1'></div>
										<i className='material-icons'> {menuActiveValue === menuItem.title ? 'expand_less' : 'expand_more'}</i>
									</MenuMobileButton>

									<div className={`tw-transition-all tw-flex tw-flex-col tw-gap-4 tw-duration-300 tw-ease-out tw-delay-[200ms] tw-mt-4 ${menuActiveValue === menuItem.title ? '' : 'tw-hidden'}`}>
										{
											menuItem?.subLinks?.map(subItem => (
												<MenuMobileButton className='tw-text-neutral-700 tw-text-base tw-cursor-pointer tw-flex tw-items-center tw-gap-4' to={subItem.to} onClick={subItem.onClick} key={subItem.key} >
													{subItem.icon}
													{subItem.title}
												</MenuMobileButton>
											)) || null
										}
									</div>
								</div>
							)

						})

					}
					<MenuMobileButton className='tw-text-neutral-700 tw-text-[18px] sm:tw-text-xl tw-cursor-pointer tw-flex tw-items-center tw-gap-4' onClick={onClickLogout}>
						Sair / Deslogar
					</MenuMobileButton>
				</div>

			</div >

		</>
	)
}
