/**
 * A função `inAtivoWithMatch` verifica se um teaser está ativo e possui um
 *código de substatus.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um objeto que contém informações sobre um teaser. Este objeto provavelmente tem
 * propriedades como `subStatusOfActiveCostumer`, que é usada no
 * Função `inAtivoWithMatch` para determinar se o teaser está ativo
 * @returns A função `inAtivoWithMatch` está retornando um valor booleano. Ele verifica
 * se o objeto `teaser` passado como parâmetro tiver um `subStatusOfActiveCostumer`
 * propriedade que corresponde a um dos códigos definidos no
 * Constante `SUB_STATUS_OF_ACTIVE_COSTUMER`. A função também verifica se o
 * O objeto `teaser` passa na verificação da função `inAtivo`.
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'

export const inAtivoWithMatch = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const list:
		(keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER)[] = [
			SUB_STATUS_OF_ACTIVE_COSTUMER.s00200.code,
			SUB_STATUS_OF_ACTIVE_COSTUMER.s00300.code,
			SUB_STATUS_OF_ACTIVE_COSTUMER.s00400.code,
			SUB_STATUS_OF_ACTIVE_COSTUMER.s00500.code,
		]

	return inAtivo(cloneTeaser) &&
		list.includes(cloneTeaser.subStatusOfActiveCostumer)


}
