import { clone } from './clone'

/**
* Transforma em milhões o número que recebe
* @param {Number} valor {Number} Valor a ser transformado
* @param {Number} decimal {Number} Número de casas decimais
* @returns {Number} {Number} Retorna número com 1 casa decimal
* @example escreveMilhoes(3333333.33, 1) => 3.3
*/


export const escreveMilhoes = (valor = 0, decimal = 1) => {

	let cloneValor = clone(valor)
	const cloneDecimal = clone(decimal)

	cloneValor = Number(cloneValor)
	cloneValor = cloneValor / 1000000
	const valorFixado = cloneValor.toFixed(cloneDecimal)
	return Number(valorFixado)
}
