import { TypeUser } from 'Functions/User/types/TypeUser'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeCreateAdm = {
	email: string;
	password: string
}

type createAdmResponse = {
	message: string
}

export const _createAdm = (input: TypeCreateAdm): Promise<{ data: createAdmResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'createAdm', input })
