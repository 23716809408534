import { ReactNode, useEffect } from 'react'
import { fireLogout } from 'Services/firebase'
import firebase from 'firebase/app'
import 'firebase/functions'
import { fnUser } from 'Functions/User'
import { useContextAuth } from './ContextAuth'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { useContextCustomerTeaser } from './ContextCustomerTeaser'
import { useContextUnReadNotifications } from './ContextUnReadNotifications'
import { useContextCustomerMatches } from './ContextCustomerMatches'
import { db5 } from '../Services/db5'
import { snaps2 } from '../Services/snaps'
import { useModelNPS } from '../Hooks/useModelNPS'

export const Auth = ({ children }: { children: ReactNode }) => {

	const { globalAuth, setGlobalAuth } = useContextAuth()
	const modelNps = useModelNPS()

	const {
		setGlobalCustomerTeaser,
		setGlobalCustomerTeaserId,
	} = useContextCustomerTeaser()

	const { setGlobalCustomerMatches } = useContextCustomerMatches()

	const { unReadNotifications, setUnReadNotifications } = useContextUnReadNotifications()

	const setAllAuthData = (user: firebase.User, doc: TypeUser) => {
		snaps2.access(doc)
		modelNps.run(doc)
		setGlobalAuth({
			auth: 'online',
			uid: user.uid,
			profile: doc,
			email: user.email,
		})
	}

	const asAdm = async (user: firebase.User, doc: TypeUser) => {
		setAllAuthData(user, doc)
	}

	const asMicroBda = async (user: firebase.User, doc: TypeUser) => {
		setAllAuthData(user, doc)
	}

	const asOffice = async (user: firebase.User, doc: TypeUser) => {
		setAllAuthData(user, doc)
	}

	const asOfficeAnalyst = async (user: firebase.User, doc: TypeUser) => {
		setAllAuthData(user, doc)
	}

	const asBDA = async (user: firebase.User, doc: TypeUser) => {
		fnUser.unReadNotificationsGeneral({ profile: doc, callback: notifications => setUnReadNotifications(notifications) })
		setAllAuthData(user, doc)
	}

	const asEnterprise = async (user: firebase.User, doc: TypeUser) => {
		fnUser.unReadNotificationsGeneral({ profile: doc, callback: notifications => setUnReadNotifications(notifications) })
		setAllAuthData(user, doc)
	}

	const asCustomer = async (user: firebase.User, doc: TypeUser) => {
		let teaser: '' | TypeTeaser = ''
		let teaserId = ''
		fnUser.unReadNotificationsGeneral({ profile: doc, callback: notifications => setUnReadNotifications(notifications) })
		db5.teasers.getWhere({
			profile: doc,
			wheres: [['users', 'array-contains', user.uid]]
		})
			.then(res => {
				if (res.docs) {

					Object.entries(res.docs).forEach(([id, localTeaser]) => {
						teaser = localTeaser
						teaserId = id
					})
					setGlobalCustomerTeaser(teaser)
					setGlobalCustomerTeaserId(teaserId)
				}
			})
		db5
			.matches.getWhereSync({
				profile: doc,
				wheres: [['idTeaser', '==', teaserId]],
				callBack: res => {
					if (res.docs) {
						setGlobalCustomerMatches(res?.docs)
					}
				}
			})
		setAllAuthData(user, doc)
	}

	useEffect(() => {

		const LoginFirebase = (callback: (user: firebase.User) => void, callback2: () => void) => {
			firebase.auth().onAuthStateChanged(user => user ? callback(user) : callback2())
		}

		LoginFirebase(
			async user => {
				// getRoleId()
				firebase.firestore().doc(`/users/${user.uid}`).onSnapshot(
					doc => {
						if (doc.data()) {
							const profile = fnUser.modelUser({ ...doc.data(), _id: user.uid }) as TypeUser

							if (fnUser.isAdm(profile)) asAdm(user, profile)
							if (fnUser.isBda(profile)) asBDA(user, profile)
							if (fnUser.isCustomer(profile)) asCustomer(user, profile)
							if (fnUser.isMicroBda(profile)) asMicroBda(user, profile)
							if (fnUser.isOffice(profile)) asOffice(user, profile)
							if (fnUser.isOfficeAnalyst(profile)) asOfficeAnalyst(user, profile)
							if (fnUser.isEnterprise(profile)) asEnterprise(user, profile)
						}
						else {
							fireLogout() // se não encontrar o perfil, faz logoff no usuário
						}
					}
				)

			},
			() => {
				setGlobalAuth({ ...globalAuth, auth: 'offLine', email: null }) // caso não esteja logado, seta o estado como offline
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (globalAuth.auth === 'online' && globalAuth.profile && globalAuth.uid) {
		return <>{children}</>
	}

	return <>{children}</>
}

// const getRoleId = async () => {
// 	try {
// 		const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult()
// 		console.log(idTokenResult?.claims?.roleId)
// 		return
// 	}
// 	catch (e) {
// 		console.log(e)
// 		return ''
// 	}
// }
