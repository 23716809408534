import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BriefCaseFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M13.6751 5.64258H18.3339C18.9517 5.64258 19.5442 5.88656 19.981 6.32086C20.4179 6.75515 20.6633 7.34418 20.6633 7.95837V10.2742H25.3221C25.9399 10.2742 26.5324 10.5181 26.9693 10.9524C27.4061 11.3867 27.6515 11.9758 27.6515 12.5899V25.3268C27.6515 25.941 27.4061 26.53 26.9693 26.9643C26.5324 27.3986 25.9399 27.6426 25.3221 27.6426H6.68683C5.39401 27.6426 4.35742 26.6005 4.35742 25.3268V12.5899C4.35742 11.3047 5.39401 10.2742 6.68683 10.2742H11.3457V7.95837C11.3457 6.6731 12.3822 5.64258 13.6751 5.64258ZM18.3339 10.2742V7.95837H13.6751V10.2742H18.3339Z' fill='#272827' />
	</Svg>
)


