import { sendOriginationRejected } from './sendOriginationRejected'
import { sendOriginationCreated } from './sendOriginationCreated'
import { sendOriginationPublished } from './sendOriginationPublished'
import { sendOriginationApproved } from './sendOriginationApproved'
import { sendOriginationSignContract } from './sendOriginationSignContract'
import { sendOriginationAdjust } from './sendOriginationAdjust'
import { sendEnviadoMatchmaking } from './sendEnviadoMatchmaking'
import { sendMatch } from './sendMatch'
import { sendEnviadoInactive } from './sendEnviadoInactive'
import { sendInativeToMatch } from './sendInativeToMatch'
import { sendOferta } from './sendOferta'
import { sendDiligencia } from './sendDiligencia'
import { sendContratosDefinitivos } from './sendContratosDefinitivos'
import { sendTransacaoConcluida } from './sendTransacaoConcluida'
import { sendChurn } from './sendChurn'
import { sendServiçoRetomado } from './sendServiçoRetomado'

export const agente = {
	sendOriginationCreated,
	sendOriginationPublished,
	sendOriginationRejected,
	sendOriginationApproved,
	sendOriginationSignContract,
	sendOriginationAdjust,
	sendEnviadoMatchmaking,
	sendMatch,
	sendEnviadoInactive,
	sendInativeToMatch,
	sendOferta,
	sendDiligencia,
	sendContratosDefinitivos,
	sendTransacaoConcluida,
	sendChurn,
	sendServiçoRetomado
}
