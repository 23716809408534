import { useContextAuth } from 'App/ContextAuth'

export const permissions: {
	[key: string]: {
		'adm'?: boolean
		'BDA'?: boolean
		'enterprise'?: boolean
		'MicroBda'?: boolean
		'Customer'?: boolean
		'hub'?: boolean
		'office'?: boolean
		'officeAnalyst'?: boolean
	}
} = {
	'editor de bda': {
		'adm': false,
		'BDA': true,
		'Customer': false,
		'MicroBda': false,
		'hub': false,
		'enterprise': true
	},
	'botão de excluir': {
		'adm': true,
		'BDA': true,
		'enterprise': true
	},
	'icon-tags': {
		'adm': true,
		'BDA': true,
		'enterprise': true
	},
	'Kanban-modal-MM': {
		'adm': true,
	},
	'teaser-edit': {
		'adm': true,
	},
	'origination-edit': {
		'adm': true,
		'BDA': true,
		'enterprise': true
	},
	'UserManagement-user-id': {
		'adm': true,
	},
	'originations-btn-new-origination': {
		'adm': false,
		'BDA': true,
		'Customer': false,
		'MicroBda': true,
		'hub': true,
		'office': true,
		// 'officeAnalyst': true,
		'enterprise': true
	},
	'sinatraButtonsEdition': {
		adm: true,
	},
	'profileCompany-visible': {
		'adm': true,
	},
	'profileCompany-filter-for-office': {
		'adm': true,
	},
	'profileCompany-assinarContrato': {
		'adm': true,
		'office': true,
	},
	'filter-office-origination': {
		'adm': true,
		'BDA': true,
		'Customer': false,
		'MicroBda': true,
		'hub': true,
		'office': false,
		'enterprise': true
	},
	'userManagement-edit-button': {
		'adm': true,
	},
	'userManagement-filtro': {
		'adm': true,
	},
	'addOrigination-segmentAdmin': {
		'adm': true,
		'office': true,
		'officeAnalyst': true,
	},
	'addOrigination-segmentAdmin-logo': {
		'adm': true,
		'office': true,
		'officeAnalyst': true,
	},
	'addOrigination-segmentAdmin-details': {
		'adm': true,
		'office': true,
		'officeAnalyst': true,
	},
	'addOrigination-segmentAdmin-authors': {
		'adm': true,
	},
	'kanban-longlist': {
		'adm': true,
	},
	'add-origination-save-edit': {
		'adm': true,
		'office': true,
		'officeAnalyst': true,
	},
	'edit-delete-notification-feed': {
		'adm': true,
		'office': true,
		'officeAnalyst': true,
	},
	'button-clickup-active-customer': {
		'adm': true,
	},
	'button-google-drive-active-customer': {
		'adm': true
	},
	'button-profile-investor': {
		'adm': true
	},
	'key-people-adm-manipulation': {
		'adm': true
	},

	'match-tabs-without-other': {
		'adm': false,
		'office': true,
		'officeAnalyst': true,
	},
	'match-tabs-with-other': {
		'adm': true,
		'office': false,
	},
	'exportExcelMatches': {
		'adm': true,
		'office': true,
	},
	'userManagement-buttons-of-ranking': {
		'adm': true,
	},
	'profileCompany-commercial-validation': {
		'adm': true,
		'office': true,
	},
	'tese-data-modificação': {
		'adm': true,
	},
	'originators-atualizar-ranking': {
		'adm': true,
	},

	'originator-perfil-ranking': {
		'BDA': true,
		'adm': true,
		'enterprise': true
	},
	'originator-perfil-lista-agentes': {
		'office': true,
		'adm': true,
	},
	'notification-bell': {
		'BDA': true,
		'enterprise': true
	},
	'button-tour': {
		'BDA': true,
		'enterprise': true
	},
	'TeseCard-data-modificacao': {
		adm: true,
	},
	'assistant-ai': {
		'adm': true,
		'office': true,
		'BDA': true,
		'officeAnalyst': true,
		'enterprise': true,
		'MicroBda': true,
	},
	'show-switch-status-guarantee': {
		'adm': true,
		'office': true,
	}
}

export const segment = (name: string): boolean => {
	const { globalAuth, } = useContextAuth()

	const type = globalAuth?.profile?.tipo

	// @ts-ignore
	return permissions?.[name]?.[type] ?? false
}
