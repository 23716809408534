import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const NotepadOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M8.375 4.54541H11.0417C11.778 4.54541 12.375 5.11523 12.375 5.81814C12.375 6.52105 11.778 7.09087 11.0417 7.09087H8.3793C8.02543 7.09349 7.68678 7.22883 7.43649 7.46774C7.1862 7.70666 7.04441 8.02992 7.04166 8.3677V26.1777C7.04441 26.5155 7.1862 26.8387 7.43649 27.0777C7.68678 27.3166 8.02545 27.4519 8.37933 27.4545H24.3707C24.7245 27.4519 25.0632 27.3166 25.3135 27.0777C25.5638 26.8387 25.7056 26.5155 25.7083 26.1777V8.36773C25.7056 8.02993 25.5638 7.70666 25.3135 7.46774C25.0632 7.22883 24.7246 7.09349 24.3707 7.09087H21.7083C20.9719 7.09087 20.375 6.52105 20.375 5.81814C20.375 5.11523 20.9719 4.54541 21.7083 4.54541H24.3823C25.4394 4.55097 26.4516 4.9543 27.1991 5.66783C27.9466 6.38136 28.3691 7.34752 28.375 8.35658L28.375 8.3636L28.375 26.1888C28.3692 27.1979 27.9466 28.164 27.1991 28.8776C26.4516 29.5911 25.4394 29.9944 24.3823 30L24.375 30H8.36765C7.31053 29.9944 6.29837 29.5911 5.55087 28.8776C4.80337 28.164 4.38084 27.1979 4.37502 26.1888L4.375 26.1818V8.35658C4.38082 7.34752 4.80336 6.38136 5.55087 5.66783C6.29837 4.9543 7.31054 4.55099 8.36765 4.54543L8.375 4.54541Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.70898 4.54546C9.70898 3.13964 10.9029 2 12.3756 2H20.3756C21.8484 2 23.0423 3.13964 23.0423 4.54546V7.09092C23.0423 8.49673 21.8484 9.63637 20.3756 9.63637H12.3756C10.9029 9.63637 9.70898 8.49673 9.70898 7.09092V4.54546ZM20.3756 4.54546L12.3756 4.54546V7.09092H20.3756L20.3756 4.54546Z' fill='#272827' />
	</Svg>
)
