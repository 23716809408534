import { clone } from '../../../Functions/Utils/clone'
import { TYPE_NOTIFICATIONS } from '../../../Constants/TYPE_NOTIFICATIONS'
import { TypeNotification } from '../types/notification'

/** Função que verifica se uma notificação é independente.
 * Independente quer dizer que ela é uma notificação para o usuário
 * e não está ligada à originação, match ou outra coisa.
 * Exemplo: Notificação de Boas Vindas
 */
export const isIndependent = ({
	notification,
}: {
	notification: TypeNotification;
}): boolean => {

	const cloneNotification = clone(notification)

	const independentMessages: (keyof typeof TYPE_NOTIFICATIONS)[] = [
		TYPE_NOTIFICATIONS.b0001._id,
		TYPE_NOTIFICATIONS.b0003._id,
		TYPE_NOTIFICATIONS.b0015._id,
	]

	return independentMessages.includes(cloneNotification.type as keyof typeof TYPE_NOTIFICATIONS)
}
