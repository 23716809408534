export const helperCheckDate = (dataView: string | null, dataAnswer: string | null, dataAtual: Date = new Date()) => {
	if (dataAnswer) {
		const diffDays = (dataAtual.getTime() - +dataAnswer) / (1000 * 60 * 60 * 24)
		if (diffDays < 15) return false
	}
	else if (dataView) {
		const diffDays = (dataAtual.getTime() - +dataView) / (1000 * 60 * 60 * 24)
		if (diffDays < 2) return false
	}
	return true
}
