import { IUiInput } from '..'
import { masks } from 'Functions/Utils/masks'
import React from 'react'

export const onChangePercentage = (props: IUiInput) =>
	(event: React.ChangeEvent<HTMLInputElement>) => {
		const value = masks.toPercent({
			value: event.target.value,
			min: props.min as number,
			max: props.max as number,
			fraction: props.fraction
		})
		event.target.value = value.toString()
		props.onChange && props.onChange(event)
	}
