import React, { createContext, useContext, useState } from 'react'
import { TypeUser } from 'Functions/User/types/TypeUser'

const _useGlobalContext = () => {

	const [globalAuth, setGlobalAuth] = useState({
		auth: '',
		uid: '',
		profile: {},
		email: '',
	} as {
		auth: '' | 'offLine' | 'online',
		uid: string,
		profile: TypeUser,
		email: string | null,
	})

	return ({
		globalAuth,
		setGlobalAuth
	})
}
const ContextAuth = createContext({} as ReturnType<typeof _useGlobalContext>)

export const useContextAuth = () => useContext(ContextAuth)
export const ContextAuthProvider: React.FC = ({ children }) => {
	const controller = _useGlobalContext()
	return (
		<ContextAuth.Provider value={controller}>
			{children}
		</ContextAuth.Provider>
	)
}
