/**
 * A função `isTech` verifica se um teaser pertence a uma empresa de tecnologia com base em sua
 * classificação da empresa ou etiqueta tecnológica ativa.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente é um objeto contendo informações sobre um teaser. No `isTech`
 * função, um clone do objeto `teaser` é criado usando a função `clone`
 * do módulo `Utils`. A função então
 * @returns A função `isTech` está retornando um valor booleano. Ele verifica se o
 * A propriedade `companyRating` do objeto `teaser` é igual ao ID do 'Tech'
 * classificação da empresa a partir das constantes `COMPANY_RATING` ou se for `tagTechActive`
 * propriedade do objeto `teaser` é verdadeira. A função retorna `true` se o
 *a condição é
 */
//CRIADO POR IA

import { COMPANY_RATING } from '../../../Constants/COMPANY_RATING'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'


export const isTech = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.companyRating ? cloneTeaser.companyRating === COMPANY_RATING.GET('Tech')._id : cloneTeaser.tagTechActive

}
