import React, { createContext, useContext, useState } from 'react'

const _useContext = () => {
	const [openNotification, setOpenNotification] = useState(false)
	return ({
		openNotification, setOpenNotification,
	})
}
const ContextNotificationWidget = createContext({} as ReturnType<typeof _useContext>)

export const useContextNotificationWidget = () => useContext(ContextNotificationWidget)
export const ContextNotificationWidgetProvider: React.FC = ({ children }) => {
	const controller = _useContext()
	return (
		<ContextNotificationWidget.Provider value={controller}>
			{children}
		</ContextNotificationWidget.Provider>
	)
}
