import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ArrowUpOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M30.4216 7.41466C30.9566 7.96753 30.9566 8.86393 30.4216 9.4168L18.8067 21.4188C18.2717 21.9717 17.4042 21.9717 16.8692 21.4188L11.7241 16.1022L3.51462 24.5853C2.97958 25.1382 2.11211 25.1382 1.57706 24.5853C1.04202 24.0325 1.04202 23.1361 1.57706 22.5832L10.7553 13.099C11.2904 12.5461 12.1578 12.5461 12.6929 13.099L17.838 18.4156L28.484 7.41466C29.019 6.86178 29.8865 6.86178 30.4216 7.41466Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M20.7432 8.41573C20.7432 7.63384 21.3566 7 22.1132 7H29.4528C30.2095 7 30.8229 7.63384 30.8229 8.41573V16C30.8229 16.7819 30.2095 17.4157 29.4528 17.4157C28.6962 17.4157 28.0828 16.7819 28.0828 16V9.83146H22.1132C21.3566 9.83146 20.7432 9.19762 20.7432 8.41573Z' fill='#272827' />
	</Svg>
)
