import { clone } from '../../../Functions/Utils/clone'
import { TypeNotification } from '../types/notification'

export const checkUnRead =
	({ notification, uid }: { notification: TypeNotification, uid: string }) => {

		const cloneNotification = clone(notification)
		const cloneUid = clone(uid)

		return cloneNotification.unReadBy?.[cloneUid] && cloneNotification.unReadBy?.[cloneUid] === true
	}

