import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

import { TypeUser } from 'Functions/User/types/TypeUser'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'

type TypeCnpjIsAvailable = {
	teaser: TypeTeaser,
	profile: TypeUser,
	publishing?: boolean
}

type CnpjIsAvailableResponse = {
	status: number;
	message: string;
	consoleLength: number;
	cnpj: string;
	uid: string;
}

export const _cnpjIsAvailable = (input: TypeCnpjIsAvailable): Promise<{ data: CnpjIsAvailableResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'cnpjIsAvailable', input })

