import { clone } from '../../Utils/clone'
import { TypeTeaser } from '../types/teaser'
/**
 * Alguns usuários do tipo agente não devem contar como se fossem os originadores de um teaser
 * Esse método, verifica se o uid do teaser está entre alguns desses originadores e o troca pelo do starkIb
 * @param teaser
 * @returns teaser
 */
export const replaceUidToStarkIB = (teaser: TypeTeaser) => {
	const _teaser = clone(teaser)
	const check = !![
		's9VQHxVPv7ZADfcuRzIE7KJETGn1', // fausto
		'YuWB4uV7BxbXh1ZyXOFdXollzl32', // gual
		'smfjTUWOOten4Zd0awKVnP3phVr1', // rodrigo
		'W00FCnF2JtZwxN58sU90JHp2ZF43', // joao
		'z9yFVuHvLbW2AZBcr7PZHGJi1df1', // anderson
		'oVHxJ0I7ZZWfgh7bodnMsicokv72', //brunazulauf@gmail.com
		'dFcJf2T9rdUt05OQr8e9pMcbqwx1', //bruno@creditoarian.com.br
		'L7PRyoyt3Ed2qsNFFFnanwPAqlF2', //rodrigo.direitofmu@gmail.com
		'Bk3EWhiBsQcFO3r1MciOBDtf3AE3', //silveirajhe@gmail.com
		'MYIy9NVinlXhABL8ZleEhClR8u12', //gualadm@gmail.com
		'AcubeUIt16Vhbeoeped4cTKqLW03', //joaovitorcarminatti@gmail.com
		'1U6RAZUKOXb9yQJCJQ2cQ3Vc0tf2', //gsp@stark-capital.com.br
		'YuWB4uV7BxbXh1ZyXOFdXollzl32', //gsp@stark-capital.com.br
		'mVWwX19Bh7hIM2rqbURw3vp9To43', //gsp@stark-capital.com.br
		'Ui7fpWGvxZNQHsjfiwX1exZmd9c2', //rodrigo@startupeiro.com
		'RvPkymcXjQZoD5znNcCnalUX6YA3', //corporativo@stark.money
	].includes(teaser.uid)

	if (check) _teaser.uid = 'ez22SO3AU9WiDqCLsLKBqHj5lu33'

	return _teaser
}
