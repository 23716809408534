import { TypeTeaser } from '../types/teaser'
import { TypeUserGenerics } from '../../User/types/userGenerics'

/**
 * Ordena um array de teasers pelo nome do office
 * @param teasers
 * @param order
 * @returns  TypeTeaser[]
 */
export const sortByOffice = ({ teasers, order, users }: { teasers: TypeTeaser[], order: 'asc' | 'desc', users: TypeUserGenerics }): TypeTeaser[] =>
	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
		const isAsc = order === 'asc'
		const nameA = users?.[teaserA?.fromOffice]?.nome || 'Stark'
		const nameB = users?.[teaserB?.fromOffice]?.nome || 'Stark'

		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})
