import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Default } from './src/index'
import { ITheme } from './src/themes'
import { IQueriesGutter } from './row'
import { getGutter } from './getGutter'
import { getResponsive } from './getResponsive'
import { getSize } from './getSize'
export type TSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type TGutterDirection = 'horizontal' | 'both' | 'vertical' | undefined;
export type TSizeValue = number | 'hide' | 'show';

/** @deprecated */
export interface IGutter {
	gutter?: number | IQueriesGutter;
}

/** @deprecated */
export interface ColProps extends HTMLAttributes<HTMLDivElement>, IGutter {
	size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	xxs?: number | 'hide' | 'show';
	xs?: number | 'hide' | 'show';
	sm?: number | 'hide' | 'show';
	md?: number | 'hide' | 'show';
	lg?: number | 'hide' | 'show';
	xl?: number | 'hide' | 'show';
	xxl?: number | 'hide' | 'show';
	xxxl?: number | 'hide' | 'show';
	gutterDirection?: 'both' | 'horizontal' | 'vertical';
	hide?: boolean;
	display?:
	| 'flex'
	| 'inline-flex'
	| 'block'
	| 'inline-block'
	| 'inline'
	| 'none';
	justifyContent?:
	| 'center'
	| 'flex-start'
	| 'flex-end'
	| 'space-around'
	| 'space-between';
	alignItems?:
	| 'center'
	| 'flex-start'
	| 'flex-end'
	| 'space-around'
	| 'space-between';
}

/** @deprecated */
export interface InternalColProps extends ColProps {
	theme: ITheme,
}

const BoxStyled = styled.div<ColProps>`
    display: ${props => props.hide ?
		'none' :
		(props.display ? props.display : 'block')};
    box-sizing: border-box;
    ${(props: InternalColProps) => props.gutter ?
		css`
            ${getGutter({ props, type: 'padding' })}
        ` : ''}
    ${(props: ColProps) => css`
            ${props.size ? `
                flex: 0 0 ${getSize(props.size)}%;
                max-width: ${getSize(props.size)}%;` : ''}
        `}
    ${(props: InternalColProps) => css`${getResponsive(props)}`}
    ${(props: InternalColProps) => props.justifyContent ? css`justify-content: ${props.justifyContent};` : ''}
    ${(props: InternalColProps) => props.alignItems ? css`align-items: ${props.alignItems};` : ''}
`

export const Col: FC<ColProps> = props => <BoxStyled {...props}>{props.children}</BoxStyled>

const DP: InternalColProps = {
	theme: Default
}

Col.defaultProps = DP
