import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const TractorFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M27.6043 20.0386C28.5504 17.8797 28.7444 16.4303 28.2108 15.4843C28.0046 15.1204 27.4891 14.508 26.3551 14.508H24.7359V10.9543C24.7359 10.0507 25.2089 9.89303 25.2514 9.8809C25.403 9.85665 25.494 9.72323 25.4818 9.57163L25.3969 8.40122C25.3909 8.32238 25.3545 8.24961 25.2938 8.2011C25.2332 8.15258 25.1604 8.12833 25.0816 8.12833C24.3539 8.18291 22.9773 8.84391 22.9773 10.9543V14.508H18.041L16.9918 7.39455C16.9251 6.96399 16.5552 6.64258 16.1186 6.64258H7.33144C6.8463 6.64258 6.45212 7.03676 6.45212 7.5219V9.24416C6.45212 9.68078 6.77353 10.0446 7.19803 10.1113V15.8724C5.04521 16.6365 3.50488 18.6862 3.50488 21.0998C3.50488 24.1623 5.98517 26.6426 9.04764 26.6426C11.3884 26.6426 13.3957 25.1872 14.2083 23.1314H21.1641C21.1641 23.1374 21.1641 23.1435 21.1641 23.1496C21.1641 25.078 22.7287 26.6365 24.651 26.6365C26.5734 26.6365 28.138 25.0719 28.138 23.1496C28.138 22.252 27.7984 21.4334 27.2405 20.8148C27.3618 20.5662 27.4831 20.3054 27.6043 20.0386ZM9.04764 23.7075C7.6104 23.7075 6.44 22.5371 6.44 21.0998C6.44 19.6626 7.6104 18.4922 9.04764 18.4922C10.4849 18.4922 11.6553 19.6626 11.6553 21.0998C11.6553 22.5371 10.4849 23.7075 9.04764 23.7075ZM9.76929 14.4716V9.69898H14.6874L15.403 14.4716H9.76929ZM24.651 24.2957C24.0203 24.2957 23.5049 23.7802 23.5049 23.1496C23.5049 22.5189 24.0203 22.0034 24.651 22.0034C25.2817 22.0034 25.7972 22.5189 25.7972 23.1496C25.7972 23.7802 25.2817 24.2957 24.651 24.2957Z' fill='#272827' />
	</Svg>
)


