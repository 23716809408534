import { TypeUser } from '../../../User/types/TypeUser'
import { TypeTeaser } from '../../types/teaser'
import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { STATUS_CUSTOMER } from '../../../../Constants/STATUS_CUSTOMER'
import { inComite } from '../inComite'
import { inComercial } from '../inComercial'
import { inStandby } from '../inStandby'
import { inAtivo } from '../inAtivo'
import { isPublicado } from '../isPublicado'
import { helpersDate } from '../../../../Functions/Utils/helpersDate'
import { getValidateDue } from '../getValidateDue'
import { getStatusDate } from './getStatusDate'
import { clone } from '../../../Utils/clone'
import { isAdm } from '../../../User/methods/isAdm'
import { modelUser } from '../../../User/model/modelUser'

export const getStatus = ({ teaser, profile }: { teaser: TypeTeaser, profile?: TypeUser }) => {
	const cloneTeaser = clone(teaser)
	const cloneProfile = clone(profile || modelUser({ tipo: 'BDA' }))

	const statusCustomer = getStatusCustomer(cloneTeaser)
	const status: { label: string, value: keyof typeof STATUS_CUSTOMER } = { label: statusCustomer.label, value: statusCustomer.value }

	if (!isPublicado(cloneTeaser)) {
		status.label = isAdm(cloneProfile) ? 'Não publicado' : 'Prospecto'
	}

	let substatus: { code: keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER, label: string } | undefined

	const isPrematch = cloneTeaser.subStatusOfActiveCostumer !== SUB_STATUS_OF_ACTIVE_COSTUMER.s00050.code
	const isActiveTeaser = status?.value === STATUS_CUSTOMER.ativo.value
	const subStatusOfActiveCostumer = cloneTeaser.subStatusOfActiveCostumer as keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER

	if (isPublicado(cloneTeaser) && isActiveTeaser) {
		substatus = { code: SUB_STATUS_OF_ACTIVE_COSTUMER[subStatusOfActiveCostumer].code as keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER, label: SUB_STATUS_OF_ACTIVE_COSTUMER[subStatusOfActiveCostumer].labelSubstatus }
	}

	if (!isPrematch && substatus && !isAdm(cloneProfile)) {
		substatus.label = 'Ativo'
	}

	const prazo = { ...getValidateDue(cloneTeaser, cloneProfile) }

	if (inComite(cloneTeaser) && !isAdm(cloneProfile)) {
		prazo.resp = 'Aguardando'
	}

	return {
		status,
		substatus,
		date: helpersDate.toString(helpersDate.convertAllToTimestamp(getStatusDate(cloneTeaser))),
		numberOfDays: prazo.result,
		defeated: prazo.defeated,
		deadline: prazo.deadline,
		timePeriodLabel: prazo.resp
	}
}

const getStatusCustomer = (teaser: TypeTeaser) => {
	if (inComite(teaser)) return STATUS_CUSTOMER.comite
	if (inComercial(teaser)) return STATUS_CUSTOMER.comercial
	if (inStandby(teaser)) return STATUS_CUSTOMER.standby
	if (inAtivo(teaser)) return STATUS_CUSTOMER.ativo
	return STATUS_CUSTOMER.notPublish
}

