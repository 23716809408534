import { agente } from './methods/agente'
import { checkRead } from './methods/checkRead'
import { customer } from './methods/customer'
import { formatData } from './methods/formatData'
import { getPermission } from './methods/getPermission'
import { remove } from './methods/remove'
import { send } from './methods/send'
import { sendEnviadoInactive } from './methods/sendEnviadoInactive'
import { sendHistory } from './methods/sendHistory'
import { sendInativeToMatch } from './methods/sendInativeToMatch'
import { sendMatch } from './methods/sendMatch'
import { update } from './methods/update'
import { modelNotification } from './methods/modelNotification'
import { checkUnRead } from './methods/checkUnRead'
import { setAsRead } from './methods/setAsRead'
import { getNotificationsGroupByTeaser } from './methods/getNotificationsGroupByTeaser'
import { isIndependent } from './methods/isIndependent'
import { isAutomatic } from './methods/isAutomatic'
import { unReadNotificationsPerInstitution } from './methods/unReadNotificationsPerInstitution'
import { unReadNotificationsPerOrigination } from './methods/unReadNotificationsPerOrigination'
import { unReadNotificationsPerMatch } from './methods/unReadNotificationsPerMatch'

export const fnNotification = {
	modelNotification,
	agente,
	customer,
	send,
	getPermission,
	sendMatch,
	sendEnviadoInactive,
	sendInativeToMatch,
	formatData,
	sendHistory,
	remove,
	update,
	checkRead,

	isIndependent,
	checkUnRead,
	setAsRead,
	unReadNotificationsPerInstitution,
	unReadNotificationsPerOrigination,
	unReadNotificationsPerMatch,
	getNotificationsGroupByTeaser,
	isAutomatic
}
