import React, { FC, HTMLAttributes, ReactChild } from 'react'
import styled from 'styled-components'

/** @deprecated */
export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
	/** custom content */
	children?: ReactChild | ReactChild[];
}

const StyledContainer = styled.div``

/**
 * A custom Container component.
 *
 * 12 grid System
 *
 * Responsivity:
 *
 * xxs = 320px, xs = 480px, sm = 600px, md = 768px, lg = 900px, xl = 1024px, xxl = 1440px, xxxl = 1441px
 *
 * Lower than 320 (xxs)
**  `@media(max-width: 320px)`
*
 * Between 321 & 480 (xs)
 *
 *
** `@media(min-width : 321px) and (max-width : 480px) `
*
 * Between 481 & 600 (sm)
**   `@media(min-width :481px) and (max-width : ${SIZE_SM}px) `
*
 * Between 601 & 768 (md)
**   `@media(min-width : 601px) and (max-width : 768px) `
*
 * Between 769 & 900 (lg)
**   `@media(min-width : 769px) and (max-width : 900px) `
*
 * Between 901 & 1024 (xl)
**   `@media(min-width : 901px) and (max-width : 1024px) `
*
 * Between 1025 & 1440 (xxl)
**   `@media(min-width : 1025px) and (max-width : 1440px) `
*
 * Bigger then 1441 (xxxl)
**   `@media(min-width : 1441px)`
 *
 *
 * Combine "hide" with breakpoint to show elements in specific breakpoint
 *
 * ex: md={3} hide
 *
 */
/** @deprecated */
export const Container: FC<ContainerProps> = props => <StyledContainer {...props}>{props.children}</StyledContainer>
