import * as Tokens from 'Components/CardDS/src/Tokens'

export const AvatarTheme = {
	sizes: {
		xxs: {
			size: Tokens.AVATAR_SIZE_XXS
		},
		xs: {
			size: Tokens.AVATAR_SIZE_XS
		},
		sm: {
			size: Tokens.AVATAR_SIZE_SM
		},
		md: {
			size: Tokens.AVATAR_SIZE_MD
		},
		lg: {
			size: Tokens.AVATAR_SIZE_LG
		},
		xl: {
			size: Tokens.AVATAR_SIZE_XL
		},
		xxl: {
			size: Tokens.AVATAR_SIZE_XXL
		}
	},
}
