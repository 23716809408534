import { GUARANTEES } from '../../../Constants/GUARANTEES'
import { TEASER_FIELDS } from '../../../Constants/TEASER_FIELDS'
import { TypeTeaser } from '../../Teaser/types/teaser'

type TError = {
	message: string
}

type TypeError = {
	[key: string]: TError
}

const ZERO_VALUE = ['0.00', '0,00']

export const validateGuarantees = (origination: TypeTeaser) => {
	const { guarantees, noGuarantees } = origination
	const errors: TypeError = {}

	if (guarantees && Object.keys(guarantees).length) {
		for (const key in guarantees) {
			if (Object.prototype.hasOwnProperty.call(guarantees, key)) {
				const list = guarantees[key as GUARANTEES]
				list?.map(i => {
					if ('type' in i && (!i.type || ZERO_VALUE.includes(i.type))) {
						errors[TEASER_FIELDS.guarantees] = { message: 'O tipo de uma garantia não pode ser vazio' }
					}
					if ('value' in i && (!i.value || ZERO_VALUE.includes(i.value))) {
						errors[TEASER_FIELDS.guarantees] = { message: 'O valor de uma garantia não pode ser vazio ou R$0,00' }
					}

				})
			}
		}
	}
	else if (!noGuarantees) {
		errors[TEASER_FIELDS.noGuarantees] = { message: 'Selecione ao menos uma garantia ou clique em "Não tenho garantias"' }
	}

	return Object.keys(errors) ? errors : null
}
