import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const DocumentMinusOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M8.31526 2H19.0614C19.394 2 19.7128 2.12591 19.948 2.35004L28.0076 10.033C28.2427 10.2571 28.3748 10.5611 28.3748 10.878V26.2505C28.3691 27.2432 27.9528 28.1938 27.2164 28.8957C26.48 29.5977 25.4829 29.9945 24.4414 30L24.4345 30H8.30836C7.26694 29.9945 6.26981 29.5977 5.53341 28.8957C4.797 28.1938 4.38076 27.2432 4.37502 26.2505L4.375 26.2439V5.74951C4.38074 4.75677 4.797 3.80625 5.53341 3.10426C6.26981 2.40228 7.26694 2.00549 8.30836 2.00002L8.31526 2ZM8.31926 4.39024C7.93911 4.39297 7.5753 4.53812 7.30644 4.79442C7.03757 5.05072 6.8853 5.39753 6.88244 5.7599V26.2401C6.8853 26.6025 7.03757 26.9493 7.30644 27.2056C7.57525 27.4618 7.93899 27.607 8.31906 27.6098H24.4307C24.8108 27.607 25.1745 27.4618 25.4434 27.2056C25.7122 26.9493 25.8645 26.6025 25.8674 26.2401V11.3731L18.5421 4.39024H8.31926Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M19.0613 2C19.7537 2 20.3151 2.53507 20.3151 3.19512V9.68293H27.121C27.8134 9.68293 28.3747 10.218 28.3747 10.878C28.3747 11.5381 27.8134 12.0732 27.121 12.0732H19.0613C18.3689 12.0732 17.8076 11.5381 17.8076 10.878V3.19512C17.8076 2.53507 18.3689 2 19.0613 2Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M11.0918 19.8416C11.0918 19.1816 11.6531 18.6465 12.3455 18.6465H20.4052C21.0976 18.6465 21.6589 19.1816 21.6589 19.8416C21.6589 20.5017 21.0976 21.0367 20.4052 21.0367H12.3455C11.6531 21.0367 11.0918 20.5017 11.0918 19.8416Z' fill='#272827' />
	</Svg>
)
