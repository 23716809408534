import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { Default } from './src/index'
import { ITheme } from './src/themes'
import { getGutter } from './getGutter'

/** @deprecated */
export interface IQueriesGutter {
	[key: string]: number | undefined;
	xxs?: number;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	xxl?: number;
	xxxl?: number;
}

/** @deprecated */
export interface RowProps extends HTMLAttributes<HTMLDivElement> {
	gutter?: number | IQueriesGutter;
	gutterDirection?: 'both' | 'horizontal' | 'vertical';
	justifyContent?:
	| 'center'
	| 'flex-start'
	| 'flex-end'
	| 'space-around'
	| 'space-between';
	alignItems?:
	| 'center'
	| 'flex-start'
	| 'flex-end'
	| 'space-around'
	| 'space-between';
}

/** @deprecated */
export interface InternalRowProps extends RowProps {
	theme: ITheme,
}

const BoxStyled = styled.div<RowProps>`
    display: flex;
    flex-flow: row wrap;

    ${(props: InternalRowProps) => props.gutter ?
		css`
            ${getGutter({ props, type: 'margin', negative: true })}
        ` : ''}

    ${(props: InternalRowProps) => props.justifyContent ? css`justify-content: ${props.justifyContent};` : ''}
    ${(props: InternalRowProps) => props.alignItems ? css`align-items: ${props.alignItems};` : ''}
`

/** @deprecated */
export const Row: FC<RowProps> = props => {
	let children = React.Children.toArray(props.children)
	const childProps: RowProps = {}
	if (props.gutter) {
		childProps.gutter = props.gutter
	}
	if (props.gutterDirection) {
		childProps.gutterDirection = props.gutterDirection
	}
	children = children.map((child: any) =>
		React.isValidElement(child) ?
			React.cloneElement(child, childProps) : child)
	return <BoxStyled {...props}>{children}</BoxStyled>
}

const DP: InternalRowProps = {
	theme: Default
}

Row.defaultProps = DP
