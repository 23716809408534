import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const HeartOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M15.9675 27.5C15.5707 27.5 15.3062 27.3701 15.0417 27.1102L4.99072 17.2345C2.08122 14.3757 2.08122 9.5678 4.99072 6.70904C7.90022 3.85028 12.7935 3.85028 15.703 6.70904L15.9675 6.96893L16.232 6.70904C17.6867 5.27966 19.5382 4.5 21.6542 4.5C23.638 4.5 25.6217 5.27966 27.0765 6.70904C28.399 8.13842 29.1925 9.95763 29.1925 11.9068C29.1925 13.8559 28.399 15.8051 26.9442 17.2345L16.8932 27.1102C16.6287 27.3701 16.3642 27.5 15.9675 27.5ZM10.2807 7.09887C8.95822 7.09887 7.76797 7.61864 6.84222 8.52825C4.85847 10.4774 4.85847 13.4661 6.84222 15.4153L15.9675 24.3814L25.0927 15.4153C26.0185 14.5056 26.5475 13.2062 26.5475 11.9068C26.5475 10.6073 26.0185 9.43785 25.0927 8.52825C24.167 7.61864 22.9767 7.09887 21.6542 7.09887C20.3317 7.09887 19.1415 7.61864 18.2157 8.52825L16.8932 9.69774C16.6287 9.95763 16.3642 10.0876 15.9675 10.0876C15.5707 10.0876 15.3062 9.95763 15.0417 9.69774L13.8515 8.52825C12.7935 7.61864 11.6032 7.09887 10.2807 7.09887Z' fill='#272827' />

	</Svg>
)
