import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { TypeUser } from '../../../Functions/User/types/TypeUser'
import { TypeTeasers } from '../../../Functions/Teaser/types/teasers'
import { firstDateInPlatform } from './firstDateInPlataform'
import { TypeViewTeasers001 } from '../../ViewTeaser001/types/TypeViewTeasers001'

// const DATE_START_MEETING_VALIDATION = 20231004
// const dateStartMeetingValidation = myDateToDate(DATE_START_MEETING_VALIDATION)

export const firstMeeting = ({ user, viewTeasers }: { user: TypeUser, viewTeasers: { [id: string]: TypeViewTeasers001 } }) => {
	const teasersFiltered: Date[] = []
	Object.values(viewTeasers).forEach(teaser => {
		if (teaser?.validation?.dateMeet) teasersFiltered.push(helpersDate.convertAllToDate(teaser.validation.dateMeet))
	})

	const datesOrderedAsc = teasersFiltered.sort((dateA, dateB) => dateA.getTime() - dateB.getTime())
	const firstDate = datesOrderedAsc[0] ? helpersDate.convertAllToTimestamp(datesOrderedAsc[0]) : null
	const userDate = helpersDate.convertAllToTimestamp(firstDateInPlatform(user))
	const differenceInDays = firstDate && userDate ? helpersDate.howManyDays(firstDate, userDate) : 0
	const dateFormated = firstDate ? helpersDate.toString(firstDate) : ''
	return {
		date:
		{
			value: firstDate,
			formatted: dateFormated
		},
		days: differenceInDays,
		label: ''
	}
}
