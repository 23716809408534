import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ClockOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16 5.61871C10.2666 5.61871 5.61871 10.2666 5.61871 16C5.61871 21.7334 10.2666 26.3813 16 26.3813C21.7334 26.3813 26.3813 21.7334 26.3813 16C26.3813 10.2666 21.7334 5.61871 16 5.61871ZM3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M15.9998 7.67627C16.7229 7.67627 17.3091 8.26249 17.3091 8.98562V15.1908L21.2616 17.167C21.9084 17.4904 22.1706 18.2769 21.8472 18.9237C21.5238 19.5705 20.7373 19.8327 20.0905 19.5093L15.4142 17.1711C14.9706 16.9493 14.6904 16.496 14.6904 16V8.98562C14.6904 8.26249 15.2766 7.67627 15.9998 7.67627Z' fill='#272827' />
	</Svg>
)
