import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PauseOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16 4.97044C9.58811 4.97044 4.39024 10.1683 4.39024 16.5802C4.39024 22.9921 9.58811 28.19 16 28.19C22.4119 28.19 27.6098 22.9921 27.6098 16.5802C27.6098 10.1683 22.4119 4.97044 16 4.97044ZM2 16.5802C2 8.84821 8.26801 2.5802 16 2.5802C23.732 2.5802 30 8.84821 30 16.5802C30 24.3122 23.732 30.5802 16 30.5802C8.26801 30.5802 2 24.3122 2 16.5802Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M13.4393 11.5436C14.0993 11.5436 14.6344 12.0787 14.6344 12.7388V20.4217C14.6344 21.0817 14.0993 21.6168 13.4393 21.6168C12.7792 21.6168 12.2441 21.0817 12.2441 20.4217V12.7388C12.2441 12.0787 12.7792 11.5436 13.4393 11.5436Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M18.5604 11.5436C19.2204 11.5436 19.7555 12.0787 19.7555 12.7388V20.4217C19.7555 21.0817 19.2204 21.6168 18.5604 21.6168C17.9003 21.6168 17.3652 21.0817 17.3652 20.4217V12.7388C17.3652 12.0787 17.9003 11.5436 18.5604 11.5436Z' fill='#272827' />
	</Svg>
)
