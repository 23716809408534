export const TESE_CREDIT_USABILITY_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_USABILITY

	if (!TESE_CREDIT_USABILITY[_code]) {
		return (TESE_CREDIT_USABILITY.u000)
	}

	return TESE_CREDIT_USABILITY[_code]
}


export const TESE_CREDIT_USABILITY = {
	u000: { _id: 'u000', label: 'Sem usabilidade', helper: '' },
	u001: { _id: 'u001', label: 'Pouco útil', helper: '' },
	u002: { _id: 'u002', label: 'Útil', helper: '' },
	u003: { _id: 'u003', label: 'Inútil', helper: '' },
}

export const TESE_CREDIT_USABILITY_VALUES = Object.values(TESE_CREDIT_USABILITY)
export const TESE_CREDIT_USABILITY_KEYS = Object.keys(TESE_CREDIT_USABILITY)
