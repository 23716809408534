import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { fnValuation } from '.'
import { TAGS_SECTOR_ROOT } from 'Constants/TAGS'

export const mergeWithOrigination = (origination: TypeTeaser | undefined) => {
	const valuation: TypeValuation = fnValuation.modelValuation()

	if (origination) {
		valuation.tags = origination.tags ? origination.tags.filter(tag => Object.keys(TAGS_SECTOR_ROOT).includes(tag)) : []//garante que apenas tags de seção estejam no array
		valuation.companyName = origination.nome
		valuation.cnpj = origination.cnpj
		valuation.ebitda = Number(origination.eb1) || null
		valuation.billing = Number(origination.rb1) || null
		valuation.debt = (Number(origination.emprestimos) || 0) + (Number(origination.tributos) || 0) + (Number(origination.fornecedores) || 0) + (Number(origination.outrasdividas) || 0) || null
	}
	return valuation
}
