import { getGovernanceScore } from '../Functions/Teaser/methods/getGovernanceScore'
import { TypeTeaser } from '../Functions/Teaser/types/teaser'

/** @deprecated */
export enum GUARANTEES {
	guarantee1 = 'Imobiliária',
	guarantee2 = 'Recebíveis',
	guarantee3 = 'Recebíveis Imobiliários',
	guarantee4 = 'Fiança de Safra',
	guarantee5 = 'Financiamento para término de obra',
	guarantee6 = 'outros',
}

/** @deprecated */
export const GUARANTEES_DATA: TGuaranteeData = {
	[GUARANTEES.guarantee1]: {
		itemLabel: 'Imobiliária',
		valueLabel: 'Valor de avaliação desses imóveis',
		types: [
			{
				label: 'Casas',
				value: 'casas',
				operation: '',
				multiplicator: () => 0.9,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			},
			{
				label: 'Apartamentos',
				value: 'apartamentos',
				operation: '',
				multiplicator: () => 0.9,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			},
			{
				label: 'Lojas / Salas comerciais',
				value: 'lojasSalasComerciais',
				operation: '',
				multiplicator: () => 0.9,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			},
			{
				label: 'Galpões / fábricas',
				value: 'galpoesFabricas',
				operation: '',
				multiplicator: () => 0.8,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			},
			{
				label: 'Prédios administrativos',
				value: 'prediosAdministrativos',
				operation: '',
				multiplicator: () => 0.8,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			},
			// {
			// 	label: 'Operacional',
			// 	value: 'operacional',
			// 	operation: 'sales and leaseback',
			// 	multiplicator: () =>  0.8,
			// 	multiplicatorValue: 'Valor de avaliação'
			// },
			// {
			// 	label: 'Não Operacional',
			// 	value: 'naoOperacional',
			// 	operation: 'CCB/capital de giro',
			// 	multiplicator: () =>  0.9,
			// 	multiplicatorValue: 'Valor de avaliação'
			// },
			{
				label: 'Terrenos urbanos',
				value: 'terrenos',
				operation: 'CCB/capital de giro',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor de avaliação',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			},
			// {
			// 	label: 'Fazendas',
			// 	value: 'fazendas',
			// 	operation: 'CRA/ venda de ativo',
			// 	multiplicator: () =>  0.7,
			// 	multiplicatorValue: 'Valor de avaliação',
			// 	title: 'Tipo do imóvel',
			// 	titleValue: 'Valor de avaliação desses imóveis'
			// }
			{
				label: 'Fazendas produtivas',
				value: 'fazendasProdutivas',
				operation: 'CRA/ venda de ativo',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor de avaliação',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			},
			{
				label: 'Fazendas não-produtivas',
				value: 'fazendasNaoProdutivas',
				operation: 'CRA/ venda de ativo',
				multiplicator: () => 0.5,
				multiplicatorValue: 'Valor de avaliação',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis'
			}
		]
	},
	[GUARANTEES.guarantee2]: {
		itemLabel: 'Recebíveis',
		valueLabel: 'Valor',
		types: [
			{
				label: 'Contratos públicos',
				value: 'contratosPublicos',
				operation: 'CCB/capital para investimento',
				multiplicator: () => 0.2,
				multiplicatorValue: 'Valor total do contrato',
				description: 'Contratos assinados com orgãos ou empresas públicas',
				title: 'Tipo',
				titleValue: 'Valor total do contrato',
				descriptionValue: 'Qual o valor total a receber por esse contrato (prazo x valor mensal)?'
			},
			{
				label: 'Contratos privados',
				value: 'contratosPrivados',
				operation: 'CCB/capital para investimento',
				multiplicator: () => 0.2,
				multiplicatorValue: 'Valor total do contrato',
				description: 'Contratos assinados com empresas privadas',
				title: 'Tipo',
				titleValue: 'Valor total do contrato',
				descriptionValue: 'Qual o valor total a receber por esse contrato (prazo x valor mensal)?'
			},
			{
				label: 'Duplicatas/ boletos',
				value: 'duplicatasBoletos',
				operation: 'CCB/antecipacao de recebíveis',
				multiplicator: (teaser?: TypeTeaser) => {
					if (teaser) {
						const gov = getGovernanceScore(teaser)
						if (gov === 'A') return 4
						if (gov === 'B') return 3
						if (gov === 'C') return 2
					}
					return 4
				},
				multiplicatorValue: 'Valor mensal',
				description: 'Recebíveis comerciais comuns já emitidos',
				title: 'Tipo',
				titleValue: 'Valor mensal',
				descriptionValue: 'Valor mensal médio das duplicatas e boletos'
			},
			{
				label: 'Cartões de crédito',
				value: 'cartoesDeCredito',
				operation: '',
				multiplicator: () => 5,
				multiplicatorValue: 'Valor mensal de cartão',
				description: 'Tecebíveis de maquininhas de cartão de crédito',
				title: 'Tipo',
				titleValue: 'Valor mensal de cartão',
				descriptionValue: 'Qual é o volume mensal de cartões que passam pelas maquininhas da empresa?'
			},
			// {
			// 	label: 'Não performados',
			// 	value: 'naoPerformados',
			// 	operation: 'CCB/capital para investimento',
			// 	multiplicator: () =>  0.5,
			// 	multiplicatorValue: 'Total de recebíveis NP'
			// }
		]
	},
	[GUARANTEES.guarantee3]: {
		itemLabel: 'Recebíveis Imobiliários',
		valueLabel: 'Valor',
		types: [
			{
				label: 'Contratos de aluguel',
				value: 'contratosDeAluguel',
				operation: 'CRI/capital para investimento',
				multiplicator: () => 60,
				multiplicatorValue: 'Valor mensal do aluguel',
				description: 'Contratos vigentes de aluguel com um ou mais inquilinos',
				title: 'Tipo do recebível imobiliário',
				titleValue: 'Valor mensal do aluguel',
				descriptionValue: 'Qual o valor mensal recebido por este(s) contratos(s) de aluguel?'
			},
			{
				label: 'Imóveis prontos',
				value: 'imoveisProntos',
				operation: 'CRI/capital para investimento',
				multiplicator: () => 1,
				multiplicatorValue: 'Saldo devedor da carteira',
				description: 'Recebíveis de imóveis já entregues',
				title: 'Tipo',
				titleValue: 'Saldo devedor',
				descriptionValue: 'Quanto falta a receber dos imóveis já vendidos e entregues?'
			},
			// {
			// 	label: 'Imóveis na planta',
			// 	value: 'imoveisNaPlanta',
			// 	operation: 'CRI/término de obra',
			// 	multiplicator: () => 0.6,
			// 	multiplicatorValue: 'Saldo devedor da carteira',
			// 	description: 'Recebíveis de imóveis ainda não entregues',
			// 	title: 'Tipo',
			// 	titleValue: 'Saldo devedor',
			// 	descriptionValue: 'Quanto falta a receber dos imóveis já vendidos e ainda não entregues?'
			// },
			// {
			// 	label: 'Obras em andamento',
			// 	value: 'obrasEmAndamento',
			// 	operation: '',
			// 	multiplicator: () =>  0,
			// 	multiplicatorValue: ''
			// }
		]
	},
	[GUARANTEES.guarantee4]: {
		itemLabel: 'Fiança de Safra',
		valueLabel: 'Valor esperado da safra',
		types: [
			// {
			// 	label: 'Todos',
			// 	value: 'fiancaSafraTodos',
			// 	operation: 'CRA',
			// 	multiplicator: () =>  0.9,
			// 	multiplicatorValue: 'Valor esperado da safra'
			// },
			{
				label: 'Algodão',
				value: 'fiancaSafraAlgodao',
				operation: 'CRA',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Arroz',
				value: 'fiancaSafraArroz',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Café',
				value: 'fiancaSafraCafe',
				operation: 'CRA',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Especiarias',
				value: 'fiancaSafraEspeciarias',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Feijão',
				value: 'fiancaSafraFeijao',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Hortifruti',
				value: 'fiancaSafraHortifruti',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Maricultura',
				value: 'fiancaSafraMaricultura',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Milho',
				value: 'fiancaSafraMilho',
				operation: 'CRA',
				multiplicator: () => 0.9,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Pecuária de Corte',
				value: 'fiancaSafraPecuaria',
				operation: 'CRA',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Pecuária de Leite',
				value: 'fiancaSafraPecuariaLeite',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Piscicultura',
				value: 'fiancaSafraPisicultura',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Sucroalcooleiro',
				value: 'fiancaSafraSucroalcoleiro',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Soja',
				value: 'fiancaSafraSoja',
				operation: 'CRA',
				multiplicator: () => 0.9,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Trigo',
				value: 'fiancaSafraTrigo',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Floricultura',
				value: 'fiancaSafraFloricultura',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra'
			},
			{
				label: 'Outros',
				value: 'outros',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => (getGovernanceScore(teaser) === 'C') ? 0 : 0.7,
				multiplicatorValue: 'Valor esperado da safra'
			}
		]
	},
	[GUARANTEES.guarantee5]: {
		types: [
			{
				label: 'Comercial',
				value: 'comercial',
				operation: '',
				multiplicator: () => 1,
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Residencial',
				value: 'residencial',
				operation: '',
				multiplicator: () => 1,
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Loteamento',
				value: 'loteamento',
				operation: '',
				multiplicator: () => 1,
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Outro',
				value: 'outros',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => (getGovernanceScore(teaser) === 'C') ? 0 : 1,
				multiplicatorValue: 'Valor'
			},
		]
	},
	[GUARANTEES.guarantee6]: {
		itemLabel: 'Outros',
		valueLabel: 'Valor',
		types: [
			{
				label: 'Estoques',
				value: 'estoques',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => (getGovernanceScore(teaser) === 'C') ? 0 : 0.5,
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Ativos legais',
				value: 'ativosLegais',
				operation: '',
				multiplicator: teaser => (getGovernanceScore(teaser) === 'C') ? 0 : 0.5,
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Máquinas e equipamentos',
				value: 'maquinasEquipamentos',
				operation: '',
				multiplicator: teaser => (getGovernanceScore(teaser) === 'C') ? 0 : 0.5,
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Ações/equity do projeto',
				value: 'acoesEquityDoProjeto',
				operation: '',
				multiplicator: teaser => {
					if (teaser) {
						const gov = getGovernanceScore(teaser)
						if (gov === 'A') return 0.6
						if (gov === 'B') return 0.3
						if (gov === 'C') return 0
					}
					return 0.25
				},
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Veículos/aeronaves/embarcações',
				value: 'veiculos',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => (getGovernanceScore(teaser) === 'C') ? 0 : 0.5,
				multiplicatorValue: 'Valor'
			},
			{
				label: 'Outros',
				value: 'outros',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => (getGovernanceScore(teaser) === 'C') ? 0 : 0.5,
				multiplicatorValue: 'Valor'
			}
		]
	}
}

export type TGuaranteesTypes = {
	label: string
	value: string
	operation: string
	multiplicator: (teaser: TypeTeaser) => number
	multiplicatorValue: string
	description?: string
	title?: string
	titleValue?: string
	descriptionValue?: string
	disabled?: boolean
	descriptionNew?: string
	icon?: string
	helperText?: string
	labelInput?: string
}

export type TGuaranteeData = {
	[name in GUARANTEES]: {
		itemLabel?: string;
		valueLabel?: string;
		types: TGuaranteesTypes[]
	}
}

export const GUARANTEES_DETAILS: { [key: string]: { subType: { [subKey: string]: TGuaranteesTypes }, garantiaDerivada?: boolean, value?: string, labelOrigination?: string, icon?: string, description?: string } } = {
	Imobiliária: {
		value: 'Imobiliária',
		description: 'Imóveis com finalidade residencial, comercial ou industrial que compõem o patrimônio da empresa ou dos seus sócios. Somente ativos imobiliários prontos. Não cadastrar ativos imobiliários em construção. ',
		labelOrigination: 'Imobiliário',
		icon: 'maps_home_work',
		subType: {
			casas: {
				label: 'Casas e Apartamentos',
				value: 'casas',
				operation: '',
				multiplicator: () => 0.9,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: false,
				descriptionNew: 'Imóveis utilizados para fins residenciais que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'home_work',
				helperText: 'Considere o somatório do valor de mercado de todas as casas e apartamentos da empresa e seus sócios.',
				labelInput: 'Valor de todas as casas e apartamentos'
			},
			apartamentos: {
				label: 'Apartamentos',
				value: 'apartamentos',
				operation: '',
				multiplicator: () => 0.9,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: true,
			},
			'lojasSalasComerciais': {
				label: 'Lojas / Salas comerciais',
				value: 'lojasSalasComerciais',
				operation: '',
				multiplicator: () => 0.9,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: false,
				descriptionNew: 'Imóveis utilizados para fins comerciais que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'storefront',
				labelInput: 'Valor de todas as lojas e salas comerciais',
				helperText: 'Considere a somatória do valor de mercado de todas as lojas e salas comerciais da empresa e seus sócios.'
			},
			'galpoesFabricas': {
				label: 'Galpões / fábricas',
				value: 'galpoesFabricas',
				operation: '',
				multiplicator: () => 0.8,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: false,
				descriptionNew: 'Imóveis utilizados para fins industriais que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'warehouse',
				labelInput: 'Valor de todos os galpões e fábricas',
				helperText: 'Considere a somatória do valor de mercado de todos os galpões e fábricas da empresa e seus sócios.'
			},
			'prediosAdministrativos': {
				label: 'Prédios administrativos',
				value: 'prediosAdministrativos',
				operation: '',
				multiplicator: () => 0.8,
				multiplicatorValue: '',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: false,
				descriptionNew: 'Imóveis / prédios inteiros utilizados para fins comerciais que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'business',
				labelInput: 'Valor de todos os prédios administrativos',
				helperText: 'Considere a somatória do valor de mercado de todos os prédios administrativos da empresa e seus sócios.'
			},
			'terrenos': {
				label: 'Terrenos urbanos',
				value: 'terrenos',
				operation: 'CCB/capital de giro',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor de avaliação',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: false,
				descriptionNew: 'Imóveis / áreas não construídas dentro de cidades que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'pin_drop',
				labelInput: 'Valor de todos os terrenos urbanos',
				helperText: 'Considere a somatória do valor de mercado de todos os terrenos urbanos da empresa e seus sócios.'
			},
			'fazendasProdutivas': {
				label: 'Fazendas produtivas',
				value: 'fazendasProdutivas',
				operation: 'CRA/ venda de ativo',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor de avaliação',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: false,
				descriptionNew: 'Imóveis rurais com produção agrícola que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'cabin',
				labelInput: 'Valor de todas as fazendas produtivas',
				helperText: 'Considere a somatória do valor de mercado de todas as fazendas produtivas da empresa e seus sócios.'
			},
			'fazendasNaoProdutivas': {
				label: 'Fazendas não produtivas',
				value: 'fazendasNaoProdutivas',
				operation: 'CRA/ venda de ativo',
				multiplicator: () => 0.5,
				multiplicatorValue: 'Valor de avaliação',
				title: 'Tipo do imóvel',
				titleValue: 'Valor de avaliação desses imóveis',
				disabled: false,
				descriptionNew: 'Imóveis rurais sem produção agrícola, e/ou áreas de pastagem utilizadas na pecuária ou de outras formas, que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'gite',
				labelInput: 'Valor todas as fazendas não produtivas',
				helperText: 'Considere a somatória do valor de mercado de todas as fazendas não produtivas da empresa e seus sócios.'
			}
		}
	},
	Recebíveis: { // recebíveis comerciais
		value: 'Recebíveis',
		description: 'Valores recebidos provenientes de transações comerciais, como vendas de produtos ou prestação de serviços. Representam o montante de dinheiro que a empresa espera receber de seus clientes como pagamento por suas atividades comerciais.',
		labelOrigination: 'Recebíveis Comerciais',
		icon: 'receipt_long',
		subType: {
			contratosPublicos: {
				label: 'Contratos públicos',
				value: 'contratosPublicos',
				operation: 'CCB/capital para investimento',
				multiplicator: () => 0.2,
				multiplicatorValue: 'Valor total do contrato',
				descriptionNew: 'Contratos de prestação de serviço ou venda de produtos com o poder público.',
				icon: 'article',
				labelInput: 'Valor a receber de todos os contratos públicos',
				helperText: 'Considere a somatória dos valores previstos para recebimento até a finalização de todos os contratos públicos vigentes.'
			},
			contratosPrivados: {
				label: 'Contratos privados',
				value: 'contratosPrivados',
				operation: 'CCB/capital para investimento',
				multiplicator: () => 0.2,
				multiplicatorValue: 'Valor total do contrato',
				descriptionNew: 'Contratos de prestação de serviço ou venda de produtos com indivíduos e empresas privadas. Não cadastrar recebíveis imobiliários (contrato de aluguel ou venda de imóveis prontos) nesta categoria.',
				icon: 'feed',
				labelInput: 'Valor a receber de todos os contratos privados',
				helperText: 'Considere a somatória dos valores previstos para recebimento até a finalização de todos os contratos privados vigentes.'
			},
			duplicatasBoletos: {
				label: 'Duplicatas e boletos',
				value: 'duplicatasBoletos',
				operation: 'CCB/antecipacao de recebíveis',
				multiplicator: (teaser?: TypeTeaser) => {
					if (teaser) {
						const gov = getGovernanceScore(teaser)
						if (gov === 'A') return 4
						if (gov === 'B') return 3
						if (gov === 'C') return 2
					}
					return 4
				},
				labelInput: 'Valor mensal das duplicatas e boletos',
				multiplicatorValue: 'Valor mensal',
				descriptionNew: 'Títulos comerciais referentes a venda de produtos ou prestação de serviços. Não cadastrar recebíveis imobiliários (contrato de aluguel ou venda de imóveis prontos) nesta categoria.  ',
				icon: 'receipt_long',
				helperText: 'Considere o valor médio mensal de duplicatas e boletos emitidos pela empresa.'
			},

			cartoesDeCredito: {
				label: 'Cartões de crédito',
				value: 'cartoesDeCredito',
				operation: '',
				multiplicator: () => 5,
				multiplicatorValue: 'Valor mensal de cartão',
				description: 'Tecebíveis de maquininhas de cartão de crédito',
				title: 'Tipo',
				titleValue: 'Valor mensal de cartão',
				descriptionValue: 'Qual é o volume mensal de cartões que passam pelas maquininhas da empresa?',
				descriptionNew: 'Informe os valores a receber por vendas feitas com cartão. É o dinheiro que uma empresa espera receber das transações realizadas por seus clientes usando cartões de crédito.',
				icon: 'credit_card',
				labelInput: 'Valor mensal dos recebíveis de cartões de crédito',
				helperText: 'Considere o valor mensal de recebíveis de cartão de crédito, incluindo os que por ventura estejam sendo antecipados (descontados).'
			},

			// naoPerformados: {
			// 	label: 'Não performados',
			// 	value: 'naoPerformados',
			// 	operation: 'CCB/capital para investimento',
			// 	multiplicator: () =>  0.5,
			// 	multiplicatorValue: 'Total de recebíveis NP'
			// }
		}
	},
	'Recebíveis Imobiliários': {
		value: 'Recebíveis Imobiliários',
		description: 'Valores recebidos provenientes de transações envolvendo aluguéis e vendas de imóveis.',
		labelOrigination: 'Recebíveis Imobiliários',
		icon: 'real_estate_agent',
		subType: {
			contratosDeAluguel: {
				label: 'Contratos de aluguel',
				value: 'contratosDeAluguel',
				labelInput: 'Valor mensal dos recebíveis de contratos de aluguel',
				operation: 'CRI/capital para investimento',
				multiplicator: () => 60,
				multiplicatorValue: 'Valor mensal do aluguel',
				descriptionNew: 'Recebíveis provenientes de contratos de locação de bens imóveis residenciais, comerciais ou industriais da empresa ou dos seus sócios.',
				icon: 'real_estate_agent',
				helperText: 'Considere a somatória do valor recebido mensalmente proveniente dos contratos de alugueis.'
			},
			imoveisProntos: {
				label: 'Imóveis prontos',
				value: 'imoveisProntos',
				operation: 'CRI/capital para investimento',
				multiplicator: () => 1,
				labelInput: 'Valor mensal dos recebíveis de imóveis prontos',
				multiplicatorValue: 'Saldo devedor da carteira',
				descriptionNew: 'Recebíveis provenientes de contratos de venda de imóveis prontos residenciais, comerciais ou industriais da empresa ou dos seus sócios.',
				icon: 'house',
				helperText: 'Considere o valor recebido mensalmente proveniente da venda de imóveis prontos financiados.'
			},
			// imoveisNaPlanta: {
			// 	label: 'Imóveis na planta',
			// 	value: 'imoveisNaPlanta',
			// 	operation: 'CRI/término de obra',
			// 	multiplicator: () => 0.8,
			// 	multiplicatorValue: 'Saldo devedor da carteira'
			// },
		}
	},
	'Fiança de Safra': {
		value: 'Fiança de Safra',
		description: 'Produção agrícola de alguma cultura que já esteja plantada ou da próxima safra.',
		labelOrigination: 'Fiança de Safra',
		icon: 'grass',
		subType: {
			// fiancaSafraTodos: {
			// 	label: 'Todos',
			// 	value: 'fiancaSafraTodos',
			// 	operation: 'CRA',
			// 	multiplicator: () =>  0.9,
			// 	multiplicatorValue: 'Valor esperado da safra'
			// },
			fiancaSafraAlgodao: {
				label: 'Algodão',
				value: 'fiancaSafraAlgodao',
				operation: 'CRA',
				labelInput: 'Valor estimado para safra',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Estimativa da produção da safra já plantada ou da próxima safra.',
				icon: 'home_work',
				helperText: 'Considere o valor de venda esperado para toda a safra já plantada ou da próxima safra.'
			},
			fiancaSafraArroz: {
				label: 'Arroz',
				value: 'fiancaSafraArroz',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Informe o valor da fiança de safra. A safra fica estocada em algum galpão? se sim, lembre-se de cadastrar este galpão em  Imobiliários - Galpões e Fábricas.',
				icon: 'warehouse',
				disabled: true
			},
			fiancaSafraArrozEFeijao: {
				label: 'Arroz / Feijão',
				value: 'fiancaSafraArrozEFeijao',
				operation: 'CRA',
				multiplicator: () => 0.7,
				labelInput: 'Valor estimado para safra',
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Estimativa da produção da safra já plantada ou da próxima safra.',
				icon: 'warehouse',
				helperText: 'Considere o valor de venda esperado para toda a safra já plantada ou da próxima safra.'
			},
			fiancaSafraCafe: {
				label: 'Café',
				value: 'fiancaSafraCafe',
				operation: 'CRA',
				labelInput: 'Valor estimado para safra',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Estimativa da produção da safra já plantada ou da próxima safra.',
				icon: 'business',
				helperText: 'Considere o valor de venda esperado para toda a safra já plantada ou da próxima safra.'
			},
			fiancaSafraEspeciarias: {
				label: 'Especiarias',
				value: 'fiancaSafraEspeciarias',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			fiancaSafraFeijao: {
				label: 'Feijão',
				value: 'fiancaSafraFeijao',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			fiancaSafraHortifruti: {
				label: 'Hortifruti',
				value: 'fiancaSafraHortifruti',
				operation: 'CRA',
				labelInput: 'Valor estimado para safra',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Estimativa da produção da safra já plantada ou da próxima safra.',
				icon: 'pin_drop',
				helperText: 'Considere o valor de venda esperado para toda a safra já plantada ou da próxima safra.'
			},
			fiancaSafraMaricultura: {
				label: 'Maricultura',
				value: 'fiancaSafraMaricultura',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			fiancaSafraMilho: {
				label: 'Milho',
				value: 'fiancaSafraMilho',
				operation: 'CRA',
				multiplicator: () => 0.9,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Estimativa da produção da safra já plantada ou da próxima safra.',
				icon: 'home_work',
				labelInput: 'Valor estimado para safra',
				helperText: 'Considere o valor de venda esperado para toda a safra já plantada ou da próxima safra.'
			},
			fiancaSafraPecuaria: {
				label: 'Pecuária de Corte',
				value: 'fiancaSafraPecuaria',
				operation: 'CRA',
				multiplicator: () => 0.8,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Informe o valor da fiança de safra. A safra fica estocada em algum galpão? se sim, lembre-se de cadastrar este galpão em  Imobiliários - Galpões e Fábricas.',
				icon: 'cabin',
				disabled: true
			},
			pecuariaDeLeite: {
				label: 'Pecuária de Leite',
				value: 'pecuariaDeLeite',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			fiancaSafraPisicultura: {
				label: 'Piscicultura',
				value: 'fiancaSafraPisicultura',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			fiancaSafraSucroalcoleiro: {
				label: 'Sucroalcoleiro',
				value: 'fiancaSafraSucroalcoleiro',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			fiancaSafraSoja: {
				label: 'Soja',
				value: 'fiancaSafraSoja',
				operation: 'CRA',
				multiplicator: () => 0.9,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Estimativa da produção da safra já plantada ou da próxima safra.',
				icon: 'storefront',
				labelInput: 'Valor estimado para safra',
				helperText: 'Considere o valor de venda esperado para toda a safra já plantada ou da próxima safra.'
			},
			fiancaSafraTrigo: {
				label: 'Trigo',
				value: 'fiancaSafraTrigo',
				operation: 'CRA',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			fiancaSafraFloricultura: {
				label: 'Floricultura',
				value: 'fiancaSafraFloricultura',
				operation: 'CRA',
				multiplicator: () => 0.6,
				multiplicatorValue: 'Valor esperado da safra',
				disabled: true
			},
			outros: {
				label: 'Outros',
				value: 'outros',
				labelInput: 'Valor estimado para safra',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => getGovernanceScore(teaser) === 'C' ? 0 : 0.7,
				multiplicatorValue: 'Valor esperado da safra',
				descriptionNew: 'Estimativa da produção da safra já plantada ou da próxima safra de outras culturas. Lembre-se de descrever a cultura em “Descrição do patrimônio” abaixo.',
				icon: 'gite',
				helperText: 'Considere o valor de venda esperado para toda a safra já plantada ou da próxima safra.'
			}
		}
	},
	'Financiamento para término de obra': {
		subType: {
			comercial: {
				label: 'Comercial',
				value: 'comercial',
				operation: '',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor'
			},
			residencial: {
				label: 'Residencial',
				value: 'residencial',
				operation: '',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor'
			},
			loteamento: {
				label: 'Loteamento',
				value: 'loteamento',
				operation: '',
				multiplicator: () => 0.7,
				multiplicatorValue: 'Valor'
			},
			outros: {
				label: 'Outro',
				value: 'outros',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => getGovernanceScore(teaser) === 'C' ? 0 : 0.7,
				multiplicatorValue: 'Valor'
			},
		}
	},
	outros: {
		value: 'outros',
		description: 'Outros ativos que tenham valor econômico/financeiro que compõem o patrimônio da empresa ou dos seus sócios.',
		labelOrigination: 'Outros',
		icon: 'precision_manufacturing',
		subType: {
			estoques: {
				label: 'Estoques',
				value: 'estoques',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => getGovernanceScore(teaser) === 'C' ? 0 : 0.5,
				multiplicatorValue: 'Valor',
				descriptionNew: 'Matérias primas, materiais e produtos acabados que compõem o estoque da empresa.',
				icon: 'inventory_2',
				labelInput: 'Valor de mercado dos estoques',
				helperText: 'Considere o valor total dos estoques desta empresa'
			},
			ativosLegais: {
				label: 'Ativos legais',
				value: 'ativosLegais',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => getGovernanceScore(teaser) === 'C' ? 0 : 0.5,
				multiplicatorValue: 'Valor',
				descriptionNew: 'Direitos e ativos legais judicializados.',
				icon: 'request_page',
				labelInput: 'Valor atualizado das ações judiciais',
				helperText: 'Considere a somatória dos valores atualizados das ações judiciais (ativos legais).'
			},
			maquinasEquipamentos: {
				label: 'Máquinas e equipamentos',
				value: 'maquinasEquipamentos',
				operation: '',
				labelInput: 'Valor de mercado de todas as máquinas e equipamentos',
				multiplicator: (teaser: TypeTeaser) => getGovernanceScore(teaser) === 'C' ? 0 : 0.5,
				multiplicatorValue: 'Valor',
				descriptionNew: 'Maquinas e equipamentos de propriedade da empresa que são utilizados na produção de bens ou prestação de serviços.',
				icon: 'precision_manufacturing',
				helperText: 'Considere a somatória do valor de mercado (incluindo estimativa de depreciação) de todas as máquinas e equipamentos.'
			},
			acoesEquityDoProjeto: {
				label: 'Ações/equity do projeto',
				value: 'acoesEquityDoProjeto',
				operation: '',
				multiplicator: (teaser?: TypeTeaser) => {
					if (teaser) {
						const gov = getGovernanceScore(teaser)
						if (gov === 'A') return 0.6
						if (gov === 'B') return 0.3
						if (gov === 'C') return 0
					}
					return 0.25
				},
				multiplicatorValue: 'Valor',
				labelInput: 'Valor justo de mercado das ações / equity do projeto ',
				descriptionNew: 'Informe a estimativa do valor justo de mercado de participações societárias que compõem o patrimônio da empresa ou dos seus sócios.',
				icon: 'swap_horizontal_circle',
				helperText: 'Considere a estimativa do valor justo de mercado atribuído à participação em empresas e/ou projetos.'
			},
			veiculos: {
				label: 'Veículos',
				value: 'veiculos',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => getGovernanceScore(teaser) === 'C' ? 0 : 0.5,
				multiplicatorValue: 'Valor',
				descriptionNew: 'Veículos terrestres, aeronaves e embarcações, considerando a utilização empresarial (frota) ou pessoal, que sejam de propriedade da empresa e dos sócios.',
				icon: 'directions_car_filled',
				labelInput: 'Valor de mercado de todos os veículos, aeronaves e embarcações',
				helperText: 'Insira o valor total restante do contrato, considerando todo o período restante.'
			},
			outros: {
				label: 'Outros',
				value: 'outros',
				operation: '',
				multiplicator: (teaser: TypeTeaser) => getGovernanceScore(teaser) === 'C' ? 0 : 0.5,
				multiplicatorValue: 'Valor',
				descriptionNew: 'Quaisquer outros ativos que tenham valor econômico/financeiro. Lembre-se de descrever estes ativos em “Descrição do patrimônio” abaixo.',
				description: 'Quaisquer outros ativos que tenham valor econômico/financeiro. Lembre-se de descrever estes ativos em “Descrição do patrimônio” abaixo.',
				icon: 'add',
				labelInput: 'Valor de mercado de outros ativos',
				helperText: 'Insira o valor total restante do contrato, considerando todo o período restante.'
			}
		}
	},
	// todo: chamar bruno para revisar multiplicador
	'VGV da Obra': {
		subType: {
			'VGV da Obra': {
				label: 'VGV da Obra',
				value: 'VGV da Obra',
				operation: '',
				multiplicator: () => 1,
				multiplicatorValue: 'Valor'
			}
		},
		garantiaDerivada: true, //se a pessoa marcar um tipo de operacao termino de obra
	},
	'Recebíveis da Obra': {
		subType: {
			'Recebíveis da Obra': {
				label: 'Recebíveis da Obra',
				value: 'Recebíveis da Obra',
				operation: '',
				multiplicator: () => 1,
				multiplicatorValue: 'Valor'
			}
		},
		garantiaDerivada: true, //se a pessoa marcar um tipo de operacao termino de obra
	}
} as const

export const GUARANTEES_GET = (code: string) => {
	const _code = code as keyof typeof GUARANTEES_DETAILS

	if (!GUARANTEES_DETAILS[_code]) {
		return ({
			label: '',
			value: '',
			operation: '',
			multiplicator: () => 1,
			multiplicatorValue: '',
			description: '',
			title: '',
			titleValue: '',
			descriptionValue: '',
		})
	}

	return GUARANTEES_DETAILS[_code]
}
