import { BORDER_CIRCULAR, BORDER_LG, BORDER_MD, BORDER_NONE, BORDER_SM, BORDER_WEIGHT_BOLD, BORDER_WEIGHT_NONE, BORDER_WEIGHT_THIN, LG, MD, SM } from '../../Tokens'
import { IBorders } from '../IBorders'

export const borders: IBorders = {
	sizes: {
		none: {
			value: BORDER_NONE
		},
		[SM]: {
			value: BORDER_SM
		},
		[MD]: {
			value: BORDER_MD
		},
		[LG]: {
			value: BORDER_LG
		},
		circular: {
			value: BORDER_CIRCULAR
		}
	},
	weights: {
		none: {
			value: BORDER_WEIGHT_NONE
		},
		thin: {
			value: BORDER_WEIGHT_THIN
		},
		bold: {
			value: BORDER_WEIGHT_BOLD
		},
	}
}
