import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const TargetOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16 4.82014C9.82554 4.82014 4.82014 9.82554 4.82014 16C4.82014 22.1745 9.82554 27.1799 16 27.1799C22.1745 27.1799 27.1799 22.1745 27.1799 16C27.1799 9.82554 22.1745 4.82014 16 4.82014ZM2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M22.1445 15.9999C22.1445 15.2212 22.7758 14.5898 23.5546 14.5898H28.5906C29.3693 14.5898 30.0006 15.2212 30.0006 15.9999C30.0006 16.7787 29.3693 17.41 28.5906 17.41H23.5546C22.7758 17.41 22.1445 16.7787 22.1445 15.9999Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M2 15.9999C2 15.2212 2.63131 14.5898 3.41007 14.5898H8.44604C9.2248 14.5898 9.85612 15.2212 9.85612 15.9999C9.85612 16.7787 9.2248 17.41 8.44604 17.41H3.41007C2.63131 17.41 2 16.7787 2 15.9999Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M15.9999 2C16.7787 2 17.41 2.63131 17.41 3.41007V8.44604C17.41 9.2248 16.7787 9.85612 15.9999 9.85612C15.2212 9.85612 14.5898 9.2248 14.5898 8.44604V3.41007C14.5898 2.63131 15.2212 2 15.9999 2Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M15.9999 22.144C16.7787 22.144 17.41 22.7754 17.41 23.5541V28.5901C17.41 29.3688 16.7787 30.0002 15.9999 30.0002C15.2212 30.0002 14.5898 29.3688 14.5898 28.5901V23.5541C14.5898 22.7754 15.2212 22.144 15.9999 22.144Z' fill='#272827' />
	</Svg>
)
