import { clone } from '../../../Utils/clone'
import { TypeTeaser } from '../../types/teaser'

//Tipagem automática pelo retorno da função ATENÇÃO COM ESSE TIPO DE TIPAGEM
export type TGetAuthorship = ReturnType<typeof getAuthorship>

export const getAuthorship = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const result = {
		uid: cloneTeaser.uid,
		owner: cloneTeaser.uid,
		coAuthor: cloneTeaser.uidCoCreator,
		fromOffice: cloneTeaser.fromOffice,
		isFromStark: cloneTeaser.fromOffice ? false : true,
		analystEmail: cloneTeaser.analystEmail,
		isCorporative: cloneTeaser.uid === cloneTeaser.fromOffice ? true : false,
		uidCreator: cloneTeaser.uidCoCreator || cloneTeaser.uid,
		uidFatherCreator: cloneTeaser.uidCoCreator ? cloneTeaser.uid : (cloneTeaser.fromOffice ? cloneTeaser.fromOffice : null), // no caso de ser null é da Stark
	}
	return result
}
