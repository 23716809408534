import { startModalDialog } from '../../../Components/Modal'
import { serviceUserResetPass } from '../../../Services/user/serviceUserResetPass'

// TODO: REFAZER, UMA FUNCTION NAO PODE DEPENDER DO REACT, NEM SER TSX
export const resetPass = (email: string) => {
	serviceUserResetPass(email)
	// @ts-ignore
	startModalDialog({
		title: 'Redefinição de senha',
		body: () => <>Um e- mail foi enviado para você.Acesse o link enviado para poder alterar sua senha </>,
		button: 'Ok',
		color: 'success',
		onClick: (close: () => void) => close()
	})
}
