type TypeTags = {
	[key: string]: {
		label: string,
		cnae: string[],
		tags?: string[],
		tagRoot?: string[],
		icon?: string,
		img?: string,
		liquid?: number
	}
}

export const TAGS_SECTOR_ROOT: TypeTags = {
	t000: {
		label: 'Multisetorial Generalista',
		cnae: [''],
		tags: ['t000'],
		icon: 'apps',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fgeneralista.jpg?alt=media&token=ee0b31fa-ba66-44bd-977a-5043965f944b'
	},
	t001: {
		label: 'Agro', cnae: ['01', '02', '03'],
		tags: ['t002', 't091', 't106', 't160', 't161', 't162', 't163'],
		icon: 'agriculture',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fagro.jpg?alt=media&token=e4f3afe6-79fa-4861-be03-8ddf25a4d068'
	},
	t047: {
		label: 'Mineração', cnae: ['05', '06', '07', '08', '09'],
		tags: ['t027'],
		icon: 'diamond',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fmineracao.jpg?alt=media&token=6670461e-c78c-484e-9085-65a1bd919f14'
	},
	t025: {
		label: 'Indústrias', cnae: ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33'],
		tags: ['t005', 't006', 't009', 't020', 't023', 't029', 't033', 't036', 't042', 't044', 't098', 't116', 't117', 't130', 't173', 't173', 't004', 't096', 't112', 't171', 't113', 't014', 't010', 't022', 't097', 't131', 't164', 't026', 't092', 't046', 't021', 't031', 't030', 't127', 't168'],
		icon: 'factory',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Findustrias.jpg?alt=media&token=36a70edb-2cc8-46f0-b673-3f163262661e'
	},
	t048: {
		label: 'Energia Elétrica e Gás', cnae: ['35'],
		tags: ['t084', 't102', 't103', 't129'],
		icon: 'offline_bolt',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fenergia-eletrica-e-gas.jpg?alt=media&token=b43069a7-d9be-4c59-822b-b7662749327b'
	},
	t049: {
		label: 'Água e Esgoto', cnae: ['36', '37'],
		tags: ['t086', 't087', 't111'],
		icon: 'water_drop',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fagua-e-esgoto.jpg?alt=media&token=01e578cd-1d31-42f2-8e5c-4bfa651d8c1e'
	},
	t050: {
		label: 'Gestão de Resíduos', cnae: ['38', '39'],
		tags: ['t122'],
		icon: 'delete',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fgestao-de-residuos.jpg?alt=media&token=2e10fb1e-25ca-4fc5-ad97-df5936ccf6d0'
	},
	t051: {
		label: 'Construção e Infra-Estrutura', cnae: ['41', '42', '43'],
		tags: ['t085'],
		icon: 'maps_home_work',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fconstrucao-e-infra-estrutura.jpg?alt=media&token=192b7889-4e5d-49c7-aba9-3709777e557c'
	},
	t052: {
		label: 'Comércio', cnae: ['45', '46', '47'],
		tags: ['t094', 't114', 't115', 't120', 't145', 't146', 't147', 't148', 't149', 't150', 't151', 't152', 't153', 't154', 't167', 't174', 't174', 't174', 't174', 't174', 't174', 't174', 't175', 't041', 't090', 't095', 't108', 't124', 't128', 't132', 't133', 't134', 't135', 't136', 't137', 't138', 't139', 't140', 't141', 't142', 't143', 't144', 't176', 't177'],
		icon: 'shopping_cart',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fcomercio.jpg?alt=media&token=1fa35e23-3dfe-41e1-8088-44e62d2a8ba7'
	},
	t053: {
		label: 'Transporte e Logística', cnae: ['49', '50', '51', '52', '53'],
		tags: ['t076', 't105', 't073', 't074', 't075', 't121'],
		icon: 'local_shipping',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Ftransporte-e-logistica.jpg?alt=media&token=6b4d6e63-4b6e-43db-ac9d-7efb28a087b7'
	},
	t054: {
		label: 'Hotéis e Alojamentos', cnae: ['55'],
		tags: [],
		icon: 'hotel',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fhoteis-e-alojamentos.jpg?alt=media&token=22fe58c3-deb7-496c-a0eb-bed849905413'
	},
	t055: {
		label: 'Alimentação - Bares e Restaurantes', cnae: ['56'],
		tags: ['t078', 't099', 't125'],
		icon: 'room_service',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Falimentac%CC%A7a%CC%83o-e-bares.jpg?alt=media&token=89357875-61d1-4860-83c8-9fe6e71ec100'
	},
	t056: {
		label: 'Comunicação', cnae: ['58', '59', '60'],
		tags: [],
		icon: 'record_voice_over',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fcomunicacao.jpg?alt=media&token=3fc6dfe3-8984-4e21-8370-440a5c495f9a'
	},
	t043: {
		label: 'Telecomunicações', cnae: ['61'],
		tags: ['t035'],
		icon: 'headphones',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Ftelecomunicacoes.jpg?alt=media&token=6769af8f-f03c-4055-bdb1-7403628de75e',
		liquid: 1
	},
	t058: {
		label: 'Tecnologia', cnae: ['62', '63.1'],
		tags: ['t178'],
		icon: 'laptop',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Ftecnologia.jpg?alt=media&token=57122734-4397-4ca8-b128-b7fe8f79fee8'
	},
	t059: {
		label: 'Informação', cnae: ['63.9'],
		tags: [],
		icon: '3p',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Finformacao.jpg?alt=media&token=0200e80f-d920-4486-8ca5-81643a874207'
	},
	t060: {
		label: 'Atividades Financeiras', cnae: ['64', '65', '66'],
		tags: ['t079', 't080', 't170', 't032', 't034', 't040', 't081', 't156', 't157'],
		icon: 'account_balance_wallet',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fatividades-financeiras.jpg?alt=media&token=05ae205b-c95d-48af-925b-ee7c3f48ca26'
	},
	t061: {
		label: 'Atividades Imobiliárias (Real Estate)', cnae: ['68'],
		tags: [],
		icon: 'business',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Freal-estate.jpg?alt=media&token=7fd1f812-9466-464c-a6eb-ba9e9a78789d'
	},
	t062: {
		label: 'Serviços Prof., Científicos e Técnicos',
		cnae: ['69', '70', '71', '72', '73', '74', '75'],
		tags: ['t093', 't119', 't088', 't123', 't172', 't038'],
		icon: 'work',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fservicos-prof-cientificos-e-tecnicos.jpg?alt=media&token=7e17dcca-dc80-4c04-a01a-b7d162443d71'
	},
	t063: {
		label: 'Serviços Adm. e Complementares', cnae: ['77', '78', '79', '80', '81', '82'],
		tags: ['t104', 't155', 't165', 't166', 't169', 't039', 't072'],
		icon: 'store',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fservicos-adm-e-complementares.jpg?alt=media&token=52130c84-4ed0-4b7a-a796-2c493946ad56'
	},
	t064: {
		label: 'Adm. Pública, Defesa e Seguridade Social', cnae: ['84'],
		tags: [],
		icon: 'admin_panel_settings',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fadm-publica-defesa-e-seguridade-social.jpg?alt=media&token=2b4e6206-888c-4428-af75-247cb6b79883'
	},
	t013: {
		label: 'Educação', cnae: ['85'],
		tags: ['t011', 't015', 't016', 't017', 't018', 't019', 't158'],
		icon: 'school',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Feducacao.jpg?alt=media&token=34b18d09-0764-40a5-946e-0a667046d3a1',
		liquid: 1
	},
	t037: {
		label: 'Saúde', cnae: ['86', '87.1', '87.2'],
		tags: ['t003', 't007', 't008', 't012', 't024', 't071', 't082', 't089', 't083', 't083', 't101', 't126'],
		icon: 'monitor_heart',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fsaude.jpg?alt=media&token=a3535252-91bf-4e44-bdd8-ef90d2ee2984',
		liquid: 1
	},
	t065: {
		label: 'Assistência Social ', cnae: ['87.3', '88'],
		tags: [],
		icon: 'assignment_ind',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fassistencia-social.jpg?alt=media&token=06d16ad2-cdc4-4323-808f-495cddbe51d5'
	},
	t066: {
		label: 'Artes e Cultura', cnae: ['90', '91'],
		tags: ['t109'],
		icon: 'palette',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Farte-e-cultura.jpg?alt=media&token=d4b64b7d-7d53-4abd-a9ea-c38889b9b56d'
	},
	t067: {
		label: 'Esporte e Lazer', cnae: ['92', '93'],
		tags: ['t100', 't101', 't110'],
		icon: 'sports_football',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fesporte-e-lazer.jpg?alt=media&token=acd79967-b0a3-4e29-bc19-08dec4463bca'
	},
	t068: {
		label: 'Organizações Associativas', cnae: ['94'],
		tags: [],
		icon: 'groups',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Forganizacoes-associativas.jpg?alt=media&token=cee11598-57c3-47f1-9d9a-696d2d12e553'
	},
	t069: {
		label: 'Outros Serviços Pessoais', cnae: ['95', '96', '97'],
		tags: ['t159'],
		icon: 'person',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Foutros-servicos-pessoais.jpg?alt=media&token=71c6656c-6c81-4392-9e91-0a0a834b5a4c'
	},
	t070: {
		label: 'Organismos Internacionais', cnae: ['99'],
		tags: [],
		icon: 'public',
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Forganismos-internacionais.jpg?alt=media&token=e8862dcf-ec40-4616-bb31-68ea92da9806'
	},
}//TODO: DEVEMOS COLOCAR AS CONST

export const TAGS_SUB: TypeTags = {
	// TODO: PQ A GENERALISTA ESTA AQUI, PODE TIRAR ?
	// t000: { label: 'Multisetorial Generalista', cnae: [''] },
	t002: { label: 'Aluguel de maquinários agrícolas', cnae: ['01.61-0', '77.31-4'], tagRoot: ['t001'] },
	t003: { label: 'Banco de Sangue', cnae: ['86.40-2-12'], tagRoot: ['t037'] },
	t004: { label: 'Bebidas não alcoólicas', cnae: ['11.2', '10.33.3'], tagRoot: ['t025'] },
	t005: { label: 'Biscoitos', cnae: ['10.91-9'], tagRoot: ['t025'] },
	t006: { label: 'Castanhas', cnae: ['10.3'], tagRoot: ['t025'] },
	t007: { label: 'Clínicas de multi especialidades', cnae: ['86.3', '86.5', '86.90-9-01', '86.90-9-03', '86.90-9-04', ''], tagRoot: ['t037'] },
	t008: { label: 'Clínicas oncológicas', cnae: ['86.40-2-10', '86.40-2-11', '86.40-2-12 '], tagRoot: ['t037'] },
	t009: { label: 'Condimentos', cnae: ['10.95-3'], tagRoot: ['t025'] },
	t010: { label: 'Cosméticos', cnae: ['20.63-1'], tagRoot: ['t025'] },
	t011: { label: 'Curso Preparatório', cnae: ['85.99-6-05'], tagRoot: ['t013'] },
	t012: { label: 'Diagnóstico por imagem', cnae: ['86.40-2-04', '86.40-2-05', '86.40-2-06', '86.40-2-07', '86.40-2-08', '86.40-2-09'], tagRoot: ['t037'] },
	t014: { label: 'Embalagens', cnae: ['16.2', '17.3', '22.2', '23.1', '25.9'], tagRoot: ['t025'] },
	t015: { label: 'Ensino Básico', cnae: ['85.1'], tagRoot: ['t013'] },
	t016: { label: 'Ensino Fundamental', cnae: ['85.13-9'], tagRoot: ['t013'] },
	t017: { label: 'Ensino Médio ', cnae: ['85.2'], tagRoot: ['t013'] },
	t018: { label: 'Ensino Superior', cnae: ['85.3', '85.42-2'], tagRoot: ['t013'] },
	t019: { label: 'Ensino Técnico', cnae: ['85.41-4'], tagRoot: ['t013'] },
	t020: { label: 'Farináceos e cereais ', cnae: ['10.6'], tagRoot: ['t025'] },
	t021: { label: 'Ferramentas manuais', cnae: ['25.4'], tagRoot: ['t025'] },
	t022: { label: 'Fertilizantes', cnae: ['20.1'], tagRoot: ['t025'] },
	t023: { label: 'Frutas desidratas', cnae: ['10.3'], tagRoot: ['t025'] },
	t024: { label: 'Hospitais', cnae: ['86.1'], tagRoot: ['t037'] },
	t026: { label: 'Laboratório Farmacêutico', cnae: ['21.1', '21.2'], tagRoot: ['t025'] },
	t027: { label: 'Materiais de Construção', cnae: ['08.1', '16', '20.9', '22.2', '23', '24.2', '25.11-0', '25.12-8', '25.4', '25.92-6', '25.99-3'], tagRoot: ['t047'] },
	t029: { label: 'Pães congelados', cnae: ['10.91-1-01'], liquid: 1, tagRoot: ['t025'] },
	t030: { label: 'Peças Automotivas', cnae: ['29.4', ''], tagRoot: ['t025'] },
	t031: { label: 'Peças de reposição agrícola', cnae: ['25.4', '28.33-0-00'], tagRoot: ['t025'] },
	t032: { label: 'Planos de Saúde', cnae: ['65.5', '66.2'], tagRoot: ['t060'] },
	t033: { label: 'Pratos prontos', cnae: ['10.96-1'], tagRoot: ['t025'] },
	t034: { label: 'Previdência Complementar', cnae: ['65.4', '66.2'], tagRoot: ['t060'] },
	t035: { label: 'Provedor de internet', cnae: ['61.1', '61.9'], liquid: 1, tagRoot: ['t043'] },
	t036: { label: 'Ração animal', cnae: ['10.66-0'], tagRoot: ['t025'] },
	t038: { label: 'Saúde animal', cnae: ['75.0'], tagRoot: ['t062'] },
	t039: { label: 'Segurança privada', cnae: ['80.1', '80.2'], tagRoot: ['t063'] },
	t040: { label: 'Seguros', cnae: ['65.11-1', '65.12-0', '65.2', '65.3', '66.2'], tagRoot: ['t060'] },
	t041: { label: 'Supermercado', cnae: ['47.1'], tagRoot: ['t052'] },
	t042: { label: 'Suplementos alimentares', cnae: ['10.99-6-07', '21.21-1'], liquid: 1, tagRoot: ['t025'] },
	t044: { label: 'Temperos e condimentos', cnae: ['10.95-3'], tagRoot: ['t025'] },
	t046: { label: 'Utensílios domésticos', cnae: ['22.29-3-01', '23.19-2-00', '16.29-3-01', '25.93-4-00', '23.49-4'], tagRoot: ['t025'] },
	t071: { label: 'Laboratórios Análises clínicas', cnae: ['86.40-2-01', '86.40-2-02'], tagRoot: ['t037'] },
	t072: { label: 'Facilities', cnae: ['81.1', '81.2', '81.3'], tagRoot: ['t063'] },
	t073: { label: 'Concessionarias de Rodovias', cnae: ['52.21-4'], tagRoot: ['t053'] },
	t074: { label: 'Concessionarias de Aeroportos', cnae: ['52.40-1-01'], tagRoot: ['t053'] },
	t075: { label: 'Concessionarias de Portos', cnae: ['52.31-1'], tagRoot: ['t053'] },
	t076: { label: 'Logística', cnae: ['49.11-6', '49.3', '50.11-4-01', '50.12-2-01', '50.21-1', '50.30-1-01', '50.91-2', '50.99-8-99', '51.2', '52.1', '52.32-0-00', '52.5', '53.2'], tagRoot: ['t053'] },
	t078: { label: 'Delivery de alimentos', cnae: ['56.20-1-04'], tagRoot: ['t055'] },
	t079: { label: 'Bancos', cnae: ['64.21-2', '64.22-1', '64.24-7-01', '64.31-0', '64.32-8', '64.38-7-01'], tagRoot: ['t060'] },
	t080: { label: 'Financiadoras', cnae: ['64.24-7-02', '64.24-7-03', '64.24-7-04', '64.35-2', '64.36-1', '64.37-9', '64.91-3', '64.99-9-05'], tagRoot: ['t060'] },
	t081: { label: 'Corretoras', cnae: ['66.12-6', '66.19-3-01', '66.19-3-99', '66.22-3'], tagRoot: ['t060'] },
	t082: { label: 'Odontologia', cnae: ['86.30-5-04'], tagRoot: ['t037'] },
	t083: { label: 'Cuidados continuados', cnae: ['87.11-5', '87.12-3', '87.20-4', ''], tagRoot: ['t037'] },
	t084: { label: 'Energia Solar', cnae: ['35.11-5-01'], tagRoot: ['t048'] },
	t085: { label: 'Instalação de painel fotovoltaico', cnae: ['43.21-5-00'], tagRoot: ['t051'] },
	t086: { label: 'Tratamento de água', cnae: ['36.00-6-01'], tagRoot: ['t049'] },
	t087: { label: 'Tratamento de efluentes', cnae: ['37.01-1'], tagRoot: ['t049'] },
	t088: { label: 'Genética', cnae: ['72.10-0-00'], liquid: 1, tagRoot: ['t062'] },
	t089: { label: 'Câncer Center', cnae: ['86.1', '86.40-2-10', '86.40-2-11', '86.40-2-12 '], tagRoot: ['t037'] },
	t090: { label: 'Varejo - HortiFruti', cnae: ['47.24-5-00'], tagRoot: ['t052'] },
	t091: { label: 'Sementes de HortiFruti', cnae: ['01.41-5', '46.23-1-06 '], tagRoot: ['t001'] },
	t092: { label: 'Nutracêuticos', cnae: ['21.1', '21.2', '10.41-4-00', '10.99-6-06', '10.99-6-07'], tagRoot: ['t025'] },
	t093: { label: 'Consultora em eficiência energética', cnae: ['71.12-0'], tagRoot: ['t062'] },
	t094: { label: 'Comércio de auto peças', cnae: ['45.30-7'], tagRoot: ['t052'] },
	t095: { label: 'Padarias', cnae: ['47.21-1', '56.11-2-01', '56.11-2-03'], tagRoot: ['t052'] },
	t096: { label: 'Vinícola', cnae: ['11.12-7'], tagRoot: ['t025'] },
	t097: { label: 'Produtos de limpeza', cnae: ['20.61-4', '20.62-2'], tagRoot: ['t025'] },
	t098: { label: 'Indústria de Alimentos', cnae: ['10'], tagRoot: ['t025'] },
	t099: { label: 'Restaurantes', cnae: ['56.11-2-01', '56.11-2-03'], tagRoot: ['t055'] },
	t100: { label: 'Academias', cnae: ['93.13-1'], tagRoot: ['t067'] },
	t101: { label: 'Centro de alta performance', cnae: ['93.13-1-00', '86.50-0-04', '86.50-0-02'], tagRoot: ['t067', 't037'] },
	t102: { label: 'PCH - Pequenas centrais Hidroelétricas', cnae: ['35.11-5-01'], tagRoot: ['t048'] },
	t103: { label: 'Parque eólico', cnae: ['35.11-5-01'], tagRoot: ['t048'] },
	t104: { label: 'Locadora de veículos', cnae: ['77.11-0'], tagRoot: ['t063'] },
	t105: { label: 'Transporte e logística terrestre', cnae: ['49.30-2', '52.1', '52.50-8'], tagRoot: ['t053'] },
	t106: { label: 'Mercearia seca', cnae: ['01.11-3', '01.15-6', '01.16-4-01', '01.16-4-99', '01.21-1-01', '01.39-3-01', '01.39-3-02', '01.39-3-03', '01.39-3-04', '10.31-7', '10.32-5', '10.6', '10.7', '10.8'], liquid: 1, tagRoot: ['t001'] },
	t107: { label: 'Snack', cnae: [''], liquid: 1, tagRoot: [''] },
	t108: { label: 'Farmácias', cnae: ['47.71-7-01', '47.71-7-02', '47.71-7-03'], tagRoot: ['t052'] },
	t109: { label: 'Exploração de parques ecológicos e ecoturismo ', cnae: ['91.03-1'], tagRoot: ['t066'] },
	t110: { label: 'Parques de diversão', cnae: ['93.21-2'], tagRoot: ['t067'] },
	t111: { label: 'Gestão de esgoto', cnae: ['37.0', ''], tagRoot: ['t049'] },
	t112: { label: 'Indústria de Bebidas', cnae: ['11', '10.33-3'], tagRoot: ['t025'] },
	t113: { label: 'Indústria de Calçado', cnae: ['15.3', '15.4'], tagRoot: ['t025'] },
	t114: { label: 'Distribuidora de insumos agrícolas', cnae: ['46.83-4', '46.23-1-06', '46.23-1-08', '46.23-1-99'], tagRoot: ['t052'] },
	t115: { label: 'Distribuidora de laticínios', cnae: ['46.31-1-00'], tagRoot: ['t052'] },
	t116: { label: 'Alimentos Congelados', cnae: ['10.91-1-01', '10.94-5-00', '10.96-1-00', '10.53-8'], liquid: 1, tagRoot: ['t025'] },
	t117: { label: 'Frigorífico Bovino', cnae: ['10.11-2-01'], tagRoot: ['t025'] },
	t119: { label: 'Testes e análises técnicas', cnae: ['71.20-1'], tagRoot: ['t062'] },
	t120: { label: 'Distribuidora de equipamentos odontológicos', cnae: ['46.64-8'], tagRoot: ['t052'] },
	t121: { label: 'Estacionamentos', cnae: ['52.23-1'], tagRoot: ['t053'] },
	t122: { label: 'Reciclagem', cnae: ['38.3'], tagRoot: ['t050'] },
	t123: { label: 'Pesquisa e desenvolvimento ( P&D )', cnae: ['72.10-0'], tagRoot: ['t062'] },
	t124: { label: 'Comércio varejista', cnae: ['47'], tagRoot: ['t052'] },
	t125: { label: 'Bares e restaurantes', cnae: ['56.11-2'], tagRoot: ['t055'] },
	t126: { label: 'HomeCare', cnae: ['87.12-3', '86.50-01', '86.50-02', '86.50-04', '86.50-05', '86.50-06', '86.50-07', '86.30-5-03'], tagRoot: ['t037'] },
	t127: { label: 'Próteses odontológicas', cnae: ['32.50-7-06'], tagRoot: ['t025'] },
	t128: { label: 'Petshop', cnae: ['47.89-0-04'], tagRoot: ['t052'] },
	t129: { label: 'Energia Renovável', cnae: ['35.1'], tagRoot: ['t048'] },
	t130: { label: 'Alimentos Saudáveis', cnae: ['10'], liquid: 1, tagRoot: ['t025'] },
	t131: { label: 'Laboratório Veterinário', cnae: ['20.63-1', '21.10-6', '21.22-0'], tagRoot: ['t025'] },
	t132: { label: 'Varejo de produtos de uso doméstico', cnae: ['47.54-7', '47.55-5', '47.56-3', '47.57-1', '47.59-8', '47.53-9'], tagRoot: ['t052'] },
	t133: { label: 'Varejo de produtos de informática e comunicação', cnae: ['47.51-2', '47.52-1', '47.53-9', '47.57-1'], tagRoot: ['t052'] },
	t134: { label: 'Varejo de papelaria e livraria', cnae: ['47.61-0'], tagRoot: ['t052'] },
	t135: { label: 'Varejo de discos cds dvds e fitas', cnae: ['47.62-8'], tagRoot: ['t052'] },
	t136: { label: 'Varejo de artigos esportivos e recreativos', cnae: ['47.63-6'], tagRoot: ['t052'] },
	t137: { label: 'Varejos de produtos farmacêuticos e cosméticos', cnae: ['47.71-7', '47.72-5'], tagRoot: ['t052'] },
	t138: { label: 'Varejo de artigos médicos ópticos e ortopédicos', cnae: ['47.73-3', '47.74-1'], tagRoot: ['t052'] },
	t139: { label: 'Varejo de vestuário e assessórios', cnae: ['47.81-4', '47.83-1'], tagRoot: ['t052'] },
	t140: { label: 'Varejo de calçados e artigos de viagem', cnae: ['47.82-2'], tagRoot: ['t052'] },
	t141: { label: 'Varejo de produtos usados', cnae: ['47.85-7'], tagRoot: ['t052'] },
	t142: { label: 'Varejo pet', cnae: ['47.89-0-04'], tagRoot: ['t052'] },
	t143: { label: 'Varejo de bijuteria e artesanato', cnae: ['47.89-0-01'], tagRoot: ['t052'] },
	t144: { label: 'Varejo de objeto de arte', cnae: ['47.89-0-03'], tagRoot: ['t052'] },
	t145: { label: 'Atacado de alimento para animais', cnae: ['46.23-1-09'], tagRoot: ['t052'] },
	t146: { label: 'Atacado de tecidos e armarinho', cnae: ['46.41-9'], tagRoot: ['t052'] },
	t147: { label: 'Atacado de vestuário e assessórios', cnae: ['46.42-7'], tagRoot: ['t052'] },
	t148: { label: 'Atacado de calçados e artigos de viagem', cnae: ['46.43-5'], tagRoot: ['t052'] },
	t149: { label: 'Atacado de medicamentos humanos', cnae: ['46.44-3-01'], tagRoot: ['t052'] },
	t150: { label: 'Atacado de medicamentos veterinários', cnae: ['46.44-3-02'], tagRoot: ['t052'] },
	t151: { label: 'Atacado de instrumentos médicos, ortopédicos e odontológicos', cnae: ['46.45-1'], tagRoot: ['t052'] },
	t152: { label: 'Atacado de cosméticos e higiene pessoal', cnae: ['46.46-0'], tagRoot: ['t052'] },
	t153: { label: 'Atacado de papelaria e livraria', cnae: ['46.47-8'], tagRoot: ['t052'] },
	t154: { label: 'Atacado de produtos de uso pessoal e doméstico', cnae: ['46.49-4'], tagRoot: ['t052'] },
	t155: { label: 'Locação de meios de transportes', cnae: ['77.1'], tagRoot: ['t063'] },
	t156: { label: 'Gestora de investimento', cnae: ['66.3'], tagRoot: ['t060'] },
	t157: { label: 'Agentes autônomos de investimento', cnae: ['66.12-6-05'], tagRoot: ['t060'] },
	t158: { label: 'Educação corporativa', cnae: ['85.99-6-04', '85.99-6-99'], tagRoot: ['t013'] },
	t159: { label: 'Montagem e manutenção de móveis', cnae: ['95.29-1-05', '33.29-5-01'], tagRoot: ['t069'] },

	t160: { label: 'Serviços agro', cnae: ['01.6', '02.3'], tagRoot: ['t001'] },
	t161: { label: 'Agricultura', cnae: ['01.1', '01.2', '01.3', '01.4', '01.61-0', '01.63-6'], tagRoot: ['t001'] },
	t162: { label: 'Pecuária', cnae: ['01.5', '01.62-8'], tagRoot: ['t001'] },
	t163: { label: 'Aquicultura', cnae: ['03.2'], tagRoot: ['t001'] },
	t164: { label: 'Aromatizantes', cnae: ['20.93-2'], tagRoot: ['t025'] },
	t165: { label: 'Aluguel de automóveis', cnae: ['77.31-4', ''], tagRoot: ['t063'] },
	t166: { label: 'Aluguel de maquinário para construção', cnae: ['77.32-2-01', '43.13-4-00', '43.91-6-00', '43.99-1-04', ''], tagRoot: ['t063'] },
	t167: { label: 'Distribuidora de produtos químicos', cnae: ['46.84-2', '46.83-4', '46.89-3', '46.81-8-05', '46.79-6-01', '46.84-2-99'], tagRoot: ['t052'] },
	t168: { label: 'Indústria de Produtos odontológicos', cnae: ['32.50-7-01', '32.50-7-02', '32.50-7-05', '32.50-7-06', '26.60-4-00', '33.19-8-00'], tagRoot: ['t025'] },
	t169: { label: 'Franquiadora', cnae: ['77.40-3'], tagRoot: ['t063'] },
	t170: { label: 'Crédito Pessoal', cnae: ['64.36-1'], tagRoot: ['t060'] },
	t171: { label: 'Indústria de vestuário e acessórios', cnae: ['14'], tagRoot: ['t025'] },
	t172: { label: 'Marketing, publicidade e propaganda', cnae: ['73.1'], tagRoot: ['t062'] },

	t173: { label: 'Processamento de proteína animal', cnae: ['10.2', '10.13.9'], tagRoot: ['t025'] },
	t174: { label: 'Distribuidora de alimentos e bebidas', cnae: ['46.31.1', '46.32.0', '46.33.8', '46.34.6', '46.35.4', '46.37.1', '46.39.7'], tagRoot: ['t052'] },
	t175: { label: 'Supermercado atacadista', cnae: ['46.91.5'], tagRoot: ['t052'] },

	t176: { label: 'Movelaria', cnae: ['47.44-0-01', '47.44-0-02'], tagRoot: ['t052'] },
	t177: { label: 'Purificadores de água', cnae: ['46.18-4-99', '46.49-9-99', '47.53-9-00', '47.59-8-99'], tagRoot: ['t052'] },
	t178: { label: 'Serviços em segurança cibernética', cnae: ['62.04-0', '62.09-1'], tagRoot: ['t058'] },
}//TODO: DEVEMOS COLOCAR AS CONST

export const TAGS_SECTOR: TypeTags = {
	...TAGS_SECTOR_ROOT,
	...TAGS_SUB,
} as const

export const TAGS_SECTOR_GET = (code: keyof typeof TAGS_SECTOR): TypeTags['tag'] => {
	const _code = code

	return TAGS_SECTOR[_code] ||
	{
		label: 'Tag não encontrada',
		cnae: [],
		tags: [],
		tagRoot: [],
		img: 'https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2Fteses%2Fnao-informada.jpg?alt=media&token=516cf3ff-76c5-49e0-9761-32a80544c17b',
		icon: 'not_interested',
		liquid: 0,
	}
}

export const TAGS_SECTOR_VALUES = Object.values(TAGS_SECTOR)
export const TAGS_SECTOR_KEYS = Object.keys(TAGS_SECTOR)

/**
 * @description Retorna a tag setorial de uma tag
 * @param tag Tag a ser verificada
 * @returns Tag setorial
 * @example
 * caseTagFromSector('tag20') // {tag1: {label:'label1', cnae:[] ... }, tag2: {label:'label2', cnae:[] ... }
 */

export const TAGS_SECTOR_FROM_TAG = (tag: string) => {
	const tagsSector = TAGS_SECTOR_GET(tag).tagRoot || []
	return tagsSector.reduce((acc, tag) => {
		acc[tag] = TAGS_SECTOR_GET(tag)
		return acc
	}, {} as TypeTags)
}

/**
 * @description Retorna todas as tags de um setor
 * @param tagSector Tag Setor a ser verificado
 * @returns Todas as tags do setor
 * @example
 * caseTagFromSector('t001') // {tag1: {label:'label1', cnae:[] ... }, tag2: {label:'label2', cnae:[] ... }
 * caseTagFromSector('t002') // {tag1: {label:'label1', cnae:[] ... }, tag2: {label:'label2', cnae:[] ... }
 */

export const TAGS_FROM_SECTOR = (tagSector: string): TypeTags => {
	const tagsSectorEntries = Object.entries(TAGS_SUB).filter(([key, value]) => value.tagRoot?.includes(tagSector))
	return tagsSectorEntries.reduce((acc, [key, value]) => {
		acc[key] = value
		return acc
	}, {} as TypeTags)
}

/**
 * @description Retorna as tags enviadas separadas por setor e contadas, caso exista uma tag root no array de tags, essa tag será contada como uma tag e virá na propriedade tagRoot
 * @param tags array de string
 * @returns objeto com as tags separadas por setor e contadas
 * @example
 * caseTagFromSector(['tag1', 'tag2', 'tag3', 'tag4', 'tag5', 'tag6', 'tag7', 'tag8', 'tag9', 'tag10'])
 * retorno // {t001: {tags: {..}, total: 2}, t002: {tags: {...}, total: 5}, t003: {tags: {...}, total: 5, tagRoot: {...}}
 */

export const TAGS_FROM_SECTOR_COUNT = (tags: string[]): { [key: string]: { tags: TypeTags, total: number } } => {
	const tagsSector = tags.reduce((acc, tag) => {
		const tagSector = TAGS_SECTOR_GET(tag).tagRoot || []
		if (TAGS_SECTOR_ROOT[tag]) {
			acc[tag] = { tags: { ...acc[tag]?.tags || {} }, tagRoot: { [tag]: TAGS_SECTOR_ROOT[tag] }, total: (acc[tag]?.total || 0) + 1 }
		}

		tagSector.forEach(sector => {
			if (!acc[sector]) acc[sector] = { tags: {}, total: 0 }
			acc[sector].tags[tag] = TAGS_SECTOR_GET(tag)
			acc[sector].total++
		})
		return acc
	}, {} as { [key: string]: { tags: TypeTags, total: number, tagRoot?: TypeTags } })

	return tagsSector
}

/**
 * @description Retorna as tags root de um array de tags contendo tags root e subtags
 * @param tags array de string
 * @returns objeto com as tags root
 * @example
 * TAGS_TO_TAG_ROOT(['t001', 't002', 't049', 't086', 't078', 't055', 't109', 't101', 't110'])
 * retorno ['t001', 't049', 't055', 't066', 't067', 't037']
 */
export const TAGS_TO_TAG_ROOT = (tags: string[]) => {
	const tagsRoot: string[] = []
	tags.map(tag => {
		// se a tag já for root, adicionamos ela
		if (TAGS_SECTOR_ROOT[tag]) {
			tagsRoot.push(tag)
			return
		}
		const rest: string[] = []
		// Check if the tag is a sub tag and get its tag roots
		Object.entries(TAGS_SUB).forEach(([id, subInfo]) => {
			if (tag === id && subInfo.tagRoot) rest.push(...subInfo.tagRoot)
		})
		tagsRoot.push(...rest)
	})
	return [...new Set(tagsRoot)]
}

/**
 * Retorna as tags root em ordem alfabética
 */
export const SORTED_TAGS_ROOT = Object.keys(TAGS_SECTOR_ROOT).sort((a, b) => TAGS_SECTOR_ROOT[a]?.label.localeCompare(TAGS_SECTOR_ROOT[b]?.label))
