import { checkCNPJ } from './checkCNPJ'
import { getValuationMedian } from './getValuationMedian'
import { modelValuation } from './model'
import { addTag } from './addTag'
import { removeTag } from './removeTag'
import { mergeWithOrigination } from './mergeWithOrigination'
import { addTransaction } from './addTransaction'
import { removeTransaction } from './removeTransaction'
import { getEstimatedValuation } from './getEstimatedValuation'
import { getValuationResult } from './getValuationResult'
import { createValuation } from './create'
import { validateFormValuationData } from './validateFormValuationData'
import { getTemporaryValuation } from './getTemporaryValuation'
import { validateFormCreateValuation } from './validateFormCreateValuation'
import { getOpenCapitalTransactions } from './getOpenCapitalTransactions'
import { getClosedCapitalTransactions } from './getClosedCapitalTransactions'

export const fnValuation = {
	checkCNPJ,
	removeTransaction,
	addTransaction,
	modelValuation,
	getValuationMedian,
	addTag,
	removeTag,
	mergeWithOrigination,
	getEstimatedValuation,
	getValuationResult,
	createValuation,
	validateFormValuationData,
	getTemporaryValuation,
	validateFormCreateValuation,
	getOpenCapitalTransactions,
	getClosedCapitalTransactions
}
