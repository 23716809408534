import styled, { keyframes } from 'styled-components'


const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255, .7);
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`

const StyledAnimation = styled.div`
img{
		animation: ${rotate} 2s linear infinite;
}
`

export const InternaLoading = () => <Wrapper>
	<InternaLoadingSection />
</Wrapper>


export const InternaLoadingSection = () => <StyledAnimation>
	<img src='/assets/img/loading.png' alt='Loading' width={50} />
</StyledAnimation>
