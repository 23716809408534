import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ExclamationOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M16.2012 4.39024C9.78928 4.39024 4.59142 9.58811 4.59142 16C4.59142 22.4119 9.78928 27.6098 16.2012 27.6098C22.6131 27.6098 27.8109 22.4119 27.8109 16C27.8109 9.58811 22.6131 4.39024 16.2012 4.39024ZM2.20117 16C2.20117 8.26801 8.46918 2 16.2012 2C23.9332 2 30.2012 8.26801 30.2012 16C30.2012 23.732 23.9332 30 16.2012 30C8.46918 30 2.20117 23.732 2.20117 16Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.2 9.68292C16.8601 9.68292 17.3951 10.218 17.3951 10.878V16C17.3951 16.66 16.8601 17.1951 16.2 17.1951C15.54 17.1951 15.0049 16.66 15.0049 16V10.878C15.0049 10.218 15.54 9.68292 16.2 9.68292Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M15.0049 21.1219C15.0049 20.4619 15.54 19.9268 16.2 19.9268H16.2128C16.8729 19.9268 17.4079 20.4619 17.4079 21.1219C17.4079 21.782 16.8729 22.317 16.2128 22.317H16.2C15.54 22.317 15.0049 21.782 15.0049 21.1219Z' fill='#272827' />
	</Svg>
)
