import { clone } from '../../Utils/clone'
import { TypeCalendar } from '../types/calendar'

export const modelCalendar = (obj: Partial<TypeCalendar> = {}): TypeCalendar => {

	const cloneObj = clone(obj)

	return ({
		_id: cloneObj._id || '',
		title: cloneObj.title || '',
		place: cloneObj.place || '',
		dateStart: cloneObj.dateStart || 0,
		dateEnd: cloneObj.dateEnd || 0,
		url: cloneObj.url || '',
		createdAt: cloneObj.createdAt || 0,
		updatedAt: cloneObj.updatedAt || 0,
	})

}
