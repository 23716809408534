/**
* Função que converte valor monetário em string para número
* @param {String} value {String} Valor a ser checado
* @returns {Number} {Number} Valor formatado
* @example checkIfCoinIsInBrazilianFormat('1.000,00') => 1000
* @example checkIfCoinIsInBrazilianFormat('1000.33')) => 1000.33
*/

export const checkIfCoinIsInBrazilianFormat = (value: string): number => {

	const cloneValue: string = JSON.parse(JSON.stringify(value))

	const pattern = /,\d{2}$/

	if (!pattern.test(cloneValue)) return +cloneValue

	const newValue = cloneValue.replaceAll('.', '').replace(',', '.')

	return +newValue
}
