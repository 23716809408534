
export const TEASER_FIELDS_GET = (code: string) => {
	const _code = code as keyof typeof TEASER_FIELDS

	if (!TEASER_FIELDS[_code]) {
		return ('')
	}

	return TEASER_FIELDS[_code]
}

export enum TEASER_FIELDS {
	// CNPJ
	cnpj = 'cnpj',
	// CPF,
	cpf = 'cpf',
	// Site
	site = 'site',
	// Descrição da empresa
	overview = 'overview',
	// Nome empresarial
	nome = 'nome',
	// Nome fantasia
	fantasyName = 'fantasyName',
	// Descrição
	description = 'description',
	// Tipo de operação
	typeOperation = 'typeOperation',
	// O que você deseja dessa operação? Total/ Minoritária/ Majoritária
	etr = 'etr',
	// Valor desejado na captação
	desiredValue = 'desiredValue',
	// Financiamento de projeto
	isProjectFinancing = 'isProjectFinancing',
	// Orçamento total do projeto
	projectBudget = 'projectBudget',
	// Classificação da empresa - Real ou tech
	companyRating = 'companyRating',
	// EBITDA ano atual (valor esperado)
	eb0 = 'eb0',
	// EBITDA anos anteriores
	eb1 = 'eb1',
	eb2 = 'eb2',
	eb3 = 'eb3',
	eb4 = 'eb4',
	// Receita bruta ano atual (valor esperado)
	rb0 = 'rb0',
	// Receita bruta anos anteriores
	rb1 = 'rb1',
	rb2 = 'rb2',
	rb3 = 'rb3',
	rb4 = 'rb4',

	emprestimos = 'emprestimos',
	tributos = 'tributos',
	fornecedores = 'fornecedores',
	outrasdividas = 'outrasdividas',
	// garantias do teaser
	guarantees = 'guarantees',
	// Não possui garantias
	noGuarantees = 'noGuarantees',
	// Informações adicionais sobre a empresa
	additionalInformation = 'additionalInformation',
	//endividamento
	indebtedness = 'indebtedness',
	//tags de tag adicionadas ao teaser
	tagTech = 'tagTech',
	//caso tenhamos uma empresa do tipo tag e uma tag seja adicionada, a tagTechActive será true
	tagTechActive = 'tagTechActive',
	//caso nenhuma tag seja encontrada relacionada ao teaser
	tagNotFound = 'tagNotFound',
	// todas as tags que foram excluídas da lista de tags do teaser quando o tipo for real
	tagsNegative = 'tagsNegative',
	// se o teaser possui ou não um site
	hasSite = 'hasSite',

	tags = 'tags',

	reasonDeal = 'reasonDeal',

	customerName = 'customerName',
	customerEmail = 'customerEmail',

	financing = 'financing', // Financiamentos da empresa
	hasCreditoOperacoesEstruturadas = 'hasCreditoOperacoesEstruturadas',
	hasFinanciamentoObras = 'hasFinanciamentoObras',
	hasFinanciamentoObrasDoZero = 'hasFinanciamentoObrasDoZero',
	creditOperations = 'creditOperations', // operações de crédito, usado apenas para validar o formulário (não é um campo do typeteaser),
	financingObras = 'financingObras', // financiamento de obras, usado apenas para validar o formulário (não é um campo do typeteaser),
	financingProjetosZero = 'financingProjetosZero', // financiamento de projetos do zero, usado apenas para validar o formulário (não é um campo do typeteaser),

	governanceAuditedBalance = 'governanceAuditedBalance',
	governanceRevenueOutside = 'governanceRevenueOutside',

}
export const TEASER_FIELDS_VALUES = Object.values(TEASER_FIELDS)
export const TEASER_FIELDS_KEYS = Object.keys(TEASER_FIELDS)
