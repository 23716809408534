import React, { createContext, useContext, useEffect, useState } from 'react'
import { assistantAnne } from '../Services/cloudfunctions'


const _useContext = () => {
	const [openChat, setOpenChat] = useState(false)
	const [threadId, setThreadId] = useState<string | null>(null)
	const [conversation, setConversation] = useState<any[]>([])

	useEffect(() => {
		const storedConversation = localStorage.getItem('assistantConversation')
		if (storedConversation) {
			setConversation(JSON.parse(storedConversation))
		}

		// Check if openChat is true and thread ID exists in localStorage
		const storedThreadId = localStorage.getItem('assistantThreadId')
		if (openChat && !storedThreadId) {
			// Create a new thread if openChat is true and no thread ID found in localStorage
			createNewThread()
		}
		else {
			setThreadId(storedThreadId)
		}
		//eslint-disable-next-line
	}, [openChat]); // Execute when openChat changes

	// Function to create a new thread
	const createNewThread = async () => {
		try {
			// call assistant cloud to create a thread
			const threadID = await assistantAnne({ isContext: true })

			setThreadId(threadID.data)
			// Store the thread ID in localStorage
			localStorage.setItem('assistantThreadId', threadID.data)
		}
		catch (error) {
			// console.error('Error creating thread:', error)
		}
	}

	return ({
		openChat, setOpenChat, threadId, setThreadId, conversation, setConversation
	})
}
const ContextAssistantChat = createContext({} as ReturnType<typeof _useContext>)

export const useContextAssistantChat = () => useContext(ContextAssistantChat)
export const ContextAssistantChatProvider: React.FC = ({ children }) => {
	const controller = _useContext()
	return (
		<ContextAssistantChat.Provider value={controller}>
			{children}
		</ContextAssistantChat.Provider>
	)
}
