import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const SignsOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M12.1439 2C12.8099 2 13.4012 2.47355 13.6118 3.17561L19.5123 22.8438L21.7285 15.4563C21.9391 14.7542 22.5305 14.2807 23.1965 14.2807H28.1088C28.9634 14.2807 29.6561 15.0505 29.6561 16C29.6561 16.9495 28.9634 17.7193 28.1088 17.7193H24.3118L20.9802 28.8244C20.7696 29.5265 20.1783 30 19.5123 30C18.8462 30 18.2549 29.5265 18.0443 28.8244L12.1439 9.1562L9.92761 16.5437C9.71699 17.2458 9.12568 17.7193 8.45965 17.7193H3.54737C2.69278 17.7193 2 16.9495 2 16C2 15.0505 2.69278 14.2807 3.54737 14.2807H7.34437L10.6759 3.17561C10.8865 2.47355 11.4778 2 12.1439 2Z' fill='#272827' />
	</Svg>
)
