import { TypeUser } from '../../../User/types/TypeUser'
import { TypeTeaser } from '../../types/teaser'
import { TypeUserGenerics } from '../../../User/types/userGenerics'
import { clone } from '../../../Utils/clone'
import { modelUser } from '../../../User/model/modelUser'


export const getBda = (teaser: TypeTeaser, users: TypeUserGenerics): TypeUser => {

	const cloneTeaser = clone(teaser)
	const cloneUsers = clone(users)

	if (cloneTeaser.uid && cloneUsers[cloneTeaser.uid]) {
		const uid = cloneTeaser.uid
		const bda = cloneUsers[uid]
		return modelUser(bda)
	}
	return modelUser()
}
