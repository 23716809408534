import { SUB_STATUS_OF_ACTIVE_COSTUMER } from './SUB_STATUS_OF_ACTIVE_COSTUMER'

const _ICONS_STEPS_WITH_LABEL = {
	'b0000': {
		notificationId: 'b0000',
		_id: 'b0000',
		icon: 'error',
		label: ''
	}, // aceia match
	'b0002': {
		notificationId: 'b0002',
		_id: 'b0002',
		icon: 'how_to_reg',
		label: ''
	}, // aceia match
	'b0003': {
		notificationId: 'b0003',
		_id: 'b0003',
		icon: 'how_to_reg',
		label: ''
	}, // novos investidores
	'b0004': {
		notificationId: 'b0004',
		_id: 'b0004',
		icon: 'cancel',
		label: ''
	}, // rejeita match
	'b0005': {
		notificationId: 'b0005',
		_id: 'b0005',
		icon: 'fact_check',
		label: SUB_STATUS_OF_ACTIVE_COSTUMER.s00100.labelSubstatus
	}, // matchmaking
	'b0006': {
		notificationId: 'b0006',
		_id: 'b0006',
		icon: 'settings',
		label: SUB_STATUS_OF_ACTIVE_COSTUMER.s00200.labelSubstatus
	}, // match preparacao
	'b0007': {
		notificationId: 'b0007',
		_id: 'b0007',
		icon: 'cancel',
		label: ''
	}, // match declinado
	'b0008': {
		notificationId: 'b0008',
		_id: 'b0008',
		icon: 'cancel',
		label: ''
	}, // inativo
	'b0009': {
		notificationId: 'b0009',
		_id: 'b0009',
		icon: 'undo',
		label: ''
	}, // matchmaking (de volta do declinado)
	'b00010': {
		notificationId: 'b00010',
		_id: 'b00010',
		icon: 'description',
		label: SUB_STATUS_OF_ACTIVE_COSTUMER.s00300.labelSubstatus
	}, // Negociação de oferta
	'b00011': {
		notificationId: 'b00011',
		_id: 'b00011',
		icon: 'backup',
		label: SUB_STATUS_OF_ACTIVE_COSTUMER.s00400.labelSubstatus
	}, // Diligência
	'b00012': {
		notificationId: 'b00012',
		_id: 'b00012',
		icon: 'task',
		label: SUB_STATUS_OF_ACTIVE_COSTUMER.s00500.labelSubstatus
	}, // contratos definitivos
	'b00013': {
		notificationId: 'b00013',
		_id: 'b00013',
		icon: 'star_rate',
		label: SUB_STATUS_OF_ACTIVE_COSTUMER.s00600.labelSubstatus
	}, // transação concluida
}

export type ICONS_STEPS_WITH_LABEL = keyof typeof _ICONS_STEPS_WITH_LABEL

export const ICONS_STEPS_WITH_LABEL = {
	GET: (code: string) => {
		const _code = code as keyof typeof _ICONS_STEPS_WITH_LABEL

		if (!_ICONS_STEPS_WITH_LABEL[_code]) {
			return _ICONS_STEPS_WITH_LABEL['b0000']
		}

		return _ICONS_STEPS_WITH_LABEL[_code]
	},
	KEYS: Object.keys(_ICONS_STEPS_WITH_LABEL) as Array<keyof typeof _ICONS_STEPS_WITH_LABEL>,
	VALUES: Object.values(_ICONS_STEPS_WITH_LABEL) as Array<typeof _ICONS_STEPS_WITH_LABEL[keyof typeof _ICONS_STEPS_WITH_LABEL]>
}
