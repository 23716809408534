export const LogoStarkTypographyWhite = props => (
	<svg
		id='prefix__Camada_1'
		xmlns='http://www.w3.org/2000/svg'
		x={0}
		y={0}
		viewBox='0 0 1404.3 544.7'
		xmlSpace='preserve'
		{...props}
	>
		<style>{'.prefix__st0{fill:#fff}'}</style>
		<path
			className='prefix__st0'
			d='M625.4 228.4h-37.9c-3.8-23.9-19.7-36-47.4-36-26.4 0-43 12.1-43 31.5 0 16.2 11.1 24.2 35 29l27.4 5.4c43.3 8.6 64.9 26.1 64.9 59.5 0 20.1-7.6 36.6-23.2 49-15.3 12.4-35.3 18.5-60.5 18.5-26.7 0-48.4-6.4-65.3-20.1-16.5-13.4-25.8-31.8-27.7-55.1h37.9c3.8 25.5 25.1 41.4 57.3 41.4 26.4 0 42.3-11.1 42.3-29.3 0-14.3-10.5-22.6-31.8-26.7l-35.6-7c-40.7-8-61.1-28-61.1-61.4 0-20.1 7.3-36.3 22-48.7 15-12.4 34.4-18.8 58.9-18.8 38.5.1 82.4 16.6 87.8 68.8zM771.2 379.3c-14 4.1-26.4 6.4-37.6 6.4-36 0-58.2-24.2-58.2-62.7v-61.7H641v-30.9h34.4v-65.9H712v65.9h55.7v30.9H712V323c0 19.1 9.5 28.6 28.6 28.6 8 0 16.2-1.3 25.5-3.8l5.1 31.5zM946.5 380.9h-36.6v-10.5c-13.1 9.2-28.3 14-46.2 14-21 0-38.8-7.6-53.8-22.9-14.6-15.3-22-33.7-22-55.7s7.3-40.4 22-55.7c15-15.6 32.8-23.2 53.8-23.2 17.8 0 33.1 4.8 46.2 14v-10.5h36.6v150.5zm-36.6-49.7V280c-9.5-14.3-23.2-21.3-40.7-21.3-12.7 0-23.2 4.8-31.8 14s-12.7 20.4-12.7 33.1c0 12.7 4.1 23.9 12.7 33.1 8.6 9.2 19.1 13.7 31.8 13.7 17.5 0 31.2-7 40.7-21.4zM1014.2 249.4c14.3-14.6 32.5-22 54.7-22v34.7c-23.6 1-41.7 9.2-54.7 25.1v93.6h-36.9V230.3h36.9v19.1zM1128.7 347.2v33.7h-36.6V164.5h36.6v134.3l66.2-68.4h46.8l-60.5 62.4 69.1 88.2h-47.7l-46.8-61.7-27.1 27.9zM286.9 161.8l54 93.5c2.1 3.7-.5 8.3-4.8 8.3h-108c-4.3 0-6.9-4.6-4.8-8.3l54-93.5c2.1-3.7 7.5-3.7 9.6 0z'
		/>
		<g>
			<path
				className='prefix__st0'
				d='M346.4 280.9l-54 93.5c-2.1 3.7.5 8.3 4.8 8.3h108c4.3 0 6.9-4.6 4.8-8.3l-54-93.5c-2.1-3.7-7.4-3.7-9.6 0z'
			/>
		</g>
		<g>
			<path
				className='prefix__st0'
				d='M208.7 280.9l-54 93.5c-2.1 3.7.5 8.3 4.8 8.3h108c4.3 0 6.9-4.6 4.8-8.3l-54-93.5c-2.1-3.7-7.4-3.7-9.6 0z'
			/>
		</g>
	</svg>
)
