import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const CreditCardOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M5.60903 8.45614C5.04474 8.45614 4.5873 8.8904 4.5873 9.42608V22.5739C4.5873 23.1096 5.04474 23.5439 5.60903 23.5439H26.391C26.9553 23.5439 27.4127 23.1096 27.4127 22.5739V9.42608C27.4127 8.8904 26.9553 8.45614 26.391 8.45614H5.60903ZM2 9.42608C2 7.53391 3.61582 6 5.60903 6H26.391C28.3842 6 30 7.53391 30 9.42608V22.5739C30 24.4661 28.3842 26 26.391 26H5.60903C3.61581 26 2 24.4661 2 22.5739V9.42608Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M2 13.8086C2 13.1304 2.57919 12.5806 3.29365 12.5806H28.7064C29.4208 12.5806 30 13.1304 30 13.8086C30 14.4869 29.4208 15.0367 28.7064 15.0367H3.29365C2.57919 15.0367 2 14.4869 2 13.8086Z' fill='#272827' />
	</Svg>
)
