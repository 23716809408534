import { helpersDate } from 'Functions/Utils/helpersDate'
import { VALUATION_TYPES } from 'Screens/Sinatra/Constants/ValuationTypes'
import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'

export const modelValuation = (valuation?: Partial<TypeValuation>): TypeValuation => ({
	_id: valuation?._id || '',
	createdAt: valuation?.createdAt || helpersDate.newDate(),
	createdBy: valuation?.createdBy || { uid: '', name: '' },
	fromOffice: valuation?.fromOffice || '',
	tags: valuation?.tags || [],
	companyName: valuation?.companyName || '',
	cnpj: valuation?.cnpj || '',
	billing: valuation?.billing || null,
	cash: valuation?.cash || null,
	debt: valuation?.debt || null,
	publicCompanies: valuation?.publicCompanies || true,
	privateCompanies: valuation?.privateCompanies || true,
	ebitda: valuation?.ebitda || null,

	mediansOfOpenCapital: {
		ebitda: valuation?.mediansOfOpenCapital?.ebitda || null,
		ebitdaMultiple: valuation?.mediansOfOpenCapital?.ebitdaMultiple || null,
		billing: valuation?.mediansOfOpenCapital?.billing || null,
		billingMultiple: valuation?.mediansOfOpenCapital?.billingMultiple || null,
		enterpriseValue: valuation?.mediansOfOpenCapital?.enterpriseValue || null
	},

	mediansOfClosedCapital: {
		ebitda: valuation?.mediansOfClosedCapital?.ebitda || null,
		ebitdaMultiple: valuation?.mediansOfClosedCapital?.ebitdaMultiple || null,
		billing: valuation?.mediansOfClosedCapital?.billing || null,
		billingMultiple: valuation?.mediansOfClosedCapital?.billingMultiple || null,
		enterpriseValue: valuation?.mediansOfClosedCapital?.enterpriseValue || null
	},

	mediansTotal: {
		ebitda: valuation?.mediansTotal?.ebitda || null,
		ebitdaMultiple: valuation?.mediansTotal?.ebitdaMultiple || null,
		billing: valuation?.mediansTotal?.billing || null,
		billingMultiple: valuation?.mediansTotal?.billingMultiple || null,
		enterpriseValue: valuation?.mediansTotal?.enterpriseValue || null
	},

	valuationOfEbitda: {
		open: valuation?.valuationOfEbitda?.open || null,
		closed: valuation?.valuationOfEbitda?.closed || null,
		total: valuation?.valuationOfEbitda?.total || null,
	},

	valuationOfBilling: {
		open: valuation?.valuationOfBilling?.open || null,
		closed: valuation?.valuationOfBilling?.closed || null,
		total: valuation?.valuationOfBilling?.total || null,
	},

	valuationResult: {
		typeValue: valuation?.valuationResult?.typeValue || 'increase',
		percentage: valuation?.valuationResult?.percentage || null,
		type: valuation?.valuationResult?.type || VALUATION_TYPES.ebitdaOpenCapital._id as keyof typeof VALUATION_TYPES,
		value: valuation?.valuationResult?.value || null
	},

	transactions: []
})
