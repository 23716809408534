import { clone } from '../../../Functions/Utils/clone'
import { minhaData } from '../../../Functions/Utils/minhaData'
import { notifications } from '../../../Services/cloudfunctions'
import { TypeTeaser } from '../types/teaser'
import { TypeUser } from '../../User/types/TypeUser'
import { db5 } from '../../../Services/db5'


/**
 * A função `adjust` atualiza os dados de um teaser em um banco de dados, adiciona um novo
 * data de ajuste e envia uma notificação.
 * @param - 1. `teaser`: Objeto do tipo `TypeTeaser` contendo dados do teaser.
 */
//#CRIADO POR IA

export const adjust = async ({ teaser, id, adjust, profile, uid }: { teaser: TypeTeaser, id: string, adjust: string, profile?: TypeUser, uid: string }) => {

	const cloneId = clone(id)

	db5.teasers.update({
		id: cloneId,
		doc: {
			dataPublicacao: 0,
			statusCustomer: '',
			dataAjustes: [...teaser.dataAjustes, minhaData()]
		}
	}).then(async () => {

		await notifications({
			targetId: cloneId,
			typeOrMessage: 'a0006',
			profileUid: uid,
		})

		return true
	}
	)
}
