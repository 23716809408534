import { useContextAuth } from 'App/ContextAuth'
import { fnUser } from 'Functions/User'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useAnalytics = () => {
	const location = useLocation()
	const { globalAuth: { profile } } = useContextAuth()
	const isAdmUser = fnUser.isAdm(profile)


	const gaCodes = {
		adm: process.env.REACT_APP_GA_ID_ADM || '',
		agent: process.env.REACT_APP_GA_ID_AGENT || '',
		customer: process.env.REACT_APP_GA_ID_CUSTOMER || '',
		office: process.env.REACT_APP_GA_ID_OFFICE || '',
		officeAnalyst: process.env.REACT_APP_GA_ID_OFFICE_ANALYST || '',
		ep: process.env.REACT_APP_GA_ID_EP || '',
		assessor: process.env.REACT_APP_GA_ID_ASSESSOR || '',
		default: process.env.REACT_APP_GA_ID_DEFAULT || '',
	}

	let userCode = gaCodes.default

	if (fnUser.isAdm(profile)) userCode = gaCodes.adm
	if (fnUser.isAgent(profile)) userCode = gaCodes.agent
	if (fnUser.isCustomer(profile)) userCode = gaCodes.customer
	if (fnUser.isOffice(profile)) userCode = gaCodes.office
	if (fnUser.isOfficeAnalyst(profile)) userCode = gaCodes.officeAnalyst
	if (fnUser.isEnterprise(profile)) userCode = gaCodes.ep
	if (fnUser.isMicroBda(profile)) userCode = gaCodes.assessor

	if (!userCode) return false

	useEffect(
		() => {
			if ((window as any).gtag && !isAdmUser) {
				(window as any).gtag('event', 'page_view', {
					'screen_name': location.pathname
				})
				// (window as any).gtag('event', 'login', {
				// 	'screen_name': location.pathname,
				// 	'uid': profile._id,
				// 	'type': fnUser.getUser({ user: profile }),
				// 	'name': `${profile.nome} ${profile.sobrenome || ''}`
				// })
			}
		},
		[location, isAdmUser]
	)
	useEffect(() => {
		let gaScript: HTMLScriptElement | null = document.getElementById('gaScript') as HTMLScriptElement | null
		let gaContent: HTMLScriptElement | null = document.getElementById('gaContent') as HTMLScriptElement | null

		if (Object.keys(profile).length && !gaContent && !gaScript) {
			// add GS script
			gaScript = document.createElement('script')
			gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${userCode}`
			gaScript.async = true
			gaScript.id = 'gaScript'
			document.head.appendChild(gaScript)

			// add GA content
			gaContent = document.createElement('script')
			gaContent.id = 'gaContent'
			const inlineCode = document.createTextNode(`
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${userCode}');`)
			gaContent.appendChild(inlineCode)
			document.head.appendChild(gaContent)
		}

	}, [isAdmUser, profile, userCode])
}

export default useAnalytics
