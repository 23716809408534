// como faço para saber o tipo do item que será passado? pode ser objeto, array ou data

/**
*	@description Função que clona um objeto, array ou data
*	@param {T} item - item a ser clonado
*	@returns {T} item clonado
*	@example
*	clone([1, 3, 4]) // [1, 3, 4]
*/

//@ts-ignore
import cloneDeep from 'clone-deep'

export const deprecated_clone = <T>(item: T): T =>
	// @ts-ignore
	structuredClone(item)

export const clone = <T>(item: T): T =>
	cloneDeep(item)
