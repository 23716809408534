import { TypeScoreProps } from '../types/TypeScoreProps'
import { genValuation } from './genValuation'

export const genTransactionValue = (props: TypeScoreProps): number => {

	if (props.operation === 'greenfield') return +props.teaser?.desiredValue || 0
	if (props.operation === 'corporateFinance') return +props.teaser?.desiredValue || 0
	// if (props.operation === 'corporateFinance') return 20_000_000
	// if (props.operation === 'corporateFinance') return getTotalInsure(props.teaser)

	/** Transação do project Finance é a soma dos cronogramas do fin1 */
	if (props.operation === 'projectFinance') return (props.teaser.financing?.fin1?.reduce((acc, item) => (acc + +(item?.timeline || 0)), 0) || 0)

	if (props.operation === 'total') return genValuation(props.teaser)
	if (props.operation === 'minoritário') return genValuation(props.teaser) * 0.3125
	if (props.operation === 'majoritário') return genValuation(props.teaser) * 0.70

	return 0
}
