import { TypeTeaser } from '../../Teaser/types/teaser'

export enum UidsNotification {
	'a01', // Agente
	'c01' // Customer
}

export const getUidsNotification = ({ origination, type }: { origination: TypeTeaser, type?: UidsNotification }): string[] => {
	if (type === UidsNotification.a01) {
		let uidS: string[] = []
		uidS.push(origination.uid)
		uidS.push(origination.uidCoCreator)
		uidS.push(origination.fromOffice)
		uidS = [...new Set(uidS)].filter(uid => !!uid)
		return uidS
	}
	return origination.users
}
