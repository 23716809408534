import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const DocumentPlusOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M8.31525 2H19.0614C19.3939 2 19.7128 2.12591 19.9479 2.35004L28.0075 10.033C28.2426 10.2571 28.3747 10.5611 28.3747 10.878V26.2505C28.369 27.2432 27.9527 28.1938 27.2163 28.8957C26.4799 29.5977 25.4828 29.9945 24.4414 30L24.4345 30H8.30835C7.26693 29.9945 6.26981 29.5977 5.5334 28.8957C4.797 28.1938 4.38076 27.2432 4.37502 26.2505L4.375 26.2439V5.74951C4.38074 4.75677 4.797 3.80625 5.5334 3.10426C6.26981 2.40228 7.26693 2.00549 8.30835 2.00002L8.31525 2ZM8.31924 4.39024C7.9391 4.39297 7.57529 4.53812 7.30643 4.79442C7.03756 5.05072 6.88529 5.39753 6.88243 5.7599V26.2401C6.88529 26.6025 7.03756 26.9493 7.30643 27.2056C7.57524 27.4618 7.93898 27.607 8.31905 27.6098H24.4307C24.8107 27.607 25.1745 27.4618 25.4433 27.2056C25.7122 26.9493 25.8644 26.6025 25.8673 26.2401V11.3731L18.5421 4.39024H8.31924Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M19.0623 2C19.7547 2 20.316 2.53507 20.316 3.19512V9.68293H27.1219C27.8143 9.68293 28.3756 10.218 28.3756 10.878C28.3756 11.5381 27.8143 12.0732 27.1219 12.0732H19.0623C18.3699 12.0732 17.8086 11.5381 17.8086 10.878V3.19512C17.8086 2.53507 18.3699 2 19.0623 2Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.3748 14.8047C17.0672 14.8047 17.6285 15.3398 17.6285 15.9998V23.6827C17.6285 24.3428 17.0672 24.8779 16.3748 24.8779C15.6824 24.8779 15.1211 24.3428 15.1211 23.6827V15.9998C15.1211 15.3398 15.6824 14.8047 16.3748 14.8047Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M11.0918 19.8416C11.0918 19.1816 11.6531 18.6465 12.3455 18.6465H20.4051C21.0975 18.6465 21.6588 19.1816 21.6588 19.8416C21.6588 20.5017 21.0975 21.0367 20.4051 21.0367H12.3455C11.6531 21.0367 11.0918 20.5017 11.0918 19.8416Z' fill='#272827' />
	</Svg>
)
