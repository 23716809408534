/**
* Retorna um documento do banco de dados
* @param {{ id: string, profile: TypeUser }} Parametros
* @returns {{status: 200 | 400, doc?: TypeDoc, docs?: { [key: string]: TypeDoc }, id?: string }} Retornos
* @example função(parâmetro) => exemplo de retorno
*/

import { TypeDbResponse } from '../TypeDBResponse'
import { DocumentData } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { permissionsGetThisCollection } from '../configs/permissionsGetThisCollection'
import { TypeCollections } from '../configs/collections'
import { cfGetBlind } from 'Services/cloudfunctions'

export const get = <TDoc extends DocumentData>(collection: TypeCollections, model: (doc?: DocumentData) => TDoc,) => (
	async ({ id, profile }: { id: string, profile: TypeUser }): Promise<TypeDbResponse<TDoc>> => {

		const serviceResult: TypeDbResponse<TDoc> = {
			status: 400
		}

		const runServerLess = async () => {
			try {
				const response = await firebase
					.firestore()
					.collection(collection)
					.doc(id)
					.get()

				if (response.exists) {
					serviceResult.status = 200
					serviceResult.doc = model({ ...response.data(), _id: id })
					serviceResult.id = id
				}
			}
			catch (error) {
				// throw new Error(`Erro ao obter documento :: ${error}`)
			}
		}

		const runCloud = async () => {
			const res = await cfGetBlind<TDoc>({
				collection: collection,
				id: id
			})
			if (res.status === 200) {
				serviceResult.status = res.status
				serviceResult.doc = model({ ...res?.doc, _id: id })
				serviceResult.id = res.id
			}
			else {
				throw new Error('Erro ao obter documento')
			}
		}

		permissionsGetThisCollection(collection, profile)
			? await runServerLess()
			: await runCloud()

		return serviceResult
	}
)
