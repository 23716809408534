import { TypeScoreProps } from '../types/TypeScoreProps'

export const GEN_MARGIN_FEE = (operation: TypeScoreProps['operation']) => {

	if (operation === 'greenfield') return 0.04
	if (operation === 'corporateFinance') return 0.04
	if (operation === 'projectFinance') return 0.04

	if (operation === 'total') return 0.05
	if (operation === 'minoritário') return 0.05
	if (operation === 'majoritário') return 0.05

	return 0
}
