import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Plus = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>

		<path d='M28.7135 17.248C28.4328 17.5278 28.0585 17.7143 27.4971 17.7143L4.38597 17.6211C3.4503 17.6211 2.70176 16.875 2.70176 15.9424C2.70176 15.0098 3.45029 14.2637 4.38597 14.2637L27.5907 14.2637C28.5264 14.2637 29.2749 15.0098 29.2749 15.9424C29.2749 16.5019 28.9942 16.9682 28.7135 17.248Z' fill='#272827' />
		<path d='M17.1111 28.8109C16.8304 29.0907 16.4561 29.2772 15.8947 29.2772C14.9591 29.2772 14.2105 28.5311 14.2105 27.5985L14.3041 4.56335C14.3041 3.63075 15.0526 2.88467 15.9883 2.88467C16.924 2.88467 17.6725 3.63075 17.6725 4.56335L17.5789 27.5985C17.6725 28.0648 17.4854 28.4379 17.1111 28.8109Z' fill='#272827' />

	</Svg>
)
