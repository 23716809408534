/**
 * Este código TypeScript define tipos para analistas e escritórios, fornece funções
 * para recuperar dados do analista e atribuir dados padrão do escritório se os dados do analista não forem
 * encontrado.
 * @property [key: undefined] – O código que você forneceu define um tipo TypeScript
 * `TAnalyst` representando uma coleção de analistas com seus detalhes como nome,
 * email, link para ligação e escritório. Também inclui uma variedade de objetos de escritório
 * e uma função para recuperar dados do analista com base em um objeto teaser.
 */
//CRIADO POR IA

import { TypeTeaser } from '../types/teaser'

type TAnalyst = {
	[key: string]: {
		name: string,
		email: string,
		linkCall: string,
		office: string,
	}
}

const analysts: TAnalyst = {
	'pedro.rocha@stark.money': {
		name: 'Pedro Rocha',
		email: 'pedro.rocha@stark.money',
		linkCall: 'https://calendly.com/pedrorocha-stark',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'mateus.galdino@stark.money': {
		name: 'Mateus Melo',
		email: 'mateus.galdino@stark.money',
		linkCall: 'https://calendly.com/mateus-galdino/30min',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'bruno.vieira@stark.money': {
		name: 'Bruno Vieira',
		email: 'bruno.vieira@stark.money',
		linkCall: '',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'giovanne.rolim@stark.money': {
		name: 'Giovanne Rolin',
		email: 'giovanne.rolim@stark.money',
		linkCall: '',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'hb@stark.money': {
		name: 'Hayko Bonckewitz',
		email: 'hb@stark.money',
		linkCall: 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0f2B1KkZKRVC9QohiblFxgN8ElbllEyeIhwZhwscaAd7t79tSd6JKEonLZUAZkDGa99bZCT9Mm',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'ph.cortes@stark.money': {
		name: 'Pedro Henrique Lemos',
		email: 'ph.cortes@stark.money',
		linkCall: '',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'luis.uliana@stark.money': {
		name: 'Luis Guilherme Uliana',
		email: 'luis.uliana@stark.money',
		linkCall: 'https://calendly.com/luis-uliana',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'jv.carminatti@stark.money': {
		name: 'João Vitor Carminatti',
		email: 'jv.carminatti@stark.money',
		linkCall: '',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	'gsp@stark.money': {
		name: 'Gualtiero Piccoli',
		email: 'gsp@stark.money',
		linkCall: '',
		office: 'ez22SO3AU9WiDqCLsLKBqHj5lu33'
	},
	//Office Poços | Axios
	'artur.blasi@axioscapital.com.br': {
		name: 'Artur Granato Blasi',
		email: 'sidnei.jr@axioscapital.com.br',
		linkCall: '',
		office: 'BYcx4u7MDKSXnM5FYicdueJXj5i2'

	},
	'hernane.rodrigues@axioscapital.com.br': {
		name: 'Hernane Rodrigues de Paula',
		email: 'hernane.rodrigues@axioscapital.com.br',
		linkCall: '',
		office: 'BYcx4u7MDKSXnM5FYicdueJXj5i2'
	},
	'felipe.brasil@axioscapital.com.br': {
		name: 'Felipe Mello Souza Leite do Brasil',
		email: 'felipe.brasil@axioscapital.com.br',
		linkCall: '',
		office: 'BYcx4u7MDKSXnM5FYicdueJXj5i2'
	},
}

const offices = [
	{
		uid: 'ez22SO3AU9WiDqCLsLKBqHj5lu33',
		name: 'Stark IB',
		email: 'comercial@stark.money',
		linkCall: 'https://calendly.com/stark'
	},
	{
		uid: 'BYcx4u7MDKSXnM5FYicdueJXj5i2',
		name: 'Axios',
		email: 'sidnei.jr@axioscapital.com.br',
		linkCall: 'https://calendly.com/sidnei-jr/30min',
	},
	{
		uid: 'gZyLhK66HmPcrVSVHip4FcUN5r63',
		name: 'Dynamom',
		email: 'luizcyrillo@dynamomcampinas.com',
		linkCall: 'https://calendly.com/dynamom/30min',
	},
	{
		uid: 'ZBdavhShtUdb5qNkyJnLTU18pXU2',
		name: 'O2 Consulting',
		email: 'helio@o2consulting.com.br',
		linkCall: 'https://calendly.com/heliostarkoffice/30min',
	},
	{
		uid: 'jabqouV1YcO38MTQdz3xxkRQDfa2',
		name: 'Zucci',
		email: 'gustavo@zucchi.com.br',
		linkCall: 'https://calendly.com/gustavostarkofficechapeco/30min',
	}
]

const officeDefault = offices.find(office => office.name === 'Stark IB')

/**
 * getAllAnalysts
 *
 * Função que retorna todos os analistas.
 *
 * @returns {Analistas[]}}: { name: string; email: string; linkCall: string; }[]
 *
 * @example getAllAnalysts() => [{ name: 'Pedro Rocha'; email: 'pedro.rocha@stark.money'; linkCall: 'https://calendly.com/pedrorocha-stark'; }]
 */

export const getAllAnalysts = () => Object.values(analysts)

/**
 * getAnalystData
 *
 * Função que retorna os dados do analista responsável de um teaser.
 *
 * @param teaser: TypeTeaser
 * @returns {retorno}: { name: string; email: string; linkCall: string; }
 *
 * @example getAnalystData(teaser) => { name: 'Pedro Rocha'; email: 'pedro.rocha@stark.money'; linkCall: 'https://calendly.com/pedrorocha-stark'; }
 */

export const getAnalystData = (teaser: TypeTeaser): {
	name: string;
	email: string;
	linkCall: string;
} => {
	const res = {
		name: '',
		email: '',
		linkCall: '',
	}
	if (analysts[teaser.analystEmail]) {
		res.name = analysts[teaser.analystEmail].name
		res.email = analysts[teaser.analystEmail].email
		res.linkCall = analysts[teaser.analystEmail].linkCall
	}
	else {
		offices.forEach(office => {
			if (office.uid === teaser.fromOffice) {
				res.name = office.name
				res.email = office.email
				res.linkCall = office.linkCall
			}
		})
		if (res.name === '') {
			res.name = officeDefault?.name || ''
			res.email = officeDefault?.email || ''
			res.linkCall = officeDefault?.linkCall || ''
		}
	}

	return res
}
