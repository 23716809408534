import { InternalColProps } from './col'
import { getSize } from './getSize'


function getResponsiveBreakpoint(value: number) {
	return `
        flex: 0 0 ${getSize(value)}%;
        max-width: ${getSize(value)}%;
        display: block;
    `
}

function getResponsiveData(value: string | number, breakpoint: string) {
	if (typeof value === 'number') {
		return `${breakpoint} {
            ${getResponsiveBreakpoint(value)};
        }`
	}
	else {
		const visibility: string = value === 'hide' ? 'none' : 'block'
		return `${breakpoint} {
            display: ${visibility};
        }`
	}
}

/** @deprecated */
export function getResponsive(props: InternalColProps) {
	const { theme } = props

	let xxs, xs, sm, md, lg, xl, xxl, xxxl

	if (props.xxxl) {
		xxxl = getResponsiveData(props.xxxl, theme.breakpoints?.queries?.['xxxl'] || '@media(min-width : 1441px)')
	}
	if (props.xxl) {
		xxl = getResponsiveData(props.xxl, theme?.breakpoints?.queries?.['xxl'] || '@media(min-width : 1025px) and (max-width : 1440px) ')
	}
	if (props.xl) {
		xl = getResponsiveData(props.xl, theme?.breakpoints?.queries?.['xl'] || '@media(min-width : 901px) and (max-width : 1024px) ')
	}

	if (props.lg) {
		lg = getResponsiveData(props.lg, theme?.breakpoints?.queries?.['lg'] || '@media(min-width : 769px) and (max-width : 900px) ')
	}
	if (props.md) {
		md = getResponsiveData(props.md, theme?.breakpoints?.queries?.['md'] || '@media(min-width : 601px) and (max-width : 768px) ')
	}

	if (props.sm) {
		sm = getResponsiveData(props.sm, theme.breakpoints?.queries?.['sm'] || '@media(min-width : 1441px)')
	}

	if (props.xs) {
		xs = getResponsiveData(props.xs, theme?.breakpoints?.queries?.['xs'] || '@media(min-width : 321px) and (max-width : 480px) ')
	}

	if (props.xxs) {
		xxs = getResponsiveData(props.xxs, theme?.breakpoints?.queries?.['xxs'] || '@media(max-width: 320px)')
	}

	const _query = `${xxxl || ''}${xxl || ''}${xl || ''}${lg || ''}${md || ''}${sm || ''}${xs || ''}${xxs || ''}`

	return _query
}
