import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const TruckFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M24.001 25.2676C23.5037 25.2676 23.0268 25.07 22.6752 24.7184C22.3235 24.3668 22.126 23.8899 22.126 23.3926C22.126 22.8953 22.3235 22.4184 22.6752 22.0668C23.0268 21.7151 23.5037 21.5176 24.001 21.5176C24.4983 21.5176 24.9752 21.7151 25.3268 22.0668C25.6784 22.4184 25.876 22.8953 25.876 23.3926C25.876 23.8899 25.6784 24.3668 25.3268 24.7184C24.9752 25.07 24.4983 25.2676 24.001 25.2676ZM25.876 14.0176L28.326 17.1426H22.751V14.0176H25.876ZM9.00098 25.2676C8.5037 25.2676 8.02678 25.07 7.67515 24.7184C7.32352 24.3668 7.12598 23.8899 7.12598 23.3926C7.12598 22.8953 7.32352 22.4184 7.67515 22.0668C8.02678 21.7151 8.5037 21.5176 9.00098 21.5176C9.49826 21.5176 9.97517 21.7151 10.3268 22.0668C10.6784 22.4184 10.876 22.8953 10.876 23.3926C10.876 23.8899 10.6784 24.3668 10.3268 24.7184C9.97517 25.07 9.49826 25.2676 9.00098 25.2676ZM26.501 12.1426H22.751V7.14258H5.25098C3.86348 7.14258 2.75098 8.25508 2.75098 9.64258V23.3926H5.25098C5.25098 24.3871 5.64606 25.341 6.34933 26.0442C7.05259 26.7475 8.00641 27.1426 9.00098 27.1426C9.99554 27.1426 10.9494 26.7475 11.6526 26.0442C12.3559 25.341 12.751 24.3871 12.751 23.3926H20.251C20.251 24.3871 20.6461 25.341 21.3493 26.0442C22.0526 26.7475 23.0064 27.1426 24.001 27.1426C24.9955 27.1426 25.9494 26.7475 26.6526 26.0442C27.3559 25.341 27.751 24.3871 27.751 23.3926H30.251V17.1426L26.501 12.1426Z' fill='#272827' />
	</Svg>
)


