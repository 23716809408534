import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const TrashFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M24.5594 6.8648H20.2816L19.0594 5.64258H12.9482L11.726 6.8648H7.44824V9.30924H24.5594V6.8648ZM8.67046 25.1981C8.67046 25.8464 8.928 26.4682 9.38643 26.9266C9.84485 27.385 10.4666 27.6426 11.1149 27.6426H20.8927C21.541 27.6426 22.1627 27.385 22.6212 26.9266C23.0796 26.4682 23.3371 25.8464 23.3371 25.1981V10.5315H8.67046V25.1981Z' fill='#272827' />
	</Svg>
)


