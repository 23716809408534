import { ICONS_STEPS_WITH_LABEL } from '../../../Constants/ICONS_STEPS'
import { clone } from '../../../Functions/Utils/clone'
import { TypeNotification } from '../types/notification'

/** Função que verifica se a notifacação é automática. Automática quer dizer que não foi escrita e
 * enviada manualmente por um usuário mas disparada automaticamente por alguma ação na plataforma
 *
 * @param {Object} [notification] TypeNotification
 *
 * @returns boolean
 */
export const isAutomatic = ({
	notification,
}: {
	notification: TypeNotification;
}): boolean => {

	const cloneNotification = clone(notification)

	// se o tipo da notificação está no tipo do ícone utilizado
	return ICONS_STEPS_WITH_LABEL.VALUES.map(icon => icon.notificationId).includes(cloneNotification.type)
}

