import { isInRange } from '../../../../../Functions/Utils/isInRange'
import { getEbWithSeptemberRule } from '../../get/getEbWithSeptemberRule'
import { getRbWithSeptemberRule } from '../../get/getRbWithSeptemberRule'
import { getTotalDebit } from '../../get/getTotalDebit'
import { isTech } from '../../isTech'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreLeverage = (props: TypeScoreProps) => {
	const res: TypeScoreStark['scoreItens'] = []

	const reason = isTech(props.teaser) ? getRbWithSeptemberRule(props.teaser) : getEbWithSeptemberRule(props.teaser)
	const debtReason = getTotalDebit(props.teaser) / reason

	const number1 = isTech(props.teaser) ? 0.6 : 2.5
	const number2 = isTech(props.teaser) ? 0.9 : 3
	const number3 = isTech(props.teaser) ? 1.2 : 4.5

	if (isInRange(debtReason, '<', number1))
		res.push({ // adiciona 0
			'pontosMax': 5,
			'pontos': 0,
			'pontosCor': 'red',
			'setor': 'Nota de alavancagem',
			'item': 'Penalidade sobre alavancagem',
			'itemDescrição': 'Sem penalidade para alavancagem',
			'metaDados': '',
			devolutiva: ''
		})
	if (isInRange(number1, '<=', debtReason, '<', number2))
		res.push({ // adiciona -2
			'pontosMax': 5,
			'pontos': -2,
			'pontosCor': 'red',
			'setor': 'Nota de alavancagem',
			'item': 'Penalidade sobre alavancagem',
			'itemDescrição': `Penalidade para nota de alavancagem entre ${number1} e ${number2}`,
			'metaDados': '',
			devolutiva: ''
		})

	if (isInRange(number2, '<=', debtReason, '<', number3))
		res.push({ // adiciona -3
			'pontosMax': 5,
			'pontos': -3,
			'pontosCor': 'red',
			'setor': 'Nota de alavancagem',
			'item': 'Penalidade sobre alavancagem',
			'itemDescrição': `Penalidade para nota de alavancagem entre ${number1} e ${number2}`,
			'metaDados': '',
			devolutiva: ''
		})

	if (isInRange(number3, '<=', debtReason))
		res.push({ // adiciona -5
			'pontosMax': 5,
			'pontos': -5,
			'pontosCor': 'red',
			'setor': 'Nota de alavancagem',
			'item': 'Penalidade sobre alavancagem',
			'itemDescrição': `Penalidade para nota de alavancagem entre ${number1} e ${number2}`,
			'metaDados': '',
			devolutiva: ''
		})

	return res
}
