import { create } from './methods/create'
import { getCompanyDetail } from './methods/getCompanyDetail'
import { save } from './methods/save'
import { publish } from './methods/publish'
import { getPercentageCompleted } from './methods/getPercentageCompleted'
import { getStatus } from './methods/getStatus'
import { validateFields } from './methods/validateFields'
import { validateField } from './methods/validateField'
import { validateGuarantees } from './methods/validateGuarantees'
import { createOffline } from './methods/createOffline'
import { validateFinancing } from './methods/validateFinancing'
import { addFile } from './methods/addFile'
import { getUidsNotification } from './methods/getUidsNotification'
import { getAnalystEmail } from './methods/getAnalystEmail'
import { saveLogo } from './methods/saveLogo'
import { getCommissionPerOrigination } from './methods/getCommissionPerOrigination'

export const fnOrigination = {
	getAnalystEmail,
	getCompanyDetail,
	create,
	save,
	update: save,
	publish,
	getPercentageCompleted,
	getStatus,
	validateFields,
	validateGuarantees,
	validateField,
	createOffline,
	validateFinancing,
	addFile,
	getUidsNotification,
	saveLogo,
	getCommissionPerOrigination
}
