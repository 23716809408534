import { clone } from '../../../Functions/Utils/clone'
import { TypeNotification } from '../types/notification'

export const checkRead =
	({ notification, uid }: { notification: TypeNotification, uid: string }) => {

		const cloneNotification = clone(notification)
		const cloneUid = clone(uid)

		return cloneNotification.isRead || cloneNotification.readBy?.includes(cloneUid)
	}


