import { FinancingItemProps } from '../../../../../Constants/FINANCING'
import { STATUS_TERRENO } from '../../../../../Constants/STATUS_TERRENO'
import { SUB_TYPE_OPERATIONS_KEYS } from '../../../../../Constants/SUB_TYPE_OPERATIONS'
import { TYPE_OPERATION_CODE } from '../../../../../Constants/TYPE_OPERATION'
import { TypeTeaser } from '../../../../../Functions/Teaser/types/teaser'
import { generateNameIdOperation } from '../../generateNameIdOperation'

/** Recebe um fin1 e um id, cria um objeto, que é uma nova operação com os dados do fin1 passado e retorna a nova operação  */
export const translateTerminoDeObras = (fin1: FinancingItemProps, id: string) => {
	const newOperationTerminoDeObras: TypeTeaser['operations'][0] = {
		id: id,
		operationType: TYPE_OPERATION_CODE['Crédito']._id,
		operationSubType: ['projectFinance'] as SUB_TYPE_OPERATIONS_KEYS[],
		motivoCaptacao: fin1.observation,
		construction: fin1.construction,
		saldoCarteiraRecebiveis: fin1.saldoCarteiraRecebiveis,
		timeline: fin1.timeline,
		sales: fin1.sales,
		vgv: fin1.vgv,
		// statusTerreno: STATUS_TERRENO.s000._id,
		statusTerreno: fin1.statusTerreno || STATUS_TERRENO.s000._id,
		valorAtualCaixa: fin1.valorAtualCaixa || 0,
		type: fin1.type,
		valorReperfilamento: 0,
		nameIdGenerated: fin1.nameIdGenerated
	}

	return newOperationTerminoDeObras
}
