const _AGENTS_SOURCE = {
	as000: { label: '', _id: 'as000' },
	as001: { label: 'Indicação', _id: 'as001' },
	as002: { label: 'Inbound', _id: 'as002' },
	as003: { label: 'Outbound', _id: 'as003' },
} as const

const GET = (code: string) => {
	const _code = code as keyof typeof _AGENTS_SOURCE

	if (!_AGENTS_SOURCE[_code]) {
		return (_AGENTS_SOURCE.as000)
	}

	return _AGENTS_SOURCE[_code]
}

const KEYS = Object.keys(_AGENTS_SOURCE)
const VALUES = Object.values(_AGENTS_SOURCE)

export type AGENTS_SOURCE_TYPE = keyof typeof _AGENTS_SOURCE

export const AGENTS_SOURCE = {
	VALUES: VALUES,
	KEYS: KEYS,
	GET: GET
}
