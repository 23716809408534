import { clone } from '../../Utils/clone'
import { TypeMatch } from '../types/match'
import { db5 } from '../../../Services/db5'

/**
 * Responsável por adiconar um novo documento a um match
 * @param match
 * @param document
 * @returns match
 */

export const addDocument = async ({ match, document }: { match: TypeMatch, document: TypeMatch['documents'][0] }) => {
	const cloneMatch = clone(match)
	const cloneDocument = clone(document)

	const _match: TypeMatch = { ...cloneMatch, documents: [...cloneMatch.documents, cloneDocument] }
	const { _id } = cloneMatch
	await db5.matches.update({ id: _id, doc: { documents: _match.documents } })
	return _match
}
