import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const TeaserOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M21.4292 22.0518H10.5655C9.96194 22.0518 9.3584 21.6391 9.3584 20.9514C9.3584 20.2637 9.81105 19.8511 10.5655 19.8511H21.4292C22.0328 19.8511 22.6363 20.2637 22.6363 20.9514C22.6363 21.6391 22.0328 22.0518 21.4292 22.0518Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.86946 20.317C9.72364 20.45 9.62774 20.6568 9.62774 20.9514C9.62774 21.4838 10.0881 21.8062 10.5654 21.8062H21.4291C21.9064 21.8062 22.3668 21.4838 22.3668 20.9514C22.3668 20.419 21.9064 20.0967 21.4291 20.0967H10.5654C10.2422 20.0967 10.0153 20.1841 9.86946 20.317ZM9.48842 19.9697C9.75753 19.7244 10.1342 19.6055 10.5654 19.6055H21.4291C22.1589 19.6055 22.9056 20.1084 22.9056 20.9514C22.9056 21.7945 22.1589 22.2974 21.4291 22.2974H10.5654C9.83559 22.2974 9.08887 21.7945 9.08887 20.9514C9.08887 20.5583 9.2193 20.215 9.48842 19.9697Z' fill='#272827' />
		<path d='M21.4292 16.9629H10.5655C9.96194 16.9629 9.3584 16.5503 9.3584 15.8626C9.3584 15.1748 9.81105 14.7622 10.5655 14.7622H21.4292C22.0328 14.7622 22.6363 15.1748 22.6363 15.8626C22.6363 16.5503 22.0328 16.9629 21.4292 16.9629Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.86946 15.2282C9.72364 15.3611 9.62774 15.568 9.62774 15.8626C9.62774 16.395 10.0881 16.7173 10.5654 16.7173H21.4291C21.9064 16.7173 22.3668 16.395 22.3668 15.8626C22.3668 15.3302 21.9064 15.0078 21.4291 15.0078H10.5654C10.2422 15.0078 10.0153 15.0952 9.86946 15.2282ZM9.48842 14.8808C9.75753 14.6355 10.1342 14.5166 10.5654 14.5166H21.4291C22.1589 14.5166 22.9056 15.0195 22.9056 15.8626C22.9056 16.7056 22.1589 17.2085 21.4291 17.2085H10.5654C9.83559 17.2085 9.08887 16.7056 9.08887 15.8626C9.08887 15.4695 9.2193 15.1261 9.48842 14.8808Z' fill='#272827' />
		<path d='M14.7903 11.7359H10.5655C9.96194 11.7359 9.3584 11.3232 9.3584 10.6355C9.3584 9.94779 9.81105 9.53516 10.5655 9.53516H14.7903C15.3938 9.6727 15.8465 10.2229 15.8465 10.7731C15.8465 11.3232 15.3938 11.7359 14.7903 11.7359Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M9.86946 10.0011C9.72364 10.134 9.62774 10.3409 9.62774 10.6355C9.62774 11.1679 10.0881 11.4903 10.5654 11.4903H14.7902C15.2449 11.4903 15.5769 11.1876 15.5769 10.7731C15.5769 10.3418 15.2207 9.90179 14.7549 9.78078H10.5654C10.2422 9.78078 10.0153 9.8682 9.86946 10.0011ZM9.48842 9.65377C9.75753 9.40845 10.1342 9.28955 10.5654 9.28955H14.8233L14.8555 9.29688C15.5822 9.46249 16.1158 10.1132 16.1158 10.7731C16.1158 11.4589 15.5425 11.9815 14.7902 11.9815H10.5654C9.83559 11.9815 9.08887 11.4786 9.08887 10.6355C9.08887 10.2424 9.2193 9.89909 9.48842 9.65377Z' fill='#272827' />
		<path d='M16.0001 29.7544C5.89078 29.7544 2.26953 26.1782 2.26953 16C2.26953 5.82175 5.89078 2.24561 16.0001 2.24561C26.1094 2.24561 29.7307 5.82175 29.7307 16C29.7307 26.1782 26.2603 29.7544 16.0001 29.7544ZM16.0001 4.30876C7.09786 4.30876 4.53281 7.05964 4.53281 16C4.53281 24.9403 7.24875 27.6912 16.0001 27.6912C24.9023 27.6912 27.4674 24.9403 27.4674 16C27.4674 7.05964 24.9023 4.30876 16.0001 4.30876Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M5.54274 5.47154C3.4451 7.5666 2.53888 10.9242 2.53888 16C2.53888 21.0758 3.4451 24.4334 5.54274 26.5285C7.6339 28.6171 10.9692 29.5088 16 29.5088C21.1074 29.5088 24.4413 28.6166 26.5131 26.5292C28.5919 24.4348 29.4611 21.0778 29.4611 16C29.4611 10.9242 28.5549 7.5666 26.4573 5.47154C24.3661 3.38294 21.0308 2.49123 16 2.49123C10.9692 2.49123 7.6339 3.38294 5.54274 5.47154ZM5.14472 5.14039C7.39151 2.89636 10.9214 2 16 2C21.0786 2 24.6085 2.89636 26.8553 5.14039C29.0956 7.37796 30 10.8975 30 16C30 21.1005 29.1341 24.6207 26.9127 26.8588C24.6841 29.1041 21.1528 30 16 30C10.9214 30 7.39151 29.1036 5.14472 26.8596C2.9044 24.622 2 21.1025 2 16C2 10.8975 2.9044 7.37796 5.14472 5.14039ZM6.72632 6.6389C8.56203 4.74959 11.5346 4.06316 16 4.06316C20.4654 4.06316 23.438 4.74959 25.2737 6.6389C27.1 8.51856 27.7367 11.5267 27.7367 16C27.7367 20.4733 27.1 23.4814 25.2737 25.3611C23.438 27.2504 20.4654 27.9368 16 27.9368C11.6085 27.9368 8.6378 27.2499 6.78386 25.3621C4.93849 23.483 4.26328 20.4756 4.26328 16C4.26328 11.5267 4.9 8.51856 6.72632 6.6389ZM7.12972 6.96461C5.44796 8.69547 4.80216 11.5329 4.80216 16C4.80216 20.4647 5.48491 23.3029 7.18535 25.0344C8.87721 26.7572 11.6401 27.4456 16 27.4456C20.4368 27.4456 23.1979 26.7566 24.8703 25.0354C26.552 23.3045 27.1978 20.4671 27.1978 16C27.1978 11.5329 26.552 8.69547 24.8703 6.96461C23.1979 5.24339 20.4368 4.55439 16 4.55439C11.5632 4.55439 8.80209 5.24339 7.12972 6.96461Z' fill='#272827' />
	</Svg>
)
