import { clone } from './clone'

/**
 * Formata números em que 1000 se torna 1k, 1000000 se torna 1M...
 * @param num O número a ser formatado.
 * @param precision A precisão desejada para o número formatado.
 * Define quantas casas decimais devem ser mostradas.
 * @returns { String } retorna 0 número formatado com sufixo K, M, B, T ou sem sufixo, dependendo do valor.
 * @example formatNumberToKMBT(1100) => 1.1K
 * @example  formatNumberToKMBT(11200) => 11.2K
 * @example  formatNumberToKMBT(-11200) => -11.2K
 * @example  formatNumberToKMBT(1110000) => 1.1M
 * @example  formatNumberToKMBT(1400000000) => 1.4B
 * @example  formatNumberToKMBT(1400000000000, 2) => 1.40T
 */
export const formatNumberToKMBT = (num: number, precision = 1) => {

	const cloneNum = clone(num)
	const clonePrecision = clone(precision)

	const map = [
		{ suffix: 'T', threshold: 1e12 },
		{ suffix: 'B', threshold: 1e9 },
		{ suffix: 'M', threshold: 1e6 },
		{ suffix: 'K', threshold: 1e3 },
		{ suffix: '', threshold: 1 },
	]

	const found = map.find(x => Math.abs(cloneNum) >= x.threshold)
	if (found) {
		const value = (cloneNum / found.threshold).toFixed(clonePrecision)
		const valueSplited = value.split('.')
		const valueWithoutLastChar0 = valueSplited[valueSplited.length - 1] === '0' ? valueSplited[0] : value
		const formatted = valueWithoutLastChar0 + found.suffix
		return formatted
	}

	return String(cloneNum)
}
