import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

export const removeBuyerCompany =
	(
		{ index, transaction }:
			{ index: number, transaction: TypeTransaction }) => {
		const copyOfTransaction = { ...transaction }
		copyOfTransaction.buyerCompanies = [...transaction.buyerCompanies]
		copyOfTransaction.buyerCompanies.splice(index, 1)
		return copyOfTransaction.buyerCompanies
	}
