/**
* Converte de pixels para rem
* @param {Number} numberInPixels {Number} Número em pixels
* @returns {String} {String} Número convertido para rem
* @example convertToRem(14) => 0.875rem
*/

export const convertToRem = (numberInPixels: number) => {
	const cloneNumberInPixels: number = JSON.parse(JSON.stringify(numberInPixels))

	return `${cloneNumberInPixels / 16}rem`
}
