/**
 * A função `inComiteApproved` verifica se um teaser foi aprovado por um
 * comitê.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente contém informações sobre um teaser, como data de publicação,
 * status do cliente e status de validação. A função `inComiteApproved` leva um
 * objeto teaser como entrada, clona-o usando a função `clone` e
 * @returns A função `inComiteApproved` está retornando um valor booleano baseado em
 * as condições especificadas na instrução return. Ele verifica se o
 * A propriedade `dataPublicacao` do objeto `teaser` é verdadeira, o `statusCustomer`
 * a propriedade é falsa e a propriedade `validation.approved` é verdadeira. Se tudo
 * essas condições são atendidas, a função retorna `true`,
 */
//CRIADO POR IA
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'


export const inComiteApproved = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.dataPublicacao && !cloneTeaser.statusCustomer && cloneTeaser.validation.approved

}
