/**
 * Esta função TypeScript atualiza um objeto teaser em um banco de dados usando um objeto clonado
 * teaser, objeto e ID.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é um objeto do tipo
 * `TypeTeaser`, que é usado para representar dados de teaser no aplicativo.
 * @param {any} obj - O parâmetro `obj` na função `update` é um objeto
 * que contém os dados que você deseja atualizar no teaser. Poderia incluir
 * propriedades como título, descrição, imagem, etc.
 * @param {string} id - O parâmetro `id` é uma string que representa o único
 * identificador do objeto teaser que deseja atualizar no banco de dados.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeTeaser } from '../types/teaser'

// set
export const update = async (teaser: TypeTeaser, obj: any, id: string) => {

	const cloneTeaser = clone(teaser)
	const cloneObj = clone(obj)
	const cloneId = clone(id)

	await db5.teasers.update({
		id: cloneId,
		doc: cloneObj
	})
}
