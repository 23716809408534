import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const DownloadOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M3.54941 18.6006C4.40512 18.6006 5.09881 19.2943 5.09881 20.15V25.6836C5.09881 26.0065 5.22707 26.3161 5.45538 26.5444C5.68369 26.7727 5.99333 26.901 6.31621 26.901H25.6838C26.0067 26.901 26.3163 26.7727 26.5446 26.5444C26.7729 26.3161 26.9012 26.0065 26.9012 25.6836V20.15C26.9012 19.2943 27.5949 18.6006 28.4506 18.6006C29.3063 18.6006 30 19.2943 30 20.15V25.6836C30 26.8283 29.5453 27.9262 28.7358 28.7356C27.9264 29.5451 26.8285 29.9998 25.6838 29.9998H6.31621C5.17148 29.9998 4.07363 29.5451 3.26419 28.7356C2.45474 27.9262 2 26.8283 2 25.6836V20.15C2 19.2943 2.69369 18.6006 3.54941 18.6006Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M7.98701 12.1374C8.5921 11.5323 9.57313 11.5323 10.1782 12.1374L15.9996 17.9588L21.821 12.1374C22.4261 11.5323 23.4071 11.5323 24.0122 12.1374C24.6173 12.7425 24.6173 13.7235 24.0122 14.3286L17.0952 21.2456C16.4901 21.8507 15.5091 21.8507 14.904 21.2456L7.98701 14.3286C7.38193 13.7235 7.38193 12.7425 7.98701 12.1374Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M15.9996 2C16.8553 2 17.549 2.69369 17.549 3.54941V20.1502C17.549 21.0059 16.8553 21.6996 15.9996 21.6996C15.1439 21.6996 14.4502 21.0059 14.4502 20.1502V3.54941C14.4502 2.69369 15.1439 2 15.9996 2Z' fill='#272827' />
	</Svg>
)
