export const FOLLOWUP_STATUS = {
	f100: { label: 'Em andamento', _id: 'f100' },
	f200: { label: 'Ignorado', _id: 'f200' },
	f300: { label: 'Declinado', _id: 'f300' },
	f400: { label: 'Em contato', _id: 'f400' },
} as const

export const FOLLOWUP_STATUS_GET = (code: string) => {
	const _code = code as keyof typeof FOLLOWUP_STATUS

	if (!FOLLOWUP_STATUS[_code]) {
		return (FOLLOWUP_STATUS.f100)
	}

	return FOLLOWUP_STATUS[_code]
}
export const FOLLOWUP_STATUS_TYPES_VALUES = Object.values(FOLLOWUP_STATUS)
export const FOLLOWUP_STATUS_TYPES_KEYS = Object.keys(FOLLOWUP_STATUS)
