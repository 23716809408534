export const TESE_CREDIT_SECTORS_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_SECTORS

	if (!TESE_CREDIT_SECTORS[_code]) {
		return (TESE_CREDIT_SECTORS.s000)
	}

	return TESE_CREDIT_SECTORS[_code]
}

export const TESE_CREDIT_SECTORS = {
	s000: { label: 'Sem setor', _id: 's000' },
	s001: { label: 'Real Estate', _id: 's001' },
	s002: { label: 'Tecnologia', _id: 's002' },
	s003: { label: 'Legal Claim', _id: 's003' },
	s004: { label: 'Agronegócios', _id: 's004' },
	s005: { label: 'Generalista', _id: 's005' },
} as const

export const TESE_CREDIT_SECTORS_VALUES = Object.values(TESE_CREDIT_SECTORS)
export const TESE_CREDIT_SECTORS_KEYS = Object.keys(TESE_CREDIT_SECTORS)
