import React, { createContext, useContext, useState } from 'react'
import { TGlobalModal } from 'Components/GlobalModal'

const _useGlobalContext = () => {

	const [globalLoading, _setGlobalLoading] = useState(false)// responsável por mostrar/ocultar o modal de loading
	const [globalModal, setGlobalModal] = useState<TGlobalModal>()// responsável por mostrar/ocultar o modal global
	const setGlobalLoading = (status: boolean) => {
		_setGlobalLoading(status)
	}
	return ({
		globalLoading, setGlobalLoading,
		globalModal, setGlobalModal,
	})
}
const GlobalContext = createContext({} as ReturnType<typeof _useGlobalContext>)

export const useGlobalContext = () => useContext(GlobalContext)
export const GlobalProvider: React.FC = ({ children }) => {
	const controller = _useGlobalContext()
	return (
		<GlobalContext.Provider value={controller}>
			{children}
		</GlobalContext.Provider>
	)
}
