import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'
import { TypeTransactionProperties } from '../../Types/TypeTransactionProperties'
import { TRANSACTION_TYPES } from 'Screens/Sinatra/Constants/TransactionTypes'
import { fnValuation } from '../Valuation'

export const getMedian = ({ valuation, property, type }: { type?: keyof typeof TRANSACTION_TYPES, valuation: TypeValuation, property: TypeTransactionProperties }) => {
	let transactions = valuation.transactions
	if (type === 'private') transactions = fnValuation.getClosedCapitalTransactions(valuation)
	if (type === 'public') transactions = fnValuation.getOpenCapitalTransactions(valuation)

	const ascendingOrderedCompanies = sortAscendingByProperty({ transactions, property })
	const isEven = ascendingOrderedCompanies.length % 2 === 0
	const valuesOfProperty: number[] = []
	transactions.map(transaction => {
		const value = transaction[property]
		if (value) valuesOfProperty.push(value)
	})

	return isEven ? medianByEvenNumber(valuesOfProperty) : medianByOddNumber(valuesOfProperty)
}

// mediana de quantidade ímpar
const medianByOddNumber = (arrayOfValues: number[]) => {
	const centerValue = arrayOfValues.length > 1 ? (arrayOfValues.length + 1) / 2 : 1
	const median = arrayOfValues[centerValue - 1]
	return median
}

// mediana de quantidade par
const medianByEvenNumber = (arrayOfValues: number[]) => {
	const firstCenterPosition = arrayOfValues.length / 2
	const secondCenterPosition = firstCenterPosition + 1
	const firstCenterValue = arrayOfValues[firstCenterPosition - 1]
	const secondCenterValue = arrayOfValues[secondCenterPosition - 1]
	return (firstCenterValue + secondCenterValue) / 2
}

/**
 * Ordena o array de empresas selecionadas de acordo com a propriedade selecionada
 * @param transactions TypeValuation['transactions']
 * @param property 'ebitda' | 'ebitdaMultiple' | 'enterpriseValue' | 'billing' | 'billingMultiple'
 * @returns Retorna um TypeValuation['transactions'] ordenado
 */
const sortAscendingByProperty = ({ transactions, property }: { transactions: TypeValuation['transactions'], property: 'ebitda' | 'ebitdaMultiple' | 'enterpriseValue' | 'billing' | 'billingMultiple' }) => transactions.sort(function (x, y) {
	const _property = property as keyof TypeValuation['transactions'][0]
	const a = x[_property]
	const b = y[_property]
	if (typeof a === 'number' && typeof b === 'number') {
		return a - b
	}
	return 1
})
