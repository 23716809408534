import convertToMoney from 'Functions/Utils/convertToMoney'
import { coinToNumber } from 'Functions/Utils/moeda'
import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

export const getEnterpriseValue =
	(transaction: TypeTransaction): { formatted: string, value: number } => {
		const percentageBought = transaction.percentage || 0
		const result = { formatted: '', value: 0 }
		if (transaction.value && percentageBought) {
			const enterpriseValue = Number((transaction.value * 100) / percentageBought).toFixed(2)
			result.formatted = convertToMoney(enterpriseValue, null)
			result.value = Number(enterpriseValue)
			return result

		}
		return result
	}
