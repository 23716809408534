import {
	SPACE_1, SPACE_10, SPACE_11, SPACE_12,
	SPACE_13, SPACE_14, SPACE_15, SPACE_16,
	SPACE_17, SPACE_18, SPACE_19, SPACE_2,
	SPACE_20, SPACE_21, SPACE_22, SPACE_23,
	SPACE_24, SPACE_25, SPACE_26, SPACE_27,
	SPACE_28, SPACE_29, SPACE_3, SPACE_30,
	SPACE_31, SPACE_32, SPACE_33, SPACE_34,
	SPACE_35, SPACE_36, SPACE_37, SPACE_38,
	SPACE_39, SPACE_4, SPACE_40, SPACE_5,
	SPACE_6, SPACE_7, SPACE_8, SPACE_9
} from '../../Tokens'
import { ISpace } from '../ISpace'

export const spaces: ISpace = {
	1: {
		value: SPACE_1
	},
	2: {
		value: SPACE_2
	},
	3: {
		value: SPACE_3
	},
	4: {
		value: SPACE_4
	},
	5: {
		value: SPACE_5
	},
	6: {
		value: SPACE_6
	},
	7: {
		value: SPACE_7
	},
	8: {
		value: SPACE_8
	},
	9: {
		value: SPACE_9
	},
	10: {
		value: SPACE_10
	},
	11: {
		value: SPACE_11
	},
	12: {
		value: SPACE_12
	},
	13: {
		value: SPACE_13
	},
	14: {
		value: SPACE_14
	},
	15: {
		value: SPACE_15
	},
	16: {
		value: SPACE_16
	},
	17: {
		value: SPACE_17
	},
	18: {
		value: SPACE_18
	},
	19: {
		value: SPACE_19
	},
	20: {
		value: SPACE_20
	},
	21: {
		value: SPACE_21
	},
	22: {
		value: SPACE_22
	},
	23: {
		value: SPACE_23
	},
	24: {
		value: SPACE_24
	},
	25: {
		value: SPACE_25
	},
	26: {
		value: SPACE_26
	},
	27: {
		value: SPACE_27
	},
	28: {
		value: SPACE_28
	},
	29: {
		value: SPACE_29
	},
	30: {
		value: SPACE_30
	},
	31: {
		value: SPACE_31
	},
	32: {
		value: SPACE_32
	},
	33: {
		value: SPACE_33
	},
	34: {
		value: SPACE_34
	},
	35: {
		value: SPACE_35
	},
	36: {
		value: SPACE_36
	},
	37: {
		value: SPACE_37
	},
	38: {
		value: SPACE_38
	},
	39: {
		value: SPACE_39
	},
	40: {
		value: SPACE_40
	}
}
