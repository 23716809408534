import { TEASER_FIELDS } from '../../../Constants/TEASER_FIELDS'
import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { validateFields } from './validateFields'

const ZERO_VALUE = '0.00'

export const getPercentageCompleted = (origination: TypeTeaser) => {
	/*const {typeOperation, tagNotFound, indebtedness, noGuarantees, companyRating, guarantees, hasSite, desiredValue, projectBudget } = origination
	const itens:{[key:string]:boolean} = {
		[TEASER_FIELDS.cnpj]: true,
		[TEASER_FIELDS.nome]: true,
		[TEASER_FIELDS.typeOperation]: true,
		[TEASER_FIELDS.companyRating]: true
	}
	const errors = validateFields(origination)

	// if (hasSite) {
	// 	itens[TEASER_FIELDS.site] = true
	// }
	// else{
	// 	itens[TEASER_FIELDS.description] = true
	// }

	itens[TEASER_FIELDS.description] = true

	if (typeOperation === TYPE_OPERATION.op1 || typeOperation === TYPE_OPERATION.op3) {
		itens[TEASER_FIELDS.etr] = true
		// itens[TEASER_FIELDS.rb0] = true
		// itens[TEASER_FIELDS.rb1] = true
		itens[TEASER_FIELDS.rb2] = true
		// itens[TEASER_FIELDS.rb3] = true

		// itens[TEASER_FIELDS.eb0] = true
		// itens[TEASER_FIELDS.eb1] = true
		itens[TEASER_FIELDS.eb2] = true
		// itens[TEASER_FIELDS.eb3] = true
			
		if (indebtedness) {
			// itens[TEASER_FIELDS.fornecedores] = true
			// itens[TEASER_FIELDS.outrasdividas] = true
		}
	}
	const checkGuarantees = fnOrigination.validateGuarantees(origination)
	if (typeOperation === TYPE_OPERATION.op2 || typeOperation === TYPE_OPERATION.op3) {
		itens[TEASER_FIELDS.desiredValue] = true
		
		if (origination.isProjectFinancing) {
			itens[TEASER_FIELDS.projectBudget] = true
		}

		if (checkGuarantees) {
			if (checkGuarantees[TEASER_FIELDS.guarantees]) {
				itens[TEASER_FIELDS.guarantees] = true
			}
			else if(checkGuarantees[TEASER_FIELDS.noGuarantees]){
				itens[TEASER_FIELDS.noGuarantees] = true
			}
		}
	}

	if (indebtedness) {
		// itens[TEASER_FIELDS.emprestimos] = true
		// itens[TEASER_FIELDS.tributos] = true
	}

	itens[TEASER_FIELDS.rb1] = true
	itens[TEASER_FIELDS.eb1] = true

	if (tagNotFound) {
		itens[TEASER_FIELDS.tagNotFound] = true
	}

	if (!tagNotFound && companyRating === COMPANY_RATING.rating2) {
		itens[TEASER_FIELDS.tagTech] = true
	}

	if (!tagNotFound && companyRating === COMPANY_RATING.rating1) {
		itens[TEASER_FIELDS.tagsNegative] = true
	}


	const totalItens = Object.keys(itens).length
	let items = 0

	Object.keys(itens).map(k => {
		const value = origination[k as keyof TypeTeaser]

		if (k === TEASER_FIELDS.desiredValue) {
			if (!desiredValue || desiredValue === ZERO_VALUE) {
				return
			}
		}

		if (k === TEASER_FIELDS.projectBudget) {
			if (!projectBudget || projectBudget === ZERO_VALUE) {
				return
			}
		}
	
		if (k === TEASER_FIELDS.etr) {
			if(!origination.etr[ETR['Aquisição Majoritária']] &&
			!origination.etr[ETR['Aquisição Minoritária']] &&
			!origination.etr[ETR['Aquisição Total']]){
				return
			}
		}

		if (k === TEASER_FIELDS.site) {
			if (!validateURL(origination[TEASER_FIELDS.site])) {
				return
			}
		}

		if (k === TEASER_FIELDS.tagTech) {
			if(!origination[TEASER_FIELDS.tagTech].length){
				return
			}
		}

		if (k === TEASER_FIELDS.tagsNegative) {
			if(origination[TEASER_FIELDS.tagsNegative].length === origination[TEASER_FIELDS.tags].length){
				return
			}
		}

		if (k === TEASER_FIELDS.eb1 || k === TEASER_FIELDS.eb2 || k === TEASER_FIELDS.rb1 || k === TEASER_FIELDS.rb2 ) {
			if (!validateField({field:k, value})) {
				return
			}
		}

		if (k === TEASER_FIELDS.guarantees) {
			if(checkGuarantees){
				return
			}
		}

		if(!value){
			console.log(k, 'value not found')
			return
		}

		items++
	})*/

	const validation = validateFields(origination)
	const allFields: { [key: string]: boolean } = {
		...validation.fields,
		fantasyName: true,
		site: true,
		companyRating: true
	}

	if (origination.typeOperation !== TYPE_OPERATION.op1) {
		allFields.noGuarantees = true
		allFields.guarantees = true
	}


	const totalItens = Object.keys(allFields).length
	let count = 0
	Object.keys(allFields).map(k => {
		let value = origination[k as keyof TypeTeaser]

		if (k === TEASER_FIELDS.site && validation.errors.site) {
			count++
			return
		}

		if (!origination.noGuarantees) {
			const checkGuarantees = k === TEASER_FIELDS.guarantees && origination.guarantees && (validation.errors.guarantees || !Object.keys(origination.guarantees).length)
			if (checkGuarantees) {
				count++
				return
			}
		}

		if (k === TEASER_FIELDS.noGuarantees) value = true
		if (k === TEASER_FIELDS.noGuarantees && validation.errors.noGuarantees) {
			count++
			return
		}

		if (k === TEASER_FIELDS.financingObras) value = true
		if (k === TEASER_FIELDS.financingObras && validation.errors.financingObras) {
			count++
			return
		}

		if (k === TEASER_FIELDS.financingProjetosZero) value = true
		if (k === TEASER_FIELDS.financingProjetosZero && validation.errors.financingProjetosZero) {
			count++
			return
		}

		const checkEtr: boolean = !origination.etr['Aquisição Majoritária'] &&
			!origination.etr['Aquisição Minoritária'] &&
			!origination.etr['Aquisição Total']
		if (k === TEASER_FIELDS.etr && checkEtr) {
			count++
			return
		}

		const tagTechCheck = !origination[TEASER_FIELDS.tagTech].length
		if (k === TEASER_FIELDS.tagTech && tagTechCheck) {
			count++
			return
		}

		const tacgNegativeCheck: boolean = origination[TEASER_FIELDS.tagsNegative].length === origination[TEASER_FIELDS.tags].length
		if (k === TEASER_FIELDS.tagsNegative && tacgNegativeCheck) {
			count++
			return
		}

		const checkCreditOperations = origination.hasFinanciamentoObras || origination.hasFinanciamentoObrasDoZero || origination.hasCreditoOperacoesEstruturadas

		if (k === TEASER_FIELDS.creditOperations) value = true
		if (k === TEASER_FIELDS.creditOperations && !checkCreditOperations && validation.errors.checkCreditOperations) {
			count++
			return
		}
		// console.log(k, value)
		if (!value) {
			count++
			// console.log(k, 'value not found')
			return
		}
	})

	// console.log(validation)
	// console.log(allFields)
	// console.log(Object.keys(allFields).length, count)
	const percent = Math.round(((totalItens - count) / totalItens) * 100)

	return percent
}

