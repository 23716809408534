import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'

export const validateFormCreateValuation = (valuation: TypeValuation) => {
	const errors: { openPercentage?: string, closedType?: string } = {}

	// if (!valuation.valuationResult.openPercentage) {
	// 	errors.openPercentage = 'Digite a porcentagem de acréscimo ou desconto para capital aberto'
	// }

	// if (!valuation.valuationResult.closedType) {
	// 	errors.closedType = 'Digite a porcentagem de acréscimo ou desconto para capital fechado'
	// }

	return errors
}
