import { TypeScoreProps } from '../types/TypeScoreProps'
import { GEN_STARK_FEE } from '../genConst/GEN_STARK_FEE'
import { genFee } from '../genCount/genFee'
import { genTags } from '../genCount/genTags'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreFee = (
	props: TypeScoreProps
) => {
	if (props.operation === 'majoritário') {
		return genScoreFeeMajoritário(props)
	}

	if (props.operation === 'minoritário') {
		return genScoreFeeMinoritário(props)
	}

	if (props.operation === 'total') {
		return genScoreFeeTotal(props)
	}

	if (props.operation === 'greenfield') {
		return genScoreFeeGreenfield(props)
	}

	if (props.operation === 'corporateFinance') {
		return genScoreFeeCorporateFinance(props)
	}

	if (props.operation === 'projectFinance') {
		return genScoreFeeProjectFinance(props)
	}

	return []
}

const devolutiva = (props: TypeScoreProps, zerou = false) => {
	let res = zerou ? 'O tamanho da operação está abaixo do apetite de mercado. Quanto maior o valor da Receita Bruta e EBITDA, maior a atratividade da empresa. Monitore estes indicadores com o cliente. Verifique os campos preenchidos em busca de possíveis erros e corrija-os.' : 'O tamanho da operação está abaixo do apetite de mercado. Quanto maior o valor da Receita Bruta e EBITDA, maior a atratividade da empresa. Monitore estes indicadores com o cliente.'
	if (props.teaser.etr['Aquisição Minoritária'] && !props.teaser.etr['Aquisição Majoritária'] && !props.teaser.etr['Aquisição Total']) {
		res += ' Verifique se o empresário está aberto a ouvir propostas de venda acima de 50% da empresa.'
	}
	return res
}

const devolutivaDCM = 'Verifique o interesse do empresário em operações de valores acima de R$ 20 milhões.'

const genScoreFeeMajoritário = (
	props: TypeScoreProps
) => {

	const res: TypeScoreStark['scoreItens'] = []
	const successFee = genFee(props)
	const feeDivididoPelaStarkFee = successFee / (GEN_STARK_FEE(props)[0])

	if (1 < feeDivididoPelaStarkFee && feeDivididoPelaStarkFee <= 1.5) {
		res.push({ // adiciona 2.5
			'pontosMax': 5,
			'pontos': 2.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (1.5 < feeDivididoPelaStarkFee && feeDivididoPelaStarkFee <= 2.5) {
		res.push({ // adiciona 3.5
			'pontosMax': 5,
			'pontos': 3.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (2.5 < feeDivididoPelaStarkFee && feeDivididoPelaStarkFee <= 3.5) {
		res.push({ // adiciona 4.5
			'pontosMax': 5,
			'pontos': 4.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (3.5 < feeDivididoPelaStarkFee) {
		res.push({ // adiciona 5
			'pontosMax': 5,
			'pontos': 5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': '',
		} as const)
	}

	return res
}


const genScoreFeeMinoritário = (
	props: TypeScoreProps
) => {

	const res: TypeScoreStark['scoreItens'] = []
	const successFee = genFee(props)
	const feeDivididoPelaStarkFee = successFee / (GEN_STARK_FEE(props)[0])

	if (feeDivididoPelaStarkFee < 1) {
		res.push({ // adiciona 0
			'pontosMax': 5,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso reprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está abaixo de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props, true),
		})
	}
	if (1 <= feeDivididoPelaStarkFee && feeDivididoPelaStarkFee <= 1.5) {
		res.push({ // adiciona 2.5
			'pontosMax': 5,
			'pontos': 2.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (1.5 < feeDivididoPelaStarkFee && feeDivididoPelaStarkFee <= 2.5) {
		res.push({ // adiciona 3.5
			'pontosMax': 5,
			'pontos': 3.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (2.5 < feeDivididoPelaStarkFee && feeDivididoPelaStarkFee <= 3.5) {
		res.push({ // adiciona 4.5
			'pontosMax': 5,
			'pontos': 4.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (3.5 < feeDivididoPelaStarkFee) {
		res.push({ // adiciona 5
			'pontosMax': 5,
			'pontos': 5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': '',
		} as const)
	}

	return res
}


const genScoreFeeTotal = (
	props: TypeScoreProps
) => {

	const res: TypeScoreStark['scoreItens'] = []
	const successFee = genFee(props)
	const feeDivididoPelaStarkFee = successFee / (GEN_STARK_FEE(props)[0])

	if (1 <= feeDivididoPelaStarkFee && feeDivididoPelaStarkFee < 1.5) {
		res.push({ // adiciona 2.5
			'pontosMax': 5,
			'pontos': 2.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (1.5 <= feeDivididoPelaStarkFee && feeDivididoPelaStarkFee < 2.5) {
		res.push({ // adiciona 3.5
			'pontosMax': 5,
			'pontos': 3.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (2.5 <= feeDivididoPelaStarkFee && feeDivididoPelaStarkFee < 3.5) {
		res.push({ // adiciona 4.5
			'pontosMax': 5,
			'pontos': 4.5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props),
		} as const)
	}
	if (3.5 <= feeDivididoPelaStarkFee) {
		res.push({ // adiciona 5
			'pontosMax': 5,
			'pontos': 5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': '',
		} as const)
	}
	if (feeDivididoPelaStarkFee < 1) {
		res.push({ // adiciona 0
			'pontosMax': 5,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso reprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , abaixo de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutiva(props, true),
		} as const)
	}

	return res
}


const genScoreFeeProjectFinance = (
	props: TypeScoreProps
) => {

	const res: TypeScoreStark['scoreItens'] = []

	const successFee = genFee(props)

	if (successFee >= (1 * GEN_STARK_FEE(props)[0]))
		res.push({
			pontosMax: 5,
			pontos: 5,
			pontosCor: 'green',
			setor: 'Taxa de sucesso',
			item: 'nome do item',
			itemDescrição: 'descrição do item',
			devolutiva: '',
			metaDados: '',
		})

	if (successFee >= (2.5 * GEN_STARK_FEE(props)[0]))
		res.push({
			pontosMax: 1,
			pontos: 1,
			pontosCor: 'green',
			setor: 'Taxa de sucesso',
			item: 'Bônus por sucesso',
			itemDescrição: 'Bônus por sucesso',
			devolutiva: '',
			metaDados: '',
		})

	// SCORE SOBRE FEE #s206
	// if (successFee >= GEN_STARK_FEE(props)[0]) { // success fee >= do que o fee mínimo da stark
	// 	res.push({ // adiciona 5
	// 		'pontosMax': 5,
	// 		'pontos': 5,
	// 		'pontosCor': 'green',
	// 		'setor': 'Taxa de sucesso',
	// 		'item': 'Taxa de sucesso aprovada',
	// 		'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
	// 		'metaDados': '',
	// 		'devolutiva': '',
	// 	} as const)
	// }
	// // #s227
	// if (successFee < GEN_STARK_FEE(props)[0]) { // success fee < do que o fee mínimo da stark
	// 	res.push({ // adiciona 0
	// 		'pontosMax': 5,
	// 		'pontos': 0,
	// 		'pontosCor': 'green',
	// 		'setor': 'Taxa de sucesso',
	// 		'item': 'Taxa de sucesso reprovada',
	// 		'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está abaixo de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
	// 		'metaDados': '',
	// 		'devolutiva': devolutivaDCM,
	// 	} as const)
	// }

	return res
}


const genScoreFeeGreenfield = (
	props: TypeScoreProps
) => {

	const res: TypeScoreStark['scoreItens'] = []

	const successFee = genFee(props)

	// SCORE SOBRE FEE
	if (successFee >= GEN_STARK_FEE(props)[0]) { // success fee >= do que o fee mínimo da stark
		res.push({ // adiciona 5
			'pontosMax': 5,
			'pontos': 5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': '',
		} as const)

		if (genTags(props.teaser).includes('t129')) { // possui tag de Energia Renovável
			res.push({ // adiciona 2
				'pontosMax': 5,
				'pontos': 2,
				'pontosCor': 'green',
				'setor': 'Taxa de sucesso',
				'item': 'Adicional',
				'itemDescrição': 'Adicional para transação com setor de energia',
				'metaDados': '',
				'devolutiva': devolutivaDCM,
			} as const)
		}

		if (!genTags(props.teaser).includes('t129')) { // não possui tag de Energia Renovável
			res.push({ // adiciona 0
				'pontosMax': 5,
				'pontos': 0,
				'pontosCor': 'green',
				'setor': 'Taxa de sucesso',
				'item': 'Adicional',
				'itemDescrição': 'Sem adicional transação com setor de energia',
				'metaDados': '',
				'devolutiva': devolutivaDCM,
			} as const)
		}
	}

	if (successFee < GEN_STARK_FEE(props)[0]) { // success fee < do que o fee mínimo da stark
		res.push({ // adiciona 0
			'pontosMax': 5,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso reprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está abaixo de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutivaDCM,
		} as const)
	}

	if (GEN_STARK_FEE(props)[1]) {
		if (successFee >= GEN_STARK_FEE(props)[1]) { // success fee >= do que o SEGUNDO fee mínimo da stark
			res.push({ // adiciona 1
				'pontosMax': 5,
				'pontos': 1,
				'pontosCor': 'green',
				'setor': 'Taxa de sucesso',
				'item': 'Adicional',
				'itemDescrição': `Adicional para FEE elevado, maior que ${GEN_STARK_FEE(props)[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
				'metaDados': '',
				'devolutiva': devolutivaDCM,
			} as const)
		}

		if (successFee < GEN_STARK_FEE(props)[1]) { // success fee < do que o SEGUNDO fee mínimo da stark
			res.push({ // adiciona 0
				'pontosMax': 5,
				'pontos': 0,
				'pontosCor': 'green',
				'setor': 'Taxa de sucesso',
				'item': 'Adicional',
				'itemDescrição': 'Sem adicional para FEE elevado',
				'metaDados': '',
				'devolutiva': devolutivaDCM,
			} as const)
		}
	}

	return res
}


const genScoreFeeCorporateFinance = (
	props: TypeScoreProps
) => {

	const res: TypeScoreStark['scoreItens'] = []

	const successFee = genFee(props)

	// SCORE SOBRE FEE
	if (successFee >= GEN_STARK_FEE(props)[0]) { // success fee >= do que o fee mínimo da stark
		res.push({ // adiciona 5
			'pontosMax': 5,
			'pontos': 5,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso aprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está acima de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': '',
		} as const)
	}
	if (successFee < GEN_STARK_FEE(props)[0]) { // success fee < do que o fee mínimo da stark
		res.push({ // adiciona 0
			'pontosMax': 5,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Taxa de sucesso reprovada',
			'itemDescrição': `Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está abaixo de ${GEN_STARK_FEE(props)[0].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			'devolutiva': devolutivaDCM,
		} as const)
	}

	return res
}
