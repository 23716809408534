// useTeaser.ts
import { useEffect, useState } from 'react'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { useParams } from 'react-router-dom'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useContextAuth } from 'App/ContextAuth'
import { db5 } from 'Services/db5'

type RouterParams = {
	id: string;
}

export const useTeaser = (_id?: string) => {
	const { id } = _id ? { id: _id } : useParams<RouterParams>()
	const { globalAuth } = useContextAuth() as { globalAuth: { uid: string, profile: TypeUser, auth: string | undefined } }
	const profile = globalAuth.profile
	const [teaser, setTeaser] = useState<TypeTeaser | null>(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {


		db5.teasers.get({
			id,
			profile
		}).then(res => {
			if (res.doc) {
				setTeaser(res.doc)
				setLoading(false)
			}
		})

	}, [profile, id])

	return { teaser, loading }
}
