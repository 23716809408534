import { FC } from 'react'

export const Stark = () => (
	<svg width='60' height='20' viewBox='0 0 60 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M25.8012 3.80233H23.727C23.519 2.49435 22.6489 1.83215 21.1329 1.83215C19.6882 1.83215 18.7797 2.49435 18.7797 3.55606C18.7797 4.44263 19.3872 4.88045 20.6951 5.14314L22.1947 5.43867C24.5643 5.90932 25.7464 6.86704 25.7464 8.69492C25.7464 9.79494 25.3305 10.6979 24.4768 11.3765C23.6394 12.0552 22.5449 12.389 21.1658 12.389C19.7046 12.389 18.517 12.0387 17.5921 11.289C16.6891 10.5556 16.1802 9.54867 16.0762 8.27353H18.1503C18.3583 9.66907 19.524 10.5392 21.2862 10.5392C22.731 10.5392 23.6011 9.93176 23.6011 8.93572C23.6011 8.15313 23.0265 7.69889 21.8608 7.47451L19.9125 7.09142C17.6851 6.65361 16.5687 5.55907 16.5687 3.73118C16.5687 2.63117 16.9682 1.74459 17.7727 1.06598C18.5936 0.387362 19.6553 0.0371094 20.9961 0.0371094C23.0976 0.0371094 25.5056 0.945577 25.8012 3.80233Z' fill='#B2B2B2'/>
		<path d='M33.7739 12.0552C33.0077 12.2796 32.3291 12.4054 31.7162 12.4054C29.746 12.4054 28.531 11.081 28.531 8.97404V5.59738H26.6484V3.90632H28.531V0.299805H30.5341V3.90632H33.5824V5.59738H30.5341V8.97404C30.5341 10.0193 31.054 10.5392 32.0993 10.5392C32.5371 10.5392 32.9858 10.4681 33.4948 10.3313L33.7739 12.0552Z' fill='#B2B2B2'/>
		<path d='M43.3731 12.1428H41.3701V11.5682C40.6531 12.0717 39.8213 12.3343 38.8417 12.3343C37.6924 12.3343 36.7183 11.9184 35.8974 11.0811C35.0983 10.2438 34.6934 9.2368 34.6934 8.03281C34.6934 6.82881 35.0929 5.82183 35.8974 4.98451C36.7183 4.13077 37.6924 3.71484 38.8417 3.71484C39.8158 3.71484 40.6531 3.97753 41.3701 4.48102V3.90639H43.3731V12.1428ZM41.3701 9.42835V6.62632C40.8501 5.84372 40.1004 5.46064 39.1427 5.46064C38.4476 5.46064 37.873 5.72333 37.4023 6.22681C36.9317 6.7303 36.7018 7.33777 36.7018 8.03281C36.7018 8.72784 36.9262 9.34078 37.3969 9.84427C37.8675 10.3478 38.4422 10.594 39.1372 10.594C40.1004 10.594 40.8447 10.2109 41.3701 9.42835Z' fill='#B2B2B2'/>
		<path d='M47.0722 4.95155C47.8548 4.15254 48.8508 3.74756 50.0657 3.74756V5.64659C48.7742 5.70131 47.7836 6.15008 47.0722 7.02024V12.1427H45.0527V3.90627H47.0722V4.95155Z' fill='#B2B2B2'/>
		<path d='M53.3448 10.2984V12.1427H51.3418V0.299805H53.3448V7.64964L56.9677 3.90632H59.529L56.218 7.32128L59.9996 12.1482H57.3891L54.8279 8.77155L53.3448 10.2984Z' fill='#B2B2B2'/>
		<path d='M6.74974 0.151868L3.79448 5.26885C3.67955 5.47134 3.82184 5.72308 4.05717 5.72308H9.96769C10.203 5.72308 10.3453 5.47134 10.2304 5.26885L7.27512 0.151868C7.15472 -0.0506225 6.86467 -0.0506225 6.74974 0.151868Z' fill='#B2B2B2'/>
		<path d='M10.5329 6.66974L7.57768 11.7867C7.46276 11.9892 7.60505 12.2409 7.84037 12.2409H13.7509C13.9862 12.2409 14.1285 11.9892 14.0136 11.7867L11.0583 6.66974C10.9379 6.47272 10.6479 6.47272 10.5329 6.66974Z' fill='#B2B2B2'/>
		<path d='M2.99583 6.66974L0.0405728 11.7867C-0.0743539 11.9892 0.0679356 12.2409 0.303262 12.2409H6.21378C6.44911 12.2409 6.5914 11.9892 6.47647 11.7867L3.52121 6.66974C3.40628 6.47272 3.11076 6.47272 2.99583 6.66974Z' fill='#B2B2B2'/>
	</svg>
)
