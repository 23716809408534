import React from 'react'
import { LogoStark } from 'Components/Layout/LogoStark'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useHistory } from 'react-router-dom'
import { TMenu } from './menu'
import { useGlobalContext } from 'App/Global'
import { NotificationBell } from 'Components/NotificationBell'
import { generateUniqueId } from 'Functions/Utils/generateUniqueId'
import { useContextNotificationWidget } from 'App/ContextNotificationWidget'
import { useContextUnReadNotifications } from 'App/ContextUnReadNotifications'

export const menuCustomer = (profile: TypeUser, menu: string, setMenu: React.Dispatch<React.SetStateAction<string>>): TMenu => {

	const history = useHistory()
	const { openNotification, setOpenNotification } = useContextNotificationWidget()

	const { unReadNotifications, setUnReadNotifications } = useContextUnReadNotifications()

	return ([
		{
			Component: (<>
				<div className='tw-hidden md:tw-block'>
					<LogoStark />
				</div>
				<div className='tw-block md:tw-hidden tw-h-[32px]'></div>
			</>),
			key: generateUniqueId(),
			type: 'component',
		},
		{
			type: 'link',
			title: 'Dash',
			key: generateUniqueId(),
			icon: <i className='material-icons-outlined'> home </i>,
			iconActive: <i className='material-icons tw-text-xl'>home</i>,
			checkActive: history.location.pathname === '/painel/',
			to: '/painel/'
		},
		{
			title: 'Notificações',
			key: generateUniqueId(),
			type: 'link',
			icon: <NotificationBell small notifications={Object.keys(unReadNotifications).length}>
				<i className='material-icons-outlined'>notifications</i>
			</NotificationBell>,
			iconActive: <i className='material-icons tw-text-xl'>notifications</i>,
			onClick: () => setOpenNotification(e => !e),
		},
		{
			title: 'Kit Stark',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'> inventory </i>,
			iconActive: <i className='material-icons tw-text-xl'>inventory</i>,
			checkActive: history.location.pathname === '/kit-stark/',
			to: '/kit-stark/'
		},
		{
			title: 'Perfil',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'> person </i>,
			iconActive: <i className='material-icons tw-text-xl'>person</i>,
			checkActive: history.location.pathname === '/perfil/',
			to: '/perfil/'
		},
	])
}
