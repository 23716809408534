import * as S from './styles'

export const Title = (
	{ icon, title, description, marginBottom = '30px', classFont = 'material-icons', className }
		: { icon?: string, title: string, description?: string, marginBottom?: string, classFont?: string, className?: string }
) => (
	<S.Container marginBottom={marginBottom} className={className}>
		{
			icon &&
			<S.IconTitle>
				<i className={classFont}>{icon}</i>
			</S.IconTitle>
		}
		<S.DescriptionTitle>
			<p>{title}</p>
			<span>{description}</span>
		</S.DescriptionTitle>
	</S.Container>
)
