import { clone } from './clone'

/**
* Para validar uma url
* @param {String} url {String} Url a ser validada
* @returns {Array} {Array} Array com url
* @example validateURL('http://teste.com') => ['http://teste.com']
* @example validateURL('teste.com') => null
*/
export const validateURL = (url: string) => {

	const cloneUrl = clone(url)

	const urlExpression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,5})?(\/.*)?$/gm
	const regex = new RegExp(urlExpression)
	return cloneUrl.match(regex)
}
