import { STATUS_TERRENO } from './STATUS_TERRENO'
import { TEASER_FIELDS } from './TEASER_FIELDS'


export const FINANCING_GET = (code: string) => {
	const _code = code as keyof typeof FINANCING

	if (!FINANCING[_code]) {
		return (FINANCING.fin1)
	}

	return FINANCING[_code]
}

export enum Financing {
	fin1 = 'fin1', // financiamento de obras
	fin2 = 'fin2', // financiamento de projetos do zero
}

export const FINANCING = {
	fin1: {
		label: 'Financiamento de obras',
		value: Financing.fin1,
		types: [
			{
				label: 'Comercial',
				value: 'comercial',
			},
			{
				label: 'Residencial',
				value: 'residencial',
			},
			{
				label: 'Loteamento',
				value: 'loteamento',
			},
			{
				label: 'Outro',
				value: 'outros',
			},
		]
	},
	fin2: {
		label: 'Financiamento de projetos do zero',
		value: Financing.fin2,
		types: [],
	},
} as const

export type FinancingItemProps = {
	index: string;
	key: Financing;

	// Financiamento de obras
	type?: string; // Tipo de obra
	sales?: string; //Percentual de vendas concluídas
	construction?: string; //Percentual de obra concluída
	vgv?: string;
	saldoCarteiraRecebiveis?: string;
	timeline?: string;
	observation?: string;
	/** @description status do terreno
		 *
		 * label: Status do Terreno
		 *
		 * #addOrigination
	*/
	statusTerreno?: keyof typeof STATUS_TERRENO
	/** @description Valor caixa do empreendimento
		 *
		 * label: Valor atual do caixa do empreendimento
		 *
		 * #addOrigination
	*/
	valorAtualCaixa?: number

	// Greenfield
	totalBudget?: string// Orçamento total do projeto Greenfield
	valueByThird?: string// Valor já aportado por terceiros
	valueByPartnes?: string// Valor já aportado pelos sócios
	mainType?: Financing,
	nameIdGenerated?: string
}

export type FinancingItemsProps = FinancingItemProps[];

export type FinancingProps = {
	[name in Financing]?: FinancingItemsProps;
};

export const Fin1Model: FinancingItemProps = {
	key: Financing.fin1,
	type: '',
	sales: '',
	construction: '',
	vgv: '',
	saldoCarteiraRecebiveis: '',
	timeline: '',
	observation: '',
	index: '',
} as const

export const Fin2Model: FinancingItemProps = {
	key: Financing.fin2,
	totalBudget: '',
	valueByThird: '',
	valueByPartnes: '',
	index: '',
} as const

export const getFinancingModelByType = (type: Financing) => {
	if (type === Financing.fin1) return Fin1Model
	if (type === Financing.fin2) return Fin2Model
	// TODO: @jhonsore ESTE SERIA O PADRÃO?
	return Fin1Model
}

export const getFinancingNameByType = (type: TEASER_FIELDS) => {
	if (type === TEASER_FIELDS.hasFinanciamentoObras) return Financing.fin1
	if (type === TEASER_FIELDS.hasFinanciamentoObrasDoZero) return Financing.fin2
	// TODO: @jhonsore ESTE SERIA O PADRÃO?
	return Financing.fin1
}

export type TGuaranteeData = {
	[name in Financing]?: {
		types?: {
			label: string;
			value: string;
		}[];
		title: string;
		subtitle: string;
		itemLabel: string;
		buttonText: string;
	};
};

export const FINANCING_DATA: TGuaranteeData = {
	[Financing.fin1]: {
		buttonText: 'Adicionar nova obra',
		itemLabel: 'Obra',
		title: 'Financiamento de obras:',
		subtitle: 'Detalhamento sobre o sua obra:',
		types: [
			{
				label: 'Comercial',
				value: 'comercial',
			},
			{
				label: 'Residencial',
				value: 'residencial',
			},
			{
				label: 'Loteamento',
				value: 'loteamento',
			},
			{
				label: 'Outro',
				value: 'outros',
			},
		],
	},
	[Financing.fin2]: {
		buttonText: 'Adicionar novo projeto',
		itemLabel: 'Projeto',
		title: 'Financiamento de projetos do zero:',
		subtitle: 'Detalhamento sobre o seu projeto:',
	},
}
export const FINANCING_VALUES = Object.values(FINANCING)
export const FINANCING_KEYS = Object.keys(FINANCING)
