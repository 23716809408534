import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

import { TypeUser } from 'Functions/User/types/TypeUser'

type TypeUserCreate = {
	email: string,
	pass: string,
	profile: TypeUser
}

type UserCreateResponse = {
	status: number,
	message: string,
	uid: string
}

export const _userCreate = (input: TypeUserCreate): Promise<{ data: UserCreateResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'userCreate', input })
