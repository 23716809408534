import { TypeDbResponse } from '../TypeDBResponse'
import { DocumentData } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { TypeCollections } from '../configs/collections'
import { permissionsGetThisCollection } from '../configs/permissionsGetThisCollection'

export const getSync = <TDoc extends DocumentData>(collection: TypeCollections, model: (doc?: DocumentData) => TDoc) => (
	async ({ id, profile, callBack }: { id: string, profile: TypeUser, callBack: (e: TypeDbResponse<TDoc>) => void }) => {

		const serverLess = async () => {
			await firebase
				.firestore()
				.collection(collection)
				.doc(id)
				.onSnapshot(res => {
					if (res.exists) {
						callBack({
							id: id,
							status: 200,
							doc: model({ _id: id, ...res.data() }),
						})
					}
					else {
						callBack({
							id: id,
							status: 400,
						})
					}
				})
		}

		const error = async () => {
			callBack({
				id: id,
				status: 400,
			})
		}

		permissionsGetThisCollection(collection, profile)
			? await serverLess()
			: await error()
	}

)
