import { clone } from '../../Utils/clone'
import { minhaData } from '../../Utils/minhaData'
import { TypeTese } from '../types/tese'

export const modelTese = (obj: any = {}): TypeTese => {

	const cloneObj = clone(obj)

	return ({
		_id: cloneObj._id || '',
		_update: cloneObj._update || minhaData(),
		ativado: cloneObj.ativado || true,
		hideToBda: cloneObj.hideToBda || false,

		uid: cloneObj.uid || '',
		idInstitution: cloneObj.idInstitution || '',

		codinome: cloneObj.codinome || '',

		aqmi: cloneObj.aqmi || false,
		aqma: cloneObj.aqma || false,
		aq100: cloneObj.aq100 || false,

		dataCriacao: cloneObj.dataCriacao || minhaData(),
		dataModificacao: cloneObj.dataModificacao || cloneObj.dataCriacao || minhaData(),

		sdg: cloneObj.sdg ?? [],
		sdgN: cloneObj.sdgN ?? [],

		setor: cloneObj.setor || '',
		subsetor: cloneObj.subsetor || '',
		outrosubsetor: cloneObj.outrosubsetor || '',

		regiao: cloneObj.regiao || '',

		paises: cloneObj.paises || '',

		rae: cloneObj.rae || {
			Norte: false,
			Nordeste: false,
			'Centro-Oeste': false,
			Sudeste: false,
			Sul: false,
			'Atuação Internacional': false,
		},

		valorminimoSR: cloneObj.valorminimoSR || '',
		valorminimo: cloneObj.valorminimo || '',
		valormaximoSR: cloneObj.valormaximoSR || '',
		valormaximo: cloneObj.valormaximo || '',


		rbmiSR: cloneObj.rbmiSR || '',
		rbmi: cloneObj.rbmi || '',
		rbmaSR: cloneObj.rbmaSR || '',
		rbma: cloneObj.rbma || '',


		ebmiSR: cloneObj.ebmiSR || '',
		ebmi: cloneObj.ebmi || '',
		ebmaSR: cloneObj.ebmaSR || '',
		ebma: cloneObj.ebma || '',

		ebmarSR: cloneObj.ebmarSR || false,
		ebmar: cloneObj.ebmar || '',

		kpi: cloneObj.kpi || '',

		tags: cloneObj.tags || [],

		// tags para stark lite
		slite: cloneObj.slite ?? false,
		tagTech: cloneObj.tagTech || [],
		tagsTarget: cloneObj.tagsTarget || [],
		tagsInvestmentStage: cloneObj.tagsInvestmentStage || [],
		tagsBusinessModel: cloneObj.tagsBusinessModel || [],

		isHot: cloneObj.isHot ?? false,
		toFeatureHot: cloneObj?.toFeatureHot ?? false,
	})
}
