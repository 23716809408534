import * as Tokens from 'Components/CardDS/src/Tokens'
import { ColDS } from 'Components/ContainerDS'
import { LayoutDS } from 'Components/LayoutDS'
import { Separator } from 'Components/Separator'
import styled from 'styled-components'

export const HeaderContent = styled.div`
	display: flex;
	justifyContent: space-between;
	width: 100%;
	align-items: center;

	@media(max-width: 768px){
		flex-direction: column;
	}
`

export const ProgressBox = styled.div`
	flex: 1;
	width: 100%;
`
export const HeaderButtons = styled.div`
	margin-left: 32px;
	margin-top: 6px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media(max-width: 768px){
		margin: 16px 0 0 0;
		width: 100%;

		button{
			font-size: 10px;
			flex: 1;
			padding: 0;
		}
	}
`

export const BodyContent = styled.div`
	padding-top: ${20 + 28}px;
`

export const InputWithMargin = styled(ColDS)`
	margin-bottom: 20px;
`

export const ModalBox = styled.div`
	padding: 32px 32px 24px 32px;
`

export const ModalTitle = styled.div`
	margin: 12px 0 12px 0;
`

export const CloseBox = styled.div`
	position: absolute;
	right: 12px;
	top: 12px;
`

const ButtonReset = styled.button`
-webkit-appearance: none;
-moz-appearance:    none;
appearance:         none;
border-radius: 0;
-webkit-border-radius: 0;
-moz-border-radius: 0;
background:none;
border:none;
outline:none;

&:focus{
	outline:none;
}

cursor: pointer;
`

export const BtClose = styled(ButtonReset)``

export const FooterButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
`
export const ButtonError = styled(ButtonReset)`
	width: 100%;
	text-align: left;
	border-bottom: 1px solid ${Tokens.COLOR_NEUTRAL_400};

	&:last-child{
		border-bottom: none;
	}

	&:hover{
		color: ${'#0B31E0'};
	}
`

type TWrapper = {
	offline: boolean | null
}

export const Wrapper = styled.div<TWrapper>`
	width: 100%;
	${props => props.offline ? 'max-width: 1100px; margin: 0 auto;' : ''}
`

export const BrandBox = styled.div`
	margin-right: 32px;
`

export const StyledSeparator = styled(Separator)`
	margin: 48px 0;
`
const { Header: H, HeaderWrapper: HW } = LayoutDS
export const Header = styled(H)`
	z-index: 9;

	&.offline{
		padding: 0;
	}

	@media(max-width: 768px){
		padding: 0;
		height: auto;
	}
`

export const HeaderWrapper = styled(HW)`
	@media(max-width: 768px){
		height: auto;
	}
`
