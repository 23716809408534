import classNames from '../Functions/Utils/classNames'

export const UiLabel = (props: { className?: string, children: string }) =>
	<p
		className={
			classNames(
				'tw-mb-1 tw-font-semibold tw-text-xs tw-text-neutral-900 ',
				props.className ?? ''
			)
		}
	>
		{props.children}
	</p>

