import React from 'react'
import { permissions } from 'Hooks/segment'
import { useContextAuth } from 'App/ContextAuth'

type Props = {
	children: React.ReactNode
	name: string
}

export const Segment = ({ children, name }: Props) => {
	const { globalAuth, } = useContextAuth()

	const type = globalAuth?.profile?.tipo

	// @ts-ignore
	const valid = permissions?.[name]?.[type] ?? false

	if (valid) {
		return <>{children}</>
	}
	else {
		return null
	}
}
