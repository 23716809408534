export const createCodinome = (name:string) => {
	name = name.replace(/[^a-z]/gi, '')
	const randomLetter = (letter:string) => letter.charAt(Math.floor(Math.random() * letter.length))
	const today = new Date()
	const year = today.getFullYear().toString().substring(2)
	const numpad = (value:number) => value < 10 ? `0${value}` : value
	const month = numpad(today.getMonth()+1)
	const day = today.getDate()
	const seconds = today.getSeconds()
	const miliseconds = today.getMilliseconds()
	return `${randomLetter(name)}${randomLetter(name)}-${year}${month}${day}${miliseconds}`
}
