/**
 * A função `inComercial` verifica se um teaser foi publicado e tem status de
 * "comercial".
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é um objeto do tipo
 * `TypeTeaser`, que provavelmente é uma estrutura de dados que representa algum tipo de teaser
 * contente. A função `inComercial` pega esse objeto `teaser` e cria um clone
 * dele usando a função `clone`, e então verifica se
 * @returns A função `inComercial` está retornando um valor booleano. Ele verifica se
 * o objeto `teaser` passado como argumento é publicado (usando o comando `isPublicado`
 * função) e se a propriedade `statusCustomer` do objeto `teaser` clonado for
 * igual ao valor de `STATUS_CUSTOMER.comercial.value`. Se ambas as condições forem
 * verdadeiro, a função retorna `true`, caso contrário
 */
//CRIADO POR IA

import { STATUS_CUSTOMER } from '../../../Constants/STATUS_CUSTOMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { isPublicado } from './isPublicado'

export const inComercial = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)
	return isPublicado(cloneTeaser) && cloneTeaser.statusCustomer === STATUS_CUSTOMER.comercial.value

}
