/**
 * Função que retorna um array de strings para serem usadas em buscas para o firebase
 * @example fatorialStringForSearch('Nome Moça') => ['Nome', 'Moça', 'nome', 'moça', 'Nome', 'Moca', 'nome', 'moca']
 */

export const fatorialStringForSearch = (str: string) => (
	str
		? [
			...new Set([
				...(str || '').split(' '),
				...(str || '').toLowerCase().split(' '),
				...(str || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' '),
				...(str || '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' '),
			])
		]
		: []
)
	|| []
