import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { STATUS_MATCH_MAKING } from '../../../Constants/STATUS_MATCH_MAKING'
import { SUB_STATUS_OF_MATCH } from '../../../Constants/SUB_STATUS_OF_MATCH'
import { TypeMatch } from '../types/match'
import { fnMatch } from '..'
import { subStatus } from './subStatus'
import { clone } from '../../../Functions/Utils/clone'


export const inPreparation = (match: TypeMatch) => {

	const cloneMatch = clone(match)

	return fnMatch.status(cloneMatch) === STATUS_MATCH.match.value && fnMatch.matchMakingStatus(cloneMatch) === STATUS_MATCH_MAKING.match.value && subStatus(cloneMatch) === SUB_STATUS_OF_MATCH[100]._id

}
