import { clone } from '../../../Utils/clone'
import { TAGS_SECTOR } from '../../../../Constants/TAGS'
import { fatorialCnaes } from '../../../Utils/fatorialCnaes'
import { intersectionArray } from '../../../Utils/intersectionArray'
import { TypeTeaser } from '../../types/teaser'

// Retorna as tags + tagsNegative
export const getTags = (teaser: TypeTeaser): string[] => {

	const cloneTeaser = clone(teaser)

	const res: string[] = []
	// @ts-ignore
	const teaserCnaes: any = cloneTeaser.cnaes.reduce((acc, cur) => ([...acc, ...fatorialCnaes(cur)]), [])
	Object.entries(TAGS_SECTOR)
		.filter(([tag, tagObj]) => intersectionArray(teaserCnaes, tagObj.cnae).length)
		.forEach(([tag, tagObj]) => {
			res.push(tag)
		})
	return [...res, ...cloneTeaser.tags]
}
