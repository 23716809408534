import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'

export const addTag =
	(
		{ tag, valuation }:
			{ tag: TypeValuation['tags'][0], valuation: TypeValuation }
	) => {
		const copyOfValuation = { ...valuation }
		copyOfValuation.tags = [...valuation.tags]
		copyOfValuation.tags.push(tag)
		return copyOfValuation.tags
	}

