import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeTeaser } from '../types/teaser'

/**
 * Método responsável por atualizar um item do array de teasers anexados
 * @param id: id do teaser anexado
 * @param teaser: teaser
 * return teaser
 */
export const updateAttachedTeaser = async ({ item, teaser, id }: { id: string, item: TypeTeaser['attachedTeasers'][0], teaser: TypeTeaser }) => {

	const cloneItem = clone(item)
	const cloneTeaser = clone(teaser)
	const cloneId = clone(id)

	const _teaser = { ...cloneTeaser }
	const { _id } = cloneTeaser
	_teaser.attachedTeasers = cloneTeaser.attachedTeasers.map(attachedTeaser => {
		if (attachedTeaser._id === cloneId) return cloneItem
		return attachedTeaser
	})
	await db5.teasers.update({ id: _id, doc: _teaser })
	return _teaser
}
