import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PrinterOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M7.41113 3.41007C7.41113 2.63131 8.04244 2 8.8212 2H23.9291C24.7079 2 25.3392 2.63131 25.3392 3.41007V12.223C25.3392 13.0018 24.7079 13.6331 23.9291 13.6331C23.1504 13.6331 22.519 13.0018 22.519 12.223V4.82014H10.2313V12.223C10.2313 13.0018 9.59997 13.6331 8.8212 13.6331C8.04244 13.6331 7.41113 13.0018 7.41113 12.223V3.41007Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M6.30308 10.813H26.4547C27.4927 10.8187 28.4865 11.2336 29.2204 11.9675C29.9544 12.7015 30.3693 13.6953 30.375 14.7333L30.375 14.741L30.375 21.0438C30.3693 22.0817 29.9544 23.0755 29.2204 23.8095C28.4865 24.5435 27.4927 24.9583 26.4547 24.964L26.447 24.9641H23.929C23.1502 24.9641 22.5189 24.3328 22.5189 23.554C22.5189 22.7752 23.1502 22.1439 23.929 22.1439H26.4424C26.7366 22.1415 27.0182 22.0235 27.2263 21.8154C27.4345 21.6072 27.5524 21.3256 27.5549 21.0313V14.7457C27.5524 14.4515 27.4345 14.1698 27.2263 13.9617C27.0182 13.7535 26.7366 13.6356 26.4423 13.6331H6.30775C6.01347 13.6356 5.73188 13.7535 5.52372 13.9617C5.31557 14.1698 5.1976 14.4514 5.19516 14.7457V21.0313C5.1976 21.3256 5.31557 21.6072 5.52372 21.8154C5.73186 22.0235 6.01341 22.1415 6.30766 22.1439H8.82106C9.59982 22.1439 10.2311 22.7752 10.2311 23.554C10.2311 24.3328 9.59982 24.9641 8.82106 24.9641H6.29531C5.25735 24.9583 4.26354 24.5435 3.52958 23.8095C2.79563 23.0755 2.38076 22.0817 2.37504 21.0438L2.375 21.036L2.37502 14.7333C2.38074 13.6953 2.79562 12.7015 3.52958 11.9675C4.26354 11.2336 5.25735 10.8187 6.29531 10.813L6.30308 10.813Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M7.41113 18.518C7.41113 17.7392 8.04244 17.1079 8.8212 17.1079H23.9291C24.7079 17.1079 25.3392 17.7392 25.3392 18.518V28.5899C25.3392 29.3687 24.7079 30 23.9291 30H8.8212C8.04244 30 7.41113 29.3687 7.41113 28.5899V18.518ZM10.2313 19.9281V27.1798H22.519V19.9281H10.2313Z' fill='#272827' />
	</Svg>
)
