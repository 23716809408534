import { REASON_CONTRACT_DONE } from '../../../Constants/REASON_CONTRACT_DONE'
import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { TypeTeaser } from '../../../Functions/Teaser/types/teaser'
import { clone } from '../../../Functions/Utils/clone'
/**
 * Função responsável por dar churn um teaser, tornar ele offline e retornar o teaser
 *
 * @param teaserModal
 * @param motivo
 * @param dataChurn
 * @param detalhes
 * @param teaser
 */
export const churnTeaser = ({ teaserModal, motivo, dataChurn, detalhes, teaser }: {
	teaserModal: TypeTeaser,
	motivo: keyof typeof REASON_CONTRACT_DONE,
	dataChurn: Date,
	detalhes: string,
	teaser: TypeTeaser
}): TypeTeaser => {

	const cloneTeaserModal = clone(teaserModal)
	const cloneMotivo = clone(motivo)
	const cloneDataChurn = clone(dataChurn)
	const cloneDetalhes = clone(detalhes)
	const cloneTeaser = clone(teaser)

	const dateNow = new Date()
	const dateWithHMS = new Date(cloneDataChurn)
	dateWithHMS.setHours(dateNow.getHours())
	dateWithHMS.setMinutes(dateNow.getMinutes())
	dateWithHMS.setSeconds(dateNow.getSeconds())
	dateWithHMS.setMilliseconds(dateNow.getMilliseconds())

	return ({
		...cloneTeaserModal,
		dateContractDone: helpersDate.newDate(dateWithHMS),
		subStatusOfActiveCostumer: cloneTeaser.subStatusOfActiveCostumer,
		reasonContractDone: cloneMotivo,
		detailsContractDone: cloneDetalhes,
		onLine: false
	})
}


