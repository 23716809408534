import * as Tokens from 'Components/CardDS/src/Tokens'

const { XS, SM, MD, LG, XL, BUTTON_SIZE_XS,
	BUTTON_SIZE_SM, BUTTON_SIZE_MD,
	BUTTON_SIZE_XL, BUTTON_SIZE_LG,
	FONT_400, FONT_300, FONT_200,
	BUTTON_BACKGROUND_DISABLED, BUTTON_DISABLED, COLOR_NEUTRAL_400,
	BUTTON_PRIMARY, BUTTON_BACKGROUND_PRIMARY,
	BUTTON_BACKGROUND_QUARTENARY, BUTTON_BACKGROUND_SECONDARY,
	BUTTON_BACKGROUND_TERTIARY, BUTTON_QUARTENARY,
	BUTTON_SECONDARY, BUTTON_TERTIARY, COLOR_WHITE,
	COLOR_NEUTRAL_800, COLOR_BRANDING_PRIMARY,
	BUTTON_BACKGROUND_PRIMARY_HOVER, BUTTON_BACKGROUND_SECONDARY_HOVER,
	BUTTON_BACKGROUND_TERTIARY_HOVER, BUTTON_BACKGROUND_QUARTENARY_HOVER,
	BUTTON_BORDER_PRIMARY_ON_MOUSE_DOWN,
	BUTTON_BORDER_SECONDARY_ON_MOUSE_DOWN,
	BUTTON_BORDER_TERTIARY_ON_MOUSE_DOWN,
	BUTTON_SHADOW_PRIMARY_ON_MOUSE_DOWN,
	BUTTON_SHADOW_SECONDARY_ON_MOUSE_DOWN,
	BUTTON_SHADOW_TERTIARY_ON_MOUSE_DOWN,
	BUTTON_SHADOW_QUARTENARY_ON_MOUSE_DOWN } = Tokens

export const ButtonTheme = {
	sizes: {
		[XS]: {
			size: BUTTON_SIZE_XS,
			fontSize: FONT_200
		},
		[SM]: {
			size: BUTTON_SIZE_SM,
			fontSize: FONT_200
		},
		[MD]: {
			size: BUTTON_SIZE_MD,
			fontSize: FONT_300
		},
		[LG]: {
			size: BUTTON_SIZE_LG,
			fontSize: FONT_400
		},
		[XL]: {
			size: BUTTON_SIZE_XL,
			fontSize: FONT_400
		}
	},
	variants: {
		[BUTTON_PRIMARY]: {
			bgColor: BUTTON_BACKGROUND_PRIMARY,
			color: COLOR_WHITE
		},
		[BUTTON_SECONDARY]: {
			bgColor: BUTTON_BACKGROUND_SECONDARY,
			color: COLOR_BRANDING_PRIMARY
		},
		[BUTTON_TERTIARY]: {
			bgColor: BUTTON_BACKGROUND_TERTIARY,
			color: COLOR_NEUTRAL_800,
			borderColor: COLOR_NEUTRAL_400
		},
		[BUTTON_QUARTENARY]: {
			bgColor: BUTTON_BACKGROUND_QUARTENARY,
			color: COLOR_WHITE
		},
		[BUTTON_DISABLED]: {
			bgColor: BUTTON_BACKGROUND_DISABLED,
			color: COLOR_WHITE
		},
		['text']: {
			bgColor: '',
			color: COLOR_BRANDING_PRIMARY
		}
	},
	actions: {
		[BUTTON_PRIMARY]: {
			hover: {
				bgColor: BUTTON_BACKGROUND_PRIMARY_HOVER,
				color: COLOR_WHITE
			},
			mouseDown: {
				borderColor: BUTTON_BORDER_PRIMARY_ON_MOUSE_DOWN,
				shadowColor: BUTTON_SHADOW_PRIMARY_ON_MOUSE_DOWN
			}
		},
		[BUTTON_SECONDARY]: {
			hover: {
				bgColor: BUTTON_BACKGROUND_SECONDARY_HOVER,
				color: COLOR_WHITE
			},
			mouseDown: {
				borderColor: BUTTON_BORDER_SECONDARY_ON_MOUSE_DOWN,
				shadowColor: BUTTON_SHADOW_SECONDARY_ON_MOUSE_DOWN
			}
		},
		[BUTTON_TERTIARY]: {
			hover: {
				bgColor: BUTTON_BACKGROUND_TERTIARY_HOVER,
				color: COLOR_NEUTRAL_800,
			},
			mouseDown: {
				borderColor: BUTTON_BORDER_TERTIARY_ON_MOUSE_DOWN,
				shadowColor: BUTTON_SHADOW_TERTIARY_ON_MOUSE_DOWN
			}
		},
		[BUTTON_QUARTENARY]: {
			hover: {
				bgColor: BUTTON_BACKGROUND_QUARTENARY_HOVER,
				color: COLOR_WHITE
			},
			mouseDown: {
				shadowColor: BUTTON_SHADOW_QUARTENARY_ON_MOUSE_DOWN
			}
		},
		['text']: {
			hover: {
				bgColor: '',
				color: '#002A9B'
			},
			mouseDown: {
				shadowColor: ''
			}
		}
	}
}
