export const TESE_CREDIT_MANDATORY_AUDITED_SHEETS_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_MANDATORY_AUDITED_SHEETS

	if (!TESE_CREDIT_MANDATORY_AUDITED_SHEETS[_code]) {
		return (TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000)
	}

	return TESE_CREDIT_MANDATORY_AUDITED_SHEETS[_code]
}

export const TESE_CREDIT_MANDATORY_AUDITED_SHEETS = {
	a000: { label: 'Preferencialmente', _id: 'a000' },
	a001: { label: 'Obrigatório', _id: 'a001' },
	a002: { label: 'Não obrigatório', _id: 'a002' },
} as const

export const TESE_CREDIT_MANDATORY_AUDITED_SHEETS_VALUES = Object.values(TESE_CREDIT_MANDATORY_AUDITED_SHEETS)
export const TESE_CREDIT_MANDATORY_AUDITED_SHEETS_KEYS = Object.keys(TESE_CREDIT_MANDATORY_AUDITED_SHEETS)
