import { TypeTransaction } from '../../Types/TypeTransaction'
import { db5 } from 'Services/db5'

/**
 * Função usada para retornar atualizar uma transação
 * @param transaction TypeTransaction
 * @returns TypeTransaction
 */
export const updateTransaction =
	async ({ transaction }: { transaction: TypeTransaction }) =>
		await db5.sinatraTransactions.update({ id: transaction._id, doc: transaction })
