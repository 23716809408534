import { sendEnviadoInactive } from './sendEnviadoInactive'
import { sendAccept } from './sendAccept'
import { sendEnviadoDeclined } from './sendEnviadoDeclined'
import { sendEnviadoMatchmaking } from './sendEnviadoMatchmaking'
import { sendFirstLogin } from './sendFirstLogin'
import { sendMatch } from './sendMatch'
import { sendMatchAccepted } from './sendMatchAccepted'
import { sendMatchRejected } from './sendMatchRejected'
import { sendInativeToMatch } from './sendInativeToMatch'
import { sendOferta } from './sendOferta'
import { sendDiligencia } from './sendDiligencia'
import { sendContratosDefinitivos } from './sendContratosDefinitivos'
import { sendTransacaoConcluida } from './sendTransacaoConcluida'
import { sendPreMatchEnviadoDeclined } from './sendPreMatchEnviadoDeclined'
import { sendMatchEnviadoDeclined } from './sendMatchEnviadoDeclined'
import { sendDeliverables } from './sendDeliverables'
import { sendChurn } from './sendChurn'
import { sendServiçoRetomado } from './sendServiçoRetomado'

export const customer = {
	sendFirstLogin,
	sendAccept,
	sendMatchAccepted,
	sendMatchRejected,
	sendEnviadoMatchmaking,
	sendMatch,
	sendMatchEnviadoDeclined,
	sendPreMatchEnviadoDeclined,
	sendEnviadoDeclined,
	sendEnviadoInactive,
	sendInativeToMatch,
	sendOferta,
	sendDiligencia,
	sendContratosDefinitivos,
	sendTransacaoConcluida,
	sendDeliverables,
	sendChurn,
	sendServiçoRetomado
}
