import { clone } from '../../../Functions/Utils/clone'
import dateIsBetween from '../../../Functions/Utils/dateIsBetween'
import { minhaData, myDateToDate } from '../../../Functions/Utils/minhaData'
import { xMonthsAgo } from '../../../Functions/Utils/xMonthsAgo'
import { TypeTeasers } from '../../Teaser/types/teasers'

export const isAtivo = (teasers: TypeTeasers) => {

	const cloneTeasers = clone(teasers)

	const ultimaPublicação = () => {

		if (!cloneTeasers) return 0

		let data = 0

		Object.entries(cloneTeasers).forEach(([, teaser]) => {
			if (teaser.dataComercial > data) data = teaser.dataComercial
		})

		return data
	}

	const data = ultimaPublicação()

	const now = minhaData()

	return dateIsBetween(myDateToDate(data), xMonthsAgo(3), myDateToDate(now))
}
