import { masks } from 'Functions/Utils/masks'
import React from 'react'
import { IUiInput } from '..'

export const onChangeTimeUnit = (props: IUiInput) =>
	(event: React.ChangeEvent<HTMLInputElement>) => {

		const funcTo = props.includesNegativeNumbers ? masks.helper.integer : masks.helper.natural

		const value = funcTo(event.target.value)
		event.target.value = value.toString()

		props.onChange && props.onChange(event)
	}
