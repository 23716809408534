import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const StarOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M15.9999 3C16.4689 3 16.8976 3.26496 17.1073 3.6844L20.6699 10.8095L28.5587 11.9546C29.0248 12.0223 29.4121 12.3485 29.5581 12.7963C29.704 13.2441 29.5832 13.7359 29.2465 14.0651L23.5238 19.6607L24.9227 27.5456C25.005 28.0095 24.8169 28.48 24.4373 28.7592C24.0578 29.0384 23.5527 29.078 23.1343 28.8613L15.9999 25.1667L8.86558 28.8613C8.44719 29.078 7.94207 29.0384 7.56255 28.7592C7.18302 28.48 6.99487 28.0095 7.07718 27.5456L8.47612 19.6607L2.75342 14.0651C2.41668 13.7359 2.2959 13.2441 2.44182 12.7963C2.58773 12.3485 2.97506 12.0223 3.44113 11.9546L11.33 10.8095L14.8926 3.6844C15.1023 3.26496 15.531 3 15.9999 3ZM15.9999 7.00656L13.2555 12.4955C13.0742 12.858 12.727 13.1088 12.3259 13.167L6.27511 14.0454L10.675 18.3476C10.9677 18.6337 11.1 19.046 11.0285 19.4491L9.95328 25.5095L15.4306 22.6731C15.7876 22.4882 16.2122 22.4882 16.5693 22.6731L22.0466 25.5095L20.9714 19.4491C20.8999 19.046 21.0322 18.6337 21.3248 18.3476L25.7248 14.0454L19.6739 13.167C19.2728 13.1088 18.9257 12.858 18.7444 12.4955L15.9999 7.00656Z' fill='#272827' />
	</Svg>
)


