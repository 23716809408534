import { clone } from '../../Utils/clone'
import { db } from '../../../Services/db'
import { TypeInstitutions } from '../../Institution/types/institutions'
import { TypeMatch } from '../types/match'
import { TypeTeses } from '../../Tese/types/teses'
import { db5 } from '../../../Services/db5'

const getAllTeses = (match: TypeMatch) => {
	const cloneIdsTeses = clone(match?.idsTeses)
	const cloneTeseId = clone(match?.idTese)

	if (cloneIdsTeses?.length) return cloneIdsTeses
	else if (cloneTeseId) return [cloneTeseId]
	return []
}

const getInstitution = (match: TypeMatch, institutions: TypeInstitutions, teses: TypeTeses) => {

	const cloneMatch = clone(match)
	const cloneInstitutions = clone(institutions)
	const cloneTeses = clone(teses)

	if (cloneInstitutions?.[cloneMatch?.idInstitution]) return cloneInstitutions[cloneMatch.idInstitution]
	else if (cloneInstitutions?.[cloneTeses?.[cloneMatch?.idTese]?.idInstitution]) return cloneInstitutions[cloneTeses[cloneMatch.idTese].idInstitution]
	else return null
}

const setTeseFall = (match: TypeMatch, idsTesesFall: string[], idMatch: string,) => {

	const cloneMatch = clone(match)
	const cloneIdsTesesFall = clone(idsTesesFall)
	const cloneIdMatch = clone(idMatch)

	const idsTeses = cloneMatch.idsTeses.filter(id => !cloneIdsTesesFall.includes(id))
	db5.matches.update({
		id: cloneIdMatch,
		doc: {
			idsTeses: idsTeses,
			idsTesesFall: cloneIdsTesesFall
		}
	})
}

export {
	setTeseFall,
	getAllTeses,
	getInstitution,
}
