import { TypeScoreProps } from '../types/TypeScoreProps'

export const GEN_STARK_FEE_BIG = (operation: TypeScoreProps['operation']) => {

	if (operation === 'greenfield') return 50_000_000
	if (operation === 'corporateFinance') return 50_000_000
	if (operation === 'projectFinance') return 50_000_000

	if (operation === 'total') return 50_000_000
	if (operation === 'minoritário') return 50_000_000
	if (operation === 'majoritário') return 50_000_000

	return 0
}
