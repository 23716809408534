import { TypeTeaser } from '../../../../Functions/Teaser/types/teaser'
import { clone } from '../../../../Functions/Utils/clone'
import { generateUniqueId } from '../../../../Functions/Utils/generateUniqueId'
import { translateCredOps } from './operations/translateCredOps'
import { translateMeA } from './operations/translateMeA'
import { translateTerminoDeObras } from './operations/translateTerminoDeObras'
import { TYPE_OPERATION_CODE } from '../../../../Constants/TYPE_OPERATION'
import { isCredit } from '../../../../Functions/Teaser/methods/isCredit'
import { isMA } from '../../../../Functions/Teaser/methods/isMA'

/** Recebe um teaser. Clona ele.
 * Seta operations removendo qualquer operação que existir.
 * Se for crédito e tiver financing, faz um map em fin1, cria um id para cada item do map, e faz um push em operations usando translateTerminoDeObras.
 * Se for m&a e tiver algum etr, cria um id para a operação e faz um push em operations usando translateMeA.
 * Se for credito e tiver desiredValue, cria um id para a operação, faz um push em operations usando translateCredOps e seta hasCreditoOperacoesEstruturadas para true.
 * Retorna o teaser clonado e alterado.
  */
export const translateOldToNewOrigination = (teaser: TypeTeaser) => {
	const cloneTeaser = clone(teaser)

	cloneTeaser.operations = cloneTeaser.operations.filter(op =>
		!op.operationSubType.includes('projectFinance') &&
		!op.operationSubType.includes('corporateFinance') &&
		op.operationType !== TYPE_OPERATION_CODE['M&A']._id)


	//cloneTeaser.patrimonios = []
	// cloneTeaser.operations = []

	if (isCredit(cloneTeaser) && cloneTeaser.financing) {
		cloneTeaser.financing.fin1?.map(fin => {
			const id = generateUniqueId()
			return cloneTeaser.operations.push(translateTerminoDeObras(fin, id))
		})
	}

	if (isMA(cloneTeaser) && (cloneTeaser.etr['Aquisição Majoritária'] || cloneTeaser.etr['Aquisição Minoritária'] || cloneTeaser.etr['Aquisição Total'])) {
		const id = generateUniqueId()
		cloneTeaser.operations.push(translateMeA(cloneTeaser, id))
	}

	if (isCredit(teaser) && cloneTeaser.desiredValue) {
		const id = generateUniqueId()
		cloneTeaser.operations.push(translateCredOps(cloneTeaser, id))
		//TODO: reler essa linha para corrigir
		cloneTeaser.hasCreditoOperacoesEstruturadas = true
	}

	return cloneTeaser
}
