import React from 'react'
import { Link } from 'react-router-dom'
import { __String } from 'typescript'

interface ILayoutMenuButton {
	children: React.ReactNode
	active?: boolean
	onClick?: () => void
	to?: string
	small?: boolean
}

const classNameString = (props: ILayoutMenuButton) => `
tw-text-sm
tw-flex-1 
tw-pl-4 
tw-mx-2 
tw-text-neutral-400 
hover:tw-text-neutral-500 
hover:tw-bg-neutral-100 
tw-cursor-pointer 
tw-rounded 
tw-flex 
tw-items-center 
tw-gap-2 
tw-py-1
tw-border-none
tw-outline-none 
tw-font-medium

${props.small ? `
tw-text-xs
` : ''}

${props.active ? `
tw-font-semibold
tw-text-neutral-900
tw-bg-neutral-200
hover:tw-text-neutral-900 
hover:tw-bg-neutral-200 
` : `
tw-bg-transparent
`}
`

export const LayoutMenuButton = (props: ILayoutMenuButton) => {
	const isLink = props.to !== undefined

	if (isLink) return (
		<Link to={props.to || ''} >
			<div className={classNameString(props)}>
				{props.children}
			</div>
		</Link>
	)

	return (
		<div
			className={classNameString(props)}
			onClick={props.onClick}
		>
			{props.children}
		</div>
	)
}
