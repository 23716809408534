import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeAssistantAnne = {
	contentText?: string,
	threadId?: string,
	isContext?: boolean,
	isAddingMessages?: boolean,
	isRunning?: boolean,
	isDeleting?: boolean
}

export const _assistantAnne = (input: TypeAssistantAnne = {
	contentText: '',
	threadId: '',
	isContext: false,
	isAddingMessages: false,
	isRunning: false,
	isDeleting: false
}) => firebase.functions().httpsCallable('fnCloud')({ microservice: 'assistantAnne', input })
