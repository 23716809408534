import { getLabelTypeUser } from './methods/getLabelTypeUser'
import { getUidCoAuth } from './methods/getUidCoAuth'
import { getUser } from './methods/getUser'
import { _type } from './methods/_type'
import { isAdm } from './methods/isAdm'
import { isBda } from './methods/isBda'
import { isAgent } from './methods/isAgent'
import { isEnterprise } from './methods/isEnterprise'
import { isCustomer } from './methods/isCustomer'
import { isMicroBda } from './methods/isMicroBda'
import { isHub } from './methods/isHub'
import { isOffice } from './methods/isOffice'
import { isAgentFromOffice } from './methods/isAgentFromOffice'
import { isOfficeAnalyst } from './methods/isOfficeAnalyst'
import { isAtivo } from './methods/isAtivo'
import { acceptedTerms } from './methods/acceptedTerms'
import { generateRankingAgent } from './methods/generateRankingAgent'
import { resetPass } from './methods/resetPass'
import { isAdmOperation } from './methods/isAdmOperation'
import { isAdmChannel } from './methods/isAdmChannel'
import { isAdmIr } from './methods/isAdmIr'
import { modelUser } from './model/modelUser'
import { isBetaUser } from './methods/isBetaUser'
import { unReadNotificationsGeneral } from './methods/unReadNotifications'
import { sortByName } from './methods/sortByName'
import { sortByType } from './methods/sortByType'
import { sortByEmail } from './methods/sortByEmail'
import { sortByPhone } from './methods/sortByPhone'
import { sortByActive } from './methods/sortByActive'
import { filterByName } from './methods/filterByName'
import { filterByType } from './methods/filterByType'
import { filterByRelationship } from './methods/filterByRelationship'
import { filterByActivity } from './methods/filterByActivity'
import { getLabelAgent } from './methods/getLabelAgent'
import { getRankingPosition } from './methods/getRankingPosition'
import { firstOrigination } from './methods/firstOrigination'
import { firstApproval } from './methods/firstApproval'
import { firstMeeting } from './methods/firstMeeting'
import { firstActiveCustomer } from './methods/firstActiveCustomer'
import { firstMatch } from './methods/firstMatch'
import { firstDateInPlatform } from './methods/firstDateInPlataform'
import { sumPointsRanking } from './methods/sumPointsRanking'
import { getUidUserAdm } from './methods/getUidUserAdm'

export const fnUser = {
	modelUser,
	isAdm,
	isAdmOperation,
	isAdmChannel,
	isAdmIr,
	isBda,
	isAgent,
	isEnterprise,
	isCustomer,
	isMicroBda,
	isHub,
	isOffice,
	resetPass,
	isAgentFromOffice,
	generateRankingAgent,
	getLabelTypeUser,
	isAtivo,
	getUidCoAuth,
	acceptedTerms,
	getUser,
	isOfficeAnalyst,
	isBetaUser,
	unReadNotificationsGeneral,
	sortByName,
	sortByType,
	sortByEmail,
	sortByPhone,
	sortByActive,
	filterByName,
	filterByType,
	filterByRelationship,
	filterByActivity,
	getLabelAgent,
	getRankingPosition,
	firstOrigination,
	firstApproval,
	firstMeeting,
	firstActiveCustomer,
	firstMatch,
	firstDateInPlatform,
	sumPointsRanking,
	getUidUserAdm
}
