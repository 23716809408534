import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { TypeTeaser } from '../types/teaser'
/**
 * Ordena um array de teasers pelo contrato da empresa
 * @param teasers
 * @param order
 * @returns  TypeTeaser[]
 */
// export const sortByContrato = ({ teasers, order }: { teasers: TypeTeaser[], order: 'asc' | 'desc' }): TypeTeaser[] =>
// 	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
// 		const isAsc = order === 'asc'
// 		const tipoContratoA = teaserA?.tipoContrato
// 		const tipoContratoB = teaserB?.tipoContrato
// 		const valorContratoA = teaserA?.valorContrato
// 		const valorContratoB = teaserB?.valorContrato

// 		if (tipoContratoA === 'none') return 1
// 		if (tipoContratoB === 'none') return -1

// 		if (isAsc && (tipoContratoA < tipoContratoB)) return -1
// 		if (isAsc && (tipoContratoA === tipoContratoB && valorContratoA < valorContratoB)) return -1
// 		if (isAsc) return 1

// 		if (tipoContratoA > tipoContratoB) return -1
// 		if (tipoContratoA === tipoContratoB && valorContratoA > valorContratoB) return -1
// 		return 1
// 	})

export const sortByContrato2 = ({ teasers, order }: { teasers: TypeTeaser[], order: 'asc' | 'desc' }): TypeTeaser[] =>
	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
		const isAsc = order === 'asc'

		const contratoA = helpersDate.convertAllToDate(teaserA.dataContrato)
		const contratoB = helpersDate.convertAllToDate(teaserB.dataContrato)

		if (contratoA > contratoB) return isAsc ? 1 : -1
		if (contratoA < contratoB) return isAsc ? -1 : 1

		return 1
	})
