import React, { useEffect, useRef, useState } from 'react'
import { useContextAssistantChat } from '../../App/ContextAssistantChat'
import { UiButton } from '../../ComponentsUi/UiButton'
import { UiInput } from '../../ComponentsUi/UiInput'
import { IMG_S } from '../../Constants/IMG_S'
import { useContextAuth } from '../../App/ContextAuth'
import { assistantAnne } from '../../Services/cloudfunctions'
import { LoadingAnimate } from '../Loading'

export const AssistantChat = () => {
	const { openChat, setOpenChat, threadId, setThreadId, conversation, setConversation } = useContextAssistantChat()
	const { globalAuth } = useContextAuth()
	const [userQuestion, setUserQuestion] = useState<string>('')
	const [sendingMessage, setSendingMessage] = useState<boolean>(false)
	const predefinedMessages = [
		'O que é m&a?',
		'O que são debêntures?',
		'Quais as diferenças entre CRI e CCB?',
		'Como calcular EBITDA de uma empresa?'
	]

	const messagesEndRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		scrollToBottom()

		// Cleanup function to delete conversation data from local storage
		window.addEventListener('beforeunload', cleanup)
		return () => window.removeEventListener('beforeunload', cleanup)
	}, [conversation])

	const cleanup = async () => {
		// const storedThreadId = localStorage.getItem('assistantThreadId')
		// if (storedThreadId) {
		// 	// Delete thread from assistant by calling the cloud
		// 	await assistantAnne({ isDeleting: true, threadId: storedThreadId, contentText: '', isAddingMessages: false, isContext: false, isRunning: false })
		// }

		// Delete conversation data from local storage
		localStorage.removeItem('assistantThreadId')
		localStorage.removeItem('assistantConversation')
	}

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const addMessageToThread = async (content: string) => {
		try {
			if (threadId) { // Check if threadId is not null
				// add message to the thread by calling the cloud
				await assistantAnne({ isAddingMessages: true, contentText: content, threadId: threadId })

				// Update state and localStorage with the new message
				const updatedConversation = [...conversation, { role: 'user', content }]
				setConversation(updatedConversation)
				localStorage.setItem('assistantConversation', JSON.stringify(updatedConversation))
			}
			else {
				// console.error('Thread ID is null.')
			}
		}
		catch (error) {
			// // console.error('Error adding message to thread:', error)
		}
	}

	const runAssistant = async () => {
		try {
			if (threadId) {
				const messagesResponse = await assistantAnne({ isRunning: true, threadId: threadId })

				// Extract and update the conversation
				const newMessages = messagesResponse.data.filter((message: any) => !conversation.find(existingMessage => existingMessage.id === message.id))

				const updatedConversation = [
					...conversation,
					...newMessages.map((message: any) => ({
						id: message.id,
						role: message.role,
						content: message.content.map((content: any) => content.text?.value).join(' ')
					}))
				]

				setConversation(updatedConversation)

				// Store the conversation in localStorage
				localStorage.setItem('assistantConversation', JSON.stringify(updatedConversation))
			}
		}
		catch (error) {
			// // console.error('Error running assistant:', error)
		}
	}

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()

		setSendingMessage(true)

		// Add user message to the thread
		await addMessageToThread(userQuestion)

		setUserQuestion('')

		// Run the assistant to get the response
		await runAssistant()

		setSendingMessage(false)
	}

	const renderMessages = () => {
		const userMessages: any[] = []
		const assistantMessages: any[] = []

		conversation.forEach(message => {
			if (message.role === 'user') {
				userMessages.push(message)
			}
			else {
				assistantMessages.push(message)
			}
		})

		const messagesToRender: any[] = []
		const maxLength = Math.max(userMessages.length, assistantMessages.length)

		for (let i = 0; i < maxLength; i++) {
			if (userMessages[i]) {
				messagesToRender.push(userMessages[i])
			}
			if (assistantMessages[i]) {
				messagesToRender.push(assistantMessages[i])
			}
		}

		return messagesToRender
	}

	const handlePredefinedMessageClick = async (message: string) => {
		setSendingMessage(true)
		await addMessageToThread(message)
		await runAssistant()
		setSendingMessage(false)
	}

	return (
		<>
			<div className={`${openChat ? 'tw-translate-x-0' : 'tw-translate-x-full'} tw-fixed tw-bottom-0 tw-right-0 tw-max-w-md tw-bg-white -border-solid tw-border-0 tw-border-l-4 tw-border-neutral-300 tw-shadow-xl tw-h-full tw-z-[60] tw-transition tw-duration-300 tw-ease-in-out`}>
				<div className='tw-flex-1 tw-w-[400px] tw-flex tw-flex-col tw-h-full'>
					<div className='tw-flex tw-py-5 tw-px-4 tw-w-full tw-justify-between tw-pb-3 tw-border-b-2 tw-shadow-md tw-border-gray-200'>
						<div className='tw-relative tw-flex tw-items-center tw-space-x-3 '>
							<img src='https://firebasestorage.googleapis.com/v0/b/stark-development-fb954.appspot.com/o/assets%2FassistantAi%2FAnneIB.png?alt=media&token=26a51da4-ff49-40ee-abcd-5111f7a04ef8' alt='Foto de perfil da Anne' className='tw-w-12 tw-h-12 tw-rounded-full tw-object-cover' />
							<div className='tw-flex tw-flex-col tw-leading-tight'>
								<div className='tw-text-base tw-mt-1 tw-flex tw-items-center'>
									<span className='tw-text-gray-700 tw-mr-3'>Anne</span>
								</div>
								<span className='tw-text-sm tw-text-gray-600'>Especialista em Investment Banking</span>
							</div>
						</div>
						<button className='material-icons tw-text-base tw-text-red-500 tw-bg-transparent tw-border-none hover:tw-cursor-pointer focus:tw-outline-none' onClick={() => setOpenChat(false)}>
							close
						</button>
					</div>

					<div id='messages' className='tw-flex tw-mt-auto tw-flex-col tw-space-y-4 tw-px-4 tw-overflow-y-auto tw-scrollbar-thumb-blue tw-scrollbar-thumb-rounded tw-scrollbar-track-blue-lighter tw-scrollbar-w-2 tw-scrolling-touch'>
						{/* Render predefined messages */}
						{!renderMessages().length &&
							<div className='tw-flex tw-flex-col tw-items-center'>
								<p className='tw-text-sm tw-font-semibold'>Sugestões de perguntas</p>
								<div className='tw-grid tw-grid-cols-2 tw-grid-rows-2 tw-gap-4'>
									{predefinedMessages.map((message, index) => (
										<div key={index} className='predefined-message tw-flex tw-items-center tw-border-solid tw-border-neutral-200 tw-p-2 tw-rounded-md hover:tw-cursor-pointer hover:tw-bg-neutral-200' onClick={() => handlePredefinedMessageClick(message)}>
											{message}
										</div>
									))}
								</div>
							</div>
						}

						{renderMessages().map((item, index) => (
							item.role === 'user' ?
								<div key={index} className='chat-message tw-mt-2'>
									<div className='tw-flex tw-items-end tw-justify-end'>
										<div className='tw-space-y-2 tw-text-xs tw-max-w-xs tw-mx-2 tw-order-1 tw-items-end'>
											<span className='tw-px-4 tw-py-2 tw-rounded-lg tw-inline-block tw-rounded-br-none tw-bg-blue-500 tw-text-white '>
												{item.content}
											</span>
										</div>
										<img src={globalAuth.profile?.ulrimg.trim().length > 0 ? globalAuth.profile.ulrimg : IMG_S.USER_PROFILE} alt='My profile photo' className='tw-w-6 tw-h-6 tw-rounded-full tw-order-2' />
									</div>
								</div>
								:
								<div key={index} className='chat-message'>
									<div className='tw-flex tw-items-end items-end'>
										<div className='tw-flex tw-flex-col tw-space-y-2 tw-text-xs tw-max-w-xs tw-mx-2 tw-order-2 tw-items-start'>
											<span className='tw-px-4 tw-py-2 tw-rounded-lg tw-inline-block tw-rounded-bl-none tw-bg-gray-300 tw-text-gray-600'>
												{item.content}
											</span>
										</div>
										<img src='https://drive.google.com/thumbnail?id=1dKCD5vk9bivDURAMcJRHSRQhiHts8DzZ' alt='Open IB profile photo' className='tw-w-6 tw-h-6 tw-rounded-full tw-order-1 tw-object-cover' />
									</div>
								</div>
						))}

						<div ref={messagesEndRef} />
					</div>
					<form onSubmit={handleSubmit} className='tw-flex tw-flex-row tw-gap-2 tw-items-center tw-pb-5 tw-px-4 tw-mt-3 tw-mb-1'>
						<UiInput
							type='text'
							sizeInput='small'
							value={userQuestion}
							onChange={e => setUserQuestion(e.target.value)}
							className='tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md'
							placeholder={sendingMessage ? 'Aguarde...' : 'Digite uma pergunta...'}
							disabled={sendingMessage}
						/>
						{sendingMessage ? <LoadingAnimate width={30} /> :
							<UiButton disabled={sendingMessage}>
								Enviar
							</UiButton>
						}
					</form>
				</div>
			</div>
		</>
	)
}
