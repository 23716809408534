import styled from 'styled-components'
import { IconsDS } from 'Components/IconsDS'
import { ReactNode } from 'react'
import { UiButton } from '../../../../ComponentsUi/UiButton'

export const ModalBox = styled.div`
padding: 32px 32px 24px 32px;
`

export const ModalTitle = styled.div`
margin: 12px 0 12px 0;
`

export const CloseBox = styled.div`
position: absolute;
right: 12px;
top: 12px;
`

export const BtClose = styled.button`
-webkit-appearance: none;
-moz-appearance:    none;
appearance:         none;
border-radius: 0;
-webkit-border-radius: 0;
-moz-border-radius: 0;
background:none;
border:none;
outline:none;

&:focus{
	outline:none;
}

cursor: pointer;
`

export type Props = {
	onClose?: () => void
	icon?: ReactNode
	title?: ReactNode
	description?: ReactNode
	okHandler?: () => void
	cancelHandler?: () => void
	errors: ReactNode
}

export const Overlay = (props: Props) => {
	const { onClose, icon, title, description, okHandler, cancelHandler, errors } = props
	return <ModalBox>
		<CloseBox>
			<BtClose onClick={onClose}><IconsDS.Close size={12} color={'#ED0000'} /></BtClose>
		</CloseBox>
		<div className='text-center'>
			{icon || <IconsDS.Warning color={'#D5D5D5'} size={45} />}
			<ModalTitle>
				<h2 className='tw-text-sm tw-font-bold tw-text-gray-800 tw-m-0'>{title}</h2>
			</ModalTitle>
			<p className='tw-text-neutral-800 tw-text-xs' >{description}</p>
		</div>
		<div className='mt-5 mb-3'>
			{errors}
		</div>
		<UiButton variant='secondary' onClick={okHandler} size='small' className='tw-w-full'>Voltar</UiButton>
	</ModalBox>
}
