import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { TypeNotification } from '../../types/notification'
import { fnNotification } from '../..'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.b0001

export const sendFirstLogin =
	async ({ uids, origination, createdBy, user }: TypeNotification) => {

		const cloneUids = clone(uids)
		const cloneOrigination = clone(origination)
		const cloneCreatedBy = clone(createdBy)
		const cloneUser = clone(user)

		return await fnNotification.send({ uiPermissions: ITEM.permissions, uids: cloneUids, origination: cloneOrigination, createdBy: cloneCreatedBy, user: cloneUser, message: ITEM.msg, type: ITEM._id, showCustomer: true } as TypeNotification)
	}

