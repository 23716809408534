import { firestore } from 'firebase/app'
import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { STATUS_MATCH_MAKING } from '../../../Constants/STATUS_MATCH_MAKING'
import { SUB_STATUS_OF_MATCH } from '../../../Constants/SUB_STATUS_OF_MATCH'
import { fnNotification } from '../../../Functions/Notification'
import { getHighestStatusFromMatches, notifications } from '../../../Services/cloudfunctions'
import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../types/match'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeTese } from '../../Tese/types/tese'
import { TypeUser } from '../../User/types/TypeUser'
import { setMatch } from './setMatch'

import { clone } from '../../../Functions/Utils/clone'
import { helpersDate } from '../../../Functions/Utils/helpersDate'

export const toOfferNegotiationDecline = async ({
	matchId,
	match,
	teaser,
	teaserId,
	profile,
	institution,
	institutionId,
	uid,
	tese,
	teseId,
}: {
	matchId: string
	match: TypeMatch
	teaser: TypeTeaser
	teaserId: string
	profile: TypeUser
	institution: TypeInstitution
	institutionId: string
	uid: string
	tese: TypeTese
	teseId: string
}) => {

	const cloneMatchId = clone(matchId)
	const cloneMatch = clone(match)
	const cloneTeaser = clone(teaser)
	const cloneTeaserId = clone(teaserId)
	const cloneProfile = clone(profile)
	const cloneInstitution = clone(institution)
	const cloneInstitutionId = clone(institutionId)
	const cloneUid = clone(uid)
	const cloneTese = clone(tese)
	const cloneTeseId = clone(teseId)


	const getHighestStatus = async () => {
		await getHighestStatusFromMatches({
			'id': cloneTeaserId
		})
	}

	await setMatch({
		id: cloneMatchId,
		match: {
			...cloneMatch,
			status: STATUS_MATCH.match.value,
			matchMakingStatus: STATUS_MATCH_MAKING.inativo.value,
			matchStatus: SUB_STATUS_OF_MATCH[200]._id as keyof typeof SUB_STATUS_OF_MATCH,
			dateOfferDeclined: helpersDate.convertAllToTimestamp(new Date()),
		}
	}).then(() => {
		getHighestStatus()
	})

	const responseSendMatchEnviadoDeclined = await fnNotification.customer.sendMatchEnviadoDeclined({
		origination: cloneTeaser,
		originationId: cloneTeaserId,
		profile: cloneProfile,
		match: cloneMatch,
		matchId: cloneMatchId,
		tese: cloneTese,
		teseId: cloneMatch.idTese,
		institution: cloneInstitution,
		institutionId: cloneInstitutionId,
		uid: cloneUid
	})

	const responseNotifications = await notifications({
		targetId: teaserId,
		typeOrMessage: 'a0009',
		profileUid: uid,
	})

}
