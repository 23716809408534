import { TypeUser } from '../types/TypeUser'

/**
 * Ordena um array de usuários pelo ativo/inativo
 * @param teasers
 * @param order
 * @returns  TypeUser[]
 */
export const sortByActive = ({ users, order }: { users: TypeUser[], order: 'asc' | 'desc' }): TypeUser[] =>
	users.sort((userA: TypeUser, userB: TypeUser) => {
		const isAsc = order === 'asc'
		const nameA = String(userA?.ranking.isActive) || ''
		const nameB = String(userB?.ranking.isActive) || ''
		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})
