/** @deprecated */
export const TYPE_USER = {
	adm: 'adm',
	bda: 'BDA', // tipo usado no codigo : tipo usado no banco de dados
	enterprise: 'enterprise',
	microBda: 'MicroBda',
	customer: 'Customer',
	hub: 'hub',
	office: 'office',
	officeAnalyst: 'officeAnalyst'
} as const

/** @deprecated */
export const TYPE_USER_LABEL = {
	adm: 'Corporativo',
	bda: 'Agente',
	enterprise: 'Enterprise Partner',
	microBda: 'Assessor',
	customer: 'Cliente',
	hub: 'Hub',
	office: 'Office',
	officeAnalyst: 'Office Analyst',
} as const

/** @deprecated */
export const USERS = {
	'adm': {
		label: 'Corporativo', // mostrar na tela
		code: 'adm', // código no banco de dados
		color: 'yellow-400',
	},
	'bda': {
		label: 'Agente',
		code: 'BDA',
		color: 'blue-500',
	},
	'enterprise': { // cuidado, isso é uma variação de BDA
		label: 'Enterprise Partner',
		code: 'enterprise',
		color: 'green-400',
	},
	'customer': {
		label: 'Cliente',
		code: 'Customer',
		color: 'blue-500',
	},
	'microBda': {
		label: 'Assessor',
		code: 'MicroBda',
		color: 'green-400',
	},
	'hub': {
		label: 'Hub',
		code: 'hub',
		color: 'orange-400',
	},
	'office': {
		label: 'Office',
		code: 'office',
		color: 'orange-400',
	},
	'officeAnalyst': {
		label: 'Office Analyst',
		code: 'officeAnalyst',
		color: 'orange-400',
	}
} as const


export const USER_GET = (code: string) => {
	const _code = code as keyof typeof USERS_CONST

	if (!USERS_CONST[_code]) {
		return (
			{
				label: 'Usuário não encontrado',
				code: ''
			}
		)
	}

	return USERS_CONST[_code] || { label: 'Usuário não encontrado', code: '' }
}

export const USERS_CONST = {
	'adm': {
		_id: 'adm', // código no banco de dados
		label: 'Corporativo', // mostrar na tela
		color: 'yellow-400',
	},
	'BDA': {
		_id: 'BDA',
		label: 'Agente',
		color: 'blue-500',
	},
	'enterprise': { // cuidado, isso é uma variação de BDA
		_id: 'enterprise',
		label: 'Enterprise Partner',
		color: 'green-400',
	},
	'Customer': {
		_id: 'Customer',
		label: 'Cliente',
		color: 'blue-500',
	},
	'MicroBda': {
		_id: 'MicroBda',
		label: 'Assessor',
		color: 'green-400',
	},
	'hub': {
		_id: 'hub',
		label: 'Hub',
		color: 'orange-400',
	},
	'office': {
		_id: 'office',
		label: 'Office',
		color: 'orange-400',
	},
	'officeAnalyst': {
		_id: 'officeAnalyst',
		label: 'Office Analyst',
		color: 'orange-400',
	}
} as const

export const USERS_CONST_ARRAY = () => Object.values(USERS_CONST)

export const TYPE_USER_VALUES = Object.values(USERS_CONST)
export const TYPE_USER_KEYS = Object.keys(USERS_CONST)
