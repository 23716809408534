import { TypeDashBoardAdm } from 'Functions/DashboardAdm/type/TypeDashBoardAdm'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type GetDataDashBoardResponse = {
	version: string,
	status: number,
	doc: TypeDashBoardAdm
}

export const _getDataDashBoard = (): Promise<{ data: GetDataDashBoardResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'getDataDashBoard' })
