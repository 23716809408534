/**
* Função para juntar objetos de array de classes em uma única string
* @param {Array} classes {Array} Array com as classes de estilo
* @returns {String} {String} Retorna a string com as classes
* @example classNames('pl-2 d-flex flex-column gap-2 w-full h-fit',
						'mr-2 my-2') => 'pl-2 d-flex flex-column gap-2 w-full h-fit mr-2 my-2'
*/

export default function classNames(...classes: string[]) {

	const cloneClasses: string[] = JSON.parse(JSON.stringify(classes))

	return cloneClasses.filter(Boolean).join(' ')
}
