export const TESES_CREDIT_IMPEDIMENTO_RECEITA_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_IMPEDIMENTO_RECEITA

	if (!TESE_CREDIT_IMPEDIMENTO_RECEITA[_code]) {
		return (TESE_CREDIT_IMPEDIMENTO_RECEITA.ir000)
	}

	return TESE_CREDIT_IMPEDIMENTO_RECEITA[_code]
}

export const TESE_CREDIT_IMPEDIMENTO_RECEITA = {
	ir000: { label: 'Não há impedimento', _id: 'ir000' },
	ir001: { label: 'Recorrente', _id: 'ir001' },
	ir002: { label: 'Não recorrente', _id: 'ir002' },
} as const

export const TESE_CREDIT_IMPEDIMENTO_RECEITA_VALUES = Object.values(TESE_CREDIT_IMPEDIMENTO_RECEITA)
export const TESE_CREDIT_IMPEDIMENTO_RECEITA_KEYS = Object.keys(TESE_CREDIT_IMPEDIMENTO_RECEITA)
