import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BoxOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M5.88396 9.98926C6.53287 9.98926 7.05893 10.5304 7.05893 11.1979V25.5828H25.6906V11.1979C25.6906 10.5304 26.2167 9.98926 26.8656 9.98926C27.5145 9.98926 28.0406 10.5304 28.0406 11.1979V26.7914C28.0406 27.4589 27.5145 28 26.8656 28H5.88396C5.23504 28 4.70898 27.4589 4.70898 26.7914V11.1979C4.70898 10.5304 5.23504 9.98926 5.88396 9.98926Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M2.375 5.20863C2.375 4.54112 2.90105 4 3.54997 4H29.2C29.8489 4 30.375 4.54112 30.375 5.20863V11.1978C30.375 11.8654 29.8489 12.4065 29.2 12.4065H3.54997C2.90105 12.4065 2.375 11.8654 2.375 11.1978V5.20863ZM4.72494 6.41726V9.98921H28.0251V6.41726H4.72494Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M12.8652 16.0001C12.8652 15.3326 13.3913 14.7915 14.0402 14.7915H18.7086C19.3575 14.7915 19.8836 15.3326 19.8836 16.0001C19.8836 16.6676 19.3575 17.2088 18.7086 17.2088H14.0402C13.3913 17.2088 12.8652 16.6676 12.8652 16.0001Z' fill='#272827' />
	</Svg>
)
