import { clone } from '../../../Utils/clone'
import { GUARANTEES, GUARANTEES_DETAILS } from '../../../../Constants/GUARANTEES'
import { checkIfCoinIsInBrazilianFormat } from '../../../Utils/checkIfCoinIsInBrazilianFormat'
import { GuaranteeProps, TypeTeaser } from '../../types/teaser'

type InsuresType = {
	g001: number,
	g002: number,
	g003: number,
	g004: number,
	g005: number,
	g006: number
}

export const getInsure = (teaser: TypeTeaser, guarantees: GuaranteeProps, guarantee: GUARANTEES): number => {

	const cloneGuarantees = clone(guarantees)
	const cloneGuarantee = clone(guarantee)

	if (!cloneGuarantees?.[cloneGuarantee]) return 0

	const insures = cloneGuarantees[cloneGuarantee]

	if (!insures) return 0

	return insures.reduce((accumulator, value) => {
		if (!value?.value || !value?.type) return accumulator

		const enable = value?.enabled ?? true

		if (!enable) return accumulator

		const _value = checkIfCoinIsInBrazilianFormat(value?.value)

		const multiple =
			GUARANTEES_DETAILS[cloneGuarantee].subType?.[value?.type]?.multiplicator(teaser) ?? 1

		return accumulator + _value * multiple
	}, 0)
}

export const getInsures = (teaser: TypeTeaser): InsuresType => {

	const cloneTeaser = clone(teaser)

	if (!cloneTeaser.guarantees) return {
		g001: 0,
		g002: 0,
		g003: 0,
		g004: 0,
		g005: 0,
		g006: 0
	}

	const guarantees = cloneTeaser.guarantees

	return {
		g001: getInsure(teaser, guarantees, GUARANTEES.guarantee1),
		g002: getInsure(teaser, guarantees, GUARANTEES.guarantee2),
		g003: getInsure(teaser, guarantees, GUARANTEES.guarantee3),
		g004: getInsure(teaser, guarantees, GUARANTEES.guarantee4),
		g005: getInsure(teaser, guarantees, GUARANTEES.guarantee5),
		g006: getInsure(teaser, guarantees, GUARANTEES.guarantee6)
	}
}
