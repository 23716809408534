import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { STATUS_MATCH_MAKING } from '../../../Constants/STATUS_MATCH_MAKING'
import { TypeMatch } from '../types/match'
import { fnMatch } from '..'
import { clone } from '../../../Functions/Utils/clone'

// PRE MATCH
export const inPreMatchDeclined = (match: TypeMatch) => {

	const cloneMatch = clone(match)

	return STATUS_MATCH.excluido.value === fnMatch.status(cloneMatch) && fnMatch.matchMakingStatus(cloneMatch) === STATUS_MATCH_MAKING.beforeMatchMaking.value
}
