import { TypeTeaser } from '../types/teaser'
import { clone } from '../../../Functions/Utils/clone'
import { TypeScoreStark } from './scoreStark/types/TypeScoreStark'
import { fnDate } from '../../Utils/date'

/**
 * Em alguns casos do score, o item poderá ter o seu valor ajustado caso após análise validem sua elegibilidade para a transação
 * esse método ajusta o valor para o valor necessário para que o item do score seja aprovado
 * @param teaser  - TypeTeaser
 * @returns
 */
export const adjustDesiredValue = ({ teaser, score }: { score: TypeScoreStark, teaser: TypeTeaser }) => {
	const clonedTeaser = clone(teaser)
	const desiredValueAdjusted: TypeTeaser['desiredValueAdjusted'] = clonedTeaser.desiredValueAdjusted.date ? { date: 0, value: '' } : { value: clonedTeaser.desiredValue, date: fnDate.new() }
	const desiredValue: TypeTeaser['desiredValue'] = clonedTeaser.desiredValueAdjusted ?
		clonedTeaser.desiredValueAdjusted.value :
		String((score.metadata.governanceCoverage * score.metadata.valueTotalInsure) / 100)

	clonedTeaser.desiredValueAdjusted = desiredValueAdjusted
	clonedTeaser.desiredValue = desiredValue
	return clonedTeaser
}
