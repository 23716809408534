import { DEVICE } from 'Constants/DEVICE'
import { convertToRem } from 'Functions/Utils/convertToRem'
import styled, { css } from 'styled-components'

interface ContainerProps {
	mobile?: boolean
	active?: boolean
}

export const Container = styled.div<ContainerProps>`
position: fixed;
right: -5px;
top: 0;
width: 0px;
height:${props => props.mobile ? 'calc(100vh - 60px)' : '100%'};
background: #FFFFFF;
box-sizing: border-box;
z-index: 99;
transition: 0.3s ease-in-out;

${props => {
		if (props.mobile) {
			if (props.active) return css`width: 100%;`
			if (!props.active) return css`display: none`
		}
		if (!props.mobile) {
			if (props.active) return css`width: 392px;`
			if (!props.active) return css`width: 0px;`
		}
	}}

`
export const Title = styled.div`
	padding: 14px 16px 15px 16px;

	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	background-color: #ffffff;
	border-bottom: 1px solid #f1f5f9;

`
export const TitleText = styled.div`
	font-size: ${convertToRem(16)};
	font-weight: 700;
	color: #272827;
`
export const TitleIcon = styled.div`
	flex:1;
	cursor: pointer;
	text-align: right;
`

export const NotificationContainer = styled.div<ContainerProps>`
top: 0;
width: 100%;
/* height: ${props => props.mobile ? '90%' : '100%'}; */
overflow-y: scroll;
position: absolute;
height: 100%;
// ${DEVICE.xs}{
// 	height: calc(100% - 60px) !important;
// }
// ${DEVICE.all}{
// 	height: 115vh;
// }

& {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

&::-webkit-scrollbar {
  width: 8px;
	height: 184px;
}

&::-webkit-scrollbar-track {
  background: transparent;
	border-radius: 500px;
	margin-bottom: 10px;
}

&::-webkit-scrollbar-thumb {
  background-color: transparent;
	height: 184px;
  border-radius: 350px;
}
`

export const NotificationBox = styled.div`
padding: 60px 5px 50px 16px;
`

interface NotificationProps {
	notRead: boolean
	mobile: boolean
}

export const Notification = styled.div<NotificationProps>`
	background: ${props => props.notRead ? '#EAEEFF' : '#FFFFFF'};
	border: 1px solid #DFDFDF;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 12px 0;
	padding: 16px;
	font-weight: 400;
	font-size: ${convertToRem(12)};
	width: 358px;

	${DEVICE.xs}{
		width: 100%;
	}

	&.cursor{
		cursor: pointer;
	}
`
export const NotificationText = styled.div`
	color: #272827;
	margin-bottom: 16px;
`
export const NotificationDate = styled.div`
	color: #6f6f6f;
`
