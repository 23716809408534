export const STATUS_TERRENO = {
	s000: { label: 'Sem status', _id: 's000' },
	s001: { label: 'Terreno próprio', _id: 's001' },
	s002: { label: 'Terreno de permuta', _id: 's002' },
} as const

export const STATUS_TERRENO_GET = (code: string) => {
	const _code = code as keyof typeof STATUS_TERRENO

	if (!STATUS_TERRENO[_code]) {
		return (STATUS_TERRENO.s000)
	}

	return STATUS_TERRENO[_code]
}
export const STATUS_TERRENO_VALUES = Object.values(STATUS_TERRENO)
export const STATUS_TERRENO_KEYS = Object.keys(STATUS_TERRENO)
