import React from 'react'

import { mergeClassName } from '../Functions/Utils/mergeClassName'

interface IUiCard extends React.ComponentProps<'div'> {
	className?: string
}

export const UiCard = (props: IUiCard) =>
	<div
		{...props}
		className={
			mergeClassName(
				'tw-border tw-border-solid tw-border-neutral-200 tw-rounded-lg tw-bg-white tw-overflow-hidden tw-w-full',
				props.className || '',
			)
		}
	/>

