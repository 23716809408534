import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const BarChart3 = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M15.9969 12.5206C15.2196 12.5206 14.6478 13.062 14.6478 13.7979V25.0654C14.6478 25.8013 15.2196 26.3427 15.9969 26.3427C16.6983 26.3427 17.346 25.7712 17.346 25.0654V13.7979C17.346 13.062 16.7741 12.5206 15.9969 12.5206ZM12.3691 13.7979C12.3691 11.8705 13.9612 10.3633 15.9969 10.3633C18.0326 10.3633 19.6246 11.8705 19.6246 13.7979V25.0654C19.6246 27.0229 17.8921 28.5 15.9969 28.5C13.9612 28.5 12.3691 26.9928 12.3691 25.0654V13.7979Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M24.8729 5.65736C24.0956 5.65736 23.5238 6.19874 23.5238 6.93462V25.0651C23.5238 25.801 24.0956 26.3424 24.8729 26.3424C25.5742 26.3424 26.2219 25.7709 26.2219 25.0651V6.93462C26.2219 6.19874 25.6501 5.65736 24.8729 5.65736ZM21.2451 6.93462C21.2451 5.00726 22.8371 3.5 24.8729 3.5C26.9086 3.5 28.5006 5.00726 28.5006 6.93462V25.0651C28.5006 27.0226 26.7681 28.4997 24.8729 28.4997C22.8371 28.4997 21.2451 26.9925 21.2451 25.0651V6.93462Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M7.12774 17.2316C6.35048 17.2316 5.77866 17.773 5.77866 18.5088V25.0645C5.77866 25.8004 6.35048 26.3418 7.12774 26.3418C7.82911 26.3418 8.47683 25.7703 8.47683 25.0645V18.5088C8.47683 17.773 7.905 17.2316 7.12774 17.2316ZM3.5 18.5088C3.5 16.5815 5.09201 15.0742 7.12774 15.0742C9.16347 15.0742 10.7555 16.5815 10.7555 18.5088V25.0645C10.7555 27.022 9.02298 28.4991 7.12774 28.4991C5.09202 28.4991 3.5 26.9919 3.5 25.0645V18.5088Z' fill='#272827' />
	</Svg>
)
