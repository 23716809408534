import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const IdCardFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M24.2 6.4H19.184C18.68 5.008 17.36 4 15.8 4C14.24 4 12.92 5.008 12.416 6.4H7.4C6.08 6.4 5 7.48 5 8.8V25.6C5 26.92 6.08 28 7.4 28H24.2C25.52 28 26.6 26.92 26.6 25.6V8.8C26.6 7.48 25.52 6.4 24.2 6.4ZM15.8 6.4C16.46 6.4 17 6.94 17 7.6C17 8.26 16.46 8.8 15.8 8.8C15.14 8.8 14.6 8.26 14.6 7.6C14.6 6.94 15.14 6.4 15.8 6.4ZM15.8 11.2C17.792 11.2 19.4 12.808 19.4 14.8C19.4 16.792 17.792 18.4 15.8 18.4C13.808 18.4 12.2 16.792 12.2 14.8C12.2 12.808 13.808 11.2 15.8 11.2ZM23 25.6H8.6V23.92C8.6 21.52 13.4 20.2 15.8 20.2C18.2 20.2 23 21.52 23 23.92V25.6Z' fill='#272827' />
	</Svg>
)
