import { TypeUser } from '../types/TypeUser'

/**
 * Filta um array de usuários pelo nome
 * @param teasers
 * @param order
 * @returns  TypeUser[]
 */
export const filterByName = ({ users, filter }: { users: TypeUser[], filter: string }): TypeUser[] =>
	users.filter(user => `${user.nome}${user.sobrenome}`.trim().toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase()))
