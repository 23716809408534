import VMasker from 'vanilla-masker'


/**
 * @deprecated
 * Converte um valor para o formato de moeda.
 * @param value string
 * @param val string | null
 * @returns valor convertido no formato de moeda.
 * @example converteToMoney('1000') => '1.000,00'
 * @example convertToMoney('-1000') => '-1.000,00'
 */


export default function convertToMoney(value: string, val: string | null): string {

	let cloneValue: string = JSON.parse(JSON.stringify(value))
	const cloneVal: string | null = JSON.parse(JSON.stringify(val))

	let minus = ''
	const minusChar = '-'

	if (cloneValue.indexOf(minusChar) !== -1 || cloneValue === minusChar) {
		minus = minusChar
	}
	if (cloneValue === '-0,0' && !cloneVal) {
		minus = ''
	}
	cloneValue = VMasker.toMoney(cloneValue)
	cloneValue = `${minus}${VMasker.toMoney(cloneValue)}`

	return cloneValue
}
