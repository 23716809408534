import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const DripFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M16.0039 27.6426C20.5541 27.6426 24.2539 23.9889 24.2539 19.471C24.2539 15.522 18.8091 10.665 16.6886 6.05964C16.4346 5.50175 15.6174 5.50175 15.3633 6.07058C13.2981 10.6541 7.75391 15.5439 7.75391 19.482C7.75391 23.9889 11.4427 27.6426 16.0039 27.6426ZM11.4206 18.5193C12.1384 18.5193 12.7127 19.0882 12.7127 19.7992C12.7127 21.0353 13.7288 22.0417 14.9768 22.0417C15.6947 22.0417 16.269 22.6106 16.269 23.3216C16.269 24.0327 15.6947 24.6015 14.9768 24.6015C12.3041 24.6015 10.1284 22.4465 10.1284 19.7992C10.1284 19.0991 10.7137 18.5193 11.4206 18.5193Z' fill='#272827' />
	</Svg>
)


