import { formatNumberToKMBT } from 'Functions/Utils/formatNumberToKMBT'
import { VALUATION_TYPES } from 'Screens/Sinatra/Constants/ValuationTypes'
import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'

export const getTemporaryValuation = (valuation: TypeValuation) => {
	const { valuationOfBilling, valuationOfEbitda, valuationResult: { percentage, typeValue } } = valuation

	const ebitdaOpen = valuationOfEbitda.open && percentage ?
		getValueByCalculationType(valuationOfEbitda.open, percentage, typeValue) : valuationOfEbitda.open || 0
	const ebitdaClosed = valuationOfEbitda.closed && percentage ?
		getValueByCalculationType(valuationOfEbitda.closed, percentage, typeValue) : valuationOfEbitda.closed || 0
	const ebitdaTotal = valuationOfEbitda.total && percentage ?
		getValueByCalculationType(valuationOfEbitda.total, percentage, typeValue) : valuationOfEbitda.total || 0

	const billingOpen = valuationOfBilling.open && percentage ?
		getValueByCalculationType(valuationOfBilling.open, percentage, typeValue) : valuationOfBilling.open || 0
	const billingClosed = valuationOfBilling.closed && percentage ?
		getValueByCalculationType(valuationOfBilling.closed, percentage, typeValue) : valuationOfBilling.closed || 0
	const billingTotal = valuationOfBilling.total && percentage ?
		getValueByCalculationType(valuationOfBilling.total, percentage, typeValue) : valuationOfBilling.total || 0

	const result = {
		[VALUATION_TYPES.ebitdaOpenCapital._id]: {
			formatted: formatNumberToKMBT(ebitdaOpen, 1),
			value: ebitdaOpen
		},
		[VALUATION_TYPES.ebitdaClosedCapital._id]: {
			formatted: formatNumberToKMBT(ebitdaClosed, 1),
			value: ebitdaClosed
		},
		[VALUATION_TYPES.ebitdaTotal._id]: {
			formatted: formatNumberToKMBT(ebitdaTotal || 0, 1),
			value: ebitdaTotal
		},
		[VALUATION_TYPES.billingOpenCapital._id]: {
			formatted: formatNumberToKMBT(billingOpen, 1),
			value: billingOpen
		},
		[VALUATION_TYPES.billingClosedCapital._id]: {
			formatted: formatNumberToKMBT(billingClosed, 1),
			value: billingClosed
		},
		[VALUATION_TYPES.billingTotal._id]: {
			formatted: formatNumberToKMBT(billingTotal || 0, 1),
			value: billingTotal
		}
	}
	return result
}

const getValueByCalculationType = (value: number, percentage: number, type: TypeValuation['valuationResult']['typeValue']) =>
	type === 'increase' ? value + (percentage * value) / 100 : value - (percentage * value) / 100
