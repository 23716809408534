import { Notifications } from 'Components/Layout/Notifications'
import { ReactChild } from 'react'
import { LayoutMenuSide } from 'Components/Layout/LayoutMenuSide'
import { LayoutTabsFooter } from 'Components/Layout/LayoutTabsFooter'
import { useContextNotificationWidget } from 'App/ContextNotificationWidget'
import { AssistantChat } from '../../AssistantChat'

export const LayoutContainer = ({ children, bgWhite = true, menu = true }: { children: ReactChild, bgWhite?: boolean, menu?: boolean }) => {

	const { openNotification } = useContextNotificationWidget()

	return (
		<div>
			<div className='tw-flex tw-flex-col tw-h-screen tw-overflow-hidden md:tw-flex-row tw-relative'>
				{menu && <div className={'tw-hidden lg:tw-block tw-w-[200px] tw-fixed tw-top-0 tw-left-0 tw-h-screen tw-z-20'}>
					<LayoutMenuSide />
				</div>}
				<div id='layout-container' className={`tw-w-full ${menu ? 'lg:tw-ml-[200px]' : ''} tw-duration-200 tw-h-full tw-overflow-auto tw-relative ${bgWhite ? 'tw-bg-white' : 'tw-bg-neutral-50'}`} style={{ height: '100% !important' }}>
					{children}
				</div>
			</div>
			<Notifications />

			<AssistantChat />
			{/* <div className='tw-block md:tw-hidden'>
			<LayoutTabsFooter />
		</div> */}
		</div>
	)
}
