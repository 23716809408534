import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeSendNda = {
	idMatch: string
}

type SendNdaResponse = {
	status: number,
	url: string
}

export const _sendNda = (input: TypeSendNda): Promise<{ data: SendNdaResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'sendNda', input })
