/* eslint-disable no-console */

import { clone } from './clone'

/**
* Retorna uma nova data menos X meses
* @param {Number} month {Number} Meses a serem subtraídos
* @param {Date} today {Date} Data
* @returns {Date} {Date} Data - X meses
* @example xMonthsAgo(2, new Date('2023/05/23')) => Wed Mar 01 2023 03:00:00 GMT-0300 (Horário Padrão de Brasília)
*/
export const xMonthsAgo = (month: number, today?: Date) => {

	const cloneMonth = clone(month)
	let cloneToday = clone(today)

	// TODO: TEMOS QUE NIVELAR O MONTH, APENAS PARA NÚMEROS NATURAIS
	cloneToday = cloneToday || new Date()
	cloneToday = new Date(cloneToday.setMonth(cloneToday.getMonth() - cloneMonth))
	return cloneToday
}

// export const xMonthsAgo = (month: number, today?: Date) => {
// 	today = today || new Date()
// 	console.log('XMONTH incial', month, today)
// 	const newMonth = today.getMonth() - month
// 	const year = today.getFullYear()
// 	const day = 1
// 	console.log('XH final', new Date(year, newMonth, day))
// 	return new Date(year, newMonth, day)
// }


