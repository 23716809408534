import { clone } from '../../../Utils/clone'
import { STATUS_MATCH } from '../../../../Constants/STATUS_MATCH'
import { TypeMatch } from '../../types/match'

export const relationStatus = (match: TypeMatch) => {

	const cloneMatch = clone(match)

	Object.values(STATUS_MATCH).filter(status => {
		if (status.value === cloneMatch.status) {
			return status
		}
	})
	return STATUS_MATCH.salvo
}
