import { TypeUser } from '../types/TypeUser'

/**
 * Ordena um array de usuários pelo telefone
 * @param teasers
 * @param order
 * @returns  TypeUser[]
 */
export const sortByPhone = ({ users, order }: { users: TypeUser[], order: 'asc' | 'desc' }): TypeUser[] =>
	users.sort((userA: TypeUser, userB: TypeUser) => {
		const isAsc = order === 'asc'
		const nameA = userA?.telefone || ''
		const nameB = userB?.telefone || ''
		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})
