import { notifications } from '../../../Services/cloudfunctions'
import { minhaData } from '../../Utils/minhaData'
import { db } from '../../../Services/db'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { createCodinome } from './createCodinome'
import { db5 } from '../../../Services/db5'

type Props = {
	uid?: string
	uidCo?: string
	isFromOffice?: string
	origination: TypeTeaser
}

// Link de afiliados Stark

// link de um Agente
// https://app.stark.money/teaser-add/hBmCvqG6YqgNFS2VRQtJZpu7Fcy2
// o código após o / é o uid do agente

// link de um assessor de um EP
// https://app.stark.money/teaser-add/Q53o34gisqQcAfQ1pEjc5BjbcJv2/o4sid5nashhD9WFTUmXcw7kRaE23
// o código após o / é o uid do EP, e após o segundo / é o uid do assessor

// link de um agente ligado a um Office
// https://app.stark.money/teaser-add/Q53o34gisqQcAfQ1pEjc5BjbcJv2/o4sid5nashhD9WFTUmXcw7kRaE23/office
// o código após o / é o uid do Office, e após o segundo / é o uid do agente, e após o terceiro / é a palavra office para diferenciar do link de assessores e EPs

export const createOffline = async (props: Props) => {
	const { origination, isFromOffice, uid, uidCo } = props
	origination.codinome = createCodinome(origination.nome)
	origination.origin = 'Link'

	if (isFromOffice === 'office') {
		origination.uid = uidCo || ''
		origination.fromOffice = uid || ''
	}
	else {
		origination.uid = uid || 'l1zCnbP2MCaDHQwfAWOpneTECMX2'
		origination.uidCoCreator = uidCo || ''
	}
	const data: TypeTeaser = {
		...origination,
		ativado: true,
		dataCriacao: minhaData(),
		dataModificacao: minhaData(),
	}
	db5.teasers.add({
		doc: data
	}).then(async res => {
		if (!res.id) return
		await notifications({
			targetId: res.id,
			typeOrMessage: 'a0030',
			profileUid: uid || ''
		})
	})



	return true
}
