/**
 * A função `inConcluido` verifica se o subStatusOfActiveCostumer de um teaser
 * corresponde a um código específico.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente é um objeto contendo informações sobre um teaser. A função
 * `inConcluido` pega esse objeto `teaser` e cria um clone dele usando o
 * função `clone` e então verifica se o `sub
 * @returns A função `inConcluido` está retornando um valor booleano que indica
 * se a propriedade `subStatusOfActiveCostumer` do objeto `teaser` foi aprovada
 * como argumento é igual ao valor do código de
 * `SUB_STATUS_OF_ACTIVE_COSTUMER.s00600`.
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const inConcluido = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return SUB_STATUS_OF_ACTIVE_COSTUMER.s00600.code === cloneTeaser.subStatusOfActiveCostumer

}
