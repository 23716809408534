/**
 * O código define funções para validar e criar um objeto teaser mínimo baseado
 *em critérios específicos.
 * @param {TypeTeaser} teaser - O código que você forneceu define duas funções:
 * `teaserMinimal` e `validação`.
 * @returns A função `validation` retorna um objeto do tipo `TValidation`,
 * que inclui as seguintes propriedades:
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const teaserMinimal = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return (
		cloneTeaser.nome &&
		cloneTeaser.cnpj &&
		cloneTeaser.codinome &&
		// teaser.titulo &&
		// teaser.grau &&
		cloneTeaser.setor &&
		cloneTeaser.subsetor &&
		(
			cloneTeaser.regiao['Norte'] ||
			cloneTeaser.regiao['Nordeste'] ||
			cloneTeaser.regiao['Centro-Oeste'] ||
			cloneTeaser.regiao['Sudeste'] ||
			cloneTeaser.regiao['Sul'] ||
			cloneTeaser.regiao['Atuação Internacional']
		) &&
		cloneTeaser.site &&
		cloneTeaser.overview &&
		// teaser.dpp &&
		// teaser.cdr &&
		// teaser.prr &&
		(cloneTeaser.rb1 && cloneTeaser.rb1 !== '-') &&
		// teaser.rb2 &&
		// teaser.rb3 &&
		// teaser.rl2 &&
		// teaser.rl3 &&
		(cloneTeaser.eb1 && cloneTeaser.eb1 !== '-') &&
		// teaser.eb2 &&
		// teaser.eb3 &&
		// teaser.emprestimos &&
		// teaser.tributos &&
		// teaser.fornecedores &&
		// teaser.maanterior &&
		(
			cloneTeaser.etr['Aquisição Total'] ||
			cloneTeaser.etr['Aquisição Majoritária'] ||
			cloneTeaser.etr['Aquisição Minoritária']
		)
		&&

		((cloneTeaser.valuationExpectation !== 0 && !!cloneTeaser.valuationExpectation) || cloneTeaser.valuationExpectationUndefined)
		// teaser.motivo
	) ? true : false

}


export type TValidation = {
	porcentagem: number | string,
	preenchido: number | string,
	faltando: number | string,
	teaserMinimal: boolean
}

export const validation = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	let preenchido = 0
	let faltando = 0

	cloneTeaser.nome ? preenchido++ : faltando++
	cloneTeaser.site ? preenchido++ : faltando++
	cloneTeaser.cnpj ? preenchido++ : faltando++
	cloneTeaser.sede ? preenchido++ : faltando++

	cloneTeaser.codinome ? preenchido++ : faltando++
	cloneTeaser.grau ? preenchido++ : faltando++
	cloneTeaser.setor ? preenchido++ : faltando++
	cloneTeaser.subsetor ? preenchido++ : faltando++

	(
		cloneTeaser.regiao['Norte'] ||
		cloneTeaser.regiao['Nordeste'] ||
		cloneTeaser.regiao['Centro-Oeste'] ||
		cloneTeaser.regiao['Sudeste'] ||
		cloneTeaser.regiao['Sul'] ||
		cloneTeaser.regiao['Atuação Internacional']
	) ? preenchido++ : faltando++
	// teaser.relacionamento ? preenchido++ : faltando++

	cloneTeaser.overview ? preenchido++ : faltando++

	// teaser.dpp ? preenchido++ : faltando++
	cloneTeaser.cdr === true || cloneTeaser.cdr === false ? preenchido++ : faltando++ // a variável cdr começa como string vazia, e somente com interação do user que vira boolean
	cloneTeaser.cdrlct ? preenchido++ : faltando++
	// teaser.prr ? preenchido++ : faltando++

	(cloneTeaser.rb1 && cloneTeaser.rb1 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.rb2 && cloneTeaser.rb2 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.rb3 && cloneTeaser.rb3 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.rl1 && cloneTeaser.rl1 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.rl2 && cloneTeaser.rl2 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.rl3 && cloneTeaser.rl3 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.eb1 && cloneTeaser.eb1 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.eb2 && cloneTeaser.eb2 !== '-') ? preenchido++ : faltando++
	(cloneTeaser.eb3 && cloneTeaser.eb3 !== '-') ? preenchido++ : faltando++

	cloneTeaser.emprestimos ? preenchido++ : faltando++
	cloneTeaser.tributos ? preenchido++ : faltando++
	cloneTeaser.fornecedores ? preenchido++ : faltando++
	cloneTeaser.outrasdividas ? preenchido++ : faltando++

	// teaser.maanterior ? preenchido++ : faltando++
	(
		cloneTeaser.etr['Aquisição Total'] ||
		cloneTeaser.etr['Aquisição Majoritária'] ||
		cloneTeaser.etr['Aquisição Minoritária']
	) ? preenchido++ : faltando++
	cloneTeaser.motivo ? preenchido++ : faltando++

	return {
		porcentagem: ((preenchido / (preenchido + faltando)) * 100).toFixed(0),
		preenchido,
		faltando,
		teaserMinimal: teaserMinimal(cloneTeaser)
	}
}
