/**
 * A função `getStatusCustomer` determina o status de um cliente com base em um
 * determinado objeto teaser.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente é um objeto contendo informações relacionadas a um teaser. Esse
 * A função `getStatusCustomer` pega um objeto teaser como entrada e o clona usando o
 * função `clone` e, em seguida, verifica certas condições usando funções auxiliares como
 * `inCom
 * @returns A função `getStatusCustomer` está retornando o status do
 * cliente com base no objeto `teaser` fornecido. A função primeiro clona o
 * Objeto `teaser` usando a função `clone`. Em seguida, ele verifica o status do
 * cliente chamando diferentes funções (`inComite`, `inComercial`, `inStandby`,
 * `inAtivo`) passando o `teaser` clonado
 */
//CRIADO POR IA

import { STATUS_CUSTOMER } from '../../../Constants/STATUS_CUSTOMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'
import { inComercial } from './inComercial'
import { inComite } from './inComite'
import { inStandby } from './inStandby'

export const getStatusCustomer = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	if (inComite(cloneTeaser)) return STATUS_CUSTOMER.comite
	if (inComercial(cloneTeaser)) return STATUS_CUSTOMER.comercial
	if (inStandby(cloneTeaser)) return STATUS_CUSTOMER.standby
	if (inAtivo(cloneTeaser)) return STATUS_CUSTOMER.ativo
}
