import React, { FC, HTMLAttributes, ReactChild } from 'react'
import styled from 'styled-components'
import { SPACES } from './settings'

/** @deprecated */
export interface HeaderWrapperProps extends HTMLAttributes<HTMLDivElement> {
	/** Component children */
	children?: ReactChild | ReactChild[];

	/** Spacing */
	spacing?: 'sm' | 'md' | 'lg';
}

const LayoutStyled = styled.div`
    box-sizing: border-box;
    height: 76px;
    width: 100%;
    align-items: center;
    display: flex;
     ${(props: HeaderWrapperProps) => props.spacing ? `padding: 0 ${SPACES[props.spacing]}px` : ''};
`
/**
 * A custom header wrapper component
 */
/** @deprecated */
export const HeaderWrapper: FC<HeaderWrapperProps> = props => {
	const { children } = props
	return <LayoutStyled {...props}>{children}</LayoutStyled>
}

const DF: HeaderWrapperProps = {
	spacing: 'md'
}

HeaderWrapper.defaultProps = DF

