import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const MapOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M10.7083 2.22167C11.158 1.94243 11.7061 1.92604 12.1692 2.178L20.4755 6.69731L27.6826 2.22167C28.1622 1.92386 28.7512 1.92628 29.2287 2.228C29.7062 2.52973 30 3.08517 30 3.68602V23.3914C30 23.9968 29.7017 24.5557 29.2186 24.8557L21.2917 29.7783C20.842 30.0575 20.2939 30.0739 19.8308 29.822L11.5245 25.3027L4.31737 29.7783C3.8378 30.0761 3.24883 30.0737 2.77134 29.772C2.29385 29.4702 2 28.9148 2 28.3139V8.6086C2 8.00322 2.29826 7.4443 2.78144 7.14425L10.7083 2.22167ZM11.5245 5.5973L5.09881 9.58767V25.4105L10.7083 21.927C11.158 21.6478 11.7061 21.6314 12.1692 21.8834L20.4755 26.4027L26.9012 22.4123V6.58945L21.2917 10.0729C20.842 10.3522 20.2939 10.3686 19.8308 10.1166L11.5245 5.5973Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M11.4762 2C12.3319 2 13.0256 2.75486 13.0256 3.68602V23.3914C13.0256 24.3225 12.3319 25.0774 11.4762 25.0774C10.6205 25.0774 9.92676 24.3225 9.92676 23.3914V3.68602C9.92676 2.75486 10.6205 2 11.4762 2Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M20.524 6.92285C21.3797 6.92285 22.0734 7.67771 22.0734 8.60887V28.3142C22.0734 29.2454 21.3797 30.0002 20.524 30.0002C19.6683 30.0002 18.9746 29.2454 18.9746 28.3142V8.60887C18.9746 7.67771 19.6683 6.92285 20.524 6.92285Z' fill='#272827' />
	</Svg>
)
