import classNames from 'Functions/Utils/classNames'
import { mergeClassName } from 'Functions/Utils/mergeClassName'
import React from 'react'

export interface IUiTagProps extends React.ComponentProps<'div'> {
	variant?: 'blue' | 'orange' | 'default';
	size?: 'small' | 'medium' | 'large'
	active?: boolean;
	color?: string;
	/**
	 * Habilita o botão de remover no canto direito da tag
	 */
	deletable?: boolean
	/**
	 * Se o texto será bold ou não
	 */
	isBold?: boolean
	/**
	 * Método chamado ao clicar no botão de deletar
	 */
	onDelete?: () => void
}

export const variantsSize = {
	'small': 'tw-text-[10px]',
	'medium': 'tw-text-xs',
	'large': 'tw-text-sm'
}

export const variants = {
	'blue': {
		light: 'blue-100',
		dark: 'blue-600',
	},
	'orange': {
		light: 'orange-100',
		dark: 'orange-600',
	}
	,
	'default': {
		light: 'neutral-100',
		dark: 'neutral-700',
	}
}

export const UiTag = ({ variant = 'blue', isBold = true, deletable = false, size = 'small', active = false, color, ...props }: IUiTagProps) =>
	<div
		{...props}
		className={
			mergeClassName(
				`${variantsSize[size]} 
				${active ? `tw-bg-${variants[variant]['dark']} tw-text-${variants[variant]['light']} tw-text-white` : `tw-bg-${variants[variant]['light']} tw-text-${variants[variant]['dark']}`}
				tw-rounded-md ${isBold ? ' tw-font-bold ' : ''} tw-flex  tw-items-center tw-justify-center   tw-w-fit tw-leading-none	
			${(deletable && active) ? 'tw-pl-[6px] tw-pr-[2px]' : ' tw-px-[6px] '}
				${props.className || ''}
				`,
				color ?
					(active ?
						`tw-bg-${color}-600` + ` tw-text-${color}-100` :
						`tw-bg-${color}-100` + ` tw-text-${color}-600`) :
					''
			)
		}
	>
		<div className='tw-py-1'>{props.children}</div>
		{
			deletable && active && <div onClick={props.onDelete} className=' tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-1 tw-w-[14px] tw-h-[14px] tw-bg-white/20 tw-rounded-full	'><span className='material-icons tw-text-white tw-text-[10px]'>close</span></div>
		}
	</div>

