/**
 * A função `cnpjToCnaes` busca informações da empresa com base em um número de CNPJ,
 * extrai atividades primárias e secundárias, converte-as em códigos CNAE e
 * atualiza um banco de dados com os CNAEs extraídos.
 * @param {string} id - O parâmetro `id` é uma string que representa o
 * identificador de uma entidade ou objeto específico. É usado dentro do `cnpjToCnaes`
 * função para identificar exclusivamente a entidade que está sendo processada.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` no `cnpjToCnaes`
 * a função é do tipo `TypeTeaser`. Ele contém informações sobre um teaser, que
 * provavelmente inclui uma propriedade `cnpj` representando um número de CNPJ. A função usa
 *este número de CNPJ para buscar dados de uma API
 * @returns A função `cnpjToCnaes` retorna um array de strings representando o
 * Códigos CNAE associados a determinado número de CNPJ.
 */
//CRIADO POR IA

import { TypeTeaser } from '../types/teaser'
import { stringToCnae } from '../../../Functions/Utils/numberToCnae'
import { db } from '../../../Services/db'
import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'

export const cnpjToCnaes = async (id: string, teaser: TypeTeaser) => {

	const cloneId = clone(id)
	const cloneTeaser = clone(teaser)

	const cnpj = cloneTeaser.cnpj
	const cnaes: string[] = []

	const numberCnpj = cnpj.replace(/\D/g, '')
	const res = await fetch(`https://api.cnpja.com.br/companies/${numberCnpj}`, {
		'method': 'GET',
		'headers': {
			'authorization': '690197b9-0483-4557-b140-c45fc08be9e1-b1aca2b6-8f00-4cae-997e-1794dbc64ded'
		}
	})
	const resText = await res.text()
	const resJson = await JSON.parse(resText)


	if (res.status !== 200) {
		return cnaes
	}
	const cnaePrincipal = stringToCnae(resJson?.primary_activity?.code)
	cnaes.push(cnaePrincipal)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	resJson.secondary_activities.forEach((activity: any) => {
		cnaes.push(stringToCnae(activity.code))
	})

	db5.teasers.update({
		id: cloneId,
		doc: { cnaes: cnaes }
	})

	return cnaes
}
