/**
 * A função `inRequestedChanges` verifica se um teaser solicitou alterações com base
 *na data de sua publicação e data de alterações solicitadas.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um objeto que contém informações sobre um teaser. A função
 * `inRequestedChanges` pega este objeto `teaser` como entrada e verifica se o
 * A propriedade `dataPublicacao` é falsa e o `dateRequestedChanges`
 * @returns A função `inRequestedChanges` está retornando um valor booleano. Isto
 * verifica se a propriedade `dataPublicacao` do objeto `teaser` clonado é falsa
 * e se a propriedade `dateRequestedChanges` do objeto `teaser` clonado for
 *verdade. Se ambas as condições forem atendidas, retorna `true`, caso contrário, retorna
 * `falso`.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const inRequestedChanges = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return !cloneTeaser.dataPublicacao && cloneTeaser.dateRequestedChanges
}
