import { TypeDbResponse } from '../TypeDBResponse'
import { DocumentData } from '@firebase/firestore-types'
import firebase from 'firebase/app'
import { TypeCollections } from '../configs/collections'

export const del = <TDoc extends DocumentData>(collection: TypeCollections) => (
	async ({ id }: { id: string }): Promise<TypeDbResponse<TDoc>> => {

		const serviceResult: TypeDbResponse<TDoc> = {
			status: 400
		}

		try {
			await firebase.firestore().collection(collection).doc(id).delete()
			serviceResult.status = 200
		}
		catch (error) {
			// throw new Error(`Erro ao deletar documento :: ${error}`)
		}

		return serviceResult
	}
)
