import { clone } from './clone'

/**
 * Generate a unique id
 * @returns {string} - A unique id
 * @example
 * generateUniqueId('user')
 * => user-19a2c5db-23eb-9b2b-c764-695249595f59
 * @example
 * generateUniqueId()
 * => 19a2c5db-23eb-9b2b-c764-695249595f59
 **/

export const generateUniqueId = (prefix?: string) => {

	const clonePrefix = clone(prefix)

	const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
	//return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
	return `${clonePrefix ? `${clonePrefix}-` : ''}${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}
