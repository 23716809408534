import { cnpjToCnaes } from './methods/cnpjToCnaes'
import { getTags } from './methods/get/getTags'
import { validation } from './methods/validation'
import { cnaesToTags } from './methods/cnaesToTags'
import { cnaesFactored } from './methods/cnaesFactored'
import { createTeaser } from './methods/createTeaser'
import { getBda } from './methods/get/getBda'
import { getFirstRb } from './methods/get/getFirstRb'
import { assinarContrato } from './methods/assinarContrato'
import { curar } from './methods/curar'
import { toComercial } from './methods/toComercial'
import { toggleLine } from './methods/toggleLine'
import { toStandby } from './methods/toStandby'
import { updateTeaser } from './methods/updateTeaser'
import { getRealInsure } from './methods/get/getRealInsure'
import { getTotalInsure } from './methods/get/getTotalInsure'
import { getInsures } from './methods/get/getInsures'
import { getRbWithSeptemberRule, getRb2WithSeptemberRule, getRb3WithSeptemberRule } from './methods/get/getRbWithSeptemberRule'
import { getEbWithSeptemberRule } from './methods/get/getEbWithSeptemberRule'
import { getTotalDebit } from './methods/get/getTotalDebit'
import { getGuaranteesCover } from './methods/get/getGuaranteesCover'
import { getAllGuaranteesCover } from './methods/get/getAllGuaranteesCover'
import { algorithmCredit } from './methods/algorithmCredit'
import { getFinancing } from './methods/get/getFinancing'
import { isRelatedToUid } from './methods/is/isRelatedToUid'
import { isOriginatedFromUid } from './methods/is/isOriginatedFromUid'
import { isMinoritárioTech } from './methods/is/isMinoritárioTech'
import { getTagsSectorMatch } from './methods/get/getTagsSectorMatch'
import { attachTeaser } from './methods/attachTeaser'
import { getAuthorship } from './methods/get/getAuthorship'
import { getCnaeFromCnpj } from './methods/get/getCnaeFromCnpj'
import { removeAttachedTeaser } from './methods/removeAttachedTeaser'
import { updateAttachedTeaser } from './methods/updateAttachedTeaser'
import { getAllGuaranteesInArray } from './methods/get/getAllGuaranteesInArray'
import { mountCardOriginacao } from './methods/mountCardOriginacao'
import { relationSubStatusOfActiveCostumer } from './methods/relation/relationSubStatusOfActiveCostumer'
import { inStep } from './methods/inStep'
import { _addYearDate } from './methods/_addYearDate'
import { isPublicado } from './methods/isPublicado'
import { isNotPublicado } from './methods/isNotPublicado'
import { isOnline } from './methods/isOnline'
import { isOffline } from './methods/isOffline'
import { isCurado } from './methods/isCurado'
import { isTech } from './methods/isTech'
import { inComite } from './methods/inComite'
import { inComercial } from './methods/inComercial'
import { inStandby } from './methods/inStandby'
import { inAtivo } from './methods/inAtivo'
import { inAtivoWithoutMatchmaking } from './methods/inAtivoWithoutMatchmaking'
import { inAtivoWitchMatchmaking } from './methods/inAtivoWitchMatchmaking'
import { inAtivoWithoutMatch } from './methods/inAtivoWithoutMatch'
import { inAtivoWithPreparation } from './methods/inAtivoWithPreparation'
import { inAtivoWithNegotiation } from './methods/inAtivoWithNegotiation'
import { inAtivoWithMatch } from './methods/inAtivoWithMatch'
import { inAtivoWithCompletedTransaction } from './methods/inAtivoWithCompletedTransaction'
import { inRequestedChanges } from './methods/inRequestedChanges'
import { inChurn } from './methods/inChurn'
import { inConcluido } from './methods/inConcluido'
import { getDateCreateFormatted } from './methods/getDateCreateFormatted'
import { getIdOwner } from './methods/getIdOwner'
import { getDateContractEnd } from './methods/getDateContractEnd'
import { getRequestedChangedDate } from './methods/getRequestedChangedDate'
import { getStatusCustomerLabel } from './methods/getStatusCustomerLabel'
import { getLastDate } from './methods/getLastDate'
import { getValidateDue } from './methods/getValidateDue'
import { getStatusCustomer } from './methods/getStatusCustomer'
import { isMA } from './methods/isMA'
import { isCredit } from './methods/isCredit'
import { update } from './methods/update'
import { setHub } from './methods/setHub'
import { addUser } from './methods/addUser'
import { adjust } from './methods/adjust'
import { getUidAuth } from './methods/getUidAuth'
import { getUidCoAuth } from './methods/getUidCoAuth'

import { modelTeaser } from './model/modelTeaser'
import { getLabelStatus } from './methods/get/getLabelStatus'
import { getStatus } from './methods/get/getStatus'
import { getAuthor } from './methods/get/getAuthor'
import { sortByName } from './methods/sortByName'
import { sortByOriginator } from './methods/sortByOriginator'
import { sortByStatus } from './methods/sortByStatus'
import { sortByOffice } from './methods/sortByOffice'
import { sortByPrazo } from './methods/sortByPrazo'
import { sortByContrato2 } from './methods/sortByContrato'

import { getGovernanceScore } from './methods/getGovernanceScore'
import { sortByAnalyst } from './methods/sortByAnalyst'
import { getAverageOriginationsPerPeriod } from './methods/get/getAverageOriginationsPerPeriod'
import { getContractSigningBonus } from './methods/get/getContractSigningBonus'
import { getCommissionPerActiveCustomer } from './methods/get/getCommissionPerActiveCustomer'
import { getSuccessCommissionEstimate } from './methods/getSuccessCommissionEstimate'

import { churnTeaser } from './methods/churnTeaser'
import { filterInApprovedInDiscovery } from './methods/filterInApprovedInDiscovery'
import { createTagsRoot } from './methods/createTagsRoot'
import { getOnlyActiveTags } from './methods/get/getOnlyActiveTags'
import { scoreStark } from './methods/scoreStark'

import { getTeasersOriginatorDetails } from './methods/getTeasersOriginatorDetails'

import { getAnalystData } from './methods/getAnalystData'

import { translateOldToNew } from './methods/translate/oldToNew'
import { replaceUidToStarkIB } from './methods/replaceUidToStarkIB'
import { getStatusLabelAndIcon } from './methods/getStatusLabelAndIcon'
import { getValidityLabel } from './methods/getValidityLabel'

import { scoreStarkGeneral } from './methods/scoreStarkGeneral/scoreStarkGeneral'
import { adjustDesiredValue } from './methods/adjustDesiredValue'

export type TRequestedChangeDate = { formated: string, value: string | number }
export type TStatusCustomer = { label: string, value: string } | undefined

export const fnTeaser = {
	modelTeaser,
	adjustDesiredValue,
	isMinoritárioTech,
	isPublicado,
	isNotPublicado,
	isOnline,
	isOffline,
	isCurado,
	isTech,
	isMA,
	isCredit,

	inComite,
	inComercial,
	inStandby,
	inAtivo,
	inAtivoWithoutMatchmaking,
	inAtivoWitchMatchmaking,
	inAtivoWithPreparation,
	inAtivoWithNegotiation,
	inAtivoWithMatch,
	inAtivoWithoutMatch,
	inAtivoWithCompletedTransaction,
	inRequestedChanges,
	inChurn,

	addUser,

	getDateCreateFormatted,
	getIdOwner,
	getDateContractEnd,
	getFirstRb,

	getUidAuth,
	getUidCoAuth,

	update,
	setHub,

	getStatusCustomerLabel,
	getStatusCustomer,
	getRequestedChangedDate,

	cnpjToCnaes,
	cnaesToTags,
	cnaesFactored,
	// score,
	// scoreMaj,
	// scoreMin,
	// scoreTotal,
	// scoreMA,
	// scoreCredit,
	// scoreCreditPF,
	getTags,
	getOnlyActiveTags,

	validation,
	adjust,

	getRealInsure,
	getTotalInsure,

	getCnaeFromCnpj,
	getInsures,

	getEbWithSeptemberRule,
	getRbWithSeptemberRule,
	getRb2WithSeptemberRule,
	getRb3WithSeptemberRule,
	createTeaser,
	getBda,
	assinarContrato,
	curar,
	toComercial,
	toggleLine,
	toStandby,
	updateTeaser,
	getLastDate,
	getValidateDue,
	getTotalDebit,
	getGuaranteesCover,
	algorithmCredit,
	getFinancing,
	isRelatedToUid,
	isOriginatedFromUid,
	mountCardOriginacao,

	getTagsSectorMatch,
	getAuthorship,
	attachTeaser,
	removeAttachedTeaser,
	updateAttachedTeaser,
	getAllGuaranteesCover,
	getAllGuaranteesInArray,
	getLabelStatus,
	inStep,

	// relations:
	relationSubStatusOfActiveCostumer,
	getStatus,
	getAuthor,
	getGovernanceScore,

	churnTeaser,
	//Sorts
	sortByName,
	sortByOriginator,
	sortByStatus,
	sortByOffice,
	sortByPrazo,
	// sortByContrato,
	sortByContrato2,
	sortByAnalyst,
	getAverageOriginationsPerPeriod,
	getContractSigningBonus,
	getCommissionPerActiveCustomer,
	getSuccessCommissionEstimate,
	inConcluido,
	filterInApprovedInDiscovery,
	createTagsRoot,

	scoreStark,
	scoreStarkGeneral,

	getTeasersOriginatorDetails,
	getAnalystData,
	translateOldToNew,
	replaceUidToStarkIB,
	getStatusLabelAndIcon,
	getValidityLabel,
}
