import { IUi } from '../../IUi'
import { AvatarTheme } from './avatar.theme'
import { ButtonTheme } from './buttons.theme'
import { CardTheme } from './card.theme'
import { CheckboxTheme } from './checkbox.theme'
import { CheckboxGroupTheme } from './checkboxgroup.theme'
import { ProgressTheme } from './progress.theme'
import { RadioTheme } from './radio.theme'
import { RadioGroupTheme } from './radiogroup.theme'
import { SearchTheme } from './search.theme'
import { TextareaTheme } from './textarea.theme'
import { TextfieldTheme } from './textfield.theme'

export const ui: IUi = {
	button: ButtonTheme,
	progress: ProgressTheme,
	card: CardTheme,
	avatar: AvatarTheme,
	search: SearchTheme,
	textfield: TextfieldTheme,
	textarea: TextareaTheme,
	checkbox: CheckboxTheme,
	radio: RadioTheme,
	radioGroup: RadioGroupTheme,
	checkboxGroup: CheckboxGroupTheme
}
