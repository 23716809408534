import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { arraySubtraction } from 'Functions/Utils/arraySubtraction'

export const getOnlyActiveTags = (teaser: TypeTeaser | null | undefined) => {
	if (!teaser) {
		return [] // Return an empty array if teaser is null or undefined
	}

	return arraySubtraction(teaser.tags || [], teaser.tagsNegative || [])
}
