export const STATUS_MATCH = {
	excluido: { value: 0, label: 'Excluído' },
	salvo: { value: 5, label: 'Salvo' },
	preMatch: { value: 10, label: 'Pré Match' },
	matchMaking: { value: 20, label: 'Matchmaking' },
	match: { value: 30, label: 'Match' },
	completed: { value: 40, label: 'Transação Concluída' },
} as const

export const STATUS_MATCH_GET = (code: string) => {
	const _code = code as keyof typeof STATUS_MATCH
	if (!STATUS_MATCH[_code]) {
		return (
			{
				value: -1,
				label: 'Não mapeado'
			}
		)
	}

	return STATUS_MATCH[_code]
}
export const STATUS_MATCH_VALUES = Object.values(STATUS_MATCH)
export const STATUS_MATCH_KEYS = Object.keys(STATUS_MATCH)


export const TITLE_LONG_LIST = 'Long List'

