/**
 * Esta função adiciona um ID de usuário ao array users em um documento teaser em um
 * banco de dados.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é um objeto do tipo
 * `TypeTeaser`, que provavelmente contém informações sobre um teaser como o seu
 * título, descrição e usuários associados a ele.
 * @param {string} id - O parâmetro `id` é uma string que representa o
 * identificador de um teaser.
 * @param {string} uid - O parâmetro `uid` é uma string que representa o único
 * identificador de um usuário.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeTeaser } from '../types/teaser'

export const addUser = async (teaser: TypeTeaser, id: string, uid: string) => {

	const cloneTeaser = clone(teaser)
	const cloneId = clone(id)
	const cloneUid = clone(uid)

	await db5.teasers.update({
		id: cloneId,
		doc: {
			users: [...cloneTeaser.users, cloneUid]
		}
	})
}
