import { clone } from '../../../Functions/Utils/clone'
import { minhaData } from '../../../Functions/Utils/minhaData'
import { notifications } from '../../../Services/cloudfunctions'
import { db } from '../../../Services/db'
import { TypeTeaser } from '../../../Functions/Teaser/types/teaser'
import { TypeUser } from '../../../Functions/User/types/TypeUser'
import { db5 } from '../../../Services/db5'

type TProps = {
	id: string;
	notification: {
		origination: TypeTeaser
		originationId: string;
		profile: TypeUser
		uid: string
	}
}

/**
 *
 * @param id: string
 * @param notification:  {
		origination: TypeTeaser,
		originationId: string,
		profile: TypeUser,
		uid: string
	}
 */
// export const toStandby = async ({ id, notification }: { id?: string; notification: TypeNotify }) => {
export const toStandby = async (props: TProps) => {

	const cloneProps = clone(props)

	await db5.teasers.update({ id: cloneProps.id, doc: { statusCustomer: 'standby', dataStandby: minhaData() } }).then(async () => {
		await notifications({
			targetId: cloneProps.id,
			typeOrMessage: 'a0004',
			profileUid: cloneProps.notification.uid,
		})

	})
}
