import { InternalColProps } from './col'
interface IProps {
	props: InternalColProps,
	negative?: boolean,
	type: string,
	gutterDirection?: string;
}

const SIDES = {
	'both': 'both',
	'horizontal': 'horizontal',
	'vertical': 'vertical'
}

function getProperties(gutter: number, type: string, negative: boolean | undefined) {
	const _gutter = gutter
	return `
        ${getHorizontalProperties(_gutter, type, negative)}
        ${getVerticalProperties(_gutter, type, negative)}
    `
}

function getHorizontalProperties(gutter: number, type: string, negative: boolean | undefined) {
	const _gutter = gutter / 2
	return `
        ${type}-left: ${negative ? '-' : ''}${_gutter}px;
        ${type}-right: ${negative ? '-' : ''}${_gutter}px;
    `
}

function getVerticalProperties(gutter: number, type: string, negative: boolean | undefined) {
	const _gutter = gutter / 2
	return `
        ${type}-top: ${negative ? '-' : ''}${_gutter}px;
        ${type}-bottom: ${negative ? '-' : ''}${_gutter}px;
    `
}

function getGutterProperties(args: IProps) {
	const { props: { gutter, theme, gutterDirection }, type, negative } = args

	const _gutterDirection: string = gutterDirection || 'both'

	if (typeof gutter === 'number') {

		if (_gutterDirection === SIDES.both) return getProperties(gutter, type, negative)
		if (_gutterDirection === SIDES.horizontal) return getHorizontalProperties(gutter, type, negative)
		if (_gutterDirection === SIDES.vertical) return getVerticalProperties(gutter, type, negative)

	}

	if (typeof gutter === 'object' && theme.breakpoints && theme.breakpoints.queries) {
		let _query = ''
		for (const key in gutter) {
			if (Object.prototype.hasOwnProperty.call(gutter, key) && typeof gutter[key] === 'number') {
				const element: number | undefined = gutter[key]
				if (element) {
					//@ts-ignore
					if (_gutterDirection === SIDES.both) _query += `${theme?.breakpoints?.queries?.[key]} {${getProperties(element, type, negative)}};`
					//@ts-ignore
					else if (_gutterDirection === SIDES.horizontal) _query += `${theme.breakpoints.queries[key]} {${getHorizontalProperties(element, type, negative)}};`
					//@ts-ignore
					else if (_gutterDirection === SIDES.vertical) _query += `${theme.breakpoints.queries[key]} {${getVerticalProperties(element, type, negative)}};`

				}
			}
		}

		return _query
	}
	return ''
}

/** @deprecated */
export function getGutter(args: IProps) {
	const { props: { gutter } } = args

	if (gutter) {
		return getGutterProperties(args)
	}
	return ''
}
