import { TypeTeasers } from '../types/teasers'
import { inComiteApproved } from './inComiteApproved'

/**
 * Filtra os teasers aprovados em comitê de uma coleção de teasers.
 *
 * @param {TypeTeasers} teasers - Um objeto contendo uma coleção de teasers.
 * @returns {TypeTeasers} teasers - Uma coleção de teasers que foram aprovados em comitê.
 * @example
 *
 * const teasers = {
 *   teaser1: {
 * 	dataPublicacao : 20210901000000,
 * 	statusCustomer: ''
 * 	validation.approved: true
 *  },
 *  teaser2: {
 * 	dataPublicacao : 20210901000000,
 * 	statusCustomer: ''
 * 	validation.approved: false
 *  },
 * teaser3: {
 * 	dataPublicacao : 20210901000000,
 * 	statusCustomer: 20210901000000
 * 	validation.approved: true
 *  },
 * };
 *
 * const teasersAprovadosEmComite = filterInApprovedInComite(teasers);
 * console.log(teasersAprovadosEmComite);
 * / Saída: { teaser1: { approvedInComite: true }, teaser3: { approvedInComite: true } }
 */

export const filterInApprovedInDiscovery = (teasers: TypeTeasers) => Object.values(teasers).filter(teaser => inComiteApproved(teaser))

