import { TypeInstitutions } from 'Functions/Institution/types/institutions'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeGetBlindInstitutions = {
	profile?: { tipo: string }
}

type GetBlindInstitutionsResponse = {
	status: number,
	version: number,
	data: TypeInstitutions
}

export const _getBlindInstitutions = (input: TypeGetBlindInstitutions): Promise<{ data: GetBlindInstitutionsResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'getBlindInstitutions', input })

