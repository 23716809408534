import { DocumentData, Query } from '@firebase/firestore-types'
import { fnUser } from 'Functions/User'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { TypeCollections } from './collections'

// QUANDO CRIAR UMA NOVA COLLECTION, ADICIONAR AQUI WHERES PADRÃO PARA CADA PERFIL DE USUÁRIO
export const generateWheres = (ref: Query<DocumentData>, collection: TypeCollections, profile: TypeUser): Query<DocumentData> => {
	const wheres = {
		teses: () => ref,
		tesesCredit: () => ref,
		teasers: () => {
			if (fnUser.isAdm(profile))
				return ref
			if (fnUser.isBda(profile))
				return ref.where('uid', '==', profile._id)
			if (fnUser.isMicroBda(profile))
				return ref.where('uidCoCreator', '==', profile._id)
			if (fnUser.isEnterprise(profile))
				return ref.where('uid', '==', profile._id)
			if (fnUser.isOffice(profile))
				return ref.where('fromOffice', '==', profile._id)
			if (fnUser.isOfficeAnalyst(profile))
				return ref.where('fromOffice', '==', profile.fromOffice)
			return ref
		},
		institutions: () => ref,
		matches: () => {
			if (fnUser.isAdm(profile))
				return ref
			if (fnUser.isBda(profile))
				return ref.where('uidTeaser', '==', profile._id)
			if (fnUser.isMicroBda(profile))
				return ref.where('uidCoCreatorTeaser', '==', profile._id)
			if (fnUser.isEnterprise(profile))
				return ref.where('uidTeaser', '==', profile._id)
			if (fnUser.isOffice(profile))
				return ref.where('fromOffice', '==', profile._id)
			if (fnUser.isOfficeAnalyst(profile))
				return ref.where('fromOffice', '==', profile.fromOffice)
			// if (fnUser.isCustomer(profile))
			// 	return ref.where('users', 'array-contains', profile._id)
			return ref
		},
		users: () => {
			if (fnUser.isEnterprise(profile))
				return ref.where('uidBdaFather', '==', profile._id)
			if (fnUser.isOffice(profile))
				return ref.where('fromOffice', '==', profile._id)
			if (fnUser.isOfficeAnalyst(profile))
				return ref.where('fromOffice', '==', profile.fromOffice)
			return ref
		},
		walletLines: () => {
			if (fnUser.isAgent(profile) || fnUser.isEnterprise(profile))
				return ref.where('uid', '==', profile._id)
			return ref
		},
		notifications: () => {
			// O usuários do tipo office e officeAnalyst tem a mesma regra do tipo ADM
			// pois não precisam estar incluidos na propriedade "uids" da notificação
			// para vê-las
			if (fnUser.isAdm(profile) || fnUser.isOffice(profile) || fnUser.isOfficeAnalyst(profile))
				return ref

			// os usuários do tipo Customer precisam estar incluidos no "uids" e "showCustomer"
			// precisar ser verdadeiro
			if (fnUser.isCustomer(profile))
				return ref
					.where('uids', 'array-contains', profile._id)
					.where('showCustomer', '==', true)

			// Caso contrário, qualquer outro usuário só pode ver a notificação se
			// ele estiver na propriedade "uids"
			return ref.where('uids', 'array-contains', profile._id)
		},
		tools: () => ref,
		transactions: () => ref,
		transactionsCompanies: () => ref,
		transactionsInstitutions: () => ref,
		valuations: () => ref,
		followup: () => ref,
		darwinFiles: () => ref,
		sinatraTransactions: () => ref,
		sinatraValuations: () => ref,
		ranking: () => ref,
		featuredMaterials: () => ref,
		calendars: () => ref,
		_viewTeasers001: () => ref,
		tour: () => {
			if (fnUser.isAgent(profile))
				return ref.where('_id', '==', profile._id)
			return ref
		},
		profilePosts: () => ref,
		valuationsOld: () => ref,
		templateEmailMatchmakings: () => ref
	}

	return wheres?.[collection]() || ref
}
