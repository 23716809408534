import { ebNames } from '../../../Screens/AddOriginacao/Components/FinancialInformation/controller'
import { COMPANY_RATING } from '../../../Constants/COMPANY_RATING'
import { TEASER_FIELDS } from '../../../Constants/TEASER_FIELDS'
import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { validateURL } from '../../Utils/validateURL'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { fnOrigination } from '..'

type PropsFields = {
	[key: string]: boolean
}

type Props = {
	errors: TypeError
	fields: PropsFields
}

export type TError = {
	message: string
	// caso queira colocar um elemento diferente do item,
	// podemos colocar um id com o field e ele será usado
	// no caso de haver erro para deslizar a página até o campo
	field?: string
}

export type TypeError = {
	[key: string]: TError
}

const ZERO_VALUE = ['0.00', '0,00']

export const validateFields = (origination: TypeTeaser) => ({
	errors: { ...validateGeneral(origination).errors, ...validateMeA(origination).errors, ...validateCredit(origination).errors },
	fields: { ...validateGeneral(origination).fields, ...validateMeA(origination).fields, ...validateCredit(origination).fields },
})

export enum ValidateFinancialProps {
	'up' = 'up',
	'down' = 'down'
}
export const validateFinancial = (type: ValidateFinancialProps) => {
	const today = new Date()
	const month = today.getMonth() + 1
	const MAX_MONTH = 9

	return type === 'up' ? month >= MAX_MONTH : month < MAX_MONTH
}

export const validateGeneral = (origination: TypeTeaser): Props => {
	const errors: TypeError = {}
	const { typeOperation,
		reasonDeal,
		description,
		eb0,
		eb1,
		rb0,
		rb1,
		eb2,
		rb2,
		site,
		emprestimos,
		fornecedores,
		tributos,
		outrasdividas,
		indebtedness,
		governanceAuditedBalance,
		governanceRevenueOutside,
	} = origination

	// caso surja um novo campo ou mude a regra de validação é necessário acrescentar o campo aqui
	const fields: PropsFields = {
		description: true,
		reasonDeal: true,
		typeOperation: true,
		rb1: true,
		eb1: true
	}

	if (!description) {
		errors[TEASER_FIELDS.description] = { message: 'Descreva a empresa' }
	}

	if (!reasonDeal) {
		errors[TEASER_FIELDS.reasonDeal] = { message: 'Descreva o motivo da captação' }
	}

	if (!governanceAuditedBalance) {
		errors[TEASER_FIELDS.governanceAuditedBalance] = { message: 'Informe se o balanço é auditado' }
	}
	if (!governanceRevenueOutside) {
		errors[TEASER_FIELDS.governanceRevenueOutside] = { message: 'Informe se a empresa possui informalidade' }
	}

	// é obrigatório selecionar um tipo de operação
	if (!typeOperation) {
		errors[TEASER_FIELDS.typeOperation] = { message: 'Selecione um tipo de operação (M&A, Crédito ou M&A e Crédito)' }
	}
	const currentYear = ebNames[0].label.replace(' (valor esperado)', '')
	const yearBeforeLast = ebNames[2].label
	// Se o mês for maior que 9, consideramos eb1/ eb0/ rb1/ rb0 como dois anos obrigatórios
	// se o mês for menor que 9, consideramos eb1/ eb2/ rb1/ rb2 como dois anos obrigatórios
	if (validateFinancial(ValidateFinancialProps.up) && !eb0) errors[TEASER_FIELDS.eb0] = { message: `O valor esperado do EBITDA de ${currentYear} não pode ser vazio` }
	if (validateFinancial(ValidateFinancialProps.up) && !rb0) errors[TEASER_FIELDS.rb0] = { message: `O valor esperado do Faturamento Bruto de ${currentYear} não pode ser vazio` }
	if (validateFinancial(ValidateFinancialProps.down) && !eb2) errors[TEASER_FIELDS.eb2] = { message: `O EBITDA de ${yearBeforeLast} não pode ser vazio` }
	if (validateFinancial(ValidateFinancialProps.down) && !rb2) errors[TEASER_FIELDS.rb2] = { message: `O Faturamento Bruto de ${yearBeforeLast} não pode ser vazio` }

	fields.eb0 = true
	fields.rb0 = true
	fields.eb1 = true
	fields.rb1 = true
	fields.eb2 = true
	fields.rb2 = true
	fields.eb3 = true
	fields.rb3 = true

	// o faturamento bruto do ano anterior não pode ser vazio
	if (!rb1) {
		errors[TEASER_FIELDS.rb1] = { message: 'O Faturamento Bruto de 2021 não pode ser vazio' }
	}

	// o faturamento bruto do ano anterior não pode ser vazio
	if (!eb1) {
		errors[TEASER_FIELDS.eb1] = { message: 'O EBITDA de 2021 não pode ser vazio' }
	}

	if (indebtedness && !emprestimos) {
		errors[TEASER_FIELDS.emprestimos] = { message: 'O Endividamento bancário não pode ser vazio' }
	}
	if (indebtedness && !tributos) {
		errors[TEASER_FIELDS.tributos] = { message: 'O Endividamento tributos não pode ser vazio' }
	}
	if (indebtedness && !fornecedores && origination.typeOperation !== TYPE_OPERATION.op2) {
		errors[TEASER_FIELDS.fornecedores] = { message: 'O Endividamento fornecedores não pode ser vazio' }
	}
	if (indebtedness && !outrasdividas && origination.typeOperation !== TYPE_OPERATION.op2) {
		errors[TEASER_FIELDS.outrasdividas] = { message: 'O Endividamento outros não pode ser vazio' }
	}

	if (indebtedness) {
		fields.emprestimos = true
		fields.tributos = true
	}

	if (indebtedness && origination.typeOperation !== TYPE_OPERATION.op2) {
		fields.fornecedores = true
		fields.outrasdividas = true
	}

	const checkSite = !!validateURL(site)
	if (site && !checkSite) {
		errors[TEASER_FIELDS.site] = { message: 'O site precisa ser válido' }
	}

	checkTags({ origination, errors, fields })

	return { errors, fields }
}

export const validateMeA = (origination: TypeTeaser): Props => {
	if (!origination.typeOperation) return { errors: {}, fields: {} }
	if ((origination.typeOperation === TYPE_OPERATION.op2)) return { errors: {}, fields: {} }

	const { etr, companyRating } = origination
	const errors: TypeError = {}
	// caso surja um novo campo ou mude a regra de validação é necessário acrescentar o campo aqui
	const fields: PropsFields = {
		companyRating: true,
		etr: true
	}

	// é obrigatório classificar a empresa
	if (!companyRating) {
		errors[TEASER_FIELDS.companyRating] = { message: 'Qual a natureza da atividade que a empresa realiza? Real ou Tech?' }
	}

	// É obrigatório escolher ao menos um tipo de Operações Societárias
	if (!etr['Aquisição Majoritária'] &&
		!etr['Aquisição Minoritária'] &&
		!etr['Aquisição Total']) {
		errors[TEASER_FIELDS.etr] = { message: 'Escolha ao menos um tipo de Operações Societárias (Total, Majoritária ou Minoritária)' }
	}

	return { errors, fields }
}

function checkTags(args: { origination: TypeTeaser, errors: TypeError, fields: PropsFields }) {
	const { origination, errors, fields } = args
	const { companyRating, tagsNegative, tags, tagNotFound, tagTech } = origination
	// se a empresa for do tipo Tech, deve-se ao menos escolher uma tag para representar a empresa
	const isTech = companyRating === COMPANY_RATING.GET('Tech')._id
	if (isTech && !tagTech.length && !tagNotFound) {
		errors[TEASER_FIELDS.tagTech] = { message: 'Selecione ao menos uma tag Tech ou clique em "Não achei minha tag"' }
	}

	if (isTech && !tagNotFound) {
		fields.tagTech = true
	}

	// se a empresa for do tipo real, deve-se ao menos escolher uma tag para representar a empresa
	const isReal = companyRating === COMPANY_RATING.GET('Real')._id
	const checktags = tagsNegative.length === tags.length
	if (isReal && checktags && !tagNotFound) {
		errors[TEASER_FIELDS.tagsNegative] = { message: 'Selecione ao menos uma tag Real ou clique em "Não achei minha tag"' }
	}

	if (isReal && !tagNotFound) {
		fields.tagsNegative = true
	}

	if (tagNotFound) {
		fields.tagNotFound = true
	}
}

export const validateCredit = (origination: TypeTeaser): Props => {
	// se o tipo de operação for diferente de Crédito
	if (!origination.typeOperation) return { errors: {}, fields: {} }
	if (origination.typeOperation === TYPE_OPERATION.op1) return { errors: {}, fields: {} }

	// caso surja um novo campo ou mude a regra de validação é necessário acrescentar o campo aqui
	const fields: PropsFields = {
		creditOperations: true,
		desiredValue: true
	}

	const { hasFinanciamentoObras, guarantees, hasFinanciamentoObrasDoZero, desiredValue, hasCreditoOperacoesEstruturadas } = origination
	let errors: TypeError = {}

	if (!hasFinanciamentoObras && !hasFinanciamentoObrasDoZero && !hasCreditoOperacoesEstruturadas) {
		errors[TEASER_FIELDS.creditOperations] = { field: 'creditOperations', message: 'Escolha ao menos uma operação de crédito' }
	}

	// não pode publicar sem valor da captação
	if (!desiredValue || ZERO_VALUE.includes(desiredValue)) {
		errors[TEASER_FIELDS.desiredValue] = { field: 'valorDesejado', message: 'O Valor desejado na captação não pode ser vazio' }
	}

	// caso hasCreditoOperacoesEstruturadas esteja marcado, é obrigatório adicionar ao menos uma garantia
	if (hasCreditoOperacoesEstruturadas || (guarantees && Object.keys(guarantees).length)) {
		const checkGuarantees = fnOrigination.validateGuarantees(origination)
		if (checkGuarantees) {
			errors = { ...errors, ...checkGuarantees }
		}
		fields.noGuarantees = true
		fields.guarantees = true
	}

	// caso hasCreditoOperacoesEstruturadas esteja marcado, é obrigatório adicionar ao menos uma garantia
	if (hasFinanciamentoObras || hasFinanciamentoObrasDoZero) {
		const checkFinancing = fnOrigination.validateFinancing(origination)
		if (checkFinancing) {
			errors = { ...errors, ...checkFinancing }
		}
		if (hasFinanciamentoObras) fields.financingObras = true
		if (hasFinanciamentoObrasDoZero) fields.financingProjetosZero = true
	}

	return { errors, fields }
}
