/**
 * A função `assinarContrato` atualiza um teaser com informações do contrato e
 * envia uma notificação.
 * @param - A função `assinarContrato` recebe quatro parâmetros:
 */
//CRIADO POR IA

import { db5 } from '../../../Services/db5'
import { TypeUser } from '../../User/types/TypeUser'
import { TypeTeaser } from '../types/teaser'
import { clone } from '../../../Functions/Utils/clone'
import { notifications } from '../../../Services/cloudfunctions'

export const assinarContrato = async ({
	teaser,
	contract,
	dataContrato,
	profile
}:
	{
		teaser: TypeTeaser,
		contract: TypeTeaser['contract'],
		dataContrato: number,
		profile: TypeUser
	}
) => {

	const cloneTeaser = clone(teaser)
	const cloneContract = clone(contract)
	const cloneDataContrato = clone(dataContrato)
	const cloneProfile = clone(profile)

	const id = cloneTeaser._id

	await db5.teasers.update({
		id,
		doc: {
			dataContrato: cloneDataContrato,
			statusCustomer: 'ativo',
			linkContrato: cloneContract.file.url,
			tipoContrato: cloneContract.type, // ! deprecated
			valorContrato: cloneContract.monthlyPayment, // ! deprecated
			contract: cloneContract
		}
	})

	await notifications({
		targetId: cloneTeaser._id,
		typeOrMessage: 'a0005',
		profileUid: cloneProfile._id,
	})

}
