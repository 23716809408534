import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Back = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M2.875 15.9997C2.875 15.494 3.28497 15.084 3.7907 15.084H28.2093C28.715 15.084 29.125 15.494 29.125 15.9997C29.125 16.5054 28.715 16.9154 28.2093 16.9154H3.7907C3.28497 16.9154 2.875 16.5054 2.875 15.9997Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M2 15.9997C2 15.0107 2.80172 14.209 3.7907 14.209H28.2093C29.1983 14.209 30 15.0107 30 15.9997C30 16.9887 29.1983 17.7904 28.2093 17.7904H3.7907C2.80172 17.7904 2 16.9887 2 15.9997ZM3.7907 15.959C3.76822 15.959 3.75 15.9772 3.75 15.9997C3.75 16.0222 3.76822 16.0404 3.7907 16.0404H28.2093C28.2318 16.0404 28.25 16.0222 28.25 15.9997C28.25 15.9772 28.2318 15.959 28.2093 15.959H3.7907Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M16.6475 3.1432C17.0051 3.5008 17.0051 4.08059 16.6475 4.43819L5.08569 16L16.6475 27.5618C17.0051 27.9194 17.0051 28.4992 16.6475 28.8568C16.2899 29.2144 15.7101 29.2144 15.3525 28.8568L3.1432 16.6475C2.7856 16.2899 2.7856 15.7101 3.1432 15.3525L15.3525 3.1432C15.7101 2.7856 16.2899 2.7856 16.6475 3.1432Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M14.7338 2.52448C15.4331 1.82517 16.5669 1.82517 17.2662 2.52448C17.9655 3.22379 17.9655 4.3576 17.2662 5.05691L6.32313 16L17.2662 26.9431C17.9655 27.6424 17.9655 28.7762 17.2662 29.4755C16.5669 30.1748 15.4331 30.1748 14.7338 29.4755L2.52448 17.2662C1.82517 16.5669 1.82517 15.4331 2.52448 14.7338L14.7338 2.52448ZM16.0288 3.76192C16.0129 3.74603 15.9871 3.74603 15.9712 3.76192L3.76192 15.9712C3.74603 15.9871 3.74603 16.0129 3.76192 16.0288L15.9712 28.2381C15.9871 28.254 16.0129 28.254 16.0288 28.2381C16.0447 28.2222 16.0447 28.1964 16.0288 28.1805L4.46697 16.6187C4.30288 16.4546 4.21069 16.2321 4.21069 16C4.21069 15.7679 4.30288 15.5454 4.46697 15.3813L16.0288 3.81947C16.0447 3.80358 16.0447 3.77781 16.0288 3.76192Z' fill='#272827' />
	</Svg>
)
