import { TYPE_USER_LABEL } from '../../../Constants/TYPE_USER'
import { getLabelTypeUser } from './getLabelTypeUser'
import { TypeUser } from '../types/TypeUser'
import { isOffice } from './isOffice'
import { isEnterprise } from './isEnterprise'
import { getUidCoAuth } from './getUidCoAuth'
import { TypeUserGenerics } from '../types/userGenerics'

const labelAgent = {
	[TYPE_USER_LABEL.adm]: {
		color: 'yellow-400',
		label: TYPE_USER_LABEL.adm,
	},
	[TYPE_USER_LABEL.bda]: {
		color: 'blue-500',
		label: TYPE_USER_LABEL.bda,
	},
	[TYPE_USER_LABEL.customer]: {
		color: 'blue-500',
		label: TYPE_USER_LABEL.customer,
	},
	[TYPE_USER_LABEL.enterprise]: {
		color: 'green-400',
		label: TYPE_USER_LABEL.enterprise,
	},
	[TYPE_USER_LABEL.microBda]: {
		color: 'green-400',
		label: TYPE_USER_LABEL.microBda,
	},
	[TYPE_USER_LABEL.hub]: {
		color: 'orange-400',
		label: TYPE_USER_LABEL.hub,
	},
	[TYPE_USER_LABEL.office]: {
		color: 'orange-400',
		label: TYPE_USER_LABEL.office,
	},
	[TYPE_USER_LABEL.officeAnalyst]: {
		color: 'orange-400',
		label: TYPE_USER_LABEL.officeAnalyst,
	},
}

export const getLabelAgent = ({ user, users }: { user: TypeUser, users: TypeUserGenerics }) => {
	const type = labelAgent[getLabelTypeUser(user) || TYPE_USER_LABEL.bda]
	const haveSecondName = !isOffice(user) && !isEnterprise(user)
	const coAuth = users[getUidCoAuth(user)]?.nome || 'Stark'
	const label = haveSecondName ? `${type.label} | ${coAuth}` : type.label
	return { label, coAuth, type: type.label, color: type.color }
}
