import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PersonTalkingFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M12.7628 9.80047C13.9077 9.80047 15.0058 10.2441 15.8154 11.0337C16.6249 11.8233 17.0798 12.8943 17.0798 14.011C17.0798 15.1277 16.6249 16.1987 15.8154 16.9883C15.0058 17.7779 13.9077 18.2215 12.7628 18.2215C11.6179 18.2215 10.5199 17.7779 9.71027 16.9883C8.90068 16.1987 8.44586 15.1277 8.44586 14.011C8.44586 12.8943 8.90068 11.8233 9.71027 11.0337C10.5199 10.2441 11.6179 9.80047 12.7628 9.80047ZM12.7628 20.3268C15.6444 20.3268 21.3967 21.7373 21.3967 24.5373V26.6426H4.12891V24.5373C4.12891 21.7373 9.88125 20.3268 12.7628 20.3268ZM21.1377 10.1794C23.3178 12.4952 23.3178 15.7057 21.1377 17.8321L19.3246 16.0531C20.2311 14.811 20.2311 13.2005 19.3246 11.9584L21.1377 10.1794ZM24.71 6.64258C28.9514 10.9057 28.919 17.2847 24.71 21.3794L22.9508 19.6636C25.9403 16.3163 25.9403 11.5373 22.9508 8.35837L24.71 6.64258Z' fill='#272827' />
	</Svg>
)


