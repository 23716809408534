/**
 * A função `inAtivoWithPreparation` verifica se um teaser está com status ativo
 * com um código de substatus específico.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido em algum lugar da sua base de código. Provavelmente contém
 * propriedades e métodos específicos para as necessidades da sua aplicação. No
 * Função `inAtivoWithPreparation`, este parâmetro está sendo passado para o `clone
 * @returns A função `inAtivoWithPreparation` está retornando um valor booleano. Isto
 * verifica se o objeto `teaser` passado como argumento tem um
 * Propriedade `subStatusOfActiveCostumer` que corresponde ao valor de
 * `SUB_STATUS_OF_ACTIVE_COSTUMER.s00200.code` e se a função `inAtivo`
 * retorna verdadeiro quando chamado com o objeto `teaser` clonado.
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'

export const inAtivoWithPreparation = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const subStatusOfActiveCostumerInPreparation: (keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER)[] = [
		SUB_STATUS_OF_ACTIVE_COSTUMER.s00200.code
	]

	return inAtivo(cloneTeaser) && subStatusOfActiveCostumerInPreparation.includes(cloneTeaser.subStatusOfActiveCostumer)


}
