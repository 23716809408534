import { fnTeaser } from '../../Teaser'
import { stringToCnae } from '../../Utils/numberToCnae'
import { TypeTeaser } from '../../Teaser/types/teaser'

export async function getCompanyDetail(cnpj: string) {

	if (cnpj === '00.000.000/0000-00') {
		const data: TypeTeaser = {
			nome: 'Blind',
		} as TypeTeaser
		return data
	}

	const numberCnpj = cnpj.replace(/\D/g, '')
	const res = await fetch(`https://api.cnpja.com.br/companies/${numberCnpj}`, {
		'method': 'GET',
		'headers': {
			'authorization': '690197b9-0483-4557-b140-c45fc08be9e1-b1aca2b6-8f00-4cae-997e-1794dbc64ded'
		}
	})
	const resText = await res.text()
	const resJson = await JSON.parse(resText)

	if (res.status !== 200) {
		return
	}

	const uf = resJson?.address.state
	const { name }: { name: string } = resJson
	const cnaes: string[] = []
	const cnaePrincipal = stringToCnae(resJson?.primary_activity?.code)
	cnaes.push(cnaePrincipal)
	resJson.secondary_activities.forEach((activity: any) => {
		cnaes.push(stringToCnae(activity.code))
	})
	const cnaesTags = fnTeaser.cnaesToTags(cnaes)
	const tags: string[] = [...new Set(['t001', 't061', ...cnaesTags])]
	const tagsNegative: string[] = [...new Set(['t001', 't061', ...cnaesTags])]

	const nameCapitalized = name[0].toUpperCase() + name.substring(1).toLowerCase()

	const data: TypeTeaser = {
		tags,
		cnaes,
		nome: nameCapitalized,
		tagsNegative,
		uf
	} as TypeTeaser
	return data
}
