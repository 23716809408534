import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const SuitCaseOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 28 24'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M3.81818 7.17483C3.12495 7.17483 2.54545 7.69621 2.54545 8.39459V20.4125C2.54545 21.1109 3.12495 21.6323 3.81818 21.6323H24.1818C24.8751 21.6323 25.4545 21.1109 25.4545 20.4125V8.39459C25.4545 7.69621 24.8751 7.17483 24.1818 7.17483H3.81818ZM0 8.39459C0 6.42232 1.68313 4.80717 3.81818 4.80717H24.1818C26.3169 4.80717 28 6.42232 28 8.39459V20.4125C28 22.3848 26.3169 24 24.1818 24H3.81818C1.68313 24 0 22.3848 0 20.4125V8.39459Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M7.63574 3.58742C7.63574 1.61515 9.31887 0 11.4539 0H16.5448C18.6799 0 20.363 1.61515 20.363 3.58742V22.8161C20.363 23.47 19.7932 24 19.0903 24C18.3874 24 17.8176 23.47 17.8176 22.8161V3.58742C17.8176 2.88904 17.2381 2.36767 16.5448 2.36767H11.4539C10.7607 2.36767 10.1812 2.88904 10.1812 3.58742V22.8161C10.1812 23.47 9.61138 24 8.90847 24C8.20556 24 7.63574 23.47 7.63574 22.8161V3.58742Z' fill='#272827' />
	</Svg>
)
