import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'

export const validateFormValuationData = (valuation: TypeValuation) => {
	const errors: { companyName?: string, ebitda?: string, billing?: string, cash?: string, debt?: string, companyType?: string, tag?: string } = {}

	if (!valuation.companyName) {
		errors.companyName = 'Digite o nome da empresa'
	}

	if (!valuation.ebitda) {
		errors.ebitda = 'Digite o Ebitda da empresa'
	}

	if (!valuation.billing) {
		errors.billing = 'Digite o faturamento da empresa'
	}

	if (!valuation.privateCompanies && !valuation.publicCompanies) {
		errors.companyType = 'Selecione ao menos um tipo de empresa (capital aberto ou fechado)'
	}

	if (valuation.tags.length <= 0) {
		errors.tag = 'Selecione ao menos uma tag que mais se parece com a empresa'
	}

	return errors
}
