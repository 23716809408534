import { clone } from '../../../Functions/Utils/clone'
import { db5 } from '../../../Services/db5'
import { TypeMatch } from '../types/match'

export const setMatch = async ({ id, match }: { id: string, match: TypeMatch }) => {

	const cloneId = clone(id)
	const cloneMatch = clone(match)

	return await db5.matches.update({ id: cloneId, doc: cloneMatch })
}
