import { clone } from '../../../Functions/Utils/clone'
import { TypeNotification } from '../types/notification'
import { TypeUser } from '../../User/types/TypeUser'
import { db5 } from '../../../Services/db5'

export const setAsRead = async (notification: TypeNotification, user: TypeUser) => {

	const cloneNotification = clone(notification)
	const cloneUser = clone(user)

	return await db5.notifications.update({
		id: cloneNotification._id,
		doc: {
			unReadBy: {
				...cloneNotification.unReadBy,
				[cloneUser._id]: false
			}
		}
	})
}
