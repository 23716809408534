import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'

export const addBuyerCompany =
	(
		{ buyerCompany, transaction }:
			{ buyerCompany: string, transaction: TypeTransaction }
	) => {
		const arrayOfNames: string[] = buyerCompany.split(',')
		const arrayOfBuyerCompanies: TypeTransaction['buyerCompanies'] = arrayOfNames.map(name => ({ name }))

		const copyOfTransaction = { ...transaction }
		copyOfTransaction.buyerCompanies = [...transaction.buyerCompanies, ...arrayOfBuyerCompanies]
		return copyOfTransaction.buyerCompanies
	}

