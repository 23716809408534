/**
 * A função `isCredit` verifica se o tipo de operação de um teaser é op2 ou
 *op3.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é um objeto do tipo
 * `TypeTeaser`, que provavelmente contém informações sobre um teaser ou promoção
 *item. A função `isCredit` pega este objeto `teaser` como entrada e verifica se
 * a propriedade `typeOperation` de uma versão clonada do `teaser
 * @returns A função `isCredit` está retornando um valor booleano baseado em se
 * a propriedade `typeOperation` do objeto `teaser` é igual a
 * `TYPE_OPERATION.op2` ou `TYPE_OPERATION.op3`.
 */
//CRIADO POR IA

import { TYPE_OPERATION } from '../../../Constants/TYPE_OPERATION'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const isCredit = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return cloneTeaser.typeOperation === TYPE_OPERATION.op2 || cloneTeaser.typeOperation === TYPE_OPERATION.op3

}
