import { genFee } from '../genCount/genFee'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { GEN_STARK_FEE_BIG } from '../genConst/GEN_STARK_FEE_BIG'
import { TypeScoreStark } from '../types/TypeScoreStark'
import { GEN_MARGIN_FEE } from '../genConst/GEN_MARGIN_FEE'

export const genScoreFeeBigValue = (props: TypeScoreProps) => {
	const res: TypeScoreStark['scoreItens'] = []

	const successFee = genFee(props)
	const bigFee = GEN_STARK_FEE_BIG(props.operation) * GEN_MARGIN_FEE(props.operation)

	if (successFee >= bigFee) {
		res.push({ // adiciona 1
			'pontosMax': 1,
			'pontos': 1,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Bonus por porte',
			'itemDescrição': `Ganhou bonus por porte. Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, está acima de ${bigFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			devolutiva: ''
		} as const)
	}
	if (successFee < bigFee) {
		res.push({ // adiciona 0
			'pontosMax': 1,
			'pontos': 0,
			'pontosCor': 'green',
			'setor': 'Taxa de sucesso',
			'item': 'Bonus por porte',
			'itemDescrição': `Não ganhou pontos adicionais por porte. Taxa de sucesso avaliada em ${successFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} , está abaixo de ${bigFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,
			'metaDados': '',
			devolutiva: ''
		} as const)
	}

	return res
}
