import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { TypeParameterDate } from '.'
import { clone } from '../clone'
import { convertAllToDate } from './converts'

const MESSAGE_ERROR = 'Data inválida'

/**
 * @example 20240102000000 -> "2024/01/02"
 * @example new Date(2024, 5, 10, 0, 0, 0) -> "2024/06/10"
 */
export const YYYY_MM_DD = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'yyyy/MM/dd')
}

/**
 * @example 20240102000000 -> "02/01/2024"
 * @example new Date(2024, 5, 10, 0, 0, 0) -> "10/06/2024"
 */
export const DD_MM_YYYY = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'dd/MM/yyyy')
}

/**
 * @example 20240102044510 -> "04:45:10"
 * @example new Date(2024, 5, 10, 10, 20, 5) -> "10:20:05"
 */
export const HH_mm_ss = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'HH:mm:ss')
}

/**
 * @example 20240102044510 -> "04:45"
 * @example new Date(2024, 5, 10, 10, 20, 5) -> "10:20"
 */
export const HH_mm = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'HH:mm')
}

/**
 * @description número do dia no mês
 *
 * @example 20240102044510 -> "01"
 * @example 20242002044510 -> "20"
 */
export const day_number = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'dd')
}

/**
 * @description abreviação do nome do dia da semana
 *
 * @example 20240102044510 -> "ter"
 * @example 20242002044510 -> "qua"
 */
export const day_name_abbreviation = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'EEEEEE', { locale: ptBR })
}

/**
 * @description nome do dia da semana
 *
 * @example 20240102044510 -> "quarta"
 * @example 20242002044510 -> "segunda"
 */
export const day_name = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'EEE', { locale: ptBR })
}

/**
 * @example 20240102000000 -> "01"
 * @example new Date(2024, 5, 10, 0, 0, 0) -> "06"
 */
export const month_number = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'MM')
}

/**
 * @example 20240102000000 -> "jan"
 * @example new Date(2024, 5, 10, 0, 0, 0) -> "jun"
 */
export const month_name_abbreviation = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'MMM', { locale: ptBR })
}

/**
 * @example 20240102000000 -> "janeiro"
 * @example new Date(2024, 5, 10, 0, 0, 0) -> "junho"
 */
export const month_name = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'MMMM', { locale: ptBR })
}

/**
 * @example 20240102000000 -> "2024"
 */
export const year = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'yyyy')
}

/**
 * @example 20240102000000 -> "24"
 */
export const year_two_digits = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'yy')
}

/**
 * @example 20240102041020 -> "04"
 */
export const hour = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'HH')
}

/**
 * @example 20240102041020 -> "10"
 */
export const minute = <T extends TypeParameterDate>(
	value: T,
	messageError = MESSAGE_ERROR
): string => {
	const cloneValue = convertAllToDate(clone(value))

	if (!cloneValue) return messageError

	return format(cloneValue, 'mm')
}
