import { TypeTeaser } from '../../types/teaser'
import { scoreStark } from '../scoreStark'
import { TypeScoreStark } from '../scoreStark/types/TypeScoreStark'
import { TypeScoreProps } from '../scoreStark/types/TypeScoreProps'
import { algorithmTags } from '../../../../Screens/PreMatches/Functions/algorithmTags'
import { TypeTeses } from '../../../Tese/types/teses'
import { algorithmCredit } from '../algorithmCredit'
import { TypeTesesCredit } from '../../../TeseCredit/types/tesesCredit'
import { TypeInstitutions } from '../../../Institution/types/institutions'
import { _SUB_TYPE_OPERATIONS_GET } from '../../../../Constants/SUB_TYPE_OPERATIONS'
import { scoreStarkMin } from '../scoreStark/scoreStarkMin'

/**
 * Calcula a pontuação geral de um teaser com base em várias combinações de teses e instituições.
 *
 * Regras de Negócio:
 * 1. Inicializa um objeto `result` com valores padrão.
 * 2. Gera combinações de teses multi-setoriais e de crédito usando os algoritmos `algorithmTags` e `algorithmCredit`.
 * 3. Configura operações específicas que serão usadas para calcular a pontuação, associando cada operação a suas combinações correspondentes.
 * 4. Itera sobre as operações configuradas e calcula a pontuação de cada uma usando a função `scoreStark`, armazenando os resultados em `scoreCards`.
 * 5. Calcula a média das notas aprovadas dos cartões de pontuação e atualiza o resultado:
 *    - Filtra os cartões de pontuação aprovados.
 *    - Soma as notas dos cartões aprovados e calcula a média.
 *    - Se não houver cartões aprovados, calcula a média de todas as notas.
 *    - Atribui a média calculada à propriedade `score` do objeto `result`.
 * 6. Verifica se há cartões de pontuação aprovados e atualiza o status de aprovação no resultado:
 *    - Se houver pelo menos um cartão de pontuação aprovado, define a propriedade `approved` do objeto `result` como `true`.
 *
 * @param {TypeTeaser} teaser - O teaser para o qual a pontuação será calculada.
 * @param {TypeTeses} teses - As teses a serem consideradas no cálculo.
 * @param {TypeTesesCredit} tesesCredit - As teses de crédito a serem consideradas no cálculo.
 * @param {TypeInstitutions} institutions - As instituições a serem consideradas no cálculo.
 * @returns {scoreStarkGeneralT} - O resultado contendo a pontuação geral, status de aprovação, número de instituições e cartões de pontuação.
 */

export type scoreStarkGeneralT = {
	approved: boolean
	score: number
	institutions: number
	scoreColor: 'red' | 'green' | 'orange'

	scoreCards: TypeScoreStark[]
}

export const scoreStarkGeneral = (teaser: TypeTeaser, teses: TypeTeses, tesesCredit: TypeTesesCredit, institutions: TypeInstitutions): scoreStarkGeneralT => {

	const result: scoreStarkGeneralT = {
		approved: false,
		score: 0,
		institutions: 0,
		scoreColor: 'red',
		scoreCards: [],
	}

	const combinationsMA = algorithmTags({
		verMultiSetorial: true,
		filterFinancials: true,
		filterCheck: true,
		filterEtr: [],
		teses: teses,
		tagsTech: teaser?.tagTech,
		tags: teaser.tags,
		tagsNegative: teaser.tagsNegative,
		teaser: teaser,
		teaserId: teaser._id,
		matches: {},
	})

	const combinationsDCM = algorithmCredit(teaser, tesesCredit, institutions, teaser?._id, {})

	/**
	 * Configuração: determinamos aqui as operações e suas respectivas variáveis que serão usadas.
	*/
	const configOperations = [
		// {
		// 	op: _SUB_TYPE_OPERATIONS_GET('greenfield').id,
		// 	combinations: combinationsDCM,
		// },
		{
			op: _SUB_TYPE_OPERATIONS_GET('corporateFinance').id,
			combinations: combinationsDCM,
		},
		{
			op: _SUB_TYPE_OPERATIONS_GET('projectFinance').id,
			combinations: combinationsDCM,
		},
		{
			op: _SUB_TYPE_OPERATIONS_GET('total').id,
			combinations: combinationsMA,
		},
		{
			op: _SUB_TYPE_OPERATIONS_GET('minoritário').id,
			combinations: combinationsMA,
		},
		{
			op: _SUB_TYPE_OPERATIONS_GET('majoritário').id,
			combinations: combinationsMA,
		},
	]

	/**
	 * Aqui iteramos sobre as operações configuradas e calculamos o score de cada uma.
	*/
	configOperations.forEach(operation => result.scoreCards.push(
		scoreStark(teaser, operation.op as TypeScoreProps['operation'], operation.combinations)
	))

	/**
	 * Calcula a média das notas aprovadas dos cartões de pontuação e atualiza o resultado.
	 * Filtra os cartões de pontuação aprovados, soma suas notas e calcula a média.
	 * A média é então atribuída à propriedade `score` do objeto `result`.
	*/
	const scoreApproved = result.scoreCards.filter(score => score.approved)
	const scoreApprovedSum = scoreApproved.reduce((acc, score) => acc + score.scoreNote, 0)

	const scoreSum = result.scoreCards.reduce((acc, score) => acc + score.scoreNote, 0)

	const scoreAverage = scoreApproved.length ? (scoreApprovedSum / scoreApproved.length) : (scoreSum / result.scoreCards.length)
	result.score = +scoreAverage.toFixed(1)

	/**
	 * Verifica se há cartões de pontuação aprovados e atualiza o status de aprovação no resultado.
	 * Se houver pelo menos um cartão de pontuação aprovado, a propriedade `approved` do objeto `result` é definida como `true`.
	*/
	if (scoreApproved.length > 0) {
		result.approved = true
	}

	/**
	 * Atualiza o status de cor do resultado.
	*/
	if (result.score >= scoreStarkMin) {
		result.scoreColor = 'green'
	}

	return result
}
