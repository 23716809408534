import styled from 'styled-components'
import { IconsDS } from 'Components/IconsDS'
import { ReactNode } from 'react'
import { UiButton } from '../../ComponentsUi/UiButton'

export const ModalBox = styled.div`
padding: 32px 32px 24px 32px;
`

export const ModalTitle = styled.div`
margin: 12px 0 12px 0;
`

export const CloseBox = styled.div`
position: absolute;
right: 12px;
top: 12px;
`

export const BtClose = styled.button`
-webkit-appearance: none;
-moz-appearance:    none;
appearance:         none;
border-radius: 0;
-webkit-border-radius: 0;
-moz-border-radius: 0;
background:none;
border:none;
outline:none;

&:focus{
	outline:none;
}

cursor: pointer;
`

export type TGlobalMessage = {
	onClose?: () => void
	icon?: ReactNode
	title?: ReactNode
	description?: ReactNode
	okHandler?: () => void
	cancelHandler?: () => void
	okText?: string
	cancelText?: string;
	withoutCloseIcon?: boolean;
}

export const GlobalMessage = (props: TGlobalMessage) => {
	const { onClose, icon, title, description, okHandler, cancelHandler, okText, cancelText, withoutCloseIcon } = props
	return <ModalBox>
		{!withoutCloseIcon && <CloseBox>
			<BtClose onClick={onClose}><IconsDS.Close size={12} color={'#ED0000'} /></BtClose>
		</CloseBox>}
		<div className='text-center'>
			{icon || <IconsDS.Warning color={'#D5D5D5'} size={45} />}
			<ModalTitle>
				<h2 className='tw-text-base tw-font-bold tw-text-gray-800 '>{title}</h2>
			</ModalTitle>
			<p className='tw-text-sm'>{description}</p>
		</div>
		{okText && <UiButton onClick={okHandler} size='small' className='tw-w-full tw-mt-6 tw-mb-2'>{okText}</UiButton>}
		{cancelText && <UiButton onClick={cancelHandler} size='small' variant='tertiary' className='tw-w-full'>{cancelText}</UiButton>}
	</ModalBox>
}
