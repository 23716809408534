import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ShieldFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M21.5556 14.8889C21.9333 14.8889 22.3 14.9333 22.6667 14.9889V9.63333L14.3333 6L6 9.63333V15.0889C6 20.1333 9.55556 24.8556 14.3333 26C14.9444 25.8556 15.5333 25.6444 16.1111 25.3889C15.3444 24.3 14.8889 22.9778 14.8889 21.5556C14.8889 17.8778 17.8778 14.8889 21.5556 14.8889Z' fill='#272827' />
		<path d='M21.5558 17.1113C19.1002 17.1113 17.1113 19.1002 17.1113 21.5558C17.1113 24.0113 19.1002 26.0002 21.5558 26.0002C24.0113 26.0002 26.0002 24.0113 26.0002 21.5558C26.0002 19.1002 24.0113 17.1113 21.5558 17.1113ZM21.5558 18.6447C22.2447 18.6447 22.8002 19.2113 22.8002 19.8891C22.8002 20.5669 22.2336 21.1336 21.5558 21.1336C20.878 21.1336 20.3113 20.5669 20.3113 19.8891C20.3113 19.2113 20.8669 18.6447 21.5558 18.6447ZM21.5558 24.6113C20.5224 24.6113 19.6224 24.1002 19.0669 23.3113C19.1224 22.5113 20.7447 22.1113 21.5558 22.1113C22.3669 22.1113 23.9891 22.5113 24.0447 23.3113C23.4891 24.1002 22.5891 24.6113 21.5558 24.6113Z' fill='#272827' />
	</Svg>
)
