import { useContextAuth } from 'App/ContextAuth'
import { modelTour } from 'Functions/Tour/Model/modelTour'
import { TypeTour } from 'Functions/Tour/Type/TypeTour'
import { db5 } from 'Services/db5'
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import { useEffect, useState } from 'react'

export const useTour1 = () => {
	const { globalAuth } = useContextAuth()
	const profile = globalAuth.profile
	const [tourUser, setTourUser] = useState<TypeTour>()
	const [openTour, setOpenTour] = useState(false)


	const updateTour = async () => {
		await db5.tour.update({ id: profile._id, doc: { tour1: true } }).then(res => setTourUser({ tour1: true, _id: profile._id }))
	}

	useEffect(() => {

		let isMounted = true

		if (profile && isMounted) {
			const addUserIfDoesNotExist = async () => {
				await db5.tour.get({ id: profile._id, profile: profile }).then(res => {
					if (res.doc) {
						isMounted && setTourUser(res.doc)
					}
					else {
						db5.tour.add({ doc: modelTour({ tour1: false, _id: profile._id }), _id: profile._id }).then(res => setTourUser({ tour1: false, _id: profile._id }))
					}
				}).catch(err => {
					// console.log('err: ', err)
				})
			}

			addUserIfDoesNotExist()
		}

		return () => {
			isMounted = false
		}

	}, [profile])

	const driverObj = driver({
		onDestroyed: () => {
			driverObj.destroy()
			updateTour ? updateTour() : null
			setOpenTour ? setOpenTour(false) : null
		},
		allowClose: false,
		showProgress: true,
		prevBtnText: 'Anterior',
		nextBtnText: 'Próximo',
		doneBtnText: 'Ok, entendi!',
		steps: [
			{ element: '.tour-user-information', popover: { title: 'Bem-vindo ao seu Dashboard da Stark!', description: 'Aqui você encontra um resumo das principais informações sobre a plataforma.' } },
			{ element: '.tour-originacoes-em-destaque', popover: { title: 'Últimas Atualizações de Originações', description: 'Acompanhe as últimas atualizações sobre suas originações.' } },
			{
				element: '.tour-desempenho', popover: {
					title: 'Sua performance no programa', description: 'Analise sua performance como originador nesta seção.'
				}
			},
			{ element: '.tour-create-new-origination', popover: { title: 'Que bom que você chegou até aqui :)', description: 'Seu próximo passo é iniciar uma originação e cadastrar sua primeira empresa. Bons negócios.' } },
			{ element: '.tour-teses-quentes', popover: { title: 'Panorama de Mercado e Teses Mais Aquecidas', description: 'Fique por dentro das tendências do mercado e das teses mais quentes da plataforma.' } },
			{
				element: '.tour-calendar', popover: {
					title: 'Apanhado de Eventos Selecionados', description: 'Descubra os eventos selecionados pela equipe da Stark para você aproveitar.'
				}
			},
			{
				element: '.tour-featured-materials', popover: {
					title: 'Últimos Lançamentos de Materiais Relevantes', description: 'Acesse os materiais mais recentes e relevantes aqui.'
				}
			},
		]
	})
	return { driverObj, tourUser, openTour, setOpenTour }
}
