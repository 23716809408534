import styled from 'styled-components'

export const Container = styled.div`
position: relative;
`

export const Logo = styled.div`
display: grid;
place-items:center;
width:100%;

svg{
	width:120px;
	margin: 13px 0px;
}
`

export const LinePosition = styled.div`
position: absolute;
width: 120%;
height: 1px;
background: #DFDFDF;
bottom:0;
`
