import { helpersDate } from '../../../Functions/Utils/helpersDate'
import { TypeUser } from '../../../Functions/User/types/TypeUser'
import { TypeTeaser } from '../../../Functions/Teaser/types/teaser'
import { TypeTeasers } from '../../../Functions/Teaser/types/teasers'
import { firstDateInPlatform } from './firstDateInPlataform'
import { TypeViewTeasers001 } from '../../ViewTeaser001/types/TypeViewTeasers001'

export const firstApproval = ({ user, viewTeasers }: { user: TypeUser, viewTeasers: { [id: string]: TypeViewTeasers001 } }) => {
	const teasersFiltered: TypeViewTeasers001[] = Object.values(viewTeasers).filter(teaser => teaser.dataComercial && teaser.dataComercial !== 0)
	const datesComercial: Date[] = teasersFiltered.map(teaser => helpersDate.convertAllToDate(teaser.dataComercial))
	const datesOrderedAsc = datesComercial.sort((dateA, dateB) => dateA.getTime() - dateB.getTime())
	const firstDateOrigination = datesOrderedAsc[0] ? helpersDate.convertAllToTimestamp(datesOrderedAsc[0]) : null
	const userDate = helpersDate.convertAllToTimestamp(firstDateInPlatform(user))
	const differenceInDays = firstDateOrigination && userDate ? helpersDate.howManyDays(firstDateOrigination, userDate) : 0
	const dateFormated = firstDateOrigination ? helpersDate.toString(firstDateOrigination) : ''
	return {
		date:
		{
			value: firstDateOrigination,
			formatted: dateFormated
		},
		days: differenceInDays,
		label: ''
	}
}
