import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const PieChartOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M16.0265 29.9998C14.2099 29.9998 12.3934 29.5798 10.5768 28.8798C7.08347 27.4798 4.42851 24.6798 3.03117 21.1798C1.63382 17.6798 1.63382 13.8998 3.1709 10.3998C4.56825 7.03985 7.22321 4.51985 10.4371 2.97986C11.1358 2.69986 11.9742 2.97986 12.2537 3.67985C12.5331 4.37985 12.2537 5.21985 11.555 5.49985C8.90003 6.89985 6.804 8.99985 5.68613 11.6598C4.56825 14.4598 4.56825 17.3998 5.68613 20.1998C6.804 22.9998 8.90003 25.0998 11.6947 26.3598C14.4894 27.4798 17.4238 27.4798 20.2185 26.3598C23.0132 25.2398 25.1093 23.1398 26.3669 20.3398C26.6463 19.6398 27.4847 19.3598 28.1834 19.6398C28.8821 19.9198 29.1616 20.7598 28.8821 21.4598C27.4847 24.9598 24.69 27.6198 21.1967 29.0198C19.5199 29.7198 17.843 29.9998 16.0265 29.9998Z' fill='#272827' />
		<path d='M28.6024 17.4H16.0263C15.1878 17.4 14.6289 16.84 14.6289 16V3.4C14.6289 2.56 15.1878 2 16.0263 2C23.7117 2 29.9997 8.29998 29.9997 16C29.9997 16.84 29.4408 17.4 28.6024 17.4ZM17.4236 14.6H27.0653C26.3666 9.55998 22.454 5.49999 17.4236 4.93999V14.6Z' fill='#272827' />
	</Svg>
)
