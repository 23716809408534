import { fnTeaser } from 'Functions/Teaser'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { TypeTese } from 'Functions/Tese/types/tese'

export const combineFinanceInvestment = (tese: TypeTese, teaser: TypeTeaser, tech = false) => {

	const valorminimo = tese.valorminimo
	const valorminimoSemRestrição = tese.valorminimoSR
	const valormaximo = tese.valormaximo
	const valormaximoSemRestrição = tese.valormaximoSR

	const valuationMinimo = tech ? fnTeaser.getRbWithSeptemberRule(teaser) * 2 : fnTeaser.getEbWithSeptemberRule(teaser) * 4
	const minimoDoValuationMinimo = valuationMinimo * 0.05
	const valuationMaximo = tech ? fnTeaser.getRbWithSeptemberRule(teaser) * 4 : fnTeaser.getEbWithSeptemberRule(teaser) * 6
	const máximoDoValuationMaximo = valuationMaximo * 0.99

	if (!valorminimoSemRestrição && +valorminimo > +valuationMaximo) return false
	if (!valormaximoSemRestrição && +valormaximo < +minimoDoValuationMinimo) return false
	return true
}
