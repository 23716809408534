export const REASON_CONTRACT_DONE = {
	i000: { label: '', _id: 'i000', title: '' },
	i001: { label: 'Churn por parte do Office', _id: 'i001', title: 'Office' },
	i002: { label: 'Churn por parte do Cliente', _id: 'i002', title: 'Cliente' },
	i003: { label: 'Transação concluída', _id: 'i003', title: 'Concluído' },
} as const

export const REASON_CONTRACT_DONE_GET = (code: string) => {
	const _code = code as keyof typeof REASON_CONTRACT_DONE

	if (!REASON_CONTRACT_DONE[_code]) {
		return (REASON_CONTRACT_DONE.i000)
	}

	return REASON_CONTRACT_DONE[_code]
}
export const REASON_CONTRACT_DONE_VALUES = Object.values(REASON_CONTRACT_DONE)
export const REASON_CONTRACT_DONE_KEYS = Object.keys(REASON_CONTRACT_DONE)
