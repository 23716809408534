import { TypeUser } from '../types/TypeUser'
import { TYPE_USER_LABEL } from '../../../Constants/TYPE_USER'
import { TypeUserGenerics } from '../types/userGenerics'
import { getLabelAgent } from './getLabelAgent'
/**
 * Ordena um array de usuários pelo tipo
 * @param teasers
 * @param order
 * @returns  TypeUser[]
 */
export const sortByType = ({ users, order }: { users: TypeUserGenerics, order: 'asc' | 'desc' }): TypeUser[] =>
	Object.values(users).sort((userA: TypeUser, userB: TypeUser) => {
		const isAsc = order === 'asc'
		const nameA = getLabelAgent({ user: userA, users }).label || TYPE_USER_LABEL.bda
		const nameB = getLabelAgent({ user: userB, users }).label || TYPE_USER_LABEL.bda
		return isAsc ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
	})
