import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const GoldFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M1.33789 24.6426L3.33789 17.9759H12.6712L14.6712 24.6426H1.33789ZM17.3379 24.6426L19.3379 17.9759H28.6712L30.6712 24.6426H17.3379ZM8.00456 15.3092L10.0046 8.64258H19.3379L21.3379 15.3092H8.00456Z' fill='#272827' />
	</Svg>
)


