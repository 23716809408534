/**
 * A função `getRequestedChangedDate` retorna a data formatada e original
 *de alterações solicitadas de um objeto teaser.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido na base de código. Provavelmente contém informações
 * sobre um objeto teaser, como suas propriedades e valores. No
 * Função `getRequestedChangedDate`, este parâmetro é usado para extrair a `data
 * @returns A função `getRequestedChangedDate` retorna um objeto com dois
 * propriedades: `formatado` e `valor`. A propriedade `formated` contém o
 * string de data formatada obtida chamando a função `escreveData` no
 * Propriedade `dateRequestedChanges` do objeto `teaser` clonado. O `valor`
 * propriedade contém o valor `dateRequestedChanges` original do clonado `
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { escreveData } from '../../../Functions/Utils/minhaData'
import { TypeTeaser } from '../types/teaser'

export const getRequestedChangedDate = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return (cloneTeaser.dateRequestedChanges ?
		{ formated: escreveData((cloneTeaser.dateRequestedChanges).toString()), value: cloneTeaser.dateRequestedChanges } : null)


}
