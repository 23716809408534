import { masks } from 'Functions/Utils/masks'
import React from 'react'
import { IUiInput } from '..'

export const onChangePercentageDecimal = (props: IUiInput) =>
	(event: React.ChangeEvent<HTMLInputElement>) => {
		let value = masks.toPercent({
			value: event.target.value,
			min: props.min as number,
			max: props.max as number,
			fraction: props.fraction
		})
		value /= 100
		event.target.value = value.toString()
		props.onChange && props.onChange(event)
	}
