import firebase from 'firebase/app'
import 'firebase/firestore'

type Props = {
	id: string
	cb: () => void
}

const serviceDelTeaser = (id: string, callBack = () => { }) => {
	firebase.firestore()
		.doc(`teasers/${id}`)
		.delete()
		.then(() => callBack())
}

export const fnDeleteOrigination = (args: Props) => {
	const { id, cb } = args
	serviceDelTeaser(id, () => {
		cb()
	})
}
