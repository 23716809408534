import { typeRanking } from './types/typeRanking'

export const modelRankingAgents = (obj?: typeRanking): typeRanking => ({
	'date': obj?.date || new Date(),
	'ranking': obj?.ranking || [],
})

export const fnRankingAgents = {
	modelRankingAgents,
}
