import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const SendOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path fillRule='evenodd' clipRule='evenodd' d='M28.1564 4.6332C28.6771 5.1539 28.6771 5.99812 28.1564 6.51881L16.4252 18.2521C15.9046 18.7728 15.0605 18.7728 14.5399 18.2521C14.0193 17.7314 14.0193 16.8872 14.5399 16.3665L26.2712 4.6332C26.7918 4.1125 27.6358 4.1125 28.1564 4.6332Z' fill='#272827' />
		<path fillRule='evenodd' clipRule='evenodd' d='M28.1522 4.63324C28.5144 4.99542 28.637 5.53306 28.4679 6.01651L21.0025 27.3498C20.8219 27.8658 20.3447 28.2193 19.7986 28.2416C19.2524 28.2638 18.748 27.9504 18.526 27.4509L14.4683 18.3195L5.33856 14.2611C4.83907 14.0391 4.52571 13.5346 4.54799 12.9883C4.57027 12.4421 4.92368 11.9648 5.43959 11.7842L26.7692 4.31757C27.2526 4.14836 27.7901 4.27105 28.1522 4.63324ZM9.49412 13.1902L16.0197 16.0909C16.3213 16.225 16.5625 16.4662 16.6965 16.7678L19.5968 23.2946L25.0367 7.74933L9.49412 13.1902Z' fill='#272827' />
	</Svg>
)
