import { TYPE_NOTIFICATIONS } from '../../../../Constants/TYPE_NOTIFICATIONS'
import { fnOrigination } from '../../../Origination'
import { fnUser } from '../../../User'
import { TypeNotification } from '../../types/notification'
import { TypeTeaser } from '../../../Teaser/types/teaser'
import { TypeUser } from '../../../User/types/TypeUser'
import { fnNotification } from '../..'
import { clone } from '../../../Utils/clone'

const ITEM = TYPE_NOTIFICATIONS.b0015

export const sendDeliverables =
	async ({
		profile, // quem está acionando o gatilho da notificação
		uid, // quem está acionando o gatilho da notificação
		origination,
		originationId,
	}: {
		profile: TypeUser, // quem está acionando o gatilho da notificação
		uid: string, // quem está acionando o gatilho da notificação
		origination: TypeTeaser,
		originationId: string
	}) => {

		const cloneProfile = clone(profile)
		const cloneUid = clone(uid)
		const cloneOrigination = clone(origination)
		const cloneOriginationId = clone(originationId)

		return await fnNotification.send({
			uiPermissions: ITEM.permissions,
			uids: fnOrigination.getUidsNotification({ origination: cloneOrigination }),
			origination: fnNotification.formatData.getOrigination({ origination: cloneOrigination, id: cloneOriginationId }),
			createdBy: fnNotification.formatData.getCreatedBy({ profile: cloneProfile, uid: cloneUid }),
			user: fnUser.getUser({ user: cloneProfile || {} as TypeUser }),
			message: ITEM.msg,
			type: ITEM._id,
			showCustomer: true,
			analystEmail: fnOrigination.getAnalystEmail({ origination: cloneOrigination }),
		} as TypeNotification)
	}
