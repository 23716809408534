import { fnTeaser } from 'Functions/Teaser'
import { intersectionArray } from 'Functions/Utils/intersectionArray'
import { fatorialCnaes } from 'Functions/Utils/fatorialCnaes'
import { TypeMatches } from 'Functions/Match/types/matches'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { TypeTeses } from 'Functions/Tese/types/teses'
import { combineFinanceInvestment } from './combineFinanceInvestment'
import { combineFinances } from './combineFinances'
import { tagsFromCnaes } from './tagsFromCnaes'

export type algorithmTagsType = {
	verMultiSetorial: boolean,
	filterFinancials: boolean,
	filterCheck: boolean,
	filterEtr: ('' | 'aq100' | 'aqmi' | 'aqma')[],
	teses: TypeTeses
	teaser: TypeTeaser
	teaserId: string
	matches: TypeMatches
	tags: string[]
	tagsNegative: string[]
	tagsTech: string[]
}

export type algorithmCombination = {
	idInstitution: string,
	idTeaser: string,

	teses: []
	idsTeses: []

	tagsMatch: [],
	tagsTechMatch: [],

	combineFinances: boolean,
	combineFinanceInvestment: boolean,

	indexFinance: number
}

export type TCombinationFromAlgorithmTags = {
	[idInstitution: string]: {
		combinations: {
			[code: string]: {
				combineFinanceInvestment: boolean
				combineFinances: boolean
				idInstitution: string
				idTeaser: string
				idTese: string
				indexFinance: number //depreciado
				tagsMatch: string[]
				tagsTechMatch: string[]
			}
		}
		idInstitution: string
		idTeaser: string
		idsTeses: string[]
	}
}

export const algorithmTags = ({ verMultiSetorial, filterFinancials, filterCheck, filterEtr, teses, teaser, teaserId, matches, tagsTech, tags, tagsNegative }: algorithmTagsType) => {


	let combinations = {}
	let index = 0

	const matchesHash = Object.entries(matches)
		.reduce((cur, [matchId, match]) => ({ ...cur, [match?.idTeaser + match?.idTese]: 1 }), {})

	const matchesInstitutionHash = Object.entries(matches)
		.reduce((cur, [matchId, match]) => ({ ...cur, [match?.idTeaser + match?.idInstitution]: 1 }), {})

	Object.entries(teses).forEach(([teseId, tese]) => {

		const tagsTeaserInTese = () => {
			const tagsTese = tese.tags
			const list = verMultiSetorial ? [...tags, 't000'] : [...tags]
			// @ts-ignore
			const tagsTeaser = list.filter(tag => !tagsNegative.includes(tag))
			const tagsMatch = intersectionArray(tagsTese, tagsTeaser)
			return {
				tagsMatch: tagsMatch,
				data: !!tagsMatch.length
			}
		}

		const combineTagTech = () => {
			if (!fnTeaser.isTech(teaser)) {
				return ({
					tagsTechMatch: [],
					data: false
				})
			}

			const matchesTagsTech = intersectionArray(tese.tagTech, verMultiSetorial ? [...tagsTech, 'MultiTech'] : [...tagsTech])

			return ({
				tagsTechMatch: matchesTagsTech,
				data: !!matchesTagsTech.length,
			})
		}

		const addCombinations = () => {
			combinations = {
				...combinations,
				[`c${index}`]: {
					idInstitution: tese.idInstitution,
					idTese: teseId,
					idTeaser: teaserId,

					tagsMatch: tagsTeaserInTese().tagsMatch,
					tagsTechMatch: combineTagTech().tagsTechMatch,

					combineFinances: combineFinances(tese, teaser),
					combineFinanceInvestment: combineFinanceInvestment(tese, teaser, !!tese.tagTech?.length),

					indexFinance: +combineFinances(tese, teaser) + +combineFinanceInvestment(tese, teaser, !!tese.tagTech?.length)
				}
			}
			index++
		}

		const isExistMatch = () =>
			// @ts-ignore
			teseId ? !!matchesHash[teaserId + teseId] : false

		const isExistMatchInstitution = () =>
			// @ts-ignore
			teseId ? !!matchesInstitutionHash[teaserId + tese.idInstitution] : false

		if (
			!isExistMatch() && !isExistMatchInstitution() &&
			(filterFinancials ? combineFinances(tese, teaser) : true) &&
			(filterCheck ? combineFinanceInvestment(tese, teaser, !!tese.tagTech?.length) : true) &&
			(
				filterEtr?.length ? (
					(filterEtr?.[0] && tese?.[filterEtr[0]]) ||
					(filterEtr?.[1] && tese?.[filterEtr[1]]) ||
					(filterEtr?.[2] && tese?.[filterEtr[2]])
				) : true
				// (filterEtr[0] ? tese?.[filterEtr[0]] : true) ||
				// (filterEtr[1] ? tese?.[filterEtr[1]] : true) ||
				// (filterEtr[2] ? tese?.[filterEtr[2]] : true)
			) &&
			(tagsTeaserInTese().data || combineTagTech().data)
		) addCombinations()

	})


	const combinationsInstitutions = Object.entries(combinations).reduce((cur: any, [key, value]: [any, any]) => ({
		...cur,
		// [value.idInstitution]: [ ...(cur[value.idInstitution] || []), value]
		[value.idInstitution]: {
			idInstitution: value.idInstitution,
			idTeaser: teaserId,
			idsTeses: [...(cur[value.idInstitution]?.idsTeses || []), value.idTese],
			combinations: {
				...(cur[value.idInstitution]?.combinations || []),
				[key]: value
			}
		}
	}), {})

	// console.log(combinationsInstitutions)

	return combinationsInstitutions
}
