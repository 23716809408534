import { ReactNode } from 'react'
/**
 *
 * @param children
 * @param onlyCenter - quando true, adiciona apenas as classes de padding X para centralizar o component
 * @param hideHorizontalMargin - quando true, remove o padding X do layout
 * @returns
 */
export const LayoutBody = ({ children, hideMarginTop, className, hideHorizontalMargin }: { hideHorizontalMargin?: boolean, children: ReactNode, hideMarginTop?: boolean, className?: string }) => (
	<div className={`${hideHorizontalMargin ? '' : 'tw-px-4 sm:tw-px-8'} ${hideMarginTop ? '' : ' tw-py-6 tw-mt-[53px] tw-mb-20 md:tw-mb-0 '} ${className || ''}`}>{children}</div>
)
