import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ShoppingCartFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 33'} {...props}>
		<path d='M23.2039 23.8426C21.8719 23.8426 20.8039 24.9106 20.8039 26.2426C20.8039 26.8791 21.0568 27.4895 21.5069 27.9396C21.9569 28.3897 22.5674 28.6426 23.2039 28.6426C23.8404 28.6426 24.4509 28.3897 24.901 27.9396C25.351 27.4895 25.6039 26.8791 25.6039 26.2426C25.6039 24.9106 24.5239 23.8426 23.2039 23.8426ZM4.00391 4.64258V7.04258H6.40391L10.7239 16.1506L9.09191 19.0906C8.91191 19.4266 8.80391 19.8226 8.80391 20.2426C8.80391 20.8791 9.05676 21.4895 9.50685 21.9396C9.95694 22.3897 10.5674 22.6426 11.2039 22.6426H25.6039V20.2426H11.7079C11.6283 20.2426 11.552 20.211 11.4958 20.1547C11.4395 20.0984 11.4079 20.0221 11.4079 19.9426C11.4079 19.8826 11.4199 19.8346 11.4439 19.7986L12.5239 17.8426H21.4639C22.3639 17.8426 23.1559 17.3386 23.5639 16.6066L27.8599 8.84258C27.9439 8.65058 28.0039 8.44658 28.0039 8.24258C28.0039 7.92432 27.8775 7.61909 27.6524 7.39405C27.4274 7.16901 27.1222 7.04258 26.8039 7.04258H9.05591L7.92791 4.64258H4.00391ZM11.2039 23.8426C9.87191 23.8426 8.80391 24.9106 8.80391 26.2426C8.80391 26.8791 9.05676 27.4895 9.50685 27.9396C9.95694 28.3897 10.5674 28.6426 11.2039 28.6426C11.8404 28.6426 12.4509 28.3897 12.901 27.9396C13.351 27.4895 13.6039 26.8791 13.6039 26.2426C13.6039 24.9106 12.5239 23.8426 11.2039 23.8426Z' fill='#272827' />
	</Svg>
)


