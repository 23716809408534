import * as Tokens from '../../../Tokens'


export const CardTheme = {
	spacing: {
		sm: {
			size: Tokens.SPACE_2
		},
		md: {
			size: Tokens.SPACE_4
		},
		lg: {
			size: Tokens.SPACE_8
		}
	},
	radius: {
		sm: {
			size: Tokens.BORDER_SM
		},
		md: {
			size: Tokens.BORDER_MD
		},
		lg: {
			size: Tokens.BORDER_LG
		},
		xl: {
			size: Tokens.BORDER_XL
		},
		xxl: {
			size: Tokens.BORDER_XXL
		}
	},
	status: {
		default: {
			backgroundColor: Tokens.CARD_DEFAULT_BACKGROUND,
			borderColor: Tokens.CARD_DEFAULT_BORDER_COLOR
		},
		disabled: {
			backgroundColor: Tokens.CARD_DISABLED_BACKGROUND,
			borderColor: Tokens.CARD_DISABLED_BORDER_COLOR,
		},
		active: {
			backgroundColor: Tokens.CARD_ACTIVE_BACKGROUND,
			borderColor: Tokens.CARD_ACTIVE_BORDER_COLOR,
		},
		success: {
			backgroundColor: Tokens.CARD_SUCCESS_BACKGROUND,
			borderColor: Tokens.CARD_SUCCESS_BORDER_COLOR,
		},
		error: {
			backgroundColor: Tokens.CARD_ERROR_BACKGROUND,
			borderColor: Tokens.CARD_ERROR_BORDER_COLOR,
		},
		warning: {
			backgroundColor: Tokens.CARD_WARNING_BACKGROUND,
			borderColor: Tokens.CARD_WARNING_BORDER_COLOR,
		}
	},
	shadow: {
		sm: {
			size: Tokens.SHADOW_DEFAULT_SMALL,
			color: Tokens.SHADOW_DEFAULT_SMALL_COLOR
		},
		md: {
			size: Tokens.SHADOW_DEFAULT_MEDIUM,
			color: Tokens.SHADOW_DEFAULT_MEDIUM_COLOR
		},
		lg: {
			size: Tokens.SHADOW_DEFAULT_LARGE,
			color: Tokens.SHADOW_DEFAULT_LARGE_COLOR
		},
	}
}
