import { TESE_CREDIT_DF_AUDITADA } from '../../../Constants/TESE_CREDIT_DF_AUDITADA'
import { TESE_CREDIT_OPERATION_ESG } from '../../../Constants/TESE_CREDIT_OPERATION_ESG'
import { TESE_CREDIT_IMPEDIMENTO_RECEITA } from '../../../Constants/TESE_CREDIT_IMPEDIMENTO_RECEITA'
import { TESE_CREDIT_INDEXERS } from '../../../Constants/TESE_CREDIT_INDEXERS'
import { TESE_CREDIT_INSTITUTIONAL_INVESTOR } from '../../../Constants/TESE_CREDIT_INSTITUTIONAL_INVESTOR'
import { TESE_CREDIT_JUDICIAL_RECOVERY } from '../../../Constants/TESE_CREDIT_JUDICIAL_RECOVERY'
import { TESE_CREDIT_MANDATORY_AUDITED_SHEETS } from '../../../Constants/TESE_CREDIT_MANDATORY_AUDITED_SHEETS'
import { TESE_CREDIT_SECTORS } from '../../../Constants/TESE_CREDIT_SECTORS'
import { TESE_CREDIT_USABILITY } from '../../../Constants/TESE_CREDIT_USABILITY'
import { TESE_CREDIT_VEHICLES } from '../../../Constants/TESE_CREDIT_VEHICLES'
import { TypeTeseCredit } from '../types/teseCredit'
import { helpersDate } from '../../../Functions/Utils/helpersDate'



export const modelTeseCredit = (tese?: Partial<TypeTeseCredit>): TypeTeseCredit => ({
	_id: tese?._id || '',
	createdAt: tese?.createdAt || helpersDate.convertAllToTimestamp(new Date()), //data de criação
	modifiedAt: tese?.modifiedAt || tese?.createdAt || helpersDate.convertAllToTimestamp(new Date()), //data de modificação
	updateDate: tese?.updateDate || tese?.modifiedAt || tese?.createdAt || helpersDate.convertAllToTimestamp(new Date()),
	complementaryNotes: tese?.complementaryNotes || '',
	usability: tese?.usability || TESE_CREDIT_USABILITY.u000._id,
	institutionId: tese?.institutionId || '',
	interesseOperacoesDividas: tese?.interesseOperacoesDividas ?? false,
	interesseFinanciamentos: tese?.interesseFinanciamentos ?? false,
	interesseOperacoesImobiliarias: tese?.interesseOperacoesImobiliarias ?? false,
	garantiaImovel: tese?.garantiaImovel ?? false, //garantia de imóvel aceita (ou não)
	garantiaVeiculos: tese?.garantiaVeiculos ?? false, //garantia de veículos aceita (ou não)
	garantiaBoletosDuplicatasContratos: tese?.garantiaBoletosDuplicatasContratos ?? false, //garantia de Boletos/Duplicatas/Contratos aceita (ou não)
	garantiaRecebiveisImobiliarios: tese?.garantiaRecebiveisImobiliarios ?? false,
	garantiaRecebiveis: tese?.garantiaRecebiveis ?? false, //garantia de recebíveis imobiliários aceita (ou não)
	garantiaFiancaSafra: tese?.garantiaFiancaSafra ?? false, //garantia de fiança safra aceita (ou não)
	garantiaEstoquesAgricolas: tese?.garantiaEstoquesAgricolas ?? false, //garantia de estoques agrícolas aceita (ou não)
	garantiaOutros: tese?.garantiaOutros ?? false, //garantia outros aceita (ou não)
	recebiveisImobiliariosPulverizados: tese?.recebiveisImobiliariosPulverizados ?? false, //aceita recebiveis imobiliarios do tipo pulverizado (ou não)
	recebiveisImobiliariosConcentrado: tese?.recebiveisImobiliariosConcentrado ?? false, //aceita recebiveis imobiliarios do tipo concentrado (ou não)
	portfolioPerformed: tese?.portfolioPerformed ?? false, //carteira performada

	groupProducts: tese?.groupProducts || [], //grupo de produtos

	bancosComerciaisImobiliarioDetails: tese?.bancosComerciaisImobiliarioDetails || '',
	capitalGiro: tese?.capitalGiro ?? false, //capital de giro
	capitalInvestimento: tese?.capitalInvestimento ?? false, //capital de investimento
	financiamentoAquisicao: tese?.financiamentoAquisicao ?? false, //financiamento de aquisição
	financiamentoConstrucaoReforma: tese?.financiamentoConstrucaoReforma ?? false, //financiamento de construção e reforma
	homeEquity: tese?.homeEquity ?? false, //home equity

	bancosComerciaisRecebiveisComerciaisDetails: tese?.bancosComerciaisRecebiveisComerciaisDetails || '',
	antecipacaoDescontoSimples: tese?.antecipacaoDescontoSimples ?? false, //antecipação de desconto simples
	antecipacaoNaoPerformado: tese?.antecipacaoNaoPerformado ?? false, //antecipação de não performado
	capitalGiro12Meses: tese?.capitalGiro12Meses ?? false, //capital de giro 12 meses
	cessaoSemDireitoRegreco: tese?.cessaoSemDireitoRegreco ?? false, //cessão sem direito de regresso
	comprorVendor: tese?.comprorVendor ?? false, //compror vendor
	operacaoComissaria: tese?.operacaoComissaria ?? false, //operação comissária

	bancosComerciaisRecebiveisImobiliariosDetails: tese?.bancosComerciaisRecebiveisImobiliariosDetails || '',
	antecipacaoRecebiveisObra: tese?.antecipacaoRecebiveisObra ?? false, //antecipação de recebíveis de obra
	compraCarteiraPerformada: tese?.compraCarteiraPerformada ?? false, //compra de carteira performada
	creditoGarantiaContratoAluguel: tese?.creditoGarantiaContratoAluguel ?? false, //crédito garantia de contrato de aluguel

	bancosComerciaisVeiculosAeronavesEmbarcacoesDetails: tese?.bancosComerciaisVeiculosAeronavesEmbarcacoesDetails || '',
	financiamentoFrotaAquisicao: tese?.financiamentoFrotaAquisicao ?? false, //financiamento de frota de aquisição
	refinanciamentoCapitalGiro: tese?.refinanciamentoCapitalGiro ?? false, //refinanciamento de capital de giro

	bancosComerciaisMaquinasEquipamentosDetails: tese?.bancosComerciaisMaquinasEquipamentosDetails || '',
	maquinasFinanciamentoAquisicao: tese?.maquinasFinanciamentoAquisicao ?? false, //financiamento de aquisição de máquinas
	maquinasLeasing: tese?.maquinasLeasing ?? false, //leasing de máquinas
	maquinasLeasingBack: tese?.maquinasLeasingBack ?? false, //leasing back de máquinas

	bancosComerciaisEstoquesDetails: tese?.bancosComerciaisEstoquesDetails || '',
	giroEstoque: tese?.giroEstoque ?? false, //giro de estoque
	cdaWa: tese?.cdaWa ?? false, //cda/wa

	bancosComerciaisFiancaSafraDetails: tese?.bancosComerciaisFiancaSafraDetails || '',
	custeio: tese?.custeio ?? false, //custeio
	investimento: tese?.investimento ?? false, //investimento

	bancosComerciaisTradeFinanceDetails: tese?.bancosComerciaisTradeFinanceDetails || '',
	accAce: tese?.accAce ?? false, //acc/ace
	produto4131: tese?.produto4131 ?? false, //produto 4131
	finimp: tese?.finimp ?? false, //finimp






	endOfWork: tese?.endOfWork ?? false, //termino de obra
	corporateCri: tese?.corporateCri ?? false, //cri corporativo
	realEstateExpensesRefund: tese?.realEstateExpensesRefund ?? false, //reembolso de despesas imobiliarias
	rentCri: tese?.rentCri ?? false, //cri de aluguel
	buyToLease: tese?.buyToLease ?? false,
	saleAndLeaseback: tese?.saleAndLeaseback ?? false,
	builtToSuit: tese?.builtToSuit ?? false,
	desenvolvimento: tese?.desenvolvimento ?? false,
	aquisicaoDireta: tese?.aquisicaoDireta ?? false,
	operacoesEstruturadas: tese?.operacoesEstruturadas ?? false,
	/** Opção de produto: legal claim */
	compraDeAtivosJudiciais: tese?.compraDeAtivosJudiciais ?? false,
	/** Opção de produto: legal claim */
	npl: tese?.npl ?? false,
	/** Opção de produto: legal claim */
	outrasOperacoes: tese?.outrasOperacoes ?? false,
	/** Opção de produto: CRA - fii agro */
	cra: tese?.cra ?? false,
	/** Opção de produto: CPR - fii agro */
	cpr: tese?.cpr ?? false,
	/** Opção de produto: CRP - fii agro */
	crp: tese?.crp ?? false,
	/** Opção de produto: CDA/WA - fii agro */
	cdawa: tese?.cdawa ?? false,
	/** Opção de produto: CDCA - fii agro */
	cdca: tese?.cdca ?? false,
	/** Opção de produto: Debênture - fii agro */
	debenture: tese?.debenture ?? false,
	/** Opção de produto: Outros - fii agro */
	outrosFiiAgro: tese?.outrosFiiAgro ?? false,
	/** Opção de produto: antecipacao - fidc */
	fidcAntecipacao: tese?.fidcAntecipacao ?? false,
	/** Opção de produto: aquisicao - fidc */
	fidcAquisicao: tese?.fidcAquisicao ?? false,
	/** Opção de produto: ccbnc - fidc */
	fidcCCBNC: tese?.fidcCCBNC ?? false,
	// email: tese?.email ?? '',
	// nome: tese?.nome ?? '',
	// sobrenome: tese?.sobrenome ?? '',
	// telefone: tese?.telefone ?? '',
	// nomeInstituicao: tese?.nomeInstituicao ?? '',
	// cargo: tese?.cargo ?? '',
	operacoesDividaCapitalGiro: tese?.operacoesDividaCapitalGiro ?? false, // objetivo com o qual o empresário pode realizar operações de dívida
	operacoesDividaMelhorarPerfilDivida: tese?.operacoesDividaMelhorarPerfilDivida ?? false, // objetivo com o qual o empresário pode realizar operações de dívida
	operacoesDividaRecursosNovosInvestimentos: tese?.operacoesDividaRecursosNovosInvestimentos ?? false, // objetivo com o qual o empresário pode realizar operações de dívida
	operacoesDividaLeveragedBuyout: tese?.operacoesDividaLeveragedBuyout ?? false, // objetivo com o qual o empresário pode realizar operações de dívida
	operacoesDividaGeneralista: tese?.operacoesDividaGeneralista ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaEducacao: tese?.operacoesDividaEducacao ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaFinanceiro: tese?.operacoesDividaFinanceiro ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaAgro: tese?.operacoesDividaAgro ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaIndustria: tese?.operacoesDividaIndustria ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaLogistica: tese?.operacoesDividaLogistica ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaRealState: tese?.operacoesDividaRealState ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaSaude: tese?.operacoesDividaSaude ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaVarejo: tese?.operacoesDividaVarejo ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaTecnologia: tese?.operacoesDividaTecnologia ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaStartup: tese?.operacoesDividaStartup ?? false, // setor para o qual deseja realizar operações de dívida
	operacoesDividaSweetSpot: tese?.operacoesDividaSweetSpot || { formated: '', value: 0 }, // recurso mínimo esperado para as operações de dívida
	operacoesDividaValorMaximo: tese?.operacoesDividaValorMaximo || { formated: '', value: 0 }, // recurso mínimo esperado para as operações de dívida
	operacoesDividaFaturamentoMinimo: tese?.operacoesDividaFaturamentoMinimo || { formated: '', value: 0 }, // recurso mínimo esperado para as operações de dívida
	operacoesDividaValorMinimo: tese?.operacoesDividaValorMinimo || { formated: '', value: 0 }, // recurso mínimo esperado para as operações de dívida
	operacoesDividaSituacoesAtraente: tese?.operacoesDividaSituacoesAtraente ?? '', // perfil da transação desejada
	operacoesDividaSituacoesDeal: tese?.operacoesDividaSituacoesDeal ?? '', // situações Deal Break
	operacoesDividaDiferenciais: tese?.operacoesDividaDiferenciais ?? '', // descrição dos diferenciais das operações de dívida
	operacoesDividaKitBanco: tese?.operacoesDividaKitBanco ?? '', //kit banco para operações de dívida

	garantiaImovelCasaApartamento: tese?.garantiaImovelCasaApartamento ?? { value: '', checked: false }, // garantia de imóvel
	garantiaImovelSalaLojaComercial: tese?.garantiaImovelSalaLojaComercial ?? { value: '', checked: false }, // garantia de imóvel
	garantiaImovelTerreno: tese?.garantiaImovelTerreno ?? { value: '', checked: false }, // garantia de imóvel
	garantiaImovelImovelRural: tese?.garantiaImovelImovelRural ?? { value: '', checked: false }, // garantia de imóvel
	garantiaImovelPredio: tese?.garantiaImovelPredio ?? { value: '', checked: false }, // garantia de imóvel
	garantiaImovelGalpaoIndustrial: tese?.garantiaImovelGalpaoIndustrial ?? { value: '', checked: false }, // garantia de imóvel
	realEstateGuaranteeProductiveFarms: tese?.realEstateGuaranteeProductiveFarms ?? false,
	realEstateGuaranteeNonProductiveFarms: tese?.realEstateGuaranteeNonProductiveFarms ?? false,
	realEstateGuaranteeOthers: tese?.realEstateGuaranteeOthers ?? false,
	realEstateGuaranteeDetails: tese?.realEstateGuaranteeDetails || '',

	financiamentosCarrosMotos: tese?.financiamentosCarrosMotos ?? { value: '', checked: false }, //Bens aceitos e LTV máximo aceito na política de financiamento da instituição
	financiamentosMaquinas: tese?.financiamentosMaquinas ?? { value: '', checked: false }, //Bens aceitos e LTV máximo aceito na política de financiamento da instituição
	financiamentosTrator: tese?.financiamentosTrator ?? { value: '', checked: false }, //Bens aceitos e LTV máximo aceito na política de financiamento da instituição
	financiamentosBarco: tese?.financiamentosBarco ?? { value: '', checked: false }, //Bens aceitos e LTV máximo aceito na política de financiamento da instituição
	financiamentosAviao: tese?.financiamentosAviao ?? { value: '', checked: false }, //Bens aceitos e LTV máximo aceito na política de financiamento da instituição

	garantiaImovelAc: tese?.garantiaImovelAc ?? false, // garantia imóvel Acre
	garantiaImovelAm: tese?.garantiaImovelAm ?? false, // garantia imóvel Amazonas
	garantiaImovelRo: tese?.garantiaImovelRo ?? false, // garantia imóvel Rondônia
	garantiaImovelTo: tese?.garantiaImovelTo ?? false, // garantia imóvel Tocantins
	garantiaImovelAp: tese?.garantiaImovelAp ?? false, // garantia imóvel Amapá
	garantiaImovelPa: tese?.garantiaImovelPa ?? false, // garantia imóvel Pará
	garantiaImovelRr: tese?.garantiaImovelRr ?? false, // garantia imóvel Roraima
	garantiaImovelAl: tese?.garantiaImovelAl ?? false, // garantia imóvel Alagoas
	garantiaImovelBa: tese?.garantiaImovelBa ?? false, // garantia imóvel Bahia
	garantiaImovelCe: tese?.garantiaImovelCe ?? false, // garantia imóvel Ceará
	garantiaImovelMa: tese?.garantiaImovelMa ?? false, // garantia imóvel Maranhão
	garantiaImovelPb: tese?.garantiaImovelPb ?? false, // garantia imóvel Paraíba
	garantiaImovelPi: tese?.garantiaImovelPi ?? false, // garantia imóvel Piauí
	garantiaImovelPe: tese?.garantiaImovelPe ?? false, // garantia imóvel Pernambuco
	garantiaImovelRn: tese?.garantiaImovelRn ?? false, // garantia imóvel Rio Grande do Norte
	garantiaImovelSe: tese?.garantiaImovelSe ?? false, // garantia imóvel Sergipe
	garantiaImovelDf: tese?.garantiaImovelDf ?? false, // garantia imóvel Distrito Federal
	garantiaImovelGo: tese?.garantiaImovelGo ?? false, // garantia imóvel Goiás
	garantiaImovelMt: tese?.garantiaImovelMt ?? false, // garantia imóvel Mato Grosso
	garantiaImovelMs: tese?.garantiaImovelMs ?? false, // garantia imóvel Mato Grosso do Sul
	garantiaImovelEs: tese?.garantiaImovelEs ?? false, // garantia imóvel Espírito Santos
	garantiaImovelMg: tese?.garantiaImovelMg ?? false, // garantia imóvel Minas Gerais
	garantiaImovelRj: tese?.garantiaImovelRj ?? false, // garantia imóvel Rio de Janeiro
	garantiaImovelSp: tese?.garantiaImovelSp ?? false, // garantia imóvel São Paulo
	garantiaImovelSc: tese?.garantiaImovelSc ?? false, // garantia imóvel Santa Catarina
	garantiaImovelPr: tese?.garantiaImovelPr ?? false, // garantia imóvel Paraná
	garantiaImovelRs: tese?.garantiaImovelRs ?? false, // garantia imóvel Rio Grande do Sul

	garantiaBoletosDuplicatasInadiplenciaMaxima: tese?.garantiaBoletosDuplicatasInadiplenciaMaxima ?? '', //Inadimplência Máxima Aceita (%) em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasMaximoLtv: tese?.garantiaBoletosDuplicatasMaximoLtv ?? '', //Máximo LTV Aceito (%) em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasPrazoMaximoMeses: tese?.garantiaBoletosDuplicatasPrazoMaximoMeses ?? '', //Prazo Máx.: Contratos (meses) em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasPrazoMaximoDias: tese?.garantiaBoletosDuplicatasPrazoMaximoDias ?? '', //Prazo Máx.: Boletos/Duplicatas (dias) em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasPrazoMedioAdequadoCarteira: tese?.garantiaBoletosDuplicatasPrazoMedioAdequadoCarteira ?? '', //Prazo Médio Adequado da Carteira (meses) em Boletos/Duplicatas/Contratos

	garantiaBoletosDuplicatasPulverizado: tese?.garantiaBoletosDuplicatasPulverizado ?? false, //Tipos de sacados em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasMonosacado: tese?.garantiaBoletosDuplicatasMonosacado ?? false, //Tipos de sacados em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasPoucoSacados: tese?.garantiaBoletosDuplicatasPoucoSacados ?? false, //Tipos de sacados em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasEstadoMercadoriaPerformada: tese?.garantiaBoletosDuplicatasEstadoMercadoriaPerformada ?? false, //Tipos de sacados em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasEstadoMercadoriaNaoPerformada: tese?.garantiaBoletosDuplicatasEstadoMercadoriaNaoPerformada ?? false, //Tipos de sacados em Boletos/Duplicatas/Contratos

	garantiaBoletosDuplicatasMaximoConcentracaoAceitoSacado: tese?.garantiaBoletosDuplicatasMaximoConcentracaoAceitoSacado ?? 0, // Máximo de Concentração Aceito por Sacado em Boletos/Duplicatas/Contratos
	garantiaBoletosDuplicatasMaximoSacadoPublicoAceito: tese?.garantiaBoletosDuplicatasMaximoSacadoPublicoAceito ?? 0, //Máximo de Sacados Públicos Aceito em Boletos/Duplicatas/Contratos

	fiancaSafraAlgodao: tese?.fiancaSafraAlgodao ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraArroz: tese?.fiancaSafraArroz ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraCafe: tese?.fiancaSafraCafe ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraEspeciarias: tese?.fiancaSafraEspeciarias ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraFeijao: tese?.fiancaSafraFeijao ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraHortifruti: tese?.fiancaSafraHortifruti ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraMaricultura: tese?.fiancaSafraMaricultura ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraMilho: tese?.fiancaSafraMilho ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraPecuaria: tese?.fiancaSafraPecuaria ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	casaApartamento: tese?.casaApartamento ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraPisicultura: tese?.fiancaSafraPisicultura ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraSoja: tese?.fiancaSafraSoja ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraSucroalcoleiro: tese?.fiancaSafraSucroalcoleiro ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraTrigo: tese?.fiancaSafraTrigo ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraFloricultura: tese?.fiancaSafraFloricultura ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraTodos: tese?.fiancaSafraTodos ?? false, //Culturas/Produção Aceitas na fiança safra da empresa
	fiancaSafraOutros: tese?.fiancaSafraOutros ?? '', //Culturas/Produção Aceitas na fiança safra da empresa
	vintageBailBeefCattle: tese?.vintageBailBeefCattle ?? false, //Pecuária de corte
	vintageBailDairyFarming: tese?.vintageBailDairyFarming ?? false, //Pecuária de leite
	vintageBailOthers: tese?.vintageBailOthers ?? false,

	fiancaSafraAc: tese?.fiancaSafraAc ?? false, //garantia fiança safra AC
	fiancaSafraAm: tese?.fiancaSafraAm ?? false, //garantia fiança safra AM
	fiancaSafraRo: tese?.fiancaSafraRo ?? false, //garantia fiança safra RO
	fiancaSafraTo: tese?.fiancaSafraTo ?? false, //garantia fiança safra TO
	fiancaSafraAp: tese?.fiancaSafraAp ?? false, //garantia fiança safra AP
	fiancaSafraPa: tese?.fiancaSafraPa ?? false, //garantia fiança safra PA
	fiancaSafraRr: tese?.fiancaSafraRr ?? false, //garantia fiança safra RR
	fiancaSafraAl: tese?.fiancaSafraAl ?? false, //garantia fiança safra AL
	fiancaSafraBa: tese?.fiancaSafraBa ?? false, //garantia fiança safra BA
	fiancaSafraCe: tese?.fiancaSafraCe ?? false, //garantia fiança safra CE
	fiancaSafraMa: tese?.fiancaSafraMa ?? false, //garantia fiança safra MA
	fiancaSafraPb: tese?.fiancaSafraPb ?? false, //garantia fiança safra PB
	fiancaSafraPi: tese?.fiancaSafraPi ?? false, //garantia fiança safra PI
	fiancaSafraPe: tese?.fiancaSafraPe ?? false, //garantia fiança safra PE
	fiancaSafraRn: tese?.fiancaSafraRn ?? false, //garantia fiança safra RN
	fiancaSafraSe: tese?.fiancaSafraSe ?? false, //garantia fiança safra SE
	fiancaSafraDf: tese?.fiancaSafraDf ?? false, //garantia fiança safra DF
	fiancaSafraGo: tese?.fiancaSafraGo ?? false, //garantia fiança safra GO
	fiancaSafraMt: tese?.fiancaSafraMt ?? false, //garantia fiança safra MT
	fiancaSafraMs: tese?.fiancaSafraMs ?? false, //garantia fiança safra MS
	fiancaSafraEs: tese?.fiancaSafraEs ?? false, //garantia fiança safra ES
	fiancaSafraMg: tese?.fiancaSafraMg ?? false, //garantia fiança safra MG
	fiancaSafraRj: tese?.fiancaSafraRj ?? false, //garantia fiança safra RJ
	fiancaSafraSp: tese?.fiancaSafraSp ?? false, //garantia fiança safra SP
	fiancaSafraSc: tese?.fiancaSafraSc ?? false, //garantia fiança safra SC
	fiancaSafraPr: tese?.fiancaSafraPr ?? false, //garantia fiança safra PR
	fiancaSafraRs: tese?.fiancaSafraRs ?? false, //garantia fiança safra RS

	fiancaSafraArrendamento: tese?.fiancaSafraArrendamento ?? false, //se a propriedade pode estar arrendada
	fiancaSafraCpr: tese?.fiancaSafraCpr ?? false, //se aceitam apenas CPR

	veiculosValorMinimo: tese?.veiculosValorMinimo ?? '', //valor mínimo da garantia de veículos
	veiculosIdadeMaxima: tese?.veiculosIdadeMaxima ?? '', //idade máxima do veículo
	veiculosValorMaximo: tese?.veiculosValorMaximo ?? '', //valor máximo da garantia de veículos
	veiculosMaximoLtv: tese?.veiculosMaximoLtv ?? null, //valor máximo LTV aceito

	financiamentosInformacoesComplementares: tese?.financiamentosInformacoesComplementares ?? '', //Informações complementares de financiamento

	operacoesImobiliariasFaturamentoMinimo: tese?.operacoesImobiliariasFaturamentoMinimo ?? '', //Faturamento Mínimo da Empresa para Operações Imobiliárias
	operacoesImobiliariasTicketMinimo: tese?.operacoesImobiliariasTicketMinimo ?? '', //Ticket Mínimo da Operação para Operações Imobiliárias
	operacoesImobiliariasTicketMaximo: tese?.operacoesImobiliariasTicketMaximo ?? '', //Ticket Máximo da Operação para Operações Imobiliárias
	operacoesImobiliariasLtvMaximoSaleLeaseback: tese?.operacoesImobiliariasLtvMaximoSaleLeaseback ?? '', //LTV Máximo do Sale Leaseback para Operações Imobiliárias

	recebiveisImobiliariosLoteamente: tese?.recebiveisImobiliariosLoteamente ?? false, //Aceitam recebíveis de loteamento nos Recebíveis Imobiliários (pulverizados) ou não
	recebiveisImobiliariosLtvMaximo: tese?.recebiveisImobiliariosLtvMaximo ?? null, //Máximo LTV Aceito nos Recebíveis Imobiliários (pulverizados)
	recebiveisImobiliariosPrazoMaximoCarteira: tese?.recebiveisImobiliariosPrazoMaximoCarteira ?? '', //Prazo Máximo da Carteira nos Recebíveis Imobiliários (pulverizados)
	recebiveisImobiliariosPrazoMaximoOperacao: tese?.recebiveisImobiliariosPrazoMaximoOperacao ?? '', //Prazo Máximo da Operação nos Recebíveis Imobiliários (pulverizados)

	recebiveisImobiliariosPerformados: tese?.recebiveisImobiliariosPerformados ?? false, //Tipo de sacados nos Recebíveis Imobiliários (corporativo)
	recebiveisImobiliariosContratosAtipicos: tese?.recebiveisImobiliariosContratosAtipicos ?? false, //Tipo de sacados nos Recebíveis Imobiliários (corporativo)
	recebiveisImobiliariosPrediosConstrucao: tese?.recebiveisImobiliariosPrediosConstrucao ?? false, //Tipo de sacados nos Recebíveis Imobiliários (corporativo)
	recebiveisImobiliariosContratosTipicos: tese?.recebiveisImobiliariosContratosTipicos ?? false, //Tipo de sacados nos Recebíveis Imobiliários (corporativo)
	recebiveisImobiliariosAlternativos: tese?.recebiveisImobiliariosAlternativos ?? false, //Tipo de sacados nos Recebíveis Imobiliários (corporativo)
	recebiveisImobiliariosInformacoesComplementares: tese?.recebiveisImobiliariosInformacoesComplementares ?? '', //Informações complementares sobre os tipos de sacados nos Recebíveis Imobiliários (corporativo)

	operacoesImobiliariasSaleLeaseback: tese?.operacoesImobiliariasSaleLeaseback ?? false, //Tipo de Operação Imobiliária
	operacoesImobiliariasBuiltSuit: tese?.operacoesImobiliariasBuiltSuit ?? false, //Tipo de Operação Imobiliária
	operacoesImobiliariasAtivosBuyLease: tese?.operacoesImobiliariasAtivosBuyLease ?? false, //Tipo de Operação Imobiliária
	operacoesImobiliariasTipico: tese?.operacoesImobiliariasTipico ?? false, //Tipo de Contrato Aceito na operação imobiliária
	operacoesImobiliariasAtipico: tese?.operacoesImobiliariasAtipico ?? false, //Tipo de Contrato Aceito na operação imobiliária

	operacoesImobiliariasEscritorio: tese?.operacoesImobiliariasEscritorio ?? false, //Tipo de Imóvel das Operações Imobiliárias
	operacoesImobiliariasIndustrial: tese?.operacoesImobiliariasIndustrial ?? false, //Tipo de Imóvel das Operações Imobiliárias
	operacoesImobiliariasShopping: tese?.operacoesImobiliariasShopping ?? false, //Tipo de Imóvel das Operações Imobiliárias
	operacoesImobiliariasAgro: tese?.operacoesImobiliariasAgro ?? false, //Tipo de Imóvel das Operações Imobiliárias
	operacoesImobiliariasGalpaoLogistico: tese?.operacoesImobiliariasGalpaoLogistico ?? false, //Tipo de Imóvel das Operações Imobiliárias
	operacoesImobiliariasOutros: tese?.operacoesImobiliariasOutros ?? '', //Tipo de Imóvel das Operações Imobiliárias

	//Operações imobiliárias em cada estado
	operacoesImobiliariasAc: tese?.operacoesImobiliariasAc ?? false,
	operacoesImobiliariasAm: tese?.operacoesImobiliariasAm ?? false,
	operacoesImobiliariasRo: tese?.operacoesImobiliariasRo ?? false,
	operacoesImobiliariasTo: tese?.operacoesImobiliariasTo ?? false,
	operacoesImobiliariasAp: tese?.operacoesImobiliariasAp ?? false,
	operacoesImobiliariasPa: tese?.operacoesImobiliariasPa ?? false,
	operacoesImobiliariasRr: tese?.operacoesImobiliariasRr ?? false,
	operacoesImobiliariasAl: tese?.operacoesImobiliariasAl ?? false,
	operacoesImobiliariasBa: tese?.operacoesImobiliariasBa ?? false,
	operacoesImobiliariasCe: tese?.operacoesImobiliariasCe ?? false,
	operacoesImobiliariasMa: tese?.operacoesImobiliariasMa ?? false,
	operacoesImobiliariasPb: tese?.operacoesImobiliariasPb ?? false,
	operacoesImobiliariasPi: tese?.operacoesImobiliariasPi ?? false,
	operacoesImobiliariasPe: tese?.operacoesImobiliariasPe ?? false,
	operacoesImobiliariasRn: tese?.operacoesImobiliariasRn ?? false,
	operacoesImobiliariasSe: tese?.operacoesImobiliariasSe ?? false,
	operacoesImobiliariasDf: tese?.operacoesImobiliariasDf ?? false,
	operacoesImobiliariasGo: tese?.operacoesImobiliariasGo ?? false,
	operacoesImobiliariasMt: tese?.operacoesImobiliariasMt ?? false,
	operacoesImobiliariasMs: tese?.operacoesImobiliariasMs ?? false,
	operacoesImobiliariasEs: tese?.operacoesImobiliariasEs ?? false,
	operacoesImobiliariasMg: tese?.operacoesImobiliariasMg ?? false,
	operacoesImobiliariasRj: tese?.operacoesImobiliariasRj ?? false,
	operacoesImobiliariasSp: tese?.operacoesImobiliariasSp ?? false,
	operacoesImobiliariasSc: tese?.operacoesImobiliariasSc ?? false,
	operacoesImobiliariasPr: tese?.operacoesImobiliariasPr ?? false,
	operacoesImobiliariasRs: tese?.operacoesImobiliariasRs ?? false,

	operacoesImobiliariasInformacoesComplementares: tese?.operacoesImobiliariasInformacoesComplementares ?? '', //Informações Complementares de Operações Imobiliárias

	// STARK itens
	operacoesDividaRecuperacaoJudicial: tese?.operacoesDividaRecuperacaoJudicial ?? false, //Operações de dívida: Operam com empresas em Recuperação Judicial?
	garantiaBoletosDuplicatasCreditoConta: tese?.garantiaBoletosDuplicatasCreditoConta ?? false, //Garantia: Boletos/Duplicatas/Contratos: Aceitam crédito em conta?
	recebiveisImobiliariosEquitySpe: tese?.recebiveisImobiliariosEquitySpe ?? false, //Recebíveis Imobiliários (pulverizados): Entram com Equity em SPE?
	recebiveisImobiliariosMinimoEmpreendimento: tese?.recebiveisImobiliariosMinimoEmpreendimento ?? null, //Recebíveis Imobiliários (pulverizados): Mínimo do empreendimento comercializado para operação?
	operacoesImobiliariasRecuperacaoJudicial: tese?.operacoesImobiliariasRecuperacaoJudicial ?? false, //Operações Imobiliárias: Operam com empresas em Recuperação Judicial?

	faturamentoTomadorMaximo: tese?.faturamentoTomadorMaximo || 0, //Operações de Dívida: Faturamento máximo do tomador
	necessarioBalancosAuditados: tese?.necessarioBalancosAuditados || '', //Operações de Dívida: Necessário Balanços auditados

	// Garantia outros
	outrosEstoques: tese?.outrosEstoques ?? false,
	outrosAtivosLegais: tese?.outrosAtivosLegais ?? false,
	outrosMaquinasEquipamentos: tese?.outrosMaquinasEquipamentos ?? false,
	outtrosAcoesEquityDoProjeto: tese?.outtrosAcoesEquityDoProjeto ?? false,
	othersGuaranteesDetails: tese?.othersGuaranteesDetails ?? '',

	// garantiaVeiculos antes era relativo ao item outros na originação
	// por isso checamos se é outrosVeiculos (nova chave) ou garantiaVeiculos
	outrosVeiculos: tese?.outrosVeiculos || tese?.garantiaVeiculos || false,
	outrosOutros: tese?.outrosOutros ?? false,

	sector: tese?.sector || TESE_CREDIT_SECTORS.s000._id, // código do SECTORS_TESES_CREDIT
	vehicle: tese?.vehicle || TESE_CREDIT_VEHICLES.v000._id, // código do VEICULOS_CREDIT

	highGrade: tese?.highGrade ?? false,
	balancedRisk: tese?.balancedRisk ?? false,
	highYield: tese?.highYield ?? false,
	distressedAssets: tese?.distressedAssets ?? false,
	indexer: tese?.indexer || TESE_CREDIT_INDEXERS.i000._id,
	targetReturn: tese?.targetReturn || 0,

	//Perfil da transação desejada
	noRestrictionMaximumBilling: tese?.noRestrictionMaximumBilling ?? false,
	noRestrictionMinimumBilling: tese?.noRestrictionMinimumBilling ?? false,
	judicialRecovery: tese?.judicialRecovery || TESE_CREDIT_JUDICIAL_RECOVERY.j000._id,
	auditedBalance: tese?.auditedBalance || '',

	//Os produtos
	productsNoRestrictionMaximumValue: tese?.productsNoRestrictionMaximumValue ?? false,
	productsNoRestrictionMinimumValue: tese?.productsNoRestrictionMinimumValue ?? false,
	productsMinimumTermOfOperation: tese?.productsMinimumTermOfOperation || 0,
	productsMaximumTermOfOperation: tese?.productsMaximumTermOfOperation || 0,
	productsGuaranteeCoverageIndex: tese?.productsGuaranteeCoverageIndex || 0,

	//Carteira performada
	carteiraPerformadaOffice: tese?.carteiraPerformadaOffice ?? false, //comercial
	carteiraPerformadaResidential: tese?.carteiraPerformadaResidential ?? false, //residencial
	carteiraPerformadaSubdivision: tese?.carteiraPerformadaSubdivision ?? false, //loteamento
	carteiraPerformadaMultiProperties: tese?.carteiraPerformadaMultiProperties ?? false, //multipropriedades
	carteiraPerformadaGeographicFeaturesDetails: tese?.carteiraPerformadaGeographicFeaturesDetails || '', //detalhes
	carteiraPerformadaMandatoryAuditedSheets: tese?.carteiraPerformadaMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id, //balanço auditado

	//Término de obra
	terminoDeObraOffice: tese?.terminoDeObraOffice ?? false, //comercial
	terminoDeObraResidential: tese?.terminoDeObraResidential ?? false, //residencial
	terminoDeObraSubdivision: tese?.terminoDeObraSubdivision ?? false, //loteamento
	terminoDeObraMultiProperties: tese?.terminoDeObraMultiProperties ?? false, //multipropriedades
	terminoDeObraMall: tese?.terminoDeObraMall ?? false, //shopping
	terminoDeObraHotel: tese?.terminoDeObraHotel ?? false, //hotel
	terminoDeObraOthers: tese?.terminoDeObraOthers ?? false,
	terminoDeObraOtherKindsOfConstructions: tese?.terminoDeObraOtherKindsOfConstructions || '', //outros tipos de obra
	terminoDeObraConstructionCharacteristicsDetails: tese?.terminoDeObraConstructionCharacteristicsDetails || '', //detalhes sobre características das obras
	terminoDeObraMinimumSalesThreshold: tese?.terminoDeObraMinimumSalesThreshold || 0, //patamar minimo de vendas
	terminoDeObraCompletedWorks: tese?.terminoDeObraCompletedWorks || 0, //patamar maximo de vendas
	terminoDeObraExposicaoDeCaixa: tese?.terminoDeObraExposicaoDeCaixa ?? false,
	terminoDeObraWorkSchedule: tese?.terminoDeObraWorkSchedule ?? false, //cronograma de obra
	terminoDeObraMandatoryAuditedSheets: tese?.terminoDeObraMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,


	//CRI Corporativo
	corporateCriDetails: tese?.corporateCriDetails || '', //detalhers desta operação
	corporateCriMandatoryAuditedSheets: tese?.corporateCriMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	//Buy to lease
	buyToLeaseTypical: tese?.buyToLeaseTypical ?? false,
	buyToLeaseAtypical: tese?.buyToLeaseAtypical ?? false,
	buyToLeaseAssetCharacteristics: tese?.buyToLeaseAssetCharacteristics || '', //caracteristicas do ativo
	buyToLeaseRentContractCharacteristics: tese?.buyToLeaseRentContractCharacteristics || '', //caracteristicas do contrato de aluguel
	buyToLeaseMandatoryAuditedSheets: tese?.buyToLeaseMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	//Sale and leaseback
	saleAndLeasebackAssetCharacteristics: tese?.saleAndLeasebackAssetCharacteristics || '', //caracteristicas do ativo
	saleAndLeasebackAssetRepurchase: tese?.saleAndLeasebackAssetRepurchase ?? false, //caracteristicas do contrato de aluguel
	saleAndLeasebackMandatoryAuditedSheets: tese?.saleAndLeasebackMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	//Built to suit
	builtToSuitAssetCharacteristics: tese?.builtToSuitAssetCharacteristics || '', //caracteristicas do ativo
	builtToSuitMandatoryAssetRepurchase: tese?.builtToSuitMandatoryAssetRepurchase ?? false, //caracteristicas do contrato de aluguel
	builtToSuitPricesTerms: tese?.builtToSuitPricesTerms || '', //preços condições
	builtToSuitMandatoryAuditedSheets: tese?.builtToSuitMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	//CRI de aluguel
	criDeAluguelTypical: tese?.criDeAluguelTypical ?? false,
	criDeAluguelAtypical: tese?.criDeAluguelAtypical ?? false,
	criDeAluguelAssetCharacteristics: tese?.criDeAluguelAssetCharacteristics || '', //caracteristicas do ativo
	criDeAluguelRentContractCharacteristics: tese?.criDeAluguelRentContractCharacteristics || '', //caracteristicas do contrato de aluguel
	criDeAluguelMandatoryAuditedSheets: tese?.criDeAluguelMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	//Reembolso de despesas imobiliarias
	realEstateExpensesRefundDetails: tese?.realEstateExpensesRefundDetails || '', //detalhes
	realEstateExpensesMandatoryAuditedSheets: tese?.realEstateExpensesMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	/** Fii Agro CRA  */
	fiiAgroCRABullet: tese?.fiiAgroCRABullet ?? false,
	fiiAgroCRASazonalidade: tese?.fiiAgroCRASazonalidade ?? false,
	fiiAgroCRAOthers: tese?.fiiAgroCRAOthers ?? false,
	fiiAgroCRADetails: tese?.fiiAgroCRADetails || '',
	fiiAgroCRADfAuditada: tese?.fiiAgroCRADfAuditada || TESE_CREDIT_DF_AUDITADA.df000._id,

	/** Fii Agro CPR  */
	fiiAgroCPRBullet: tese?.fiiAgroCPRBullet ?? false,
	fiiAgroCPRSazonalidade: tese?.fiiAgroCPRSazonalidade ?? false,
	fiiAgroCPROthers: tese?.fiiAgroCPROthers ?? false,
	fiiAgroCPRDetails: tese?.fiiAgroCPRDetails || '',
	fiiAgroCPRDfAuditada: tese?.fiiAgroCPRDfAuditada || TESE_CREDIT_DF_AUDITADA.df000._id,

	/** Fii Agro CRP  */
	fiiAgroCRPBullet: tese?.fiiAgroCRPBullet ?? false,
	fiiAgroCRPSazonalidade: tese?.fiiAgroCRPSazonalidade ?? false,
	fiiAgroCRPOthers: tese?.fiiAgroCRPOthers ?? false,
	fiiAgroCRPDetails: tese?.fiiAgroCRPDetails || '',
	fiiAgroCRPDfAuditada: tese?.fiiAgroCRPDfAuditada || TESE_CREDIT_DF_AUDITADA.df000._id,

	/** Fii Agro CDAWA  */
	fiiAgroCDAWABullet: tese?.fiiAgroCDAWABullet ?? false,
	fiiAgroCDAWASazonalidade: tese?.fiiAgroCDAWASazonalidade ?? false,
	fiiAgroCDAWAOthers: tese?.fiiAgroCDAWAOthers ?? false,
	fiiAgroCDAWADetails: tese?.fiiAgroCDAWADetails || '',
	fiiAgroCDAWADfAuditada: tese?.fiiAgroCDAWADfAuditada || TESE_CREDIT_DF_AUDITADA.df000._id,

	/** Fii Agro CDCA  */
	fiiAgroCDCABullet: tese?.fiiAgroCDCABullet ?? false,
	fiiAgroCDCASazonalidade: tese?.fiiAgroCDCASazonalidade ?? false,
	fiiAgroCDCAOthers: tese?.fiiAgroCDCAOthers ?? false,
	fiiAgroCDCADetails: tese?.fiiAgroCDCADetails || '',
	fiiAgroCDCADfAuditada: tese?.fiiAgroCDCADfAuditada || TESE_CREDIT_DF_AUDITADA.df000._id,

	/** Fii Agro Debenture  */
	fiiAgroDebentureBullet: tese?.fiiAgroDebentureBullet ?? false,
	fiiAgroDebentureSazonalidade: tese?.fiiAgroDebentureSazonalidade ?? false,
	fiiAgroDebentureOthers: tese?.fiiAgroDebentureOthers ?? false,
	fiiAgroDebentureDetails: tese?.fiiAgroDebentureDetails || '',
	fiiAgroDebentureDfAuditada: tese?.fiiAgroDebentureDfAuditada || TESE_CREDIT_DF_AUDITADA.df000._id,

	/** Fii Agro Outros  */
	fiiAgroOutrosBullet: tese?.fiiAgroOutrosBullet ?? false,
	fiiAgroOutrosSazonalidade: tese?.fiiAgroOutrosSazonalidade ?? false,
	fiiAgroOutrosOthers: tese?.fiiAgroOutrosOthers ?? false,
	fiiAgroOutrosDetails: tese?.fiiAgroOutrosDetails || '',
	fiiAgroOutrosDfAuditada: tese?.fiiAgroOutrosDfAuditada || TESE_CREDIT_DF_AUDITADA.df000._id,

	/** FIDC Agro Antecipação de recebiveis  */
	fidcReceivablesAnticipationDetails: tese?.fidcReceivablesAnticipationDetails || '',
	fidcAgroReceivablesAnticipationAuditedSheets: tese?.fidcAgroReceivablesAnticipationAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	/** FIDC Agro aquisicao de recebiveis  */
	fidcReceivablesAquisitionDetails: tese?.fidcReceivablesAquisitionDetails || '',
	fidcAgroReceivablesAquisitionAuditedSheets: tese?.fidcAgroReceivablesAquisitionAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	/** Fii Agro CCB/NC  */
	fidcAgroCCBNCBullet: tese?.fidcAgroCCBNCBullet ?? false,
	fidcAgroCCBNCSazonalidade: tese?.fidcAgroCCBNCSazonalidade ?? false,
	fidcAgroCCBNCOthers: tese?.fidcAgroCCBNCOthers ?? false,
	fidcAgroCCBNCDetails: tese?.fidcAgroCCBNCDetails || '',
	fidcAgroCCBNCAuditedSheets: tese?.fidcAgroCCBNCAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,

	//Garantias recebiveis
	receivableGuaranteesPublicContracts: tese?.receivableGuaranteesPublicContracts ?? false, //contratos publicos
	receivableGuaranteesPrivateContracts: tese?.receivableGuaranteesPrivateContracts ?? false, //contratos privados
	receivableGuaranteesTicketsDuplicates: tese?.receivableGuaranteesTicketsDuplicates ?? false, //boletos duplicatas
	receivableGuaranteesOthers: tese?.receivableGuaranteesOthers ?? false,
	receivableGuaranteesDetails: tese?.receivableGuaranteesDetails || '',

	//Garantias recebiveis imobiliarios
	realEstateReceivablesRentContracts: tese?.realEstateReceivablesRentContracts ?? false, //contratos aluguel
	realEstateReceivablesOnThePlant: tese?.realEstateReceivablesOnThePlant ?? false, //imoveis na planta
	realEstateReceivablesReady: tese?.realEstateReceivablesReady ?? false, //imoveis prontos
	realEstateReceivablesOthers: tese?.realEstateReceivablesOthers ?? false,
	realEstateReceivablesDetails: tese?.realEstateReceivablesDetails || '',

	/** Desenvolvimento */
	desenvolvOthersProjects: tese?.desenvolvOthersProjects || '',
	desenvolvParticipateEquity: tese?.desenvolvParticipateEquity ?? false,
	desenvolvParticipateScp: tese?.desenvolvParticipateScp ?? false,
	desenvolvParticipateSpe: tese?.desenvolvParticipateSpe ?? false,
	desenvolvParticipateOthers: tese?.desenvolvParticipateOthers ?? false,
	desenvolvOtherKind: tese?.desenvolvOtherKind || '',
	desenvolvProjectCharacteristics: tese?.desenvolvProjectCharacteristics || '',
	desenvolvMandatoryAuditedSheets: tese?.desenvolvMandatoryAuditedSheets || TESE_CREDIT_MANDATORY_AUDITED_SHEETS.a000._id,
	greenField: tese?.greenField ?? false,
	brownField: tese?.brownField ?? false,
	desenvolvimentoOthers: tese?.desenvolvimentoOthers ?? false,

	/** Tech */
	/** permite titulos hibridos */
	techHybridTitles: tese?.techHybridTitles ?? false,
	institutionalInvestor: tese?.institutionalInvestor || TESE_CREDIT_INSTITUTIONAL_INVESTOR.ii000._id,
	techImpedimentoModeloReceita: tese?.techImpedimentoModeloReceita || TESE_CREDIT_IMPEDIMENTO_RECEITA.ir000._id,
	/** preferencia quanto ao uso do recurso */
	techResourceUsage: tese?.techResourceUsage || '',
	techSetoresNaoAtendidos: tese?.techSetoresNaoAtendidos || [],

	/** Legal Claim */
	/** Percentual a ser pago pela LC */
	legalClaimPercentageLc: tese?.legalClaimPercentageLc || 0,
	judicialAssetsPublic: tese?.judicialAssetsPublic ?? false,
	judicialAssetsPrivate: tese?.judicialAssetsPrivate ?? false,
	/** Aceita acoes coletivas/conjuntas */
	judicialAssetsPublicCollectiveActions: tese?.judicialAssetsPublicCollectiveActions ?? false,
	judicialAssetsPrivateCollectiveActions: tese?.judicialAssetsPrivateCollectiveActions ?? false,
	judicialAssetsPublicComercial: tese?.judicialAssetsPublicComercial ?? false,
	judicialAssetsPrivateComercial: tese?.judicialAssetsPrivateComercial ?? false,
	judicialAssetsPublicTributary: tese?.judicialAssetsPublicTributary ?? false,
	/** Trabalhista */
	judicialAssetsLabor: tese?.judicialAssetsLabor ?? false,
	/** Câmaras privadas */
	judicialAssetsPrivateChambers: tese?.judicialAssetsPrivateChambers ?? false,
	/** Caso faça privado, quais características não aceitam? */
	judicialAssetsCharactDontAccept: tese?.judicialAssetsCharactDontAccept || '',
	/** Qual o perfil de estágio mínimo do processo? */
	judicialAssetsMinimumStage: tese?.judicialAssetsMinimumStage || '',
	judicialAssetsTransitoEmJulgado: tese?.judicialAssetsTransitoEmJulgado ?? false,
	/** fazem crédito com garantia/lastro em ações judiciais?  */
	judicialAssetsGuaranteesInLawsuits: tese?.judicialAssetsGuaranteesInLawsuits ?? false,
	nplSingleNames: tese?.nplSingleNames ?? false,
	nplCarteiraPulverizada: tese?.nplCarteiraPulverizada ?? false,
	/** dividas bancarias  */
	nplBankDebts: tese?.nplBankDebts ?? false,
	/** dividas nao bancarias  */
	nplNonBankDebts: tese?.nplNonBankDebts ?? false,
	nplDescriptiveField: tese?.nplDescriptiveField || '',
	otherOperationsLitigationFinance: tese?.otherOperationsLitigationFinance ?? false,
	/** Operam com antecipação/compra/crédito de icms/pis/cofins  */
	otherOperationsAnticipationIcms: tese?.otherOperationsAnticipationIcms ?? false,
	otherOperationsOtherOperations: tese?.otherOperationsOtherOperations ?? false,
	otherOperationsOtherOperationsText: tese?.otherOperationsOtherOperationsText || '',
	otherOperationsOtherOperationsDescription: tese?.otherOperationsOtherOperationsDescription || '',

	/** FOF  */
	fof: tese?.fof ?? false,
	fundosExclusivos: tese?.fundosExclusivos ?? false,
	fundosJaExistentes: tese?.fundosJaExistentes ?? false,

	/** Agro  */
	operationsWithESGFiiAgro: tese?.operationsWithESG || TESE_CREDIT_OPERATION_ESG.o000._id,
	operationsWithESGFIDC: tese?.operationsWithESGFIDC || TESE_CREDIT_OPERATION_ESG.o000._id,

	/** Finalize sua tese  */
	clubDeal: tese?.clubDeal ?? false,
	levaAMercado: tese?.levaAMercado ?? false,
	fazBridge: tese?.fazBridge ?? false,

	guarantees: tese?.guarantees || []
})
