/**
 * A função `isCurado` verifica se um objeto teaser possui a propriedade `dataCuradoria`
 * clonando o objeto teaser e retornando um booleano baseado na presença de
 * a propriedade.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que provavelmente contém alguns dados relacionados a um teaser ou visualização de conteúdo. O
 * A função `isCurado` verifica se o objeto `teaser` possui uma propriedade chamada
 * `dataCuradoria` e retorna `true` se
 * @returns A função `isCurado` está retornando um valor booleano. Ele retorna `verdadeiro`
 * se o objeto `teaser` possui uma propriedade `dataCuradoria` que não é `null`,
 * `indefinido` ou uma string vazia. Caso contrário, retorna `false`.
 */
//CRIADO POR IA

import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'

export const isCurado = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	return !!cloneTeaser.dataCuradoria

}
