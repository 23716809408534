
import { REASON_CONTRACT_DONE } from '../../Constants/REASON_CONTRACT_DONE'
import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { modelTeaser } from '../Teaser/model/modelTeaser'
import { TypeViewTeasers001 } from './types/TypeViewTeasers001'

export const modelViewTeasers001 = (obj = {} as Partial<TypeViewTeasers001>): TypeViewTeasers001 => ({
	_id: obj?._id || '',
	nome: obj?.nome || '',
	dataStandby: obj?.dataStandby || 0,
	dataContrato: obj?.dataContrato || 0,
	dateContractEnd: obj?.dateContractEnd || 0,
	dateContractDone: obj?.dateContractDone || 0,
	dataPublicacao: obj?.dataPublicacao || 0,
	dataCriacao: obj?.dataCriacao || 0,
	dataModificacao: obj?.dataModificacao || 0,
	dataComercial: obj?.dataComercial || 0,
	dataCuradoria: obj?.dataCuradoria || 0,
	dataAjustes: obj?.dataAjustes || [],
	fromOffice: obj?.fromOffice || '',
	uid: obj?.uid || '',
	uidCoCreator: obj?.uidCoCreator || '',
	typeOperation: obj?.typeOperation || null,
	validation: obj?.validation || { dateMeet: modelTeaser().validation.dateMeet, promotedBy: modelTeaser().validation.promotedBy, approved: modelTeaser().validation.approved },
	statusCustomer: obj?.statusCustomer || '',
	createdByOffice: obj?.createdByOffice || false,
	uf: obj?.uf || '',
	online: obj?.online || false,
	tags: obj?.tags || [],
	origin: obj?.origin || '',
	createdBy: obj?.createdBy || '',
	subStatusOfActiveCostumer: obj?.subStatusOfActiveCostumer || SUB_STATUS_OF_ACTIVE_COSTUMER.s00050.code,
	site: obj?.site || '',
	fantasyName: obj?.fantasyName || '',
	urlLogo: obj?.urlLogo || '',
	publicationHistory: obj?.publicationHistory || [],
	isPublicado: obj?.isPublicado || false,
	reasonContractDone: obj?.reasonContractDone || REASON_CONTRACT_DONE.i000._id
})
