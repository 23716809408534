export const TESE_CREDIT_DF_AUDITADA_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_DF_AUDITADA

	if (!TESE_CREDIT_DF_AUDITADA[_code]) {
		return (TESE_CREDIT_DF_AUDITADA.df000)
	}

	return TESE_CREDIT_DF_AUDITADA[_code]
}

export const TESE_CREDIT_DF_AUDITADA = {
	df000: { label: '', _id: 'df000' },
	df001: { label: 'Preferencialmente', _id: 'df001' },
	df002: { label: 'Obrigatório', _id: 'df002' },
	df003: { label: 'Indiferente', _id: 'df003' },
} as const

export const TESE_CREDIT_DF_AUDITADA_VALUES = Object.values(TESE_CREDIT_DF_AUDITADA)
export const TESE_CREDIT_DF_AUDITADA_KEYS = Object.keys(TESE_CREDIT_DF_AUDITADA)
