import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const UppBiggerOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M29.0631 15.0871L17.6631 3.78711C17.5631 3.68711 17.3631 3.58711 17.2631 3.48711C16.9631 3.38711 16.5631 3.38711 16.2631 3.48711C16.0631 3.58711 15.9631 3.68711 15.8631 3.78711L4.66309 15.0871C4.16309 15.5871 4.16309 16.3871 4.66309 16.8871C5.16309 17.3871 5.96309 17.3871 6.46309 16.8871L15.6631 7.68711V27.2871C15.6631 27.5871 15.7631 27.9871 16.0631 28.1871C16.3631 28.3871 16.5631 28.5871 16.9631 28.5871C17.6631 28.5871 18.1631 27.9871 18.1631 27.3871V7.68711L27.3631 16.8871C27.8631 17.3871 28.6631 17.3871 29.1631 16.8871C29.4631 16.3871 29.4631 15.5871 29.0631 15.0871Z' fill='#272827' />
	</Svg>
)
