import { clone } from '../../Utils/clone'
import { TypeMatch } from '../types/match'
import { db5 } from '../../../Services/db5'

export const toggleMatchFirst = (idMatch: string, match: TypeMatch) => {

	const cloneIdMatch = clone(idMatch)
	const cloneMatch = clone(match)

	db5.matches.update({
		id: cloneIdMatch,
		doc: {
			matchFirst: !cloneMatch.matchFirst
		}
	})
}
