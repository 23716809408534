import { fnTeaser } from '../../../../../Functions/Teaser'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreGov = (props: TypeScoreProps) => {
	const res: TypeScoreStark['scoreItens'] = []

	const gov = fnTeaser.getGovernanceScore(props.teaser)

	const devolutiva1 = 'Converse com o empresário sobre melhorias nos ritos de governança da empresa, inclusive auditando balanços.'
	const devolutiva2 = 'Explique ao empresário que o nível de informalidade da empresa exige maiores garantias e reduz a chance de êxito da captação no mercado.'

	if (gov !== 'A')
		res.push({
			'pontosMax': 0,
			'pontos': 0,
			'pontosCor': 'red',
			'setor': 'Aviso de Governança',
			'item': 'Aviso de Governança',
			'itemDescrição': 'Não possui balanço auditado',
			'metaDados': '',
			'devolutiva': devolutiva1
		})

	if (gov === 'C')
		res.push({
			'pontosMax': 0,
			'pontos': 0,
			'pontosCor': 'red',
			'setor': 'Aviso de Governança',
			'item': 'Aviso de Governança',
			'itemDescrição': 'Governança nível C',
			'metaDados': '',
			'devolutiva': devolutiva2
		})

	return res
}
