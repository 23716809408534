export const TESE_CREDIT_JUDICIAL_RECOVERY_GET = (code: string) => {
	const _code = code as keyof typeof TESE_CREDIT_JUDICIAL_RECOVERY

	if (!TESE_CREDIT_JUDICIAL_RECOVERY[_code]) {
		return (TESE_CREDIT_JUDICIAL_RECOVERY.j000)
	}

	return TESE_CREDIT_JUDICIAL_RECOVERY[_code]
}

export const TESE_CREDIT_JUDICIAL_RECOVERY = {
	j000: { label: 'Indiferente', _id: 'j000' },
	j001: { label: 'Sim, apenas recuperação judicial', _id: 'j001' },
	j002: { label: 'Não, apenas sem recuperação judicial', _id: 'j002' },
} as const

export const TESE_CREDIT_JUDICIAL_RECOVERY_VALUES = Object.values(TESE_CREDIT_JUDICIAL_RECOVERY)
export const TESE_CREDIT_JUDICIAL_RECOVERY_KEYS = Object.keys(TESE_CREDIT_JUDICIAL_RECOVERY)
