/**
 * Esta função verifica se um teaser está em estado ativo sem uma correspondência específica.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é do tipo `TypeTeaser`,
 * que é um tipo personalizado definido em outra parte da sua base de código. Provavelmente contém
 * informações relacionadas a um teaser ou conteúdo promocional, como título,
 * descrição, imagem, etc.
 * @returns A função `inAtivoWithoutMatch` está retornando um valor booleano. Isto
 * verifica se o objeto `teaser` passado como parâmetro atende a certas condições:
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'

export const inAtivoWithoutMatch = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const subStatusOfActiveCostumerInAtivoWithoutMatch:
		(keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER)[] = [
			SUB_STATUS_OF_ACTIVE_COSTUMER.s00050.code,
			SUB_STATUS_OF_ACTIVE_COSTUMER.s00100.code,
		]

	return inAtivo(cloneTeaser) &&
		subStatusOfActiveCostumerInAtivoWithoutMatch.includes(cloneTeaser.subStatusOfActiveCostumer)


}
