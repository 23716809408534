import { clone } from '../../Utils/clone'
import { TypeMatch } from '../types/match'
import { db5 } from '../../../Services/db5'

/**
 * Responsável por atualizar um documento de um match
 * @param match
 * @param document
 * @returns match
 */
export const updateDocument = async ({ match, document, id }: { id: string, match: TypeMatch, document: TypeMatch['documents'][0] }) => {
	const cloneMatch = clone(match)
	const cloneDocument = clone(document)

	const { _id } = cloneMatch
	cloneMatch.documents = cloneMatch.documents.map(_document => (_document._id === id) ? cloneDocument : _document)
	await db5.matches.update({ id: _id, doc: { documents: cloneMatch.documents } })
	return cloneMatch
}
