import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { deteleAttachedFileTeaser } from '../../../Services/teaser/deteleAttachedFileTeaser'
import { db5 } from '../../../Services/db5'

/**
 * Método responsável por remover um item do array de teasers anexados
 * @param id: id do teaser anexado
 * @param teaser: teaser
 */
export const removeAttachedTeaser = async ({ id, teaser }: { id: string, teaser: TypeTeaser }) => {

	const cloneId = clone(id)
	const cloneTeaser = clone(teaser)

	const _teaser = { ...cloneTeaser, attachedTeasers: { ...cloneTeaser.attachedTeasers } }
	const { _id } = cloneTeaser

	_teaser.attachedTeasers = cloneTeaser.attachedTeasers.filter(attachedTeaser => attachedTeaser._id !== cloneId)

	await db5.teasers.update({ id: _id, doc: _teaser })
	deteleAttachedFileTeaser({ path: `teasers/${_id}/attached-teasers/`, key: cloneId })

	return _teaser
}
