import Lottie from 'react-lottie'
import { useContextAuth } from '../../App/ContextAuth'
import { useGlobalContext } from '../../App/Global'
import { UiButton } from '../../ComponentsUi/UiButton'
import manInterrogation from '../../ComponentsUi/Lottie/manInterrogation.json'
import React from 'react'
import { UiTextArea } from '../../ComponentsUi/UiTextArea'
import { snaps2 } from '../../Services/snaps'
import { fnUser } from '../../Functions/User'
import { TypeUser } from '../../Functions/User/types/TypeUser'
import { helperCheckDate } from './Functions/helperCheckDate'

const returnTrueInPercentTimes = (percentTimes: number) => {
	const chance = Math.random()
	if (chance <= percentTimes) return true
	return false
}

export const useModelNPS = () => {

	const config: {
		[codinome in '' | 'customer' | 'ri' | 'canais' | 'operações' | 'office' | 'officeAnalyst' | 'agent' | 'ep']: {
			percent: number
			listEmail: string[]
			pergunta: string
		}
	} = {
		['']: {
			percent: 0.0,
			pergunta: '',
			listEmail: [],
		},
		agent: {
			percent: 0.05,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: [],
		},
		ep: {
			percent: 0.05,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: [],
		},
		office: {
			percent: 0.05,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: [],
		},
		officeAnalyst: {
			percent: 0.05,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: [],
		},
		customer: {
			percent: 0.05,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: [],
		},
		ri: {
			percent: 0.02,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: ['gabriel.duarte@stark.money'],
		},
		canais: {
			percent: 0.99,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: ['bruna.haut@stark.money', 'luiz.correia@stark.money', 'jhenifer.vasconcelos@stark.money', 'fausto.cabral@stark.money'],
		},
		operações: {
			percent: 0.02,
			pergunta: 'Você está satisfeito com nossa plataforma e recomendaria para outras pessoa?',
			listEmail: [
				'gsp@stark-capital.com.br',
				'gualadm@gmail.com',
				'gsp@stark.money',
				'bruno.vieira@stark.money',
				'giovanne.rolim@stark.money',
				'hb@stark.money',
				'ph.lemos@stark.money',
				'pedro.rocha@stark.money',
				'mateus.galdino@stark.money',
				'gustavo.bohrer@stark.money',
				'gabriel.brandalise@stark.money',
				'vinicius.brassiani@stark.money',
				'maicon.sousa@stark.money',
				'luis.uliana@stark.money',
			],
		}
	}

	const { globalAuth } = useContextAuth()
	const profile = globalAuth?.profile


	const { setGlobalModal } = useGlobalContext()

	const Modal = ({ pergunta, profile }: { pergunta: string, profile: TypeUser }) => {
		const [nps, setNps] = React.useState<number | null>(null)
		const [melhoria, setMelhoria] = React.useState<string>('')

		const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
			const value = e.currentTarget.innerHTML
			setNps(Number(value))
		}

		const handleClickSubmit = () => {
			snaps2.npsAnswer(profile, (nps || 0), melhoria)
			localStorage.setItem('starkLastNpsAnswer', new Date().getTime().toString())
			handleClickClose()
		}

		const handleClickClose = () => {
			setGlobalModal(previous => ({ ...previous, show: false }))
		}

		React.useEffect(() => {
			if (profile._id) {
				snaps2.npsView(profile)
				localStorage.setItem('starkLastNpsView', new Date().getTime().toString())
			}
		}, [profile])

		return (<div className='tw-p-4 tw-flex tw-flex-col tw-items-center'>
			<Lottie
				options={{
					loop: true,
					autoplay: true,
					animationData: manInterrogation,
					rendererSettings: {
						preserveAspectRatio: 'xMidYMid slice'
					}
				}}
				height={150}
				width={150}
			/>

			<div className='tw-mb-4 tw-text-lg tw-font-bold tw-text-center'> {pergunta} </div>

			<div>
				<div className='tw-flex tw-justify-center tw-items-center tw-gap-2'>
					<div onClick={handleClick} className={`tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-bg-[#fc6801] tw-rounded-md tw-font-bold tw-text-xl md:tw-text-2xl hover:tw-drop-shadow-md hover:tw-brightness-125 ${nps === 1 ? ' tw-border-4 tw-border-solid tw-border-black' : ''}`}>1</div>
					<div onClick={handleClick} className={`tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-bg-[#fe9b03] tw-rounded-md tw-font-bold tw-text-xl md:tw-text-2xl hover:tw-drop-shadow-md hover:tw-brightness-125 ${nps === 2 ? ' tw-border-4 tw-border-solid tw-border-black' : ''}`}>2</div>
					<div onClick={handleClick} className={`tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-bg-[#fdcd02] tw-rounded-md tw-font-bold tw-text-xl md:tw-text-2xl hover:tw-drop-shadow-md hover:tw-brightness-125 ${nps === 3 ? ' tw-border-4 tw-border-solid tw-border-black' : ''}`}>3</div>
					<div onClick={handleClick} className={`tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-bg-[#c0ca05] tw-rounded-md tw-font-bold tw-text-xl md:tw-text-2xl hover:tw-drop-shadow-md hover:tw-brightness-125 ${nps === 4 ? ' tw-border-4 tw-border-solid tw-border-black' : ''}`}>4</div>
					<div onClick={handleClick} className={`tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-bg-[#0dca00] tw-rounded-md tw-font-bold tw-text-xl md:tw-text-2xl hover:tw-drop-shadow-md hover:tw-brightness-125 ${nps === 5 ? ' tw-border-4 tw-border-solid tw-border-black' : ''}`}>5</div>
				</div>

				<div className='tw-flex tw-justify-between tw-text-gray-500 tw-w-full'>
					<div>Ruim</div>
					<div>Ótimo</div>
				</div>
			</div>

			{(nps !== null) && <>
				<div className='tw-mb-4 tw-text-lg tw-font-bold tw-text-center tw-mt-8'>Como podemos melhorar?</div>
				<UiTextArea value={melhoria} onChange={e => setMelhoria(e.target.value)} placeholder='Digite aqui sua sugestão' />
			</>}

			<div className='tw-mt-8 tw-w-full'>
				{nps !== null && <UiButton className='tw-w-full' variant='primary' onClick={handleClickSubmit}>Responder Pesquisa</UiButton>}
				<UiButton className='tw-w-full tw-mt-2' variant='white' onClick={handleClickClose}>Não quero participar agora</UiButton>
			</div>

		</div>)
	}

	const run = (profile: TypeUser) => {

		let npsGrupo: keyof typeof config = ''

		if (fnUser.isCustomer(profile)) (npsGrupo = 'customer')
		if (fnUser.isOffice(profile)) (npsGrupo = 'office')
		if (fnUser.isOfficeAnalyst(profile)) (npsGrupo = 'officeAnalyst')
		if (fnUser.isAgent(profile)) (npsGrupo = 'agent')
		if (fnUser.isEnterprise(profile)) (npsGrupo = 'ep')

		if (fnUser.isAdm(profile) && config.ri.listEmail.includes(profile?.email || '')) (npsGrupo = 'ri')
		if (fnUser.isAdm(profile) && config.canais.listEmail.includes(profile?.email || '')) (npsGrupo = 'canais')
		if (fnUser.isAdm(profile) && config.operações.listEmail.includes(profile?.email || '')) (npsGrupo = 'operações')

		// console.log(config[npsGrupo].percent, localStorage.getItem('starkLastNpsView'), localStorage.getItem('starkLastNpsAnswer'), helperCheckDate(localStorage.getItem('starkLastNpsView'), localStorage.getItem('starkLastNpsAnswer')))

		if (
			helperCheckDate(localStorage.getItem('starkLastNpsView'), localStorage.getItem('starkLastNpsAnswer')) && // 2 dias após visualizar OU 15 dias após responder
			config[npsGrupo] && // Existe a configuração ?
			returnTrueInPercentTimes(config[npsGrupo].percent) && // Passou na chance de aparecer ?
			(config[npsGrupo].listEmail.length ? config[npsGrupo].listEmail.includes(profile?.email || '') : true) // Caso seja necessário um email, ele está na lista ?
		) {

			setGlobalModal({
				width: 500,
				show: true,
				paddingBox: false,
				child: <Modal profile={profile} pergunta={config[npsGrupo].pergunta} />,
			})
		}
	}

	return { run }
}
