import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const ConstructionFilled = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M12.17 9.7002L5 14.4802V25.0001H9.99998V17.0002H14V25.0001H18.9999V14.2502L12.17 9.7002Z' fill='#272827' />
		<path d='M14 7V8.50999L16 9.83999L17.73 11H19V11.85L21 13.19V15H23V17H21V19H23V20.9999H21V24.9999H26.9999V7H14ZM23 13H21V11H23V13Z' fill='#272827' />
	</Svg>
)
