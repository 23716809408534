/**
 * A função `inAtivoWithNegotiation` verifica se um teaser está em estado ativo
 *com negociação.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é um objeto do tipo
 * `TypeTeaser`, que é usado como entrada para a função `inAtivoWithNegotiation`.
 *Este objeto provavelmente contém informações relacionadas a um teaser, como seu status
 * e outros dados relevantes. A função executa algumas operações neste
 * @returns A função `inAtivoWithNegotiation` está retornando um valor booleano. Isto
 * verifica se o objeto `teaser` passado como argumento tem um
 * Propriedade `subStatusOfActiveCostumer` que corresponde a qualquer um dos códigos especificados em
 * a matriz `subStatusOfActiveCostumerInNegotiation`. A função também verifica se
 * o objeto `teaser` satisfaz a condição do
 */
//CRIADO POR IA

import { SUB_STATUS_OF_ACTIVE_COSTUMER } from '../../../Constants/SUB_STATUS_OF_ACTIVE_COSTUMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'
import { inAtivo } from './inAtivo'

export const inAtivoWithNegotiation = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	const subStatusOfActiveCostumerInNegotiation: (keyof typeof SUB_STATUS_OF_ACTIVE_COSTUMER)[] = [
		SUB_STATUS_OF_ACTIVE_COSTUMER.s00300.code,
		SUB_STATUS_OF_ACTIVE_COSTUMER.s00400.code,
		SUB_STATUS_OF_ACTIVE_COSTUMER.s00500.code
	]

	return inAtivo(cloneTeaser) && subStatusOfActiveCostumerInNegotiation.includes(cloneTeaser.subStatusOfActiveCostumer)
}
