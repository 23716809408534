import { masks } from 'Functions/Utils/masks'
import React from 'react'
import { IUiInput } from '..'

export const onChangePhone = (props: IUiInput) =>
	(event: React.ChangeEvent<HTMLInputElement>) => {
		const value = masks.Phone(event.target.value, props.country).format
		event.target.value = value
		props.onChange && props.onChange(event)
	}
