import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const TrophyOutlined = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 33 32'} {...props}>
		<path d='M27.7608 6.88H25.5046V6.4C25.5046 5.08 24.4892 4 23.2483 4H8.80834C7.5674 4 6.55209 5.08 6.55209 6.4V6.88H4.28457C3.04363 6.88 2.02832 7.96 2.02832 9.28V11.2C2.02832 14.116 4.25072 16.48 6.99206 16.48H7.12743C8.30068 19.948 11.1887 22.552 14.6859 23.092V25.12H12.4296C11.685 25.12 11.0759 25.768 11.0759 26.56C11.0759 27.352 11.685 28 12.4296 28H19.6496C20.3942 28 21.0033 27.352 21.0033 26.56C21.0033 25.768 20.3942 25.12 19.6496 25.12H17.3933V23.092C20.8792 22.552 23.8011 20.008 24.9743 16.48H25.0646C27.8059 16.48 30.0283 14.116 30.0283 11.2V9.28C30.017 7.96 29.013 6.88 27.7608 6.88ZM4.73581 11.2V9.76H6.55209V13.012C6.55209 13.192 6.55209 13.372 6.56337 13.552C5.50294 13.336 4.73581 12.352 4.73581 11.2ZM15.9832 20.32C12.283 20.296 9.27087 17.008 9.27087 13.012V6.88H22.8083V13.12C22.8083 17.092 19.7737 20.32 16.0396 20.32C16.017 20.32 15.9945 20.32 15.9832 20.32ZM27.3095 11.2C27.3095 12.34 26.5537 13.324 25.4933 13.552C25.5045 13.408 25.5046 13.264 25.5046 13.12V9.76H27.3095V11.2Z' fill='#272827' />
	</Svg>
)


