import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeDeleteUser = {
	uid: string
}

type DeleteUserResponse = {
	alvo1: string
}

export const _deleteUser = (input: TypeDeleteUser): Promise<{ data: DeleteUserResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'deleteUser', input })
