/**
 * A função `inStandby` verifica se um teaser está em status de espera com base em seu
 * data de publicação e status do cliente.
 * @param {TypeTeaser} teaser - O parâmetro `teaser` é um objeto do tipo
 * `TypeTeaser`, que provavelmente contém informações sobre um teaser, como seus dados
 * publicação e status do cliente. A função `inStandby` verifica se o teaser
 * está em status de espera com base na publicação de dados e no valor do status do cliente.
 * @returns A função `inStandby` está retornando um valor booleano. Ele verifica se o
 * O objeto `teaser` possui uma propriedade `dataPublicacao` e se o `statusCustomer`
 * propriedade do objeto `teaser` é igual ao valor de
 * `STATUS_CUSTOMER.standby.value`. Se ambas as condições forem atendidas, ele retornará `true`,
 * caso contrário, retorna `false`.
 */
//CRIADO POR IA

import { STATUS_CUSTOMER } from '../../../Constants/STATUS_CUSTOMER'
import { clone } from '../../../Functions/Utils/clone'
import { TypeTeaser } from '../types/teaser'


export const inStandby = (teaser: TypeTeaser) => {

	const cloneTeaser = clone(teaser)

	// return !!cloneTeaser.dataPublicacao && (cloneTeaser.statusCustomer === STATUS_CUSTOMER.standby.value)
	return !!cloneTeaser.dataPublicacao && cloneTeaser.statusCustomer === STATUS_CUSTOMER.standby.value
}
