import { TypeTeaser } from '../types/teaser'
import { getLastDate } from './getLastDate'
import { inAtivo } from './inAtivo'
/**
 * Ordena um array de teasers pelo status mais atual
 * @param teasers
 * @param order
 * @returns  TypeTeaser[]
 */
export const sortByStatus = ({ teasers, order }: { teasers: TypeTeaser[], order: 'asc' | 'desc' }): TypeTeaser[] =>
	teasers.sort((teaserA: TypeTeaser, teaserB: TypeTeaser) => {
		const isAsc = order === 'asc'
		const dateA = getLastDate(teaserA)
		const dateB = getLastDate(teaserB)
		const subStatusOfActiveCostumerA = teaserA.subStatusOfActiveCostumer
		const subStatusOfActiveCostumerB = teaserB.subStatusOfActiveCostumer

		if (inAtivo(teaserA) || inAtivo(teaserB)) {
			if (isAsc && (subStatusOfActiveCostumerA > subStatusOfActiveCostumerB)) return -1
			if (isAsc && (subStatusOfActiveCostumerA === subStatusOfActiveCostumerB && dateA > dateB)) return -1
			if (isAsc) return 1

			if (subStatusOfActiveCostumerA < subStatusOfActiveCostumerB) return -1
			if (subStatusOfActiveCostumerA === subStatusOfActiveCostumerB && dateA < dateB) return -1
			return 1
		}

		return isAsc ? dateB - dateA : dateA - dateB
	})
