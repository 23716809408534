import React from 'react'
import styled from 'styled-components'
import { IconBase } from './IconBase'
import { IProps } from './IconBase'

const Svg = styled(IconBase)``

/** @deprecated */
export const Warning = ({ ...props }: IProps) => (
	<Svg viewBox={'0 0 32 32'} {...props}>
		<path d='M29.5509 23.2927L19.2216 4.90244C18.6228 3.95122 17.8743 3.31707 16.9761 3.15854C15.9282 2.84146 15.03 3 14.1318 3.63415C13.6827 3.95122 13.2336 4.42683 12.9342 4.90244L2.45517 23.2927C2.00607 24.4024 1.85637 25.3537 2.15577 26.3049C2.45517 27.2561 2.67765 27.8888 3.80247 28.6829C4.25157 29 5.00007 29 5.59887 29H26.4072C28.503 29 30 27.2561 30 25.1951C30 24.4024 29.8503 23.9268 29.5509 23.2927ZM27.3692 25.5333C27.3692 26.1675 27.006 26.622 26.4072 26.622H5.59887C5.14977 26.622 4.64765 26.5096 4.40127 25.9878C4.25157 25.6707 4.25157 25.1951 4.40127 24.7195L14.8803 6.17073C15.1797 5.69512 15.4791 5.53659 15.9282 5.53659C16.3773 5.53659 16.8264 5.85366 16.9761 6.17073L27.1557 24.561C27.3692 25.1 27.3692 25.2163 27.3692 25.5333Z' fill='#272827' />
		<path d='M16.0775 11.0854C15.4787 11.0854 14.8799 11.7196 14.8799 12.3537V17.4269C14.8799 18.0611 15.4787 18.6952 16.0775 18.6952C16.6763 18.6952 17.2751 18.0611 17.2751 17.4269V12.3537C17.2751 11.7196 16.6763 11.0854 16.0775 11.0854Z' fill='#272827' />
		<path d='M16.0775 21.2314C15.4787 21.2314 14.8799 21.8656 14.8799 22.4997C14.8799 23.1339 15.4787 23.768 16.0775 23.768C16.6763 23.768 17.2751 23.1339 17.2751 22.4997C17.2751 21.8656 16.6763 21.2314 16.0775 21.2314Z' fill='#272827' />
	</Svg>
)
