import { STATUS_MATCH } from '../../../Constants/STATUS_MATCH'
import { STATUS_MATCH_MAKING } from '../../../Constants/STATUS_MATCH_MAKING'
import { fnNotification } from '../../../Functions/Notification'
import { minhaData } from '../../../Functions/Utils/minhaData'
import { TypeInstitution } from '../../Institution/types/institution'
import { TypeMatch } from '../types/match'
import { TypeTeaser } from '../../Teaser/types/teaser'
import { TypeTese } from '../../Tese/types/tese'
import { TypeUser } from '../../User/types/TypeUser'
import { setMatch } from './setMatch'
import { getHighestStatusFromMatches } from '../../../Services/cloudfunctions'
import { clone } from '../../../Functions/Utils/clone'

// Pre Match
export const toPreMatch = async ({
	matchId,
	match,
	teaser,
	teaserId,
	profile,
	institution,
	institutionId,
	uid,
	tese,
	teseId,
	byCustomer
}: {
	matchId: string
	match: TypeMatch
	teaser: TypeTeaser
	teaserId: string
	profile: TypeUser
	institution: TypeInstitution
	institutionId: string
	uid: string
	tese: TypeTese
	teseId: string
	byCustomer?: boolean
}) => {

	const cloneMatchId = clone(matchId)
	const cloneMatch = clone(match)
	const cloneTeaser = clone(teaser)
	const cloneTeaserId = clone(teaserId)
	const cloneProfile = clone(profile)
	const cloneInstitution = clone(institution)
	const cloneInstitutionId = clone(institutionId)
	const cloneUid = clone(uid)
	const cloneTese = clone(tese)
	const cloneTeseId = clone(teseId)
	const cloneByCustomer = clone(byCustomer)

	const getHighestStatus = async () => {
		await getHighestStatusFromMatches({
			'id': cloneTeaserId
		})
	}

	setMatch({
		id: cloneMatchId,
		match: {
			...cloneMatch,
			status: STATUS_MATCH.preMatch.value,
			matchMakingStatus: STATUS_MATCH_MAKING.beforeMatchMaking.value,
			matchStatus: '',
			byCustomer: cloneByCustomer || false,
			datePrematch: minhaData()
		}
	}).then(() => {
		getHighestStatus()
	})

	fnNotification.customer.sendMatchAccepted({
		origination: cloneTeaser,
		originationId: cloneTeaserId,
		profile: cloneProfile,
		uid: cloneUid,
		institution: cloneInstitution,
		institutionId: cloneInstitutionId,
		tese: cloneTese,
		teseId: cloneTeseId,
		matchId: cloneMatchId,
		match: cloneMatch,
	})
}
