import { useState } from 'react'
import { Title } from 'Components/Title'
import { startModalLoading } from 'Components/Modal'
import { minhaData } from 'Functions/Utils/minhaData'
import { createBda } from 'Services/cloudfunctions'
import { USERS } from 'Constants/TYPE_USER'
import { fnUser } from 'Functions/User'
import { LayoutBody } from 'Components/Layout/LayoutBody'
import { LayoutTopBar } from 'Components/Layout/LayoutTopBar'
import { UiButton } from 'ComponentsUi/UiButton'
import { UiCard } from 'ComponentsUi/UiCard'
import { UiCardPadding } from 'ComponentsUi/UiCardPadding'
import { UiInput } from 'ComponentsUi/UiInput'
import { USERS_CONST } from 'Constants/TYPE_USER'

export default () => {

	const [nome, setNome] = useState('')
	const [email, setEmail] = useState('')
	const [cidade, setCidade] = useState('')
	const [telefone, setTelefone] = useState('')
	const [pass, setPass] = useState('')
	const [pass2, setPass2] = useState('')
	const [uidBdaFather, setUidBdaFather] = useState('')
	const [user, setUser] = useState('bda')
	const [uidOffice, setUidOffice] = useState('')

	const [cardSuccess, setCardSuccess] = useState('')

	const generateProfile = () => {
		if (user === 'bda') {
			return fnUser.modelUser({
				tipo: USERS.bda.code,
				nome,
				email,
				cidade,
				telefone,
				emailcontato: email,
				dataCriacao: minhaData(),
				status: 'true',
				bdaativado: true,
			})
		}
		if (user === 'microBda') {
			return fnUser.modelUser({
				tipo: USERS.microBda.code,
				nome,
				email,
				cidade,
				telefone,
				emailcontato: email,
				dataCriacao: minhaData(),
				status: 'true',
				uidBdaFather: uidBdaFather
			})
		}
		if (user === 'ep') {
			return fnUser.modelUser({
				tipo: USERS_CONST.enterprise._id, //TODO: usar constante nova e apontar para EP
				nome,
				email,
				cidade,
				telefone,
				emailcontato: email,
				dataCriacao: minhaData(),
				status: 'true',
				bdaativado: true,
			})
		}
		if (user === 'office') {
			return fnUser.modelUser({
				tipo: USERS.office.code,
				nome,
				email,
				cidade,
				telefone,
				emailcontato: email,
				dataCriacao: minhaData(),
				status: 'true',
			})
		}
		if (user === 'agenteOffice') {
			return fnUser.modelUser({
				tipo: USERS.bda.code,
				nome,
				email,
				cidade,
				telefone,
				emailcontato: email,
				dataCriacao: minhaData(),
				status: 'true',
				bdaativado: true,
				fromOffice: uidOffice,
			})
		}
		if (user === 'officeAnalyst') {
			return fnUser.modelUser({
				tipo: USERS.officeAnalyst.code,
				nome,
				email,
				cidade,
				telefone,
				dataCriacao: minhaData(),
				status: 'true',
				fromOffice: uidOffice,
			})
		}
		return 'vazio'
	}

	const handleSignUp = () => {
		startModalLoading(async (success, error) => {
			createBda({
				nome,
				pass,
				email,
				profile: generateProfile()
			})
				.then(e => {
					if (!e?.data?.errorInfo?.message) {
						setCardSuccess({
							nome: nome,
							pass: pass,
							email: email
						})
						alert(`Conta criada com sucesso usando o e-mail ${email} e senha: ${pass}`)
						success()
					}
					else {
						setCardSuccess({
							pass: e?.data?.errorInfo?.message
						})
						success()
					}
				})
				.catch(e => {
					// eslint-disable-next-line no-console
					// console.log(e)
					error()
				})
		}
		)
	}

	const isReadyInputs = nome && email && pass && pass2 && (pass === pass2) &&
		(
			user === 'bda' ||
			user === 'office' ||
			(user === 'agenteOffice' && !!uidOffice) ||
			(user === 'officeAnalyst' && !!uidOffice) ||
			user === 'ep' ||
			(user === 'microBda' && !!uidBdaFather)
		)

	return (
		<>

			<LayoutTopBar path={['Criar usuário']} />

			<LayoutBody>
				<UiCard style={{ maxWidth: 400, margin: 'auto' }}>
					<UiCardPadding className='tw-flex tw-flex-col tw-gap-4'>
						<Title title='Usuário' description='Crie contas para de usuários' />

						<div className='tw-flex tw-flex-col'>
							<UiInput label='Nome' value={nome} onChange={e => setNome(e.target.value)} />
							<div className='tw-pb-4'></div>
							<UiInput label='E-mail' value={email} onChange={e => setEmail(e.target.value)} />
							<div className='tw-pb-4'></div>
							<UiInput label='Cidade' value={cidade} onChange={e => setCidade(e.target.value)} />
							<div className='tw-pb-4'></div>
							<UiInput label='Telefone' value={telefone} onChange={e => setTelefone(e.target.value)} />
							<div className='tw-pb-4'></div>
							<UiInput label='Senha' value={pass} onChange={e => setPass(e.target.value)} />
							<div className='tw-pb-4'></div>
							<UiInput label='Repita a senha' value={pass2} onChange={e => setPass2(e.target.value)} />
						</div>

						<hr />
						<UiButton className='tw-w-full' onClick={() => setUser('bda')} variant={user !== 'bda' ? 'default' : 'primary'}>Agente</UiButton>
						<UiButton className='tw-w-full' onClick={() => setUser('ep')} variant={user !== 'ep' ? 'default' : 'primary'}>EP</UiButton>
						<UiButton className='tw-w-full' onClick={() => setUser('microBda')} variant={user !== 'microBda' ? 'default' : 'primary'}>Assessor EP</UiButton>
						{user === 'microBda' && <UiInput label='A qual EP ele pertence?' placeholder='Insira um id de usuário' value={uidBdaFather} onChange={e => setUidBdaFather(e.target.value)} />}
						<UiButton className='tw-w-full' onClick={() => setUser('office')} variant={user !== 'office' ? 'default' : 'primary'}>Office</UiButton>
						<UiButton className='tw-w-full' onClick={() => setUser('agenteOffice')} variant={user !== 'agenteOffice' ? 'default' : 'primary'}>Agente Office</UiButton>
						<UiButton className='tw-w-full' onClick={() => setUser('officeAnalyst')} variant={user !== 'officeAnalyst' ? 'default' : 'primary'}>Analista Office</UiButton>
						{(user === 'agenteOffice' || user === 'officeAnalyst') && <UiInput label='A qual Office ele pertence?' placeholder='Insira um id de usuário' value={uidOffice} onChange={e => setUidOffice(e.target.value)} />}


						<hr />
						<UiButton className='tw-w-full' onClick={handleSignUp} disabled={isReadyInputs !== '' ? !isReadyInputs : true}>Criar</UiButton>
					</UiCardPadding>
				</UiCard>

				<UiCard style={{ maxWidth: 400, margin: 'auto', marginTop: 50, textAlign: 'center' }}>
					<UiCardPadding>
						<div>Envie os dados de acesso para o BDA.<br />E instrua ele a alterar a senha assim que acessar o sistema</div>

						<h3>{cardSuccess?.email}</h3>
						<h3><b>{cardSuccess?.pass}</b></h3>
					</UiCardPadding>
				</UiCard>
			</LayoutBody>
		</>
	)
}

