import React from 'react'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useHistory } from 'react-router-dom'
import { LogoStark } from 'Components/Layout/LogoStark'
import { fnUser } from 'Functions/User'
import { menuAdm } from './menuAdm'
import { menuAgent } from './menuAgent'
import { menuOffice } from './menuOffice'
import { menuOfficeAnalyst } from './menuOfficeAnalyst'
import { menuCustomer } from './menuCustomer'
import { generateUniqueId } from 'Functions/Utils/generateUniqueId'

export type TMenu = {
	Component?: React.ReactNode;
	key: string,
	type: 'component' | 'link' | 'linkGroup'
	title?: string,
	icon?: React.ReactNode,
	iconActive?: React.ReactNode,
	checkActive?: boolean,
	onClick?: () => void,
	to?: string,
	subLinks?: {
		title?: string,
		key?: string,
		type?: string,
		icon?: React.ReactNode,
		checkActive?: boolean,
		onClick?: () => void,
		to?: string,
	}[]
}[]

export const menu = (profile: TypeUser, menu: string, setMenu: React.Dispatch<React.SetStateAction<string>>): TMenu => {

	if (fnUser.isAdm(profile)) return menuAdm(profile, menu, setMenu)
	if (fnUser.isAgent(profile) || fnUser.isMicroBda(profile) || fnUser.isEnterprise(profile)) return menuAgent(profile, menu, setMenu)
	if (fnUser.isOffice(profile)) return menuOffice(profile, menu, setMenu)
	if (fnUser.isOfficeAnalyst(profile)) return menuOfficeAnalyst(profile, menu, setMenu)
	if (fnUser.isCustomer(profile)) return menuCustomer(profile, menu, setMenu)

	return [
		{
			Component: <LogoStark />,
			key: generateUniqueId(),
			type: 'component',
		},
	]

}
