import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

type TypeGetProfileFromPreOrigination = {
	id: string,
}

type GetProfileFromPreOriginationResponse = {
	status: number,
	doc: any
}

export const _getProfileForPreOrigination = (input: TypeGetProfileFromPreOrigination): Promise<{ data: GetProfileFromPreOriginationResponse }> => firebase.functions().httpsCallable('fnCloud')({ microservice: 'getProfileForPreOrigination', input })

