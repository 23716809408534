import React, { useEffect, useLayoutEffect, useState } from 'react'
import { LogoStark } from 'Components/Layout/LogoStark'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useHistory } from 'react-router-dom'
import { TMenu } from './menu'
import { UiButton } from 'ComponentsUi/UiButton'
import { useGlobalContext } from 'App/Global'
import { NotificationBell } from 'Components/NotificationBell'
import { generateUniqueId } from 'Functions/Utils/generateUniqueId'
import { useContextNotificationWidget } from 'App/ContextNotificationWidget'
import { useContextUnReadNotifications } from 'App/ContextUnReadNotifications'
import { useContextAuth } from 'App/ContextAuth'
import { encrypt } from 'Functions/Utils/encript'

export const menuAgent = (profile: TypeUser, menu: string, setMenu: React.Dispatch<React.SetStateAction<string>>): TMenu => {

	const history = useHistory()

	const { globalAuth } = useContextAuth()
	const codeProfile = encrypt(globalAuth.email)

	const {
		// unReadNotifications,
		setOpenNotification,
	} = useContextNotificationWidget()

	const { unReadNotifications, setUnReadNotifications } = useContextUnReadNotifications()

	return ([
		{
			Component: (<>
				<div className='tw-hidden md:tw-block'>
					<LogoStark />
				</div>
				<div className='tw-block md:tw-hidden tw-h-[10px]'></div>
			</>),
			key: generateUniqueId(),
			type: 'component',
		},

		{
			type: 'component',
			Component: <div className='mx-2'><UiButton className='tw-w-full' onClick={() => history.push('/teasers/newOrigination/')}>Nova Originação</UiButton></div>,
			key: generateUniqueId(),
		},

		{
			title: 'Dash',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'>home</i>,
			iconActive: <i className='material-icons tw-text-xl'>home</i>,
			checkActive: history.location.pathname === '/painel/',
			to: '/painel/'
		},

		{
			title: 'Notificações',
			key: generateUniqueId(),
			type: 'link',
			icon:
				<NotificationBell small notifications={Object.keys(unReadNotifications).length}>
					<i className='material-icons-outlined'>notifications</i>
				</NotificationBell>
			,
			checkActive: false,
			onClick: () => setOpenNotification(e => !e),
		},

		{
			title: 'Carteira',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'>wallet</i>,
			iconActive: <i className='material-icons tw-text-xl'>wallet</i>,
			checkActive: history.location.pathname.startsWith('/teasers/'),
			to: '/teasers/'
		},

		{
			title: 'Kit Comercial',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'>inventory</i>,
			iconActive: <i className='material-icons tw-text-xl'>inventory</i>,
			checkActive: history.location.pathname.startsWith('/kit-comercial/'),
			to: '/kit-comercial/'
		},
		{
			title: 'Teses',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'>description</i>,
			iconActive: <i className='material-icons tw-text-xl'>description</i>,
			checkActive: history.location.pathname.startsWith('/teses/'),
			to: '/teses/'
		},

		{
			title: 'Academy',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'>school</i>,
			iconActive: <i className='material-icons tw-text-xl'>school</i>,
			//checkActive: history.location.pathname.startsWith('/academy/'),
			onClick: () => window.open(`https://stark.weduka.com.br/Authentication/Consume/?id=${codeProfile}`, '_blank')
		},
		{
			title: 'Perfil',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined'>person</i>,
			iconActive: <i className='material-icons tw-text-xl'>person</i>,
			checkActive: history.location.pathname.startsWith('/perfil/'),
			to: '/perfil/'
		},
	])
}
